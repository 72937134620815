import React, { Component } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import InvoiceMenu from "./InvoiceMenu";
import { connect } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import AtFoodPdf from "./Manual.pdf";
const logoImage = process.env.REACT_APP_DASHBOARD_LOGO_IMAGE
  ? require("../../assets/images/" + process.env.REACT_APP_DASHBOARD_LOGO_IMAGE)
  : "";
const dashboardImage = require("../../assets/images/navigation/DASHBOARD.png");
// const logoMiniImage = require("../../assets/images/Spinclabs-Thumbnails.png");
const logoMiniImage = process.env.REACT_APP_DASHBOARD_LOGO_MINI_IMAGE
  ? require("../../assets/images/" +
      process.env.REACT_APP_DASHBOARD_LOGO_MINI_IMAGE)
  : "";
const loadOptimization = process.env.REACT_APP_LOAD_OPTIMIZATION;
const planningImage = require("../../assets/images/navigation/PLANNING.png");
const tripsImage = require("../../assets/images/navigation/TRIPS.png");
const mastersImage = require("../../assets/images/navigation/MASTERS.png");
const billingImage = require("../../assets/images/navigation/BILLING.png");
const reportsImage = require("../../assets/images/navigation/REPORTS.png");
const ControlTowerImage = require("../../assets/images/navigation/control tower icon.svg");
let MAIN_DASHBOARD = "MAIN_DASHBOARD";
let MAIN_INDENT_PLANNING = "MAIN_INDENT_PLANNING";
let MAIN_TRIPS = "MAIN_TRIPS";
let MAIN_MASTERS = "MAIN_MASTERS";
let MAIN_INVOICE = "MAIN_INVOICE";
let MAIN_REPORTS = "MAIN_REPORTS";
let MAIN_CONTROL_TOWER = "MAIN_CONTROL_TOWER";

let DASHBOARD = "DASHBOARD";
let INDENT_PLANNINGS = "INDENT_PLANNINGS";
let ADD_INDENT = "ADD_INDENT";
let TRIPS = "TRIPS";
let DRIVERS = "DRIVERS";
let VEHICLES = "VEHICLES";
let USERS = "USERS";
let ROLES = "ROLES";
let AGGREMENTS = "AGGREMENTS";
let BILLS = "BILLS";
let TRANSPORTER_PERFORMANCE = "TRANSPORTER_PERFORMANCE";

class MobileMenuBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      closeIcon: false,
    };
  }
  removeFilterItemFromLocalStorageTrip = () => {
    console.log("Removing filters");

    localStorage.removeItem("filters");
    localStorage.removeItem("offset");
  };
  removeFilterItemFromLocalStorageIndent = () => {
    console.log("Removing filters");
    localStorage.removeItem("IndentFilters");
    localStorage.removeItem("offset");
  };
  render() {
    console.log("this close Icon", this.state.closeIcon);
    const keyboardArrowIconStyle = {
      position: "absolute",
      fontSize: 20,
      marginLeft: "10px",
      color: "#FFFFFF",
      right: "14%",
      marginTop: "3%",
    };
    return (
      <>
        <div
          id="my-sidebar-mobile"
          className="mainExpandedStyle"
          onMouseEnter={this.props.menuBarIn}
          onMouseLeave={this.props.menuBarOut}
        >
          <div>
            <span className="closeIconStyles">
              <CloseIcon
                onClick={() => this.props?.closeMenuBar(this.state.closeIcon)}
              />
            </span>
            <img className="mainLogoStyleMobile" src={logoImage} alt="logo" />
          </div>
          <div className="logoSepartorStyleMobile"></div>
          <div className="linksSectionMobile">
            {/* DASHBOARD */}
            {this.props.userInfo?.data?.role?.label !== "transporter" ||
            this.props.userInfo?.data?.userType == "internal" ? (
              <div className="linkSection">
                <div
                  className={`mainLink ${
                    this.props.activeState === MAIN_DASHBOARD &&
                    this.props.location.pathname === "/operationsDashboard"
                      ? "activeLinkSection"
                      : null
                  }`}
                  onClick={() => {
                    this.props.handleActiveLink(MAIN_DASHBOARD);
                    this.removeFilterItemFromLocalStorageIndent();
                    this.removeFilterItemFromLocalStorageTrip();
                  }}
                >
                  <Link to="/dashboard">
                    <div>
                      <img src={dashboardImage} alt="dashImg" />
                      <span className="mainLinkName">
                        {this.props.userLangs?.dashboard?.DASHBOARD}
                        {/* Dashboard */}
                      </span>
                      {/* <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} /> */}
                    </div>
                  </Link>
                </div>
                <div
                  className={`subLinks ${
                    this.props.sublink === 1 ? "displaySubMenu" : "hideSubMenu"
                  }`}
                ></div>
              </div>
            ) : (
              ""
            )}

            {/* INDENT PLANNING */}
            {this.props.userInfo?.data?.role?.label !== "transporter" ? (
              <div className="linkSection">
                <div
                  className={`mainLink ${
                    this.props.activeState === MAIN_INDENT_PLANNING
                      ? "activeLinkSection"
                      : null
                  }`}
                  onClick={() => {
                    this.props.handleActiveLink(MAIN_INDENT_PLANNING);
                    this.removeFilterItemFromLocalStorageTrip();
                  }}
                >
                  <div>
                    <img src={planningImage} />
                    <span className="mainLinkName">
                      {this.props.userLangs?.planning?.PLANNING_CAPS}
                    </span>
                    {this.props.activeState === MAIN_INDENT_PLANNING &&
                    this.props.show === true ? (
                      <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                    ) : (
                      <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                    )}
                  </div>
                </div>
                <div
                  className={`subMenu ${
                    this.props.activeState === MAIN_INDENT_PLANNING &&
                    this.props.show === true
                      ? "displaySubMenu"
                      : "hideSubMenu"
                  }`}
                  // onClick={() => this.setState({mainLink:MAIN_INDENT_PLANNING})}
                >
                  <Link to="/indent-planning">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">
                        {this.props.userLangs?.planning?.INDENT_PLANNING}
                      </span>
                    </div>
                  </Link>
                  <Link to="/add-indent">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">
                        {this.props.userLangs?.planning?.ADD_INDENT}
                      </span>
                    </div>
                  </Link>
                  {/* {process.env.REACT_APP_LOAD_OPTIMIZATION ? (
                    <Link to="/load-optimization">
                      <div className="subMenuLinkDiv">
                        <span className="subMenuLinkName">
                          {this.props.userLangs?.planning?.LOAD_OPTIMIZATION ??
                            "Load Optimization"}
                        </span>
                      </div>
                    </Link>
                  ) : (
                    <></>
                  )} */}
                </div>
              </div>
            ) : (
              <div className="linkSection">
                <div
                  className={`mainLink ${
                    this.props.activeState === MAIN_INDENT_PLANNING
                      ? "activeLinkSection"
                      : null
                  }`}
                  onClick={() => {
                    this.props.handleActiveLink(MAIN_INDENT_PLANNING);
                    this.removeFilterItemFromLocalStorageTrip();
                  }}
                >
                  <div>
                    <img src={planningImage} />
                    <span className="mainLinkName">
                      {this.props.userLangs?.planning?.PLANNING}
                    </span>
                    {this.props.activeState === MAIN_INDENT_PLANNING &&
                    this.props.show === true ? (
                      <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                    ) : (
                      <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                    )}
                  </div>
                </div>
                <div
                  className={`subMenu ${
                    this.props.activeState === MAIN_INDENT_PLANNING &&
                    this.props.show === true
                      ? "displaySubMenu"
                      : "hideSubMenu"
                  }`}
                  // onClick={() => this.setState({mainLink:MAIN_INDENT_PLANNING})}
                >
                  <Link to="/assigned-indent">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">
                        {this.props.userLangs?.planning?.INDENT_PLANNING}
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            )}

            {/* TRIPS */}
            <div className="linkSection">
              <div
                className={`mainLink ${
                  this.props.activeState === MAIN_TRIPS
                    ? "activeLinkSection"
                    : null
                }`}
                onClick={() => {
                  this.props.handleActiveLink(MAIN_TRIPS);
                  this.removeFilterItemFromLocalStorageIndent();
                }}
              >
                <div>
                  <img src={tripsImage} />
                  <span className="mainLinkName">
                    {" "}
                    {this.props.userLangs?.trip?.TRIPS}
                  </span>
                  {this.props.activeState === MAIN_TRIPS &&
                  this.props.show === true ? (
                    <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                  ) : (
                    <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                  )}
                </div>
              </div>
              <div
                className={`subMenu ${
                  this.props.activeState === MAIN_TRIPS &&
                  this.props.show === true
                    ? "displaySubMenu"
                    : "hideSubMenu"
                }`}
              >
                <Link to="/trips">
                  <div className="subMenuLinkDiv">
                    <span className="subMenuLinkName">
                      {this.props.userLangs?.trip?.VIEW_ALL_TRIPS}
                    </span>
                  </div>
                </Link>
              </div>
            </div>
            {/* MASTERS */}
            <div className="linkSection">
              <div
                className={`mainLink ${
                  this.props.activeState === MAIN_MASTERS
                    ? "activeLinkSection"
                    : null
                }`}
                onClick={() => {
                  this.props.handleActiveLink(MAIN_MASTERS);
                  this.removeFilterItemFromLocalStorageIndent();
                  this.removeFilterItemFromLocalStorageTrip();
                }}
              >
                <div>
                  <img src={mastersImage} />
                  <span className="mainLinkName">
                    {this.props.userLangs?.masters?.MASTERS}
                  </span>
                  {this.props.activeState === MAIN_MASTERS &&
                  this.props.show === true ? (
                    <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                  ) : (
                    <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                  )}
                </div>
              </div>
              <div
                className={`subMenu ${
                  this.props.activeState === MAIN_MASTERS &&
                  this.props.show === true
                    ? "displaySubMenu"
                    : "hideSubMenu"
                }`}
              >
                <Link to="/drivers">
                  <div
                    className="subMenuLinkDiv"
                    onClick={() => this.props.handleActiveSubLink(DRIVERS)}
                  >
                    <span className="subMenuLinkName">
                      {this.props.userLangs?.masters?.DRIVERS}
                    </span>
                  </div>
                </Link>
                <Link to="/vehicles">
                  <div className="subMenuLinkDiv">
                    <span className="subMenuLinkName">
                      {this.props.userLangs?.masters?.VEHICLES}
                    </span>
                  </div>
                </Link>
                <Link to="/vehicle-type">
                  <div className="subMenuLinkDiv">
                    <span className="subMenuLinkName">
                      {this.props.userLangs?.masters?.VEHICLE_TYPE}
                    </span>
                  </div>
                </Link>
                {this.props?.userInfo?.data?.role?.label !== "transporter" ? (
                  <Link to="/user">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">
                        {this.props.userLangs?.masters?.USERS}
                      </span>
                    </div>
                  </Link>
                ) : (
                  ""
                )}
                {this.props?.userInfo?.data?.role?.label !== "transporter" ? (
                  <Link to="/roles">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">
                        {this.props.userLangs?.masters?.ROLES}
                      </span>
                    </div>
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* INVOICE */}
            {this.props.userInfo?.data?.role?.label === "transporter" ? (
              <div className="linkSection">
                <div
                  className={`mainLink ${
                    this.props.activeState === MAIN_INVOICE
                      ? "activeLinkSection"
                      : null
                  }`}
                  onClick={() => {
                    this.props.handleActiveLink(MAIN_INVOICE);
                    this.removeFilterItemFromLocalStorageIndent();
                    this.removeFilterItemFromLocalStorageTrip();
                  }}
                >
                  <div>
                    <img src={billingImage} />
                    <span className="mainLinkName">
                      {this.props.userLangs?.dashboard?.INVOICE}
                    </span>
                    {this.props.activeState === MAIN_INVOICE &&
                    this.props.show === true ? (
                      <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                    ) : (
                      <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                    )}
                  </div>
                </div>
                <div
                  className={`subMenu ${
                    this.props.activeState === MAIN_INVOICE &&
                    this.props.show === true
                      ? "displaySubMenu"
                      : "hideSubMenu"
                  }`}
                >
                  <Link to="/Billing/InvoiceList">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">
                        {this.props.userLangs?.invoice?.INVOICE_LIST}
                      </span>
                    </div>
                  </Link>
                  <Link to="/invoiceGeneration">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">
                        {this.props.userLangs?.invoice?.INVOICE_GENERATION}
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            ) : (
              ""
            )}

            {this.props.userInfo?.data?.role?.label === "Admin" ||
            this.props.userInfo?.data?.role?.label === "billing_manager" ? (
              <div className="linkSection">
                <div
                  className={`mainLink ${
                    this.props.activeState === MAIN_INVOICE
                      ? "activeLinkSection"
                      : null
                  }`}
                  onClick={() => {
                    this.props.handleActiveLink(MAIN_INVOICE);
                    this.removeFilterItemFromLocalStorageIndent();
                    this.removeFilterItemFromLocalStorageTrip();
                  }}
                >
                  <div>
                    <img src={billingImage} />
                    <span className="mainLinkName">
                      {this.props.userLangs?.dashboard?.INVOICE}
                    </span>
                    {this.props.activeState === MAIN_INVOICE &&
                    this.props.show === true ? (
                      <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                    ) : (
                      <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                    )}
                  </div>
                </div>
                <div
                  className={`subMenu ${
                    this.props.activeState === MAIN_INVOICE &&
                    this.props.show === true
                      ? "displaySubMenu"
                      : "hideSubMenu"
                  }`}
                >
                  <Link to="/BillingIndex">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">
                        {this.props.userLangs?.invoice?.AGREEMENT}
                      </span>
                    </div>
                  </Link>
                  <Link to="/Billing/InvoiceList">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">
                        {this.props.userLangs?.invoice?.INVOICE_LIST}
                      </span>
                    </div>
                  </Link>

                  <Link to="/tripBills">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">
                        {this.props.userLangs?.invoice?.ADD_BILL}
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            ) : (
              ""
            )}

            {this.props.userInfo?.data?.role?.label ===
            "billing_team_member" ? (
              <div className="linkSection">
                <div
                  className={`mainLink ${
                    this.props.activeState === MAIN_INVOICE
                      ? "activeLinkSection"
                      : null
                  }`}
                  onClick={() => {
                    this.props.handleActiveLink(MAIN_INVOICE);
                    this.removeFilterItemFromLocalStorageIndent();
                    this.removeFilterItemFromLocalStorageTrip();
                  }}
                >
                  <div>
                    <img src={billingImage} />
                    <span className="mainLinkName">
                      {this.props.userLangs?.dashboard?.INVOICE}
                    </span>
                    {this.props.activeState === MAIN_INVOICE &&
                    this.props.show === true ? (
                      <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                    ) : (
                      <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                    )}
                  </div>
                </div>
                <div
                  className={`subMenu ${
                    this.props.activeState === MAIN_INVOICE &&
                    this.props.show === true
                      ? "displaySubMenu"
                      : "hideSubMenu"
                  }`}
                >
                  <Link to="/Billing/InvoiceList">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">
                        {this.props.userLangs?.invoice?.INVOICE_LIST}
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            ) : (
              ""
            )}
            {this.props.userInfo?.data?.role?.label === "loading_unloading" ? (
              <div className="linkSection">
                <div
                  className={`mainLink ${
                    this.props.activeState === MAIN_INVOICE
                      ? "activeLinkSection"
                      : null
                  }`}
                  onClick={() => {
                    this.props.handleActiveLink(MAIN_INVOICE);
                    this.removeFilterItemFromLocalStorageIndent();
                    this.removeFilterItemFromLocalStorageTrip();
                  }}
                >
                  <div>
                    <img src={billingImage} />
                    <span className="mainLinkName">
                      {this.props.userLangs?.dashboard?.INVOICE}
                    </span>
                    {this.props.activeState === MAIN_INVOICE &&
                    this.props.show === true ? (
                      <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                    ) : (
                      <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                    )}
                  </div>
                </div>
                <div
                  className={`subMenu ${
                    this.props.activeState === MAIN_INVOICE &&
                    this.props.show === true
                      ? "displaySubMenu"
                      : "hideSubMenu"
                  }`}
                >
                  <Link to="/BillingIndex">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">
                        {this.props.userLangs?.invoice?.AGREEMENT}
                      </span>
                    </div>
                  </Link>
                  <Link to="/tripBills">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">
                        {this.props.userLangs?.invoice?.ADD_BILL}
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* <InvoiceMenu
              userRole={this.props.userInfo?.data?.role?.label}
              handleActiveLink={this.props.handleActiveLink}
              show={this.props?.show}
              keyboardArrowIconStyle={keyboardArrowIconStyle}
              userLangs={this.props.userLangs}
            /> */}

            {/* REPORTS */}
            {this.props?.userInfo?.data?.role?.label !== "transporter" ? (
              <div className="linkSection">
                <div
                  className={`mainLink ${
                    this.props.activeState === MAIN_REPORTS
                      ? "activeLinkSection"
                      : null
                  }`}
                  onClick={() => {
                    this.props.handleActiveLink(MAIN_REPORTS);
                    this.removeFilterItemFromLocalStorageIndent();
                    this.removeFilterItemFromLocalStorageTrip();
                  }}
                >
                  <div>
                    <img src={reportsImage} />
                    <span className="mainLinkName">
                      {" "}
                      {this.props.userLangs?.report?.REPORTS}
                    </span>
                    {this.props.mainLink === MAIN_REPORTS &&
                    this.props.show === true ? (
                      <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                    ) : (
                      <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                    )}
                  </div>
                </div>
                <div
                  className={`subMenuReport ${
                    this.props.activeState === MAIN_REPORTS &&
                    this.props.show === true
                      ? "displaySubMenu"
                      : "hideSubMenu"
                  }`}
                >
                  <Link to="/Reports">
                    <div className="subMenuLinkDiv">
                      <div className="subMenuLinkName">
                        <span className="subMenuLinkName">
                          {
                            this.props.userLangs?.report
                              ?.TRANSPORTER_PERFORMANCE
                          }
                        </span>
                      </div>
                    </div>
                  </Link>
                  <div></div>
                  <Link to="/indentReport-analysis">
                    <div className="subMenuLinkDiv">
                      <div className="subMenuLinkName">
                        <span className="subMenuLinkName">
                          {" "}
                          {this.props.userLangs?.report?.INDENT_ANALYSIS_REPORT}
                        </span>
                      </div>
                    </div>
                  </Link>
                  <Link to="/vehicle-placement">
                    <div className="subMenuLinkDiv">
                      <div className="subMenuLinkName">
                        <span className="subMenuLinkName">
                          {this.props.userLangs?.report
                            ?.VEHICLE_PLACEMENT_REPORT
                            ? this.props.userLangs?.report
                                ?.VEHICLE_PLACEMENT_REPORT
                            : "Vehicle Placement Report"}
                        </span>
                      </div>
                    </div>
                  </Link>
                  <Link to="/ontime-delivery-report">
                    <div className="subMenuLinkDiv">
                      <div className="subMenuLinkName">
                        <span className="subMenuLinkName">
                        {this.props.userLangs?.report
                            ?.ON_TIME_DELIVERY_REPORT1
                            ?? "On Time Delivery Report  "}
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* CONTROL TOWER */}
            {this.props.userInfo?.data?.role?.label !== "transporter" ? (
              <div className="linkSection">
                <div
                  className={`mainLink ${
                    this.props.activeState === MAIN_CONTROL_TOWER &&
                    this.props.location.pathname === "/ControlTower"
                      ? "activeLinkSection"
                      : null
                  }`}
                  onClick={() => {
                    this.props.handleActiveLink(MAIN_CONTROL_TOWER);
                    this.removeFilterItemFromLocalStorageIndent();
                    this.removeFilterItemFromLocalStorageTrip();
                  }}
                >
                  <Link to="/ControlTower">
                    <div>
                      <img src={ControlTowerImage} alt="controlImg" />
                      <span className="mainLinkName">
                        {" "}
                        {this.props.userLangs?.controlTower?.CONTROL_TOWER}
                      </span>
                      {/* <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} /> */}
                    </div>
                  </Link>
                </div>
                <div
                  className={`subLinks ${
                    this.props.sublink === 1 ? "displaySubMenu" : "hideSubMenu"
                  }`}
                ></div>
              </div>
            ) : (
              ""
            )}
            {/* HELP */}
            <div
              className="helpMenuStyle"
              onClick={() => this.props.onResumePdfClick()}
            >
              <div className="mainLinkHelp">
                {/* <img src={ControlTowerImage} alt="controlImg" /> */}
                <a href={AtFoodPdf} target="_blank" rel="noreferrer">
                  <span className="helpName">
                    {this.props.userLangs?.common?.HELP}
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </>
    );
  }
}
const mapStatetoProps = (state) => {
  // console.log(state, "state");
  return {
    ...state.Layout,
    activeState: state.Layout.activeTheme,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeActiveTheme: (name) => {
      dispatch({ type: "CHANGE_ACTIVE_THEME", payload: name });
    },
  };
};

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(withRouter(MobileMenuBar));
