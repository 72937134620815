import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import { createDriver, deleteDriver } from "../../store/driver/actions";
import { NotificationManager } from "react-notifications";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { restrict } from "../restrict";
import { PERMS } from "../../enum/perms.enum";
import { fetchAllTransporterSearch } from "../../store/transporter/actions";
import * as Yup from "yup";

import "@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { connect } from "react-redux";

//Import Breadcrumb

import formatInput from "../InputFormatter";
import { getTransporters } from "../../store/transporter/actions";
import { FormGroup, Label, Input, FormText } from "reactstrap";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

const initialState = () => ({
  onSubmitDisable: false,
  offset: 0,
  limit: 20,
  transpoter: {},
  transporterData: "",
  form: {
    fullName: "",
    phone: "",
    driverLicenseNumber: "",
    transporter: "",
  },
});
class DriverForm extends Component {
  constructor(props) {
    super(props);
    this.state = initialState();
    (async () => {
      let redirect = await restrict(PERMS.DRIVER_TRANSPORTER_CREATE);
      // // console.log(redirect, "redirect");

      if (redirect == false) {
        this.props.history.push("/operationsDashboard");
      }
    })();
  }

  componentDidMount = () => {
    this.getAllData();
  };

  getAllData = () => {
    this.props.fetchAllTransporterSearch(
      this.state.offset,
      this.state.limit,
      "",
      (res) => {
        let transporter = [];
        if (res.data?.docs?.length > 0) {
          res.data.docs.forEach((ele) => {
            transporter.push({
              value: ele._id ? ele._id : "",
              label: ele.name ? ele.name : "",
            });
          });
        }
        this.setState(
          {
            transpoter: transporter,
            transporterData: transporter,
          },
          () => {}
        );
      }
    );
  };

  filterTransporterList = (e) => {
    if (e?.length > 2) {
      this.props.fetchAllTransporterSearch(
        this.state.offset,
        this.state.limit,
        e,
        (res) => {
          let transporter = [];
          if (res.data?.docs?.length > 0) {
            res.data.docs.forEach((ele) => {
              transporter.push({
                value: ele._id ? ele._id : "",
                label: ele.name ? ele.name : "",
              });
            });
          }
          this.setState(
            {
              transpoter: transporter,
            },
            () => {}
          );
        }
      );
    }

    if (e?.length === 0) {
      this.setState(
        {
          transpoter: this.state.transporterData,
        },
        () => {}
      );
    }
  };
  handleSubmitChange = (e) => {
    console.log("handleSubmitChange", typeof e.target.value);
    const name = e.target.name;
    let value = e.target.value;
    let formData = this.state.form;
    formData[`${name}`] = value;

    this.setState({ form: formData }, () => {});
  };
  validateNumber = (evt) => {
    var theEvent = evt || window.event;

    // Handle paste
    if (theEvent.type === "paste") {
      var key = theEvent.clipboardData.getData("text/plain");
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /^[0-9]*$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };
  trimSpace = (event, name) => {
    const { form } = this.state;
    let formObj = formatInput(event, name, form);
    this.setState({ form: formObj });
  };
  reset = (cb) => {
    this.setState(
      {
        form: {
          fullName: "",
          phone: "",
          driverLicenseNumber: "",
          transporter: "",
        },
      },
      () => {
        this.getAllData();
      }
    );
  };
  handleSubmitSave = (formValues) => {
    let { fullName, phone, driverLicenseNumber, transporter } = this.state.form;
    let formObj = {
      name: fullName,
      phone,
      licence: { number: driverLicenseNumber },
      transporter: transporter.value,
      // bloodGroup,
      // emergencyContactPerson,
      // emergencyContactNo,
    };
    console.log(formObj, "handleSubmitSave");
    this.props.createDriver(formObj, (res) => {
      if (res.message == "Driver has been created successfully") {
        NotificationManager.success(res["message"], "Success");

        this.props.handleSubmit(false);

        this.props.history.push("/drivers");
      } else {
        NotificationManager.error(res["message"], "Error");
        this.setState({ onSubmitDisable: false });
      }
    });
  };
  render() {
    const customStyles1 = {
     
      input: base => ({
        ...base,
        color: this.props.mode?"#9d9d9d":"#505d69"
      }),
      
    };
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    const validationSchema = Yup.object({
      fullName: Yup.string()
        .min(2, userLang?.masters?.TOO_SHORT)
        .required(userLang?.masters?.FULL_NAME_IS_REQUIRED),
      phone: Yup.string()
        .min(10, userLang?.masters?.PHONE_MUST_BE_10)
        .required(userLang?.masters?.PHONE_IS_REQUIRED)
        .matches(
          "^[0-9]{10}$",
          `${userLang?.masters?.PHONE_FORMAT}: 9988776655`
        ),
      driverLicenseNumber: Yup.string().required(
        userLang?.masters?.DRIVER_LICENSE_REQUIRED
      ),
      // phone: Yup.string()
      //   .matches(
      //     "^[0-9]{10}$",
      //     `${userLang?.masters?.PHONE_FORMAT}: 9988776655`
      //   )
      //   .required(),
    });
    const { form } = this.state;
    const role = JSON.parse(localStorage.getItem("authUser"))?.data?.role
      ?.label;
    return (
      <React.Fragment>
        <Row className="DriverFormDiv">
          <Col lg={12}>
            <Row>
              <Formik
                md="12"
                initialValues={
                  this.props?.location?.mode == "edit" ? this.formSet() : form
                }
                validationSchema={validationSchema}
                onSubmit={this.handleSubmitSave}
                validateOnChange={false}
              >
                {(formik) => {
                  const {
                    handleSubmit,
                    values,
                    errors,
                    touched,
                    isValid,
                    dirty,
                    handleBlur,
                    resetForm,
                  } = formik;
                  console.log("values", values);
                  // console.log(errors);
                  return (
                    <form
                      className="form text-capitalize"
                      onSubmit={handleSubmit}
                      style={{
                        width: " 330px",
                        height: "380px",
                        marginTop: "6px",
                      }}
                    >
                      <FormGroup>
                        <Label
                          className={`required-field  mt-2 ${
                            this.props.mode ? "light__span" : ""
                          }`}
                          style={{
                            color: "#05284E",
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "bold",
                            fontSize: "15px",
                            lineHeight: "18px",
                          }}
                        >
                          {userLang?.common?.FULL_NAME}
                        </Label>

                        <Input
                          type="text"
                          value={form.fullName}
                          // onBlur={(e) =>
                          //   this.trimSpace(e, "fullName")
                          // }
                          onChange={(e) =>
                            this.handleSubmitChange({
                              target: {
                                name: "fullName",
                                value: e.target.value,
                              },
                            })
                          }
                          name="fullName"
                          id="fullName"
                          className={
                            errors.fullName && touched.fullName
                              ? "input-error"
                              : null
                          }
                          placeholder="Full Name"
                          style={{
                            height: "50px",
                            width: " 400px",
                            backgroundColor: this.props.mode ? "#2E2E46" : "#FFFFFF",
                            border: "0.5px solid #B9B9B9",
                            boxSizing: "border-box",
                            borderRadius: "4px",
                          }}
                        />
                        <ErrorMessage
                          name="fullName"
                          component="span"
                          className="error"
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label
                          className={`required-field  mt-2 ${
                            this.props.mode ? "light__span" : ""
                          }`}
                          style={{
                            color: "#05284E",
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "bold",
                            fontSize: "15px",
                            lineHeight: "18px",
                          }}
                        >
                          {userLang?.masters?.CONTACT}
                        </Label>
                        <Input
                          type="text"
                          value={form.phone}
                          onChange={(e) =>
                            this.handleSubmitChange({
                              target: {
                                name: "phone",
                                value: e.target.value,
                              },
                            })
                          }
                          name="phone"
                          id="phone"
                          className={
                            errors.phone && touched.phone ? "input-error" : null
                          }
                          placeholder="98989898989"
                          onKeyPress={this.validateNumber}
                          maxlength="10"
                          style={{
                            height: "50px",
                            width: " 400px",
                            backgroundColor: this.props.mode ? "#2E2E46" : "#FFFFFF",
                            border: "0.5px solid #B9B9B9",
                            boxSizing: "border-box",

                            borderRadius: "4px",
                          }}
                        />
                        <ErrorMessage
                          name="phone"
                          component="span"
                          className="error"
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label
                          className={`required-field  mt-2 ${
                            this.props.mode ? "light__span" : ""
                          }`}
                          style={{
                            color: "#05284E",
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "bold",
                            fontSize: "15px",
                            lineHeight: "18px",
                          }}
                        >
                          {userLang?.common?.DRIVER_LICENSE_NO}
                        </Label>
                        <Input
                          type="text"
                          value={form.driverLicenseNumber}
                          // onBlur={(e) =>
                          //   this.trimSpace(
                          //     e,
                          //     "driverLicenseNumber"
                          //   )
                          // }
                          onChange={(e) =>
                            this.handleSubmitChange({
                              target: {
                                name: "driverLicenseNumber",
                                value: e.target.value,
                              },
                            })
                          }
                          name="driverLicenseNumber"
                          id="driverLicenseNumber"
                          className={
                            errors.driverLicenseNumber &&
                            touched.driverLicenseNumber
                              ? "input-error form-control"
                              : null
                          }
                          placeholder="HR2620110040897"
                          style={{
                            height: "50px",
                            width: " 400px",
                            backgroundColor: this.props.mode ? "#2E2E46" : "#FFFFFF",
                            border: "0.5px solid #B9B9B9",
                            boxSizing: "border-box",

                            borderRadius: "4px",
                          }}
                        />
                        <ErrorMessage
                          name="driverLicenseNumber"
                          component="span"
                          className="error"
                        />
                      </FormGroup>

                      {role != "transporter" ? (
                        <FormGroup className="select2-container mt-2">
                          <Label
                            className={`control-label ${
                              this.props.mode ? "light__span" : ""
                            }`}
                            style={{
                              color: "#05284E",
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "bold",
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            {userLang?.common?.TRANSPORTER}
                          </Label>
                          <Select
                            value={form.transporter}
                            onInputChange={this.filterTransporterList}
                            id="typeVehicle"
                            onChange={(e) => {
                              this.handleSubmitChange({
                                target: {
                                  name: "transporter",
                                  value: e,
                                },
                              });
                            }}
                            options={this.state.transpoter}
                            classNamePrefix="select2-selection"
                            style={{
                              height: "40px",
                              width: " 400px",
                              backgroundColor: "#FFFFFF",
                              border: "0.5px solid #B9B9B9",
                              boxSizing: "border-box",
                              borderRadius: "4px",
                            }}
                            styles={customStyles1}
                            className={this.props.mode ? "darkModeDriver" : ""}
                          />
                        </FormGroup>
                      ) : (
                        ""
                      )}

                      <Row
                        className="mt-2"
                        style={{ marginRight: "128px", float: "right" }}
                      >
                        <Col className="text-center mt-4">
                          <Button
                            type="submit"
                            color="primary"
                            className=" mr-2 btn-md button-wide"
                            style={{
                              height: "40px",
                              width: "90px",
                              // left: "518px",
                              top: " 549px",
                              borderRadius: " 0px",
                              backgroundColor: this.props.mode
                                ? "#5E40FF"
                                : "#05284E",
                            }}
                            // disabled={onSubmitDisable}
                          >
                            {userLang?.common?.SUBMIT}
                          </Button>

                          <Button
                            type="button"
                            color="secondary"
                            className=" btn-md button-wide"
                            onClick={() => this.reset(resetForm)}
                            style={{
                              height: "40px",
                              width: "90px",
                              // left: "518px",
                              top: " 549px",
                              borderRadius: " 0px",
                              backgroundColor: "#0AB38C",
                            }}
                            // disabled={onSubmitDisable}
                          >
                            {userLang?.common?.RESET}
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  );
                }}
              </Formik>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
const mapStatetoProps = (state) => {
  const user = state.Login.user;
  return { user };
};

export default connect(mapStatetoProps, {
  createDriver,
  deleteDriver,
  getTransporters,
  fetchAllTransporterSearch,
})(DriverForm);
