// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { types } from "./actionTypes";

import { getApi, postApi, putApi, deleteApi } from "../../helpers/auth_helper";
import { getDataGroupBy } from "rsuite/esm/utils";

function* worktripCountByStatus({ duration, callback }) {
  try {
    // Try to call the API

    const uri =
      process.env.REACT_APP_SERVERURL +
      `/trip/tripCountByStatus?duration=` +
      Number(200);
    // const uri ="http://142.93.210.220:4004/api/v1/trip/tripCountByStatus?duration=50"
    const response = yield call(getApi, uri);
    callback(response)
    yield put({
      type: types.TRIP_COUNT_BY_STATUS_SUCCESS,
      payload: response,
    });
    
    localStorage.setItem("transporterDashboardData", JSON.stringify(response));
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not fetch posts.", error);
  }
}

function* workOperationsDashboardTripsAndIndent({
  transporter,
  hop,
  startDate,
  endDate,
  carbonEmissionDataBy,
  OperationsDataBy,
  callback,
}) {
  try {
    // Try to call the API
    console.log("startDate sagaa", startDate, endDate, transporter,carbonEmissionDataBy,
    OperationsDataBy);
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/dashboard/operations?transporterId=` +
      transporter +
      `&hopId=` +
      hop +
      `&startDate=` +
      startDate +
      `&endDate=` +
      endDate +
      `&carbonEmissionDataBy=`+
      carbonEmissionDataBy + 
      `&operationalTATDataBy=`+
      OperationsDataBy;
    // const uri="http://142.93.210.220:4004/api/v1/dashboard/operations?transporterId=&hopId=&startDate=&endDate=";

    const response = yield call(getApi, uri);
    callback(response)
    yield put({
      type: types.OPERATIONS_TRIP_INDENT_BY_STATUS_SUCCESS,
      payload: response,
    });
   
    localStorage.setItem("operationDashboardData", JSON.stringify(response));
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not fetch posts.", error);
  }
}

function* workTransportDashboard({ hop, startDate, endDate, callback }) {
  try {
    // Try to call the API

    const uri =
      process.env.REACT_APP_SERVERURL +
      `/dashboard/transporter?hopId=` +
      hop +
      `&startDate=` +
      startDate +
      `&endDate=` +
      endDate;

    const response = yield call(getApi, uri);
    callback(response);

    yield put({
      type: types.TRANSPORTER_TRIP_HISTORY_BY_STATUS_SUCCESS,
      payload: response ? response : "",
    });
    localStorage.setItem("transportHistory", JSON.stringify(response));
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch posts.", error);
  }
}

function* workInvoiceDashboard({
  transporter,
  state,
  startDate,
  endDate,
  freightCostDataBy,
  freightAndShipmentCostDataBy,
  callback,
}) {
  try {
    // Try to call the API
    console.log("startDate sagaa", startDate, endDate, transporter);
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/dashboard/invoice?transporter=` +
      transporter +
      `&state=` +
      state +
      `&startDate=` +
      startDate +
      `&endDate=` +
      endDate+
      `&freightCostDataBy=`+
      freightCostDataBy+
      `&freightAndShipmentCostDataBy=`+
      freightAndShipmentCostDataBy;
    // const uri="http://142.93.210.220:4004/api/v1/dashboard/invoice?transporter=&state=&startDate=02-01-2021&endDate=12-15-2022";
    const response = yield call(getApi, uri);
    callback(response);
    yield put({
      type: types.INVOICE_DASHBOARD_BY_STATUS_SUCCESS,
      payload: response,
    });
    localStorage.setItem("invoiceDashboardData", JSON.stringify(response));
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch posts.", error);
  }
}
/**
 * Watchers
 */

export function* watchTripCountByStatus() {
  yield takeEvery(types.TRIP_COUNT_BY_STATUS, worktripCountByStatus);
}

export function* watchOperationsDashboardTripsAndIndent() {
  yield takeEvery(
    types.OPERATIONS_TRIP_INDENT_BY_STATUS,
    workOperationsDashboardTripsAndIndent
  );
}
export function* watchInvoiceDashboard() {
  yield takeEvery(types.INVOICE_DASHBOARD_BY_STATUS, workInvoiceDashboard);
}

export function* watchTransportDashboard() {
  yield takeEvery(
    types.TRANSPORTER_TRIP_HISTORY_BY_STATUS,
    workTransportDashboard
  );
}

function* TripCountSaga() {
  yield all([
    fork(watchTripCountByStatus),
    fork(watchOperationsDashboardTripsAndIndent),
    fork(watchTransportDashboard),
    fork(watchInvoiceDashboard),
  ]);
}

export default TripCountSaga;
