import React, { Component } from "react";
import { stateList } from "../../../../components/Common/StateList";
import {
  createInvoice,
  clearAllBills,
} from "../../../../store/billing/actions";
import { fetchBills } from "../../../../store/actions";
import Index from "../../../../components/Common/DataTableNeo/Index";
import actionVerticleDots from "./assets/more-vertical.png";
import actionVerticleDotsDark from "./assets/more-vertical-dark.png";
import { connect } from "react-redux";
import { restrict } from "../../../restrict";
import { PERMS } from "../../../../enum/perms.enum";
import Pagination from "@material-ui/lab/Pagination";
import RaiseInvoceModal from ".././RaiseInvoiceModal";
import InvoiceModal from ".././InvoiceModal";
import "./invoiceListTheme.scss";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import { MoonLoader } from "react-spinners";
import Checkbox from "@material-ui/core/Checkbox";
import {
  AdminHeaderData,
  searchBar,
  getLocalStorage,
} from "./RowHeaders/RowHeaders.js";
import QuickFilters from "./quckFilters";

import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
} from "reactstrap";

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;

var defaultCheckedBills = [];
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
var darkMode;
class InvoiceGeneration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoading:false,
      stateFilter: "",
      stateValue: "",
      searchValue: "",
      searchOn: false,
      FromDate: "",
      ToDate: "",
      raiseinvoiceModal: false,
      isOpen: false,
      invoiceDet: {},
      dateFlag: false,
      allFlag: false,
      bills: [],
      documentArray: [],
      raiseInvoice: {
        gstNo: "",
        FromDate: "",
      },
      resetDate:true,
      errorMessage:"",
      billInfo: { bill: [], response: [] },
      raiseInvoiceDisable: true,
      billFreightCheck: [],
      defaultCheckedBills: []
    };
   
    (async () => {
      let redirect = await restrict(PERMS.INDENT_TRANSPORTER_READ);

      if (redirect == false) {
        this.props.history.push("/operationsDashboard");
      }
    })();
  }
  componentDidMount() {
    if (AdminHeaderData.columns.length > 0) {
      if (AdminHeaderData.columns[0].label === " ") {
        AdminHeaderData.columns[0].label = (
          <Checkbox
            id="table-check"
            disabled
            inputProps={{ "aria-label": "disabled checkbox" }}
          />
        );
      }
    }

    // if(defaultCheckedBills.length>0 || this.state.billInfo.bill.length>0){
    //   this.setState({raiseInvoiceDisable:false})
    // }else{
    //   this.setState({raiseInvoiceDisable:true})
    // }
  }
  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };

  handleStateChange = (e) => {
    console.log(e, "eval");
    let offset = 0;
    let values = { offset: offset, limit: 10 };
    this.setState(
      {
        stateFilter: e,
        searchOn: !this.state.searchOn,
        stateFlag: true,
        stateValue: e.value,
        allFlag: true,
      },
      () => {
         this.setState({dataLoading:true})
        this.props.fetchBills(
          values,
          this.state.FromDate,
          this.state.ToDate,
          this.state.stateValue,
          ()=>{
          this.setState({dataLoading:false})
            if(defaultCheckedBills.length>0 || this.state.billInfo.bill.length>0){
             this.setState({raiseInvoiceDisable:false})
            }else{
             this.setState({raiseInvoiceDisable:true})
            }
          }
        );
        this.setState({ allFlag: true });
      }
    );
  };



  handleChange = (dateValues) => {
    console.log("date values", dateValues);
    this.setState(
      {
        FromDate: moment(dateValues[0]).format("MM-DD-YYYY"),
        ToDate: moment(dateValues[1]).format("MM-DD-YYYY"),
        dateFlag: true,
      },
      () => {
        if (
          this.state.stateFilter?.value &&
          this.state.FromDate &&
          this.state.ToDate
        )
          this.setState({ allFlag: true });
        this.searchFilter();
      }
    );
  };
  searchFilter = () => {
    let limit = 10;
    let values = { limit: limit, offset: offset };
    let { FromDate, ToDate, dateFlag, stateFlag, stateFilter, allFlag } =
      this.state;
    if (FromDate && ToDate && dateFlag && !allFlag) {
      this.setState({ dateFlag: false ,dataLoading:true}, () => {
        this.props.fetchBills(values, FromDate, ToDate, "",()=>{
        this.setState({dataLoading:false})
        });
      });
    } else if (allFlag) {
      this.setState(
        { dateFlag: false, stateFlag: false, allFlag: false,dataLoading:true },
        () => {
          this.props.fetchBills(values, FromDate, ToDate, stateFilter.value,()=>{
          this.setState({dataLoading:false})
          });
        }
      );
    } else if (!dateFlag && !allFlag && stateFlag) {
      this.setState({ stateFlag: false ,dataLoading:true }, () => {
        this.props.fetchBills(values, "", "", stateFilter.value ,()=>{
          this.setState({dataLoading:false})
        });
      });
    }
  };
  typeDisplay = (item) => {
    switch (item) {
      case "indent_rejection":
        return "Indent Rejection";
      case "canacelation_on_arrival":
        return "Cancellation On Arrival";
      case "freight":
        return "Freight";
      case "vehicle_non_placement":
        return "Vehicle Non Placement";
      default:
        return item;
    }
  };

  openRaiseInvoiceModal = () => {
    if (this.state.billInfo.bill.length > 0 || defaultCheckedBills.length > 0) {
      this.setState(
        { raiseinvoiceModal: !this.state.raiseinvoiceModal },
        () => {
          this.setState({ defaultCheckedBills: defaultCheckedBills });
        }
      );
    }
  };
  documentAssigner = (value) => {
    this.setState({ documentArray: value }, () => {
      // this.invoiceSubmit();
    });
  };

  defaultBillConcat = () => {
    if (defaultCheckedBills.length > 0) {
      this.setState({
        bills: this.state.billInfo.bill.concat(defaultCheckedBills),
      });
      console.log(this.state.bills);
    }
  };
  
  validateVal = (values)=>{
    const errors = {};
    if (!values.gstNo ){
      errors.gstNo = "Gst No is required!";
    }
    if (!values.fromDate) {
      errors.fromDate = "Date is required!";
    } 
    if (values.documents.length == 0) {
      errors.documents = "File is required!";
    }
    return errors;
  }
  invoiceSubmit = async (value) => {
    this.setState({ documentArray: value }, () => {
    });
    await this.defaultBillConcat();
    let raise = this.state.raiseInvoice;
    if (this.state.bills > 0 || this.state.defaultCheckedBills.length>0 || this.state.billInfo.bill.length>0) {
      let values = {
        bills: this.state.bills,
        gstNo: this.state.raiseInvoice?.gstNo,
        fromDate: this.state.raiseInvoice?.FromDate,
        documents: this.state.documentArray,
      };
      console.log(values.documents.length,"value")
      this.setState({errorMessage:this.validateVal(values)})
      console.log(this.state.errorMessage,"messahe")
      if(Object.keys(this.state.errorMessage).length === 0 ){
      this.setState({ dataLoading: true });
      this.props.createInvoice(values, (res) => {
        if (res["result"] && res["message"] == "Invoice created successfully") {
          NotificationManager.success(res["message"], "Success");
          this.setState({ dataLoading: false ,defaultCheckedBills:[]},()=>{
            this.reset();
          });
          this.props.history.push({
            pathname: "/generateInvoice",
            state: { invoiceId: res["result"]._id },
          });
        } else {
          NotificationManager.error(res["message"], "Error");
          this.setState({ dataLoading: false });
          this.props.history.push("/invoiceGeneration");
        }
      });
    }
    }
  };
  closeInvoiceModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  invoiceModal = (r) => {
    let destination = r.destination;
    let id = r.id;
    let indentId = r.indentId;
    let invoice = r.invoice;
    let isOpen = r.isOpen;
    let select = r.select;
    let source = r.select;
    let totalCost = r.totalCost;
    let tripId = r.tripId;
    let charges = r.charges;
    let vehicle = r.vehicle;
    let status = r.status;
    let startTime = r.startTime;
    let leadTime = r.leadTime;
    let distance = r.distance;
    let calculations = r.calculations;

    this.setState({
      invoiceDet: {
        charges,
        destination,
        id,
        indentId,
        invoice,
        isOpen,
        select,
        source,
        totalCost,
        tripId,
        vehicle,
        status,
        startTime,
        leadTime,
        distance,
        calculations,
      },
    });
    this.closeInvoiceModal();
  };


  reset = () => {

    this.setState(
      {
        FromDate: "",
        ToDate: "",
        stateFilter: "",
        billInfo: { bill: [] },
        billFreightCheck: [],
        raiseInvoiceDisable: true,
        stateValue: "",
        documentArray:[],
        defaultCheckedBills:[]
      },
      () => {
        offset = 0;
        totCnt = 0;
        totPages = 0;
        fromVal = 0;
        toVal = 0;
        defaultCheckedBills=[]

        this.props.clearAllBills();
      }
    );
  };

  imageReset =()=>{
    this.setState({documentArray:[]})
  }

  checkbox = (item) => {

    // if(defaultCheckedBills.length>0 || this.state.billInfo.bill.length>0){
    //   this.setState({raiseInvoiceDisable:false})
    // }else{
    //   this.setState({raiseInvoiceDisable:true})
    // }
   if (!defaultCheckedBills.includes(item._id) && item.type !== "freight") {
    defaultCheckedBills.push(item._id);
    console.log(defaultCheckedBills,"bill")
      if(defaultCheckedBills.length>0){
        this.setState({raiseInvoiceDisable:false})
      }else{
        this.setState({raiseInvoiceDisable:true})
      }
      }

    if (item.type !== "freight") {
      return (
        <form>
          <div className="form-check m-0">
            <Input
              type="checkbox"
              name="bill"
              value={item._id}
              id="flexCheckDefault"
              className="check-field mx-0 my-0"
              defaultChecked={true}
              disabled
              inputprops={{ "aria-label": "controlled" }}
            />
          </div>
        </form>
      )
    } else

    return (

         <form>
          <div className="form-check m-0">
            <Input
              type="checkbox"
              value={item._id}
              id="flexCheckDefault"
              name="bill"
              className="check-field mx-0 my-0"
              defaultChecked={this.state.billInfo.bill.includes(item._id)}
              onChange={(e) => {
                this.addBills(e);
              }}
              inputprops={{ "aria-label": "controlled" }}
            />
          </div>
        </form>
      );
  };
  addBills = (e) => {
    const { value, checked } = e.target;
    const { bill } = this.state.billInfo;
    console.log(value, checked, bill, "con");
    // Case 1 : The user checks the box
    if (checked) {
      if (!bill.includes(value)) {
        this.setState(
          {
            billInfo: {
              bill: [...bill, value],
              response: [...bill, value],
            },
          },
          () => {
            if (bill?.length < 0) {
              this.setState({ raiseInvoiceDisable: true });
            } else {
              this.setState({ raiseInvoiceDisable: false }, () => {});
            }
          }
        );
      }
    }

    // Case 2  : The user unchecks the box
    else {
      this.setState(
        {
          billInfo: {
            bill: bill.filter((e) => e !== value),
            response: bill.filter((e) => e !== value),
          },
        },
        () => {
          if (bill?.length <= 1) {
            this.setState({ raiseInvoiceDisable: true });
          } else {
            this.setState({
              raiseInvoiceDisable: false,
            });
          }
        }
      );
    }
  };

  raiseChange = (e) => {
    let { name, value } = e.target;
    let raise = this.state.raiseInvoice;
    raise[name] = value;
    this.setState({ raiseInvoice: raise });
  };

  handlePdf = () => {
    this.setState({ showpdf: !this.state.showpdf });
  };
  getAllData = () => {
    this.handleStateChange(this.state.stateFilter);
  };
  handleChangePage = (event, val) => {
    let { FromDate, ToDate, stateFilter } = this.state;
    let offsets = (val - 1) * process.env.REACT_APP_PERPAGE;
    let values = { limit: 10, offset: offsets };
    this.setState({dataLoading:true})
    this.props.fetchBills(values, FromDate, ToDate, stateFilter.value,()=>{
      this.setState({dataLoading:false})
    });
  };
  handleResetInvoiceInput = ()=>{
   this.setState({
     resetDate: false,
     documentArray:[],
     errorMessage:{},
    raiseInvoice: {
      gstNo: "",
      FromDate: "",
    },
   },()=>{
     this.setState({
       resetDate:true
     })
   })

  }
  render() {
    console.log("this.state.raiseInvoice.FromDate",this.state.raiseInvoice.FromDate)
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let mode = localStorage.getItem('Theme')?localStorage.getItem('Theme'):null
    darkMode = ((mode === "dark__mode") ? true : false);

    
    const { stateFilter, FromDate, ToDate, loading } = this.state;
    const { bills } = this.props;
    offset = bills?.offset;
    totCnt = bills?.length;
    fromVal = totCnt !== 0 ? offset + 1 : fromVal;
    toVal = bills?.offset + bills?.docs.length;
    totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);

    let bill = bills.docs.map((e) => ({
      id: e._id,
      bill: e.type ? this.typeDisplay(e.type) : "----",
      tripId: e.trip?.tripId || "N/A",
      indentId: e.indentId ? e.indentId : "-----",
      source:
        e.type === "indent_rejection" || e.source?.description !== undefined
          ? e.source?.description
          : e.trip?.source?.label || "-----",
      destination:
        e.type === "indent_rejection" ||
        e.destination?.description !== undefined
          ? e.destination?.description
          : e.trip?.destination?.label || "-----",
      totalCost: e.calculations ? e.calculations.netPayble : "----",
      charges: e.charges,
      vehicle: e.trip?.vehicle,
      status: e.trip?.subStatus,
      startTime: e.trip?.startTime,
      leadTime: e.trip?.leadTime,
      distance: e.trip?.distance,
      calculations: e.calculations,
      select: e.type ? this.checkbox(e) : "No Type",
      date: moment(e.createdAt).format("DD/MM/YYYY"),
    }));

    if (Array.isArray(bill)) {

      bill.map((r, i) => {
        if (r.bill === "Freight") {
          r.actions = (
            <div className="dropdown">
              <Button className="iconButtons dropbtn actionButtonsDark">
                <img
                  style={{ margin: "-4px" }}
                  src={darkMode ? actionVerticleDotsDark : actionVerticleDots}
                  alt="actions"
                />
              </Button>
              <div className="dropdown-content contentDark">
                <div
                  className="action-button actionDspan"
                  onClick={() => {
                    this.invoiceModal(r);
                  }}
                >
                  Edit Details
                </div>
              </div>
            </div>
          );
        } else {
          r.actions = (
            <div className="dropdown">
              <Button
                className="iconButtons dropbtn actionButtonsDark"
                disabled
              >
                <img
                  style={{ margin: "-4px" }}
                  src={darkMode ? actionVerticleDotsDark : actionVerticleDots}
                  alt="actions"
                />
              </Button>
            </div>
          );
          r.clickEvent = (params) => this.handleChangeRow(params);
        }
      });
    }
    let Aggreement = bill;
    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#123abc"} />
          </Col>
        </div>
      );
    }

    return (
      <div /* className="page-content" */>
        {getLocalStorage()}
        <RaiseInvoceModal
          isOpen={this.state.raiseinvoiceModal}
          toggle={this.openRaiseInvoiceModal}
          submit={this.invoiceSubmit}
          onChange={this.raiseChange}
          FromDate={this.state.raiseInvoice.FromDate}
          gstNo={this.state.raiseInvoice.gstNo}
          documentAssigner={this.documentAssigner}
          mode={darkMode}
          handleResetInvoiceInput={this.handleResetInvoiceInput}
          resetDate = {this.state.resetDate}
          errorMessage= {this.state.errorMessage}
          documentArray={this.state.documentArray}
          imageReset ={this.imageReset}
        />
        <InvoiceModal
          isOpen={this.state.isOpen}
          toggle={this.closeInvoiceModal}
          invoiceDet={this.state.invoiceDet}
          getAllData={this.getAllData}
          mode={darkMode}
        />
        {/* <Container fluid> */}
          {/* <div> */}
            <QuickFilters
              name="stateFilter"
              handleStateChange={this.handleStateChange}
              stateListOption={stateList}
              stateFilter={stateFilter}
              handleChange={this.handleChange}
              reset={this.reset}
              mode={darkMode}
            />
          {/* </div> */}

          {/* <Row>
            <Col xs={12}> */}
              {Aggreement?.length > 0 ? (
                <>
                  {/* <Card
                    style={{
                      marginTop: "-8px",
                      backgroundColor: darkMode ? "#5E40FF" : "#ffffff",
                    }}
                  > */}
                    <Index
                      {...this.props}
                      headers={AdminHeaderData}
                      data={Aggreement}
                      searchBar={searchBar}
                      rowHeight={"56px"}
                      getAllData={this.getAllData}
                      dataLength={this.props.bills?.docs?.length}
                      fetchId={this.props?.fetchId}
                      dataLoading={this.state.dataLoading}
                    />
                  {/* </Card> */}
                  {totCnt !== 0 && !this.state.dataLoading? (
                    <Row
                      xs={12}
                      style={{
                        color: "#0C0C0C",
                        backgroundColor: darkMode ? "#2E2E46" : "#ffffff",
                      }}
                      className="mb-2 paginationRow"
                    >
                      <Col
                        className="mt-0 mobileDisplay"
                        xs={12}
                        style={{ fontSize: "12px",fontFamily:"Roboto" }}
                      >
                        <div className="float-right d-flex flex-row pt-2 pb-2">
                          <div>
                            <Button
                              color="primary"
                              size="sm"
                              style={{
                                marginRight: "25px",
                                backgroundColor: "#05284E",
                                width: "102px",
                              }}
                              onClick={() => this.openRaiseInvoiceModal()}
                              disabled={
                                this.state.stateFilter.label === "All" ||
                                this.state.raiseInvoiceDisable
                              }
                            >
                              {userLang?.invoice?.RAISE_INVOICE}
                            </Button>
                          </div>
                          <div>
                            <Button
                              color="success"
                              size="sm"
                              style={{ width: "87px" }}
                              onClick={() => this.reset()}
                            >
                              {" "}
                              {userLang?.common?.RESET}
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} sm={5} md={4} className="span-col-pagenation">
                        <div className="float-left">
                          <Pagination
                            className="desktop-pagination"
                            size="medium"
                            shape="rounded"
                            page={offset / 10 + 1}
                            count={totPages}
                            color="primary"
                            onChange={this.handleChangePage}
                            defaultPage={1}
                            siblingCount={1}
                          />
                          <Pagination
                            className="mobile-pagination"
                            size="small"
                            shape="rounded"
                            page={offset / 10 + 1}
                            count={totPages}
                            color="primary"
                            onChange={this.handleChangePage}
                            defaultPage={1}
                            siblingCount={1}
                          />
                        </div>
                      </Col>
                      <Col
                        className="mt-2"
                        xs={6}
                        md={6}
                        style={{ fontSize: "13px" }}
                      >
                        <span className="light__span">
                          Showing {fromVal} to {toVal} of {totCnt} entries
                        </span>
                      </Col>
                      <Col
                        className="mt-0 desktopDisplay"
                        xs={2}
                        style={{ fontSize: "13px" }}
                      >
                        {/* <div
                          className="float-left"
                          style={{ paddingLeft: "15px" }}
                        > */}
                        <Row>
                          <Col>
                            <Button
                              color="primary"
                              size="sm"
                              style={{
                                marginRight: "4px",
                                backgroundColor:darkMode ? "#5E40FF":"#05284E",
                                width: "102px",
                              }}
                              onClick={() => this.openRaiseInvoiceModal()}
                              disabled={
                                this.state.stateFilter.label === "All" ||
                                this.state.raiseInvoiceDisable
                              }
                            >
                              {userLang?.invoice?.RAISE_INVOICE}
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              color="success"
                              size="sm"
                              style={{ width: "87px" }}
                              onClick={() => this.reset()}
                            >
                              {" "}
                              {userLang?.common?.RESET}
                            </Button>{" "}
                          </Col>
                        </Row>
                        {/* </div> */}
                      </Col>
                    </Row>
                  ) : null}
                </>
              ) : (
                <>
                  {/* <Card style={{ marginTop: "-8px" }}> */}
                    <Index
                      {...this.props}
                      headers={AdminHeaderData}
                      data={Aggreement}
                      searchBar={searchBar}
                      rowHeight={"56px"}
                      getAllData={this.getAllData}
                      dataLength={this.props.bills?.docs?.length}
                      fetchId={this.props?.fetchId}
                      dataLoading={this.state.dataLoading}
                    />
                   {/* </Card> */}
                  <div
                    style={{
                      color: "red",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      className="alertStyleGenerator light__span">
                      *{userLang?.invoice?.SELECT_STATE_FUNCTIONALITY}
                    </span>
                  </div>
                </>
              )}
            {/* </Col>
          </Row> */}
          {totCnt == 0 && !this.state.dataLoading || totCnt == undefined ? (
            <>
              <Row
                xs={12}
                style={{ color: "#0C0C0C" }}
                className="mb-2 paginationRow"
              >
                <Col
                  className="mt-0 mobileDisplay"
                  xs={12}
              
                  style={{ fontSize: "12px", fontFamily:"Roboto" }}
                >
                  <div
                    className="float-right d-flex flex-row pt-2 pb-2"
                    // style={{ paddingLeft: "15px" }}
                  >
                    <div>
                      <Button
                        color="primary"
                        size="sm"
                        style={{
                          marginRight: "25px",
                          backgroundColor: "#05284E",
                        }}
                        onClick={() => this.openRaiseInvoiceModal()}
                        disabled={
                          this.state.stateFilter.label === "All"
                            ? true
                            : false || this.state.raiseInvoiceDisable
                        }
                      >
                        {userLang?.invoice?.RAISE_INVOICE}
                      </Button>
                    </div>
                    <div>
                      <Button
                        color="success"
                        size="sm"
                        style={{ width: "87px" }}
                      >
                        {" "}
                        {userLang?.common?.RESET}
                      </Button>{" "}
                    </div>
                  </div>
                </Col>
                <Col xs={6} sm={5} md={4} className="span-col-pagenation">
                  <div className="float-left">
                    <Pagination
                      className="desktop-pagination"
                      size="medium"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                    <Pagination
                      className="mobile-pagination"
                      size="small"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                  </div>
                </Col>
                <Col
                  className="mt-2"
                  xs={6}
                  md={6}
                  style={{ fontSize: "13px" }}
                >
                  <span className="light__span">Showing 0 to 0 of 0 entries</span>
                </Col>
                <Col
                  className="mt-0 desktopDisplay"
                  xs={10}
                  md={2}
                  style={{ fontSize: "13px" }}
                >
                  <div
                    className="float-left d-flex flex-row"
                    style={{ paddingLeft: "15px" }}
                  >
                    <div>
                      <Button
                        color="primary"
                        size="sm"
                        style={{
                          marginRight: "25px",
                          backgroundColor: "#05284E",
                        }}
                        onClick={() => this.openRaiseInvoiceModal()}
                        disabled={
                          this.state.stateFilter.label === "All"
                            ? true
                            : false || this.state.raiseInvoiceDisable
                        }
                      >
                        {userLang?.invoice?.RAISE_INVOICE}
                      </Button>
                    </div>
                    <div>
                      <Button
                        color="success"
                        size="sm"
                        style={{ width: "87px" }}
                      >
                        {" "}
                        {userLang?.common?.RESET}
                      </Button>{" "}
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          ) : null}
        {/* </Container> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bills: state.Billing.billing.bills,
  fetchId: state.Billing.billing.fetchId,
});

export default connect(mapStateToProps, {
  fetchBills,
  createInvoice,
  clearAllBills,
})(InvoiceGeneration);
