import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  TabPane,
  Label,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { connect } from "react-redux";
import { setRouteDetails } from "../../../../store/indent/actions";
import { fetchHops, createIndent } from "../../../../store/indent/actions";

// const city = [
//   { value: "Bangalore", label: "Bangalore" },
//   { value: "Mumbai", label: "Mumbai" },
//   { value: "Delhi", label: "Delhi" },
// ];

class RouteDetailTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeFormvalues: {
        loadingPoint: "",
        destination: [],
        reportTime: "",
        checkDestinationOmit: false,
      },
    };
  }

  routeFormvalues = {
    loadingPoint: "dummy",
    destination: [],
    reportTime: "",
    checkDestinationOmit: false,
  };

  city = [];

  componentDidMount = () => {
    this.props.fetchHops((res) => {
      if (res.length > 0) {
        res.forEach((obj) => {
          this.city.push({
            label: obj.hopName,
            value: obj.hopName,
          });
        });
      }
    });
  };

  valueAssigner = (e, value) => {
    let locArr = [],
      i = 0;
    if (value === "destination") {
      if (e !== null)
        e.map((obj) => {
          locArr[i] = obj.value;
          i++;
        });
      this.routeFormvalues.destination = locArr;
      this.setState((prevState) => ({
        routeFormvalues: { ...prevState.routeFormvalues, [value]: locArr },
      }));
    } else if (value === "checkDestinationOmit") {
      let val = e.target.checked;
      this.routeFormvalues.checkDestinationOmit = val;
      this.setState((prevState) => ({
        routeFormvalues: {
          ...prevState.routeFormvalues,
          [value]: val,
        },
      }));
    } else {
      this.routeFormvalues[value] = e.value;
      this.setState((prevState) => ({
        routeFormvalues: {
          ...prevState.routeFormvalues,
          [value]: e.value,
        },
      }));
    }
    this.props.checkValid(this.routeFormvalues);

    this.props.setRouteDetails(this.routeFormvalues);
  };

  reset = () => {
    let routeFormvalues = {
      loadingPoint: "",
      destination: "",
      reportTime: "",
      checkDestinationOmit: false,
    };
    this.routeFormvalues = routeFormvalues;
    this.setState({ routeFormvalues: routeFormvalues });
    this.props.setRouteDetails(routeFormvalues);
    this.props.checkValid(routeFormvalues);
  };

  handleChangeDate = (date) => {
    this.routeFormvalues.reportTime = date;
    this.setState((prevState) => ({
      routeFormvalues: {
        ...prevState.routeFormvalues,
        reportTime: date,
      },
    }));
    this.props.checkValid(this.routeFormvalues);
  };

  render() {
    const { routeFormvalues } = this.state;
    return (
      <React.Fragment>
        <TabPane tabId={1}>
          <Form>
            <Row>
              <Col md="12">
                <FormGroup className="select2-container">
                  <Label className="col-form-label">Loading Point</Label>
                  <Select
                    value={
                      this.city.find((x) => {
                        return (
                          x.value === this.state.routeFormvalues.loadingPoint
                        );
                      }) || { value: "", label: "" }
                    }
                    label="Single select"
                    options={this.city}
                    onChange={(e) => this.valueAssigner(e, "loadingPoint")}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup className="select2-container">
                  <Label className=" col-form-label">Destination</Label>
                  <Select
                    isMulti
                    value={this.city.filter((x) =>
                      routeFormvalues.destination.includes(x.value)
                    )}
                    options={this.city}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => this.valueAssigner(e, "destination")}
                    isDisabled={this.state.routeFormvalues.checkDestinationOmit}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      initialV
                      onChange={(e) => {
                        this.valueAssigner(e, "checkDestinationOmit");
                      }}
                      defaultChecked={routeFormvalues.checkDestinationOmit}
                    />{" "}
                    Fetch from Orders
                  </Label>
                </FormGroup>
              </Col>
              <Col md="12" className="mb-6">
                <FormGroup className="select2-container">
                  <Label className="" htmlFor="example-datetime-local-input">
                    Report Time
                  </Label>
                  <Datetime
                    value={routeFormvalues.reportTime || new Date("")}
                    onChange={this.handleChangeDate}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <div className="mb-2 mt-2 m5 text-center btn_indene_style">
                  <Link>
                    <Button className="m-2 p-2 secondary" onClick={this.reset}>
                      Reset
                    </Button>
                  </Link>
                  <Link></Link>
                </div>
              </Col>
            </Row>
          </Form>
        </TabPane>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  indents: state.Indent.indents,
});

export default connect(mapStateToProps, {
  setRouteDetails,
  fetchHops,
  createIndent,
})(RouteDetailTab);
