// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { types } from "./actionTypes";

import { getApi, postApi, putApi, deleteApi } from "../../helpers/auth_helper";
import { getDataGroupBy } from "rsuite/esm/utils";
import { isExportAssignment } from "typescript";

function* workfetchOntimeDeliveryReport({ values, callback }) {
  console.log("values", values);
  let offset = values?.offset ? values?.offset : 0;
  let limit = values?.limit ? values?.limit : process.env.REACT_APP_PERPAGE;
  let transpoterId = values?.transpoterId ? values.transpoterId : "";
  let startDate = values?.startDate ? values?.startDate : "";
  let endDate = values?.endDate ? values?.endDate : "";
  let isExport = values?.isExport ? values?.isExport : false;
  try {
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/report/fetch/onTimeDeliveryReport?offset=` +
      offset +
      `&limit=` +
      Number(limit) +
      `&transporterId=` +
      transpoterId +
      `&startDate=` +
      startDate +
      `&endDate=` +
      endDate +
      `&isExport=` +
      isExport;
    const response = yield call(getApi, uri);
    localStorage.setItem("OntimeDeliveryReports", JSON.stringify(response));
    callback(response);
    yield put({
      type: types.ON_TIME_DELIVERY_REPORT_SUCCESS,
      payload: response,
    });
    localStorage.setItem("VehicleplacementData", JSON.stringify(response));
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch posts.", error);
  }
}

function* workfetchOntimeDeliveryReportExport({ values, callback }) {
  console.log("values", values);
  let offset = values?.offset ? values?.offset : 0;
  let limit = values?.limit ? values?.limit : process.env.REACT_APP_PERPAGE;
  let transpoterId = values?.transpoterId ? values.transpoterId : "";
  let startDate = values?.startDate ? values?.startDate : "";
  let endDate = values?.endDate ? values?.endDate : "";
  let isExport = values?.isExport ? values?.isExport : false;
  try {
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/report/fetch/onTimeDeliveryReport?offset=` +
      offset +
      `&limit=` +
      Number(limit) +
      `&transporterId=` +
      transpoterId +
      `&startDate=` +
      startDate +
      `&endDate=` +
      endDate +
      `&isExport=` +
      isExport;
    const response = yield call(getApi, uri);
    callback(response);
    yield put({
      type: types.ON_TIME_DELIVERY_REPORT_EXPORT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.countlog("Request failed! Could not fetch posts.", error);
  }
}

/**
 * Watchers
 */

export function* watchfetchOntimeDeliveryReport() {
  yield takeEvery(types.ON_TIME_DELIVERY_REPORT, workfetchOntimeDeliveryReport);
}
export function* watchfetchOntimeDeliveryReportExport() {
  yield takeEvery(
    types.ON_TIME_DELIVERY_REPORT_EXPORT,
    workfetchOntimeDeliveryReportExport
  );
}

function* OnTimeDeliveryReportSaga() {
  yield all([
    fork(watchfetchOntimeDeliveryReport),
    fork(watchfetchOntimeDeliveryReportExport),
  ]);
}

export default OnTimeDeliveryReportSaga;
