import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Button,
  Input,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Datetime from "react-datetime";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { createTrip, fetchHops } from "../../store/trip/actions";
import { fetchAvailDrivers } from "../../store/driver/actions";
import {
  fetchProductNames,
  fetchProductNamesByOrderId_DealerCode,
  getOrdersByPagination,
  fetchOrder,
} from "../../store/order/actions";
import { fetchAvailVehicles } from "../../store/vehicle/actions";
import { MoonLoader } from "react-spinners";

class TripForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onSubmitDisable: false,
      key: "ExternalOrderId",
      breadcrumbItems: [
        { title: "Trip", link: "trips" },
        { title: "Add Trip", link: "#" },
      ],
      destinationDisable: false,
      loading: true,
      menuIsOpen: false,
      isSearchEnable: false,
      form: {
        orderId: "",
        dealerCode: "",
        tripType: "",
        startingLocation: "",
        destination: "",
        driverLicNo: "",
        vehicleRegNo: "",
        tripOrderDetails: [],
        orderDetails: [],
        startTime: new Date(),
      },
      formErrors: {
        tripType: "",
        startingLocation: "",
        destination: "",
        driverLicNo: "",
        vehicleRegNo: "",
        tripOrderDetails: [],
        startTime: "",
        orderDetails: [],
      },
      prodLists: [],
      hops: [],
    };
    this.tripTypeArr = [
      this.initialVal("Trip Type"),
      { value: "Material Transfer", label: "Material Transfer" },
      { value: "Last Mile", label: "Last Mile" },
    ];
    this.hops = [this.initialVal("Location")];
    this.startingLocationArr = [
      this.initialVal("starting Location"),

      { value: "Bangalore Factory", label: "Bangalore Factory" },
      { value: "Bangalore Warehouse", label: "Bangalore Warehouse" },
    ];
    this.initialArr = [
      this.initialVal("destination"),
      { value: "Bangalore Warehouse", label: "Bangalore Warehouse" },
      { value: "Bangalore Dealer", label: "Bangalore Dealer" },
    ];
    this.destinationArr = this.initialArr;
    this.tripStatusArr = [
      this.initialVal("status"),
      { value: "active", label: "active" },
      { value: "inactive", label: "inactive" },
    ];

    this.vehicleRegNoArr = [this.initialVal("Vehicles")];
    this.driversList = [this.initialVal("Driver")];
    this.prodList = [];
  }
  initialVal = (val) => {
    return { label: "Select " + val, value: "", isDisabled: true };
  };
  valid = (current) => {
    var yesterday = moment().subtract(1, "day");
    return current.isAfter(yesterday);
  };
  componentDidMount() {
    this.props.fetchAvailDrivers((res) => {
      if (res.length > 0) {
        if (this.driversList.length > 1)
          this.driversList = this.driversList.slice(0, 1);

        res.forEach((ele) => {
          this.driversList.push({
            label: ele.userId?.fullName + " (" + ele.userId?.phone + ")",
            id: ele._id,
            value: ele._id,
          });
        });
      }
    });
    // this.props.fetchProductNames((res) => this.setProductName(res));
    this.props.getOrdersByPagination((res) => this.setProductName(res.docs));
    if (/true/i.test(process.env.REACT_APP_Kurlon) === false) {
      this.props.fetchAvailVehicles((res) => {
        if (res.length > 0) {
          if (this.vehicleRegNoArr.length > 1)
            this.vehicleRegNoArr = this.vehicleRegNoArr.slice(0, 1);
          res.forEach((ele) => {
            this.vehicleRegNoArr.push({
              id: ele._id,
              label: ele.VehicleRegistrationNumber,
              value: ele._id,
            });
          });
        }
      });
    }
    this.props.fetchHops((res) => {
      if (res?.length > 0) {
        this.hops = [this.initialVal("Location")];
        res.forEach((ele) => {
          this.hops.push({
            label: ele.hopName,
            value: {
              name: ele.hopName,
              latitude: ele.cordinates ? ele.cordinates[0] : null,
              longitude: ele.cordinates ? ele.cordinates[1] : null,
            },
          });
        });
        this.setState({ hops: this.hops });
      }
      setTimeout(this.setState({ loading: false }), 4000);
    });
  }

  setProductName = (res) => {
    if (res?.length > 0) {
      this.prodList = [];
      res.forEach((ele) => {
        const dealer = ele.DealerCode ? ele.DealerCode.userId?.fullName : "-";
        this.prodList.push({
          label:
            ele.ProductName +
            "( External OrderId: " +
            ele.ExternalOrderId +
            ", Dealer : " +
            dealer +
            " )",
          item:
            ele.ProductName +
            "(Price: " +
            ele.ProductPrice +
            ", Quantity: " +
            ele.Quantity +
            " )",
          value: ele._id,
        });
      });
      this.setState({ prodLists: this.prodList }, () => {
        setTimeout(this.setState({ loading: false }), 4000);
      });
    } else {
      this.prodList = [];
      this.setState({ prodLists: this.prodList });
    }
  };

  validateField = (name, value, refValue) => {
    let errorMsg = null;
    const { form } = this.state;

    switch (name) {
      case "tripType":
        if (!value) errorMsg = "Please Select Trip Type.";

        break;
      case "startingLocation":
        if (!value) errorMsg = "Please Select Starting Location.";
        break;
      case "destination":
        if (!value && form.tripType !== "Last Mile")
          errorMsg = "Please Select Destination.";
        if (value === form.startingLocation && form.tripType !== "Last Mile")
          errorMsg = "Starting Location and Destination should be different";
        break;
      case "tripStatus":
        if (!value) errorMsg = "Please Select Trip Status.";
        break;
      // case "vehicleRegNo":
      //   if (!value) errorMsg = "Please Select Vehicle.";

      //   break;
      case "replacementVehicle":
        if (!value) errorMsg = "Please select replacementVehicle.";
        else if (form.vehicleRegNo) {
          if (form.vehicleRegNo === form.replacementVehicle)
            errorMsg =
              "replacementVehicle No and Assigned vehicle should not be same.";
        }
        break;
      case "driverLicNo":
        if (!value) errorMsg = "Please Select Driver.";
        break;
      case "tripOrderDetails":
        if (!value) errorMsg = "Please Select Orders.";

        if (value && value.length === 0) errorMsg = "Please Select Orders.";
        break;

      case "startTime":
        if (!value) {
          errorMsg = "Please Select Start Time.";
        } else {
          let yesterday = moment().subtract(1, "day");
          let val = moment(form.startTime);

          let isValid = val.isAfter(yesterday);
          if (!isValid) {
            errorMsg = "Please select Valid Time.";
          }
        }
        break;
      default:
        break;
    }
    return errorMsg;
  };
  resetDestVal = (form) => {
    let formData = {
      startingLocation: form.startingLocation,
      destination: "",
      driverLicNo: form.driverLicNo,
      vehicleRegNo: form.vehicleRegNo,
      tripOrderDetails: form.tripOrderDetails,
      startTime: form.startTime,
      orderDetails: form.orderDetails,
      orderId: "",
      dealerCode: "",
    };

    this.setState({
      form: formData,
    });
  };
  validateForm = (form, formErrors, validateFunc) => {
    const errorObj = {};
    Object.keys(formErrors).map((x) => {
      let refValue = null;

      const msg = validateFunc(x, form[x], refValue);
      if (msg) errorObj[x] = msg;
    });
    return errorObj;
  };

  handleSubmit = () => {
    this.setState({ onSubmitDisable: true });
    const { form, formErrors } = this.state;
    const errorObj = this.validateForm(form, formErrors, this.validateField);
    if (Object.keys(errorObj).length !== 0) {
      this.setState({
        onSubmitDisable: false,
        formErrors: { ...formErrors, ...errorObj },
      });
      return false;
    }
    let updatedTrip = form.tripOrderDetails.map((trip) => {
      return trip.value;
    });

    let formVal = JSON.parse(JSON.stringify(form));
    delete formVal.tripOrderDetails;
    delete formVal.orderId;
    delete formVal.dealerCode;
    if (form.vehicleRegNo === "") {
      formVal.vehicleRegNo = null;
    }
    if (form.tripType === "Last Mile") {
      formVal["destination"] = { latitude: "", longitude: "", name: "" };
    }
    formVal.orderDetails = updatedTrip;
    this.props.createTrip(formVal, (res) => {
      if (
        res["trip"] &&
        res["trip"]["message"] &&
        res["trip"]["message"] === "Trip has been created successfully"
      ) {
        NotificationManager.success(res["trip"]["message"], "Success");
        window.history.replaceState(null, null, "/");
        this.props.history.push("/trips");
      } else {
        NotificationManager.error(res["trip"]["message"], "Error");
        this.setState({ onSubmitDisable: false });
        this.props.history.push("/trips");
      }
    });
    setTimeout(() => {
      this.setState({ onSubmitDisable: false });
      this.props.history.push("/trips");
    }, 3000);
  };

  reset = () => {
    let formData = {
      tripType: "",
      startingLocation: "",
      destination: "",
      driverLicNo: "",
      vehicleRegNo: "",
      tripOrderDetails: [],
      startTime: "",
      orderDetails: [],
    };

    this.setState({
      form: formData,
    });
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "tripType" && value === "Last Mile") {
      let { form } = this.state;
      form["destination"] = "";
      this.setState({ form, destinationDisable: true });
    } else if (name === "tripType" && value === "Material Transfer") {
      this.setState({ destinationDisable: false });
    } else if (name === "orderId" || name === "dealerCode") {
      this.setState(
        { [this.state.form[e.target.name]]: e.target.value },
        () => {
          const { orderId, dealerCode } = this.state.form;
          if (this.state.isSearchEnable) {
            if (
              (orderId.trim() === "" && dealerCode.trim() === "") ||
              (dealerCode.trim() === "" && orderId.trim() === "")
            ) {
              this.props.getOrdersByPagination((res) => this.setProductName(res.docs));
              this.setState({ isSearchEnable: false });
            }
          }
        }
      );
    }
    const { form, formErrors } = this.state;
    let formObj = {};

    formObj = {
      ...form,
      [name]: value,
    };

    this.setState({ form: formObj }, () => {
      if (!Object.keys(formErrors).includes(name)) return;
      let formErrorsObj = {};

      const errorMsg = this.validateField(
        name,
        name === "language" ? this.state.form["language"] : value
      );
      formErrorsObj = { ...formErrors, [name]: errorMsg };
      this.setState({ formErrors: formErrorsObj });
    });
  };
  changeDateTime = (e) => {
    this.setState({ form: { ...this.state.form, startTime: e } });
  };

  handleSearch = () => {
    const { dealerCode } = this.state.form;
    let searchString = "";
    // if (orderId.trim() !== "") searchString = `OrderdId=${orderId.trim()}`;
    if (dealerCode.trim() !== "")
      searchString = `${
        searchString.trim() !== "" ? `${searchString}&` : ""
      }DealerCode=${dealerCode.trim()}`;

    if (searchString.trim() !== "") {
      this.props.fetchProductNamesByOrderId_DealerCode(
        (res) => this.setProductName(res),
        searchString, this.state.key
      );
      this.setState({ isSearchEnable: true });
    }
  };

  changed = (e) =>{
    let key = e.target.value;
    this.setState({key})
  }

  render() {
    const { fullScreen } = this.props;
    const { loading, rows } = this.state;
    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#123abc"} />
          </Col>
        </div>
      );
    }
    const { form, formErrors, onSubmitDisable } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Add Trip"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <form style={{ marginBottom: "8vh" }}>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label>Filter By</Label>
                            <Input
                              type="select"
                              name="key"
                              id="key"
                              placeholder="key"
                              onChange={(event) => this.changed(event)}
                              autoComplete="off"
                            >
                              <option value = "ExternalOrderId">External Order Id</option>
                              <option value = "dealerCode">Dealer Code</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="5">
                          <FormGroup className="select2-container">
                            <Label>Search</Label>
                            <Input
                              type="text"
                              name="dealerCode"
                              id="dealerCode"
                              placeholder="Enter Dealer Code here..."
                              onChange={(event) => this.handleChange(event)}
                              autoComplete="off"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="1" className="d-flex flex-row align-items-end">
                          <FormGroup>
                            <Button
                              disabled={
                                !(
                                  form.orderId.trim() !== "" ||
                                  form.dealerCode.trim() !== ""
                                )
                              }
                              type="button"
                              color="primary"
                              onClick={() => this.handleSearch()}
                            >
                              Search
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">Trip Type</Label>
                            <Select
                              name="tripType"
                              value={this.tripTypeArr.find(
                                (x) => x.value === form.tripType
                              )}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "tripType",
                                    value: e.value,
                                  },
                                })
                              }
                              options={this.tripTypeArr}
                              classNamePrefix="select2-selection"
                            />
                            {formErrors.tripType && (
                              <span className="err">{formErrors.tripType}</span>
                            )}
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="control-label required-field">
                              Orders
                            </Label>
                            <Select
                              value={form.tripOrderDetails}
                              isMulti={true}
                              menuIsOpen={this.state.menuIsOpen}
                              onFocus={() =>
                                this.setState({ menuIsOpen: true })
                              }
                              onBlur={() =>
                                this.setState({ menuIsOpen: false })
                              }
                              onChange={(e) => {
                                this.handleChange({
                                  target: {
                                    name: "tripOrderDetails",
                                    value: e,
                                  },
                                });
                              }}
                              options={this.state.prodLists}
                              classNamePrefix="select2-selection"
                            />
                            {formErrors.tripOrderDetails && (
                              <span className="err">
                                {formErrors.tripOrderDetails}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">
                              Starting Location
                            </Label>
                            <Select
                              name="startingLocation"
                              value={this.state.hops.find(
                                (x) => x.value === form.startingLocation
                              )}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "startingLocation",
                                    value: e.value,
                                  },
                                })
                              }
                              options={this.state.hops}
                              classNamePrefix="select2-selection"
                            />
                            {formErrors.startingLocation && (
                              <span className="err">
                                {formErrors.startingLocation}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">
                              Destination
                            </Label>
                            <Select
                              name="destination"
                              isDisabled={this.state.destinationDisable}
                              value={this.state.hops.find(
                                (x) => x.value === form.destination
                              )}
                              onChange={(e) => {
                                this.handleChange({
                                  target: {
                                    name: "destination",
                                    value: e.value,
                                  },
                                });
                              }}
                              options={this.state.hops}
                              classNamePrefix="select2-selection"
                            />
                            {formErrors.destination &&
                              !this.state.destinationDisable && (
                                <span className="err">
                                  {formErrors.destination}
                                </span>
                              )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">Start Time</Label>
                            <Datetime
                              value={form.startTime}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "startTime",
                                    value: e._d,
                                  },
                                })
                              }
                              closeOnSelect
                              dateFormat="DD-MM-YYYY"
                              isValidDate={this.valid}
                              defaultValue={new Date()}
                              input={true}
                            />
                            {formErrors.startTime && (
                              <span className="err">
                                {formErrors.startTime}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        {/true/i.test(process.env.REACT_APP_VEHICLE) ? (
                          <Col lg="6">
                            <FormGroup className="select2-container">
                              <Label className="control-label">Vehicles</Label>
                              <Select
                                value={this.vehicleRegNoArr.find(
                                  (x) => x.value === form.vehicleRegNo
                                )}
                                onChange={(e) => {
                                  this.handleChange({
                                    target: {
                                      name: "vehicleRegNo",
                                      value: e.value,
                                    },
                                  });
                                }}
                                options={this.vehicleRegNoArr}
                              />
                              {formErrors.vehicleRegNo && (
                                <span className="err">
                                  {formErrors.vehicleRegNo}
                                </span>
                              )}
                            </FormGroup>
                          </Col>
                        ) : (
                          <Col lg="6">
                            <FormGroup className="select2-container">
                              <Label className="required-field">Drivers</Label>
                              <Select
                                value={this.driversList.find(
                                  (x) => x.value === form.driverLicNo
                                )}
                                onChange={(e) =>
                                  this.handleChange({
                                    target: {
                                      name: "driverLicNo",
                                      value: e.value,
                                    },
                                  })
                                }
                                options={this.driversList}
                                classNamePrefix="select2-selection"
                              />
                              {formErrors.driverLicNo && (
                                <span className="err">
                                  {formErrors.driverLicNo}
                                </span>
                              )}
                            </FormGroup>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        {/true/i.test(process.env.REACT_APP_VEHICLE) ? (
                          <Col lg="6">
                            <FormGroup className="select2-container">
                              <Label className="required-field">Drivers</Label>
                              <Select
                                value={this.driversList.find(
                                  (x) => x.value === form.driverLicNo
                                )}
                                onChange={(e) =>
                                  this.handleChange({
                                    target: {
                                      name: "driverLicNo",
                                      value: e.value,
                                    },
                                  })
                                }
                                options={this.driversList}
                                classNamePrefix="select2-selection"
                              />
                              {formErrors.driverLicNo && (
                                <span className="err">
                                  {formErrors.driverLicNo}
                                </span>
                              )}
                            </FormGroup>
                          </Col>
                        ) : null}
                      </Row>

                      <FormGroup className="mb-0 text-center ">
                        <div>
                          <Button
                            disabled={onSubmitDisable}
                            type="reset"
                            color="secondary"
                            onClick={this.reset}
                          >
                            Reset
                          </Button>{" "}
                          <Button
                            disabled={onSubmitDisable}
                            type="button"
                            color="btn btn-primary"
                            className="mr-1"
                            onClick={this.handleSubmit}
                          >
                            Submit
                          </Button>
                        </div>
                      </FormGroup>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  trips: state.Trip.trips,
  drivers: state.Driver.drivers.availDrivers,
  productNames: state.Order.orders,
  vehicles: state.Vehicle.vehicles.avail_vehicles,
});

export default connect(mapStateToProps, {
  createTrip,
  fetchHops,
  fetchAvailDrivers,
  fetchProductNames,
  getOrdersByPagination,
  fetchOrder,
  fetchProductNamesByOrderId_DealerCode,
  fetchAvailVehicles,
})(TripForm);
