import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import "@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import { NotificationManager } from "react-notifications";
import Select from "react-select";
import { connect } from "react-redux";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { fetchDropPolicies, createRolePolicy } from "../../store/accessPolicy/actions";
import formatInput from '../InputFormatter';
import { MDBDataTable } from "mdbreact";

var data;

class RoleForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onSubmitDisable: false,
      breadcrumbItems: [
        { title: "Role", link: "roles" },
        { title: "Add Role", link: "#" },
      ],
      create: false,
      read: false,
      edit: false,
      delete: false,
      form: {
        role: "",
        policy: [],
        accessPolicies: []

      },
      formErrors: {
        role: "",
        policy: [],
        accessPolicies: [],
      },
      policyList : []

    };
    this.policyList = [];

  }
  initialVal = (val) => {
    return { label: "Select " + val, value: "", isDisabled: true };
  };
componentDidMount(){
  this.props.fetchDropPolicies((res)=>{
    let policyList  = [];
     res.map((ele)=>  { 
       if(ele.name==="indent"){
        if((/true/i).test(process.env.REACT_APP_INDENT)===false){
          // console.log("if1")

          return;
        }
        else{
          policyList.push({value:ele._id,label:ele.name});

        }
       }
       if(ele.name==="transporter"){

        if((/true/i).test(process.env.REACT_APP_TRANSPORTER)===false){
          // console.log("if2")

          return;
        }
        else{
          policyList.push({value:ele._id,label:ele.name});

        }
      }
       if(ele.name==="vehicle"){

        if((/true/i).test(process.env.REACT_APP_VEHICLE)===false){
          // console.log("if2")

          return;
        }
        else{
          policyList.push({value:ele._id,label:ele.name});

        }
      }
      else{
       policyList.push({"value": ele._id,"item": ele.name,"label":ele.name})
      }
       });
    this.setState({policyList});
  })
}
  validateField = ( name, value, refValue) => {
    let errorMsg = null;

    switch (name) {
      case "role":
        if (!value) errorMsg = "Please Enter role.";
        break;
      case "policy":
        if (value && value.length ==0) errorMsg = "Please Enter policy.";
        else if(!value) errorMsg = "Please Enter policy.";
        break;

      default:
        break;
    }
    return errorMsg;
  };

  validateForm = (form, formErrors, validateFunc) => {
    const errorObj = {};
    Object.keys(formErrors).map((x) => {
      let refValue = null;

      const msg = validateFunc(x, form[x], refValue);
      if (msg) errorObj[x] = msg;
    });
    return errorObj;
  };


  handleSubmit = () => {
    this.setState({ onSubmitDisable: true });
    const { form, formErrors } = this.state;

    const errorObj = this.validateForm(form, formErrors, this.validateField);
    if (Object.keys(errorObj).length !== 0) {
      this.setState({
        onSubmitDisable: false,
        formErrors: { ...formErrors, ...errorObj },
      });
      return false;
    }

    let formVal = {
      policies: form.accessPolicies,
      name: form.role
    };

    this.props.createRolePolicy(formVal, (res) => {
      if (
        res["code"]==="200"
      ) {
        NotificationManager.success("Role has been created successfully", "Success");
        this.props.history.push("/roles");
      } else {
        NotificationManager.error(res["message"], "Error");
        this.setState({ onSubmitDisable: false });
      }
    });
  };
  reset = () => {
    let formData = {
      role: "",
      policy: [],
      accessPolicies: [],
    };

    this.setState({
      form: formData,
    });
  };
  handleChange = (e) => {
    const { name, value, checked } = e.target;
    const { form, formErrors } = this.state;
    let formObj = {};

    formObj = {
      ...form,
      [name]: value,
    };
    this.setState({ form: formObj }, () => {
      if (!Object.keys(formErrors).includes(name)) return;
      let formErrorsObj = {};

      const errorMsg = this.validateField(
        name,
        name === "language" ? this.state.form["language"] : value
      );
      formErrorsObj = { ...formErrors, [name]: errorMsg };
      this.setState({ formErrors: formErrorsObj });
    });
  };

  trimSpace = (event, name) => {
    const { form } = this.state;
    let formObj = formatInput(event, name, form);
    this.setState({ form: formObj });
  }
  render() {
    const { form, formErrors, onSubmitDisable, policyList } = this.state;
    let rows = [];
    form.accessPolicies = [];
    if (form.policy && form.policy.length > 0){
      form.policy.forEach((ele, i) => {
        form.accessPolicies.push({
          id: ele.value,
          create: false,
          read: false,
          edit: false,
          delete: false,
          value: ele.label
        })
      
      });
      form.accessPolicies.forEach((ele, i) => {

      let create = "create" + i + ele.label;
      let read = "read" + i + ele.label;
      let edit = "edit" + i + ele.label;
      let del = "delete" + i + ele.label;
      rows.push(
        {
          name: ele.value,
          create: (
            <div className="custom-control custom-switch mb-2" dir="ltr">
              <Input type="checkbox" className="custom-control-input" id={create} 
                onClick={(e) => {
                  ele.create = !ele.create
                }} />
              <Label className="custom-control-label" htmlFor={create}></Label>
            </div>),
          read: (
            <div className="custom-control custom-switch mb-2" dir="ltr">
              <Input type="checkbox" className="custom-control-input" id={read}  onClick={(e) => {
                ele.read = !ele.read

              }} />
              <Label className="custom-control-label" htmlFor={read} ></Label>
            </div>),
          Update: (
            <div className="custom-control custom-switch mb-2" dir="ltr">
              <Input type="checkbox" className="custom-control-input" id={edit}  onClick={(e) => {
                ele.edit = !ele.edit

              }} />
              <Label className="custom-control-label" htmlFor={edit} ></Label>
            </div>),
          Delete: (
            <div className="custom-control custom-switch mb-2" dir="ltr">
              <Input type="checkbox" className="custom-control-input" id={del} onClick={(e) => {
                this.setState({ delete: !this.state.delete })
              }} />
              <Label className="custom-control-label" htmlFor={del} ></Label>
            </div>),

        })
    })
    }
      

    data = {
      columns: [
        {
          label: "Policies",
          field: "name",
          sort: "asc",
          width: 300,
        },
        {
          label: "Create",
          field: "create",
          sort: "asc",
          width: 300,
        },
        {
          label: "Read",
          field: "read",
          sort: "asc",
          width: 300,
        },
        {
          label: "Update",
          field: "Update",
          sort: "asc",
          width: 100,
        },
        {
          label: "Delete",
          field: "Delete",
          sort: "asc",
          width: 150,
        },
      ],
      rows: rows
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Add Role"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <form>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">Role Name</Label>

                            <Input
                              className="form-control"
                              type="text"
                              value={form.role}
                              onBlur={(e) => this.trimSpace(e, "role")}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "role",
                                    value: e.target.value,
                                  },
                                })
                              }
                              
                              placeholder="Role Name"
                            />
                            {formErrors.role && (
                              <span className="err">{formErrors.role}</span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">Policies</Label>
                            <Select
                              isMulti={true}
                              value={form.policy}
                              onChange={(e) => {
                                this.handleChange({
                                  target: {
                                    name: "policy",
                                    value: e,
                                  },
                                })

                              }

                              }
                              options={policyList}
                              classNamePrefix="select2-selection"
                            />

                            {formErrors.policy && (
                              <span className="err">{formErrors.policy}</span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <Card>
                            <CardBody>
                              <h4 className="card-title"> Trip Details </h4>

                              <MDBDataTable
                                responsive
                                striped
                                bordered
                                data={data}
                                paging={true}
                              />
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <FormGroup className="mb-0 text-center ">
                        <div>
                          <Button
                            disabled={onSubmitDisable}
                            type="reset"
                            color="secondary"
                            onClick={this.reset}
                          >
                            Reset
                          </Button>{" "}
                          <Button
                            disabled={onSubmitDisable}
                            type="button"
                            color="btn btn-primary"
                            className="mr-1"
                            onClick={this.handleSubmit}
                          >
                            Submit
                          </Button>
                        </div>
                      </FormGroup>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment >
    );
  }
}

export default connect(null, { fetchDropPolicies, createRolePolicy })(RoleForm);
