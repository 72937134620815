import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Alert,
  Input,
  Container,
  Label,
  FormGroup,
} from "reactstrap";
import "@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { NotificationManager } from "react-notifications";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import formatInput from "../InputFormatter";

// action
import { registerUser } from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// import images
// import logo from "../../assets/images/common-logo.png";
const logo = require("../../assets/images/" + process.env.REACT_APP_LOGO_IMAGE);

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onSubmitDisable: false,
      form: {
        roleType: "",
        fullName: "",
        email: "",
        phone: "",
        dealerCode: "",
        zipCode: "",
        city: "",
        state: "",
        address: "",
        password: "",
        cpassword: "",
      },
      formErrors: {
        roleType: "",
        fullName: "",
        email: "",
        phone: "",
        dealerBranchNo: "",
        dealerCode: "",
        zipCode: "",
        city: "",
        state: "",
        address: "",
        password: "",
        cpassword: "",
      },
    };
    this.roleTypes = [
      this.initialVal("role type"),
      {
        label: "Dealer",
        value: "Dealer",
      },
      {
        label: "Driver",
        value: "Driver",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
    ];
    this.statesList = [
      this.initialVal("state"),
      {
        label: "Andaman and Nicobar Islands",
        value: "Andaman and Nicobar Islands",
      },
      { label: "Andhra Pradesh", value: "Andhra Pradesh" },
      { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
      { label: "Assam", value: "Assam" },
      { label: "Bihar", value: "Bihar" },
      { label: "Chandigarh", value: "Chandigarh" },
      { label: "Chhattisgarh", value: "Chhattisgarh" },
      {
        label: "Dadra and Nagar Haveli and Daman and Diu",
        value: "Dadra and Nagar Haveli and Daman and Diu",
      },
      { label: "Delhi", value: "Delhi" },
      { label: "Goa", value: "Goa" },
      { label: "Gujarat", value: "Gujarat" },
      { label: "Haryana", value: "Haryana" },
      { label: "Himachal Pradesh", value: "Himachal Pradesh" },
      { label: "Jammu and Kashmir", value: "Jammu and Kashmir" },
      { label: "Jharkhand", value: "Jharkhand" },
      { label: "Karnataka", value: "Karnataka" },
      { label: "Kerala", value: "Kerala" },
      { label: "Ladakh", value: "Ladakh" },
      { label: "Lakshadweep", value: "Lakshadweep" },
      { label: "Madhya Pradesh", value: "Madhya Pradesh" },
      { label: "Maharashtra", value: "Maharashtra" },
      { label: "Manipur", value: "Manipur" },
      { label: "Meghalaya", value: "Meghalaya" },
      { label: "Mizoram", value: "Mizoram" },
      { label: "Nagaland", value: "Nagaland" },
      { label: "Odisha", value: "Odisha" },
      { label: "Puducherry", value: "Puducherry" },
      { label: "Punjab", value: "Punjab" },
      { label: "Rajasthan", value: "Rajasthan" },
      { label: "Sikkim", value: "Sikkim" },
      { label: "Tamil Nadu", value: "Tamil Nadu" },
      { label: "Telangana", value: "Telangana" },
      { label: "Tripura", value: "Tripura" },
      { label: "Uttar Pradesh", value: "Uttar Pradesh" },
      { label: "Uttarakhand", value: "Uttarakhand" },
      { label: "West Bengal", value: "West Bengal" },
    ];
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  initialVal = (val) => {
    return { label: "Select " + val, value: "", isDisabled: true };
  };

  componentDidMount() {
    const isLogin = localStorage.getItem("authUser");
    if (isLogin) this.props.history.push("/");
    // this.props.registerUserFailed("");
    // this.props.apiError("");
    document.body.classList.add("auth-body-bg");
  }

  validEmail = (val) => {
    return new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(val);
  };
  validateField = (name, value, refValue) => {
    let errorMsg = null;
    switch (name) {
      case "roleType":
        if (!value) errorMsg = "Please Select Role Type.";
        break;
      case "fullName":
        if (!value) errorMsg = "Please Enter Full Name.";
        break;
      case "email":
        if (!value && this.state.form.roleType !== "Driver")
          errorMsg = "Please Enter Email.";
        else {
          if (value && !this.validEmail(value))
            errorMsg = "Please Enter valid email.";
        }
        break;
      case "phone":
        if (value.length !== 10) errorMsg = "Please Enter Valid Number.";
        if (!value) errorMsg = "Please Enter Phone.";
        break;
      case "dealerCode":
        if (!value) errorMsg = "Please Enter Dealer Code.";
        break;

      case "zipCode":
        if (!value) errorMsg = "Please Enter zipCode.";
        break;
      case "city":
        if (!value) errorMsg = "Please Enter City.";
        break;
      case "state":
        if (!value) errorMsg = "Please Select State.";
        break;
      case "address":
        if (!value) errorMsg = "Please Enter Address.";
        break;
      case "password":
        if (!value) errorMsg = "Please Enter Password.";
        break;
      case "cpassword":
        if (value !== this.state.form.password)
          errorMsg = "Passwords Not Equal.";
        if (!value) errorMsg = "Please Enter Confirm Password.";
        break;
      default:
        break;
    }
    return errorMsg;
  };

  validateForm = (form, formErrors, validateFunc) => {
    const errorObj = {};
    Object.keys(formErrors).map((x) => {
      let refValue = null;

      const msg = validateFunc(x, form[x], refValue);
      if (msg) errorObj[x] = msg;
    });
    return errorObj;
  };

  handleSubmit = () => {
    console.log("data", this.state.form);
    this.setState({ onSubmitDisable: true });
    const { form, formErrors } = this.state;
    const errorObj = this.validateForm(form, formErrors, this.validateField);
    //console.log('errorObj', errorObj);
    if (Object.keys(errorObj).length !== 0) {
      this.setState({
        onSubmitDisable: false,
        formErrors: { ...formErrors, ...errorObj },
      });
      return false;
    }
    let formVal = { ...form };
    this.props.registerUser(formVal, (res) => {
      if (
        res["message"] &&
        res["message"] === "Dealer has been created successfully"
      ) {
        NotificationManager.success(res["message"], "Success");
        this.props.history.push("/login");
      } else {
        NotificationManager.error(res["message"], "Error");
      }
    });
    this.setState({ onSubmitDisable: false });
  };
  reset = () => {
    let formData = {
      fullName: "",
      phone: "",
      email: "",
      dealerID: "",
      dealerCode: "",
      zipCode: "",
      city: "",
      state: "",
      address: "",
      password: "",
      cpassword: "",
    };

    this.setState({
      form: formData,
    });
    //console.log('form', this.state.form);
  };
  validateNumber = (evt) => {
    var theEvent = evt || window.event;

    // Handle paste
    if (theEvent.type === "paste") {
      var key = theEvent.clipboardData.getData("text/plain");
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    const { form, formErrors } = this.state;
    let formObj = {};

    formObj = {
      ...form,
      [name]: value,
    };
    this.setState({ form: formObj }, () => {
      if (!Object.keys(formErrors).includes(name)) return;
      let formErrorsObj = {};

      const errorMsg = this.validateField(
        name,
        name === "language" ? this.state.form["language"] : value
      );
      formErrorsObj = { ...formErrors, [name]: errorMsg };
      this.setState({ formErrors: formErrorsObj });
    });
  };

  trimSpace = (event, name) => {
    const { form } = this.state;
    let formObj = formatInput(event, name, form);
    this.setState({ form: formObj });
  };

  render() {
    const { form, formErrors, onSubmitDisable } = this.state;
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/">
            <i className="mdi mdi-home-variant h2 text-white"></i>
          </Link>
        </div>
        <div>
          <Container fluid className="p-0">
            <Row className="no-gutters">
              <Col lg={8}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <div>
                          <div className="text-center">
                            <div>
                              <Link to="#" className="logo">
                                <img src={logo} height="40" alt="logo" />
                              </Link>
                            </div>

                            <h4 className="font-size-18 mt-4">
                              Register account
                            </h4>
                            <p className="text-muted">
                              Get your free CloudTrack account now.
                            </p>
                          </div>

                          {this.props.user && (
                            <Alert color="success">
                              Registration Done Successfully.
                            </Alert>
                          )}

                          {this.props.registrationError && (
                            <Alert color="danger">
                              {this.props.registrationError}
                            </Alert>
                          )}
                          <form>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="select2-container">
                                  <Label className="required-field">Role</Label>
                                  <Select
                                    value={this.roleTypes.find(
                                      (x) => x.value === form.roleType
                                    )}
                                    onChange={(e) =>
                                      this.handleChange({
                                        target: {
                                          name: "roleType",
                                          value: e.value,
                                        },
                                      })
                                    }
                                    options={this.roleTypes}
                                    classNamePrefix="select2-selection"
                                  />

                                  {formErrors.roleType && (
                                    <span className="err">
                                      {formErrors.roleType}
                                    </span>
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="select2-container">
                                  <Label className="required-field">
                                    Full Name
                                  </Label>

                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={form.fullName}
                                    onChange={(e) =>
                                      this.handleChange({
                                        target: {
                                          name: "fullName",
                                          value: e.target.value,
                                        },
                                      })
                                    }
                                    placeholder="Enter fullname"
                                  />
                                  {formErrors.fullName && (
                                    <span className="err">
                                      {formErrors.fullName}
                                    </span>
                                  )}
                                </FormGroup>
                              </Col>

                              <Col lg="6">
                                <FormGroup className="select2-container">
                                  <Label className="required-field">
                                    Phone
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={form.phone}
                                    onChange={(e) =>
                                      this.handleChange({
                                        target: {
                                          name: "phone",
                                          value: e.target.value,
                                        },
                                      })
                                    }
                                    placeholder="Enter phone"
                                    onKeyPress={this.validateNumber}
                                  />
                                  {formErrors.phone && (
                                    <span className="err">
                                      {formErrors.phone}
                                    </span>
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="select2-container">
                                  <Label className="required-field">
                                    Email (Optional for driver)
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={form.email}
                                    onBlur={(e) => this.trimSpace(e, "email")}
                                    onChange={(e) =>
                                      this.handleChange({
                                        target: {
                                          name: "email",
                                          value: e.target.value,
                                        },
                                      })
                                    }
                                    placeholder="Enter email"
                                  />
                                  {formErrors.email && (
                                    <span className="err">
                                      {formErrors.email}
                                    </span>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="select2-container">
                                  <Label className="required-field">
                                    State
                                  </Label>
                                  <Select
                                    value={this.statesList.find(
                                      (x) => x.value === form.state
                                    )}
                                    onChange={(e) =>
                                      this.handleChange({
                                        target: {
                                          name: "state",
                                          value: e.value,
                                        },
                                      })
                                    }
                                    options={this.statesList}
                                    classNamePrefix="select2-selection"
                                  />

                                  {formErrors.state && (
                                    <span className="err">
                                      {formErrors.state}
                                    </span>
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="6">
                                <FormGroup className="select2-container">
                                  <Label className="required-field">
                                    Address
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={form.address}
                                    onChange={(e) =>
                                      this.handleChange({
                                        target: {
                                          name: "address",
                                          value: e.target.value,
                                        },
                                      })
                                    }
                                    placeholder="Ex: 201,Street Name"
                                  />
                                  {formErrors.address && (
                                    <span className="err">
                                      {formErrors.address}
                                    </span>
                                  )}
                                </FormGroup>
                              </Col>

                              <Col lg="6">
                                <FormGroup className="select2-container">
                                  <Label className="required-field">City</Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={form.city}
                                    onChange={(e) =>
                                      this.handleChange({
                                        target: {
                                          name: "city",
                                          value: e.target.value,
                                        },
                                      })
                                    }
                                    placeholder="Enter city"
                                  />
                                  {formErrors.city && (
                                    <span className="err">
                                      {formErrors.city}
                                    </span>
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="select2-container">
                                  <Label className="required-field">
                                    Zip Code
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={form.zipCode}
                                    onChange={(e) =>
                                      this.handleChange({
                                        target: {
                                          name: "zipCode",
                                          value: e.target.value,
                                        },
                                      })
                                    }
                                    placeholder="ex: 560011"
                                  />
                                  {formErrors.zipCode && (
                                    <span className="err">
                                      {formErrors.zipCode}
                                    </span>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="select2-container">
                                  <Label className="required-field">
                                    Dealer Code
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={form.dealerCode}
                                    onChange={(e) =>
                                      this.handleChange({
                                        target: {
                                          name: "dealerCode",
                                          value: e.target.value,
                                        },
                                      })
                                    }
                                    placeholder="ex: 500229															"
                                  />
                                  {formErrors.dealerCode && (
                                    <span className="err">
                                      {formErrors.dealerCode}
                                    </span>
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="select2-container">
                                  <Label className="required-field">
                                    Password
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="password"
                                    value={form.password}
                                    onChange={(e) =>
                                      this.handleChange({
                                        target: {
                                          name: "password",
                                          value: e.target.value,
                                        },
                                      })
                                    }
                                    placeholder="Enter password"
                                  />
                                  {formErrors.password && (
                                    <span className="err">
                                      {formErrors.password}
                                    </span>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="select2-container">
                                  <Label className="required-field">
                                    Confirm Password
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="password"
                                    value={form.cpassword}
                                    onChange={(e) =>
                                      this.handleChange({
                                        target: {
                                          name: "cpassword",
                                          value: e.target.value,
                                        },
                                      })
                                    }
                                    placeholder="Enter confirm password"
                                  />
                                  {formErrors.cpassword && (
                                    <span className="err">
                                      {formErrors.cpassword}
                                    </span>
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row></Row>
                            <FormGroup className="mb-0 text-center ">
                              <div>
                                <Button
                                  disabled={onSubmitDisable}
                                  type="reset"
                                  color="secondary"
                                  onClick={this.reset}
                                >
                                  Cancel
                                </Button>{" "}
                                <Button
                                  disabled={onSubmitDisable}
                                  type="button"
                                  color="btn btn-primary"
                                  className="mr-1"
                                  onClick={this.handleSubmit}
                                >
                                  Submit
                                </Button>
                              </div>
                            </FormGroup>
                          </form>
                          <div className="mt-2 text-center">
                            <p>
                              Already have an account ?{" "}
                              <Link
                                to="/login"
                                className="font-weight-medium text-primary"
                              >
                                {" "}
                                Login
                              </Link>{" "}
                            </p>
                          </div>
                          <div className="text-center">
                            <p className="mb-0">
                              By registering you agree to the CloudTrack{" "}
                              <Link to="#" className="text-primary">
                                Terms of Use
                              </Link>
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="authentication-bg">
                  <div className="bg-overlay"></div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
  registerUser,
  // apiError,
  // registerUserFailed,
})(Register);
