import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  TabPane,
  Label,
  Input,
  Form,
  FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { connect } from "react-redux";
import { setVehicleDetails } from "../../../../store/indent/actions";
// import { fetchVehicleType } from "../../../../store/vehicle/actions";
import { fetchTransportersforIndent } from "../../../../store/transporter/actions";

const city = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

class VehiclePlanningTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleFormValues: {
        vehicleType: "",
        vehicleQuantity: "",
        transporterSelection: "",
        cost: "",
      },
      vehicleTypeList: [],
      transporterList: [],
    };
  }

  vehicleFormValues = {
    vehicleType: "",
    vehicleQuantity: "",
    transporterSelection: "",
    cost: "",
  };

  componentDidMount = () => {
    let vehicleTypeArr = [];
    let transporterArr = [];
    // this.props.fetchVehicleType((res) => {
    //   console.log(res);
    //   if (res.docs.length > 0) {
    //     res.docs.forEach((obj) => {
    //       vehicleTypeArr.push({
    //         label: obj.Label,
    //         value: obj._id,
    //       });
    //     });
    //   }
    //   console.log(vehicleTypeArr);
    //   this.setState({ vehicleTypeList: vehicleTypeArr });
    // });
    this.props.fetchTransportersforIndent((res) => {
      console.log(res);
      if (res.docs.length > 0) {
        res.docs.forEach((obj) => {
          transporterArr.push({
            label: obj.name,
            value: obj._id,
          });
        });
      }
      console.log(transporterArr);
      this.setState({ transporterList: transporterArr });
    });
  };

  valueAssigner = (e, value) => {
    if (value === "vehicleQuantity" || value === "cost") {
      this.vehicleFormValues[value] = e.target.valueAsNumber;
      let n = e.target.valueAsNumber;
      this.setState((prevState) => ({
        vehicleFormValues: {
          ...prevState.vehicleFormValues,
          [value]: n,
        },
      }));
    } else {
      this.vehicleFormValues[value] = e.value;
      this.setState((prevState) => ({
        vehicleFormValues: {
          ...prevState.vehicleFormValues,
          [value]: e.value,
        },
      }));
    }
    this.props.setVehicleDetails(this.vehicleFormValues);
    this.props.checkValid(this.vehicleFormValues);
  };

  reset = () => {
    let vehicleFormValues = {
      vehicleType: "",
      vehicleQuantity: "",
      transporterSelection: "",
      cost: "",
    };
    this.vehicleFormValues = vehicleFormValues;
    this.setState({ vehicleFormValues: vehicleFormValues });
    this.props.setVehicleDetails(vehicleFormValues);
    this.props.checkValid(vehicleFormValues);
  };

  render() {
    console.log(this.props);
    return (
      <React.Fragment>
        <TabPane tabId={3}>
          <Form>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label className=" col-form-label">Vehicle Type</Label>
                  <Select
                    name="vehicleType"
                    value={
                      this.state.vehicleTypeList.find((x) => {
                        return (
                          x.value === this.state.vehicleFormValues.vehicleType
                        );
                      }) || { value: "", label: "" }
                    }
                    label="Single select"
                    options={this.state.vehicleTypeList}
                    onChange={(e) => this.valueAssigner(e, "vehicleType")}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label
                    htmlFor="example-number-input"
                    className="col-form-label"
                  >
                    Vehicle Quantity
                  </Label>
                  <Input
                    className="form-control"
                    name="vehicleQuantity"
                    value={this.state.vehicleFormValues.vehicleQuantity}
                    type="number"
                    onChange={(e) => this.valueAssigner(e, "vehicleQuantity")}
                    id="example-number-input"
                  />
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label className=" col-form-label">
                    Transporter Selection
                  </Label>
                  <Select
                    name="transporterSelection"
                    value={
                      this.state.transporterList.find((x) => {
                        return (
                          x.value ===
                          this.state.vehicleFormValues.transporterSelection
                        );
                      }) || { value: "", label: "" }
                    }
                    label="Single select"
                    options={this.state.transporterList}
                    onChange={(e) =>
                      this.valueAssigner(e, "transporterSelection")
                    }
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label
                    htmlFor="example-number-input"
                    className="col-form-label"
                  >
                    Cost
                  </Label>
                  <Input
                    className="form-control"
                    name="cost"
                    type="number"
                    value={this.state.vehicleFormValues.cost}
                    onChange={(e) => this.valueAssigner(e, "cost")}
                    id="example-number-input"
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <div className="mb-2 mt-2 m5 text-center btn_indene_style">
                  <Link>
                    <Button className="m-2 p-2 secondary" onClick={this.reset}>
                      Reset
                    </Button>
                  </Link>
                  <Link></Link>
                </div>
              </Col>
            </Row>
          </Form>
        </TabPane>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  indents: state.Indent.indents,
});

export default connect(mapStateToProps, {
  setVehicleDetails,
  // fetchVehicleType,
  fetchTransportersforIndent,
})(VehiclePlanningTab);
