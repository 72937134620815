import React, { Component } from "react";
import { Row, Col, Card, Button } from "reactstrap";
import calendarIcon from "./assets/calendar.png";
import calendarDarkIcon from "./assets/calendarDark.png";
import exportDarkIcon from "./assets/external-linkDark.png";
import Control from "../../../components/Common/Controls/Button";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./reportListTheme.scss";
import TextField from "@mui/material/TextField";
import exportIcon from "./assets/external-link.png";
// import StaticDateRangePicker from "@mui/lab/StaticDateRangePicker";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Box from "@mui/material/Box";
import refreshIcon from "./assets/refresh-cw.png";
import { getLocalStorage } from "./RowHeaders/RowHeaders";
import { ClickAwayListener } from "@material-ui/core";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
var darkMode;
const MyIcon = React.forwardRef((props, ref) => {
  return (
    <img  className="reportsListStyle" src={darkMode?calendarDarkIcon:calendarIcon} />
  );
});

class QuickFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hops: [],
      vehicleTypes: [],
      // searchBar: [],
      searchFilters: {},
      dateValue: [null, null],
      showCalender: false,
      userLangs: undefined,
    };
  }
  handleClickAway = () => {
    if (
      this.state?.dateValue[0] !== null &&
      this.state?.dateValue[1] !== null
    ) {
      this.setState({ showCalender: false });
    }
  };
  handleOpen = () => {
    this.setState({ showCalender: true });
  };
  handleClose = () => {
    this.setState({ showCalender: false });
  };
  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (this.state.userLangs !== userLang || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }
  render() {
    let mode = localStorage.getItem('Theme') ? localStorage.getItem('Theme') : ""
     darkMode = ((mode === "dark__mode") ? true : false);

    if (this.props.location?.pathname === "/indentReport-analysis") {
      var OperationsBgClr = "#0AB38C";
      var operationsClr = "#FFFFFF";
      var border = ""
    } else {
      var OperationsBgClr = `${darkMode ? "#171722" : "#F1F5F7"}`;
      var clr = `${darkMode ? "#FFFFFF" : "#F1F5F7"}`;
      var border = `${darkMode ? "0.5px solid #616161" : " "}`;
    }
    if (this.props.location?.pathname === "/statusIndent") {
      var statusIndentBgClr = "#0AB38C";
      var statusIndentClr = "#FFFFFF";
      var border = ""
    } else {
      var statusIndentBgClr = `${darkMode ? "#171722" : "#F1F5F7"}`;
      var clr = `${darkMode ? "#ffffff" : "#05284E"}`;
      var border = `${darkMode ? "0.5px solid #616161" : " "}`;
    }
    if (this.props.location?.pathname === "/transporterIndent") {
      var transpoterBgClr = "#0AB38C";
      var transpoterClr = "#FFFFFF";
      var border = ""
    } else {
      // var transpoterBgClr = "#F1F5F7";
      // var clr = "#05284E";
      var transpoterBgClr = `${darkMode ? "#171722" : "#F1F5F7"}`;
      var clr = `${darkMode ? "#ffffff" : "#05284E"}`;
      var border = `${darkMode ? "0.5px solid #616161" : " "}`;
    }

    return (
      <React.Fragment>
        <Card className="darkBgQuickFilter">
          <div className="quickStylesBar reportStyle repotCal">
            <>
              <div className="desktopLanelot">
                <div className="d-flex align-items-center example-parent cardStyleR">
                  <div className="p-1 col-example filterR">
                    <Control
                      variant="outlined"
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/indentReport-analysis",
                        });
                      }}
                      style={{
                        backgroundColor: `${OperationsBgClr}`,
                        color: `${operationsClr || clr}`,
                        border: `${border}`,
                        width: "130px",
                      }}
                      text={this.state.userLangs?.report?.LANE}
                    />
                  </div>
                  <div className="p-1 col-example filterR ">
                    <Control
                      variant="outlined"
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/statusIndent",
                        });
                      }}
                      style={{
                        backgroundColor: `${statusIndentBgClr}`,
                        color: `${statusIndentClr || clr}`,
                        border: `${border}`,
                        width: "130px",
                      }}
                      text={this.state.userLangs?.common?.STATUS}
                    />
                  </div>
                  <div className="p-1 col-example filterR">
                    {" "}
                    <Control
                      className="operations-dashboard-btn-operations"
                      variant="outlined"
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/transporterIndent",
                        });
                      }}
                      style={{
                        backgroundColor: `${transpoterBgClr}`,
                        color: `${transpoterClr || clr}`,
                        border: `${border}`,
                      }}
                      text={this.state.userLangs?.common?.TRANSPORTER}
                    />
                  </div>

                  <div className="ml-auto p-2 col-example">
                    <div className="d-flex justify-content-sm-end cardStyleR">
                      <div className="p-0 filter">
                        <div
                          style={{
                            width: "95%",
                            float: "left",
                            marginRight: "-5px",
                          }}
                        >
                          <div className="tooltipIcon QuickFilters">
                            <div className="datepicker-selector-mobile iconButtons mobileCalender actionButtonsDark mr-2 datepicker-custom"
                              style={{ position: "relative", top: "9px" }}>
                              <DateRangePicker
                                className="datePickerIcon"
                                size="md"
                                placeholder=" "
                                block
                                format="dd MMM yy"
                                showOneCalendar
                                placement="bottom"
                                preventOverflow
                                value={this.state.dateValue || [null, null]}
                                onChange={(newValue) => {
                                  console.log(newValue)
                                  this.props.quickFilters("date",newValue)
                                }}
                                caretAs={MyIcon}
                              />
                            </div>
                            <span className="tooltipIcontext">
                              &nbsp;{this.state.userLangs?.common?.CALENDER}
                              &nbsp;
                            </span>
                          </div>
                          {/* {this.state.showCalender ? (
                            <ClickAwayListener
                              onClickAway={this.handleClickAway}
                            >
                              <div style={{ float: "left", direction: "rtl" }}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                >
                                  <StaticDateRangePicker
                                    displayStaticWrapperAs="desktop"
                                    calendars={1}
                                    format={"MM-DD-YYYY"}
                                    value={this.state.dateValue}
                                    onClose={this.handleClose}
                                    onOpen={this.handleOpen}
                                    onChange={(newValue) => {
                                      this.setState(
                                        { dateValue: newValue },
                                        () => {
                                          if (
                                            this.state.dateValue[1] !== null
                                          ) {
                                            this.setState({
                                              showCalender:
                                                !this.state.showCalender,
                                            });
                                            this.props.quickFilters(
                                              "date",
                                              this.state.dateValue
                                            );
                                          }
                                        }
                                      );
                                    }}
                                    renderInput={(startProps, endProps) => (
                                      <React.Fragment>
                                        <TextField {...startProps} />
                                        <Box sx={{ mx: 2 }}> to </Box>
                                        <TextField {...endProps} />
                                      </React.Fragment>
                                    )}
                                  />
                                </LocalizationProvider>
                              </div>
                            </ClickAwayListener>
                          ) : null} */}
                        </div>
                      </div>
                      <div className="p-0 pr-3 filter">
                        <div
                          style={{
                            width: "100%",
                            float: "right",
                            marginRight: "-5px",
                          }}
                        >
                          <div className="tooltipIcon">
                            <Button
                              outline
                              color="info"
                              disabled={!navigator.onLine}
                              onClick={() => this.props.toggleExcel()}
                              className="iconButtons actionButtonsDark"
                            >
                              <img
                                style={{ margin: "-4px" }}
                                src={darkMode ? exportDarkIcon : exportIcon}
                                alt="exp"
                              />
                            </Button>
                            <span
                              className="tooltipIcontext"
                              style={{ width: "55px" }}
                            >
                              &nbsp;
                              {this.state.userLangs?.invoice?.EXPORT}&nbsp;
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="p-0 filter">
                        {" "}
                        <div>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              this.setState({ dateValue: [null, null] }, () => {
                                this.props.reset();
                              });
                            }}
                            className="reset"
                          >
                            {this.state.userLangs?.common?.RESET}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mobileLanelot">
                <Row>
                  <Col xs={11}>
                    <div class="d-flex justify-content-around flex-shrink-1 MobileflesParent">
                      <div className="d-flex flex-row laneLotButtonsMobilePd1">
                        <div class="pl-2">
                          <Control
                            className="roboto_family mobileLaneLotButtons"
                            variant="outlined"
                            onClick={() => {
                              this.props.history.push({
                                pathname: "/indentReport-analysis",
                              });
                            }}
                            style={{
                              backgroundColor: `${OperationsBgClr}`,
                              color: `${operationsClr || clr}`,
                              // width: "100%",
                            }}
                            text="Lane"
                          />
                        </div>
                        <div class=" ">
                          <Control
                            className="roboto_family mobileLaneLotButtons"
                            variant="outlined"
                            onClick={() => {
                              this.props.history.push({
                                pathname: "/statusIndent",
                              });
                            }}
                            style={{
                              backgroundColor: `${statusIndentBgClr}`,
                              color: `${statusIndentClr || clr}`,
                              // width: "100%",
                            }}
                            text="Status"
                          />
                        </div>
                        <div class="">
                          <Control
                            className="operations-dashboard-btn-operations roboto_family mobileLaneLotButtons"
                            variant="outlined"
                            onClick={() => {
                              this.props.history.push({
                                pathname: "/transporterIndent",
                              });
                            }}
                            style={{
                              backgroundColor: `${transpoterBgClr}`,
                              color: `${transpoterClr || clr}`,
                              // width: "100%",
                            }}
                            text="Transporter"
                          />
                        </div>
                      </div>
                      {/* </Col>
                    <Col xs={4}> */}
                      <div className="d-flex flex-row-reverse laneLotButtonsMobilePd">
                        <div class="pl-2 pr-1 ">
                          {" "}
                          <div>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                this.setState(
                                  { dateValue: [null, null] },
                                  () => {
                                    this.props.reset();
                                  }
                                );
                              }}
                              className="reset"
                            >
                              <img src={refreshIcon}></img>
                            </Button>
                          </div>
                        </div>
                        <div class="pr-1 ">
                          {" "}
                          <div
                            style={
                              {
                                // width: "100%",
                                // float: "right",
                                // marginRight: "-5px",
                              }
                            }
                          >
                            <div className="tooltipIcon">
                              <Button
                                style={{
                                  width: "33px",
                                  position: "relative",
                                  left: "35%",
                                }}
                                outline
                                color="info"
                                disabled={!navigator.onLine}
                                onClick={() => this.props.toggleExcel()}
                                className="iconButtons actionButtonsDark"
                              >
                                <img
                                  style={{ margin: "-4px" }}
                                  src={darkMode ? exportDarkIcon : exportIcon}
                                  alt="exp"
                                />
                              </Button>
                              <span
                                className="tooltipIcontext"
                                style={{ width: "55px" }}
                              >
                                &nbsp;{this.state.userLangs?.invoice?.EXPORT ?? "Export"}
                                &nbsp;
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="pr-2 ">
                          {" "}
                          <div
                            style={
                              {
                                // width: "95%",
                                // float: "left",
                                // marginRight: "-30px",
                              }
                            }
                          >
                            <div className="tooltipIcon QuickFilters">
                              <div className="datepicker-selector-mobile iconButtons mobileCalender actionButtonsDark mr-2 datepicker-custom"
                                style={{ position: "relative", top: "9px" }}>
                                <DateRangePicker
                                  className="datePickerIcon"
                                  size="md"
                                  placeholder=" "
                                  block
                                  format="dd MMM yy"
                                  showOneCalendar
                                  placement="bottom"
                                  preventOverflow
                                  value={this.state.dateValue || [null, null]}
                                  onChange={(newValue) => {
                                    console.log(newValue)
                                    this.props.quickFilters("date",newValue)
                                  }}
                                  caretAs={MyIcon}
                                />
                              </div>
                              <span className="tooltipIcontext">
                                &nbsp;{this.state.userLangs?.common?.CALENDER ?? "Calender"}
                                &nbsp;
                              </span>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default connect(null)(withRouter(QuickFilters));
