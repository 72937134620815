import React, { Component } from "react";
import {
  Container,
  FormGroup,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { connect } from "react-redux";
import DataTable from "../../Utility/dataTable";
import Datetime from "react-datetime";
import { fetchAllTransporterSearch } from "../../../store/transporter/actions";
import {
  fetchLastAgreement,
  agreementDate,
} from "../../../store/billing/actions";
import {
  fetchAgreements,
  fetchAllAgreements,
} from "../../../store/billing/actions";
import { restrict } from "../../restrict";
import { PERMS } from "../../../enum/perms.enum";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import AddAgreementIndexSubScreen from "./addAgreementIndexSubScreen";
import moment from "moment";
import AggreementPdf from "../AggreementPdf";
import { getLocalStorage } from "./AgreementForms/DatatableHeaders";
import QuickFilters from "./Quickfilter";
var endDay = "";
var startDay = "";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class addAgreementIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reset: false,
      modal: false,
      showpdf: false,
      modalCopyAgreement: false,
      loading: false,
      breadcrumbItems: [
        { title: "Billing", link: "#" },
        { title: "Add Agreements", link: "#" },
      ],
      endDate: "",
      startDate: "",
      heads: [],
      transporter: [],
      selectedTransporter: {
        name: "",
        userId: "",
        id: "",
      },
      copyAgreement: [],
      copyAgreementHeads: [],
      selectedCopyAgreement: {
        name: "",
        userId: "",
        id: "",
      },
    };
    (async () => {
      let redirect = await restrict(PERMS.INDENT_PLANNER_READ);

      if (redirect == false) {
        this.props.history.push("/operationsDashboard");
      }
    })();
    this.startdate = React.createRef();
    this.expirydate = React.createRef();
  }

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };
  copyAgreementToggle = () => {
    this.setState({ modalCopyAgreement: !this.state.modalCopyAgreement });
  };

  selectTransporter = (data) => {
    console.log("data trans", data);
    let selectedData = {
      name: data.Name,
      userId: data?.userId || data?.UserId,
      id: data._id,
    };
    this.setState({ selectedTransporter: selectedData }, () => {
      this.toggle();
    });
  };

  copyAgreementTransporter = (data) => {
    let selectedCopyData = {
      id: data.id,
    };
    this.setState({ selectedCopyAgreement: selectedCopyData }, () => {
      this.copyAgreementToggle();
      this.props.fetchLastAgreement(this.state.selectedCopyAgreement.id);
    });
  };

  valid = function (current) {
    let day;
    let yesterday = moment().subtract(1, "day");
    let time = new Date(current).getHours();
    yesterday = moment(yesterday).subtract(time);
    if (endDay !== "") {
      day = endDay;
      return current.isBefore(day) && current.isAfter(yesterday);
    } else return current.isAfter(yesterday);
  };

  validExpiry = function (current) {
    // let day = endDay;
    // console.log(day,"day")
    // console.log(endDay,startDay,"day")
    // return current.isBefore(day) && current.isAfter(startDay);
    const yesterday = moment().subtract(1, "day");
    return current.isAfter(yesterday);
  };

  validExpiryLocal = function (current) {
    let day = startDay;
    return current.isAfter(day);
  };

  componentDidUpdate = () => {
    const { startDate, expiryDate } = this.props.billing.copiedAgreementValues;
    console.log(
      "THIS IS CDU CHECK",
      this.props.billing.copiedAgreementValues.id,
      "====",
      this.state.selectedTransporter.id
    );
    if (
      this.props.billing.copiedAgreementValues.id &&
      this.state.selectedTransporter.id !==
        this.props.billing.copiedAgreementValues.transporter._id
    ) {
      console.log(
        "this is the main page log about copied agreement",
        this.props.billing.copiedAgreementValues
      );
      let data = this.props.billing.copiedAgreementValues.transporter;
      console.log("data trans", data);
      let selectedCopyData = {
        name: data.name,
        userId: data.userId,
        id: data._id,
      };
      this.setState(
        {
          selectedTransporter: selectedCopyData,
          startDate: moment(startDate).format("l"),
          endDate: moment(expiryDate).format("l"),
        },
        () => {
          console.log("State LOG", this.state);
        }
      );
    }
  };

  componentDidMount = () => {
    this.props.fetchAllTransporterSearch(0, 10000, "", (res) => {
      this.transporter = [];
      if (res.data?.docs?.length > 0) {
        res.data.docs.forEach((ele) => {
          let { _id, name, email, phone, userId } = ele;
          this.transporter.push({
            _id: _id,
            [`${userLang?.masters?.NAME}`]: name,
            [`${userLang?.masters?.EMAIL}`]: email,
            [`${userLang?.masters?.PHONE}`]: phone,
            [`${userLang?.common?.USER_ID}`]: userId,
          });
        });
      }
      let head = Object.keys(this.transporter[0]);
      this.setState({
        transporter: this.transporter,
        heads: head,
      });
    });
    this.props.fetchAllAgreements(1, 10000, (res) => {
      console.log(res);
      let agreementList = [];
      if (res.result?.agreements.length > 0) {
        res.result.agreements.forEach((ele) => {
          // console.log(ele);
          agreementList.push({
            id: ele.transporter._id,
            [`${userLang?.common?.TRANSPORTER_NAME}`]: ele.transporter.name,
            [`${userLang?.common?.START_DATE}`]: moment(ele.startDate).format(
              "l"
            ),
            [`${userLang?.common?.END_DATE}`]: moment(ele.expiryDate).format(
              "l"
            ),
          });
        });
      }
      let agreementHead = Object.keys(agreementList[0]);
      this.setState({
        copyAgreement: agreementList,
        copyAgreementHeads: agreementHead,
      });
    });
    if (this.props.history.location?.state?.page == "view Agreement") {
      this.handlePdf();
    }
  };

  onChangeStartDate = (e) => {
    startDay = e;
    this.setState(
      {
        startDate: moment(e._d).format("l"),
      },
      () => {
        this.props.agreementDate({
          key: "startDate",
          Date: this.state.startDate,
        });
      }
    );
  };
  onChangeEndadate = (e) => {
    endDay = e;
    this.setState(
      {
        endDate: moment(e._d).format("l"),
      },
      () => {
        this.props.agreementDate({ key: "endDate", Date: this.state.endDate });
      }
    );
  };
  startDateCalenderToggle = () => {
    if (this.startdate.current.state.open == false) {
      this.startdate.current._openCalendar();
    } else {
      this.startdate.current._closeCalendar();
    }
  };
  endDateCalenderToggle = () => {
    if (this.expirydate.current.state.open == false) {
      this.expirydate.current._openCalendar();
    } else {
      this.expirydate.current._closeCalendar();
    }
  };
  OnChangeTranspoter = (e) => {
    console.log("e trans", e);
  };
  componentWillUnmount = () => {
    this.setState({ showpdf: false });
  };
  reset = () => {
    this.setState(
      {
        reset: true,
        startdate: "",
        endDate: "",
        selectedTransporter: {
          name: "",
          userId: "",
          id: "",
        },
      },
      () => {
        this.setState({ reset: false });
      }
    );
  };
  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    var darkMode = mode === "dark__mode" ? true : false;
    return (
      <>
        {!this.state.reset ? (
          <React.Fragment>
            <QuickFilters
              copyAgreementToggle={this.copyAgreementToggle}
              userLang={userLang}
              selectedTransporterName={this.state.selectedTransporter?.name}
              toggle={this.toggle}
              valid={this.valid}
              startDate={this.state.startDate}
              onChangeStartDate={this.onChangeStartDate}
              startdate={this.startdate}
              startDateCalenderToggle={this.startDateCalenderToggle}
              endDateCalenderToggle={this.endDateCalenderToggle}
              expirydate={this.expirydate}
              onChangeEndadate={this.onChangeEndadate}
              validExpiryLocal={this.validExpiryLocal}
              endDate={this.state.endDate}
              OnChangeTranspoter={this.OnChangeTranspoter}
              mode={darkMode}
              reset={this.reset}
            />

            <div className="mt-2">
              <AddAgreementIndexSubScreen
                transporter={this.state.selectedTransporter}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                valid={this.valid}
                validExpiry={this.validExpiry}
                pdfDownload={this.handlePdf}
              />
            </div>
            <Modal
              isOpen={this.state.modalCopyAgreement}
              toggle={() => this.copyAgreementToggle()}
              size="xl"
              centered
            >
              <Row className="mt-2">
                <Col>
                  <ModalHeader> {userLang?.common?.TRANSPORTER} </ModalHeader>
                </Col>
                <Col className="text-right">
                  <i
                    className="fas fa-times closemodal1 pr-2 text-right close"
                    onClick={() => this.copyAgreementToggle()}
                  ></i>
                </Col>
              </Row>

              <ModalBody className="mb-4">
                <DataTable
                  heads={this.state.copyAgreementHeads}
                  data={this.state.copyAgreement}
                  hidden={["id"]}
                  selected={this.copyAgreementTransporter}
                />
              </ModalBody>
            </Modal>
            <Modal
              isOpen={this.state.modal}
              toggle={() => this.toggle()}
              size="xl"
              centered
              className="TranspoterWid"
            >
              <Row className="mt-2">
                <Col>
                  <ModalHeader> {userLang?.common?.TRANSPORTER} </ModalHeader>
                </Col>
                <Col className="text-right">
                  <i
                    className="fas fa-times closemodal pr-2 text-right close"
                    onClick={() => this.toggle()}
                  ></i>
                </Col>
              </Row>

              <ModalBody className="mb-4">
                <DataTable
                  heads={this.state.heads}
                  data={this.state.transporter}
                  hidden={["_id"]}
                  selected={this.selectTransporter}
                />
              </ModalBody>
            </Modal>
          </React.Fragment>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  billing: state.Billing.billing,
});

export default connect(mapStateToProps, {
  fetchAllTransporterSearch,
  fetchLastAgreement,
  fetchAgreements,
  fetchAllAgreements,
  agreementDate,
})(addAgreementIndex);
