import React, { Component } from "react";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  Button
} from "mdbreact";
import { Row, Col } from "reactstrap";
import { Grid } from "@material-ui/core";
import { updateBill, fetchBills } from "../../../store/billing/actions";
import { connect } from "react-redux";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class InvoiceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      damageCostEdit: false,
      deductionCost: "",
      costDescription: "",
      costValue: "",
      costArray: [],
      freightCost: "",
      freightCostEdit: true,
      defaultDamageCost: 0,
      loadingChargesEdit: false,
      loadingCharges: 0,
      fetched: false,
      submitDisable: true,
    };
  }

  componentDidUpdate = () => {
    console.log(this.props.invoiceDet);
    if (this.props.invoiceDet.id !== this.state.id) {
      console.log("CDU", this.props.invoiceDet);
      this.setState({
        damageCostEdit: false,
        deductionCost: "",
        costDescription: "",
        costValue: "",
        costArray: [],
        freightCost: "",
        loadingCharges: 0,
        freightCostEdit: false,
        defaultDamageCost: 0,
        fetched: false,
      });
    }

    if (!this.state.fetched) {
      if (this.props.invoiceDet.id)
        this.setState({ id: this.props.invoiceDet.id });

      if (this.props.invoiceDet.charges?.revisedFreightCost)
        this.setState({
          freightCost: this.props.invoiceDet.charges.revisedFreightCost,
        });

      if (this.props.invoiceDet.charges?.revisedDamage)
        this.setState({
          deductionCost: this.props.invoiceDet.charges.revisedDamage,
        });

      if (this.props.invoiceDet.charges?.revisedOtherDeductions)
        this.setState({
          costArray: this.props.invoiceDet.charges.revisedOtherDeductions,
        });

      if (this.props.invoiceDet.calculations?.damageCharges)
        this.setState({
          defaultDamageCost: this.props.invoiceDet.calculations.damageCharges,
        });

      if (this.props.invoiceDet.calculations?.revisedDamageCharges)
        this.setState({
          deductionCost:
            this.props.invoiceDet.calculations.revisedDamageCharges,
        });

      if (this.props.invoiceDet.calculations?.loadingCharges)
        this.setState({
          loadingCharges: this.props.invoiceDet.calculations.loadingCharges,
        });

      this.setState({ fetched: true });
    }
  };

  deductionCostHandler = (e) => {
    console.log("cost handler called", e.target.value);
    this.setState({ deductionCost: parseInt(e.target.value) });
  };

  closeDeductionInput = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      this.setState({ damageCostEdit: false }, () => {
        console.log(this.state.deductionCost, "COST");
      });
    }
  };

  freightCostHandler = (e) => {
    if (e.target.value < 0) {
      this.setState({ freightCost: e.target.value * -1 });
    } else {
      this.setState({ freightCost: e.target.value });
    }
  };

  loadingChargeHandler = (e) => {
    if (e.target.value < 0) {
      this.setState({ loadingCharges: e.target.value * -1 });
    } else {
      this.setState({ loadingCharges: e.target.value });
    }
  };

  closeFreightCostInput = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      this.setState({ freightCostEdit: false });
    }
  };

  closeLoadingChargeInput = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      this.setState({ loadingChargesEdit: false });
    }
  };

  costDescriptionHandler = (e) => {
    console.log(e.target.value);
    this.setState({ costDescription: e.target.value },()=>{
      if(this.state.costDescription.length && this.state.costValue.length){
       this.setState({ submitDisable:false})
      }else{
        this.setState({ submitDisable:true})
      }
    });
  };

  costValueHandler = (e) => {
    console.log(e.target.value);
    this.setState({ costValue: e.target.value },()=>{
      if(this.state.costValue.length && this.state.costDescription.length ){
        this.setState({ submitDisable:false})
       }else{
         this.setState({ submitDisable:true})
       }
    });
  };

  expenseAddHandler = () => {
    let expArray = [];
    expArray.push({
      label: this.state.costDescription,
      charges: parseInt(this.state.costValue),
    });
    this.setState(
      {
        costArray: this.state.costArray.concat(expArray),
        costDescription: "",
        costValue: "",
        submitDisable:true
      },
      () => {
        console.log("COST ARRAY", this.state.costArray);
      }
    );
  };

  resetExpenseHandler = ()=>{
    this.setState({costDescription:"",costValue:"",costArray:[]},()=>{
      if(!this.state.costDescription.length || !this.state.costValue.length){
        this.setState({submitDisable: true})
      }else{
        this.setState({submitDisable: false})
      }
    })
  }
  
  submitChanges = () => {
    let values = { limit: 10, offset: this.props.offset };
    let data = {
      billId: this.props.invoiceDet.id,
      revisedFreightCost: parseInt(this.state.freightCost),
    };

    if (this.state.loadingCharges) {
      data.loadingCharges = parseInt(this.state.loadingCharges);
    }
    if (this.state.deductionCost) {
      data.revisedDamage = this.state.deductionCost;
    }
    if (this.state.costArray) {
      data.revisedOtherDeductions = this.state.costArray;
    }

    this.props.updateBill(data, (res) => {
      this.props.toggle();
      this.props.getAllData();
    });
  };

  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    if (!this.props.isOpen) {
      if (this.state.freightCostEdit)
        this.setState({
          freightCostEdit: false,
        });
      if (this.state.damageCostEdit)
        this.setState({
          damageCostEdit: false,
        });
      if (this.state.loadingChargesEdit)
        this.setState({
          loadingChargesEdit: false,
        });
    }
    let { freightCost, freightCostEdit, loadingChargesEdit, loadingCharges } =
      this.state;
    let { charges, isOpen, vehicle, status, startTime, leadTime, distance } =
      this.props.invoiceDet;

    let multipointPickupCharges = 0;
    if (charges?.multipointPickup)
      charges.multipointPickup.forEach((c) => {
        multipointPickupCharges += c.charges;
      });

    let multipointDropCharges = 0;
    if (charges?.multipointDrop)
      charges.multipointDrop.forEach((c) => {
        multipointDropCharges += c.charges;
      });

    let sourceDetentionCharges = 0;
    if (charges?.sourceDetention)
      charges.sourceDetention.forEach((c) => {
        sourceDetentionCharges += c.charges;
      });

    let destinationDetentionCharges = 0;
    if (charges?.destinationDetention)
      charges.destinationDetention.forEach((c) => {
        destinationDetentionCharges += c.charges;
      });
    return (
      <React.Fragment>
        <MDBContainer className="invoice">
          <MDBModal
            isOpen={this.props.isOpen}
            toggle={() => {
              this.props.toggle();
            }}
            centered
            size="lg"
            className={`mdl ${this.props.mode ? "invoiceDarkMode" : ""}`}
          >
            <i
              className="fas fa-times closemodal2 p-3 close"
              onClick={() => {
                if (this.state.freightCostEdit)
                  this.setState({ freightCostEdit: false });
                this.props.toggle();
              }}
            ></i>

            <MDBModalHeader
              toggle={this.toggle}
              className={`${this.props.mode ? "headersModalColor" : ""}`}
            >
              {" "}
              <span className="fontRoboto light__span">
                {userLang?.invoice?.BILL_DETAILS}
              </span>
            </MDBModalHeader>
            <MDBModalBody>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {" "}
                    {userLang?.invoice?.TRUCK_NO}:
                  </div>
                  <div className="colaps-field dark__span">
                    {vehicle?.regNo || "---"}
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {userLang?.invoice?.TRUCK_TYPE}:
                  </div>
                  <div className="colaps-field dark__span">
                    {vehicle?.type?.label}
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {" "}
                    {userLang?.invoice?.TRIP_STATUS}:
                  </div>
                  <div className="colaps-field darkStatusCompleted">{status}</div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {userLang?.invoice?.DATE_OF_THE_PLACEMENT}:
                  </div>
                  <div className="colaps-field dark__span">{startTime?.split("T")[0]}</div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {" "}
                    {userLang?.invoice?.STATUS_EPOD}:
                  </div>
                  <div className="colaps-field darkStatusCompleted">Completed</div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {" "}
                    {userLang?.invoice?.TOTAL_DISTANCE}:
                  </div>
                  <div className="colaps-field dark__span">{distance?distance: 0}  KMs</div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="colaps-label required_field light__span">
                    Freight Cost:{" "}
                  </div>
                  <div className="colaps-label dark__span">
                    {freightCostEdit ? (
                      <>
                        <input
                          type="number"
                          placeholder="Cost"
                          style={{ width: "50%" }}
                          onChange={this.freightCostHandler}
                          onKeyDown={this.closeFreightCostInput}
                        />
                      </>
                    ) : (
                      <>
                        {freightCost}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({ freightCostEdit: true });
                          }}
                        >
                          <i className="fa fa-edit t-edit m-2 darkInvoiceModalIcons"></i>
                        </span>
                      </>
                    )}
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {" "}
                    {userLang?.invoice?.LOADING_CHARGES}:
                  </div>
                  <div className="colaps-label dark__span">
                    {loadingChargesEdit ? (
                      <>
                        <input
                          type="number"
                          placeholder="Cost"
                          min="0"
                          style={{ width: "50%" }}
                          onChange={this.loadingChargeHandler}
                          onKeyDown={this.closeLoadingChargeInput}
                        />
                      </>
                    ) : (
                      <>
                        {"₹ " + loadingCharges}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({ loadingChargesEdit: true });
                          }}
                        >
                          <i className="fa fa-edit t-edit m-2 darkInvoiceModalIcons"></i>
                        </span>
                      </>
                    )}
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {userLang?.invoice?.MULTI_POINT_PICK_CHARGES}:
                  </div>
                  <div className="colaps-field dark__span">
                    {multipointPickupCharges} ₹
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {userLang?.invoice?.DETENTION_CHARGES_AT_SOURCE}:
                  </div>
                  <div className="colaps-field dark__span">
                    {sourceDetentionCharges} ₹
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {userLang?.invoice?.DETENTION_CHARGES_AT_DESTINATON}:
                  </div>
                  <div className="colaps-field dark__span">
                    {destinationDetentionCharges} ₹
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {userLang?.invoice?.LATE_DELIVERY_CHARGES} ({" "}
                    {userLang?.invoice?.DED}):
                  </div>
                  <div className="colaps-field dark__span">
                    <span style={{ color: "red" }}>
                      {this.props.invoiceDet?.calculations?.lateDeliveryCharges}{" "}
                      ₹
                    </span>
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {" "}
                    {userLang?.invoice?.UNLOADING_CHARGES}:
                  </div>
                  <div className="colaps-field dark__span">
                    {this.props.invoiceDet?.calculations?.unloadingCharges} ₹
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {" "}
                    {userLang?.invoice?.SHORTAGE} ( {userLang?.invoice?.DED}):
                  </div>
                  <div className="colaps-field dark__span">
                    <span style={{ color: "red" }}>
                      {this.props.invoiceDet?.calculations?.shortageCharges} ₹
                    </span>
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {userLang?.invoice?.DAMAGE} ( {userLang?.invoice?.DED}):{" "}
                    {this.state.damageCostEdit ? (
                      <input
                        type="number"
                        style={{ width: "50%" }}
                        onChange={this.deductionCostHandler}
                        onKeyDown={this.closeDeductionInput}
                      />
                    ) : (
                      <>
                        <span style={{ color: "red" }}>
                          {" " + this.state.defaultDamageCost
                            ? this.state.defaultDamageCost + " ₹"
                            : this.state.deductionCost + "₹"}
                        </span>

                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({ damageCostEdit: true });
                          }}
                        >
                          <i className="fa fa-edit t-edit m-2 darkInvoiceModalIcons"></i>
                        </span>
                      </>
                    )}
                  </div>
                </Grid>
                <Grid item xs={6} md={3}></Grid>

                <Grid item xs={12} md={3}>
                  <div className="colaps-field light__span">
                    <p>
                      {userLang?.invoice?.ORIGINAL}:
                      <span style={{ color: "red" }}>
                        {" " + this.state.defaultDamageCost} ₹
                      </span>{" "}
                    </p>
                    <p>
                      {userLang?.invoice?.REVISED}:{" "}
                      <span style={{ color: "red" }}>
                        {" " + this.state.deductionCost
                          ? this.state.deductionCost
                          : "---"}
                      </span>
                    </p>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  style={{
                    backgroundColor: this.props.mode ? "#24243C" : "#F1F5F7",
                    marginTop: "20px",
                    padding: "21px",
                  }}
                >
                  <Row>
                    <Col xs={12}>
                      <div
                        className="colaps-label light__span"
                        style={{
                          left: "0%",
                        }}
                      >
                        <label className="dark__span">
                          {" "}
                          {userLang?.invoice?.OTHER_CHARGES} (
                          {userLang?.invoice?.ADD_DEDUCTION}):{" "}
                        </label>
                      </div>
                    </Col>
                    <Col xs={12} md={5}>
                      <div className="d-block flex-column">
                        <div
                          className="colaps-label"
                          style={{
                            left: "0%",
                          }}
                        >
                          {" "}
                          <label className="light__span">
                            {userLang?.tracking?.DESCRIPTION}
                          </label>
                        </div>
                        <div>
                          <input
                            style={{ width: "100%" }}
                            onChange={this.costDescriptionHandler}
                            value={this.state.costDescription}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <div className="d-block flex-column ">
                        <div
                          className="colaps-label light__span"
                          style={{
                            left: "0%",
                          }}
                        >
                          <label> {userLang?.invoice?.EXPENSE_ENTRY}</label>
                        </div>
                        <div>
                          <input
                            style={{ width: "100%" }}
                            onChange={this.costValueHandler}
                            value={this.state.costValue}
                            type="number"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={3} className="mobileExpenseColumn">
                      <Button
                        className="btn btn-primary btnExpense "
                        onClick={this.expenseAddHandler}
                        disabled={this.state.submitDisable}
                      >
                        {userLang?.invoice?.ADD_EXPENSE}
                      </Button>
                     
                      <Button
                        className="btn btn-primary btnExpense ml-2"
                        onClick={this.resetExpenseHandler}                       
                      >
                        Reset
                      </Button>
                      
                    </Col>
                    

                   
                    <Col xs={12}>
                      <div className="d-flex justify-content-around">
                        {this.state.costArray.length ? (
                          <div>
                            {this.state.costArray.map((e, index) => (
                              <Row
                                key={index}
                                className="m-1"
                                style={{ width: "300px" }}
                              >
                                <Col className="text-left font-weight-bold ml-1">
                                  {e.label}
                                </Col>
                                <Col className="float-right mr-3">
                                  {e.charges > 0 ? (
                                    <span>{` ₹ ` + e.charges}</span>
                                  ) : (
                                    <span style={{ color: "red" }}>
                                      {` ₹ ` + e.charges}
                                    </span>
                                  )}
                                </Col>
                              </Row>
                            ))}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="saveButton pt-3">
                    <MDBBtn
                      type="button"
                      color="primary"
                      style={{ width: "100%" }}
                      onClick={() => {
                        this.submitChanges();
                      }}
                      disabled={
                        !this.state.freightCost || this.state.freightCostEdit
                      }
                    >
                      {userLang?.common?.SAVE}
                    </MDBBtn>
                  </div>
                </Grid>
                <Grid item xs={6} md={3}></Grid>
                <Grid item xs={6} md={6}></Grid>
              </Grid>

              {/* <div className="row">
                <div className="colsp">
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {" "}
                    {userLang?.invoice?.TRUCK_NO}:
                  </div>
                  <div
                    className={`colaps-field ${
                      this.props.mode ? "dark__span" : ""
                    }`}
                  >
                    {vehicle?.regNo || "---"}
                  </div>
                </div>
                <div className="colsp">
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {userLang?.invoice?.TRUCK_TYPE}:
                  </div>
                  <div
                    className={`colaps-field ${
                      this.props.mode ? "dark__span" : ""
                    }`}
                  >
                    {vehicle?.type?.label}
                  </div>
                </div>
                <div className="colsp">
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {" "}
                    {userLang?.invoice?.TRIP_STATUS}:
                  </div>
                  <div
                    className={`colaps-field textRole ${
                      this.props.mode ? "darkmodeStatusMode" : ""
                    }`}
                  >
                    {status}
                  </div>
                </div>
                <div className="colsp">
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {userLang?.invoice?.DATE_OF_THE_PLACEMENT}:
                  </div>
                  <div
                    className={`colaps-field ${
                      this.props.mode ? "dark__span" : ""
                    }`}
                  >
                    {startTime?.split("T")[0]}
                  </div>
                </div>
                <div className="colsp">
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {" "}
                    {userLang?.invoice?.STATUS_EPOD}:
                  </div>
                  <div
                    className={`colaps-field ${
                      this.props.mode ? "darkmodeStatusMode" : ""
                    }`}
                  >
                    Completed
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="colsp">
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {" "}
                    {userLang?.invoice?.TOTAL_DISTANCE}:
                  </div>
                  <div
                    className={`colaps-field ${
                      this.props.mode ? "dark__span" : ""
                    }`}
                  >
                    {distance} KMs
                  </div>
                </div>
                <div className="colsp">
                  <div
                    className={`colaps-label required_field ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    Freight Cost:{" "}
                  </div>
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {freightCostEdit ? (
                      <>
                        <input
                          type="number"
                          placeholder="Cost"
                          style={{ width: "50%" }}
                          onChange={this.freightCostHandler}
                          onKeyDown={this.closeFreightCostInput}
                          className={
                            this.props.mode ? "darkInvoiceModalCharge" : ""
                          }
                        />
                      </>
                    ) : (
                      <>
                        {freightCost}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({ freightCostEdit: true });
                          }}
                        >
                          <i
                            className={`fa fa-edit t-edit m-2 ${
                              this.props.mode ? "darkInviceModalIcons" : ""
                            }`}
                          ></i>
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <div className="colsp">
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {" "}
                    {userLang?.invoice?.LOADING_CHARGES}:
                  </div>
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {loadingChargesEdit ? (
                      <>
                        <input
                          type="number"
                          placeholder="Cost"
                          style={{ width: "50%" }}
                          onChange={this.loadingChargeHandler}
                          onKeyDown={this.closeLoadingChargeInput}
                          className={
                            this.props.mode ? "darkInvoiceModalCharge" : ""
                          }
                        />
                      </>
                    ) : (
                      <>
                        {"₹ " + loadingCharges}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({ loadingChargesEdit: true });
                          }}
                        >
                          <i
                            className={`fa fa-edit t-edit m-2 ${
                              this.props.mode ? "darkInviceModalIcons" : ""
                            }`}
                          ></i>
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <div className="colsp">
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {userLang?.invoice?.MULTI_POINT_PICK_CHARGES}:
                  </div>
                  <div
                    className={`colaps-field ${
                      this.props.mode ? "dark__span" : ""
                    }`}
                  >
                    {multipointPickupCharges} ₹
                  </div>
                </div>
                <div className="colsp">
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {userLang?.invoice?.DETENTION_CHARGES_AT_SOURCE}:
                  </div>
                  <div className="colaps-field">{sourceDetentionCharges} ₹</div>
                </div>
              </div>
              <div className="row">
                <div className="colsp">
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {userLang?.invoice?.DETENTION_CHARGES_AT_DESTINATON}:
                  </div>
                  <div
                    className={`colaps-field ${
                      this.props.mode ? "dark__span" : ""
                    }`}
                  >
                    {destinationDetentionCharges} ₹
                  </div>
                </div>
                <div className="colsp">
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {userLang?.invoice?.LATE_DELIVERY_CHARGES} ({" "}
                    {userLang?.invoice?.DED}):
                  </div>
                  <div
                    className={`colaps-field ${
                      this.props.mode ? "dark__span" : ""
                    }`}
                  >
                    <span style={{ color: "red" }}>
                      {this.props.invoiceDet?.calculations?.lateDeliveryCharges}{" "}
                      ₹
                    </span>
                  </div>
                </div>
                <div className="colsp">
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {" "}
                    {userLang?.invoice?.UNLOADING_CHARGES}:
                  </div>
                  <div className="colaps-field">
                    {this.props.invoiceDet?.calculations?.unloadingCharges} ₹
                  </div>
                </div>

                <div className="colsp">
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {" "}
                    {userLang?.invoice?.SHORTAGE} ( {userLang?.invoice?.DED}):
                  </div>
                  <div
                    className={`colaps-field ${
                      this.props.mode ? "dark__span" : ""
                    }`}
                  >
                    <span style={{ color: "red" }}>
                      {this.props.invoiceDet?.calculations?.shortageCharges} ₹
                    </span>
                  </div>
                </div>
              </div>
              <div style={{ width: "20%", float: "left", marginTop: "16px" }}>
                <div>
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {userLang?.invoice?.DAMAGE} ( {userLang?.invoice?.DED}):{" "}
                    {this.state.damageCostEdit ? (
                      <input
                        type="number"
                        style={{ width: "50%" }}
                        onChange={this.deductionCostHandler}
                        onKeyDown={this.closeDeductionInput}
                        className={
                          this.props.mode ? "darkInvoiceModalCharge" : ""
                        }
                      />
                    ) : (
                      <>
                        <span style={{ color: "red" }}>
                          {" " + this.state.defaultDamageCost
                            ? this.state.defaultDamageCost + " ₹"
                            : this.state.deductionCost + "₹"}
                        </span>

                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({ damageCostEdit: true });
                          }}
                        >
                          <i
                            className={`fa fa-edit t-edit m-2 ${
                              this.props.mode ? "darkInviceModalIcons" : ""
                            }`}
                          ></i>
                        </span>
                      </>
                    )}
                  </div>
                  <div
                    className={`colaps-field ${
                      this.props.mode ? "dark__span" : ""
                    }`}
                  >
                    <p>
                      {userLang?.invoice?.ORIGINAL}:
                      <span style={{ color: "red" }}>
                        {" " + this.state.defaultDamageCost} ₹
                      </span>{" "}
                    </p>
                    <p>
                      {userLang?.invoice?.REVISED}:{" "}
                      <span style={{ color: "red" }}>
                        {" " + this.state.deductionCost
                          ? this.state.deductionCost
                          : "---"}
                      </span>
                    </p>
                  </div>
                  <div className="saveButton" style={{ marginTop: "64px" }}>
                    <MDBBtn
                      type="button"
                      color="primary"
                      style={{ width: "100%" }}
                      onClick={() => {
                        this.submitChanges();
                      }}
                      disabled={
                        !this.state.freightCost || this.state.freightCostEdit
                      }
                    >
                      {userLang?.common?.SAVE}
                    </MDBBtn>
                  </div>
                </div>
              </div>
              <div style={{ width: "80%", float: "right" }}>
                <div className="col-md-9">
                  <div
                    className="row last mt-1"
                    style={{ justifyContent: "space-around" }}
                  >
                    <div style={{ width: "20%" }}>
                      <div className="col-md-2">
                        <div
                          className={`colaps-label ${
                            this.props.mode ? "light__span" : ""
                          }`}
                        >
                          <label>
                            {" "}
                            {userLang?.invoice?.OTHER_CHARGES} (
                            {userLang?.invoice?.ADD_DEDUCTION}):{" "}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div
                        className={`colaps-label ${
                          this.props.mode ? "light__span" : ""
                        }`}
                      >
                        {" "}
                        <label>{userLang?.tracking?.DESCRIPTION}</label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div
                        className={`colaps-label ${
                          this.props.mode ? "light__span" : ""
                        }`}
                      >
                        <label> {userLang?.invoice?.EXPENSE_ENTRY}</label>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "80%",
                      textAlign: "end",
                      marginLeft: "126px",
                    }}
                  >
                    <div className="d-flex justify-content-around">
                      {this.state.costArray.length ? (
                        <div>
                          {this.state.costArray.map((e, index) => (
                            <Row
                              key={index}
                              className="m-1"
                              style={{ width: "300px" }}
                            >
                              <Col className="text-left font-weight-bold ml-1">
                                {e.label}
                              </Col>
                              <Col className="float-right mr-3">
                                {e.charges > 0 ? (
                                  <span>{` ₹ ` + e.charges}</span>
                                ) : (
                                  <span style={{ color: "red" }}>
                                    {` ₹ ` + e.charges}
                                  </span>
                                )}
                              </Col>
                            </Row>
                          ))}
                        </div>
                      ) : null}
                    </div>
                    <div className="d-flex justify-content-end">
                      <input
                        style={{ width: "25%" }}
                        onChange={this.costDescriptionHandler}
                        value={this.state.costDescription}
                        className={
                          this.props.mode ? "darkInvoiceModalCharge" : ""
                        }
                      />
                      <input
                        style={{ width: "35%" }}
                        onChange={this.costValueHandler}
                        value={this.state.costValue}
                        type="number"
                        className={
                          this.props.mode ? "darkInvoiceModalCharge" : ""
                        }
                      />
                      <button
                        style={{ height: "26px", lineHeight: "16px" }}
                        className="btn btn-primary btn-sm ml-1"
                        onClick={this.expenseAddHandler}
                      >
                        {userLang?.invoice?.ADD_EXPENSE}
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
            </MDBModalBody>
          </MDBModal>
        </MDBContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { updateBill, fetchBills })(
  InvoiceModal
);
