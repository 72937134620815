import React, { Component } from "react";
import { Row, Col, Container } from "reactstrap";
import { Card } from "reactstrap";
import { Button } from "@mui/material";
import Select,{components} from "react-select";
import TextField from "@mui/material/TextField";
import DateRangePicker from "@mui/lab/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Box from "@mui/material/Box";
import MomentUtils from "@date-io/moment";

const DropdownIndicator = props => {
  const iconSvg = props.selectProps.selectProps
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {iconSvg}
      </components.DropdownIndicator>
    )
  );
};


class TransportHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const customStyles = {
      control: (style) => {
        return {
          ...style,
          width: "300px",
          height: "40px",
        };
      },
      indicatorsContainer: (style) => {
        return {
          ...style,
          backgroundColor: "#8e9af8",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          backgroundColor: "#8e9af8",
        };
      },
      dropdownIndicator: (style) => {
        return {
          ...style,

          backgroundColor: "#0066cc",
        };
      },
    };
    return (
      <>
        <Card className="dashboard-header"
          // fluid
          style={{
            backgroundColor: "white",
            height: "69px",
            marginTop: "-140px",
          }}
        >
          <Row>
            <Col xs="5">
              <div className="d-flex flex-row pt-3 pl-2">
                <div className="pr-2">
                  <Button
                    className="transporter-dashboard-btn-operations"
                    variant="outlined"
                   
                  >
                    Operations
                  </Button>
                </div>
                <div className="pr-2">
                  <Button className="transporter-dashboard-btn-transpoter"
                    variant="success"
                    
                    onClick={() => {
                      this.props.history.push({
                        pathname: "/transporterDashboard",
                       
                      });
                    }}
                  >
                    Transporter
                  </Button>
                </div>
                <div className="pr-2">
                  {" "}
                  <Button
                  className="operational-dashboard-btn-invoice"
                    onClick={() => {
                      this.props.history.push({
                        pathname: "/InvoiceDashboard",
                      });
                    }}
                    variant="outlined"
                    
                  >
                    Invoice
                  </Button>
                </div>
              </div>
            </Col>
            <Col xs="7">
              <div className="d-flex flex-row-reverse pt-3 pr-2">
                <div>
                  <Button className="operational-dashboard-btn-reset"
                    variant="outlined"
            
                    onClick={() => this.props.onReset()}
                  >
                    Reset
                  </Button>
                </div>
                <div className="mr-2">
                  <div className="datepicker-selector"
                    style={{
                      width: "290px",
                      height: "40px",
                      // border: "1px solid #c0c0c0",
                      borderRadius:"5px"
                    }}
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      utils={MomentUtils}
                    >
                      <DateRangePicker
                        calendars={1}
                        startText=""
                        endText=""
                        format={"MM-DD-YYYY"}
                        value={this.props.datevalues}
                        onChange={(datevalues) =>
                          this.props.onChangeDatePicker(datevalues)
                        }
                        renderInput={(startProps, endProps) => (
                          <React.Fragment>
                            <TextField {...startProps} />
                            <Box sx={{ mx: 1 }}> ~ </Box>
                            <TextField {...endProps} />
                          </React.Fragment>
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="pr-2">
                  <Select
                    isMulti
                    autoFocus={true}
                    isSearchable
                    styles={customStyles}
                    width="150px"
                    value={this.props.selectedHopValues}
                    options={this.props.hopOptions}
                    onChange={this.props.onChangeHop}
                    components={{ DropdownIndicator }}
                    selectProps={<svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
                    <path d="M12.455 6.7275C12.455 11.1825 6.727 15.0005 6.727 15.0005C6.727 15.0005 1 11.1825 1 6.7275C1 5.20847 1.60343 3.75166 2.67755 2.67755C3.75166 1.60343 5.20847 1 6.7275 1C8.24653 1 9.70334 1.60343 10.7775 2.67755C11.8516 3.75166 12.455 5.20847 12.455 6.7275V6.7275Z" stroke="#2800FC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7 9.00049C8.10457 9.00049 9 8.10506 9 7.00049C9 5.89592 8.10457 5.00049 7 5.00049C5.89543 5.00049 5 5.89592 5 7.00049C5 8.10506 5.89543 9.00049 7 9.00049Z" stroke="#2800FC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>}
                  />
                </div>
               
              </div>
            </Col>
          </Row>
        </Card>
      </>
    );
  }
}
export default TransportHeader;
