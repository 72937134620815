import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import "@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
// import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

class EmployeeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Employee", link: "employees" },
        { title: "Add Employee", link: "#" },
      ],
      form: {
        // uname: "",
        fname: "",
        lname: "",
        phone: "",
        email: "",
        // role: "",
        // status: "",
        empSerialNo: "",
        empBranchNo: "",
        // bloodGrp: "",
        // emergencyContactPerson: "",
        // emergencyContactNo: ""
      },
      formErrors: {
        // uname: "",
        fname: "",
        lname: "",
        phone: "",
        email: "",
        // role: "",
        // status: "",
        empSerialNo: "",
        empBranchNo: "",
        // bloodGrp: "",
        // emergencyContactPerson: "",
        // emergencyContactNo: ""
      },
    };

    this.bloodGrpList = [
      { value: "O+", label: "O+" },
      { value: "AB", label: "AB" },
      { value: "A", label: "A" },
      { value: "B", label: "B" },
    ];
    this.status = [
      { value: "available", label: "available" },
      { value: "not available", label: "not available" },
    ];
  }

  validateField = (name, value, refValue) => {
    let errorMsg = null;
    switch (name) {
      case "uname":
        if (!value) errorMsg = "Please Enter Name.";
        break;
      case "fname":
        if (!value) errorMsg = "Please Enter First Name.";
        break;
      case "lname":
        if (!value) errorMsg = "Please Enter Last Name.";
        break;
      case "phone":
        if (!value) errorMsg = "Please Enter Phone.";
        break;

      // case "role":
      // 	if (!value) errorMsg = "Please select role.";
      // 	break;
      case "email":
        if (!value) errorMsg = "Please Enter email.";
        else if (
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          )
        )
          errorMsg = "Please enter valid Email.";
        break;

      case "empSerialNo":
        if (!value) errorMsg = "Please Enter empSerialNo.";
        break;
      case "empBranchNo":
        if (!value) errorMsg = "Please Enter empBranchNo.";
        break;
      // case "emergencyContactPerson":
      // 	if (!value) errorMsg = "Please Enter emergencyContactPerson.";
      // 	break;
      // case "emergencyContactNo":
      // 	if (!value) errorMsg = "Please Enter emergencyContactNo.";
      // 	break;
      // case "bloodGrp":
      // 	if (!value) errorMsg = "Please Enter bloodGrp.";
      // 	break;
      // case "status":
      // 	if (!value) errorMsg = "Please Click Status.";
      // 	break;

      default:
        break;
    }
    return errorMsg;
  };

  validateForm = (form, formErrors, validateFunc) => {
    const errorObj = {};
    Object.keys(formErrors).map((x) => {
      let refValue = null;

      const msg = validateFunc(x, form[x], refValue);
      if (msg) errorObj[x] = msg;
    });
    return errorObj;
  };

  handleSubmit = () => {
    const { form, formErrors } = this.state;

    const errorObj = this.validateForm(form, formErrors, this.validateField);
    if (Object.keys(errorObj).length !== 0) {
      this.setState({ formErrors: { ...formErrors, ...errorObj } });
      return false;
    }
  };
  validateNumber = (evt) => {
    var theEvent = evt || window.event;

    // Handle paste
    if (theEvent.type === "paste") {
      var key = theEvent.clipboardData.getData("text/plain");
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    const { form, formErrors } = this.state;
    let formObj = {};

    formObj = {
      ...form,
      [name]: value,
    };
    this.setState({ form: formObj }, () => {
      if (!Object.keys(formErrors).includes(name)) return;
      let formErrorsObj = {};

      const errorMsg = this.validateField(
        name,
        name === "language" ? this.state.form["language"] : value
      );
      formErrorsObj = { ...formErrors, [name]: errorMsg };
      this.setState({ formErrors: formErrorsObj });
    });
  };
  render() {
    const { form, formErrors } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Add Employee"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <form>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label>First Name</Label>

                            <Input
                              className="form-control"
                              type="text"
                              value={form.fname}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "fname",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="First Name"
                            />
                            {formErrors.fname && (
                              <span className="err">{formErrors.fname}</span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label>Last Name</Label>
                            <Input
                              className="form-control"
                              type="text"
                              value={form.lname}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "lname",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="Last Name"
                            />
                            {formErrors.lname && (
                              <span className="err">{formErrors.lname}</span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label>Email</Label>
                            <Input
                              className="form-control"
                              type="text"
                              value={form.email}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "email",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="Email"
                            />
                            {formErrors.email && (
                              <span className="err">{formErrors.email}</span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label>Phone</Label>
                            <Input
                              className="form-control"
                              type="text"
                              value={form.phone}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "phone",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="98989898989"
                              onKeyPress={this.validateNumber}
                            />
                            {formErrors.phone && (
                              <span className="err">{formErrors.phone}</span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        {/* <Col lg="6">
													<FormGroup className="select2-container">
														<Label>Status</Label>
														<Select
															value={this.status.find(x => x.value === form.status)}

															onChange={e =>
																this.handleChange({
																	target: {
																		name: "status",
																		value: e.value
																	}
																})
															}
															options={this.status}
															classNamePrefix="select2-selection"

														/>

														{formErrors.status && <span className="err">{formErrors.status}</span>}
													</FormGroup>
												</Col> */}
                        {/* <Col lg="6">
													<FormGroup className="select2-container">
														<Label>Emergency Contact Person</Label>
														<Input className="form-control" type="text"
															value={form.emergencyContactPerson} onChange={(e) =>
																this.handleChange({
																	target: {
																		name: "emergencyContactPerson",
																		value: e.target.value
																	}
																})
															}
															placeholder="Ex: Smith"
														/>
														{formErrors.emergencyContactPerson && <span className="err">{formErrors.emergencyContactPerson}</span>}
													</FormGroup>
												</Col> */}
                      </Row>
                      <Row>
                        {/* <Col lg="6">
													<FormGroup className="select2-container">
														<Label>Blood Group</Label>
														<Select
															value={this.bloodGrpList.find(x => x.value === form.startLoc)}

															onChange={e =>
																this.handleChange({
																	target: {
																		name: "bloodGrp",
																		value: e.value
																	}
																})
															}
															options={this.bloodGrpList}
															classNamePrefix="select2-selection"
														/>

														{formErrors.bloodGrp && <span className="err">{formErrors.bloodGrp}</span>}
													</FormGroup>
												</Col>
												<Col lg="6">
													<FormGroup className="select2-container">
														<Label>Emergency Contact No</Label>
														<Input className="form-control" type="text"
															value={form.emergencyContactNo} onChange={(e) =>
																this.handleChange({
																	target: {
																		name: "emergencyContactNo",
																		value: e.target.value
																	}
																})
															}
															placeholder="98989898989"

															onKeyPress={this.validateNumber}
														/>
														{formErrors.emergencyContactNo && <span className="err">{formErrors.emergencyContactNo}</span>}
													</FormGroup>
												</Col> */}
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label>Employee Branch No</Label>
                            <Input
                              className="form-control"
                              type="text"
                              value={form.empBranchNo}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "empBranchNo",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="98989898989"
                            />
                            {formErrors.empBranchNo && (
                              <span className="err">
                                {formErrors.empBranchNo}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label>Employee Serial No</Label>
                            <Input
                              className="form-control"
                              type="text"
                              value={form.empSerialNo}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "empSerialNo",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="98989898989"
                            />
                            {formErrors.empSerialNo && (
                              <span className="err">
                                {formErrors.empSerialNo}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup className="mb-0 text-center">
                        <div>
                          <Button
                            type="button"
                            color="btn btn-primary"
                            className="mr-1"
                            onClick={this.handleSubmit}
                          >
                            Submit
                          </Button>{" "}
                          <Button type="reset" color="secondary">
                            Cancel
                          </Button>
                        </div>
                      </FormGroup>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default EmployeeForm;
