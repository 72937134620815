import React, { Component } from 'react'
import ReactApexCharts from 'react-apexcharts'
import moment from 'moment'
export default class CarbonApexChar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      chartType: this.props.chartType,
      series: [
        {
          name: 'Carbon Emitted(co2)',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          color: 'rgba(10, 179, 140, .7)',
        },
      ],
      widthCarbon: '',
      options: {
        tooltip: {
          shared: false,
          intersect: true,
          y: {
            formatter: (value) => {
              return value + 'kg' + ' ' + 'Co2'
            },
          },
          
        },
        markers: {
          size: 5,
          colors: 'rgba(40, 0, 252, 1)',
          shape: 'circle',
          radius: 3,
          strokeColor: 'rgba(241, 245, 247, 1)',
        },
        chart: {
          width: '100%',
          type: "bar",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        legend: {
          show: false,
          position: 'bottom',
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
          curve: 'smooth',
        },
        grid :{
          show:false
        },

        xaxis: {
          startAtZero: true,
        },

        xaxis: {
          axisBorder: {
            show: true,
            color: '#78909C',
            offsetX: -10,
            offsetY: 0,
          },

          // tickAmount: 11,
          tickPlacement: 'between',
          categories: [
            'JAN',
            'FEB',
            'MAR',
            'APR',
            'MAY',
            'JUNE',
            'JULY',
            'AUG',
            'SEPT',
            'OCT',
            'NOV',
            'DEC',
          ],
        },

        yaxis: {
          forceNiceScale: true,
          labels: {
            formatter: function (val) {
              return val + 'kg' + ' ' + 'Co2'
            },
            rotate:0,
          },
          show: true,
          showAlways: true,
          axisBorder: {
            show: true,
            color: '#78909C',
            offsetX: 0,
            offsetY: +10,
          },

          tickPlacement: 'between',
        },
      },
    }
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ widthCarbon: '100%' })
    }, 1000)
    this.setState({ widthCarbon: ' ' })
  }
  componentDidUpdate(prevProps) {
    if (prevProps.carbonEmision != this.props.carbonEmision || prevProps.chartType != this.props.chartType) {
      if (this.props.carbonEmision !== undefined) {
        let carbonEmisionData = this.props?.carbonEmision
        // console.log("carbonEmisionData",carbonEmisionData)
        let carbonFootPrint = []
        let totalMonth = 12
        let years =[];
         let canbonYear=[];
         let WeekDay = 7;
         let CarbonWeek =[];
         let quater = 4
         let CarbonQuater=[];
         let date =[];
         let CarbonDate=[];
         if(this.props.selectedFilter.value == "month"){

        for (let i = 0; i < totalMonth; i++) {
          let checkData = carbonEmisionData.filter((ele) => {
            // console.log("ele",ele)
            return ele?.tenure == i + 1;
          });
          // console.log("check data", checkData)
          if (checkData?.length > 0) {
            carbonFootPrint.push(Number(checkData[0].carbonEmission.toFixed(2)));
          } else {
            carbonFootPrint.push(0);
          }
        }
       
          this.setState(
            {
              chartType: this.props.chartType,
              series: [
                {
                  data:carbonFootPrint
              
                },
              ],
              options: {
                xaxis: {
                  categories: [
                    'JAN',
                    'FEB',
                    'MAR',
                    'APR',
                    'MAY',
                    'JUNE',
                    'JULY',
                    'AUG',
                    'SEPT',
                    'OCT',
                    'NOV',
                    'DEC',
                  ],
                },
                chart:{type: this.props.chartType}
              },
              widthCarbon: '100%',
            },
            () => {
              // console.log("tthis.state",this.state)

             }
          )
       
      }
       else if ( this.props.selectedFilter.value == "year"){
         
        carbonEmisionData.forEach((ele)=>{
           years.push(ele?.tenure)
           canbonYear.push(ele?.carbonEmission)
        })
        this.setState(
          {
            chartType: this.props.chartType,
            series: [
              {
                data:[0,...canbonYear]
            
              },
            ],
            options: {
              xaxis: {
                categories: [years[0]-1,...years],
              },
              chart:{type: this.props.chartType}
            },
            widthCarbon: '100%',
          },
          () => {
            // console.log("tthis.state",this.state)

           }
        )
       }
       else if ( this.props.selectedFilter.value == "weekDay"){
        for (let i = 0; i < WeekDay; i++) {
          let checkData = carbonEmisionData.filter((ele) => {
            // console.log("ele",ele)
            return ele?.tenure == i;
          });
          // console.log("check data", checkData)
          if (checkData?.length > 0) {
            CarbonWeek.push(Number(checkData[0].carbonEmission.toFixed(2)));
          } else {
            CarbonWeek.push(0);
          }
        }
        this.setState(
          {
            chartType: this.props.chartType,
            series: [
              {
                data:CarbonWeek
            
              },
            ],
            options: {
              xaxis: {
                categories: [
                  "Sunday",
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                ],
              },
             
              chart:{type: this.props.chartType}
            },
            widthCarbon: '100%',
          },
          () => {
            console.log("tthis.state",this.state)

           }
        )
       
       }
       else if ( this.props.selectedFilter.value == "quarter"){
        for (let i = 0; i < quater; i++) {
          let checkData = carbonEmisionData.filter((ele) => {
            // console.log("ele",ele)
            return ele?.tenure == i+1;
          });
          // console.log("check data", checkData)
          if (checkData?.length > 0) {
            CarbonQuater.push(Number(checkData[0].carbonEmission.toFixed(2)));
          } else {
            CarbonQuater.push(0);
          }
        }
        this.setState(
          {
            chartType: this.props.chartType,
            series: [
              {
                data:CarbonQuater
            
              },
            ],
            options: {
              xaxis: {
                categories: [
                  "Quater1",
                  "Quater2 ",
                  "Quater3",
                  "Quater4 ",
                ],
              },
              chart:{type: this.props.chartType}
            },
            widthCarbon: '100%',
          },
          () => {
            console.log("tthis.state",this.state)

           }
        )
       
       }
       else if ( this.props.selectedFilter.value == "date" || this.props.selectedFilter.value == "week"){
         
        carbonEmisionData.forEach((ele)=>{
           date.push(ele?.tenure)
           CarbonDate.push(ele?.carbonEmission)
        })
        carbonEmisionData?.length >1 ?
        this.setState(
          {
            chartType: this.props.chartType,
            series: [
              {
                data:[...CarbonDate]
            
              },
            ],
            options: {
              xaxis: {
                categories: date,
              },
              
              chart:{type: this.props.chartType}
            },
            widthCarbon: '100%',
          },
          () => {
            // console.log("tthis.state",this.state)

           }
        ):this.setState(
          {
            chartType: this.props.chartType,
            series: [
              {
                data:[0,...CarbonDate]
            
              },
            ],
            options: {
              xaxis: {
                categories: [date[0]-1, date],
              },
              
              chart:{type: this.props.chartType}
            },
            widthCarbon: '100%',
          },
          () => {
            // console.log("tthis.state",this.state)

           }
        )
       
       }
      
    }
    }
  }
  render() {
    return (
      <div className='cursor-pointer freightCostApexchart'>
        <ReactApexCharts
          options={this.state.options}
          series={this.state.series}
          type={this.state.chartType ? this.state.chartType : "line"}
          width={this.state.widthCarbon ? this.state.widthCarbon : '100%'}
          height={350}
        />
      </div>
    )
  }
}
