import React, { Component } from "react";
import { Row, Col, Card, Button } from "reactstrap";
import { InputGroup, Input } from "reactstrap";
import SeachIcon from "./assets/search.png";

class QuickSearchFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hops: [],
      vehicleTypes: [],
      // searchBar: [],
      searchFilters: {},
      dateValue: [null, null],
      showCalender: false,
    };
  }

  render() {
    let mode = localStorage.getItem('Theme')
    return (
      <React.Fragment>
        <Card className={`${mode === "dark__mode"?"darkBgQuickFilter":""}`}>
          <div className="quickStylesBar">
            <Row>
              <Col sm={9}></Col>
              {/* <Col sm={2}>
                <div className="input-border mt-2">
                  <InputGroup>
                    <Input
                      className="form-control"
                      type="text"
                      name="filters"
                      placeholder="Search"
                      aria-label="Search"
                      value={this.props?.filterValue}
                      onKeyDown={(event) => this.props?.enterCheck(event)}

                      onChange={(e) => this.props?.change(e)}
                    />
                    <span className="pt-2 pb-2 input-line pl-1 ml-1"></span>
                    <span
                      className="span-img"
                      onClick={() => this.props?.filterList()}
                    >
                      <img className="pl-1" src={SeachIcon}></img>
                    </span>
                  </InputGroup>
                </div>
              </Col>
              <Col sm={1} className="pr-2">
                <div style={{ width: "70%", float: "left" }}>
                  <Button onClick={() => this.props?.reset()} className="reset">
                    Reset
                  </Button>
                </div>
              </Col> */}
            </Row>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default QuickSearchFilters;
