import { all } from "redux-saga/effects";

//public
import accountSaga from "./auth/register/saga";
import loginSaga from "./auth/login/saga";
import forgetSaga from "./auth/forgetpwd/saga";
import TripSaga from "./trip/saga";
import TripCountSaga from "./dashboard/saga"
import DriverSaga from "./driver/saga";
import OrderSaga from "./order/saga";
import VehicleSaga from "./vehicle/saga";
import DealerSaga from "./dealer/saga";
import EmployeeSaga from "./employee/saga";
import HopSaga from "./hop/saga";
import AcessPolicySaga from "./accessPolicy/saga";
import IndentSaga from "./indent//saga";
import BillingSaga from "./billing/saga";

import TransporterSaga from "./transporter/saga";
import VehicleTypeSaga from "./vehicleType/saga";
import RouteSaga from "./route/saga";
import CommonSaga from "./common/saga";
import ReportsSaga from "./Reports/saga";
import loadOptimizationSaga from "./LoadOptimization/saga";
import vehiclePlacementReportSaga from "./vehiclePlacement/saga"
import OnTimeDeliveryReportSaga from "./OntimeDeliveryReports/saga"

export default function* rootSaga() {
  yield all([
    //public
    accountSaga(),
    loginSaga(),
    forgetSaga(),
    TripSaga(),
    TripCountSaga(),
    DriverSaga(),
    OrderSaga(),
    VehicleSaga(),
    DealerSaga(),
    EmployeeSaga(),
    HopSaga(),
    AcessPolicySaga(),
    IndentSaga(),
    TransporterSaga(),
    VehicleTypeSaga(),
    RouteSaga(),
    CommonSaga(),
    BillingSaga(),
    ReportsSaga(),
    loadOptimizationSaga(),
    vehiclePlacementReportSaga(),
    OnTimeDeliveryReportSaga()
  ]);
}
