import React, { Component } from "react";
import ReactApexCharts from "react-apexcharts";
export default class MobileFreightCost extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartType: this.props.chartType,
      series: [
        {
          name: "Freight Cost",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          color: "rgba(10, 179, 140, .7)",
        },
      ],
      options: {
        tooltip: {
          followCursor: true,
        },
        markers: {
          size: 5,
          colors: "rgba(40, 0, 252, 1)",
          shape: "circle",
          radius: 3,
          strokeColor: "rgba(241, 245, 247, 1)",
        },
        chart: {
          width: "100%",
          //   height: 300,
          type: "area",
          //   foreColor: "#ccc",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        legend: {
          show: false,
          position: "bottom",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
          curve: "straight",
        },

        xaxis: {
          startAtZero: true,
        },

        xaxis: {
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: -10,
            offsetY: 0,
          },
          labels:{
           rotate:0,
           styles:{
            paddingLeft:"10px",
            fontSize:"10px"
           }
          },

          tickAmount: 11,
          tickPlacement: "between",
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        grid: {
          show: false,
        },

        yaxis: {
          forceNiceScale: true,
          show: true,
          showAlways: true,
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: 0,
            offsetY: +10,
          },

          //   tickAmount: 3,
          tickPlacement: "between",
          //   max:60
        },
      },
    };
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps.invoice != this.props.invoice || prevProps.chartType != this.props.chartType) {
      let invoice = this.props?.invoice;
      let invoiceData = [];
      let totalMonth = 12;
      for (let i = 0; i < totalMonth; i++) {
        let checkData = invoice.filter((inv) => {
          return inv.month == i + 1;
        });
        if (checkData.length > 0) {
          invoiceData.push(checkData[0].totalAmount);
        } else {
          invoiceData.push(0);
        }
      }
     this.setState({
       chartType:this.props.chartType,
       series:[{
         data:invoiceData
       }],
       options:{
        chart:{type: this.props.chartType}
       }
     },()=>{
      // console.log("this",this.state.series[0].data)
     })
    }
  };
  render() {
    // console.log("this",this.state.series[0].data)
    // console.log("freight charges", this.props.invoice);
    let darkMode = this.props.darkInvoiceTheme 
    return (
      <div  className={`cursor-pointer freightCostApexchart ${darkMode ? 'apexBgDarkCard' : ''}`}
      >
        <ReactApexCharts
          options={this.state.options}
          series={this.state.series}
          type={this.state.chartType ? this.state.chartType : "line"}
          // width="1330px"
          height={340}
        />
      </div>
    );
  }
}
