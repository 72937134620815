import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Label,
  Input,
  FormGroup,
  Container,
  Card,
  CardBody,
  InputGroup,
  InputGroupAddon,
  CustomInput,
} from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";
import Datetime from "react-datetime";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import DataTable from "../../../Utility/dataTable";
import moment from "moment";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { fetchHops } from "../../../../store/trip/actions";
import { fetchVehicleType } from "../../../../store/vehicle/actions";
import { fetchRoutes } from "../../../../store/route/actions";
import { fetchHopsSourceDestination } from "../../../../store/hop/actions";
import { fetchTransporterSearch } from "../../../../store/transporter/actions";
import { createIndent } from "../../../../store/indent/actions";
import { NotificationManager } from "react-notifications";
import { Redirect } from "react-router-dom";
import { restrict } from "../../../restrict";
import { PERMS } from "../../../../enum/perms.enum";
import { TranspoterHeaderData, searchBar } from "./TranspoterRowHeaders";
import Index from "../../../../components/Common/DataTableNeo/Index";
// import formSideImg from "../../../../assets/images/layouts/indentplanner.png";
import {
  Box,
  Grid,
  Item,
  Close,
  ListItem,
  Input as MaterialInput,
  TextField,
  OutlinedInput,
  FormControl,
} from "@mui/material";
import "./indentDetails.css";

import CancelIndentModal from "../../../CancelIndentModal";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
var yesterday = moment().subtract(1, "day");

const formSideImg = require("../../../../assets/images/" +
  process.env.REACT_APP_INDENT_IMAGE);

const initialState = () => ({
  customText: "down",
  modal: false,

  heads: [],
  source: [],
  destination: [],
  route: [],
  hops: [],
  vehicleType: [],
  transporter: [],
  rejectionHistory: [],
  form: {
    source: "",
    destination: "",
    date: moment(new Date()).add(1, "day").startOf("day").add(15, "hours"),
    expirydate: moment(new Date())
      .add(1, "day")
      .startOf("day")
      .add(11, "hours"),
    route: "",
    vehicleType: "",
    transporter: "",
    description: "",
    region: "",
    fuelRate: "",
    lotName: "",
    laneLot: "",
  },
  offset: 0,
  disableHop: true,
  onSubmitDisable: false,
  actualDate: "",
  via: [],
  check: [],
  loadingPoints: [],
  rejectModal: false,
  userLangs: undefined,
});
var darkMode;

class IndentDetails extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    var Timer = null;

    this.state = initialState();
    this.hops = [];
    this.routes = [];
    this.vehicleType = [];
    this.transporter = [];
    this.startdate = React.createRef();
    this.expirydate = React.createRef();

    (async () => {
      let redirect = await restrict(PERMS.INDENT_CREATE);
      //console.log(redirect, "redirect");

      if (redirect == false) {
        this.props.history.push("/operationsDashboard");
      }
    })();
  }

  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (this.state.userLangs !== userLang || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }
  componentDidMount = () => {
    let offset = this.state.offset;
    let filter = "";
    console.log();
    // setTimeout(() => {
    //   let formData = this.state.form;
    //   formData["expirydate"] = moment("");
    //   this.setState({ form: formData });
    // }, 10000);

    this.sourceDestination();

    if (this.props.location.indent) {
      let indData = this.formSet();
      let { rejectionHistory = [] } = this.props.location.indent;
      this.setState(
        {
          form: { ...indData, vehicleType: indData.vehicleType },
          rejectionHistory,
        },
        () => this.fetchTransporter(indData)
      );
    }
  };

  formSet = () => {
    let formData = {};
    if (this.props.location.indent) {
      const {
        source,
        destination,
        route,
        vehicle,
        transporter,
        description,
        startTime,
        expiryTime,
      } = this.props.location.indent;

      formData = {
        source,
        destination,
        route,
        vehicleType: vehicle,
        expirydate: expiryTime
          ? moment(expiryTime).format("YYYY-MM-DD HH:mm:ss")
          : "---",
        date: startTime
          ? moment(startTime).format("YYYY-MM-DD HH:mm:ss")
          : "---",
        transporter,
        description,
      };

      // this.setState({ form: formData });
    }
    return formData;
  };

  fetchVehicleType = (data) => {
    data = data || {};
    let source = data.source?.value?.id || this.state.form.source?.value?.id;
    let destination =
      data.destination?.value?.id || this.state.form.destination?.value?.id;
    let route = data.route?.value?.id || this.state.form.route?.value?.id;
    this.props.fetchVehicleType({
      callback: (res) => {
        this.vehicleType = [];
        if (res?.data?.length > 0) {
          res.data.forEach((ele) => {
            this.vehicleType.push({
              label: ele.label + " - " + ele.vehicleTypeCode,
              value: {
                id: ele._id,
                // latitude: ele.cordinates ? ele.cordinates[0] : null,
                // longitude: ele.cordinates ? ele.cordinates[1] : null,
              },
            });
          });
          this.setState({ vehicleType: this.vehicleType });
        }
      },
      source,
      destination,
      route,
    });
  };
  fetchTransporter = (data) => {
    data = data || {};
    let source = data.source?.value?.id || this.state.form.source?.value?.id;
    let destination =
      data.destination?.value?.id || this.state.form.destination?.value?.id;
    let vehicleType =
      data.vehicleType?.type?.value?.id ||
      this.state.form.vehicleType?.value?.id;
    let route = data.route?.value?.id || this.state.form.route?.value?.id;
    this.props.fetchTransporterSearch(
      this.state.offset,
      source,
      destination,
      vehicleType,
      route,
      (res) => {
        this.transporter = [];
        console.log(res.data, "res");
        if (res.data?.docs?.length > 0) {
          res.data.docs.forEach((ele) => {
            let {
              _id,
              name,
              kms,
              leadTime,
              lotNo,
              actualIndents,
              targetIndents,
              cost,
              validityStatus,
            } = ele;
            console.log("LANE LOT", ele.laneLot);
            this.transporter.push({
              _id: _id,
              Name: name,
              KMs: kms ? kms : "N/A",
              LeadTime: leadTime ? leadTime : "N/A",
              LotNo: lotNo ? lotNo : "N/A",
              Actual: actualIndents ? actualIndents : "N/A",
              Target: targetIndents ? targetIndents + "%" : "N/A",
              laneLot: ele.laneLot ? ele.laneLot : "N/A",

              Lane_Lot: ele.laneLot ? (
                <span>
                  <i className="fas fa-code-branch text-success"></i>
                  {" " + ele.laneLot}
                </span>
              ) : (
                <span>On Demand</span>
              ),
              cost: cost ? "Rs. " + cost.main : "unavailable",
              Status: (
                <div>
                  {this.state.rejectionHistory.some(
                    (e) => e.transporter == _id
                  ) ? (
                    <span>
                      <i className="fas fa-circle text-danger"></i>Rejected
                    </span>
                  ) : (
                    <span>-------</span>
                  )}
                </div>
              ),
              Validity:
                validityStatus === "Invalid" ? (
                  <span style={{ color: "red" }}>Invalid</span>
                ) : (
                  <span style={{ color: "green" }}>Valid</span>
                ),
              disabled: this.state.rejectionHistory.some(
                (e) => e.transporter == _id
              ),
              inValid: validityStatus === "Invalid" ? true : false,
              Action: (
                <>
                  <Button
                    style={{
                      backgroundColor: darkMode ? "#24243C" : "#FFF",
                      border: "none",
                    }}
                    disabled={validityStatus === "Invalid" ? true : false}
                  >
                    <svg
                      onClick={() => this.selectTransporter(ele)}
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22 0H4C1.79086 0 0 1.79086 0 4V22C0 24.2091 1.79086 26 4 26H22C24.2091 26 26 24.2091 26 22V4C26 1.79086 24.2091 0 22 0Z"
                        fill={darkMode ? "#222238" : "#F1F5F7"}
                      />
                      <path
                        d="M22 0.25H4C1.92893 0.25 0.25 1.92893 0.25 4V22C0.25 24.0711 1.92893 25.75 4 25.75H22C24.0711 25.75 25.75 24.0711 25.75 22V4C25.75 1.92893 24.0711 0.25 22 0.25Z"
                        stroke="#2800FC"
                        strokeWidth="0.5"
                      />
                      <path
                        d="M13 8V18"
                        stroke="#2800FC"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8 13H18"
                        stroke="#2800FC"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Button>
                </>
              ),
            });
          });
          this.transporter.sort(function (a, b) {
            let lane1 = a.laneLot;
            let lane2 = b.laneLot;
            if (lane1 < lane2) {
              return -1;
            } else return 1;
          });
          //console.log("transp", this.transporter);
        }
        let head = Object.keys(this.transporter[0]);
        //console.log("------", Object.keys(this.transporter[0]));
        //head.shift();
        this.setState(
          {
            transporter: this.transporter,
            heads: head,
          },
          () => {
            console.log(this.state.transporter);
            console.log(this.state.head);
          }
        );
      }
    );
  };
  fetchRoutes = () => {
    let source = this.state.form.source?.value?.id;
    let destination = this.state.form.destination?.value?.id;
    this.props.fetchRoutes(this.state.offset, source, destination, (res) => {
      this.routes = [];
      if (res?.data?.length > 0) {
        res.data.forEach((ele) => {
          this.routes.push({
            label: ele.name,
            value: {
              via: ele.via,
              id: ele._id,
              routeCode: ele.routeCode,
              // name:
              //   ele.address.addressLine1 + ele.address.city + ele.address.state,
              // hopCode: ele.hopCode,
              // latitude: ele.cordinates ? ele.cordinates[0] : null,
              // longitude: ele.cordinates ? ele.cordinates[1] : null,
            },
          });
        });
        this.setState({ route: this.routes }, this.fetchVehicleType);
      }
    });
  };
  route = (via) => {
    let rt = "";
    if (Array.isArray(via) && via.length > 0) {
      via.forEach((r, i) => {
        if (i < via.length - 1) {
          rt += r.label + "-";
        } else {
          rt += r.label;
        }
      });
    }
    return rt;
  };
  sourceDestination = (name = "source", q = "") => {
    let id = this.state.form.source?.value
      ? this.state.form.source?.value.id
      : "";
    let offset = this.state.offset;
    let values = { offset, id };
    if (q != "") {
      values.source = q;
    }
    this.props.fetchHopsSourceDestination(offset, id, q, (res) => {
      this.hops = [];
      if (res?.data?.length > 0) {
        // this.hops = [this.initialVal("Location")];
        res.data.forEach((ele) => {
          this.hops.push({
            label: ele.hopCode + " (" + ele.label + ")",
            value: {
              name:
                ele.address.addressLine1 + ele.address.city + ele.address.state,
              hopCode: ele.hopCode,
              id: ele._id,
              // latitude: ele.cordinates ? ele.cordinates[0] : null,
              // longitude: ele.cordinates ? ele.cordinates[1] : null,
            },
          });
        });
        this.setState({ [name]: this.hops });
      }
    });
  };

  disablePastDt = (current) => {
    return current.isAfter(yesterday);
  };

  customText = () => {
    if (this.state.customText == "up") {
      this.setState({ customText: "down" });
    } else {
      this.setState({ customText: "up" });
    }
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  selectTransporter = (data) => {
    console.log("data", data);
    let formData = this.state.form;
    formData.transporter = { name: data.name, value: { id: data._id } };
    this.setState({ form: formData, modal: !this.state.modal });
  };
  handleSubmitChange = (e) => {
    //console.log("SOURCE: ", e.target.value);
    const name = e.target.name;
    let value = e.target.value;
    let formData = this.state.form;
    if (name == "date" || name == "expirydate") {
      // let dt =new Date(e.target.value).getHours()
      // if(dt==0){
      //   yesterday.add(24,'h');
      // }
      if (name == "date") {
        let isBeforeExpiry = moment(value).isBefore(
          moment(formData["expirydate"])
        );
        //console.log("IS BEFORE EXPIRY: ", isBeforeExpiry);
        this.setState({
          actualDate: isBeforeExpiry ? "" : value,
        });

        //if (isBeforeExpiry)
        //  formData["expirydate"] = moment().add(2, "hours").format();
      }

      value = moment(value).format();
      //console.log("DATE_ ", value);
    }

    formData[`${name}`] = value;
    //console.log("\n==================\n");
    //console.log(formData["date"], ":", formData["expirydate"]);
    this.setState({ form: formData }, () => {
      if (name == "source") {
        this.sourceDestination("destination");
        this.setState({ disableHop: !this.state.disableHop });
      }
      if (name == "destination") {
        this.fetchRoutes();
      }
      if (name == "route") {
        let vi = value?.value?.via;
        this.setState({ via: vi }, () => {
          let ch = [];
          vi.map((v, i) => {
            ch[i] = false;
          });
          this.setState({ check: ch });
        });
        this.fetchVehicleType();
      }
      if (name == "vehicleType") {
        this.fetchTransporter();
      }
    });
  };
  toggleRejectModal = () => {
    this.setState({ rejectModal: !this.state.rejectModal });
  };
  handleSubmitSave = (formValues) => {
    this.setState({ onSubmitDisable: true });
    let {
      source,
      destination,
      vehicleType,
      route,
      transporter,
      date,
      expirydate,
      description,
    } = this.state.form;

    let form = {
      source: source?.value?.id,
      destination: destination?.value?.id,
      vehicleType: vehicleType?.type?.value?.id || vehicleType?.value?.id,
      route: route?.value?.id || "",
      transporter: transporter?.value.id,
      startTime: date,
      expiryTime: expirydate,
      description: description || "",
      loadingPoints: this.state.loadingPoints,
    };
    if (this.props.location.mode == "edit") {
      form.indentID = this.props.location.indentID;
    }
    this.props.createIndent(form, (res) => {
      this.setState({ onSubmitDisable: false });
      // // //console.log(this.props, "create res", this.state.form);
      if (res && res["message"] && res["message"] === "Successfully Added") {
        // this.props.history.push("/indent-planning");
        window.history.replaceState(null, null, "/");
        this.props.history.push("/indent-planning");
        NotificationManager.success(res["message"], "Success");
      } else {
        NotificationManager.error(res["message"], "Error");
        this.props.history.push("/indent-planning");
      }
    });
  };

  reset = (cb) => {
    this.setState(initialState(), () => {
      this.startdate.current.state.value = "";
      this.startdate.current.state.inputValue = "";
      this.expirydate.current.state.value = "";
      this.expirydate.current.state.inputValue = "";
      cb();
      this.sourceDestination();
    });
  };

  valid = function (current) {
    var time = new Date(current).getHours();
    // yesterday.subtract(time)
    yesterday = moment(yesterday).subtract(time);
    // yesterday=moment(yesterday).subtract(11,"hours")
    return current.isAfter(yesterday);
  };

  validExpiry = (current) => {
    let day = new Date(this.state.form.date);
    let expiryEndDay = moment(day).add(1, "days");
    let expiryStartDay = moment(day).subtract(1, "days");
    if (day.getDate() === new Date().getDate()) {
      return current.isBefore(expiryEndDay) && current.isAfter(expiryStartDay);
    } else {
      // console.log("notequals", new Date().getDate(), day.getDate());
      return (
        current.isBefore(day) &&
        current.isAfter(moment(new Date()).subtract(1, "days"))
      );
    }
  };

  handleBlurFilter = (e) => {
    if (e.target.name == "source") {
      if (e.target.value.length >= 3) {
        if (this.state.source.length > 0) {
          let filter = this.state.source.filter((st, i) => {
            return st.label.includes(e.target.value.toUpperCase());
          });
          if (this.Timer) {
            clearTimeout(this.Timer);
          }
          if (Array.isArray(filter)) {
            if (filter.length <= 0) {
              this.Timer = setTimeout(() => {
                this.sourceDestination("source", e.target.value);
              }, 500);
            }
          }
        }
      }
    }
    if (e.target.name == "destination") {
      if (e.target.value.length >= 3) {
        if (this.state.destination.length > 0) {
          let filter = this.state.destination.filter((st, i) => {
            return st.label.includes(e.target.value.toUpperCase());
          });
          if (this.Timer) {
            clearTimeout(this.Timer);
          }
          if (Array.isArray(filter)) {
            if (filter.length <= 0) {
              this.Timer = setTimeout(() => {
                this.sourceDestination("destination", e.target.value);
              }, 500);
            }
          }
        }
      }
    }
  };
  checkChange = (e, i) => {
    let ch = this.state.check;
    let value = e.target.value;
    ch[i] = !this.state.check[i];
    this.setState({ check: ch }, () => {
      let vi = [...this.state.loadingPoints];
      if (ch[i] == false) {
        if (vi.length > 0) {
          let res = vi.filter((v) => v == value);
          this.setState({ loadingPoints: res });
        }
      } else {
        vi.push(value);
        this.setState({ loadingPoints: vi });
      }
    });
  };
  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    const validationSchema = Yup.object({
      source: Yup.object().required(
        this.state.userLangs?.planning?.SOURCE_IS_REQUIRED
      ),
      destination: Yup.object().required(
        this.state.userLangs?.planning?.DESTINATION_IS_REQUIRED
      ),
      route: Yup.object().required(
        this.state.userLangs?.planning?.ROUTE_IS_REQUIRED
      ),
      vehicleType: Yup.object().required(
        this.state.userLangs?.planning?.VEHICLE_TYPE_IS_REQUIRED
      ),
      transporter: Yup.object().required(
        this.state.userLangs?.planning?.TRANSPORTER_IS_REQUIRED
      ),

      date: Yup.date()
        .required(this.state.userLangs?.planning?.START_DATE_IS_REQUIRED)
        .test(
          "StartDatetest",
          this.state.userLangs?.planning?.REPORT_DATE,
          function (startdate) {
            let expirydate = this.parent["expirydate"];
            return expirydate && moment(startdate).isSameOrAfter(expirydate);
          }
        ),
      expirydate: Yup.date()
        .required(this.state.userLangs?.planning?.EXPIRY_DATE_IS_REQUIRED)
        .test(
          "ExpiryDatetest",
          this.state.userLangs?.planning?.EXPIRY_DATE,
          function (expirydate) {
            let startdate = this.parent["date"];
            return startdate && moment(expirydate).isSameOrBefore(startdate);
          }
        ),
    });
    var switchTheme = this.props.switchsTheme;
    let initTogglestorage = localStorage.getItem("Theme");
    if (
      this.props.switchsTheme === "dark__mode" ||
      initTogglestorage === "dark__mode"
    ) {
      darkMode = true;
    } else if (
      this.props.switchsTheme === "light__mode" ||
      initTogglestorage === "light__mode"
    ) {
      darkMode = false;
    } else {
      darkMode = false;
    }
    const resetButtonStyle = {
      backgroundColor: "#0AB38C",
      borderRadius: "3px",
      height: "50px",
      width: "120px",
      margin: "auto",
      marginRight: "9px",
    };

    const resetButtonSpanStyle = {
      margin: "auto",
      fontWeight: "normal",
      fontSize: "15px",
      fontStyle: "normal",
      fontFamily: "Verdana, sans-serif",
      color: "#FFFFFF",
    };
    const submitButtonStyle = {
      backgroundColor: darkMode ? "#5E40FF" : "#05284E",
      borderRadius: "3px",
      height: "50px",
      width: "120px",
      margin: "auto",
      marginRight: "9px",
    };

    const submitButtonSpanStyle = {
      margin: "auto",
      fontWeight: "normal",
      fontSize: "15px",
      fontStyle: "normal",
      fontFamily: "Verdana, sans-serif",
      color: "#FFFFFF",
    };
    const cancelIndentButtonStyle = {
      backgroundColor: "#dc3545",
      borderRadius: "3px",
      height: "50px",
      width: "150px",
      margin: "auto",
      marginRight: "9px",
    };

    const cancelIndentButtonSpanStyle = {
      margin: "auto",
      fontWeight: "normal",
      fontSize: "15px",
      fontStyle: "normal",
      fontFamily: "Verdana, sans-serif",
      color: "#FFFFFF",
    };

    const { onSubmitDisable, via } = this.state;
    const form = this.state.form;

    const rejection = this.props.location?.indent?.rejectionHistory || [];
    const customStyles = {
      control: (style) => {
        return {
          ...style,
          border: "0.5px solid #AEAEAE",
          borderRadius: "5px",
          height: "50px",
          backgroundColor: darkMode ? "#2E2E46" : "#ffffff",
        };
      },
      input: base => ({
        ...base,
        color: darkMode?"#9d9d9d":"#505d69"
      }),
      indicatorsContainer: (style) => {
        return {
          ...style,
          //backgroundColor: "#FFFFFF",
          width: "50px",
          margin: "auto",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          backgroundColor: "#B9B9B9",
          height: "24px",
          margin: "auto",
          //marginRight:"13px"
        };
      },
      dropdownIndicator: (style) => ({
        ...style,
        svg: {
          fill: "#2800FC",
        },
        margin: "auto",
      }),
    };

    return (
      <React.Fragment>
        <CancelIndentModal
          isOpen={this.state.rejectModal}
          toggle={this.toggleRejectModal}
          indentIndex={this.props.location?.indent?._id}
          refreshData={() => {}}
        />

        <div className="mt-2">
          <Container
            style={{
              minWidth: "100%",
            }}
          >
            <div
              className={`containerWhiteDivider mt-1  mb-3 ${
                darkMode ? "darkBackgroundList" : ""
              }`}
            ></div>
            <Modal
              isOpen={this.state.modal}
              toggle={() => this.toggle()}
              size="xl"
              centered
              className="TranspoterModel"
            >
              <Row className="mt-2">
                <Col>
                  <ModalHeader>
                    {this.state.userLangs?.common?.TRANSPORTER}{" "}
                  </ModalHeader>
                </Col>
                <Col className="text-right">
                  <i
                    className="fas fa-times closemodal1 pr-2 text-right"
                    onClick={() => this.toggle()}
                  ></i>
                </Col>
              </Row>

              <ModalBody className="mb-4">
                <Index
                  {...this.props}
                  headers={TranspoterHeaderData}
                  data={this.state.transporter}
                  searchable={false}
                  searchBar={searchBar}
                />
                {/* <DataTable
                  heads={this.state.heads}
                  data={this.state.transporter}
                  hidden={["_id", "laneLot"]}
                  selected={this.selectTransporter}
                /> */}
              </ModalBody>
            </Modal>
          </Container>
          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
            className={`gridContainer ${
              darkMode ? "darkBackgroundBorder" : ""
            }`}
            style={{
              width: "100%",
            }}
          >
            <Grid item xs={4} md={4} className="mobileIndentImg">
              <div className="">
                <img
                  src={formSideImg}
                  alt="placeholder"
                  style={{ width: "100%" }}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={8}>
              <Formik
                initialValues={
                  this.props.location.mode == "edit" ? this.formSet() : form
                }
                validationSchema={validationSchema}
                onSubmit={this.handleSubmitSave}
                validateOnChange={false}
              >
                {(formik) => {
                  const {
                    handleSubmit,
                    values,
                    errors,
                    touched,
                    isValid,
                    dirty,
                    handleBlur,
                    resetForm,
                  } = formik;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid
                        spacing={2}
                        container
                        style={{ margin: "2%" }}
                        className="darkDropDownAddIndent"
                      >
                        <Grid item xs={12} md={6}>
                          <span
                            className={`formFieldHeaderStyle ${
                              darkMode ? "addIndentDarkColor" : ""
                            }`}
                          >
                            {this.state.userLangs?.common?.SOURCE}{" "}
                            <span className="mandatoryFieldStyle">*</span>
                          </span>
                          <div style={{ height: "15px" }}></div>

                          <Select
                            // value={this.state.source.find(
                            //   (x) => x.value === values.source
                            // )}
                            styles={customStyles}
                            name="source"
                            value={form?.source || null}
                            onChange={(e) => {
                              this.handleSubmitChange({
                                target: {
                                  name: "source",
                                  value: e,
                                },
                              });
                            }}
                            isDisabled={rejection.length > 0 ? true : false}
                            options={this.state.source}
                            onInputChange={(e) => {
                              this.handleBlurFilter({
                                target: {
                                  name: "source",
                                  value: e,
                                },
                              });
                            }}
                            classNamePrefix="select2-selection"
                            id="source"
                            className={
                              errors.source && touched.source
                                ? "input-error"
                                : null
                            }
                          />
                          <ErrorMessage
                            name="source"
                            component="span"
                            className="error"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} className="indentFeildPd">
                          <span
                            className={`formFieldHeaderStyle ${
                              darkMode ? "addIndentDarkColor" : ""
                            }`}
                          >
                            {this.state.userLangs?.common?.DESTINATION}
                            <span className="mandatoryFieldStyle">*</span>
                          </span>
                          <div style={{ height: "15px" }}></div>
                          <Select
                            value={form?.destination || null}
                            styles={customStyles}
                            name="destination"
                            isDisabled={rejection.length > 0 ? true : false}
                            onChange={(e) => {
                              this.handleSubmitChange({
                                target: {
                                  name: "destination",
                                  value: e,
                                },
                              });
                            }}
                            options={this.state.destination}
                            onBlur={handleBlur}
                            classNamePrefix="select2-selection"
                            id="destination"
                            onInputChange={(e) => {
                              this.handleBlurFilter({
                                target: {
                                  name: "destination",
                                  value: e,
                                },
                              });
                            }}
                            className={
                              errors.destination && touched.destination
                                ? "input-error"
                                : null
                            }
                          />
                          <ErrorMessage
                            name="destination"
                            component="span"
                            className="error"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <div style={{ height: "15px" }}></div>
                          <span
                            className={`formFieldHeaderStyle ${
                              darkMode ? "addIndentDarkColor" : ""
                            }`}
                          >
                            {this.state.userLangs?.common?.ROUTE}
                            <span className="mandatoryFieldStyle">*</span>
                          </span>
                          <div style={{ height: "15px" }}></div>
                          <Select
                            value={form.route ? form.route : ""}
                            name="route"
                            styles={customStyles}
                            isDisabled={rejection.length > 0 ? true : false} //console.log("Form Source: ", form.source);
                            onChange={(e) => {
                              this.handleSubmitChange({
                                target: {
                                  name: "route",
                                  value: e,
                                },
                              });
                            }}
                            options={this.state.route}
                            onBlur={handleBlur}
                            classNamePrefix="select2-selection"
                            id="route"
                            className={
                              errors.route && touched.route
                                ? "input-error"
                                : null
                            }
                          />
                          <ErrorMessage
                            name="route"
                            component="span"
                            className="error"
                          />
                        </Grid>
                        <Grid item xs={12} className="mobileDisplay">
                          {via.length > 0 ? (
                            <h6
                              style={{
                                fontSize: "small",
                                fontWeight: 1000,
                                color: "black",
                                fontFamily: "Poppins",
                              }}
                            >
                              {this.state.userLangs?.common?.LOADING_POINTS}
                            </h6>
                          ) : null}
                          {via.length > 0
                            ? via.map((v, i) => {
                                return (
                                  <FormGroup check inline key={i}>
                                    <Label check>
                                      <Input
                                        type="checkbox"
                                        onChange={(e) => {
                                          this.checkChange(e, i);
                                        }}
                                        value={v._id}
                                        checked={this.state.check[i]}
                                      />{" "}
                                      <span style={{ color: "red" }}>
                                        {v.label}
                                      </span>
                                    </Label>
                                  </FormGroup>
                                );
                              })
                            : null}
                        </Grid>
                        <Grid item xs={12} md={6} className="indentFeildPd">
                          <div style={{ height: "15px" }}></div>
                          <span
                            className={`formFieldHeaderStyle ${
                              darkMode ? "addIndentDarkColor" : ""
                            }`}
                          >
                            {this.state.userLangs?.common?.VEHICLE_TYPE}

                            <span className="mandatoryFieldStyle">*</span>
                          </span>
                          <div style={{ height: "15px" }}></div>
                          <Select
                            value={
                              form.vehicleType.type
                                ? form.vehicleType.type
                                : form.vehicleType
                            }
                            name="vehicleType"
                            styles={customStyles}
                            isDisabled={rejection.length > 0 ? true : false}
                            onChange={(e) => {
                              this.handleSubmitChange({
                                target: {
                                  name: "vehicleType",
                                  value: e,
                                },
                              });
                            }}
                            options={this.state.vehicleType}
                            classNamePrefix="select2-selection"
                            id="vehicleType"
                            onBlur={handleBlur}
                            className={
                              errors.vehicleType && touched.vehicleType
                                ? "input-error"
                                : null
                            }
                          />
                          <ErrorMessage
                            name="vehicleType"
                            component="span"
                            className="error"
                          />
                        </Grid>
                        <Grid item xs={0.5}></Grid>
                        <Grid item xs={12} className="desktopDisplayaddIndent">
                          {via.length > 0 ? (
                            <h6
                              // style={{
                              //   fontSize: "small",
                              //   fontWeight: 1000,
                              //   color: "black",
                              //   fontFamily: "Poppins",
                              // }}
                              className={` ${
                                darkMode
                                  ? "addIndentDarkColor"
                                  : "addLoadingPoint"
                              }`}
                            >
                              {this.state.userLangs?.common?.LOADING_POINTS}
                            </h6>
                          ) : null}
                          {via.length > 0
                            ? via.map((v, i) => {
                                return (
                                  <FormGroup check inline key={i}>
                                    <Label check>
                                      <Input
                                        type="checkbox"
                                        onChange={(e) => {
                                          this.checkChange(e, i);
                                        }}
                                        value={v._id}
                                        checked={this.state.check[i]}
                                      />{" "}
                                      <span style={{ color: "red" }}>
                                        {v.label}
                                      </span>
                                    </Label>
                                  </FormGroup>
                                );
                              })
                            : null}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          className={`${darkMode ? "addIntentDarkModal" : ""}`}
                        >
                          <div style={{ height: "15px" }}></div>
                          <span
                            className={`formFieldHeaderStyle ${
                              darkMode ? "addIndentDarkColor" : ""
                            }`}
                          >
                            {
                              this.state.userLangs?.planning
                                ?.VEHICLE_REPORTING_DATE
                            }
                            / {this.state.userLangs?.planning?.TIME_AT_FACTORY}/
                            {this.state.userLangs?.planning?.DEPOT}
                            <span className="mandatoryFieldStyle">*</span>
                          </span>
                          <div style={{ height: "15px" }}></div>
                          <div className="d-flex dateTimeInputParent">
                            <div
                              className={`addIndentDateDiv ${
                                darkMode ? "darkReportTime" : ""
                              }`}
                            >
                              <Datetime
                                value={
                                  rejection.length > 0
                                    ? moment(form.date)
                                    : form.date != null
                                    ? moment(form.date)
                                    : null
                                }
                                dateFormat={"DD/MM/YYYY"}
                                style={{
                                  height: "45px",
                                  border: "none",
                                  //borderBottom: "0.5px solid #AEAEAE",
                                }}
                                // className="d-flex flex-sm-grow-1 time-cont"
                                name="date"
                                isValidDate={this.valid}
                                onChange={(e) => {
                                  this.handleSubmitChange({
                                    target: {
                                      name: "date",
                                      value: e._d,
                                    },
                                  });
                                }}
                                onBlur={handleBlur}
                                id="date"
                                inputProps={{
                                  disabled: rejection.length > 0 ? true : false,
                                }}
                                className={
                                  (errors.date && touched.date
                                    ? "input-error"
                                    : null,
                                  "dateTime")
                                }
                                //className={`"dateTime ${errors?.date} && 'touched.date'? input-error:null"`}
                                ref={this.startdate}
                                onFocus={(e) => {}}
                              />
                            </div>
                            <div className="calendarSeparator"></div>
                            <div
                              className={`d-flex align-items-center justify-content-end icon-cont ${
                                darkMode ? "darkInputHighlightDiv" : ""
                              }`}
                            >
                              <span
                                style={{
                                  border: "0.5px solid #2800FC",
                                  margin: "25%",
                                  borderRadius: "5px",
                                  backgroundColor: darkMode
                                    ? "#2E2E46"
                                    : "#F1F5F7",
                                  height: "25px",
                                  width: "43px",
                                }}
                                onClick={() => {
                                  if (
                                    this.startdate.current.state.open == false
                                  ) {
                                    this.startdate.current._openCalendar();
                                  } else {
                                    this.startdate.current._closeCalendar();
                                  }
                                  // //console.log(
                                  //   this.startdate.current
                                  // );
                                }}
                                className={` ${
                                  rejection.length > 0 ? "disable" : ""
                                }`}
                              >
                                <i
                                  className="fas fa-calendar-week fa-lg "
                                  style={{
                                    padding: "5px 5px",
                                    color: "#2800FC",
                                    fontSize: "small",
                                  }}
                                ></i>
                              </span>
                            </div>
                          </div>
                          <ErrorMessage
                            name="date"
                            component="span"
                            className="error"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          className={`indentFeildPd ${
                            darkMode ? "addIntentDarkModal" : ""
                          }`}
                        >
                          <div style={{ height: "15px" }}></div>
                          <span
                            className={`formFieldHeaderStyle ${
                              darkMode ? "addIndentDarkColor" : ""
                            }`}
                          >
                            {this.state.userLangs?.common?.TRANSPORTER}
                            <span className="mandatoryFieldStyle">*</span>
                          </span>
                          <div style={{ height: "15px" }}></div>
                          <div className="d-flex dateTimeInputParent">
                            <Input
                              name="transporter"
                              defaultValue={
                                form.transporter.name
                                  ? form.transporter.name
                                  : ""
                              }
                              id="transporter"
                              onBlur={handleBlur}
                              className={
                                errors.transporter && touched.transporter
                                  ? "input-error"
                                  : null
                              }
                              disabled
                              //onFocus="blur"
                              style={{
                                height: "45px",
                                border: "none",
                                backgroundColor: darkMode
                                  ? "#2E2E46"
                                  : "#ffffff",
                                //borderBottom: "0.5px solid #AEAEAE",
                              }}
                            />
                            <div className="transporterSeparator"></div>
                            <div
                              className={`d-flex align-items-center justify-content-end icon-cont ${
                                darkMode ? "darkInputHighlightDiv" : ""
                              }`}
                              style={{ float: "right" }}
                            >
                              <span
                                style={{
                                  border: "0.5px solid #2800FC",
                                  margin: "30%",
                                  borderRadius: "5px",
                                  backgroundColor: darkMode
                                    ? "#2E2E46"
                                    : "#F1F5F7",
                                  height: "25px",
                                  width: "25px",
                                }}
                                className=""
                                onClick={() => this.toggle()}
                              >
                                <i
                                  className="fas fa-plus fa-lg"
                                  style={{
                                    padding: "5px 5px",
                                    color: "#2800FC",
                                    fontSize: "small",
                                  }}
                                ></i>
                              </span>
                            </div>
                          </div>
                          <ErrorMessage
                            name="transporter"
                            component="span"
                            className="error"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          className={`${darkMode ? "addIntentDarkModal" : ""}`}
                        >
                          <div style={{ height: "15px" }}></div>
                          <span
                            className={`formFieldHeaderStyle ${
                              darkMode ? "addIndentDarkColor" : ""
                            }`}
                          >
                            {
                              this.state.userLangs?.planning
                                ?.TRANSPORTER_ACCEPTANCE_EXPIRT_DATE
                            }
                            <span className="mandatoryFieldStyle">*</span>
                          </span>
                          <div style={{ height: "15px" }}></div>
                          <div className="d-flex dateTimeInputParent">
                            <div
                              className={`addIndentDateDiv ${
                                darkMode ? "darkReportTime" : ""
                              }`}
                            >
                              <Datetime
                                value={
                                  rejection.length > 0
                                    ? moment(form.expirydate)
                                    : form.expirydate != null
                                    ? moment(form.expirydate)
                                    : null
                                }
                                name="expirydate"
                                isValidDate={this.validExpiry}
                                dateFormat={"DD/MM/YYYY"}
                                showTimeSelect
                                onChange={(e) => {
                                  this.handleSubmitChange({
                                    target: {
                                      name: "expirydate",
                                      value: e._d,
                                    },
                                  });
                                }}
                                onBlur={handleBlur}
                                input={true}
                                defaultValue={form.expirydate}
                                id="expirydate"
                                className={
                                  (errors.date && touched.date
                                    ? "input-error"
                                    : null,
                                  "dateTime")
                                }
                                ref={this.expirydate}
                              />
                            </div>
                            <div className="calendarSeparator"></div>
                            <div
                              className={`d-flex align-items-center justify-content-end icon-cont ${
                                darkMode ? "darkInputHighlightDiv" : ""
                              }`}
                            >
                              <span
                                style={{
                                  border: "0.5px solid #2800FC",
                                  margin: "25%",
                                  borderRadius: "5px",
                                  backgroundColor: darkMode
                                    ? "#2E2E46"
                                    : "#F1F5F7",
                                  height: "25px",
                                  width: "43px",
                                }}
                                onClick={() => {
                                  if (
                                    this.expirydate.current.state.open == false
                                  ) {
                                    this.expirydate.current._openCalendar();
                                  } else {
                                    this.expirydate.current._closeCalendar();
                                  }
                                }}
                              >
                                <i
                                  className="fas fa-calendar-week fa-lg"
                                  style={{
                                    padding: "5px 5px",
                                    color: "#2800FC",
                                    fontSize: "small",
                                  }}
                                ></i>
                              </span>
                            </div>
                          </div>

                          <ErrorMessage
                            name="expirydate"
                            component="span"
                            className="error"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} className="indentFeildPd">
                          <div style={{ height: "15px" }}></div>
                          <span
                            className={`formFieldHeaderStyle ${
                              darkMode ? "addIndentDarkColor" : ""
                            }`}
                          >
                            {this.state.userLangs?.planning?.COMMENTS}
                            <span className="mandatoryFieldStyle"></span>
                          </span>
                          <div style={{ height: "15px" }}></div>
                          <input
                            id="description"
                            placeholder="Type"
                            name="description"
                            value={form.description || ""}
                            onChange={(e) => {
                              this.handleSubmitChange(e);
                            }}
                            onBlur={handleBlur}
                            options={[]}
                            className={
                              errors.description && touched.description
                                ? "input-error"
                                : null
                            }
                            style={{
                              height: "50px",
                              width: "90%",
                              border: "0.5px solid #AEAEAE",
                              borderRadius: "5px",
                              backgroundColor: darkMode ? "#2E2E46" : "#ffffff",
                            }}
                          />
                        </Grid>

                        <Grid item xs={6} md={6}>
                          <div style={{ height: "25px" }}></div>
                          <div className="d-flex flex-row">
                            <div>
                              <Button
                                style={resetButtonStyle}
                                onClick={() => this.reset(resetForm)}
                              >
                                <span style={resetButtonSpanStyle}>
                                  {" "}
                                  {this.state.userLangs?.common?.RESET}
                                </span>
                              </Button>
                            </div>
                            <div>
                              <Button
                                style={submitButtonStyle}
                                disabled={onSubmitDisable}
                                type="submit"
                              >
                                <span style={submitButtonSpanStyle}>
                                  {onSubmitDisable
                                    ? this.state.userLangs?.planning?.PROCESSING
                                    : this.state.userLangs?.common?.SUBMIT}
                                </span>
                              </Button>
                            </div>
                            <div>
                              {rejection.length > 0 ? (
                                <Button
                                  style={cancelIndentButtonStyle}
                                  onClick={() => {
                                    this.toggleRejectModal();
                                  }}
                                >
                                  <span style={cancelIndentButtonSpanStyle}>
                                    {
                                      this.state.userLangs?.planning
                                        ?.CANCEL_INDENT
                                    }
                                  </span>
                                </Button>
                              ) : null}
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>

          {/* <Grid 
          container 
          
          className="gridContainer ml-3">
            <Grid item xs={4}>
              <div className="">
                <img
                  src={formSideImg}
                  alt="placeholder"
                  style={{ width: "100%" }}
                />
              </div>
            </Grid>
            <Grid item xs={8}>
              <Formik
                initialValues={
                  this.props.location.mode == "edit" ? this.formSet() : form
                }
                validationSchema={validationSchema}
                onSubmit={this.handleSubmitSave}
                validateOnChange={false}
              >
                {(formik) => {
                  const {
                    handleSubmit,
                    values,
                    errors,
                    touched,
                    isValid,
                    dirty,
                    handleBlur,
                    resetForm,
                  } = formik;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid container style={{ margin: "2%" }}>
                        <Grid item xs={5.5}>
                          <span className="formFieldHeaderStyle">
                            {this.state.userLangs?.common?.SOURCE}{" "}
                            <span className="mandatoryFieldStyle">*</span>
                          </span>
                          <div style={{ height: "15px" }}></div>

                          <Select
                            // value={this.state.source.find(
                            //   (x) => x.value === values.source
                            // )}
                            styles={customStyles}
                            name="source"
                            value={form?.source || null}
                            onChange={(e) => {
                              this.handleSubmitChange({
                                target: {
                                  name: "source",
                                  value: e,
                                },
                              });
                            }}
                            isDisabled={rejection.length > 0 ? true : false}
                            options={this.state.source}
                            onInputChange={(e) => {
                              this.handleBlurFilter({
                                target: {
                                  name: "source",
                                  value: e,
                                },
                              });
                            }}
                            classNamePrefix="select2-selection"
                            id="source"
                            className={
                              errors.source && touched.source
                                ? "input-error"
                                : null
                            }
                          />
                          <ErrorMessage
                            name="source"
                            component="span"
                            className="error"
                          />
                        </Grid>
                        <Grid item xs={0.5}></Grid>
                        <Grid item xs={5.5}>
                          <span className="formFieldHeaderStyle">
                            {this.state.userLangs?.common?.DESTINATION}
                            <span className="mandatoryFieldStyle">*</span>
                          </span>
                          <div style={{ height: "15px" }}></div>
                          <Select
                            value={form?.destination || null}
                            styles={customStyles}
                            name="destination"
                            isDisabled={rejection.length > 0 ? true : false}
                            onChange={(e) => {
                              this.handleSubmitChange({
                                target: {
                                  name: "destination",
                                  value: e,
                                },
                              });
                            }}
                            options={this.state.destination}
                            onBlur={handleBlur}
                            classNamePrefix="select2-selection"
                            id="destination"
                            onInputChange={(e) => {
                              this.handleBlurFilter({
                                target: {
                                  name: "destination",
                                  value: e,
                                },
                              });
                            }}
                            className={
                              errors.destination && touched.destination
                                ? "input-error"
                                : null
                            }
                          />
                          <ErrorMessage
                            name="destination"
                            component="span"
                            className="error"
                          />
                        </Grid>
                        <Grid item xs={0.5}></Grid>
                        <Grid item xs={5.5}>
                          <div style={{ height: "15px" }}></div>
                          <span className="formFieldHeaderStyle">
                            {this.state.userLangs?.common?.ROUTE}
                            <span className="mandatoryFieldStyle">*</span>
                          </span>
                          <div style={{ height: "15px" }}></div>
                          <Select
                            value={form.route || null}
                            name="route"
                            styles={customStyles}
                            isDisabled={rejection.length > 0 ? true : false} //console.log("Form Source: ", form.source);
                            onChange={(e) => {
                              this.handleSubmitChange({
                                target: {
                                  name: "route",
                                  value: e,
                                },
                              });
                            }}
                            options={this.state.route}
                            onBlur={handleBlur}
                            classNamePrefix="select2-selection"
                            id="route"
                            className={
                              errors.route && touched.route
                                ? "input-error"
                                : null
                            }
                          />
                          <ErrorMessage
                            name="route"
                            component="span"
                            className="error"
                          />
                        </Grid>
                        <Grid item xs={0.5}></Grid>
                        <Grid item xs={5.5}>
                          <div style={{ height: "15px" }}></div>
                          <span className="formFieldHeaderStyle">
                            {this.state.userLangs?.planning?.VEHICLE_TYPE}
                            <span className="mandatoryFieldStyle">*</span>
                          </span>
                          <div style={{ height: "15px" }}></div>
                          <Select
                            value={
                              form.vehicleType.type
                                ? form.vehicleType.type
                                : form.vehicleType
                            }
                            name="vehicleType"
                            styles={customStyles}
                            isDisabled={rejection.length > 0 ? true : false}
                            onChange={(e) => {
                              this.handleSubmitChange({
                                target: {
                                  name: "vehicleType",
                                  value: e,
                                },
                              });
                            }}
                            options={this.state.vehicleType}
                            classNamePrefix="select2-selection"
                            id="vehicleType"
                            onBlur={handleBlur}
                            className={
                              errors.vehicleType && touched.vehicleType
                                ? "input-error"
                                : null
                            }
                          />
                          <ErrorMessage
                            name="vehicleType"
                            component="span"
                            className="error"
                          />
                        </Grid>
                        <Grid item xs={0.5}></Grid>
                        <Grid item xs={12}>
                          {via.length > 0 ? (
                            <h6
                              style={{
                                fontSize: "small",
                                fontWeight: 1000,
                                color: "black",
                                fontFamily: "Poppins",
                              }}
                            >
                              Loading points
                            </h6>
                          ) : null}
                          {via.length > 0
                            ? via.map((v, i) => {
                                return (
                                  <FormGroup check inline key={i}>
                                    <Label check>
                                      <Input
                                        type="checkbox"
                                        onChange={(e) => {
                                          this.checkChange(e, i);
                                        }}
                                        value={v._id}
                                        checked={this.state.check[i]}
                                      />{" "}
                                      {v.label}
                                    </Label>
                                  </FormGroup>
                                );
                              })
                            : null}
                        </Grid>
                        <Grid item xs={5.5}>
                          <div style={{ height: "15px" }}></div>
                          <span className="formFieldHeaderStyle">
                            {
                              this.state.userLangs?.planning
                                ?.VEHICLE_REPORTING_DATE
                            }{" "}
                            / {this.state.userLangs?.planning?.TIME_AT_FACTORY}{" "}
                            / {this.state.userLangs?.planning?.DEPOT}
                            <span className="mandatoryFieldStyle">*</span>
                          </span>
                          <div style={{ height: "15px" }}></div>
                          <div className="d-flex dateTimeInputParent">
                            <div>
                              <Datetime
                                value={
                                  rejection.length > 0
                                    ? moment("")
                                    : form.date
                                    ? moment(form.date)
                                    : moment("")
                                }
                                style={{
                                  height: "45px",
                                  border: "none",
                                  //borderBottom: "0.5px solid #AEAEAE",
                                }}
                                // className="d-flex flex-sm-grow-1 time-cont"
                                name="date"
                                isValidDate={this.valid}
                                onChange={(e) => {
                                  this.handleSubmitChange({
                                    target: {
                                      name: "date",
                                      value: e._d,
                                    },
                                  });
                                }}
                                onBlur={handleBlur}
                                id="date"
                                inputProps={{
                                  disabled: rejection.length > 0 ? true : false,
                                }}
                                className={
                                  (errors.date && touched.date
                                    ? "input-error"
                                    : null,
                                  "dateTime")
                                }
                                //className={`"dateTime ${errors?.date} && 'touched.date'? input-error:null"`}
                                ref={this.startdate}
                                onFocus={(e) => {}}
                              />
                            </div>
                            <div className="calendarSeparator"></div>
                            <div className="d-flex align-items-center justify-content-end icon-cont">
                              <span
                                style={{
                                  border: "0.5px solid #2800FC",
                                  margin: "25%",
                                  borderRadius: "5px",
                                  backgroundColor: "#F1F5F7",
                                  height: "25px",
                                  width: "43px",
                                }}
                                onClick={() => {
                                  if (
                                    this.startdate.current.state.open == false
                                  ) {
                                    this.startdate.current._openCalendar();
                                  } else {
                                    this.startdate.current._closeCalendar();
                                  }
                                  // //console.log(
                                  //   this.startdate.current
                                  // );
                                }}
                                className={` ${
                                  rejection.length > 0 ? "disable" : ""
                                }`}
                              >
                                <i
                                  className="fas fa-calendar-week fa-lg "
                                  style={{
                                    padding: "5px 5px",
                                    color: "#2800FC",
                                    fontSize: "small",
                                  }}
                                ></i>
                              </span>
                            </div>
                          </div>
                          <ErrorMessage
                            name="date"
                            component="span"
                            className="error"
                          />
                        </Grid>
                        <Grid item xs={0.5}></Grid>
                        <Grid item xs={5.5}>
                          <div style={{ height: "15px" }}></div>
                          <span className="formFieldHeaderStyle">
                            {this.state.userLangs?.common?.TRANSPORTER}
                            <span className="mandatoryFieldStyle">*</span>
                          </span>
                          <div style={{ height: "15px" }}></div>
                          <div className="d-flex dateTimeInputParent">
                            <div>
                              <Input
                                name="transporter"
                                defaultValue={
                                  form.transporter.name
                                    ? form.transporter.name
                                    : ""
                                }
                                id="transporter"
                                onBlur={handleBlur}
                                className={
                                  errors.transporter && touched.transporter
                                    ? "input-error"
                                    : null
                                }
                                disabled
                                //onFocus="blur"
                                style={{
                                  height: "45px",
                                  border: "none",
                                  //borderBottom: "0.5px solid #AEAEAE",
                                }}
                              />
                            </div>
                            <div className="transporterSeparator"></div>
                            <div
                              className="d-flex align-items-center justify-content-end icon-cont"
                              style={{ float: "right" }}
                            >
                              <span
                                style={{
                                  border: "0.5px solid #2800FC",
                                  margin: "30%",
                                  borderRadius: "5px",
                                  backgroundColor: "#F1F5F7",
                                  height: "25px",
                                  width: "25px",
                                }}
                                className=""
                                onClick={() => this.toggle()}
                              >
                                <i
                                  className="fas fa-plus fa-lg"
                                  style={{
                                    padding: "5px 5px",
                                    color: "#2800FC",
                                    fontSize: "small",
                                  }}
                                ></i>
                              </span>
                            </div>
                          </div>
                          <ErrorMessage
                            name="transporter"
                            component="span"
                            className="error"
                          />
                        </Grid>
                        <Grid item xs={0.5}></Grid>
                        <Grid item xs={5.5}>
                          <div style={{ height: "15px" }}></div>
                          <span className="formFieldHeaderStyle">
                            {
                              this.state.userLangs?.planning
                                ?.TRANSPORTER_ACCEPTANCE_EXPIRT_DATE
                            }
                            <span className="mandatoryFieldStyle">*</span>
                          </span>
                          <div style={{ height: "15px" }}></div>
                          <div className="d-flex dateTimeInputParent">
                            <Datetime
                              value={
                                rejection.length > 0
                                  ? null
                                  : form.expirydate != null
                                  ? moment(form.expirydate)
                                  : null
                              }
                              name="expirydate"
                              isValidDate={this.validExpiry}
                              showTimeSelect
                              onChange={(e) => {
                                this.handleSubmitChange({
                                  target: {
                                    name: "expirydate",
                                    value: e._d,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              input={true}
                              defaultValue={form.expirydate}
                              id="expirydate"
                              className={
                                (errors.date && touched.date
                                  ? "input-error"
                                  : null,
                                "dateTime")
                              }
                              ref={this.expirydate}
                            />
                            <div className="calendarSeparator"></div>
                            <div className="d-flex align-items-center justify-content-end icon-cont">
                              <span
                                style={{
                                  border: "0.5px solid #2800FC",
                                  margin: "25%",
                                  borderRadius: "5px",
                                  backgroundColor: "#F1F5F7",
                                  height: "25px",
                                  width: "43px",
                                }}
                                onClick={() => {
                                  if (
                                    this.expirydate.current.state.open == false
                                  ) {
                                    this.expirydate.current._openCalendar();
                                  } else {
                                    this.expirydate.current._closeCalendar();
                                  }
                                }}
                              >
                                <i
                                  className="fas fa-calendar-week fa-lg"
                                  style={{
                                    padding: "5px 5px",
                                    color: "#2800FC",
                                    fontSize: "small",
                                  }}
                                ></i>
                              </span>
                            </div>
                          </div>

                          <ErrorMessage
                            name="expirydate"
                            component="span"
                            className="error"
                          />
                        </Grid>
                        <Grid item xs={0.5}></Grid>
                        <Grid item xs={5.5}>
                          <div style={{ height: "15px" }}></div>
                          <span className="formFieldHeaderStyle">
                            Comment
                          </span>
                          <div style={{ height: "15px" }}></div>
                          <input
                            id="description"
                            placeholder="Type"
                            name="description"
                            value={form.description || ""}
                            onChange={(e) => {
                              this.handleSubmitChange(e);
                            }}
                            onBlur={handleBlur}
                            options={[]}
                            className={
                              errors.description && touched.description
                                ? "input-error"
                                : null
                            }
                            style={{
                              height: "50px",
                              width: "100%",
                              border: "0.5px solid #AEAEAE",
                              borderRadius: "5px",
                             
                            }}
                          />
                        </Grid>
                        <Grid item xs={0.5}></Grid>

                        <Grid item xs={6}>
                          <div style={{ height: "25px" }}></div>
                          <Button
                            style={resetButtonStyle}
                            onClick={() => this.reset(resetForm)}
                          >
                            <span style={resetButtonSpanStyle}>
                              {this.state.userLangs?.common?.RESET}
                            </span>
                          </Button>
                          <Button
                            style={submitButtonStyle}
                            disabled={onSubmitDisable}
                          >
                            <span style={submitButtonSpanStyle}>
                              {onSubmitDisable
                                ? this.state.userLangs?.planning?.PROCESSING
                                : this.state.userLangs?.common?.SUBMIT}
                            </span>
                          </Button>

                          {rejection.length > 0 ? (
                            <Button
                              style={cancelIndentButtonStyle}
                              onClick={() => {
                                this.toggleRejectModal();
                              }}
                            >
                              <span style={cancelIndentButtonSpanStyle}>
                                {this.state.userLangs?.planning?.CANCEL_INDENT}
                              </span>
                            </Button>
                          ) : null}
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </Grid>
          </Grid> */}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  indents: state.Indent.indents,
});

export default connect(mapStateToProps, {
  fetchHopsSourceDestination,
  fetchVehicleType,
  fetchRoutes,
  fetchTransporterSearch,
  createIndent,
})(IndentDetails);
