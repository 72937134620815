import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import {
  deleteApi,
  getApi,
  patchApi,
  postApi,
} from "../../helpers/auth_helper";
import { types } from "./actionTypes";

function* workfetchRoutes({ offset, source, destination, callback }) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/route/routesViaBySourceDest?offset=` +
      offset +
      `&limit=` +
      process.env.REACT_APP_PERPAGE +
      `&source=` +
      source +
      `&destination=` +
      destination;

    const response = yield call(getApi, uri);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_ROUTES_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch posts.", error);
  }
}

export function* watchFetchRoutes() {
  yield takeEvery(types.FETCH_ROUTES, workfetchRoutes);
}

function* RouteSaga() {
  yield all([fork(watchFetchRoutes)]);
}
export default RouteSaga;
