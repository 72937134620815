import { types } from "./actionTypes";
export const fetchHops = (offset, callback) => ({
  type: types.FETCH_HOPS,
  offset,
  callback,
});
export const fetchHopsSourceDestination = (offset, sourceId, q, callback) => ({
  type: types.FETCH_SOURCE_DESTINATION,
  offset,
  sourceId,
  q,
  callback,
});
export const fetchHopsfilter = (offset, filters, callback) => ({
  type: types.FETCH_FILTER_HOPS,
  offset,
  filters,
  callback,
});

export const fetchAvailHops = () => ({ type: types.FETCH_AVAIL_HOPS });
// export const fetchPost = id => ({ type: types.FETCH_POST, id });
export const createHop = (values, callback) => ({
  type: types.CREATE_HOP,
  values,
  callback,
});
export const editHop = (values, callback) => ({
  type: types.EDIT_HOP,
  values,
  callback,
});
export const fetchHopById = (id, callback) => ({
  type: types.FETCH_HOP_BYID,
  id,
  callback,
});
export const deleteHop = (id, callback) => ({
  type: types.DELETE_HOP,
  id,
  callback,
});
export const fetchAllSourceDestination = (callback) => ({
  type: types.FETCH_ALL_SOURCE_DESTINATION,
  callback,
});
