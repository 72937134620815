const role = JSON.parse(localStorage.getItem("authUser"))?.data?.role?.label;
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(localStorage.getItem("userLangDetail"));
  TransporterHeaderData = {
    columns: [
      {
        label: userLang?.trip?.TRIP,
        field: "tripId",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.common?.INDENT,
        field: "indentId",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.common?.SOURCE,
        field: "source",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.common?.DESTINATION,
        field: "destination",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.common?.ROUTE,
        field: "route",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.trip?.VEHICLE,
        field: "vehicle",
        sort: "asc",
        width: 3,
      },
      {
        label: userLang?.trip?.DRIVER,
        field: "driver",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.common?.TRANSPORTER,
        field: "transporter",
        sort: "asc",
        width: 3,
      },
      {
        label: userLang?.trip?.TIME,
        label: "Time",
        field: "startTime",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.common?.STATUS,
        field: "subStatus",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.common?.ACTIONS,
        field: "actions",
        sort: "asc",
        width: 2,
      },
    ],
    // rows: trips,
  };

  HeaderData = {
    columns: [
      {
        label: userLang?.trip?.TRIP,
        field: "tripId",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.common?.INDENT,
        field: "indentId",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.common?.SOURCE,
        field: "source",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.common?.DESTINATION,
        field: "destination",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.common?.ROUTE,
        field: "route",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.trip?.VEHICLE,
        field: "vehicle",
        sort: "asc",
        width: 3,
      },
      {
        label: userLang?.trip?.DRIVER,
        field: "driver",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.common?.TRANSPORTER,
        field: "transporter",
        sort: "asc",
        width: 3,
      },
      {
        label: userLang?.trip?.TIME,
        field: "startTime",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.common?.STATUS,
        field: "subStatus",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.common?.ACTIONS,
        field: "actions",
        sort: "asc",
        width: 2,
      },
    ],
    // rows: trips,
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var TransporterHeaderData;
TransporterHeaderData = {
  columns: [
    {
      label: userLang?.trip?.TRIP,
      field: "tripId",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.common?.INDENT,
      field: "indentId",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.common?.SOURCE,
      field: "source",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.common?.DESTINATION,
      field: "destination",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.common?.ROUTE,
      field: "route",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.trip?.VEHICLE,
      field: "vehicle",
      sort: "asc",
      width: 3,
    },
    {
      label: userLang?.trip?.DRIVER,
      field: "driver",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.common?.TRANSPORTER,
      field: "transporter",
      sort: "asc",
      width: 3,
    },
    {
      label: userLang?.trip?.TIME,
      label: "Time",
      field: "startTime",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.common?.STATUS,
      field: "subStatus",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.common?.ACTIONS,
      field: "actions",
      sort: "asc",
      width: 2,
    },
  ],
  // rows: trips,
};

export var HeaderData;
HeaderData = {
  columns: [
    {
      label: userLang?.trip?.TRIP,
      field: "tripId",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.common?.INDENT,
      field: "indentId",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.common?.SOURCE,
      field: "source",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.common?.DESTINATION,
      field: "destination",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.common?.ROUTE,
      field: "route",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.trip?.VEHICLE,
      field: "vehicle",
      sort: "asc",
      width: 3,
    },
    {
      label: userLang?.trip?.DRIVER,
      field: "driver",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.common?.TRANSPORTER,
      field: "transporter",
      sort: "asc",
      width: 3,
    },
    {
      label: userLang?.trip?.TIME,
      field: "startTime",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.common?.STATUS,
      field: "subStatus",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.common?.ACTIONS,
      field: "actions",
      sort: "asc",
      width: 2,
    },
  ],
  // rows: trips,
};

export var searchBar;
searchBar = {
  searchBarData: [
    {
      label: userLang?.trip?.TRIP_ID,
      field: "tripId",
      type: "text",
    },
    {
      label: userLang?.planning?.INDENT_ID,
      field: "indentId",
      type: "text",
    },
    {
      label: userLang?.trip?.TRIP,
      field: "source",
      type: "select",
    },
    {
      label: userLang?.trip?.TRIP,
      field: "destination",
      type: "select",
    },
    {
      label: userLang?.trip?.TRIP,
      field: "route",
      type: "text",
    },
    {
      label: userLang?.trip?.TRIP,
      field: "vehicle",
      type: "select",
    },
    {
      label: userLang?.trip?.TRIP,
      field: "driver",
      type: "text",
    },
    {
      label: userLang?.trip?.TRIP,
      field: "transporter",
      type: "select",
    },
    {
      label: userLang?.trip?.TRIP,
      field: "startTime",
      type: "date",
    },
    {
      label: userLang?.trip?.TRIP,
      field: "subStatus",
      type: "select",
    },
    {
      label: userLang?.trip?.TRIP,
      field: "actions",
      type: "text",
    },
  ],
  // rows: trips,
};
export var TransportersearchBar;
TransportersearchBar = {
  searchBarData: [
    {
      label: userLang?.trip?.TRIP_ID,
      field: "tripId",
      type: "text",
    },
    {
      label: userLang?.planning?.INDENT_ID,
      field: "indentId",
      type: "text",
    },
    {
      label: userLang?.common?.SOURCE,
      field: "source",
      type: "select",
    },
    {
      label: userLang?.common?.DESTINATION,
      field: "destination",
      type: "select",
    },
    {
      label: userLang?.common?.ROUTE,
      field: "route",
      type: "text",
    },
    {
      label: userLang?.trip?.VEHICLE,
      field: "vehicle",
      type: "select",
    },
    {
      label: userLang?.trip?.DRIVER,
      field: "driver",
      type: "text",
    },
    {
      label: userLang?.common?.TRANSPORTER,
      field: "transporter",
      type: "select",
    },
    {
      label: userLang?.trip?.TIME,
      field: "startTime",
      type: "date",
    },
    {
      label: userLang?.common?.STATUS,
      field: "subStatus",
      type: "select",
    },
    {
      label: userLang?.common?.ACTIONS,
      field: "actions",
      type: "text",
    },
  ],
  // rows: trips,
};
