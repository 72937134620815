import React, { Component } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  FormGroup,
  Card,
  CardBody,
} from "reactstrap";
import { connect } from "react-redux";
import { MDBDataTable, MDBIcon, MDBCol } from "mdbreact";
import Select from "react-select";
import { Link } from "react-router-dom";
import { restrict } from "../restrict";
import { PERMS } from "../../enum/perms.enum";
import Pagination from "@material-ui/lab/Pagination";
import { BorderLeft } from "@material-ui/icons";
import {
  fetchAgreements,
  fetchViewAgreement,
  clearLoadedValues,
} from "../../store/billing/actions";
import moment from "moment";
import AggreementPdf from "./AggreementPdf";
// import AgreementPdf_new from "./AgreementPdf_new";

import actionVerticleDots from "./assets/more-vertical.png";
import {
  HeaderData,
  AdminHeaderData,
  searchBar,
  getLocalStorage,
} from "./RowHeaders/RowHeaders";
import Index from "../../components/Common/DataTableNeo/Index";
import QuickFilters from "./Quickfilters";

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;

class AgreementIndex_new extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showpdf: false,
      breadcrumbItems: [
        { title: "Billing", link: "#" },
        { title: "Agreements", link: "#" },
      ],
      radioFilter: "",
      searchValue: "",
      searchOn: false,
      transporter: "",
      startTime: "",
      expiryTime: "",
      agreementId: "",
      dataLoading: false,
    };
    (async () => {
      let redirect = await restrict(PERMS.INDENT_PLANNER_READ);
      // //console.log(redirect, "redirect11");

      if (redirect == false) {
        this.props.history.push("/operationsDashboard");
      }
    })();
  }

  // componentDidMount = () => {
  //   this.setState({ dataLoading : true})
  //   this.props.clearLoadedValues();
  // };

  handleDuration = (e) => {
    let value = e.value;
    this.setState({ radioFilter: e }, () => {
      if (this.state.searchOn !== true) this.setState({ searchOn: true });
      this.getAllData();
    });
  };

  getAllData = (e) => {
    this.setState({ dataLoading: true });
    this.props.fetchAgreements(
      { offset, q: this.state.searchValue },
      (response) => {
        this.setState({ dataLoading: false });
      }
    );
  };
  searchValueSetter = (e) => {
    this.setState({ searchValue: e.target.value, searchOn: true }, () => {
      if (this.state.searchKey !== "" && this.state.searchValue !== "")
        this.setState({ disabled: false });
      if (this.state.searchValue === "") {
        this.setState({ disabled: true });
      }
    });
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (this.state.disabled === false) {
        this.getAllData();
      }
    }
  };
  searchFilter = () => {
    if (this.state.disabled === false) {
      this.getAllData();
    }
  };
  handleRadioChange = (e) => {
    this.setState({ dataLoading: true });
    this.setState({ radioFilter: e, searchOn: !this.state.searchOn }, () => {
      this.props.fetchAgreements(
        "",
        (response) => {
          this.setState({ dataLoading: false });
        },
        e.value
      );
    });
  };

  componentDidMount = (e) => {
    this.setState({ dataLoading: true });
    this.props.clearLoadedValues();
    const { agreements } = this.props;
    this.props.fetchAgreements({}, (response) => {
      this.setState({ dataLoading: false });
    });
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }

  reset = (e) => {
    this.setState(
      { searchValue: "", radioFilter: "", dataLoading: true },
      () => {
        offset = 0;
        this.getAllData({});
      }
    );
  };
  handlePdf = (e) => {
    // console.log(e)
    this.setState(
      {
        showpdf: !this.state.showpdf,
        transporter: e.transporter || "",
        startTime: e.startTime || "",
        expiryTime: e.expiryTime || "",
      },
      () => {
        console.log("test", this.state.transporter);
      }
    );
  };
  closehandlePdf = () => {
    this.setState({ showpdf: !this.state.showpdf });
  };
  handleChangePage = (event, val) => {
    this.setState({ dataLoading: true });
    console.log(val, "-----", event);
    let values = {
      offset: (val - 1) * process.env.REACT_APP_PERPAGE,
      q: this.state.searchValue || "",
    };
    let key;
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;

    this.props.fetchAgreements(
      {
        offset: (val - 1) * process.env.REACT_APP_PERPAGE,
        q: this.state.searchValue || "",
      },
      (response) => {
        this.setState({ dataLoading: false });
      }
    );
    this.setState({ loading: true });
  };
  render() {
    const localStorageData = JSON.parse(localStorage.getItem("agreementData"));
    const { agreements } = this.props;
    const agreementData = navigator.onLine
      ? agreements
      : localStorageData?.data;

    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;
    let online = navigator.onLine;

    let Aggreement = [];
    if (Array.isArray(agreementData?.docs)) {
      offset = agreementData?.offset;
      const totCnt = agreementData?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = agreementData?.offset + agreementData.docs?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      agreementData.docs.forEach((e) => {
        Aggreement.push({
          transporter: e.transporter.name,
          startTime: moment(e.startDate).format("DD-MMM-YYYY"),
          expiryTime: moment(e.expiryDate).format("DD-MMM-YYYY"),
          id: e._id,
          //subStatus: "",
          actions: "",
        });
      });
    }

    if (Aggreement !== undefined) {
      Aggreement.forEach((e) => {
        e.actions = (
          <div className="dropdown">
            <Button className="iconButtons dropbtn actionButtonsDark">
              <img
                style={{ margin: "-4px" }}
                src={actionVerticleDots}
                alt="actions"
              />
            </Button>
            <div className="dropdown-content contentDark">
              <div
                // className="action-button actionDspan"
                className={
                  online
                    ? "action-button actionDspan"
                    : "action-button-disabled actionDspan"
                }
                onClick={() => {
                  this.props.fetchViewAgreement(e?.id);
                  this.handlePdf(e);
                }}
              >
                View Agreement
              </div>
              <div
                className="action-button actionDspan"
                onClick={() => {
                  this.props.fetchViewAgreement(e.id);
                  this.props.history.push({
                    pathname: "/Billing/AddAgreement",
                    state: {
                      agreementId: e._id,
                      transporter: e.transporter,
                      startTime: e.startTime || "",
                      expiryTime: e.expiryTime || "",
                      page: "view details",
                    },
                  });
                }}
              >
                View Details
              </div>
            </div>
          </div>
        );
      });
    }

    const customStyles = {
      indicatorsContainer: (style) => {
        return {
          ...style,
          backgroundColor: "#5664D2",
          width: "54px",
          textAlign: "center",
          justifyContent: "center",
          borderRadius: "0px 4px 4px 0px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          backgroundColor: "#5664D2",
        };
      },
      control: (style) => {
        return {
          ...style,
          width: "241px",
          height: "37px",
          backgroundColor: "#F1F5F7",
        };
      },
    };
    return (
      <React.Fragment>
        {getLocalStorage()}
        <AggreementPdf
          valid={this.valid}
          validExpiry={this.validExpiry}
          isOpen={this.state.showpdf}
          closePdf={this.closehandlePdf}
          transporter={this.state.transporter}
          startTime={this.state.startTime}
          expiryTime={this.state.expiryTime}
        />
        <div>
          <QuickFilters
            SearchValue={this.state.searchValue}
            searchValueSetter={this.searchValueSetter}
            handleKeyDown={this.handleKeyDown}
            reset={this.reset}
            searchFilter={this.searchFilter}
          />
          <Container fluid>
            {userType && (userType != "admin" || userType != "Admin") ? (
              <Index
                {...this.props}
                headers={HeaderData}
                data={Aggreement}
                searchBar={searchBar}
                searchable={false}
                getAllData={this.getAllData}
                rowHeight={"56px"}
                dataLoading={this.state.dataLoading}
              />
            ) : (
              <Index
                {...this.props}
                headers={AdminHeaderData}
                data={Aggreement}
                searchBar={searchBar}
                searchable={false}
                getAllData={this.getAllData}
                rowHeight={"56px"}
                dataLoading={this.state.dataLoading}
              />
            )}

            {totCnt !== 0 && !this.state.dataLoading ? (
              <Row
                xs={12}
                style={{ color: "#0C0C0C" }}
                className="mb-2 paginationRow"
              >
                <Col xs={7} sm={5} md={4} className="span-col-pagenation">
                  <div className="float-left">
                    <Pagination
                      className="desktop-pagination"
                      size="medium"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                    <Pagination
                      className="mobile-pagination"
                      size="small"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                  </div>
                </Col>
                <Col
                  className="mt-2 spanPagenationentries span-col-pagenation"
                  xs={5}
                  sm={7}
                  md={8}
                >
                  <span>
                    Showing {fromVal} to {toVal} of {totCnt} entries
                  </span>
                </Col>
              </Row>
            ) : null}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  agreements: state.Billing.billing.agreements,
});

export default connect(mapStateToProps, {
  fetchAgreements,
  fetchViewAgreement,
  clearLoadedValues,
})(AgreementIndex_new);
