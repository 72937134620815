import React, { Component } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  FormGroup,
} from "reactstrap";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";
import { MoonLoader } from "react-spinners";
import Breadcrumbs from "../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { fetchIndents } from "../store/indent/actions";
import { fetchTransporters } from "../store/transporter/actions";

import { MDBDataTable } from "mdbreact";
import Pagination from "@material-ui/lab/Pagination";
import formatDate from "./DateFormatter";
import { getAccessForEntity } from "../pages/policies";
import { MDBBtn } from "mdbreact";

var data = {};
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class Transporter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idToDelete: "",
      open: false,
      rows: [],
      loading: true,
      policies: [],
      breadcrumbItems: [
        { title: userLang?.common?.TRANSPORTER, link: "#" },
        { title: userLang?.common?.TRANSPOTER_DETAILS, link: "#" },
      ],

      filters: "",
      indentId: "",
    };
  }

  componentDidMount() {
    this.getAllData();
  }

  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };

  hasAccessPolicy(val, entity) {
    const { policies } = this.props;
    let hasAccess = false;

    if (policies && policies.length > 0) {
      hasAccess = getAccessForEntity(policies, entity, val);
    }
    return hasAccess;
  }

  getAllData = () => {
    this.props.fetchTransporters(offset, this.state.filters, (res) => {
      if (Array.isArray(res.docs)) {
        totCnt = res.length;
        fromVal = totCnt !== 0 ? offset + 1 : fromVal;
        toVal = res.offset + res.docs.length;
        totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
        let rows = [];
        res.docs.forEach((ele) => {
          rows.push({
            fullName: ele.userId ? ele.userId.fullName : "-",

            email: ele?.userId?.email ? ele.userId.email : "-",
            city: ele?.userId?.city ? ele.userId.city : "-",
            phone: ele?.userId?.phone ? ele.userId.phone : "-",
            state: ele?.userId?.state ? ele.userId.state : "-",
            zipCode: ele?.userId?.zipCode ? ele.userId.zipCode : "-",
          });
        });
        this.setState({ rows });
      }
      this.setState({ loading: false });
    });
  };

  handleChangePage = (event, val) => {
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    this.getAllData();
  };
  filterList = (e) => {
    console.error(e, "e");
    let filters = e.target.value;
    this.setState({ filters }, () => {
      if (filters.length > 2) {
        console.error(e, "e");

        this.getAllData();
      } else {
        this.getAllData();
      }
    });
  };
  change(e) {}
  render() {
    const { fullScreen } = this.props;
    const { loading, rows } = this.state;
    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#123abc"} />
          </Col>
        </div>
      );
    }

    data = {
      columns: [
        {
          label: userLang?.masters?.NAME,
          field: "fullName",
          sort: "asc",
          width: 300,
          align: "center",
        },
        {
          label: userLang?.common?.EMAIL,
          field: "email",
          sort: "asc",
          width: 300,
        },
        {
          label: userLang?.common?.PHONE,
          field: "phone",
          sort: "asc",
          width: 100,
        },
        {
          label: userLang?.common?.STATE,
          field: "state",
          sort: "asc",
          width: 150,
        },
        {
          label: userLang?.common?.CITY,
          field: "city",
          sort: "asc",
          width: 300,
        },
        {
          label: userLang?.common?.ZIP_CODE,
          field: "zipCode",
          sort: "asc",
          width: 300,
          align: "center",
        },
      ],
      rows: rows,
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={userLang?.common?.TRANSPORTER}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <FormGroup className="mb-2 text-right">
              <div>
                {this.hasAccessPolicy("create", "transporter") ? (
                  <Link to="/add-transporter" className="small">
                    <Button
                      type="button"
                      color="btn btn-primary"
                      className="mb-1"
                    >
                      {userLang?.common?.ADD_TRANSPORTER}
                    </Button>
                  </Link>
                ) : null}{" "}
              </div>
            </FormGroup>

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xs={6}>
                        <h4 className="card-title">
                          {userLang?.common?.TRANSPOTER_DETAILS}
                        </h4>
                      </Col>
                      <Col xs={3}></Col>
                      <Col xs={3}>
                        <input
                          className="form-control"
                          type="text"
                          name="filters"
                          placeholder="Search"
                          aria-label="Search"
                          onChange={(e) => this.filterList(e)}
                        />
                      </Col>
                    </Row>{" "}
                    <MDBDataTable
                      responsive
                      striped
                      bordered
                      data={data}
                      paging={false}
                      searching={false}
                      style={{ textAlign: "center" }}
                    />
                    {totCnt !== 0 ? (
                      <Row xs={12}>
                        <Col xs={6}>
                          <span>
                            Showing {fromVal} to {toVal} of {totCnt} entries
                          </span>
                        </Col>
                        <Col xs={6}>
                          <div className="float-right">
                            <Pagination
                              page={offset / 10 + 1}
                              count={totPages}
                              color="primary"
                              onChange={this.handleChangePage}
                            />
                          </div>
                        </Col>
                      </Row>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  policies: state.AccessPolicy.accessPolicies.policies,
});

export default connect(mapStateToProps, {
  fetchIndents,
  fetchTransporters,
})(Transporter);
