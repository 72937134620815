import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { memo } from "react";

import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { connect } from "react-redux";
import InvoiceMenu from "./InvoiceMenu";
import "./style.css";
import AtFoodPdf from "./Manual.pdf";
import MobileMenuBar from "./MobileMenuBar";
// const logoImage = require("../../assets/images/navigation/logo.png");
const logoImage = process.env.REACT_APP_DASHBOARD_LOGO_IMAGE
  ? require("../../assets/images/" + process.env.REACT_APP_DASHBOARD_LOGO_IMAGE)
  : "";
const dashboardImage = require("../../assets/images/navigation/DASHBOARD.png");
// const logoMiniImage = require("../../assets/images/Spinclabs-Thumbnails.png");
const logoMiniImage = process.env.REACT_APP_DASHBOARD_LOGO_MINI_IMAGE
  ? require("../../assets/images/" +
      process.env.REACT_APP_DASHBOARD_LOGO_MINI_IMAGE)
  : "";
const routeOptimization = process.env.REACT_APP_ROUTE_OPTIMIZATION;
const loadOptimization = process.env.REACT_APP_LOAD_OPTIMIZATION;
const planningImage = require("../../assets/images/navigation/PLANNING.png");
const tripsImage = require("../../assets/images/navigation/TRIPS.png");
const mastersImage = require("../../assets/images/navigation/MASTERS.png");
const billingImage = require("../../assets/images/navigation/BILLING.png");
const reportsImage = require("../../assets/images/navigation/REPORTS.png");
const ControlTowerImage = require("../../assets/images/navigation/control tower icon.svg");
//const dashboardImage = require("../../assets/images/navigation/DASHBOARD.png");
//const dashboardImage = require("../../assets/images/navigation/DASHBOARD.png");
let MAIN_DASHBOARD = "MAIN_DASHBOARD";
let MAIN_INDENT_PLANNING = "MAIN_INDENT_PLANNING";
let MAIN_TRIPS = "MAIN_TRIPS";
let MAIN_MASTERS = "MAIN_MASTERS";
let MAIN_INVOICE = "MAIN_INVOICE";
let MAIN_REPORTS = "MAIN_REPORTS";
let MAIN_CONTROL_TOWER = "MAIN_CONTROL_TOWER";
let HELP = "HELP";
let DASHBOARD = "DASHBOARD";
let INDENT_PLANNINGS = "INDENT_PLANNINGS";
let ADD_INDENT = "ADD_INDENT";
let TRIPS = "TRIPS";
let DRIVERS = "DRIVERS";
let VEHICLES = "VEHICLES";
let USERS = "USERS";
let ROLES = "ROLES";
let AGGREMENTS = "AGGREMENTS";
let BILLS = "BILLS";
let TRANSPORTER_PERFORMANCE = "TRANSPORTER_PERFORMANCE";

var usrInfo;
var darkMode;
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainLink: "",
      subLink: {},
      userInfo: {},
      userLangs: undefined,
      zoomVal: "",
    };
  }

  componentDidMount() {
    this.resize();

    console.log(this.props.activeState);
  }

  removeFilterItemFromLocalStorageTrip = () => {
    console.log("Removing filters");

    localStorage.removeItem("filters");
    localStorage.removeItem("offset");
  };
  removeFilterItemFromLocalStorageIndent = () => {
    console.log("Removing filters");
    localStorage.removeItem("IndentFilters");
    localStorage.removeItem("offset");
  };
  resize = () => {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    // else if( window.innerWidth >= 1500 && window.innerWidth <= 1600  ){
    //   this.setState({ zoomVal: 0.84 }, () => {
    //     document.body.style.zoom = this.state.zoomVal;
    //   });
    // }
    else {
      this.setState({ zoomVal: 0.9 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    }
  };

  componentDidUpdate = () => {
    let user = localStorage.getItem("authUser");
    if (user) {
      usrInfo = JSON.parse(user);
      if (usrInfo.data._id !== this.state.userInfo.data?._id)
        this.setState({ userInfo: usrInfo });
    }
    if (
      this.state.userLangs === undefined &&
      (this.state.userLangs !== userLang || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  };
  onResumePdfClick = () => {
    window.open(AtFoodPdf);
  };

  componentWillUnmount(prevProps, prevState) {
    console.log(prevState, "component willunmount");
  }

  handleActiveLink = (mainLinkval) => {
    console.log(mainLinkval);

    this.props.changeActiveTheme(mainLinkval);
    this.setState(
      {
        mainLink: mainLinkval,
        show: !this.state.show,
      },
      () => {
        console.log(this.state.mainLink);
      }
    );
  };

  handleActiveSubLink = (subLink) => {
    this.setState(
      {
        subLink: subLink,
      },
      () => {
        console.log(this.state.subLink);
      }
    );
  };

  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let initStorage = localStorage.getItem("Theme");
    if (initStorage === "dark__mode") {
      darkMode = true;
    } else if (initStorage === "light__mode") {
      darkMode = false;
    } else {
      darkMode = false;
    }

    let { userInfo } = this.state;
    const keyboardArrowIconStyle = {
      position: "absolute",
      fontSize: 20,
      marginLeft: "10px",
      color: "#FFFFFF",
      right: "14%",
      marginTop: "3%",
    };
    console.log(userInfo?.data?.role?.label);

    return (
      <>
        {this.props.show ? (
          <MobileMenuBar
            closeMenuBar={this.props?.closeMenuBar}
            closeIcon={this.props?.show}
            // activeState={this.props.activeState}
            handleActiveLink={this.handleActiveLink}
            handleActiveSubLink={this.handleActiveSubLink}
            userInfo={this.state.userInfo}
            show={this.state.show}
            sublink={this.state.a}
            mainLink={this.state.mainLink}
            userLangs={this.state.userLangs}
            onResumePdfClick={this.onResumePdfClick}
          />
        ) : (
          ""
        )}
        {!this.props.mini ? (
          <div
            id="mySidebar"
            className={`mainExpandedStyle ${
              darkMode ? "verticalMenuDark" : ""
            }`}
            onMouseEnter={this.props.menuBarIn}
            onMouseLeave={this.props.menuBarOut}
          >
            <div>
              <img className="mainLogoStyle" src={logoImage} alt="logo" />
            </div>
            <div className="logoSepartorStyle"></div>
            <div className="linksSection">
              {/* DASHBOARD */}
              {userInfo?.data?.role?.label !== "transporter" ||
              userInfo?.data?.userType == "internal" ? (
                <div className="linkSection">
                  <div
                    className={`mainLink ${
                      this.props.activeState === MAIN_DASHBOARD &&
                      (this.props.location.pathname ===
                        "/operationsDashboard" ||
                        this.props.location.pathname ===
                          "/transporterDashboard" ||
                        this.props.location.pathname === "/invoice-dashboard")
                        ? "activeLinkSection"
                        : null
                    }`}
                    onClick={() => {
                      this.handleActiveLink(MAIN_DASHBOARD);
                      this.removeFilterItemFromLocalStorageIndent();
                      this.removeFilterItemFromLocalStorageTrip();
                    }}
                  >
                    <Link to="/dashboard">
                      <div>
                        <img src={dashboardImage} alt="dashImg" />
                        <span className="mainLinkName">
                          {this.state.userLangs?.dashboard?.DASHBOARD}
                        </span>
                        {/* <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} /> */}
                      </div>
                    </Link>
                  </div>
                  <div
                    className={`subLinks ${
                      this.state.a === 1 ? "displaySubMenu" : "hideSubMenu"
                    }`}
                  ></div>
                </div>
              ) : (
                ""
              )}

              {/* INDENT PLANNING */}
              {userInfo?.data?.role?.label !== "transporter" ? (
                <div className="linkSection">
                  <div
                    className={`mainLink ${
                      this.props.activeState === MAIN_INDENT_PLANNING
                        ? "activeLinkSection"
                        : null
                    }`}
                    onClick={() => {
                      this.handleActiveLink(MAIN_INDENT_PLANNING);
                      this.removeFilterItemFromLocalStorageTrip();
                    }}
                  >
                    <div>
                      <img src={planningImage} />
                      <span className="mainLinkName">
                        {this.state.userLangs?.planning?.PLANNING_CAPS}
                      </span>
                      {this.props.activeState === MAIN_INDENT_PLANNING &&
                      this.state.show === true ? (
                        <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                      ) : (
                        <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                      )}
                    </div>
                  </div>
                  <div
                    className={`subMenu ${
                      this.props.activeState === MAIN_INDENT_PLANNING &&
                      this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                    }`}
                    // onClick={() => this.setState({mainLink:MAIN_INDENT_PLANNING})}
                  >
                    <Link to="/indent-planning">
                      <div className="subMenuLinkDiv">
                        <span className="subMenuLinkName">
                          {this.state.userLangs?.planning?.INDENT_PLANNING}
                        </span>
                      </div>
                    </Link>
                    <Link to="/add-indent">
                      <div className="subMenuLinkDiv">
                        <span className="subMenuLinkName">
                          {this.state.userLangs?.planning?.ADD_INDENT}
                        </span>
                      </div>
                    </Link>
                    {loadOptimization === "true" ? (
                      <Link to="/load-optimization">
                        <div className="subMenuLinkDiv">
                          <span className="subMenuLinkName">
                            Load Optimization
                          </span>
                        </div>
                      </Link>
                    ) : (
                      <></>
                    )}
                    {routeOptimization === "true" ? (
                      <Link to="/Route-Optimization">
                        <div className="subMenuLinkDiv">
                          <span className="subMenuLinkName">
                            {"Route Optimization"}
                            {/* {this.state.userLangs?.planning?.ADD_INDENT} */}
                          </span>
                        </div>
                      </Link>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : (
                <div className="linkSection">
                  <div
                    className={`mainLink ${
                      this.props.activeState === MAIN_INDENT_PLANNING
                        ? "activeLinkSection"
                        : null
                    }`}
                    onClick={() => {
                      this.handleActiveLink(MAIN_INDENT_PLANNING);
                      this.removeFilterItemFromLocalStorageTrip();
                    }}
                  >
                    <div>
                      <img src={planningImage} />
                      <span className="mainLinkName">
                        {" "}
                        {this.state.userLangs?.planning?.PLANNING}
                      </span>
                      {this.props.activeState === MAIN_INDENT_PLANNING &&
                      this.state.show === true ? (
                        <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                      ) : (
                        <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                      )}
                    </div>
                  </div>
                  <div
                    className={`subMenu ${
                      this.props.activeState === MAIN_INDENT_PLANNING &&
                      this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                    }`}
                    // onClick={() => this.setState({mainLink:MAIN_INDENT_PLANNING})}
                  >
                    <Link to="/assigned-indent">
                      <div className="subMenuLinkDiv">
                        <span className="subMenuLinkName">
                          {" "}
                          {this.state.userLangs?.planning?.INDENT_PLANNING}
                        </span>
                      </div>
                    </Link>
                    {routeOptimization === "true" ? (
                      <Link to="/Route-Optimization">
                        <div className="subMenuLinkDiv">
                          <span className="subMenuLinkName">
                            {"Route-Optimization "}
                            {/* {this.state.userLangs?.planning?.INDENT_PLANNING} */}
                          </span>
                        </div>
                      </Link>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              )}

              {/* TRIPS */}
              <div className="linkSection">
                <div
                  className={`mainLink ${
                    this.props.activeState === MAIN_TRIPS
                      ? "activeLinkSection"
                      : null
                  }`}
                  onClick={() => {
                    this.handleActiveLink(MAIN_TRIPS);
                    this.removeFilterItemFromLocalStorageIndent();
                  }}
                >
                  <div>
                    <img src={tripsImage} />
                    <span className="mainLinkName">
                      {" "}
                      {this.state.userLangs?.trip?.TRIPS}
                    </span>
                    {this.props.activeState === MAIN_TRIPS &&
                    this.state.show === true ? (
                      <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                    ) : (
                      <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                    )}
                  </div>
                </div>
                <div
                  className={`subMenu ${
                    this.props.activeState === MAIN_TRIPS &&
                    this.state.show === true
                      ? "displaySubMenu"
                      : "hideSubMenu"
                  }`}
                >
                  <Link to="/trips">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">
                        {" "}
                        {this.state.userLangs?.trip?.VIEW_ALL_TRIPS}
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
              {/* MASTERS */}
              <div className="linkSection">
                <div
                  className={`mainLink ${
                    this.props.activeState === MAIN_MASTERS
                      ? "activeLinkSection"
                      : null
                  }`}
                  onClick={() => {
                    this.handleActiveLink(MAIN_MASTERS);
                    this.removeFilterItemFromLocalStorageIndent();
                    this.removeFilterItemFromLocalStorageTrip();
                  }}
                >
                  <div>
                    <img src={mastersImage} />
                    <span className="mainLinkName">
                      {this.state.userLangs?.masters?.MASTERS}
                    </span>
                    {this.props.activeState === MAIN_MASTERS &&
                    this.state.show === true ? (
                      <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                    ) : (
                      <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                    )}
                  </div>
                </div>
                <div
                  className={`subMenu ${
                    this.props.activeState === MAIN_MASTERS &&
                    this.state.show === true
                      ? "displaySubMenu"
                      : "hideSubMenu"
                  }`}
                >
                  <Link to="/drivers">
                    <div
                      className="subMenuLinkDiv"
                      onClick={() => this.handleActiveSubLink(DRIVERS)}
                    >
                      <span className="subMenuLinkName">
                        {" "}
                        {this.state.userLangs?.masters?.DRIVERS}
                      </span>
                    </div>
                  </Link>
                  <Link to="/vehicles">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">
                        {" "}
                        {this.state.userLangs?.masters?.VEHICLES}
                      </span>
                    </div>
                  </Link>
                  {userInfo?.data?.role?.label === "Admin" ? (
                    <Link to="/vehicle-type">
                      <div className="subMenuLinkDiv">
                        <span className="subMenuLinkName">
                          {this.state.userLangs?.masters?.VEHICLE_TYPE}
                        </span>
                      </div>
                    </Link>
                  ) : (
                    ""
                  )}

                  {userInfo?.data?.role?.label !== "transporter" ? (
                    <Link to="/user">
                      <div className="subMenuLinkDiv">
                        <span className="subMenuLinkName">
                          {" "}
                          {this.state.userLangs?.masters?.USERS}
                        </span>
                      </div>
                    </Link>
                  ) : (
                    ""
                  )}
                  {userInfo?.data?.role?.label !== "transporter" ? (
                    <Link to="/roles">
                      <div className="subMenuLinkDiv">
                        <span className="subMenuLinkName">
                          {" "}
                          {this.state.userLangs?.masters?.ROLES}
                        </span>
                      </div>
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* INVOICE */}
              {userInfo?.data?.role?.label === "transporter" ? (
                <div className="linkSection">
                  <div
                    className={`mainLink ${
                      this.props.activeState === MAIN_INVOICE
                        ? "activeLinkSection"
                        : null
                    }`}
                    onClick={() => {
                      this.handleActiveLink(MAIN_INVOICE);
                      this.removeFilterItemFromLocalStorageIndent();
                      this.removeFilterItemFromLocalStorageTrip();
                    }}
                  >
                    <div>
                      <img src={billingImage} />
                      <span className="mainLinkName">
                        {this.state.userLangs?.dashboard?.INVOICE}
                      </span>
                      {this.props.activeState === MAIN_INVOICE &&
                      this.state.show === true ? (
                        <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                      ) : (
                        <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                      )}
                    </div>
                  </div>
                  <div
                    className={`subMenu ${
                      this.props.activeState === MAIN_INVOICE &&
                      this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                    }`}
                  >
                    <Link to="/Billing/InvoiceList">
                      <div className="subMenuLinkDiv">
                        <span className="subMenuLinkName">
                          {this.state.userLangs?.invoice?.INVOICE_LIST}
                        </span>
                      </div>
                    </Link>
                    <Link to="/invoiceGeneration">
                      <div className="subMenuLinkDiv">
                        <span className="subMenuLinkName">
                          {this.state.userLangs?.invoice?.INVOICE_GENERATION}
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : (
                ""
              )}

              {userInfo?.data?.role?.label === "Admin" ||
              userInfo?.data?.role?.label === "billing_manager" ? (
                <div className="linkSection">
                  <div
                    className={`mainLink ${
                      this.props.activeState === MAIN_INVOICE
                        ? "activeLinkSection"
                        : null
                    }`}
                    onClick={() => {
                      this.handleActiveLink(MAIN_INVOICE);
                      this.removeFilterItemFromLocalStorageIndent();
                      this.removeFilterItemFromLocalStorageTrip();
                    }}
                  >
                    <div>
                      <img src={billingImage} />
                      <span className="mainLinkName">
                        {this.state.userLangs?.dashboard?.INVOICE}
                      </span>
                      {this.state.activeState === MAIN_INVOICE &&
                      this.state.show === true ? (
                        <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                      ) : (
                        <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                      )}
                    </div>
                  </div>
                  <div
                    className={`subMenu ${
                      this.props.activeState === MAIN_INVOICE &&
                      this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                    }`}
                  >
                    <Link to="/BillingIndex">
                      <div className="subMenuLinkDiv">
                        <span className="subMenuLinkName">
                          {this.state.userLangs?.invoice?.AGREEMENT}
                        </span>
                      </div>
                    </Link>
                    <Link to="/Billing/InvoiceList">
                      <div className="subMenuLinkDiv">
                        <span className="subMenuLinkName">
                          {this.state.userLangs?.invoice?.INVOICE_LIST}
                        </span>
                      </div>
                    </Link>

                    <Link to="/tripBills">
                      <div className="subMenuLinkDiv">
                        <span className="subMenuLinkName">
                          {this.state.userLangs?.invoice?.ADD_BILL}
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : (
                ""
              )}

              {userInfo?.data?.role?.label === "billing_team_member" ? (
                <div className="linkSection">
                  <div
                    className={`mainLink ${
                      this.props.activeState === MAIN_INVOICE
                        ? "activeLinkSection"
                        : null
                    }`}
                    onClick={() => {
                      this.handleActiveLink(MAIN_INVOICE);
                      this.removeFilterItemFromLocalStorageIndent();
                      this.removeFilterItemFromLocalStorageTrip();
                    }}
                  >
                    <div>
                      <img src={billingImage} />
                      <span className="mainLinkName">
                        {this.state.userLangs?.dashboard?.INVOICE}
                      </span>
                      {this.props.activeState === MAIN_INVOICE &&
                      this.state.show === true ? (
                        <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                      ) : (
                        <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                      )}
                    </div>
                  </div>
                  <div
                    className={`subMenu ${
                      this.props.activeState === MAIN_INVOICE &&
                      this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                    }`}
                  >
                    <Link to="/Billing/InvoiceList">
                      <div className="subMenuLinkDiv">
                        <span className="subMenuLinkName">
                          {this.state.userLangs?.invoice?.INVOICE_LIST}
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : (
                ""
              )}
              {userInfo?.data?.role?.label === "loading_unloading" ? (
                <div className="linkSection">
                  <div
                    className={`mainLink ${
                      this.props.activeState === MAIN_INVOICE
                        ? "activeLinkSection"
                        : null
                    }`}
                    onClick={() => {
                      this.handleActiveLink(MAIN_INVOICE);
                      this.removeFilterItemFromLocalStorageIndent();
                      this.removeFilterItemFromLocalStorageTrip();
                    }}
                  >
                    <div>
                      <img src={billingImage} />
                      <span className="mainLinkName">
                        {this.state.userLangs?.dashboard?.INVOICE}
                      </span>
                      {this.props.activeState === MAIN_INVOICE &&
                      this.state.show === true ? (
                        <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                      ) : (
                        <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                      )}
                    </div>
                  </div>
                  <div
                    className={`subMenu ${
                      this.props.activeState === MAIN_INVOICE &&
                      this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                    }`}
                  >
                    <Link to="/BillingIndex">
                      <div className="subMenuLinkDiv">
                        <span className="subMenuLinkName">
                          {this.state.userLangs?.invoice?.AGREEMENT}
                        </span>
                      </div>
                    </Link>
                    <Link to="/tripBills">
                      <div className="subMenuLinkDiv">
                        <span className="subMenuLinkName">
                          {this.state.userLangs?.invoice?.ADD_BILL}
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* INVOICE */}
              {/* <InvoiceMenu
                userRole={userInfo?.data?.role?.label}
                handleActiveLink={this.handleActiveLink}
                show={this.state.show}
                keyboardArrowIconStyle={keyboardArrowIconStyle}
              /> */}

              {/* REPORTS */}
              {userInfo?.data?.role?.label !== "transporter" ? (
                <div className="linkSection">
                  <div
                    className={`mainLink ${
                      this.props.activeState === MAIN_REPORTS
                        ? "activeLinkSection"
                        : null
                    }`}
                    onClick={() => {
                      this.handleActiveLink(MAIN_REPORTS);
                      this.removeFilterItemFromLocalStorageIndent();
                      this.removeFilterItemFromLocalStorageTrip();
                    }}
                  >
                    <div>
                      <img src={reportsImage} />
                      <span className="mainLinkName">
                        {" "}
                        {this.state.userLangs?.report?.REPORTS}
                      </span>
                      {this.state.mainLink === MAIN_REPORTS &&
                      this.state.show === true ? (
                        <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                      ) : (
                        <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                      )}
                    </div>
                  </div>
                  <div
                    className={`subMenuReport ${
                      this.props.activeState === MAIN_REPORTS &&
                      this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                    }`}
                  >
                    <Link to="/Reports">
                      <div className="subMenuLinkDiv">
                        <div className="subMenuLinkName">
                          <span className="subMenuLinkName">
                            {
                              this.state.userLangs?.report
                                ?.TRANSPORTER_PERFORMANCE
                            }
                          </span>
                        </div>
                      </div>
                    </Link>
                    <div></div>
                    <Link to="/indentReport-analysis">
                      <div className="subMenuLinkDiv">
                        <div className="subMenuLinkName">
                          <span className="subMenuLinkName">
                            {" "}
                            {
                              this.state.userLangs?.report
                                ?.INDENT_ANALYSIS_REPORT
                            }
                          </span>
                        </div>
                      </div>
                    </Link>
                    <Link to="/vehicle-placement">
                      <div className="subMenuLinkDiv">
                        <div className="subMenuLinkName">
                          <span className="subMenuLinkName">
                            {this.state.userLangs?.report
                              ?.VEHICLE_PLACEMENT_REPORT
                              ? this.state.userLangs?.report
                                  ?.VEHICLE_PLACEMENT_REPORT
                              : "Vehicle Placement Report"}
                          </span>
                        </div>
                      </div>
                    </Link>
                    <Link to="/ontime-delivery-report">
                      <div className="subMenuLinkDiv">
                        <div className="subMenuLinkName">
                          <span className="subMenuLinkName">
                          {this.state.userLangs?.report
                            ?.ON_TIME_DELIVERY_REPORT1
                            ?? "On Time Delivery Report  "}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : (
                ""
              )}
              {userInfo?.data?.role?.label !== "transporter" ? (
                <div className="linkSection">
                  <div
                    className={`mainLink ${
                      this.props.activeState === MAIN_CONTROL_TOWER &&
                      this.props.location.pathname === "/ControlTower"
                        ? "activeLinkSection"
                        : null
                    }`}
                    onClick={() => {
                      this.handleActiveLink(MAIN_CONTROL_TOWER);
                      this.removeFilterItemFromLocalStorageIndent();
                      this.removeFilterItemFromLocalStorageTrip();
                    }}
                  >
                    <Link to="/ControlTower">
                      <div>
                        <img src={ControlTowerImage} alt="controlImg" />
                        <span className="mainLinkName">
                          {" "}
                          {this.state.userLangs?.controlTower?.CONTROL_TOWER}
                        </span>
                        {/* <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} /> */}
                      </div>
                    </Link>
                  </div>
                  <div
                    className={`subLinks ${
                      this.state.a === 1 ? "displaySubMenu" : "hideSubMenu"
                    }`}
                  ></div>
                </div>
              ) : (
                ""
              )}
              {/* HELP */}
              <div className="helpMenuStyle " onClick={this.onResumePdfClick}>
                <div className="mainLinkHelp">
                  {/* <img src={ControlTowerImage} alt="controlImg" /> */}
                  <a href={AtFoodPdf} target="_blank" rel="noreferrer">
                    <span className="helpName">
                      {this.state.userLangs?.common?.HELP}
                    </span>
                  </a>
                </div>
              </div>
            </div>

            <div></div>
          </div>
        ) : (
          <div
            id="mySidebar"
            className={`mainExpandedStyles ${
              darkMode ? "verticalMenuDark" : ""
            }`}
            onMouseEnter={this.props.menuBarIn}
            onMouseLeave={this.props.menuBarOut}
          >
            <div>
              <img className="mainLogoStyleMini" src={logoMiniImage} />
            </div>
            <div className="logoSepartorStyle"></div>
            <div className="linksSection">
              {/* DASHBOARD */}
              {userInfo?.data?.role?.label !== "transporter" ||
              userInfo?.data?.userType == "internal" ? (
                <div className="linkSection">
                  <div
                    className={`mainLink ${
                      this.props.activeState === MAIN_DASHBOARD &&
                      (this.props.location.pathname ===
                        "/operationsDashboard" ||
                        this.props.location.pathname ===
                          "/transporterDashboard" ||
                        this.props.location.pathname === "/invoice-dashboard")
                        ? "activeLinkSection"
                        : null
                    }`}
                    onClick={() => {
                      this.handleActiveLink(MAIN_DASHBOARD);
                      this.removeFilterItemFromLocalStorageIndent();
                      this.removeFilterItemFromLocalStorageTrip();
                    }}
                  >
                    <Link to="/dashboard">
                      <div>
                        <img src={dashboardImage} />
                        <span className="mainLinkName"></span>
                        {/* <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} /> */}
                      </div>
                    </Link>
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* INDENT PLANNING */}
              {userInfo?.data?.role?.label !== "transporter" ? (
                <div className="linkSection">
                  <div
                    className={`mainLink ${
                      this.props.activeState === MAIN_INDENT_PLANNING ||
                      this.props.location.pathname === "/indent-planning"
                        ? "activeLinkSection"
                        : null
                    }`}
                    onClick={() => {
                      this.handleActiveLink(MAIN_INDENT_PLANNING);
                      this.removeFilterItemFromLocalStorageTrip();
                    }}
                  >
                    <div>
                      <img src={planningImage} />
                      <span className="mainLinkName"></span>
                    </div>
                  </div>
                  <div
                    className={`subMenu ${
                      this.props.activeState === MAIN_INDENT_PLANNING &&
                      this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                    }`}
                    // onClick={() => this.setState({mainLink:MAIN_INDENT_PLANNING})}
                  >
                    <Link to="/indent-planning">
                      <div>
                        <span className="subMenuLinkName"></span>
                      </div>
                    </Link>
                    <Link to="/add-indent">
                      <div>
                        <span className="subMenuLinkName"></span>
                      </div>
                    </Link>
                    {routeOptimization ? (
                      <Link to="/Route-Optimization">
                        <div>
                          <span className="subMenuLinkName"></span>
                        </div>
                      </Link>
                    ) : (
                      <></>
                    )}
                    {loadOptimization ? (
                      <Link to="/load-optimization">
                        <div>
                          <span className="subMenuLinkName"></span>
                        </div>
                      </Link>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : (
                <div className="linkSection">
                  <div
                    className={`mainLink ${
                      this.props.activeState === MAIN_INDENT_PLANNING ||
                      this.props.location.pathname === "/assigned-indent"
                        ? "activeLinkSection"
                        : null
                    }`}
                    onClick={() => {
                      this.handleActiveLink(MAIN_INDENT_PLANNING);
                      this.removeFilterItemFromLocalStorageTrip();
                    }}
                  >
                    <div>
                      <img src={planningImage} />
                      <span className="mainLinkName"></span>
                    </div>
                  </div>
                  <div
                    className={`subMenu ${
                      this.props.activeState === MAIN_INDENT_PLANNING &&
                      this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                    }`}
                    // onClick={() => this.setState({mainLink:MAIN_INDENT_PLANNING})}
                  >
                    <Link to="/indent-planning">
                      <div>
                        <span className="subMenuLinkName"></span>
                      </div>
                    </Link>
                  </div>
                </div>
              )}

              {/* TRIPS */}
              <div className="linkSection">
                <div
                  className={`mainLink ${
                    this.props.activeState === MAIN_TRIPS
                      ? "activeLinkSection"
                      : null
                  }`}
                  onClick={() => {
                    this.handleActiveLink(MAIN_TRIPS);
                    this.removeFilterItemFromLocalStorageIndent();
                  }}
                >
                  <div>
                    <img src={tripsImage} />
                    <span className="mainLinkName"></span>
                  </div>
                </div>
                <div
                  className={`subMenu ${
                    this.props.activeState === MAIN_TRIPS &&
                    this.state.show === true
                      ? "displaySubMenu"
                      : "hideSubMenu"
                  }`}
                >
                  <Link to="/trips">
                    <div>
                      <span className="subMenuLinkName"></span>
                    </div>
                  </Link>
                </div>
              </div>
              {/* MASTERS */}
              <div className="linkSection">
                <div
                  className={`mainLink ${
                    this.props.activeState === MAIN_MASTERS
                      ? "activeLinkSection"
                      : null
                  }`}
                  onClick={() => {
                    this.handleActiveLink(MAIN_MASTERS);
                    this.removeFilterItemFromLocalStorageIndent();
                    this.removeFilterItemFromLocalStorageTrip();
                  }}
                >
                  <div>
                    <img src={mastersImage} />
                    <span className="mainLinkName"></span>
                  </div>
                </div>
                <div
                  className={`subMenu ${
                    this.props.activeState === MAIN_MASTERS &&
                    this.state.show === true
                      ? "displaySubMenu"
                      : "hideSubMenu"
                  }`}
                >
                  <Link to="/drivers">
                    <div onClick={() => this.handleActiveSubLink(DRIVERS)}>
                      <span className="subMenuLinkName"></span>
                    </div>
                  </Link>
                  <Link to="/vehicles">
                    <div>
                      <span className="subMenuLinkName"></span>
                    </div>
                  </Link>
                  {userInfo?.data?.role?.label === "Admin" ? (
                    <Link to="/vehicle-type">
                      <div>
                        <span className="subMenuLinkName"></span>
                      </div>
                    </Link>
                  ) : (
                    ""
                  )}

                  <Link to="/user">
                    <div>
                      <span className="subMenuLinkName"></span>
                    </div>
                  </Link>
                  <Link to="/roles">
                    <div>
                      <span className="subMenuLinkName"></span>
                    </div>
                  </Link>
                </div>
              </div>
              {/* INVOICE */}
              {userInfo?.data?.role?.label === "indent_planner" ? (
                <></>
              ) : (
                <div className="linkSection">
                  <div
                    className={`mainLink ${
                      this.props.activeState === MAIN_INVOICE
                        ? "activeLinkSection"
                        : null
                    }`}
                    onClick={() => {
                      this.handleActiveLink(MAIN_INVOICE);
                      this.removeFilterItemFromLocalStorageIndent();
                      this.removeFilterItemFromLocalStorageTrip();
                    }}
                  >
                    <div>
                      <img src={billingImage} />
                      <span className="mainLinkName"></span>
                    </div>
                  </div>
                </div>
              )}

              {/* REPORTS */}
              {userInfo?.data?.role?.label !== "transporter" ? (
                <div className="linkSection">
                  <div
                    className={`mainLink ${
                      this.props.activeState === MAIN_REPORTS
                        ? "activeLinkSection"
                        : null
                    }`}
                    onClick={() => {
                      this.handleActiveLink(MAIN_REPORTS);
                      this.removeFilterItemFromLocalStorageIndent();
                      this.removeFilterItemFromLocalStorageTrip();
                    }}
                  >
                    <div>
                      <img src={reportsImage} />
                      <span className="mainLinkName"></span>
                    </div>
                  </div>
                  <div
                    className={`subMenuReport ${
                      this.props.activeState === MAIN_REPORTS &&
                      this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                    }`}
                  >
                    <Link to="/Reports">
                      <div>
                        <span className="subMenuLinkName"></span>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : (
                ""
              )}
              {userInfo?.data?.role?.label !== "transporter" ? (
                <div className="linkSection">
                  <div
                    className={`mainLink ${
                      this.props.activeState === MAIN_CONTROL_TOWER &&
                      this.props.location.pathname === "/ControlTower"
                        ? "activeLinkSection"
                        : null
                    }`}
                    onClick={() => {
                      this.handleActiveLink(MAIN_CONTROL_TOWER);
                      this.removeFilterItemFromLocalStorageIndent();
                      this.removeFilterItemFromLocalStorageTrip();
                    }}
                  >
                    <Link to="/ControlTower">
                      <div>
                        <img src={ControlTowerImage} alt="controlImg" />
                        <span className="mainLinkName"></span>
                        {/* <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} /> */}
                      </div>
                    </Link>
                  </div>
                  <div
                    className={`subLinks ${
                      this.state.a === 1 ? "displaySubMenu" : "hideSubMenu"
                    }`}
                  ></div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div></div>
          </div>
        )}
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
    activeState: state.Layout.activeTheme,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeActiveTheme: (name) => {
      dispatch({ type: "CHANGE_ACTIVE_THEME", payload: name });
    },
  };
};

export default connect(mapStatetoProps, mapDispatchToProps)(withRouter(index));
