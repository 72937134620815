// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { types } from "./actionTypes";

import {
  deleteApi,
  getApi,
  patchApi,
  postApi,
} from "../../helpers/auth_helper";

function* workfetchVehicles({ offset, limit, values }) {
  try {
    console.log("called", values);
    // let offset=0;
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/report/reports?offset=` +
      offset +
      `&limit=` +
      limit +
      `&transporter=` +
      (values?.transporter || "") +
      `&startDate=` +
      values?.FromDate +
      `&endDate=` +
      values?.ToDate;
    const response = yield call(getApi, uri);
    yield put({
      type: types.FETCH_REPORTS_SUCCESS,
      payload: response,
    });
    localStorage.setItem("reportData", JSON.stringify(response));
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch vehicle.", error);
  }
}

function* workfetchIndentLane({ offset, filters, callback }) {
  try {
    let filterString = "";
    for (const [key, value] of Object.entries(filters)) {
      let filterKey = key;
      let filterValue = value;
      if (filterKey === "startTime") {
        console.log(value, "-", key);
        filterString =
          filterString +
          "&" +
          "startTimeStart=" +
          filterValue[0] +
          "&startTimeEnd=" +
          filterValue[1];
      } else filterString = filterString + "&" + filterKey + "=" + filterValue;
      console.log(filterString, "FILETR-SAGA");
    }
    console.log("called");
    // let offset=0;
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/indent/lane?offset=` +
      offset +
      `&limit=` +
      Number(process.env.REACT_APP_PERPAGE) +
      filterString;

    const response = yield call(patchApi, uri);
    yield put({
      type: types.FETCH_INDENTLANE_REPORTS_SUCCESS,
      payload: response,
    });
    callback(response)
    localStorage.setItem("indentLaneData", JSON.stringify(response));
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch.", error);
  }
}

function* workfetchIndentStatus({ offset, filters, callback }) {
  try {
    let filterString = "";
    for (const [key, value] of Object.entries(filters)) {
      let filterKey = key;
      let filterValue = value;
      if (filterKey === "startTime") {
        console.log(value, "-", key);
        filterString =
          filterString +
          "&" +
          "startTimeStart=" +
          filterValue[0] +
          "&startTimeEnd=" +
          filterValue[1];
      } else filterString = filterString + "&" + filterKey + "=" + filterValue;
      console.log(filterString, "FILETR-SAGA");
    }
    console.log("called");
    // let offset=0;
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/indent/status?offset=` +
      offset +
      `&limit=` +
      Number(process.env.REACT_APP_PERPAGE) +
      filterString;

    const response = yield call(patchApi, uri);
    console.log(response, "response");
    yield put({
      type: types.FETCH_INDENTSTATUS_REPORTS_SUCCESS,
      payload: response,
    });
    localStorage.setItem("indentStatusData", JSON.stringify(response));
    callback(response)
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch vehicle.", error);
  }
}

function* workfetchTransporterReports({ offset, filters, callback }) {
  try {
    let filterString = "";
    for (const [key, value] of Object.entries(filters)) {
      let filterKey = key;
      let filterValue = value;
      if (filterKey === "startTime") {
        console.log(value, "-", key);
        filterString =
          filterString +
          "&" +
          "startTimeStart=" +
          filterValue[0] +
          "&startTimeEnd=" +
          filterValue[1];
      } else filterString = filterString + "&" + filterKey + "=" + filterValue;
      console.log(filterString, "FILETR-SAGA");
    }
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/indent/transveh?offset=` +
      offset +
      `&limit=` +
      Number(process.env.REACT_APP_PERPAGE) +
      filterString;
    const response = yield call(patchApi, uri);
    console.log(response, "response");
    yield put({
      type: types.FETCH_INDENTTRANSPORTER_REPORTS_SUCCESS,
      payload: response,
    });
    callback(response)
    localStorage.setItem("indentTransporterData", JSON.stringify(response));
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch .", error);
  }
}

function* workfetchTransporterPerformance({offset, limit, values,callback}) {
  console.log(offset, limit, values)
  try {
    console.log("called", values);
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/report/fetch/transporterPerformance?offset=` +
      offset +
      `&limit=` +
      limit +
      `&transporterId=` +
      (values?.transporter || "") +
      `&startDate=` +
      values?.FromDate +
      `&endDate=` +
      values?.ToDate;
    const response = yield call(getApi, uri);
    yield put({
      type: types.FETCH_TRANSPORTER_PERFORMANCE_SUCCESS,
      payload: response,
    });
    localStorage.setItem("transporterPerformanceData", JSON.stringify(response));
    callback(response)
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch vehicle.", error);
  }
}

export function* watchFetchReports() {
  yield takeEvery(types.FETCH_REPORTS, workfetchVehicles);
}

export function* watchFetchIndentLaneReports() {
  yield takeEvery(types.FETCH_INDENTLANE_REPORTS, workfetchIndentLane);
}

export function* watchFetchStatusReports() {
  yield takeEvery(types.FETCH_INDENTSTATUS_REPORTS, workfetchIndentStatus);
}

export function* watchFetchTransporterReports() {
  yield takeEvery(
    types.FETCH_INDENTTRANSPORTER_REPORTS,
    workfetchTransporterReports
  );
}
export function* workFetchTrasnporterPerformance(){
  yield takeEvery(types.FETCH_TRANSPORTER_PERFORMANCE, workfetchTransporterPerformance)
}

function* ReportsSaga() {
  yield all([
    fork(watchFetchReports),
    fork(watchFetchIndentLaneReports),
    fork(watchFetchStatusReports),
    fork(watchFetchTransporterReports),
    fork(workFetchTrasnporterPerformance)
  ]);
}
export default ReportsSaga;
