import React, { Component } from "react";
import "../HorizontalLayout/header.css";
import { restrictSync, roleCheck } from "../../pages/restrict";
import { PERMS } from "../../enum/perms.enum";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import { Button } from "reactstrap";
import { fetchSync, fetchLastSyncTime } from "../../store/common/actions";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import toggle from "../../assets/images/ToggleGroup13563.png";
import VerticalMenuBar from "../VerticalMenuBar/index";
import AppTheme from "../AppTheme";
import { Card } from "reactstrap";
import { changeSwitchTheme } from "../../store/layout/actions";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class HeaderNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      userLangs: undefined,
      show: false,
    };
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
  }
  toggleSynch = () => {
    //code for synchronization with Oracle
    this.setState({ isLoading: true });
    console.log("Sync 1");
    this.props.fetchSync((res) => {
      if (res.success === true) {
        NotificationManager.success("Synced", "Success");
        this.setState({ isLoading: false });
      } else {
        NotificationManager.error("Not Synced", "failure");
        this.setState({ isLoading: false });
      }
    });
  };
  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  componentDidUpdate() {
    var switchTheme = this.props.switchsTheme;
    console.log(switchTheme, "switchTheme");
    let initTogglestorage = localStorage.getItem("Theme");
    if (
      this.props.switchsTheme === "dark__mode" ||
      initTogglestorage === "dark__mode"
    ) {
      const spanColBread = document.getElementsByClassName("breadactive");
      if (spanColBread && spanColBread.length) {
        spanColBread[0].style.color = "#FFFFFF";
      }
      const spanCol = document.getElementsByClassName("span-font-roboto");
      if (spanCol && spanCol.length) {
        spanCol[0].style.color = "#B7A9FF";
      }

      const spanlan = document.getElementsByClassName("ri-fullscreen-line");
      if (spanlan && spanlan.length) {
        spanlan[0].style.color = "#FFFFFF";
      }
    } else if (
      this.props.switchsTheme === "light__mode" ||
      initTogglestorage === "light__mode"
    ) {
      const spanColBread = document.getElementsByClassName("breadactive");
      if (spanColBread && spanColBread.length)
        spanColBread[0].style.color = "#343434";

      const spanCol = document.getElementsByClassName("span-font-roboto");
      if (spanCol && spanCol.length) spanCol[0].style.color = "#2800FC";

      const spanlan = document.getElementsByClassName("ri-fullscreen-line");
      if (spanlan && spanlan.length) spanlan[0].style.color = "#000000";
    }

    if (
      this.state.userLangs === undefined &&
      (this.state.userLangs !== userLang || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }

  closeMenuBar = (closeIcon) => {
    // console.log("close", closeIcon);
    this.setState({
      show: closeIcon,
    });
  };
  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

    const path = this.props.location.pathname;
    let rotate;
    if (this.state.isLoading === true) {
      rotate = "rotate";
    }
    var switchTheme = this.props.switchsTheme;
    let initTogglestorage = localStorage.getItem("Theme");

    if (switchTheme === "dark__mode" || initTogglestorage === "dark__mode") {
      var headerDarkBgClr = "#373756";
      var darkTheme = true;
    } else if (
      switchTheme === "light__mode" ||
      initTogglestorage === "light__mode"
    ) {
      var headerDarkBgClr = " ";
      var darkTheme = false;
    }
    return (
      <>
        {/* <Card id="page-topbar-new"> */}
        <header
          id="page-topbar-new "
          className="navbar-header-new-top shadow"
          style={{
            backgroundColor: `${headerDarkBgClr}`,
          }}
        >
          <div 
           className={
            this.props?.history?.location?.pathname == "/ontime-delivery-report" ? 
            "navbar-header-new-scroll" :"navbar-header-new"
          }
          // className="navbar-header-new"
          >
            <div className="d-flex">
              <div className="mobile-toggle ml-1 mr-2">
                <img
                  onClick={() => {
                    this.setState({ show: !this.state.show }, () => {});
                  }}
                  src={toggle}
                  height="15px"
                  width="18px"
                ></img>
                <VerticalMenuBar
                  show={this.state.show}
                  closeMenuBar={this.closeMenuBar}
                />
              </div>
              <div className="">
                {path === "/operationsDashboard" ? (
                  <>
                    <span className="span-font-roboto">
                      {this.state.userLangs?.dashboard?.DASHBOARD}
                    </span>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.dashboard?.OPERATIONS}
                    </span>
                  </>
                ) : path === "/transporterDashboard" ? (
                  <>
                    <span className="span-font-roboto">
                      {this.state.userLangs?.dashboard?.DASHBOARD}
                    </span>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.planning?.TRANSPORTER}
                    </span>
                  </>
                ) : path === "/invoice-dashboard" ? (
                  <>
                    <span className="span-font-roboto">
                      {this.state.userLangs?.dashboard?.DASHBOARD}
                    </span>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.dashboard?.INVOICE}
                    </span>
                  </>
                ) : path === "/indent-planning" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.dashboard?.INDENT_DETAILS}
                    </span>
                  </>
                ) : path === "/add-indent" ? (
                  <>
                    <div className="mobileAddIndent">
                      <Link to="/indent-planning">
                        <span className="breadcrumbInactive breadcrumb-active span-font-roboto ">
                          {this.state.userLangs?.dashboard?.INDENT_DETAILS}
                        </span>
                      </Link>
                      <span className="breadcrumbSeparator">|</span>
                      <span className="span-font-roboto ">
                        {this.state.userLangs?.planning?.INDENT_PLANNING}
                      </span>
                    </div>
                    <div className="desktopAddIndent">
                      <Link to="/indent-planning">
                        <span
                          className="breadcrumbInactive breadcrumb-active span-font-roboto"
                          style={{ color: `${darkTheme ? "#B7A9FF" : ""}` }}
                        >
                          {this.state.userLangs?.dashboard?.INDENT_DETAILS}
                        </span>
                      </Link>
                      <span className="breadcrumbSeparator">|</span>
                      <span className="span-font-roboto breadactive">
                        {this.state.userLangs?.planning?.INDENT_PLANNING}
                      </span>
                    </div>
                  </>
                ) : path === "/Route-Optimization" ? (
                  <>
                    <div className="mobileAddIndent">
                      <Link to="/Route-Optimization">
                        <span className="breadcrumbInactive breadcrumb-active span-font-roboto ">
                          {this.state.userLangs?.dashboard?.INDENT_DETAILS}
                        </span>
                      </Link>
                      <span className="breadcrumbSeparator">|</span>
                      <span className="span-font-roboto ">
                        {"Route-Optimization"}
                        {/* {this.state.userLangs?.planning?.INDENT_PLANNING} */}
                      </span>
                    </div>
                    <div className="desktopAddIndent">
                      <Link to="/Route-Optimization">
                        <span
                          className="breadcrumbInactive breadcrumb-active span-font-roboto"
                          style={{ color: `${darkTheme ? "#B7A9FF" : ""}` }}
                        >
                          {this.state.userLangs?.dashboard?.INDENT_DETAILS}
                        </span>
                      </Link>
                      <span className="breadcrumbSeparator">|</span>
                      <span className="span-font-roboto breadactive">
                        {"Route-Optimization"}
                        {/* {this.state.userLangs?.planning?.INDENT_PLANNING} */}
                      </span>
                    </div>
                  </>
                ) : path === "/trips" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.trip?.TRIPS_DETAILS}
                    </span>
                  </>
                ) : path === "/tripDetails" ? (
                  <>
                    <Link to="/trips">
                      <span
                        className="breadcrumbInactive breadcrumb-active span-font-roboto"
                        style={{ color: `${darkTheme ? "#ffffff" : ""}` }}
                      >
                        {this.state.userLangs?.trip?.TRIPS_DETAILS}
                      </span>
                    </Link>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.trip?.TRIP_HISTORY}
                    </span>
                  </>
                ) : path === "/Tracking" ? (
                  <>
                    <Link to="/trips">
                      <span className="breadcrumbInactive breadcrumb-active span-font-roboto ">
                        {this.state.userLangs?.trip?.TRIPS_DETAILS}
                      </span>
                    </Link>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.tracking?.TRACKING}
                    </span>
                  </>
                ) : path === "/drivers" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.masters?.DRIVERS}
                    </span>
                  </>
                ) : path === "/vehicles" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.masters?.VEHICLES}
                    </span>
                  </>
                ) : path === "/vehicle-type" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.masters?.VEHICLE_TYPE}
                    </span>
                  </>
                ) : path === "/user" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.masters?.USERS}
                    </span>
                  </>
                ) : path === "/roles" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.masters?.ROLES}
                    </span>
                  </>
                ) : path === "/invoiceGeneration" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {/* Invoice Genarations */}
                      {this.state.userLangs?.invoice?.INVOICE_GENERATION}
                    </span>
                  </>
                ) : path === "/Billing/InvoiceList" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {/* Invoice Genarations */}
                      {this.state.userLangs?.invoice?.INVOICE_LIST}
                    </span>
                  </>
                ) : path === "/BillingIndex" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.invoice?.AGREEMENT}
                    </span>
                  </>
                ) : path === "/Billing/AddAgreement" ? (
                  <>
                    <Link to="/BillingIndex">
                      <span className="breadcrumbInactive breadcrumb-active span-font-roboto">
                        {this.state.userLangs?.invoice?.AGREEMENT}
                      </span>
                    </Link>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.invoice?.ADD_AGREEMENT}
                    </span>
                  </>
                ) : path === "/tripBills" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.invoice?.INVOICE_LISTS}
                    </span>
                  </>
                ) : path === "/Reports" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.report?.TRANSPORTER_PERFORMANCE}
                    </span>
                  </>
                ): path === "/ontime-delivery-report" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                    {this.state.userLangs?.report
                            ?.ON_TIME_DELIVERY_REPORT1
                            ?? "On Time Delivery Report  "}
                    </span>
                  </>
                ): path === "/vehicle-placement" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                    {this.state.userLangs?.report
                            ?.VEHICLE_PLACEMENT_REPORT
                            ?? "Vehicle Placement Report  "}
                    </span>
                  </>
                ) : path === "/indentReport-analysis" ? (
                  <>
                    <span className="span-font-roboto">
                      {this.state.userLangs?.report?.INDENT_ANALYSIS_REPORT}
                    </span>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.report?.LANE}
                    </span>
                  </>
                ) : path === "/statusIndent" ? (
                  <>
                    <span className="span-font-roboto">
                      {this.state.userLangs?.report?.INDENT_ANALYSIS_REPORT}
                    </span>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.common?.STATUS}
                    </span>
                  </>
                ) : path === "/transporterIndent" ? (
                  <>
                    <span className="span-font-roboto">
                      {this.state.userLangs?.report?.INDENT_ANALYSIS_REPORT}
                    </span>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.common?.TRANSPORTER}
                    </span>
                  </>
                ) : path === "/ControlTower" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.controlTower?.CONTROL_TOWER}
                    </span>
                  </>
                ) : path === "/profileSettings" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.common?.PROFILE_SETTINGS}
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {/* <div className="offlineMode">
              {navigator.onLine ? null : "Offline Mode"}
            </div> */}
            <div
              className="d-flex mt-0 pr-2"
              style={{
                padding: "24px 0px",
                alignItems: "center",
                marginTop: "0px important",
                paddingTop: "0px !important",
              }}
            >
              {restrictSync(PERMS.INDENT_PLANNER_READ) &&
              (roleCheck("Admin") ||
                roleCheck("indent_planner") ||
                roleCheck("loading_unloading")) ? (
                <>
                  <div className=" mt-0 pt-0 dropdown mobileSync d-lg-inline-block ml-2">
                    <Button
                      style={{
                        position: "relative",
                        height: "36px",
                      }}
                      type="button"
                      color="none"
                      onClick={this.toggleSynch}
                      className="desktopDisplay"
                    >
                      <span
                        style={{
                          display: "block",
                          marginTop: "-8px",
                        }}
                      >
                        <i
                          style={{
                            width: "10.11px",
                            marginTop: "0px",
                            height: "5.99px",
                            color: `${darkTheme ? "#FFFFFF" : "#000000"}`,
                            margin: "12px",
                            paddingBottom: "14px",
                          }}
                          className={`fas fa-sync ${rotate}`}
                        ></i>
                        <span
                          style={{
                            color: `${darkTheme ? "#FFFFFF" : "#343434"}`,
                          }}
                        >
                          {this.state.userLangs?.common?.ORACLE_SYNC}
                        </span>
                      </span>
                    </Button>
                    <Button
                      style={{
                        position: "relative",
                        height: "40px",
                        paddingRight: "10px",
                      }}
                      type="button"
                      color="none"
                      onClick={this.toggleSynch}
                      className="mobileDisplay HoverSync"
                    >
                      <span>
                        <i
                          style={{
                            // width: "10.11px",
                            // marginTop: "0px",
                            // height: "5.99px",
                            color: `${darkTheme ? "#FFFFFF" : "#000000"}`,
                            // margin: "12px",
                            // paddingBottom: "14px",
                          }}
                          className={`fas fa-sync ${rotate}`}
                        ></i>
                      </span>
                    </Button>
                  </div>
                </>
              ) : null}
              {/* <AppTheme /> */}
              <div className="dropdown d-none d-lg-inline-block ml-1">
                <Button
                  type="button"
                  color="none"
                  onClick={this.toggleFullscreen}
                  className="header-item noti-icon waves-effect"
                  data-toggle="fullscreen"
                >
                  <i
                    className="ri-fullscreen-line"
                    style={{ color: "black" }}
                  ></i>
                </Button>
              </div>

              <ProfileMenu darkThemeProfile={darkTheme} />
            </div>
          </div>
        </header>
        {/* </Card> */}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    switchsTheme: state.Layout.switchTheme,
  };
};
export default connect(mapStateToProps, { fetchSync, changeSwitchTheme })(
  HeaderNew
);
