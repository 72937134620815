import React from "react";

let data = JSON.parse(window.localStorage.getItem("authUser"));
let role = data?.data?.role?.label;
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  AdminHeaderData = {
    columns: [
      {
        label: userLang?.masters?.USERNAME,
        field: "userName",
        sort: "asc",
        width: 4,
      },
      {
        label: userLang?.masters?.CONTACT,
        field: "contact",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.masters?.ROLE,
        field: "role",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.masters?.EMAIL,
        field: "email",
        sort: "asc",
        width: 4,
      },
      {
        label: userLang?.masters?.HOP,
        field: "hop",
        sort: "asc",
        width: 2,
      },

      {
        label: userLang?.common?.STATUS,
        field: "status",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.common?.ACTIONS,
        field: "action",
        sort: "asc",
        width: 1,
      },
    ],
  };
  HeaderData = {
    columns: [
      {
        label: "User Name",
        field: "userName",
        sort: "asc",
        width: 4,
      },
      {
        label: "Contact",
        field: "contact",
        sort: "asc",
        width: 2,
      },
      {
        label: "Role",
        field: "role",
        sort: "asc",
        width: 2,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 4,
      },
      {
        label: "Hop",
        field: "hop",
        sort: "asc",
        width: 2,
      },

      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 1,
      },
    ],
  };
  HoplistHeaders= {
    columns: [
      {
        label: userLang?.masters?.HOP_NAME,
        field: "hopName",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.masters?.HOP_CODE,
        field: "hopCode",
        sort: "asc",
        width: 1,
      },

      {
        label: userLang?.common?.ACTIONS,
        field: "actions",
        sort: "asc",
        width: 1,
      },
    ]
  }
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var AdminHeaderData = {
  columns: [
    {
      label: userLang?.masters?.USERNAME,
      field: "userName",
      sort: "asc",
      width: 3,
    },
    {
      label: userLang?.masters?.CONTACT,
      field: "contact",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.masters?.ROLE,
      field: "role",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.masters?.EMAIL,
      field: "email",
      sort: "asc",
      width: 4,
    },
    {
      label: userLang?.masters?.HOP,
      field: "hop",
      sort: "asc",
      width: 2,
    },

    {
      label: userLang?.common?.STATUS,
      field: "status",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.common?.ACTIONS,
      field: "action",
      sort: "asc",
      width: 1,
    },
  ],
};
export var HeaderData = {
  columns: [
    {
      label: "User Name",
      field: "userName",
      sort: "asc",
      width: 2,
    },
    {
      label: "Contact",
      field: "contact",
      sort: "asc",
      width: 2,
    },
    {
      label: "Role",
      field: "role",
      sort: "asc",
      width: 2,
    },
    {
      label: "Email",
      field: "email",
      sort: "asc",
      width: 4,
    },
    {
      label: "Hop",
      field: "hop",
      sort: "asc",
      width: 2,
    },

    {
      label: "Status",
      field: "status",
      sort: "asc",
      width: 1,
    },
  ],
};
export var HoplistHeaders= {
  columns: [
    {
      label: userLang?.masters?.HOP_NAME,
      field: "hopName",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.masters?.HOP_CODE,
      field: "hopCode",
      sort: "asc",
      width: 1,
    },

    {
      label: userLang?.common?.ACTIONS,
      field: "actions",
      sort: "asc",
      width: 1,
    },
  ]
}
export const searchBarUser = {
  searchBarData: [
    {
      label: userLang?.masters?.HOP_NAME,
      field: "hopName",
       type:'text',
    },
    {
      label: userLang?.masters?.HOP_CODE,
      field: "hopCode",
      type:'text',
    },

    {
      label: userLang?.common?.ACTIONS,
      field: "actions",
      type:'text',
    },
  ]
}
export const searchBar = {
  searchBarData: [
    {
      label: "User Name",
      field: "userName",
      type: "text",
    },

    {
      label: "Contact",
      field: "contact",
      type: "text",
    },
    {
      label: "Role",
      field: "role",
      type: "text",
    },
    {
      label: "Email",
      field: "email",
      type: "text",
    },
    {
      label: "Hop",
      field: "hop",
      type: "text",
    },
    {
      label: "Status",
      field: "status",
      type: "select",
    },
    {
      label: "Action",
      field: "action",
      type: "text",
    },
  ],
};
