import React, { Component } from "react";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import {
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Button,
  Label,
  Card,
} from "reactstrap";
import { connect } from "react-redux";
import Select from "react-select";
import {
  HoplistHeaders,
  getLocalStorage,
  searchBarUser,
} from "./RowHeaders/RowHeaders";

import { fetchUserDetails } from "../../store/auth/register/actions";
import { fetchHops } from "../../store/hop/actions";
import { fetchRoles } from "../../store/accessPolicy/actions";
import { UpdateUser } from "../../store/auth/register/actions";
import { NotificationManager } from "react-notifications";
import { MDBDataTable } from "mdbreact";
import { color } from "@mui/system";
import Index from "../../components/Common/DataTableNeo/Index";

let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
var hopListing = {};
var darkMode;
class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: this.props.user._id ? this.props.user._id : "",
      name: "",
      phone: "",
      email: "",
      hopList: [],
      selectedHop: [],
      rolesList: [],
      selectedRoles: {},
      openModalHop: false,
      hopsDataList: [],
    };
  }
  componentDidMount() {
    this.getUserDetails();
    this.getHopsList();
  }
  getUserDetails = () => {
    this.props.fetchUserDetails(this.state.userId, (res) => {
      console.log("Res data",res)
      let roleResponse = [];
      let rows = [];

      if (res && res.message === "Fetched successfully!!") {
        let roleTransform = res?.data?.role ? res?.data?.role : {};
        roleResponse.push({
          label: roleTransform.label,
          value: roleTransform._id,
        });
        let iteratehops = res.data.hopDetails ? res.data.hopDetails : [];
        iteratehops.forEach((ele) => {
          if (ele.label && ele._id) {
            rows.push({
              hopName: ele.label ? ele.label : "",
              hopCode: (
                <div className="hopCodePaddingDiv">
                  {ele.hopCode ? ele.hopCode : ""}
                </div>
              ),
              actions: (
                <div
                  className="dropdown dropDownDelete"
                  onClick={() => {
                    this.deleteHop(ele.hopCode);
                  }}
                >
                  <div style={{ color: darkMode?"#B7A9FF":"red", 
                   cursor: "pointer" }}>
                    <i className="fas fa-trash-alt"></i>
                  </div>
                </div>
              ),
              value: ele._id,
            });
          }
        });
        this.setState({
          name: res?.data?.name ?? "",
          phone: res?.data?.phone ?? "",
          email: res?.data?.email ?? "",
          selectedRoles: roleResponse[0] ? roleResponse[0] : {},
          hopsDataList: rows,
        });
      }
    });
  };
  deleteHop = (hopCode) => {
    console.log(this.state.hopsDataList);
    const filterHopData = this.state.hopsDataList.filter(
      (ele) => ele.hopCode.props.children !== hopCode
    );
    this.setState({
      hopsDataList: filterHopData,
    });
  };
  // get location name by filter
  filtersHops=(e)=>{
    if (e?.length > 0) {
      let filtersHopsData ={
        offset:0,
        limit:10,
        q:e
      }
      this.fetchHops(filtersHopsData)
    
    }
    if(e.length === 0){
      let filtersHopsData ={
        offset:0,
        limit:10,
        q:""
      }
      this.fetchHops(filtersHopsData)
    }
  }
  fetchHops = (filtersHopsData)=>{
    this.props.fetchHops({ offset:filtersHopsData.offset, 
      limit:filtersHopsData.limit ,
      label:filtersHopsData.q}, (res) => {
      let hops = [];
      if (res.data?.docs?.length > 0) {
        res.data.docs.forEach((ele) => {
          hops.push({
            label: ele?.label ? ele?.label : "",
            value: ele?._id ? ele?._id : "",
          });
        });
      }
      this.setState(
        {
          hopList: hops,
        },
        () => { },
        ()=>{}
      );
    });
  }
  getHopsList = () => {
    let filtersHopsData ={
      offset:0,
      limit:10,
      q:""
    }
    this.fetchHops(filtersHopsData)
    let offset = 0;
    // let limit = 10;
    // this.props.fetchHops({ offset, limit,label:"" }, (res) => {
    //   let hops = [];
    //   if (res.data?.docs?.length > 0) {
    //     res.data.docs.forEach((ele) => {
    //       hops.push({
    //         label: ele?.label.replace(/_/gm, "") ? ele?.label : "",
    //         value: ele?._id ? ele?._id : "",
    //       });
    //     });
    //   }
    //   this.setState({
    //     hopList: hops,
    //   });
    // });
   

    this.props.fetchRoles(offset, (res) => {
      let roles = [];
      let selectedRoleCheck = [];
      if (res?.data?.length > 0) {
        res.data.forEach((ele) => {
          roles.push({
            label:
              ele.label.replace(/_/g, " ").charAt(0).toUpperCase() +
              ele.label.replace(/_/g, " ").slice(1),
            value: ele?._id ? ele?._id : "",
          });
          if (ele.label === this.state?.selectedRoles?.label) {
            selectedRoleCheck.push({
              label:
                ele.label.replace(/_/g, " ").charAt(0).toUpperCase() +
                ele.label.replace(/_/g, " ").slice(1),
              value: ele?._id ? ele?._id : "",
            });
            this.setState({
              selectedRoles: selectedRoleCheck[0] ? selectedRoleCheck[0] : {},
            });
          }
        });
        this.setState({
          rolesList: roles,
        });
      }
    });
  };
  UpdateUser = () => {
    const updateHop = this.state.selectedHop
      ? this.state.selectedHop.map((ele) => ele.value)
      : [];
    const deleteHop = this.state.hopsDataList
      ? this.state.hopsDataList.map((ele) => ele.value)
      : [];
    let payload = {
      role: this.state?.selectedRoles ? this.state?.selectedRoles.value : "",
      hops: [...updateHop, ...deleteHop],
    };
    this.props.UpdateUser(this.state.userId, payload, (res) => {
      if (res && res.message === "user has been successfully updated") {
        NotificationManager.success(res["message"], "User Updated!!");
        this.setState({
          selectedHop: [],
        });
        this.props.closeHopModal();
        this.props.getAlldata();

      } else {
        NotificationManager.error(res["message"], "Error");
      }
      // this.getUserDetails();
    });
  };

  resetInputValue = () => {
    this.setState({selectedHop:[]})
  }

  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    console.log("HoplistHeaders.columns", HoplistHeaders.columns);
    console.log("this.state.hopsDataList", this.state.hopsDataList);

    darkMode = this.props.mode;
    hopListing = {
      columns: HoplistHeaders,
      rows: this.state.hopsDataList,
    };
    const customStyles = {
      indicatorsContainer: (style) => {
        return {
          ...style,
          backgroundColor: "#8e9af8",
        };
      },
      input: base => ({
        ...base,
        color: darkMode?"#9d9d9d":"#505d69"
      }),
      indicatorSeparator: (style) => {
        return {
          ...style,
          backgroundColor: "#8e9af8",
        };
      },
      control: (style) => {
        return {
          ...style,
          height: "50px",
          backgroundColor:darkMode?"#2e2e46":"#ffffff"
        };
      },
      menu: (style) => {
        return {
          ...style,
          backgroundColor: darkMode ? "#171722" : "#ffffff",
          color: darkMode ? "#B7A9FF" : "rgb(51, 51, 51)",
          border: darkMode ? "0.5px solid #616161" : "0.5px solid rgb(204, 204, 204)",
          boxShadow: darkMode ? "0px 3px 12px rgba(0, 0, 0, 0.12)" : "0px 3px 12px rgba(0, 0, 0, 0.12)"
          // zIndex: "22px",
          // position: "",
        };
      },
    };
    return (
      <React.Fragment>
        {getLocalStorage()}
        <div className="Hops">
          <MDBContainer>
            <MDBModal
              isOpen={this.props.isOpen}
              toggle={this.props.closeHopModal}
              size="lg"
              centered
              // onClick={
              //   ()=>{
              //     this.props.closeHopModal();
              //     this.props.getAlldata();
              //   }
              // }
              className="EditUserModal modelDark"
            >
             
              <div style={{
                position:" relative",
                left: "14px",
                lineHeight: "28px"
              }}>
              <Row>
                  <Col xs="11">
                    <div>
                      <span className="fontRoboto light__span"
                      style={{
                        color:"#0C0C0C"
                      }}>
                        {userLang?.masters?.EDIT_USERS}
                      </span>
                    </div>
                  </Col>
                  <Col xs="1">
                    <span className=""
                     onClick={() => {
                      this.props.closeHopModal();
                      this.props.getAlldata();
                    }}>
                    <i 
                    // onClick={()=>{
                    //    this.props.closeHopModal();
                    //    this.props.getAlldata();
                    // }}
                    className="fas fa-times  closemodalUser closeDark"></i>
                    </span>
                    
                  </Col>
                  </Row>
                  <Row>
                  <Col xs={12} md={4} className="pr-1">
                    <div>
                      <span className="fontRoboto154 light__span">
                        {userLang?.masters?.NAME} :{" "}
                        <span className="dark__span">{this.state.name ? this.state.name : ""}</span>
                      </span>
                    </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <div>
                      <span className="fontRoboto154 light__span ">
                        {userLang?.masters?.ROLE} :{" "}
                        <span className="dark__span">
                        {this.state?.selectedRoles?.label
                          ? this.state?.selectedRoles?.label
                          : ""}
                        </span>
                       
                      </span>
                    </div>
                  </Col>
                  <Col xs={12} md={4}></Col>
                </Row>
              </div>
              {/* <MDBModalHeader>
                
                </MDBModalHeader> */}
              <MDBModalBody>
                <Row>
                  <Col xs={12} sm={4} md={4} className="mb-15">
                    <FormGroup>
                      <Label for="fullName" className="fontRoboto15 light__span">
                        {" "}
                        {userLang?.common?.FULL_NAME}
                      </Label>
                      <Input
                        id="fullName"
                        name="fullName"
                        placeholder="Type Full Name..."
                        type="text"
                        value={this.state.name ? this.state.name : ""}
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={4} md={4} className="mb-15">
                    <FormGroup>
                      <Label for="Phone" className="fontRoboto15 light__span">
                        {" "}
                        {userLang?.masters?.PHONE}
                      </Label>
                      <Input
                        id="Phone"
                        name="Phone"
                        type="email"
                        value={this.state.phone ? this.state.phone : ""}
                        disabled={true}
                        placeholder="Type Phone No..."
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={4} md={4} className="mb-15">
                    <FormGroup>
                      <Label for="email" className=" fontRoboto15 light__span">
                        {userLang?.masters?.EMAIL}
                      </Label>
                      <Input
                        id="email"
                        name="email"
                        type="text"
                        placeholder="Type Email.."
                        value={this.state.email ? this.state.email : ""}
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>

                  <Col xs={12} sm={4} md={4} className="mb-15 textRole">
                    <FormGroup>
                      <Label for="Phone" className=" fontRoboto15 light__span">
                        {userLang?.masters?.ROLE}
                      </Label>
                      <Select
                        value={
                          this.state.selectedRoles
                            ? this.state.selectedRoles
                            : {}
                        }
                        styles={customStyles}
                        isMulti={false}
                        onInputChange={""}
                        onChange={(e) => {
                          this.setState({
                            selectedRoles: e,
                          });
                        }}
                        options={this.state.rolesList}
                        classNamePrefix="select2-selection"
                        placeholder="select Roles..."
                      />
                    </FormGroup>
                  </Col>
                  <Col
                    xs={12}
                    sm={4}
                    md={4}
                    className="mb-15 hopInnerContent hopSelect"
                  >
                    <FormGroup>
                      <Label for="Phone" className=" fontRoboto15 light__span">
                        {userLang?.masters?.HOP}
                      </Label>
                      <Select
                        value={
                          this.state.selectedHop ? this.state.selectedHop : []
                        }
                        isMulti={true}
                        styles={customStyles}
                        onInputChange={(e)=>this.filtersHops(e)}
                        onChange={(e) => {
                          this.setState({
                            selectedHop: e,
                          });
                        }}
                        options={this.state.hopList}
                        classNamePrefix="select2-selection"
                        placeholder="select Hops..."
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}  md={2} className="mb-15">
                    <div className="userBtn">
                      <Button
                        style={{ width: "100%",height:"50px", backgroundColor:"#0AB38C" }}
                        type="button"
                        // color="primary"
                        className="small align-middle rounded mb-1 fontRoboto1541"
                        onClick={()=>{this.resetInputValue()}}
                       
                      >
                        <span style={{ fontWeight: "900" }}>
                          {userLang?.common?.RESET}
                        </span>
                      </Button>
                    </div>{" "}
                  </Col>
                  <Col xs={6}  md={2} className="mb-15">
                    <div className="userBtn">
                      <Button
                        style={{ width: "100%",height:"50px", backgroundColor:"#05284E" }}
                        type="button"
                        // color="primary"
                        className="small align-middle rounded mb-1 fontRoboto1541"
                        onClick={() => {
                          this.UpdateUser();
                        }}
                      >
                        <span style={{ fontWeight: "900" }}>
                          {userLang?.common?.SUBMIT}
                        </span>
                      </Button>
                    </div>{" "}
                  </Col>
                  <Col xs={12} sm={12} md={12} className="mb-15">
                    <Card className="UserCard">
                      <Index
                        {...this.props}
                        headers={HoplistHeaders}
                        data={this.state.hopsDataList}
                        searchBar={searchBarUser}
                        searchable={false}
                        // getAllData={this.getUserDetails}
                        rowHeight={"56px"}
                        limit={this.state.hopsDataList.length}
                      />
                    </Card>
                  </Col>
                </Row>
                {/* <Row>
                  <Col xs={12} sm={2} md={2} className="mb-15">
                    <Button
                      style={{
                        width: "100%",
                      }}
                      type="submit"
                      color="secondary"
                      className="small align-middle rounded rounded-black mb-1"
                      onClick={() => {
                        this.props.closeHopModal();
                        this.props.getAlldata();
                      }}
                    >
                      <span style={{ fontWeight: "900" }}>
                        {userLang?.common?.CLOSE}
                      </span>
                    </Button>{" "}
                  </Col>
                </Row> */}
              </MDBModalBody>
            </MDBModal>
          </MDBContainer>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    hopList: state.Location.locations,
  };
};

export default connect(mapStateToProps, {
  fetchUserDetails,
  fetchHops,
  fetchRoles,
  UpdateUser,
})(UserDetails);