import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { addVehicleTypesSpec } from "../../store/vehicleType/actions";
import { updateVehicleTypesSpec } from "../../store/vehicleType/actions";
import { fetchSIgnedUrlVehicleTypes } from "../../store/vehicleType/actions";
import { uploadVehicleType } from "../../store/vehicleType/actions";
import { NotificationManager } from "react-notifications";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { restrict } from "../restrict";
import { PERMS } from "../../enum/perms.enum";

var offset = 0;
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

const initialState = () => ({
  onSubmitDisable: false,
  form: {
    VehicleTypeCode: "",
    Label: "",
    Image: "",
    Height: "",
    Lengths: "",
    Width: "",
    Weight: "",
  },
  editId: "",
  errors: {},

  selectedFile: " ",
  fileFlag: false,
  urlArrayValues: " ",
  documentArray: "",
});

class VehicleTypeForm extends Component {
  constructor(props) {
    super(props);
    this.state = initialState();
    (async () => {
      let redirect = await restrict(PERMS.VEHICLE_TRANSPORTER_READ);
      if (redirect == false) {
        this.props.history.push("/operationsDashboard");
      }
    })();
    this.fromRef = React.createRef();
    this.fileInput = React.createRef();
  }

  componentDidMount = () => {
    if (this.props?.viewData) {
      this.formSet();
    }
  };
  componentWillUnmount = () => {
    this.reset();
  };

  handleSubmitChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let formData = this.state.form;
    formData[`${name}`] = value;
    this.setState({ form: formData }, () => {});
  };

  formSet = () => {
    let formDataEdit = {};
    let data = this.props?.viewData?.ele;

    if (this.props.viewData?.viewMode === "edit") {
      formDataEdit["VehicleTypeCode"] = data?.vehicleTypeCode;
      formDataEdit["Label"] = data?.label;
      formDataEdit["Image"] = data?.image;
      formDataEdit["Height"] = data?.specs?.height;
      formDataEdit["Lengths"] = data?.specs?.length;
      formDataEdit["Width"] = data?.specs?.width;
      formDataEdit["Weight"] = data?.specs?.weight;
    }

    this.setState({ form: formDataEdit, editId: data?._id });
    return formDataEdit;
  };
  reset = (cb) => {
    this.fileInput.current.value = "";
    this.setState(
      {
        form: {
          VehicleTypeCode: "",
          Image: "",
          Height: "",
          Lengths: "",
          Width: "",
          Label: "",
          Weight: "",
        },
        fileFlag: false,
        selectedFile: "",
        documentArray: "",
        urlArrayValues: "",
        errors: {},
      },
      () => {}
    );
  };

  documentAssigner = (value) => {
    this.setState({ documentArray: value }, () => {});
  };

  S3Upload = async () => {
    let softCopy = "";

    this.props.fetchSIgnedUrlVehicleTypes((res) => {
      softCopy = res.presignedS3Url.split("?")[0];
      this.setState(
        {
          urlArrayValues: this.state.urlArrayValues.concat(softCopy),
        },
        () => {
          // console.log("URL value log", this.state.urlArrayValues);

          if (this.state.urlArrayValues && this.state.selectedFile) {
            this.documentAssigner(this.state.urlArrayValues);
            const formData = new FormData();
            formData.append(
              this.state.selectedFile,
              this.state.selectedFile?.name
            );
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", this.state.selectedFile?.type);
            this.props.uploadVehicleType(
              res.presignedS3Url,
              this.state.selectedFile,
              myHeaders
            );
          }
        }
      );
    });
  };

  fileUploadTrigger = () => {
    this.fileInput.current.click();
  };

  fileUpload = (event) => {
    console.log(event.target.files);
    if (event.target.files.length) {
      this.setState(
        { selectedFile: event.target.files["0"], fileFlag: true },
        () => {
          this.S3Upload();
        }
      );
    }
  };
  removeFileselection = (index) => {
    this.fileInput.current.value = "";
    this.setState({
      fileFlag: false,
      selectedFile: "",
      documentArray: "",
      urlArrayValues: "",
    });
  };

  validateValAdd = () => {
    const errorsAdd = {};
    let { VehicleTypeCode, Label, Height, Lengths,Image, Width, Weight } =
      this.state.form;
    let image = this.state.urlArrayValues;

    if (!VehicleTypeCode) {
      errorsAdd.VehicleTypeCode = "Vehicle Type Code is required!";
    }
    if (!Label) {
      errorsAdd.Label = "Label is required!";
    }
    if (!Height) {
      errorsAdd.Height = "Height is required!";
    }
    if (!Lengths) {
      errorsAdd.Lengths = "Length is required!";
    }
    if (!Width) {
      errorsAdd.Width = "Width is required!";
    }
    if (!Weight) {
      errorsAdd.Weight = "Weight is required!";
    }
    if (this.state.fileFlag === false && !Image) {
      errorsAdd.Image = "Image is required!";
    }

    return errorsAdd;
  };

  editVehicle = (formObj) => {
    this.setState({ errors: this.validateValAdd() }, () => {
      if (Object.keys(this.state.errors).length === 0) {
        let Id = this.state.editId ? this.state.editId : "";
        this.props.updateVehicleTypesSpec(Id, formObj, (res) => {
          if (res.success) {
            NotificationManager.success(res["message"], "Success");
            this.props.handleSubmit(false);
            this.reset();
            this.props.history.push("/vehicle-type");
          } else {
            NotificationManager.error(res["message"], "Error");
            this.setState({ onSubmitDisable: false });
          }
        });
      } else {
        NotificationManager.error("Required All fields", "Error");
      }
    });
  };
  addVehicle = (formObj) => {
    this.setState({ errors: this.validateValAdd() }, () => {
      if (Object.keys(this.state.errors).length === 0) {
        this.props.addVehicleTypesSpec(formObj, (res) => {
          if (res.success) {
            NotificationManager.success(res["message"], "Success");
            this.props.handleSubmit(false);
            this.reset();
            this.props.history.push("/vehicle-type");
          } else {
            NotificationManager.error(res["message"], "Error");
            this.setState({ onSubmitDisable: false });
          }
        });
      } else {
        NotificationManager.error("Required All fields", "Error");
      }
    });
  };
  handleSubmitSave = (formValues) => {
    let { VehicleTypeCode, Label, Image, Height, Lengths, Width, Weight } =
      this.state.form;
    let imageVal = this.state.urlArrayValues;
    let formObj = {
      vehicleTypeCode: VehicleTypeCode,
      label: Label,
      image: imageVal?.length > 1 ? imageVal : Image,
      specs: {
        weight: Weight,
        length: Lengths,
        width: Width,
        height: Height,
      },
    };
    console.log(formObj, "obj");
    if (this.props.viewData?.viewMode === "edit") {
      this.editVehicle(formObj);
    } else {
      this.addVehicle(formObj);
    }
  };
  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    const { form, errors } = this.state;
    let { viewData } = this.props;
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    var darkMode = mode === "dark__mode" ? true : false;

    return (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <Row>
              <Formik
                md="12"
                initialValues={form}
                onSubmit={this.handleSubmitSave}
              >
                {(formik) => {
                  const { handleSubmit, touched, resetForm } = formik;

                  return (
                    <form
                      className="form text-capitalize vehicleTypeForm"
                      onSubmit={handleSubmit}
                      style={{
                        width: " 330px",
                        height: "380px",
                        marginTop: "-10px",
                      }}
                    >
                      <Row>
                        <Col lg="12">
                          <FormGroup className="select2-container">
                            <Label className="required-field labelStyle mt-2 light__span">
                              <span>Vehicle Type Code</span>
                            </Label>

                            <Input
                              type="text"
                              value={form.VehicleTypeCode}
                              onChange={(e) =>
                                this.handleSubmitChange({
                                  target: {
                                    name: "VehicleTypeCode",
                                    value: e.target.value,
                                  },
                                })
                              }
                              name="VehicleTypeCode"
                              id="VehicleTypeCode"
                              placeholder="Vehicle Type Code (MT)"
                              className="inputVtStyle"
                            />
                            {errors?.VehicleTypeCode && (
                              <div className="error">
                                {errors?.VehicleTypeCode}{" "}
                              </div>
                            )}
                          </FormGroup>

                          <FormGroup className="select2-container">
                            <Label
                              className={`required-field labelStyle mt-2 ${
                                this.props.mode ? "light__span" : ""
                              }`}
                            >
                              <span>Label</span>
                            </Label>

                            <Input
                              type="text"
                              value={form.Label}
                              onChange={(e) =>
                                this.handleSubmitChange({
                                  target: {
                                    name: "Label",
                                    value: e.target.value,
                                  },
                                })
                              }
                              name="Label"
                              id="Label"
                              placeholder="Label (MT)"
                              className="inputVtStyle"
                            />
                            {errors?.Label && (
                              <div className="error">{errors?.Label} </div>
                            )}
                          </FormGroup>

                          <FormGroup className="select2-container">
                            <Label
                              className={`required-field labelStyle mt-2 ${
                                this.props.mode ? "light__span" : ""
                              }`}
                            >
                              {" "}
                              <span>length</span>
                            </Label>
                            <Input
                              type="text"
                              value={form.Lengths}
                              maxLength="10"
                              onChange={(e) => {
                                this.handleSubmitChange({
                                  target: {
                                    name: "Lengths",
                                    value: e.target.value,
                                  },
                                });
                              }}
                              name="Lengths"
                              id="Lengths"
                              className="inputVtStyle"
                              placeholder="Length In inch"
                            />
                            {errors?.Lengths && (
                              <div className="error">{errors?.Lengths} </div>
                            )}
                          </FormGroup>

                          <FormGroup className="select2-container">
                            <Label
                              className={`required-field labelStyle mt-2 ${
                                this.props.mode ? "light__span" : ""
                              }`}
                            >
                              {" "}
                              <span>Width</span>
                            </Label>
                            <Input
                              type="text"
                              value={form.Width}
                              maxLength="10"
                              onChange={(e) => {
                                this.handleSubmitChange({
                                  target: {
                                    name: "Width",
                                    value: e.target.value,
                                  },
                                });
                              }}
                              name="Width"
                              id="Width"
                              placeholder="Width In inch"
                              className="inputVtStyle"
                            />
                            {errors?.Width && (
                              <div className="error">{errors?.Width} </div>
                            )}
                          </FormGroup>

                          <FormGroup className="select2-container">
                            <Label
                              className={`required-field labelStyle mt-2 ${
                                this.props.mode ? "light__span" : ""
                              }`}
                            >
                              {" "}
                              <span>Height</span>
                            </Label>
                            <Input
                              type="text"
                              value={form.Height}
                              maxLength="10"
                              onChange={(e) => {
                                this.handleSubmitChange({
                                  target: {
                                    name: "Height",
                                    value: e.target.value,
                                  },
                                });
                              }}
                              name="Height"
                              id="Height"
                              placeholder="Height In inch"
                              className="inputVtStyle"
                            />
                            {errors?.Height && (
                              <div className="error">{errors?.Height} </div>
                            )}
                          </FormGroup>

                          <FormGroup className="select2-container">
                            <Label
                              className={`required-field labelStyle mt-2 ${
                                this.props.mode ? "light__span" : ""
                              }`}
                            >
                              {" "}
                              <span>Weight</span>
                            </Label>
                            <Input
                              type="text"
                              value={form.Weight}
                              maxLength="10"
                              onChange={(e) => {
                                this.handleSubmitChange({
                                  target: {
                                    name: "Weight",
                                    value: e.target.value,
                                  },
                                });
                              }}
                              name="Weight"
                              id="Weight"
                              placeholder="Weight In kg"
                              className="inputVtStyle"
                            />
                            {errors?.Weight && (
                              <div className="error">{errors?.Weight} </div>
                            )}
                          </FormGroup>

                          <div className="d-flex">
                            <FormGroup className="select2-container required mt-1">
                              <input
                                type="file"
                                name="Image"
                                multiple={false}
                                style={{ display: "none" }}
                                ref={this.fileInput}
                                onChange={this.fileUpload}
                              />
                              <span
                                style={{
                                  cursor: "pointer",
                                  color: this.props.mode
                                    ? "#B7A9FF"
                                    : "#5664D2",
                                }}
                                onClick={this.fileUploadTrigger}
                              >
                                <svg
                                  className="pr-2"
                                  width="31"
                                  height="31"
                                  viewBox="0 0 26 26"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M22 0H4C1.79086 0 0 1.79086 0 4V22C0 24.2091 1.79086 26 4 26H22C24.2091 26 26 24.2091 26 22V4C26 1.79086 24.2091 0 22 0Z"
                                    fill={darkMode ? "#1b1c31" : "#F1F5F7"}
                                  />
                                  <path
                                    d="M22 0.25H4C1.92893 0.25 0.25 1.92893 0.25 4V22C0.25 24.0711 1.92893 25.75 4 25.75H22C24.0711 25.75 25.75 24.0711 25.75 22V4C25.75 1.92893 24.0711 0.25 22 0.25Z"
                                    stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                                    strokeWidth="0.5"
                                  />
                                  <path
                                    d="M19.4383 12.6798L13.085 19.0331C12.3067 19.8115 11.251 20.2487 10.1503 20.2487C9.04957 20.2487 7.99393 19.8115 7.21559 19.0331C6.43726 18.2548 6 17.1992 6 16.0984C6 14.9977 6.43726 13.9421 7.21559 13.1637L13.5689 6.8104C14.0878 6.29151 14.7916 6 15.5254 6C16.2592 6 16.963 6.29151 17.4819 6.8104C18.0008 7.32928 18.2923 8.03305 18.2923 8.76686C18.2923 9.50068 18.0008 10.2044 17.4819 10.7233L11.1216 17.0767C10.8622 17.3361 10.5103 17.4819 10.1434 17.4819C9.77647 17.4819 9.42459 17.3361 9.16515 17.0767C8.90571 16.8172 8.75995 16.4653 8.75995 16.0984C8.75995 15.7315 8.90571 15.3796 9.16515 15.1202L15.0346 9.25771"
                                    stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                {viewData?.viewMode === undefined ? (
                                  <span>Please Attached image"</span>
                                ) : (
                                  ""
                                )}
                                {errors?.Image && (
                                  <div className="error">{errors?.Image} </div>
                                )}
                              </span>
                              {this.state.fileFlag &&
                              this.state.selectedFile ? (
                                <div
                                  key={Math.random()}
                                  className="container"
                                  style={{ display: "flex" }}
                                >
                                  <div style={{ width: "30%" }}>
                                    <i className="fas fa-file-alt fa-2x m-1"></i>
                                    <span className="light__span">
                                      {this.state.selectedFile?.name}
                                    </span>
                                  </div>
                                  <div style={{ flexGrow: "1" }}>
                                    <i
                                      className="fas fa-times"
                                      style={{
                                        cursor: "pointer",
                                        color: "red",
                                      }}
                                      onClick={() => this.removeFileselection()}
                                    ></i>
                                  </div>
                                </div>
                              ) : null}
                              {viewData?.viewMode === "edit" ? (
                                <img
                                  src={this.state.form?.Image}
                                  alt="image"
                                  style={{ width: "103px", height: "61px" }}
                                ></img>
                              ) : (
                                " "
                              )}
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>

                      <Row
                        className="mt-2"
                        style={{ marginRight: "128px", float: "right" }}
                      >
                        <Col className="text-center mt-4">
                          <Button
                            type="submit"
                            color="primary"
                            className=" mr-2 btn-md button-wide-vType"
                          >
                            {viewData?.viewMode === "edit" ? (
                              <span className="light__span">UPDATE</span>
                            ) : (
                              userLang?.common?.SUBMIT
                            )}
                          </Button>

                          <Button
                            type="button"
                            color="secondary"
                            className="btn-md button-wide-vType"
                            onClick={() => {
                              this.reset(resetForm);
                            }}
                            style={{ backgroundColor: "#0ab38c" }}
                          >
                            {userLang?.common?.RESET}
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  );
                }}
              </Formik>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default connect(null, {
  uploadVehicleType,
  updateVehicleTypesSpec,
  addVehicleTypesSpec,
  fetchSIgnedUrlVehicleTypes,
})(VehicleTypeForm);
