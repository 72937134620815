import React, { Component } from "react";
import { Button, Container, Row, Col, Card, CardBody, Label } from "reactstrap";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import Select from "react-select";
import { restrict } from "../../../restrict";
import { PERMS } from "../../../../enum/perms.enum";
import Datetime from "react-datetime";
import moment from "moment";
import {
  fetchInvoices,
  acceptInvoice,
  approveInvoice,
  rejectInvoice,
  fetchInvoiceById,
} from "../../../../store/actions";
import InvoicePdf from "../InvoicePdf";
import { stateList } from "../../../../components/Common/StateList";
import { NotificationManager } from "react-notifications";
import Index from "../../../../components/Common/DataTableNeo/Index";
import {
  AdminHeaderData,
  searchBar,
  getLocalStorage,
} from "./RowHeaders/RowHeaders.js";
import QuickFilters from "./quckFilters";
import { actions } from "./actionButtons";
import {
  MDBDataTable,
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBInput,
} from "mdbreact";

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class InvoiceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      role: "",
      breadcrumbItems: [
        { title: "Invoice", link: "#" },
        { title: "Invoice Generation", link: "#" },
      ],
      radioFilter: "",
      searchValue: "",
      searchOn: false,
      FromDate: "",
      ToDate: "",
      isOpen: false,
      invoiceDet: {},
      transporter: "",
      stateFilter: "",
      stateValue: "",
      rejectionCommentModal: false,
      rejectionComment: "",
      rejectedInvoiceId: "",
      documentDownloadError: false,
      documentDownloadErrorId: "",
      dataLoading : false,
      rejectState:true
    };
    this.fromRef = React.createRef();
    this.toRef = React.createRef();
    this.table = React.createRef();

    (async () => {
      let redirect = await restrict(PERMS.INVOICE_READ);
      console.log(redirect, "redirect11");

      if (redirect == false) {
        this.props.history.push("/operationsDashboard");
      }
    })();
  }

  componentDidMount = () => {
    this.setState({dataLoading : true})
    let data = JSON.parse(window.localStorage.getItem("authUser"));
    let role = data.data.role.label;
    this.setState({ role: role });
    this.props.fetchInvoices({}, (response => {
      this.setState({dataLoading : false})
    }));
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }
  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };

  handleDuration = (e) => {
    let value = e.value;
    this.setState({ radioFilter: e }, () => {
      if (this.state.searchOn !== true) this.setState({ searchOn: true });
      this.getAllData();
    });
  };

  searchValueSetter = (e) => {
    this.setState({ searchValue: e.target.value, searchOn: true }, () => {
      if (this.state.searchKey !== "" && this.state.searchValue !== "")
        this.setState({ disabled: false });
      if (this.state.searchValue === "") {
        this.setState({ disabled: true });
      }
    });
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (this.state.disabled === false) {
        this.getAllData();
      }
    }
  };
  handleStateChange = (e) => {
    this.setState({ dataLoading : true} )
    console.log(e);
    this.setState(
      {
        radioFilter: e,
        searchOn: !this.state.searchOn,
        stateFilter: e,
        stateValue: e.value,
      },
      () => {
        // this.getAllData();
        if (this.state.stateValue && this.state.FromDate && this.state.ToDate) {
          this.props.fetchInvoices(
            {},
            (response) => { this.setState({ dataLoading : false })},
            this.state.FromDate,
            this.state.ToDate,
            this.state.stateValue
          );
        } else if (
          this.state.stateFilter?.value &&
          !this.state.FromDate &&
          !this.state.ToDate
        ) {
          this.props.fetchInvoices({}, (response) => { this.setState({ dataLoading : false })}, "", "", this.state.stateValue);
        } else if (
          !this.state.stateFilter?.value &&
          !this.state.FromDate &&
          !this.state.ToDate
        ) {
          this.props.fetchInvoices({}, (response) => { this.setState({ dataLoading : false })}, "", "", "");
        }
      }
    );
  };
 

  handleChange = (dateValues) => {
    this.setState({ dataLoading : true })
    console.log("date values", dateValues);
    this.setState(
      {
        FromDate: moment(dateValues[0]).format("MM-DD-YYYY"),
        ToDate: moment(dateValues[1]).format("MM-DD-YYYY"),
      },
      () => {
        this.props.fetchInvoices(
          {},
          (response) => { this.setState({ dataLoading : false })},
          this.state.FromDate,
          this.state.ToDate,
          this.state.stateValue
        );
      }
    );
  
  };

  handleChangeRow = (state) => {
    //console.log("state", state);
    // this.setState({ selectedRows: state.selectedRows });
  };

  closeInvoiceModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  invoiceModal = (r) => {
    let destination = r.destination;
    let id = r.id;
    let indentId = r.indentId;
    let invoice = r.invoice;
    let isOpen = r.isOpen;
    let select = r.select;
    let source = r.select;
    let totalCost = r.totalCost;
    let tripId = r.tripId;
    this.props.fetchInvoiceById(id, (res) => {
      console.log(res);
    });
    this.setState({
      invoiceDet: {
        destination,
        id,
        indentId,
        invoice,
        calculations: r.calculations,
        isOpen,
        select,
        source,
        totalCost,
        tripId,
      },
      transporter: r.transporter,
    });
    this.closeInvoiceModal();
  };
  handleSearch = () => {
    this.setState({ dataLoading : true })
    let { FromDate, ToDate, stateFilter, stateValue } = this.state;
    if (FromDate && ToDate) {
      this.props.fetchInvoices(
        {},
        (response) => { this.setState({ dataLoading : false })},
        moment(this.state.FromDate).format("l"),
        moment(this.state.ToDate).format("l"),
        this.state.stateValue
      );
    } else {
      this.props.fetchInvoices({},(response) => { this.setState({ dataLoading : false })}, "", "", this.state.stateValue);
    }
  };
  reset = () => {
    this.setState({dataLoading : true})
    // this.fromRef.current.state.inputValue = "";
    // this.toRef.current.state.inputValue = "";
    this.setState(
      {
        FromDate: "",
        ToDate: "",
        stateFilter: "",
        radioFilter: "",
        stateValue: "",
      },
      () => {
        offset = 0;
        let limit = 10;
        totCnt = 0;
        totPages = 0;
        fromVal = 0;
        toVal = 0;
        // this.props.fetchInvoices({});
        console.log(totCnt, totPages, fromVal, toVal, "pagination");
        this.props.fetchInvoices(
          { offset, limit },
          (response) => { this.setState({ dataLoading : false })},
          this.state.FromDate,
          this.state.ToDate,
          this.state.stateValue
        );
      }
    );
  };
  handleChangePage = (event, val) => {
    this.setState({dataLoading : true})
    let key;
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    console.log(val, offset);
    let limit = 10;

    this.props.fetchInvoices(
      { offset, limit },
      (response) => { this.setState({ dataLoading : false })},
      this.state.FromDate,
      this.state.ToDate,
      this.state.stateValue
    );
  };
  rejectionCommentToggle = () => {
    console.log("inside toggle");
    this.setState({ rejectionCommentModal: !this.state.rejectionCommentModal });
  };
  CommentSaver = (e) => {
    this.setState({ rejectionComment: e.target.value },()=>{
      if(this.state.rejectionComment.length>0){
        this.setState({rejectState:false})
      }else{
        this.setState({rejectState:true})
      }
    });
  };
  rejectedInvoiceAssigner = (id) => {
    this.setState({ rejectedInvoiceId: id });
  };
  rejectInvoice = () => {
    let data = {
      invoiceId: this.state.rejectedInvoiceId,
      status: "sent_for_revision",
      comment: this.state.rejectionComment,
    };
    this.setState({dataLoading:true})
    this.props.rejectInvoice(data, (res) => {
      NotificationManager.success(res["message"], "Success");
      this.setState({dataLoading:false})
      this.rejectionCommentToggle();
      window.location.reload();
    });
  };
  downloadDocuments = (e, id) => {
    console.log("document", e, id);
    let documents = e ? e : "";
    console.log("downloader function", documents);
    for (let i = 0; i < documents.length; i++) {
      fetch(documents[i])
        .then((res) => res.blob())
        .then((blob) => {
          var element = document.createElement("a");
          element.href = URL.createObjectURL(blob);
          element.download = "doc.pdf";
          element.click();
          this.setState({
            documentDownloadError: false,
            documentDownloadErrorId: "",
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState(
            {
              documentDownloadError: true,
              documentDownloadErrorId: id,
            },
            () => console.log("error set true")
          );
        });
    }
  };

  invoiceEdit = (e) => {
    this.props.history.push({
      pathname: "/InvoiceEdit",
      state: {
        invoiceId: e.invoiceID,
        id: e.id,
        transporter: e.transporter,
        calculations: e.calculations,
        bills: e.bills,
        state: e.state,
        date: moment(e.createdAt).format("DD-MMM-YYYY"),
        status: e.status,
      },
    });
  };

  invoiceAccept = async (e) => {
    this.setState({dataLoading : true})
    await this.props.approveInvoice(e.id, (res) => {
      NotificationManager.success(res["message"], "Success");
      this.props.fetchInvoices({}, () => {
        this.setState({dataLoading : false})
      });
      window.location.reload();
    });
  };

  invoiceReject = (e) => {
    this.rejectionCommentToggle();
    this.rejectedInvoiceAssigner(e.id);
  };

  invoiceAttached = (e, id) => {
    this.downloadDocuments(e, id);
  };

  routingTOInvoiceDetails = (e) => {
    this.invoiceModal(e);
  };
  handleResetComment = ()=>{
    this.setState({rejectionComment:""},()=>{
      this.setState({rejectState:true})
    })
  }
  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    const localStorageData = JSON.parse(localStorage.getItem("invoiceData"));
    const { invoices } = this.props;
    const invoiceData = navigator.onLine ? invoices : localStorageData?.data;
    const { stateFilter, FromDate, ToDate } = this.state;
    let Invoices = []
    if(Array.isArray(invoiceData?.docs)){
      offset = invoiceData?.offset;
      totCnt = invoiceData?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = invoiceData?.offset + invoiceData?.docs?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      invoiceData.docs.forEach((e) => {
        Invoices.push({
            transporterName: e.transporter ? e.transporter.name : "-----",
            calculations: e.calculations || {},
            id: e._id,
            invoiceID: e.invoiceId,
            state: e.state.stateCode ? e.state.stateCode : "---",
            date: moment(e.createdAt).format("DD-MMM-YYYY"),
            status: e.status.replace(/_/g, " "),
            _status: e.status,
            actions: "",
            bills: e.bills,
            transporter: e.transporter ? e.transporter : "",
            comments: e.comment,
            total: e.calculations ? (
              e.calculations.netPayble < 0 ? (
                <p style={{ color: "red" }}>{e.calculations.netPayble}</p>
              ) : (
                <p>{e.calculations.netPayble}</p>
              )
            ) : (
              ""
            ),
            download: e.documents ? e.documents : "---",
            downloads: e.documents.length ? (
              <div>
                {" "}
                <div>
                  <i
                    onClick={() => {
                      this.downloadDocuments(e);
                    }}
                    className="fas fa-file-download fa-2x"
                    style={{ cursor: "pointer" }}
                  ></i>
                </div>
                {e._id === this.state.documentDownloadErrorId ? (
                  <div style={{ color: "red" }}>Error Downloading</div>
                ) : null}
              </div>
            ) : (
              "N/A"
            ),
        })
      })
    }
   
    let UserData = JSON.parse(window.localStorage.getItem("authUser"));
    let permissions = UserData.data.permissions;

    Invoices.forEach((e) => {
      actions(e, permissions, PERMS, this.state.documentDownloadErrorId, {
        routingTOInvoiceDetails: this.routingTOInvoiceDetails,
        invoiceEdit: this.invoiceEdit,
        invoiceAccept: this.invoiceAccept,
        invoiceReject: this.invoiceReject,
        invoiceAttached: this.invoiceAttached,
      });
    });
    let InvoiceList = Invoices;

    let data = {
      columns: [
        {
          label: <b>{userLang?.invoice?.INVOICE_ID}</b>,
          field: "invoiceID",
          sort: "asc",
          width: 300,
        },
        {
          label: <b>{userLang?.common?.TRANSPORTER}</b>,
          field: "transporterName",
          sort: "asc",
          width: 100,
        },
        {
          label: <b>{userLang?.invoice?.DATE}</b>,
          field: "date",
          sort: "asc",
          width: 100,
        },

        {
          label: <b>{userLang?.invoice?.STATE}</b>,
          field: "state",
          sort: "asc",
          width: 300,
        },
        {
          label: <b>{userLang?.invoice?.VIEW_DETAILS}</b>,
          field: "viewDetails",
          sort: "asc",
          width: 300,
        },
        {
          label: <b>{userLang?.common?.STATUS}</b>,
          field: "status",
          sort: "asc",
          width: 300,
        },
        {
          label: <b>{userLang?.invoice?.TOTAL_COST}</b>,
          field: "total",
          sort: "asc",
          width: 300,
        },
        {
          label: userLang?.common?.ACTIONS,
          field: "actions",
          sort: "asc",
          width: 100,
        },
        {
          label: userLang?.invoice?.DOWNLOAD_DOCUMENTS,
          field: "download",
          sort: "asc",
          width: 100,
        },
        {
          label: userLang?.invoice?.COMMENT,
          field: "comments",
          sort: "asc",
          width: 100,
        },
      ],
      rows: InvoiceList,
    };
    if (this.state.role === "transporter") {
      data.columns.splice(1, 1);
    }
    let finalData = {
      columns: [...data.columns],
      rows: [
        ...data.rows.map((row, order) => ({
          ...row,
        })),
      ],
    };
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;
    const customStyles = {
      indicatorsContainer: (style) => {
        return {
          ...style,
          backgroundColor: "#5664D2",
          width: "54px",
          textAlign: "center",
          justifyContent: "center",
          borderRadius: "0px 4px 4px 0px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          backgroundColor: "#5664D2",
        };
      },
      control: (style) => {
        return {
          ...style,
          width: "241px",
          height: "37px",
          backgroundColor: "#F1F5F7",
          borderTop: "0px",
          borderBottom: "0px",
          borderRight: "0px",
        };
      },
    };
    const inputProps = { placeholder: "Select" };
    let mode = localStorage.getItem('Theme')?localStorage.getItem('Theme'):null
    var darkMode = ((mode === "dark__mode") ? true : false);
    return (
      <>
        {getLocalStorage()}
        <MDBModal
          isOpen={this.state.rejectionCommentModal}
          toggle={this.rejectionCommentToggle}
          centered
        >
          <MDBModalHeader toggle={this.rejectionCommentToggle}>
            <span style={{ color: "red" }}>
              {userLang?.invoice?.REJECTION_COMMENT}
            </span>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBInput
              className="required darkTexBox"
              type="textarea"
              value={this.state.rejectionComment}
              rows="5"
              onChange={this.CommentSaver}
            />
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="warning" onClick={this.handleResetComment}>
              {userLang?.common?.RESET}
            </MDBBtn>
            <MDBBtn color="danger" onClick={this.rejectInvoice} disabled={this.state.rejectState}>
              {userLang?.invoice?.CONFIRM_REJECTION}
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        
          <InvoicePdf
            isOpen={this.state.isOpen}
            closePdf={this.closeInvoiceModal}
          ></InvoicePdf>
          {/* <Container fluid> */}
            <div>
              <QuickFilters
                name="stateFilter"
                handleStateChange={this.handleStateChange}
                stateListOption={stateList}
                stateFilter={stateFilter}
                handleChange={this.handleChange}
                reset={this.reset}
                hopIcon={<svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
                  <path d="M12.455 6.7275C12.455 11.1825 6.727 15.0005 6.727 15.0005C6.727 15.0005 1 11.1825 1 6.7275C1 5.20847 1.60343 3.75166 2.67755 2.67755C3.75166 1.60343 5.20847 1 6.7275 1C8.24653 1 9.70334 1.60343 10.7775 2.67755C11.8516 3.75166 12.455 5.20847 12.455 6.7275V6.7275Z" stroke="#2800FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M7 9.00049C8.10457 9.00049 9 8.10506 9 7.00049C9 5.89592 8.10457 5.00049 7 5.00049C5.89543 5.00049 5 5.89592 5 7.00049C5 8.10506 5.89543 9.00049 7 9.00049Z" stroke="#2800FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>}
                 mode={darkMode}
              />
            {/* </div> */}

            {/* <Row>
              <Col xs={12}> */}
                <Container fluid style={{ marginTop: "-8px" ,
                   backgroundColor:darkMode ? "#1B1C31":""}}>
                  
                    {userType &&
                      (userType != "admin" || userType != "Admin") ? (
                      <Index
                        {...this.props}
                        headers={AdminHeaderData}
                        data={InvoiceList}
                        searchBar={searchBar}
                        fetchId={this.props.fetchId}
                        // getAllData={this.getAllData}
                        // dataLength={drivers?.drivers?.data?.length}
                        rowHeight={"56px"}
                        dataLoading = {this.state.dataLoading}
                      />
                    ) : (
                      <Index
                        {...this.props}
                        headers={AdminHeaderData}
                        data={InvoiceList}
                        searchBar={searchBar}
                        fetchId={this.props.fetchId}
                        // getAllData={this.getAllData}
                        // dataLength={drivers?.drivers?.data?.length}
                        rowHeight={"56px"}
                        dataLoading = {this.state.dataLoading}
                      />
                    )}
                
                  {totCnt !== 0   && !this.state.dataLoading ? (
                    <Row
                      xs={12}
                      style={{color: darkMode ? "#9D9D9D":"#0C0C0C"}}
                      className="mb-2 paginationRow"
                    >
                      <Col xs={7} sm={5} md={4} className="span-col-pagenation">
                        <div className="float-left">
                          <Pagination
                            className="desktop-pagination"
                            size="medium"
                            shape="rounded"
                            page={offset / 10 + 1}
                            count={totPages}
                            color="primary"
                            onChange={this.handleChangePage}
                            defaultPage={1}
                            siblingCount={1}
                          />
                          <Pagination
                            className="mobile-pagination"
                            size="small"
                            shape="rounded"
                            page={offset / 10 + 1}
                            count={totPages}
                            color="primary"
                            onChange={this.handleChangePage}
                            defaultPage={1}
                            siblingCount={1}
                          />
                        </div>
                      </Col>
                      <Col
                        className="mt-2 spanPagenationentries span-col-pagenation"
                        xs={5}
                        sm={7}
                        md={8}
                      >
                        <span>
                          Showing {fromVal} to {toVal} of {totCnt} entries
                        </span>
                      </Col>
                    </Row>
                  ) : null}
                </Container>
              {/* </Col>
            </Row> */}
          {/* </Container> */}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  invoices: state.Billing.billing.invoices,
  fetchId: state.Billing.billing.invoices.fetchId,
});

export default connect(mapStateToProps, {
  fetchInvoices,
  acceptInvoice,
  approveInvoice,
  rejectInvoice,
  fetchInvoiceById,
})(InvoiceList);
