import React, { Component } from "react";
import { Row, Col, Card, Button } from "reactstrap";
import plusIcon from "./assets/plus.png";
import plusDarkIcon from "./assets/plusDark.png";
import phoneIcon from "./assets/phone.png";
import phoneDark from "./assets/phoneDrk.png";
import userIcon from "./assets/user.png";
import UserIconDark from "./assets/userDark.png";
import { InputGroup, Input } from "reactstrap";
import "./driverTheme.scss";
import refreshIcon from "./assets/refresh-cw.png";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class QuickFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userLangs: undefined,
    };
  }
  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (this.state.userLangs !== userLang || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }
  render() {
    console.log("testing reset",this.state.userLangs?.common?.RESET)
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let userPreferredLang = window.localStorage.getItem("userPreferredLang");
    let darkMode = this.props?.mode
    return (
      <React.Fragment>
        <Card className="darkBgQuickFilter">
          <div className="quickStylesBar driverStyle darkBgQuickFilter">
            <>
              <div className='d-flex justify-content-sm-end '>
                <Row>
                  <Col xs={12} md={4} lg={2} className="mobileDisplay">
                    <div className="d-flex justify-content-end">
                    <div>
                  <Button
                  style={{
                    minWidth:"70%"
                  }}
                        className="mobile-reset reset"
                        onClick={() =>
                          this.setState({ dateValue: [null, null] }, () => {
                            this.props.reset();
                          })
                        }
                      >
                        <img className="mobile-rest-img"
                          src={refreshIcon}
                        ></img>
                      </Button>
                      </div>
                      <div className="">
                  <div className="tooltipIcon">
                    <Button
                      className="iconButtons"
                      style={{
                        marginRight:"0px",
                      }}
                      onClick={() => this.props?.addDriverFunc()}
                    >
                      <img
                        style={{ margin: "-4px" }}
                        src={plusIcon}
                        alt="exp"
                      />
                    </Button>
                    <span className="tooltipIcontext" style={{ width: "80px" }}>
                      &nbsp; {this.state.userLangs?.masters?.ADD_DRIVER}&nbsp;
                    </span>
                  </div>
                </div>
                </div>
                  </Col>
                  <Col xs={12} md={3} lg={4}>
                  <div className=''>
                  <div className='input-border mt-2'>
                    <InputGroup>
                      <Input
                    
                        className='form-control'
                        type='text'
                        name='name'
                        value={this.props.name}
                        onChange={(e) => this.props.handleDriverName(e)}
                        onKeyDown={(event) => this.props?.enterCheck(event)}
                        placeholder="Search By Name..."
                        aria-label="Search"
                        icon="fas fa-times-circle"
                      />
                      <span className="pt-2 pb-2 input-line lineDark  pl-1 ml-1"></span>
                      <span
                        className="span-img"
                        onClick={() => this.props?.filterList()}
                      >
                        <img className="" src={darkMode?UserIconDark:userIcon}></img>
                      </span>
                    </InputGroup>
                  </div>
                </div>
                  </Col>
                  <Col xs={12} md={3} lg={4}>
                  <div className=''>
                  <div className='input-border mt-2'>
                    <InputGroup>
                      <Input
                        className="form-control "
                        type="text"
                        name="phoneNo"
                        value={this.props.phoneNo}
                        onChange={(e) => this.props.handleDriverPhone(e)}
                        onKeyDown={(event) => this.props?.enterCheck(event)}
                        maxLength={12}
                        pattern="[+-]?\d+(?:[.,]\d+)?"
                        // onKeyUp={(event) => {
                        //   if (
                        //     this.props.phoneNo === '' &&
                        //     (event.keyCode === 8 || event.keyCode === 46)
                        //   ) {
                        //     this.props.resetPhone()
                        //   }
                        // }}
                        placeholder="Search By Phone No..."
                        aria-label="Search"
                        icon="fas fa-times-circle"
                      />
                      <span className="pt-2 pb-2 input-line lineDark  pl-1 ml-1"></span>
                      <span
                        className="span-img"
                        onClick={() => this.props?.filterList()}
                      >
                        <img className="pl-1" src={darkMode?phoneDark:phoneIcon}></img>
                      </span>
                    </InputGroup>
                  </div>
                </div>
                  </Col>
                  <Col xs={2} md={2} lg={2} className="driverMobileCol desktopDisplay">
                    <div className='d-flex flex-row'>
                    <div className=''>
                  {' '}
                  <div>
                    <Button
                      onClick={() => this.props?.reset()}
                      className="reset"
                    >
                      {userPreferredLang === "EN" ? "Reset" :"रीसेट"}
                    </Button>
                  </div>
                </div>
                <div className="">
                  <div className="tooltipIcon">
                    <Button
                      className={`iconButtons ${darkMode ? 'iconDarkButtons' : ''}`}
                      onClick={() => this.props?.addDriverFunc()}
                    >
                      <img
                        style={{ margin: "-4px" }}
                        src={darkMode?plusDarkIcon:plusIcon}
                        alt="exp"
                      />
                    </Button>
                    <span className="tooltipIcontext" style={{ width: "80px" }}>
                      &nbsp; {this.state.userLangs?.masters?.ADD_DRIVER}&nbsp;
                    </span>
                  </div>
                </div>
                    </div>
                
                  </Col>
                  {/* <Col xs={2} md={1} lg={1}>
                 
                  </Col> */}
                </Row>
              
              
               
                
              </div>
            </>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default QuickFilters;
