import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Label,
  CardBody,
  Container,
  Button,
  CardTitle,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import { fetchOrder } from "../../store/order/actions";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { connect } from "react-redux";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { MoonLoader } from "react-spinners";
import formatDate from "../DateFormatter";
import moment from "moment";
import Eta from "../../helpers/ETA";
import MapsGoogle from "../Maps/TrackTripMap";
import { trackTripById } from "../../store/trip/actions";
import { Typography } from "@material-ui/core";

var statusArr = [];
var deliveredDate = "";
var EstimatedTime = "";
var showest = true;
var weekday = new Array(
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
);
var MonthArray = new Array(
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
);
var deldate = "";
var delMonth = "";
var delYear = "";
var delDay = "";
var estdate = "";
var estMonth = "";
var estYear = "";
var estDay = "";
let isDelivered = false;
let confirmDate = "";
let packedDate = "";
let dispatchdate = "";
let deliverydt = "";
let dateArray = [];
let tripArr = [];
let EstimatedDate = "";
let estimateDateRange = "";
const updateStatusArr = (orderData) => {
  let dt = "";

  if (orderData.EstimatedTime) {
    // console.log(orderData.EstimatedTime,"orderData.EstimatedTime")
    let datee = new Date(orderData.EstimatedTime);
    estdate = datee.getDate();
    estMonth = MonthArray[datee.getMonth()];
    estYear = datee.getFullYear();
    estDay = weekday[datee.getDay()];
  }
  // console.log(estdate,"datef",estMonth,estYear,estDay)
  const dateForm = (dt) => {
    let val = new Date(dt);
    let time = formatTime(val);
    let date =
      val.getDate() +
      " " +
      MonthArray[val.getMonth()] +
      " " +
      val.getFullYear() +
      " " +
      time;
    //  console.log(val.getFullYear(),"getFullyear",MonthArray[val.getMonth()],val.getDate(),time,date);

    return date;
  };
  const formatTime = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };
  deliveredDate = orderData.systemDeliveredAt
    ? dateForm(orderData.systemDeliveredAt)
    : orderData.customerDeliveredAt
    ? dateForm(orderData.customerDeliveredAt)
    : "";
  EstimatedTime = orderData.EstimatedTime
    ? moment(orderData.EstimatedTime).format("DD-MM-YYYY")
    : "";
  EstimatedDate = orderData.EstimatedTime
    ? moment(orderData.EstimatedTime).format(" Do MMMM YYYY")
    : "";

  let tripOrders =
    orderData.tripDetails && orderData.tripDetails.length > 0
      ? orderData.tripDetails.filter((ele) => {
          if (ele.tripStatus !== "Cancelled") return ele;
        })
      : [];
  if (tripOrders.length > 0) {
    // console.log(orderData,"oaisio")
    tripArr = [];
    let firstTrip = tripOrders.shift();

    let keyForDate = "createdAt";

    if (/true/i.test(process.env.REACT_APP_Kurlon)) {
      if (
        orderData.hasOwnProperty("ExternalOrderDate") &&
        orderData.ExternalOrderDate
      )
        keyForDate = "ExternalOrderDate";
    }

    confirmDate = dateArray.push(dateForm(orderData[keyForDate]));

    if (firstTrip.tripType === "Material Transfer") {
      isDelivered = false;

      if (firstTrip.tripStatus === "Assigned") {
        if (firstTrip.createdAt !== null) {
          // tripArr.push({
          //   name: "Order has been packed",
          //   date: dateForm(firstTrip.createdAt),
          // });
        }
      } else if (firstTrip.tripStatus === "In Transit") {
        if (firstTrip.startedAt !== null) {
          tripArr.push(
            // {
            //   name: "Order has been packed",
            //   date: dateForm(firstTrip.createdAt),
            // },
            {
              name:
                "Order has been dispatched from " +
                firstTrip.startingLocation.name,
              date: dateForm(firstTrip.startedAt),
            }
          );
        }
      } else if (firstTrip.tripStatus === "Completed") {
        tripArr.push(
          // {
          //   name: "Order has been packed",
          //   date: dateForm(firstTrip.createdAt),
          // },
          {
            name:
              "Order has been dispatched from " +
              firstTrip.startingLocation.name,
            date: dateForm(firstTrip.startedAt),
          }
        );
        if (firstTrip.completedAt !== null) {
          tripArr.push({
            name: "Order Arrived at " + firstTrip.destination.name,
            date: dateForm(firstTrip.completedAt),
          });
        }
      }
    } else if (firstTrip.tripType === "Last Mile") {
      if (firstTrip.tripStatus === "Completed") {
        isDelivered = true;
        showest = false;
      }

      if (firstTrip.tripStatus === "Assigned" || "In Transit") {
        // tripArr.push({
        //   name: "Order has been packed",
        //   date: dateForm(orderData.createdAt),
        // });

        if (firstTrip.startedAt !== null) {
          tripArr.push({
            name: "Order Out for Delivery from Warehouse",
            date: dateForm(firstTrip.startedAt),
          });
        }
      }

      // if(firstTrip.tripStatus ==="Completed"){
      //         tripArr.push({"name":"Order has been packed","date":dateForm(firstTrip.createdAt)},
      //         {"name":"Order has been Arrivedat "+firstTrip.startingLocation.name,"date":dateForm(firstTrip.startedAt)})
      //         if(firstTrip.updatedAt!==null){

      //         tripArr.push({"name":"Order Dispatched from "+firstTrip.startingLocation.name,"date":dateForm(firstTrip.updatedAt)})

      //       }
      // }
    }
    if (tripOrders.length > 0) {
      tripOrders.forEach((ele, i) => {
        if (ele.tripType === "Material Transfer") {
          isDelivered = false;

          if (ele.tripStatus === "In Transit") {
            tripArr.push({
              name: "Order has left from " + ele.startingLocation.name,
              date: dateForm(ele.startedAt),
            });
            //          if(ele.startedAt!==null){

            //  tripArr.push({"name":"Order has been dispatched  from "+ele.startingLocation.name,"date":dateForm(ele.startedAt)});
            //          }
          }

          if (ele.tripStatus === "Completed") {
            if (ele.completedAt !== null) {
              tripArr.push(
                {
                  name: "Order has left from " + ele.startingLocation.name,
                  date: dateForm(ele.startedAt),
                },
                {
                  name: "Order Arrived at " + ele.destination.name,
                  date: dateForm(ele.completedAt),
                }
              );
            }
          }
        } else if (ele.tripType === "Last Mile") {
          if (ele.tripStatus === "Completed") {
            isDelivered = true;
            showest = false;
          }

          if (ele.tripStatus === "In Transit") {
            // if(ele.createdAt!==null){
            //  tripArr.push(
            //  {"name":"Order has been Arrived at "+ele.startingLocation.name,
            //  "date":dateForm(ele.createdAt)})
            // }

            tripArr.push({
              name: "Order Out for Delivery from Warehouse",
              date: dateForm(ele.startedAt),
            });
          }

          if (ele.tripStatus === "Completed") {
            tripArr.push({
              name: "Order Out for Delivery from Warehouse",
              date: dateForm(ele.startedAt),
            });
          }
        }
      });
    }
  }
  let datef = "";
  if (deliveredDate !== null && deliveredDate !== "") {
    datef = new Date(deliveredDate);
    deldate = datef.getDate();
    delMonth = MonthArray[datef.getMonth()];
    delYear = datef.getFullYear();
    delDay = weekday[datef.getDay()];
  }
  // console.log("statusArr",deldate,delDay,delYear,delMonth,deliveredDate,datef)
};

const calculateEstimateDateRange = (orderData) => {
  const startDate = moment(
    orderData.ExternalOrderDate
      ? orderData.ExternalOrderDate
      : orderData.createdAt
  );
  const endDate = moment(orderData.EstimatedTime);
  let result = endDate.diff(startDate, "days");

  if (result !== 0) {
    let finalDate = setDate(orderData.EstimatedTime, result);
    if (result < 4) {
      let day = weekday[finalDate.getDay()];
      day === "Sunday" ? (result = 1 + 1) : (result = 1);

      finalDate = setDate(orderData.EstimatedTime, result);
      estimateDateRange = renderDateRange(finalDate, orderData.EstimatedTime);
    } else {
      let daysDiff = Math.round(Math.ceil(result * 35) / 100);
      let finalDate = setDate(orderData.EstimatedTime, daysDiff);
      let day = weekday[finalDate.getDay()];
      if (day === "Sunday") {
        daysDiff = daysDiff + 1;
      }
      finalDate = setDate(orderData.EstimatedTime, daysDiff);
      estimateDateRange = renderDateRange(finalDate, orderData.EstimatedTime);
    }
  }
};

const setDate = (date, count) => {
  let finalDate = new Date(date);
  finalDate.setDate(finalDate.getDate() - count);
  return finalDate;
};

const renderDateRange = (fromDate, toDate) => {
  const options = { year: "numeric", month: "short", day: "numeric" };
  return `${new Date(fromDate).toLocaleDateString(
    "EN-CA",
    options
  )} -- ${new Date(toDate).toLocaleDateString("EN-CA", options)}`;
};

const lists = (orderData) => {
  statusArr = [];
  dateArray = [];
  tripArr = [];
  updateStatusArr(orderData);
  calculateEstimateDateRange(orderData);
  statusArr = [...tripArr];
  return (
    <span>
      {orderData.systemDeliveredAt !== null ||
      orderData.customerDeliveredAt !== null ? (
        <Card>
          {/* <CardTitle>
            <p>Deliverd on</p>
            <h4>{delDay}</h4>
            <h4>{delMonth}</h4>
          </CardTitle> */}
          <CardBody style={{ padding: "0px" }}>
            {/* <span>
              <p style={{ fontWeight: "bolder" }}>
                <span
                  style={{
                    fontSize: "50pt",
                    color: "#2960db",
                    marginRight: "1rem",
                  }}
                >
                  {deldate}
                </span>
                <span>{delYear}</span>
              </p>
            </span> */}
            <span>{"Status :"}</span>
            <h3>
              <i
                className="fa fa-check"
                style={{
                  border: "1px solid #24c778",
                  borderRadius: "50%",
                  color: "#24c778",
                  padding: "0.5rem",
                }}
              ></i>
              {"DELIVERED"}
            </h3>
          </CardBody>
        </Card>
      ) : (
        <Card>
          <CardTitle>
            <Typography color="textSecondary" variant="body1" gutterBottom>
              Estimated Delivery :
            </Typography>
            <Typography color="primary" variant="h6" gutterBottom>
              {estimateDateRange}
            </Typography>
            {/* <h4>{estDay}</h4>
            <h4>{estMonth}</h4> */}
          </CardTitle>
          {/* <CardBody style={{ padding: "0px" }}>
            <span>
              <p style={{ fontWeight: "bolder", marginBottom: "0px" }}>
                <span
                  style={{
                    fontSize: "100pt",
                    color: "#2960db",
                    marginRight: "1rem",
                  }}
                >
                  {estdate}
                </span>
                <span>{estYear}</span>
              </p>
            </span>
            <span>{"Status :"}</span>
            <h3>
              <i
                className="fa fa-check"
                style={{
                  border: "1px solid #24c778",
                  borderRadius: "50%",
                  color: "#24c778",
                  padding: "0.5rem",
                }}
              ></i>
              {"IN PROCESS"}
            </h3>
          </CardBody> */}
        </Card>
      )}

      <ul className="list-unstyled activity-wid">
        <Row>
          <Col xs="4" md="3">
            {dateArray[0]}
          </Col>
          <Col xs="8" md="9">
            <li
              className="activity-list"
              style={{ borderLeft: "3px solid green", height: "15vh" }}
            >
              <div
                className="activity-icon avatar-xs"
                style={{
                  color: "#fff",
                  backgroundColor: "#1eb51e",
                  borderColor: "#1eb51e",
                }}
              >
                <span
                  className="avatar-title bg-soft-primary rounded-circle"
                  style={{ color: "#428c4b" }}
                >
                  <i className="ri-user-2-fill"></i>
                </span>
              </div>
              <div>
                <div>
                  <p className="text-muted mb-2">Order Confirmed...</p>

                  <p className="text-muted mb-0">
                    Thank you for choosing KurlOn
                  </p>
                </div>
              </div>
            </li>
          </Col>
        </Row>
        {statusArr.map((ele, index) => {
          // console.log((statusArr.length==index+1 && statusArr.length>2),"statusArr",statusArr)
          // console.log(isDelivered,"isDelivered")
          return (
            <Row key={index}>
              <Col xs="4" md="3">
                {ele["date"]}
              </Col>
              <Col xs="8" md="9">
                <li
                  className="activity-list"
                  style={{ borderLeft: "3px solid green", height: "15vh" }}
                >
                  <div
                    className="activity-icon avatar-xs"
                    style={{
                      color: "#fff",
                      backgroundColor: "#1eb51e",
                      borderColor: "#1eb51e",
                    }}
                  >
                    <span
                      className="avatar-title bg-soft-primary rounded-circle"
                      style={{ color: "#428c4b" }}
                    >
                      <i className="ri-user-2-fill"></i>
                    </span>
                  </div>
                  <div>
                    <div>
                      <p className="text-muted mb-2">{ele["name"]}</p>
                    </div>
                  </div>
                </li>
              </Col>
            </Row>
          );
        })}
        {orderData.systemDeliveredAt !== null ||
        orderData.customerDeliveredAt !== null ? (
          <Row key={"delivered"}>
            <Col xs="4" md="3">
              {deliveredDate}
            </Col>
            <Col xs="8" md="9">
              <li
                className="activity-list"
                style={{ borderLeft: "3px solid green", height: "5vh" }}
              >
                <div
                  className="activity-icon avatar-xs"
                  style={{
                    color: "#fff",
                    backgroundColor: "#1eb51e",
                    borderColor: "#1eb51e",
                  }}
                >
                  <span
                    className="avatar-title bg-soft-primary rounded-circle"
                    style={{ color: "#428c4b" }}
                  >
                    <i className="ri-user-2-fill"></i>
                  </span>
                </div>
                <div>
                  <div>
                    <p className="text-muted mb-2">{"Order Delivered"}</p>
                  </div>
                </div>
              </li>
            </Col>
          </Row>
        ) : null}

        {orderData.systemDeliveredAt !== null ||
        orderData.customerDeliveredAt !== null ? null : (
          <Row key={"last"} height="15vh">
            <Col xs="4" md="3">
              {/* {EstimatedDate} */}
            </Col>
            <Col xs="8" md="9">
              <li className="activity-list" style={{ height: "5vh" }}>
                <div className="activity-icon avatar-xs">
                  <span
                    className="avatar-title bg-soft-primary rounded-circle"
                    style={{ color: "#efc1c126" }}
                  >
                    <i className="ri-user-2-fill"></i>
                  </span>
                </div>
                <div>
                  <p className="text-muted mb-2">{"Arriving"}</p>
                </div>
                {/* <div>
                  <div>
          
                    {ele === "Order Delivered" && deliveredDate ? (
                      <span>Delivered Date and Time : {isDelivered?deliveredDate:""}</span>
                    ) : null}
                  </div>
                </div> */}
              </li>
            </Col>
          </Row>
        )}
      </ul>
    </span>
  );
};

class OrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      orderData: [],
      loading: true,
      delDay: "",
      delMonth: "",
      delYear: "",
      delDay: "",
      driverLocationsList: [],
    };
  }

  componentDidMount = () => {
    // if (!localStorage.getItem("authUser")) this.props.history.push("/");
    const path = this.props.history.location.pathname;
    const id = path.split("/")[2];
    this.props.fetchOrder(id, (res) => {
      if (res["message"] === "Order not found,Invalid OrderID") {
        NotificationManager.error(res["message"], "Error");
      } else {
        if (res.tripDetails && res.tripDetails.length) {
          this.interval = setInterval(() => {
            this.fetchCoordinatesByTripId(res.tripDetails[0]);
          }, 10000);
          this.setState({ orderData: res, loading: false });
        } else this.setState({ orderData: res, loading: false });
      }
    });
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  fetchCoordinatesByTripId = (trip) => {
    this.props.trackTripById(trip._id, (response) => {
      let current = [];
      response.forEach((element, i) => {
        if (element.cordinates && element.cordinates.length > 1)
          current.push({
            lat: element.cordinates[0],
            lng: element.cordinates[1],
          });
      });
      this.setState({ driverLocationsList: current });
    });
  };

  render() {
    const { orderData, loading, driverLocationsList } = this.state;

    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#123abc"} />
          </Col>
        </div>
      );
    } else {
      let address = orderData.DealerCode?.userId?.address
        ? orderData.DealerCode.userId.address
        : "-";
      let city = orderData.DealerCode?.userId?.city
        ? orderData.DealerCode.userId.city
        : "";
      let cityZip = "";
      let zipCode = orderData.DealerCode?.userId?.zipCode
        ? orderData.DealerCode?.userId?.zipCode
        : "";
      if (city && zipCode) {
        cityZip = city + " - " + zipCode;
      } else if (city || zipCode) {
        if (city) {
          cityZip = city;
        } else if (zipCode) {
          cityZip = zipCode;
        }
      }
      // console.log(isDelivered,"isDelivered")

      return (
        <React.Fragment>
          <Container fluid>
            <Button className="m-3" onClick={() => this.props.history.goBack()}>
              {"<"} Back
            </Button>
            <Card>
              <CardBody>
                <Row>
                  <Col lg="1"></Col>
                  <Col lg={11} className="mb-5">
                    <h4>
                      <span>
                        {orderData.systemDeliveredAt !== null ||
                        orderData.customerDeliveredAt !== null ? (
                          <b>Your Order Has Been Delivered</b>
                        ) : (
                          <b>
                            Track Your Order...Your Order is about to arrive
                          </b>
                        )}
                      </span>
                    </h4>
                  </Col>
                </Row>
                <Row>
                  {/* <Col lg="1"></Col> */}
                  <Col lg="6">
                    <SimpleBar height="20vh">{lists(orderData)}</SimpleBar>
                  </Col>
                  <Col lg="5">
                    <Card>
                      <CardBody className="gray-bg">
                        <form>
                          <Row>
                            <Col lg="12">
                              <Label key={"sl"}>
                                <h5>Order Details</h5>
                              </Label>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12">
                              <Label key={"sl"}>
                                <h6>
                                  {orderData.ProductName
                                    ? orderData.ProductName
                                    : "-"}
                                </h6>
                              </Label>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="4">
                              <Label key={"slv"}>Order Id:</Label>
                            </Col>
                            <Col lg="8">
                              <Label key={"slv"}>
                                {orderData.ExternalOrderId
                                  ? orderData.ExternalOrderId
                                  : "-"}
                              </Label>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="4">
                              <Label key={"slv"}>Product Price:</Label>
                            </Col>
                            <Col lg="8">
                              <Label key={"slv"}>
                                {orderData.ProductPrice &&
                                orderData.ProductPrice.trim() !== "NA"
                                  ? "Rs." + orderData.ProductPrice
                                  : "-"}
                              </Label>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="4">
                              <Label key={"slv"}>Total Amt:</Label>
                            </Col>
                            <Col lg="8">
                              <Label key={"slv"}>
                                {orderData.SubTotal &&
                                orderData.SubTotal.trim() !== "NA"
                                  ? "Rs." + orderData.SubTotal
                                  : "-"}
                              </Label>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="4">
                              <Label key={"slv"}>Quantity:</Label>
                            </Col>
                            <Col lg="8">
                              <Label key={"slv"}>
                                {orderData.Quantity ? orderData.Quantity : "-"}
                              </Label>
                            </Col>
                          </Row>
                          {/* <Row>
                          <Col lg="12">
                            <Label key={"slv"}>
                              <a className="underline">
                                View Details..

                          </a>
                            </Label>
                          </Col>

                        </Row> */}
                          <hr />
                          <Row></Row>
                          <Row>
                            <Col lg="12">
                              <Label key={"sl"}>
                                <h5>Delivery Address</h5>
                              </Label>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12">
                              <Label key={"sl"}>{address}</Label>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12">
                              <Label key={"sl"}>{cityZip}</Label>
                            </Col>
                          </Row>
                        </form>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="1"></Col>
                </Row>
                <Row className="d-flex">
                  {orderData.tripDetails && orderData.tripDetails.length > 0 && (
                    <Col xs="12" md="12" lg="6">
                      <h5>Click "Ok" below to Track the Order.</h5>
                      <MapsGoogle
                        driverLocations={driverLocationsList}
                        trackPoints={orderData.tripDetails[0]}
                      />
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Container>
        </React.Fragment>
      );
    }
  }
}

export default connect(null, { fetchOrder, trackTripById })(OrderDetail);
