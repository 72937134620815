import React, { Component } from "react";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBIcon,
} from "mdbreact";
import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";
import { NotificationManager } from "react-notifications";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { subscribeConsent } from "../store/driver/actions";
import { connect } from "react-redux";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class VerifyShipmentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isSubmitting: false,
      buttonContent: "",
      addShipment: [],
      tripID: "",
      shipment: [],
      warningModal: false,
      skipShipmentModal: false,
      source: "",
      sourceId: "",
      skipAtVia: false,
      skipAtViaReason: "",
      testInput: [],
    };
  }
  handleSubmit = async () => {
    console.log("handlesubmit=>", this.state.addShipment);
    this.setState({ loading: true });
    this.setState({ skipShipmentModal: false });
    console.log(this.props.drivers.consentStatus);
    console.log("skipAtVia", this.state.skipAtVia);
    let verify = {
      tripID: this.props.shipdetails?._id,
      sourceID: this.state.sourceId,
      vehicleRegNo: this.props.shipdetails?.vehicle.regNo,
      shipment: this.state.addShipment,
    };
    if (this.props.drivers.consentStatus) {
      if (this.state.skipAtVia) {
        verify.skipAtVia = true;
        verify.skipAtViaReason = this.state.skipAtViaReason;
        verify.shipment = [""];
      }
      console.log("VERIFY", verify);
      this.props.submit({ verify });
    } else {
      this.warningToggle();
      this.setState({ loading: false });
      console.log("VERIFY", verify);
    }
  };
  submitOnNoConsent = async () => {
    let verify = {
      tripID: this.props.shipdetails?._id,
      sourceID: this.state.sourceId,
      vehicleRegNo: this.props.shipdetails?.vehicle.regNo,
      shipment: this.state.addShipment,
    };
    if (this.state.skipAtVia) {
      verify.skipAtVia = true;
      verify.skipAtViaReason = this.state.skipAtViaReason;
      verify.shipment = [""];
    }
    this.warningToggle();
    this.props.submit({ verify });
  };

  subscribeConsent = (ele) => {
    this.props.subscribeConsent(ele.phone, (res) => {
      if (res.success)
        NotificationManager.success(
          "Message sent for tracking consent.",
          "Success"
        );
    });
  };

  componentDidUpdate = () => {
    if (this.props.shipdetails._id !== this.state.tripID) {
      this.setState({ tripID: this.props.shipdetails._id });

      if (
        this.props.shipdetails.subStatus === "loading_in_progress" ||
        this.props.shipdetails.subStatus === "arrived_at_via"
      ) {
        if (
          this.props.shipdetails.tracking.current !==
          this.props.shipdetails.tracking.path[0]._id
        ) {
          for (
            let i = 1;
            i < this.props.shipdetails.tracking.path.length;
            i++
          ) {
            if (
              this.props.shipdetails.tracking.current ===
              this.props.shipdetails.tracking.path[i]._id
            ) {
              this.setState(
                {
                  source: this.props.shipdetails.tracking.path[i].label,
                  sourceId: this.props.shipdetails.tracking.path[i].hopCode,
                },
                () => {
                  console.log("inside", this.state.source);
                }
              );
            }
          }
        } else {
          this.setState(
            {
              source: this.props.shipdetails.tracking.path[0].label,
              sourceId: this.props.shipdetails.tracking.path[0].hopCode,
            },
            () => {
              console.log("outside", this.state.source);
            }
          );
        }
      }
    }
  };

  shipmentsInput = () => {
    let add = this.state.addShipment;
    add.push("input");
    this.setState({ addShipment: add });
  };
  shipmentsInputrem = (i, a) => {
    const deleteShipmentId = this.state.addShipment.filter(
      (shipment, index) => i != index
    );
    this.setState({
      addShipment: deleteShipmentId,
    });
    // let add = this.state.addShipment;

    // if (add.length > 1) {
    //   delete add[i];
    // }
    // this.setState({ addShipment: add });
  };
  shipmentchange = (e, i) => {
    e.preventDefault();
    let ship = this.state.shipment;
    ship[i] = e.target.value;
    this.setState({ shipment: ship });
  };
  warningToggle = () => {
    this.setState({ warningModal: !this.state.warningModal });
  };
  skipShipmentToggle = (skipAtVia) => {
    this.setState({
      skipShipmentModal: !this.state.skipShipmentModal,
      skipAtVia: skipAtVia,
    });
  };
  handleResetEdit = () => {
    this.setState({
      skipAtViaReason: "",
    });
  };
  shipmentchangeTest = (e) => {
    this.setState(
      {
        testInput: e.target.value,
      },
      () => {
        console.log("SHIPMENTONCHANGE", this.state.testInput);
      }
    );
  };
  shipmentsInput1 = () => {
    this.setState(
      { addShipment: [...this.state.addShipment, this.state.testInput] },
      () => {
        console.log("ADDSHIPMENTLOG", this.state.addShipment);
        this.setState({
          testInput: "",
        });
      }
    );
  };
  handleResetEditVerify = () => {
    this.setState({
      addShipment: [],
    });
  };
  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    var darkMode = this.props?.mode;
    // console.log("VERIFY REDUCER DATA", this.props.verify);
    // console.log("---------", this.props.driver, this.state.skipAtVia);
    // console.log("SHIP DETAILS : ", this.props.shipdetails);
    const customStyles = {
      control: (style) => {
        return {
          ...style,
          border: 0,
        };
      },
    };
    return (
      <React.Fragment>
        <MDBContainer className="text-capitalize">
          <MDBModal
            isOpen={this.props.isOpen}
            toggle={() => this.props.toggle()}
            centered
            size="md"
            className={
              this.state.skipShipmentModal
                ? "VerifyShipmentModal modelDark verifyFade"
                : "VerifyShipmentModal modelDark"
            }
          >
            <MDBModalHeader
              toggle={this.toggle}
              style={{
                position: "relative",
                left: "5px",
              }}
            >
              <i
                style={{
                  marginTop: "10px",
                }}
                className="fas fa-times closeDark p-3"
                onClick={() => this.props.toggle()}
              ></i>
              <Row>
                <Col xs="12">
                  <span
                    className="fontRoboto light__span"
                    style={{
                      color: "#0C0C0C",
                    }}
                  >
                    {userLang?.trip?.VERIFY_SHIPMENT}
                  </span>
                </Col>
                <Col xs="12">
                  <span
                    className="fontRoboto1541 dark__span"
                    style={{
                      color: "#05284E",
                    }}
                  >
                    {userLang?.trip?.TRIP_ID} :{" "}
                    {this.props?.shipdetails?.tripId}
                  </span>
                </Col>
              </Row>
            </MDBModalHeader>
            <MDBModalBody className="modalPaddingBottom">
              <Row className="pl-3">
                <Col
                  xs="12"
                  md="6"
                  className="py-2"
                  style={{
                    paddingLeft: "3px",
                  }}
                >
                  {/* <FormGroup className="select2-container"> */}
                  <Label
                    className="control-label  required fontRoboto1541 light__span"
                    style={{
                      color: "#05284E",
                    }}
                  >
                    {userLang?.common?.SOURCE} <i style={{ color: "red" }}>*</i>
                  </Label>
                  <Row>
                    <Col xs={11} className="pr-0">
                      <input
                        style={{ height: "38px" }}
                        className="form-control form-control-sm w-90 light__span fontRoboto1541"
                        type="text"
                        name="source"
                        required
                        disabled
                        // value={
                        //   this.props.shipdetails
                        //     ? this.props.shipdetails?.source?.label
                        //     : ""
                        // }
                        value={this.state.source}
                        // onChange={(e) => {
                        //   // this.props.onChange({
                        //   //   target: {
                        //   //     name: "source",
                        //   //     value: e,
                        //   //   },
                        //   // });
                        // }}
                        onChange={this.searchValueSetter}
                        placeholder="Source"
                        aria-label="Source"
                        icon="fas fa-times-circle"
                      />
                    </Col>
                    {/* <Col xs={2} className="pl-0">
                      <Button
                        style={{ height: "38px" }}
                        className="btn btn-info sharp-corners-left"
                      >
                        <MDBIcon icon="search" />
                      </Button>
                    </Col> */}
                  </Row>

                  {/* </FormGroup> */}
                </Col>
                <Col xs="12" md="6" className="py-2">
                  <Label
                    className="control-label fontRoboto15 light__span"
                    style={{
                      color: "#05284E",
                    }}
                  >
                    {userLang?.tracking?.VEHICLE_NO}
                    <i style={{ color: "red" }}>*</i>
                  </Label>
                  <Row>
                    <Col xs={11} className="pr-0">
                      <input
                        style={{ height: "38px" }}
                        required
                        className="form-control form-control-sm w-90 fontRoboto1541 light__span"
                        type="text"
                        name="vehicleNumber"
                        disabled
                        value={this.props.shipdetails?.vehicle?.regNo}
                        onChange={(e) => {
                          // this.props.onChange({
                          //   target: {
                          //     name: "vehicleNumber",
                          //     value: e,
                          //   },
                          // });
                        }}
                        placeholder="Type here..."
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Col lg="12" className="py-2">
                    <div className="PfShipmentDiv addNewBg">
                      <Label
                        className="control-label fontRoboto15 light__span"
                        style={{
                          color: "#05284E",
                        }}
                      >
                        {userLang?.trip?.PF_SHIPMENT_NUMBER}{" "}
                        <i style={{ color: "red" }}>*</i>
                      </Label>
                      <Row>
                        <Col xs={9} className="pr-0">
                          <input
                            value={this.state.testInput}
                            style={{ height: "38px", minWidth: "100%" }}
                            required
                            className="form-control form-control-sm w-100 "
                            type="text"
                            name="shipmentNumber"
                            onChange={(e) => this.shipmentchangeTest(e)}
                            placeholder="Type here..."
                          />
                          <span style={{ color: "red" }}>
                            {/* {msg ? msg : ""} */}
                          </span>
                        </Col>
                        <Col xs={3} className="pt-0">
                          <Button
                            className="AddNUmberBtn addDarkBtn pl-3 pr-3 ml-4"
                            onClick={() => this.shipmentsInput1()}
                          >
                            Add Number
                          </Button>
                        </Col>
                        {this.state.addShipment?.length >= 1
                          ? this.state.addShipment.map((a, i) => {
                              let msg = this.props.verifymsg
                                ? this.props.verifymsg
                                : "";
                              // console.log(this.props.verify.filter(ft=>ft.shipmentNo==this.state.shipment[i]),"lkllksds")
                              return (
                                <Col key={`${a}-${i}`} lg="12" className="py-2">
                                  <Row>
                                    <Col xs={9} className="pr-0">
                                      <input
                                        // style={{ height: "38px", minWidth:"100%"}}
                                        value={a}
                                        required
                                        className="form-control form-control-sm w-100 InputForm"
                                        type="text"
                                        name="shipmentNumber"
                                        onChange={(e) =>
                                          this.shipmentchange(e, i)
                                        }
                                      />
                                      <span style={{ color: "red" }}>
                                        {msg ? msg : ""}
                                      </span>
                                    </Col>
                                    <Col xs={1} className="pt-0">
                                      <svg
                                        key={i}
                                        className="SvgIcon"
                                        onClick={() =>
                                          this.shipmentsInputrem(i, a)
                                        }
                                        width="7"
                                        height="7"
                                        viewBox="0 0 7 7"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M6 1L1 6"
                                          stroke={
                                            darkMode ? "#B7A9FF" : "#2800FC"
                                          }
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M1 1L6 6"
                                          stroke={
                                            darkMode ? "#B7A9FF" : "#2800FC"
                                          }
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </Col>
                                  </Row>
                                </Col>
                              );
                            })
                          : ""}
                      </Row>
                    </div>
                  </Col>
                </Col>

                <div className="mt-2">
                  <Row>
                    <Col xs={12}>
                      {!this.props.drivers.consentStatus ? (
                        <div>
                          <div className="DriverConsentDiv driverConsentDarkdiv d-flex flex-row">
                            <div className="pr-3 ">
                              <span>
                                <svg
                                  width="15"
                                  height="15"
                                  viewBox="0 0 15 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z"
                                    stroke="#E53D2C"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M7.5 10.1V7.5"
                                    stroke="#E53D2C"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M7.5 4.90039H7.505"
                                    stroke="#E53D2C"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                            </div>
                            <div>
                              <span className="fontRoboto12 dark__span">
                                {userLang?.trip?.DRIVER_CONSENT}
                              </span>
                            </div>
                          </div>

                          <div className="DriverConsentDiv driverConsentDarkdiv d-flex flex-row mt-2">
                            <div className="pr-3 ">
                              <span>
                                <svg
                                  width="15"
                                  height="15"
                                  viewBox="0 0 15 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z"
                                    stroke="#E53D2C"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M7.5 10.1V7.5"
                                    stroke="#E53D2C"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M7.5 4.90039H7.505"
                                    stroke="#E53D2C"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                            </div>
                            <div>
                              <span className="fontRoboto12 dark__span">
                                {userLang?.trip?.CONSENT_LOCATION}.
                              </span>
                            </div>
                          </div>

                          <div className="mt-2">
                            <span className=" dark__span">
                              <span className="font-weight-bold dark__span">
                                {" "}
                                {userLang?.trip?.OPERATOR} :{" "}
                              </span>
                              {this.props.operator
                                ? this.props.operator
                                : " ----"}
                            </span>
                          </div>
                        </div>
                      ) : (
                        // <span>
                        //   <i
                        //     style={{ color: "#e3e374" }}
                        //     className="fas fa-info-circle mr-1"
                        //   ></i>
                        //   <span>
                        //     <span style={{ color: "red" }}>
                        //       {userLang?.trip?.DRIVER_CONSENT}
                        //     </span>
                        //     <div className="mt-1">
                        // <i
                        //   style={{ color: "#e3e374" }}
                        //   className="fas fa-info-circle mr-1"
                        // ></i>
                        //       <span style={{ color: "#1f264c" }}>
                        //         {userLang?.trip?.CONSENT_LOCATION}.
                        //       </span>
                        //     </div>

                        //     <div className="mt-2">
                        //       <span>
                        //         <span className="font-weight-bold">
                        //           {" "}
                        //           {userLang?.trip?.OPERATOR} :{" "}
                        //         </span>
                        //         {this.props.operator
                        //           ? this.props.operator
                        //           : " ----"}
                        //       </span>
                        //     </div>
                        //   </span>
                        // </span>
                        <span>
                          <div className="d-flex flex-row greenBorder driverConsentDarkdiv">
                            <div className="pr-3">
                              <span>
                                <i
                                  style={{ color: "green", fontSize: "16px" }}
                                  className="fas fa-check-circle"
                                ></i>
                              </span>
                            </div>
                            <div>
                              <span className="fontRoboto1541 light__span">
                                {userLang?.trip?.DRIVER_CONSENT_LOCATION}.
                              </span>
                            </div>
                          </div>

                          <div>
                            <span className="dark__span">
                              <span className="font-weight-bold dark__span">
                                {" "}
                                {userLang?.trip?.OPERATOR} :{" "}
                              </span>
                              {this.props.operator
                                ? this.props.operator
                                : " ----"}
                            </span>
                          </div>
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Row>
              <Row
                className="mt-4"
                style={{
                  position: "relative",
                  left: "0px",
                  paddingTop: "10px",
                }}
              >
                {/* <Col xs="3"> */}
                {this.props.shipdetails?.tracking?.current !=
                  this.props.shipdetails?.tracking?.path[0]._id &&
                this.props.shipdetails?.tracking?.current !=
                  this.props.shipdetails?.tracking?.path[
                    this.props.shipdetails?.tracking?.path?.length - 1
                  ]._id &&
                Array.isArray(this.props.shipdetails?.tracking?.path) &&
                this.props.shipdetails.subStatus == "arrived_at_via" ? (
                  <Col xs="3" className="">
                    <MDBBtn
                      style={{
                        width: "100%",
                        height: "50px",
                        backgroundColor: "#05284E",
                        color: "#FFFFFF",
                      }}
                      color="danger rounded button-wide"
                      onClick={() => this.skipShipmentToggle(true)}
                    >
                      {userLang?.trip?.SKIP_SHIPMENT}
                    </MDBBtn>
                    <br />
                    <div className=" d-flex flex-row mt-2">
                      <div className="pr-3 ">
                        <span>
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z"
                              stroke="#6E6E6E"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.5 10.1V7.5"
                              stroke="#6E6E6E"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.5 4.90039H7.505"
                              stroke="#6E6E6E"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </div>
                      <div>
                        <span className="fontRoboto12">
                          {userLang?.trip?.CLICK_NO_SHIPMENT}
                        </span>
                      </div>
                    </div>
                    {/* <span>
                    {" "}
                    <i className="fas fa-info-circle mr-1"></i>{" "}
                    <span>{userLang?.trip?.CLICK_NO_SHIPMENT}</span>{" "}
                  </span> */}
                  </Col>
                ) : (
                  ""
                )}
                {/* </Col> */}
                <Col xs="2">
                  <MDBBtn
                    className="fontRoboto1541 addDarkBtn"
                    style={{
                      width: "100%",
                      height: "50px",
                      backgroundColor: "#0AB38C",
                      color: "#FFFFFF",
                    }}
                    color="secondary"
                    onClick={() => this.handleResetEditVerify()}
                  >
                    {userLang?.common?.RESET}
                  </MDBBtn>
                </Col>
                <Col xs="2">
                  <MDBBtn
                    className="fontRoboto1541 addDarkBtn"
                    style={{
                      width: "100%",
                      height: "50px",
                      backgroundColor: "#05284E",
                      color: "#FFFFFF",
                    }}
                    color="primary"
                    onClick={this.handleSubmit}
                    disabled={this.state.isSubmitting || this.state.loading}
                  >
                    {userLang?.trip?.VERIFY}
                  </MDBBtn>
                </Col>
                <Col xs="2"></Col>
                {this.props.shipdetails?.tracking?.current !=
                  this.props.shipdetails?.tracking?.path[0]._id &&
                this.props.shipdetails?.tracking?.current !=
                  this.props.shipdetails?.tracking?.path[
                    this.props.shipdetails?.tracking?.path?.length - 1
                  ]._id &&
                Array.isArray(this.props.shipdetails?.tracking?.path) &&
                this.props.shipdetails.subStatus == "arrived_at_via" ? (
                  ""
                ) : (
                  <Col xs="3"></Col>
                )}

                <Col xs={3}>
                  <MDBBtn
                    style={{
                      width: "86%",
                      height: "50px",
                      backgroundColor: "#E53D2C",
                      color: "#FFFFFF",
                    }}
                    color="primary rounded button-wide"
                    onClick={() => {
                      this.subscribeConsent(this.props.driver);
                    }}
                  >
                    {userLang?.trip?.TRIGGER_CONSENT}
                  </MDBBtn>
                </Col>
              </Row>
            </MDBModalBody>
            {/* <MDBBtn color="secondary" onClick={() => this.props.toggle()}>
                Cancel
              </MDBBtn> */}

            {this.props.shipdetails?.tracking?.current !=
              this.props.shipdetails?.tracking?.path[0]._id &&
            this.props.shipdetails?.tracking?.current !=
              this.props.shipdetails?.tracking?.path[
                this.props.shipdetails?.tracking?.path?.length - 1
              ]._id &&
            Array.isArray(this.props.shipdetails?.tracking?.path) &&
            this.props.shipdetails.tracking.path.includes(
              this.props.shipdetails?.tracking?.current
            ) &&
            this.props.shipdetails.subStatus == "loading_in_progress" ? (
              <div className="noLoading">
                ({userLang?.trip?.NO_LOADING_AT_THIS_LOCATION})
              </div>
            ) : (
              ""
            )}
            {/* <Row className="py-2 alignWithLocation">
              {this.props.shipdetails?.tracking?.current !=
                this.props.shipdetails?.tracking?.path[0]._id &&
              this.props.shipdetails?.tracking?.current !=
                this.props.shipdetails?.tracking?.path[
                  this.props.shipdetails?.tracking?.path?.length - 1
                ]._id &&
              Array.isArray(this.props.shipdetails?.tracking?.path) &&
              this.props.shipdetails.subStatus == "arrived_at_via" ? (
                <Col xs="4" className="shipMentPadding">
                  <MDBBtn
                    color="danger rounded button-wide"
                    onClick={() => this.skipShipmentToggle(true)}
                  >
                    {userLang?.trip?.SKIP_SHIPMENT}
                  </MDBBtn>
                  <br />
                  <span>
                    {" "}
                    <i className="fas fa-info-circle mr-1"></i>{" "}
                    <span>{userLang?.trip?.CLICK_NO_SHIPMENT}</span>{" "}
                  </span>
                </Col>
              ) : (
                <Col xs="4"></Col>
              )}
              <Col xs="4"></Col>
              <Col xs="4" className="verifyPadding">
                <MDBBtn
                  color="primary rounded button-wide"
                  onClick={this.handleSubmit}
                  disabled={this.state.isSubmitting || this.state.loading}
                >
                  {userLang?.trip?.VERIFY}
                </MDBBtn>
              </Col>
            </Row> */}

            <MDBModalFooter></MDBModalFooter>
          </MDBModal>
          <MDBContainer>
            <MDBModal
              isOpen={this.state.warningModal}
              toggle={this.warningToggle}
              centered
            >
              <MDBModalHeader toggle={this.warningToggle}>
                <span style={{ color: "red" }}> {userLang?.trip?.WARNING}</span>
              </MDBModalHeader>
              <MDBModalBody>
                {userLang?.trip?.SKIP_SHIPMENT_WARNING}?
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="warning" onClick={this.warningToggle}>
                  {userLang?.common?.CLOSE}
                </MDBBtn>
                <MDBBtn color="danger" onClick={this.submitOnNoConsent}>
                  {userLang?.trip?.CONTINUE}
                </MDBBtn>
              </MDBModalFooter>
            </MDBModal>
          </MDBContainer>
          <MDBContainer>
            <MDBModal
              className="SkipShipmentModal"
              isOpen={this.state.skipShipmentModal}
              toggle={this.skipShipmentToggle}
              centered
            >
              <MDBModalHeader toggle={this.skipShipmentToggle}>
                <span className="fontRoboto">
                  {" "}
                  {userLang?.trip?.SKIP_SHIPMENT_CAPS}
                </span>
              </MDBModalHeader>
              <MDBModalBody>
                <label className="fontRoboto15">
                  Why Do You Want to Skip Shipment?
                </label>
                <input
                  style={{ height: "233px", minWidth: "100%" }}
                  required
                  className="form-control  mt-2"
                  type="text"
                  name="skipAtViaReason"
                  value={this.state.skipAtViaReason}
                  onChange={(e) => {
                    this.setState({ skipAtViaReason: e.target.value });
                    console.log(this.state.skipAtViaReason);
                  }}
                  placeholder={userLang?.trip?.SKIP_SHIPMENT_PlACEHOLDER}
                />
              </MDBModalBody>
              <Row
                style={{
                  position: "relative",
                  left: "17px",
                  paddingTop: "10px",
                }}
              >
                <Col xs="3">
                  <MDBBtn
                    className="fontRoboto1541"
                    style={{
                      width: "100%",
                      height: "50px",
                      backgroundColor: "#0AB38C",
                      color: "#FFFFFF",
                    }}
                    color="secondary"
                    onClick={() => this.handleResetEdit()}
                  >
                    {userLang?.common?.RESET}
                  </MDBBtn>
                </Col>
                <Col xs="4">
                  <MDBBtn
                    className="fontRoboto1541"
                    style={{
                      width: "100%",
                      height: "50px",
                      backgroundColor: "#05284E",
                      color: "#FFFFFF",
                    }}
                    color="primary"
                    onClick={this.handleSubmit}
                    disabled={this.state.isSubmitting || this.state.loading}
                  >
                    {userLang?.trip?.SKIP_SHIPMENT}
                  </MDBBtn>
                </Col>
                <Col xs="6"></Col>
              </Row>
              {/* <MDBModalFooter>
             
                <MDBBtn
                  color="warning"
                  onClick={() => this.skipShipmentToggle(false)}
                >
                  {userLang?.common?.CLOSE}
                </MDBBtn>
                <MDBBtn
                  color="danger"
                  onClick={this.handleSubmit}
                  disabled={this.state.isSubmitting || this.state.loading}
                >
                  {userLang?.trip?.SKIP_SHIPMENT}
                </MDBBtn>
              </MDBModalFooter> */}
            </MDBModal>
          </MDBContainer>
        </MDBContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    indents: state.Indent.indents.assignedIndents,
    policies: state.AccessPolicy.accessPolicies.policies,
    drivers: state.Driver.drivers,
  };
};

export default connect(mapStateToProps, {
  subscribeConsent,
  //   finalizeIndent,
})(VerifyShipmentModal);
