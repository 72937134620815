import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { Container, Card, CardBody, Row, Col } from "reactstrap";
export default class ShipmentShortageChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name:"",
          data: [0, 0, 0],
        },
      ],
      options: {
        tooltip: {
          shared: false,
          intersect: true,
          y: {
            formatter: (value) => {
              return value  + ' ' + '%'
            },
          },
          
        },
        annotations: {
          points: [
            {
              seriesIndex: 0,
              label: {
                borderColor: "#775DD0",
                offsetY: 0,
                style: {
                  color: "#fff",
                  background: "#775DD0",
                },
              },
            },
          ],
        },
        chart: {
          height: 350,
          type: "bar",
        },
        colors: ["#FFB829"],
        plotOptions: {
          bar: {
            borderRadius: 0,
            columnWidth: "50%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
        },

        grid: {
          show: false,
        },
        xaxis: {
          labels: {
            rotate: 0,
            show: false,
          },
          tickAmount: 2,
          categories: [],
          tickPlacement: "between",
        },

        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100],
          },
        },
      },
    };
  }
  componentDidUpdate(prevProps) {
    let filteredArray = [];
    if (prevProps?.shipmentShortageData !== this.props?.shipmentShortageData) {
      if (Array.isArray(this.props?.shipmentShortageData)) {
        filteredArray = this.props?.shipmentShortageData;
        if (filteredArray.length > 0) {
          this.setState({
            series: [
              {
                data: filteredArray?.map(
                  (ele) => (ele?.performancePercentage * 100).toFixed(2)
                ),
              },
            ],
            options: {
              ...this.state.options,
              xaxis: {
                categories: filteredArray?.map((ele) => ele?.transporter),
              },
            },
          });
        }
        else {
          this.setState({
            series: [
              {
                data: [0, 0, 0],
              },
            ],
            options: {
              ...this.state.options,
              xaxis: {
                categories: [],
              },
            },
          });
        }
      }
    }
  }
  render() {
    var darkMode = this.props?.mode;
    return (
      <React.Fragment>
        <>
          <CardBody className="darkCardStyle">
            <div className="mt-1 pl-3 pt-1">
              <Row>
                <Col xs="11">
                  <span className="barChartHeader light__span">
                    {this.props?.userLang?.dashboard?.SHIPMENT_SHORTAGE_GRAPH ??
                      "Shipment Shortage"}
                  </span>
                </Col>
                <Col xs="1">
                  <span className="pr-0">
                    <MoreHorizIcon
                      style={{
                        color: "blue",
                        float: "right",
                        marginRight: "6px",
                      }}
                    ></MoreHorizIcon>
                  </span>
                </Col>
              </Row>
            </div>
            <hr className="lineStraight lineHr" />
            <Container fluid>
              <Card>
                <div
                  style={{
                    borderStyle: darkMode ? "none" : "solid",
                    borderWidth: "thin",
                    borderColor: "white",
                    borderRadius: "5px",
                    backgroundColor: darkMode ? "#2E2E46" : "#F1F5F7",
                  }}
                >
                  <CardBody style={{ height: "100%" }}>
                    <div className="apexChartIcon darkInputHighlightDiv">
                      <div id="chart">
                        <ReactApexChart
                          //   style={{ width: "100%" }}
                          options={this.state.options}
                          series={this.state.series}
                          type="bar"
                          height={350}
                        />
                      </div>
                    </div>
                  </CardBody>
                </div>
              </Card>
            </Container>
          </CardBody>
        </>
      </React.Fragment>
    );
  }
}
