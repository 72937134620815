import React, { Component } from "react";
import {
  InputField,
  RenderNewDate2,
  RenderNewDate,
} from "./inputFields/Fields";
import {
  Row,
  Col,
  Card,
  Button,
  CardTitle,
  CardText,
  Container,
} from "reactstrap";
import sorting_icon from "./assets/sorting_icon.png";
import sortingDark_icon from "./assets/sortDark.png";
import { MDBBtn } from "mdbreact";
import formatDate from "../../../pages/DateFormatter";
import moment from "moment";
import { WaveLoader } from "../../Common/Loader/LoaderIndex";

let dataSet = [];
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      emptyFlag: false,
      //data
      headers: [],
      columnWidthArray: [],
      data: [],
      dataLength: 0,
      processedData: [],
      //search
      searchToggle: true,
      searchBar: [],
      //date
      date: [null, null],
      startDate: [null, null],
      endDate: [null, null],
      // reset
      resetBar: true,
      fetchId: "",
    };
  }

  componentDidMount = () => {
    let headers = this.props?.headers?.columns;
    let searchBar = this.props?.searchBar?.searchBarData;
    let columnWidthArray = [];
    this.setState({ headers: headers, searchBar: searchBar }, () => {
      if (this.state.headers)
        this.state.headers.forEach((item) => {
          columnWidthArray.push(item.width);
        });
      this.setState({ columnWidthArray: columnWidthArray });
    });
  };

  componentDidUpdate = () => {
    let data = this.props.data;

    let fetchId = this.props.fetchId;
    // console.log("componentDIdupdate", data);
    if (!data.length && this.state.emptyFlag === false)
      this.setState({
        processedData: [],
        emptyFlag: true,
      });
    else if (
      this.state.data[0]?.id !== data[0]?.id ||
      this.state.data.length !== data.length ||
      this.props?.dataLength !== this.state.dataLength ||
      this.state.fetchId !== fetchId
    ) {
      this.setState(
        {
          data: data,
          emptyFlag: false,
          dataLength: this.props.dataLength,
          fetchId: fetchId,
        },
        () => {
          this.formatter(this.state.data);
        }
      );
      console.log("HERE INSIDE INDEX");
    }
  };

  formatter = (data) => {
    dataSet = [];
    data.map((dataItem, index) => {
      this.binder(dataItem, index);
    });
  };

  binder = (dataItem, index) => {
    let dataSetItem = [];
    let headers = this.props?.headers?.columns;
    Object.keys(dataItem).map((key, index) => {
      if (dataSet?.length <= 10 || this.props?.limit > 0)
        if (headers)
          headers.forEach((headItem, headIndex) => {
            if (headItem.field === key) {
              dataSetItem[headIndex] = dataItem[key];
            }
          });
    });
    dataSet[index] = dataSetItem;
    // if (dataSet.length === 10) {
    this.setState({ processedData: dataSet }, () => {
      // console.log("DATASET", this.state.processedData);
    });
    // }
  };

  handleChange = (event, type) => {
    console.log("--event", event);
    console.log("..type", type);
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        console.log(
          "state[",
          event.target.name,
          "]:",
          this.state[event.target.name]
        );
        this.props.searchValueAssignerFunction(
          event.target.name,
          event.target.value,
          type
        );
      }
    );
    // console.log("STATE:", this.state);
  };
  // handleInputChangeTest =(e)=>{
  // console.log("e",e)
  // }

  handleDate = (e, name) => {
    let date = [];
    if (name === "startTime")
      this.setState({ startDate: e }, () => {
        date[0] = moment(this.state.startDate[0]).format("MM-DD-YYYY");
        date[1] = moment(this.state.startDate[1]).format("MM-DD-YYYY");
        this.props.searchValueAssignerFunction(name, date, "date");
      });
    else if (name === "expiryTime")
      this.setState({ endDate: e }, () => {
        date[0] = moment(this.state.endDate[0]).format("MM-DD-YYYY");
        date[1] = moment(this.state.endDate[1]).format("MM-DD-YYYY");
        this.props.searchValueAssignerFunction(name, date, "date");
      });
  };

  keyPress = (e) => {
    if (e.key === "Enter") this.props.getAllData();
  };

  // change = () => {
  //   console.log("CHNAGED");
  //   this.props.getAllData();
  // };

  reset = () => {
    this.setState(
      { resetBar: false, startDate: [null, null], endDate: [null, null] },
      () => {
        this.setState({ resetBar: true });
      }
    );
    this.state.searchBar.forEach((item) => {
      // console.log(this[item.field]?.value);
      if (this[item.field]?.value) this[item.field].value = "";
      // console.log(item);
      this.setState({ [item.field]: "" }, () => {
        // console.log(this.state[item.field]);
      });
    });
    this.props.reset();
  };

  handleDoubleClick = (dataItem, Item) => {
    // console.log("double click captured", this.props, this.state, dataItem , Item)
    if (this.props?.location?.pathname == "/indent-planning") {
      const data = this.state.data.find((e) => e.indentId == Item[0]);
      this.props.toggle(data);
    }
  };

  render() {
    console.log("this.props.data", this.props.data);
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let { headers, processedData, columnWidthArray } = this.state;
    let initStorage = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : "";
    if (initStorage === "dark__mode") {
      var darkTheme = true;
      var dataHeaderBgClr = "#222238";
      var dataHeaderTextClr = "#9D9D9D";
      var dataTableTextClr = "#FFFFFF";
      var dataCardBgClr = "#1B1C31";
      var dataProcessingClr = "#24243C";
      var dateBgClr = "#24243C";
      var dateClr = "#ffffff";
    } else if (initStorage === "light__mode") {
      var darkTheme = false;
      var dataHeaderBgClr = "";
      var dataHeaderTextClr = "#05284E";
      var dataTableTextClr = "#05284E";
      var dataCardBgClr = "#eff2f7 ";
      var dataProcessingClr = "";
      var dateBgClr = "";
      var dateClr = "";
    } else {
      var darkTheme = false;
      var dataHeaderBgClr = "";
      var dataHeaderTextClr = "#05284E";
      var dataTableTextClr = "#05284E";
      var dataCardBgClr = "#eff2f7 ";
      var dataProcessingClr = "";
      var dateBgClr = "";
      var dateClr = "";
    }
    // console.log(columnWidthArray, "columnWidthArray");
    return (
      <React.Fragment>
        {this.props.dataLoading && (
          <div className="ListingPages">
            <WaveLoader />
          </div>
        )}
        <div
          // className="IndexDataTableNeoParent"
          className={
            this.props?.headers?.columns?.length > 12
              ? "IndexDataTableNeoParentScroll"
              : "IndexDataTableNeoParent"
          }
          style={{
            textAlign: "center",
            justifyContent: "center",
            marginTop: darkTheme ? "0px" : "1px",
            backgroundColor: `${dataHeaderBgClr}`,
            paddingBottom: "0px",
            marginBottom: "0px !important",

            // display:"block",
            // width:"100%",
            // overflow:"auto"
            textTransform: "capitalize",
          }}
        >
          <div
            className={
              this.props?.headers?.columns?.length > 12
                ? "scroollDataTableNeoScroll"
                : "scroollDataTableNeo"
            }
          >
            <Card
            className={
              this.props?.headers?.columns?.length > 12
                ? "CardDateTableScroll"
                : "CardDateTable"
            }
              // className="CardDateTable"
              style={{
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                height: "57px",
                marginBottom: "0px",
                width: "100%",
                backgroundColor: `${dataHeaderBgClr}`,
              }}
            >
              <div style={{ marginTop: "8px" }}>
                <CardTitle className="">
                  <Row
                    style={{ alignItems: "center" }}
                    className={
                      this.props?.headers?.columns?.length > 12
                        ? "IndexDatatableNeoRowScroll"
                        : "IndexDatatableNeoRow"
                    }
                    // className="IndexDatatableNeoRow"
                  >
                    {headers?.map((headItem, index) => {
                      // console.log("headeIten", headItem);
                      return (
                        <Col
                          className="IndexDatatableNeoCol"
                          key={index}
                          style={{
                            flex: columnWidthArray[index],
                            color: `${dataHeaderTextClr}`,
                            fontSize: "15px",
                            fontWeight: "700",
                            lineHeight: "18px",
                            fontFamily: "Roboto",
                          }}
                        >
                          {headItem.label}{" "}
                          <span>
                            <img
                              src={darkTheme ? sortingDark_icon : sorting_icon}
                              alt={"v"}
                            />
                          </span>
                        </Col>
                      );
                    })}
                  </Row>
                </CardTitle>
              </div>
            </Card>
            <Card
              style={{ minHeight: "48px", backgroundColor: `${dataCardBgClr}` }}
              // className="CardDateTable"
              className={
                this.props?.headers?.columns?.length > 12
                  ? "CardDateTableScroll"
                  : "CardDateTable"
              }
            >
              {this.props?.searchable ? (
                this.state?.searchToggle ? (
                  <div
                    style={{
                      height: "48px",
                      display: "flex",
                      alignItems: "center",
                      marginTop: "-8px",
                    }}
                  >
                    <Row
                      style={{ flex: "1", alignItems: "center" }}
                      // className="IndexDatatableNeoRow"
                    >
                      {this.state?.resetBar
                        ? this.state?.searchBar?.map((item, index) => {
                            // console.log("ITEM", item);
                            if (index !== this.state?.searchBar.length - 1) {
                              if (item.type !== "date") {
                                return (
                                  <Col
                                    className="IndexDatatableNeoCol1"
                                    key={index}
                                    style={{
                                      flex: columnWidthArray[index],
                                    }}
                                  >
                                    <InputField
                                      innerRef={(val) =>
                                        (this[item.field] = val)
                                      }
                                      inputName={item.field}
                                      controlType={item.type}
                                      // value={this.state[item.field]}
                                      placeholderValue={
                                        item.field == "driver"
                                          ? "Type Mobile No."
                                          : "Type"
                                      }
                                      onChangeMethod={(e) =>
                                        this.handleChange(e, item.type)
                                      }
                                      onInputChange={(e) =>
                                        this.props?.filterDataTranspotersAndTrips(
                                          e
                                        )
                                      }
                                      optionList={item.options}
                                      handleKeyPress={this.keyPress}
                                      dateBg={dateBgClr}
                                      dateClr={dateClr}
                                    />
                                  </Col>
                                );
                              } else if (
                                item.type === "date" &&
                                item.field === "startTime"
                              ) {
                                return (
                                  <Col
                                    className="dateSearch IndexDatatableNeoCol1"
                                    key={index}
                                    style={{
                                      flex: columnWidthArray[index],
                                    }}
                                  >
                                    <RenderNewDate
                                      inputName={item.field}
                                      controlType={item.type}
                                      inputValue={this.state.startDate}
                                      placeholderValue="Type"
                                      onChangeMethod={(e) =>
                                        this.handleDate(e, item.field)
                                      }
                                      // onInputChange={this.change}
                                      optionList={item.options}
                                      handleKeyPress={this.keyPress}
                                      dateBg={dateBgClr}
                                      dateClr={dateClr}
                                    />
                                  </Col>
                                );
                              } else if (
                                item.type === "date" &&
                                item.field === "expiryTime"
                              ) {
                                return (
                                  <Col
                                    className="dateSearch IndexDatatableNeoCol1"
                                    key={index}
                                    style={{
                                      flex: columnWidthArray[index],
                                    }}
                                  >
                                    <RenderNewDate
                                      inputName={item.field}
                                      controlType={item.type}
                                      inputValue={this.state.endDate}
                                      placeholderValue="Type"
                                      onChangeMethod={(e) =>
                                        this.handleDate(e, item.field)
                                      }
                                      // onInputChange={this.change}
                                      optionList={item.options}
                                      handleKeyPress={this.keyPress}
                                      dateBg={dateBgClr}
                                      dateClr={dateClr}
                                    />
                                  </Col>
                                );
                              }
                            } else
                              return (
                                <Col
                                  className="IndexDatatableNeoCol1"
                                  key={index}
                                  style={{
                                    flex: columnWidthArray[index],
                                    alignSelf: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      maxHeight: "22px",
                                      marginTop: "18px",
                                    }}
                                  >
                                    <Button
                                      style={{
                                        width: "100%",
                                        height: "22px",
                                        lineHeight: "0px",
                                        backgroundColor: "#0AB38C",
                                        fontSize: "12px",
                                      }}
                                      onClick={this.reset}
                                    >
                                      {userLang?.common?.RESET}
                                    </Button>
                                  </div>
                                </Col>
                              );
                          })
                        : null}

                      {/* <Col
                    style={{
                      maxHeight: "10px",
                      maxWidth: "10px",
                      alignSelf: "center",
                    }}
                    className="mb-2"
                    onClick={() => {
                      // this.setState({ searchToggle: false });
                    }}
                  >
                    <i className="fas fa-times"></i>
                  </Col> */}
                    </Row>
                  </div>
                ) : (
                  <div
                    style={{
                      backgroundColor: "#2800FC",
                      color: "#FFFFFF",
                      width: "24px",
                      height: "20px",
                      marginTop: "-12px",
                      position: "absolute",
                      borderRadius: "4px",
                    }}
                    onClick={() => {
                      // this.setState({ searchToggle: true });
                    }}
                  >
                    <i className="fas fa-search"></i>
                  </div>
                )
              ) : null}

              <div style={{ marginTop: "22px" }}>
                {!this.props.dataLoading && (
                  <>
                    {processedData?.length > 0 ? (
                      processedData.map((Item, index) => {
                        return (
                          <Card
                            className=""
                            style={{
                              height: this.props.rowHeight
                                ? this.props.rowHeight
                                : "fitContent",
                              textAlign: "center",
                              justifyContent: "center",
                              marginBottom: "4px",
                              padding: "1px 4px",
                              boxShadow: "0px 4px 20px -12px",
                              width: "100%",
                              backgroundColor: `${dataProcessingClr}`,
                            }}
                            key={index}
                          >
                            <Row
                              className={
                                this.props?.headers?.columns?.length > 12
                                  ? "IndexDatatableNeoRowScroll"
                                  : "IndexDatatableNeoRow"
                              }
                              style={{
                                border: "2px",
                                alignItems: "center",
                                textAlign: "center",
                              }}
                            >
                              {Item.map((dataItem, index) => {
                                // console.log(dataItem, "dataItem");
                                return (
                                  <>
                                    <Col
                                      onDoubleClick={() => {
                                        this.handleDoubleClick(dataItem, Item);
                                      }}
                                      className="IndexDatatableNeoCol"
                                      key={index}
                                      style={{
                                        cursor: "pointer",
                                        flex: columnWidthArray[index],
                                        color: "#05284E",
                                        fontSize: "13px",
                                        fontWeight: "400",
                                        flexDirection: "row",
                                        whiteSpace: "wrap",
                                        color: `${dataTableTextClr}`,
                                        // textOverflow: "ellipsis",
                                        fontFamily: "Roboto",
                                        flexWrap: "nowrap !important",
                                        flexGrow: "0px !important",
                                      }}
                                    >
                                      {dataItem}
                                    </Col>
                                  </>
                                );
                              })}
                            </Row>
                          </Card>
                        );
                      })
                    ) : (
                      <Card
                        style={{
                          width: "100%",
                          height: "400px",
                          backgroundColor: `${dataProcessingClr}`,
                          // backgroundColor: "#F1F5F7",
                          justifyContent: "center",
                        }}
                      >
                        <div className="fontRoboto15 light__span">
                          No Matching Records Found
                        </div>
                      </Card>
                    )}
                  </>
                )}
              </div>
            </Card>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Index;
