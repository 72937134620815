import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  CREATE_USER,
  LOAD_USERS,
  LOAD_FILTER_USERS,
  UPDATE_USER,
  USER_DETAILS_SUCCESSFUL,
  USER_DETAILS,
  LOAD_USERS_SUCCESSFUL
} from "./actionTypes";
import { registerUserSuccessful, registerUserFailed } from "./actions";

//AUTH related methods
import { postApi, getApi, patchApi } from "../../../helpers/auth_helper";
import { filter } from "lodash";
// import { getFirebaseBackend } from '../../../helpers/firebase_helper';

// initialize firebase Auth
// const fireBaseBackend = getFirebaseBackend();

// Is user register successfull then direct plot user in redux.
function* registerUser({ values, callback }) {
  try {
    let uri = "";
    switch (values.roleType) {
      case "Driver":
        uri = process.env.REACT_APP_SERVERURL + `/driver/create`;
        break;
      case "Dealer":
        uri = process.env.REACT_APP_SERVERURL + `/dealer/create`;
        break;
      case "Super Admin":
        uri = process.env.REACT_APP_SERVERURL + `/auth/signup`;
        break;
      default:
        break;
    }
    console.log(uri);
    const response = yield call(postApi, uri, values);
    //console.log('response',response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: REGISTER_USER_SUCCESSFUL,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not register.", error);
  }
}

//
function* createUser({ values, callback }) {
  try {
    let uri = "";
    uri = process.env.REACT_APP_SERVERURL + `/user/create`;

    console.log(uri);
    const response = yield call(postApi, uri, values);
    //console.log('response',response);
    callback(response);
    //   Dispatch the action to the reducers
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not register.", error);
  }
}

function* workUpdateUser({ userId, data, callback }) {
  try {
    const uri =
      process.env.REACT_APP_SERVERURL + `/user/updateUser?userId=${userId}`;
    const response = yield call(patchApi, uri, data);
    callback(response);
  } catch (error) {
    console.log("Request failed! Could not fetch driver.", error);
  }
}

function* workfetchUsers({ offset, filter, callback }) {
    console.log("FILTERs", filter)
  try {
    // Try to call the API
    let name = filter?.name ? filter?.name : ""
    let role = filter?.role ? filter?.role : ""
    let hop = filter?.hop ? filter?.hop : ""
    let phone = filter?.phone ? filter?.phone : ""
    let email = filter?.email ? filter?.email : ""
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/user/List?offset=` +
      offset +
      `&limit=10&name=`
      + name +
       `&phone=`
      + phone +
       `&email=`
        + email + 
        `&role=` 
        + role + 
        `&hop=` + hop;
    const response = yield call(getApi, uri);
    yield put({
      type: LOAD_USERS_SUCCESSFUL,
      payload: response,
    });
    localStorage.setItem("usersDataList", JSON.stringify(response));
    callback(response);
    //console.log('FETCH_DRIVERS',response);
    //   Dispatch the action to the reducers
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch users.", error);
  }
}
function* workfetchUsersfilters({ offset, filters, callback }) {
  console.log(filters);
  let filterParameterString = "";
  if (filters.name !== "") {
    filterParameterString = filterParameterString.concat(
      `&name=` + filters.name
    );
  } else filterParameterString = filterParameterString.concat(`&name=`);
  if (filters.phone !== "") {
    filterParameterString = filterParameterString.concat(
      `&phone=` + filters.phone
    );
  } else filterParameterString = filterParameterString.concat(`&phone=`);
  if (filters.email !== "") {
    filterParameterString = filterParameterString.concat(
      `&email=` + filters.email
    );
  } else filterParameterString = filterParameterString.concat(`&email=`);

  if (filters.role !== "" && filters.role !== undefined) {
    filterParameterString = filterParameterString.concat(
      `&role=` + filters.role
    );
  } else filterParameterString = filterParameterString.concat(`&role=`);
  if (filters.hop !== "" && filters.hop !== undefined) {
    filterParameterString = filterParameterString.concat(`&hop=` + filters.hop);
  } else filterParameterString = filterParameterString.concat(`&hop=`);
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/user/List?offset=` +
      offset +
      `&limit=` +
      10 +
      filterParameterString;
    const response = yield call(getApi, uri);
    console.log("inside saga", response);
    callback(response);
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch users.", error);
  }
}
function* workUserDetails({ userId, callback }) {
  try {
    const uri = process.env.REACT_APP_SERVERURL + `/user/user/${userId}`;
    const response = yield call(getApi, uri);
    yield put({
      type: USER_DETAILS_SUCCESSFUL,
      payload: response,
    });
    callback(response);
  } catch (error) {
    console.log("Request failed! Could not fetch user details.", error);
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser);
}
export function* watchCreateUser() {
  yield takeEvery(CREATE_USER, createUser);
}
export function* watchFetchUser() {
  yield takeEvery(LOAD_USERS, workfetchUsers);
}
export function* watchFetchUserFilter() {
  yield takeEvery(LOAD_FILTER_USERS, workfetchUsersfilters);
}
export function* workFetchUpdateUser() {
  yield takeEvery(UPDATE_USER, workUpdateUser);
}
export function* workFetchUserDetails() {
  yield takeEvery(USER_DETAILS, workUserDetails);
}

function* accountSaga() {
  yield all([
    fork(watchUserRegister),
    fork(watchCreateUser),
    fork(watchFetchUser),
    fork(watchFetchUserFilter),
    fork(workFetchUpdateUser),
    fork(workFetchUserDetails),
  ]);
}

export default accountSaga;