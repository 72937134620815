// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { types } from "./actionTypes";

import {
  getApi,
  postApi,
  postUploadApi,
  putUploadApi,
  patchApi,
  putApi,
} from "../../helpers/auth_helper";

function* workfetchViewAgreement({ id }) {
  try {
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/agreement/viewAgreementByAgreementId?agreementId=` +
      id;
    const response = yield call(getApi, uri);
    yield put({
      type: types.VIEW_AGREEMENT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    ////consolelog("Request failed! Could not fetch values.", error);
  }
}

function* workfetchLastAgreement({ id }) {
  try {
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/agreement/fetchLastAgreement?transporterId=` +
      id;
    const response = yield call(getApi, uri);
    yield put({
      type: types.FETCH_LAST_AGREEMENT_VALUES_SUCCESS,
      payload: response,
    });
  } catch (error) {
    ////consolelog("Request failed! Could not fetch values.", error);
  }
}

function* workPostAgreement({ values }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/agreement/save`;
    const response = yield call(postApi, uri, values);
    ////consolelog("response", response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.CREATE_AGREEMENT_SUCCESS,
      payload: response,
    });
    ////consolelog(values);
  } catch (error) {
    // Act on the error
    ////consolelog("Request failed! Could not create indent.", error);
  }
}

function* workFetchAgreements({ values, cb, duration }) {
  let { offset, q } = values;
  //consolelog(duration);
  console.log("inside saga", values);
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/agreement/search?duration=${duration || ""}&offset=${offset || 0}&q=${
        q || ""
      }`;
    const response = yield call(getApi, uri);

    
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_AGREEMENTS_SUCCESS,
      payload: response.data,
    });
    if (cb) cb(response);
    localStorage.setItem("agreementData", JSON.stringify(response));
  } catch (error) {
    // Act on the error
    console.log("Fetch Agreements Failed.", error);
  }
}

function* workFetchSignedURL({ callback }) {
  try {
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/upload/agreementSoftCopy/60d035d89392430f16acecfa`;
    const response = yield call(getApi, uri);
    ////consolelog("response---------", response);
    callback(response);
    yield put({
      type: types.FETCH_SIGNED_URL_SUCCESS,
      payload: response,
    });
  } catch (error) {
    ////consolelog("Fetch signed URL failed.", error);
  }
}

function* workUploadAgreement({ uri, data, headers }) {
  try {
    // Try to call the API
    const response = yield call(putUploadApi, uri, data, headers);
    //   Dispatch the action to the reducers
    yield put({
      type: types.UPLOAD_AGREEMENT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log(error);
  }
}

function* workAcceptInvoice({ values, callback }) {
  try {
    console.log("accept invoice saga log", values);
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/invoice/statusChange`;
    const response = yield call(postApi, uri, values);
    //consolelog("response", response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.ACCEPT_INVOICE_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could Not Accept Invoice.", error);
  }
}

function* workApproveInvoice({ invoiceID, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/invoice/statusChange`;
    const response = yield call(postApi, uri, {
      invoiceId: invoiceID,
      status: "approved",
    });
    //consolelog("response", response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.APPROVE_INVOICE_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could Not Approve Invoice.", error);
  }
}

function* workRejectInvoice({ values, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/invoice/statusChange`;
    const response = yield call(postApi, uri, values);
    //consolelog("response", response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.ACCEPT_INVOICE_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not Reject Invoice.", error);
  }
}

function* workFetchInvoices({ values, fromDate, endDate, state, callback }) {
  console.log("VALUES", values);
  let { offset, limit } = values;
  console.log(fromDate);
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/invoice/invoices?offset=${offset || 0}&limit=${limit || 10}&startDate=${
        fromDate || ""
      }&endDate=${endDate || ""}&stateCode=${state || ""}`;
    const response = yield call(getApi, uri);

    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_INVOICES_SUCCESS,
      payload: response.data,
    });
    callback(response)
    localStorage.setItem("invoiceData", JSON.stringify(response));
  } catch (error) {
    // Act on the error
    ////consolelog("Fetch Agreements Failed.", error);
  }
}

function* workFetchBills({ values, startDate, endDate, stateCode,callback }) {
  let { offset, limit } = values;
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/billing/bills?offset=${offset || 0}&limit=${limit || ""}&startDate=` +
      startDate +
      `&endDate=` +
      endDate +
      `&stateCode=` +
      stateCode;
    const response = yield call(getApi, uri);
    callback(response)
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_BILLS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    // Act on the error
    ////consolelog("Fetch Agreements Failed.", error);
  }
}

function* workCreateInvoice({ values, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/invoice/create`;

    const response = yield call(postApi, uri, values);

    callback(response);

    //   Dispatch the action to the reducers
    yield put({
      type: types.CREATE_INVOICE_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    ////consolelog("Create invoice Failed.", error);
  }
}

function* workFetchInvoiceByid({ invId, callback }) {
  try {
    // Try to call the API
    console.log(invId, "iasdjsdj");
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/invoice/viewInvoice?invoiceId=` +
      invId;

    const response = yield call(getApi, uri);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_INVOICE_BYID_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (error) {
    // Act on the error
    ////consolelog("Create invoice Failed.", error);
  }
}

function* workUpdateBill({ values, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/billing/updateBill`;

    const response = yield call(postApi, uri, values);

    callback(response);

    //   Dispatch the action to the reducers
    yield put({
      type: types.UPDATE_BILL_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    ////consolelog("Create invoice Failed.", error);
  }
}

function* workFetchAllAgreements({ offset, limit, callback }) {
  try {
    // Try to call the API
    ////consolelog(values,"iasdjsdj")
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/agreement/?offset=` +
      offset +
      `&limit=` +
      limit;
    const response = yield call(getApi, uri);
    callback(response);
    //   Dispatch the action to the reducers
    // yield put({
    //   type: types.FETCH_INVOICE_BYID_SUCCESS,
    //   payload: response,
    // });
  } catch (error) {
    // Act on the error
    //consolelog("Failed to fetch.", error);
  }
}

function* workAddNewBill({ values, callback }) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL + `/billing/addNewBillAfterEdit`;

    const response = yield call(postApi, uri, values);

    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.ADD_NEW_BILL_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    ////consolelog("Create invoice Failed.", error);
  }
}

function* workUpdateInvoice({ values, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/invoice/updateInvoice`;

    const response = yield call(postApi, uri, values);

    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.UPDATE_INVOICE_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Update invoice Failed.", error);
  }
}

export function* watchFetchViewAgreement() {
  yield takeEvery(types.VIEW_AGREEMENT, workfetchViewAgreement);
}

export function* watchFetchLastAgreement() {
  yield takeEvery(types.FETCH_LAST_AGREEMENT_VALUES, workfetchLastAgreement);
}

export function* watchPostAgreement() {
  yield takeEvery(types.CREATE_AGREEMENT, workPostAgreement);
}

export function* watchFetchAgreements() {
  yield takeEvery(types.FETCH_AGREEMENTS, workFetchAgreements);
}

export function* watchFetchSignedURL() {
  yield takeEvery(types.FETCH_SIGNED_URL, workFetchSignedURL);
}

export function* watchUploadAgreement() {
  yield takeEvery(types.UPLOAD_AGREEMENT, workUploadAgreement);
}

export function* watchFetchInvoices() {
  yield takeEvery(types.FETCH_INVOICES, workFetchInvoices);
}

export function* watchFetchBills() {
  yield takeEvery(types.FETCH_BILLS, workFetchBills);
}

export function* watchAcceptInvoice() {
  yield takeEvery(types.ACCEPT_INVOICE, workAcceptInvoice);
}

export function* watchApproveInvoice() {
  yield takeEvery(types.APPROVE_INVOICE, workApproveInvoice);
}

export function* watchRejectInvoice() {
  yield takeEvery(types.REJECT_INVOICE, workRejectInvoice);
}

export function* watchCreateInvoice() {
  yield takeEvery(types.CREATE_INVOICE, workCreateInvoice);
}
export function* watchFetchInvoiceByid() {
  yield takeEvery(types.FETCH_INVOICE_BYID, workFetchInvoiceByid);
}
export function* watchUpdateBill() {
  yield takeEvery(types.UPDATE_BILL, workUpdateBill);
}
export function* watchFetchAllAgreements() {
  yield takeEvery(types.COPY_AGREEMENT_LIST, workFetchAllAgreements);
}
export function* watchAddNewBill() {
  yield takeEvery(types.ADD_NEW_BILL, workAddNewBill);
}
export function* watchUpdateInvoice() {
  yield takeEvery(types.UPDATE_INVOICE, workUpdateInvoice);
}
function* BillingSaga() {
  yield all([
    fork(watchFetchViewAgreement),
    fork(watchFetchLastAgreement),
    fork(watchPostAgreement),
    fork(watchFetchAgreements),
    fork(watchFetchSignedURL),
    fork(watchUploadAgreement),
    fork(watchFetchInvoices),
    fork(watchFetchBills),
    fork(watchAcceptInvoice),
    fork(watchApproveInvoice),
    fork(watchRejectInvoice),
    fork(watchCreateInvoice),
    fork(watchFetchInvoiceByid),
    fork(watchUpdateBill),
    fork(watchFetchAllAgreements),
    fork(watchAddNewBill),
    fork(watchUpdateInvoice),
  ]);
}

export default BillingSaga;
