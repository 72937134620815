import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  FormGroup,
  InputGroup,
  Input,
} from "reactstrap";
import { MoonLoader } from "react-spinners";
import Breadcrumbs from "../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchUsers,
  fetchUsersfilters,
  UpdateUser,
} from "../store/auth/register/actions";
import { MDBDataTable } from "mdbreact";
import Pagination from "@material-ui/lab/Pagination";
import { getAccessForEntity } from "../pages/policies";
//import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import Chip from "@material-ui/core/Chip";
import { tableColumn } from "./Utility/userUtils";
import { NotificationManager } from "react-notifications";
import { Grid } from "@mui/material";

var data = {};
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idToDelete: "",
      open: false,
      rows: [],
      loading: false,
      policies: [],
      breadcrumbItems: [
        { title: "User", link: "user" },
        { title: "User Details", link: "#" },
      ],
      filters: "",
      key: "fullName",
      searchOn: false,
      nameFilter: "",
      emailFilter: "",
      phoneFilter: "",
    };
  }
  componentDidMount = () => {
    this.getAllData();
  };

  componentDidUpdate(prevProps) {
    if (this.props.policies !== prevProps.policies) {
      this.getAllData();
    }
  }

  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };

  hasAccessPolicy(val, entity) {
    const { policies } = this.props;
    let hasAccess = false;
    if (policies && policies.length > 0) {
      hasAccess = getAccessForEntity(policies, entity, val);
    }
    return hasAccess;
  }

  getAllData = () => {
    this.props.fetchUsers(offset, {}, (res) => {
      this.refineData(res);
      this.setState({ loading: false });
    });
  };

  refineData(res) {
    console.log(res);
    if (Array.isArray(res?.data?.docs)) {
      totCnt = res.data.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = res.data.offset + res.data?.docs?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      let rows = [];
      res.data.docs.forEach((ele, index) => {
        let itemObject = {
          phone: ele?.phone ? ele.phone : "-",
          fullName: ele?.name ? ele.name : "-",
          address: ele?.address ? ele.address : "-",
          city: ele?.city ? ele.city : "-",
          state: ele?.state ? ele.state : "-",
          zipCode: ele?.zipCode ? ele.zipCode : "-",
          email: ele?.email ? ele.email : "-",
          role: ele?.role?.label,
          hop: ele?.hops[0]?.label,
          status: ele?.isActive
            ? this.renderUserStatus("Active")
            : this.renderUserStatus("Inactive"),
        };
        if (
          this.props.user &&
          this.props.user.roleId &&
          this.props.user.roleId.name === "superadmin"
        )
          itemObject.action = this.renderSwitch(ele._id, index, ele.isActive);
        rows.push(itemObject);
      });
      this.setState({ rows, loading: false });
    } else this.setState({ loading: false });
  }

  handleUserActivation = (userId, index, e) => {
    const { rows } = this.state;
    const rowData = [...rows];
    rowData[index].status = e.target.checked
      ? this.renderUserStatus("Active")
      : this.renderUserStatus("Inactive");
    rowData[index].action = this.renderSwitch(userId, index, e.target.checked);
    this.setState({ loading: true });
    this.props.UpdateUser(userId, { isActive: e.target.checked }, () => {
      this.setState({ rows: rowData, loading: false });
    });
  };

  renderSwitch = (id, index, isActive) => {
    return (
      <Switch
        checked={isActive}
        onChange={(e) => this.handleUserActivation(id, index, e)}
        color="primary"
        name="userActivation"
      />
    );
  };

  renderUserStatus = (type) => {
    const baseStyleObject = {
      backgroundColor: type === "Active" ? "#28a745" : "#dc3545",
      color: "white",
    };
    return <Chip size="small" label={type} style={baseStyleObject} />;
  };

  handleClickOpen = (id) => {
    this.setState({ open: true, idToDelete: id });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChangePage = (event, val) => {
    this.setState({ loading: true });
    if (this.state.searchOn !== true) {
      offset = (val - 1) * process.env.REACT_APP_PERPAGE;
      this.getAllData();
    } else {
      offset = (val - 1) * process.env.REACT_APP_PERPAGE;
      this.props.fetchUsersfilters(
        offset,
        {
          name: this.state.nameFilter,
          email: this.state.emailFilter,
          phone: this.state.phoneFilter,
        },
        (res) => {
          this.refineData(res);
          this.setState({ loading: false });
        }
      );
    }
  };

  enterCheck = (event) => {
    if (event.key === "Enter") this.filterList();
  };

  filterList = () => {
    console.log("called");
    // if (this.state.filters.length > 2) {
    this.setState({ loading: true }, () => {
      if (this.state.searchOn !== true) this.setState({ searchOn: true });
      offset = 0;
      this.props.fetchUsersfilters(
        offset,
        // this.state.filters.trim(),
        {
          name: this.state.nameFilter,
          email: this.state.emailFilter,
          phone: this.state.phoneFilter,
        },
        (res) => {
          this.refineData(res);
          console.log(res, "compo");
          // this.setState({ loading: false });
          if (res.message && res.status) {
            if (res.status === "success")
              NotificationManager.success(res["message"], "Success");
            else NotificationManager.warning(res["message"], "Warning");
          }
        }
      );
    });
    // }
  };

  change(e) {
    let filters = e.target.value;
    this.setState({ filters });
  }

  filterAssigner = (e, filter) => {
    this.setState({ [filter]: e.target.value }, () => {
      // console.log(this.state.nameFilter);
    });
  };

  changed = (e) => {
    let key = e.target.value;
    this.setState({ key });
  };

  reset = (e) => {
    this.setState({ loading: true, searchOn: false });
    offset = 0;
    this.props.fetchUsers(offset, (res) => {
      this.refineData(res);
      this.setState({ nameFilter: "", phoneFilter: "", emailFilter: "" });
    });
  };

  render() {
    const { fullScreen, user } = this.props;
    const { loading, rows } = this.state;
    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#123abc"} />
          </Col>
        </div>
      );
    }

    // const optionList = [
    //   { key: "fullName", value: "Name" },
    //   { key: "email", value: "Email" },
    //   { key: "phone", value: "Phone" },
    //   { key: "zipCode", value: "Zip Code" },
    //   { key: "city", value: "City" },
    //   { key: "state", value: "State" },
    // ];

    data = { columns: tableColumn(), rows: rows };
    if (user && typeof user === "object") {
      if (user.roleId && user.roleId.name && user.roleId.name === "superadmin")
        data.columns.push({
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        });
    }
    return (
      <React.Fragment>
        <div /* className="page-content" */>
          <Container fluid>
            <div className="d-flex bd-highlight">
              <div className="p-2 flex-grow-1 bd-highlight">
                <h5
                  className="mt-1"
                  style={{
                    fontWeight: "700",
                    lineHeight: "20px",
                    size: "16px",
                    color: "#808080",
                  }}
                >
                  User Details
                </h5>
              </div>
              <div className="p-2 bd-highlight">
                <Link to="/add-user" className="small">
                  <Button
                    type="button"
                    color="btn btn-primary"
                    className="mb-1"
                  >
                    Add User
                  </Button>
                </Link>
              </div>
            </div>

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Grid container alignItems="center" className="mb-3">
                      {/* <Grid item xs={9} md={2}>
                        <h4 className="card-title"> User Details </h4>
                      </Grid> */}
                      <Grid item xs={12} sm={4} md={2}>
                        <InputGroup>
                          {/* <Input
                            className="form-control mr-2 mt-2"
                            type="select"
                            name="key"
                            placeholder="key"
                            aria-label="Search"
                            onChange={(e) => this.changed(e)}
                            value={this.state.key}
                          >
                            {optionList.map((item, index) => (
                              <option key={index} value={item.key}>
                                {item.value}
                              </option>
                            ))}
                          </Input> */}
                          <Input
                            className="form-control mr-2 mt-2"
                            type="text"
                            name="Name"
                            placeholder="Name"
                            aria-label="Search"
                            value={this.state.nameFilter}
                            onKeyDown={this.enterCheck}
                            onChange={(e) =>
                              this.filterAssigner(e, "nameFilter")
                            }
                          />
                        </InputGroup>
                      </Grid>
                      <Grid item xs={12} sm={4} md={2}>
                        <InputGroup>
                          <Input
                            className="form-control mr-2 mt-2"
                            type="text"
                            name="email"
                            placeholder="email"
                            aria-label="Search"
                            value={this.state.emailFilter}
                            onKeyDown={this.enterCheck}
                            onChange={(e) =>
                              this.filterAssigner(e, "emailFilter")
                            }
                          />
                        </InputGroup>
                      </Grid>
                      <Grid item xs={12} sm={8} md={2}>
                        <InputGroup>
                          <Input
                            className="form-control mr-2 mt-2"
                            type="text"
                            name="phone"
                            placeholder="phone"
                            aria-label="Search"
                            value={this.state.phoneFilter}
                            onKeyDown={this.enterCheck}
                            onChange={(e) =>
                              this.filterAssigner(e, "phoneFilter")
                            }
                          />
                        </InputGroup>
                      </Grid>
                      <Grid item md={2}></Grid>
                      <Grid item className="mr-2 mt-2">
                        <Button
                          type="button"
                          color="btn btn-primary"
                          onClick={this.filterList}
                          disabled={
                            this.state.nameFilter === "" &&
                            this.state.phoneFilter === "" &&
                            this.state.emailFilter === ""
                          }
                        >
                          Search
                        </Button>
                      </Grid>
                      <Grid item className="ml-2 mt-2">
                        <Button
                          type="button"
                          color="btn btn-primary"
                          onClick={this.reset}
                          disabled={this.state.searchOn === false}
                        >
                          Reset
                        </Button>
                      </Grid>
                    </Grid>
                    <MDBDataTable
                      className="text-center"
                      responsive
                      striped
                      bordered
                      data={data}
                      searching={false}
                      paging={false}
                    />
                    {totCnt !== 0 ? (
                      <Row xs={12}>
                        <Col xs={6}>
                          <span>
                            Showing {fromVal} to {toVal} of {totCnt} entries
                          </span>
                        </Col>
                        <Col xs={6}>
                          <div className="float-right">
                            <Pagination
                              page={offset / 10 + 1}
                              count={totPages}
                              color="primary"
                              onChange={this.handleChangePage}
                            />
                          </div>
                        </Col>
                      </Row>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.Login.user,
});

export default connect(mapStateToProps, {
  fetchUsers,
  fetchUsersfilters,
  UpdateUser,
})(User);
