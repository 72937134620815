
const getAccessForEntity = (policiesArr, entity, policy) => {
  return true
  let hasAccess = false;
  if (policiesArr.includes('All policies')) {
    hasAccess = true;
  }
  else {
    policiesArr.forEach(ele => {
      if (ele["entity"] === entity)
        hasAccess = ele[policy];
    });
  }
  //return hasAccess;
  
}

const getPolicies = (data) => {
  let policiesArr = [];
  if (data && data["name"] && data['name'] == 'superadmin') policiesArr.push('All policies');
  else {
    if (data && data.policies) {
      data.policies.forEach(ele => {
        policiesArr.push({ entity: ele.id.name, create: ele.create, read: ele.read, edit: ele.edit, delete: ele.delete });
      });
    }
  }
  return policiesArr;
}
export { getAccessForEntity, getPolicies }