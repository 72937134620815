import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
} from "reactstrap";
import "@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import formatInput from "../InputFormatter";
import { getUser } from "../../store/auth/login/actions";
import {
  createUser,
  fetchUsersfilters,
} from "../../store/auth/register/actions";
import { fetchRoles } from "../../store/accessPolicy/actions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {
  renderStateList,
  validateField,
  RenderInputControl,
} from "../Utility/userUtils";

class UserForm extends Component {
  constructor(props) {
    super(props);
    var arrBackup = [];
    var Timer = null;
    this.state = {
      onSubmitDisable: false,
      breadcrumbItems: [
        { title: "User", link: "user" },
        { title: "Add User", link: "#" },
      ],
      offset: 0,
      reportoArr: [],
      roleArr: [],
      loading: true,
      searchOn: false,
      filter: "",
      scroller: true,
      form: this.renderFormState("initial"),
      formErrors: {
        fullName: "",
        email: "",
        phone: "",
        zipCode: "",
        city: "",
        state: "",
        address: "",
        reportsTo: "",
        roleId: "",
      },
    };
    this.statesList = [this.initialVal("state"), ...renderStateList()];
  }

  componentDidMount() {
    this.getUsersOffset();
    this.getRolesOffset();
  }

  renderFormState = (type) => {
    const baseState = {
      fullName: "",
      email: "",
      phone: "",
      zipCode: "",
      city: "",
      state: "",
      address: "",
      reportsTo: "",
      roleId: "",
      isActive: true,
    };
    if (type === "reset") baseState.isActive = false;
    return baseState;
  };

  initialVal = (val) => {
    return { label: "Select " + val, value: "", isDisabled: true };
  };

  validateForm = (form, formErrors, validateFunc) => {
    const errorObj = {};
    Object.keys(formErrors).forEach((x) => {
      let refValue = null;
      const msg = validateFunc(x, form[x], refValue);
      if (msg) errorObj[x] = msg;
    });
    return errorObj;
  };

  handleSubmit = () => {
    this.setState({ onSubmitDisable: true });
    const { form, formErrors } = this.state;
    const errorObj = this.validateForm(form, formErrors, validateField);
    if (Object.keys(errorObj).length !== 0) {
      this.setState({
        onSubmitDisable: false,
        formErrors: { ...formErrors, ...errorObj },
      });
      return false;
    }
    let formVal = { ...form };
    formVal.password = "test"; //remove afterwards
    this.props.createUser(formVal, (res) => {
      if (
        res["message"] &&
        res["message"] === "User has been created successfully"
      ) {
        NotificationManager.success(res["message"], "Success");
        this.props.history.push("/user");
      } else {
        this.setState({ onSubmitDisable: false });
        NotificationManager.error(res["message"], "Error");
      }
    });
  };

  reset = () => {
    const formData = this.renderFormState("reset");
    this.setState({ form: formData });
  };

  validateNumber = (evt) => {
    var theEvent = evt || window.event;
    // Handle paste
    if (theEvent.type === "paste") {
      var key = theEvent.clipboardData.getData("text/plain");
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    const { form, formErrors } = this.state;
    let formObj = {};
    if (name === "zipCode") {
      value = value ? parseInt(value) : "";
    }
    formObj = {
      ...form,
      [name]: value,
    };
    this.setState({ form: formObj }, () => {
      if (!Object.keys(formErrors).includes(name)) return;
      let formErrorsObj = {};
      const errorMsg = validateField(
        name,
        name === "language" ? this.state.form["language"] : value
      );
      formErrorsObj = { ...formErrors, [name]: errorMsg };
      this.setState({ formErrors: formErrorsObj });
    });
  };

  trimSpace = (event, name) => {
    const { form } = this.state;
    let formObj = formatInput(event, name, form);
    this.setState({ form: formObj });
  };

  getUsersOffset = () => {
    this.props.getUser(this.state.offset, (res) => {
      let reportoArrval = [];
      if (Array.isArray(res.docs)) {
        res.docs.forEach((ele) => {
          reportoArrval.push({ value: ele._id, label: ele.fullName });
        });
      }
      this.setState({
        loading: false,
        reportoArr: [...this.state.reportoArr, ...reportoArrval],
      });
      this.arrBackup = this.state.reportoArr;
    });
  };

  getRolesOffset = () => {
    this.props.fetchRoles(this.state.offset, (res) => {
      let roleArr = [];
      if (Array.isArray(res.docs)) {
        res.docs.forEach((ele) => {
          if (ele.name === "indent") {
            if (/true/i.test(process.env.REACT_APP_INDENT) === false) {
              // console.log("if1")
              return;
            } else {
              roleArr.push({ value: ele._id, label: ele.name });
            }
          }
          if (ele.name === "transporter") {
            if (/true/i.test(process.env.REACT_APP_TRANSPORTER) === false) {
              // console.log("if2")
              return;
            } else {
              roleArr.push({ value: ele._id, label: ele.name });
            }
          } else {
            // console.log("else")
            roleArr.push({ value: ele._id, label: ele.name });
          }
        });
        // this.setState({ roleArr });
      }
      const baseState = { loading: false };
      if (roleArr.length > 0) {
        baseState.roleArr = roleArr;
      }
      this.setState(baseState);
    });
  };

  scrollSelectBottom = (e) => {
    let offsetval = this.state.offset;
    if (this.state.searchOn === false) {
      this.setState({ offset: offsetval + 100, loading: true }, () => {
        this.getUsersOffset();
      });
    } else if (this.state.scroller === true) {
      this.setState(
        { offset: offsetval + 10, loading: true },
        () => {
          this.props.fetchUsersfilters(
            this.state.offset,
            this.state.filter,
            "fullName",
            (res) => {
              this.refineData(res);
              if (res.status === "error") {
                this.setState({ scroller: false });
              }
            }
          );
        },
        () => {
          this.setState({ loading: false });
        }
      );
    }
  };

  refineData = (res) => {
    let repArr = [];
    if (Array.isArray(res.docs)) {
      res.docs.forEach((ele) => {
        repArr.push({ value: ele._id, label: ele.fullName });
      });
    }
    this.setState({
      reportoArr: [...this.state.reportoArr, ...repArr],
      loading: false,
    });
  };

  filterList = (e) => {
    if (this.state.scroller === false) this.setState({ scroller: true });
    this.setState({ filter: e });
    if (this.Timer) {
      clearTimeout(this.Timer);
    }
    this.Timer = setTimeout(() => {
      if (e.length > 2) {
        this.setState({ loading: true, offset: 0, reportoArr: [] }, () => {
          if (this.state.searchOn !== true) this.setState({ searchOn: true });
          this.props.fetchUsersfilters(
            this.state.offset,
            this.state.filter,
            "fullName",
            (res) => {
              this.refineData(res);
            }
          );
        });
      }
      if (e.length < 3 && this.state.searchOn === true) {
        this.setState({ searchOn: false, offset: 0 });
        this.props.getUser(this.state.offset, (res) => {
          let reportoArrval = [];
          if (Array.isArray(res.docs)) {
            res.docs.forEach((ele) => {
              reportoArrval.push({ value: ele._id, label: ele.fullName });
            });
          }
          this.setState({
            loading: false,
            reportoArr: reportoArrval,
          });
          this.arrBackup = this.state.reportoArr;
        });
      }
      if (e === "") {
        this.setState({ reportoArr: this.arrBackup });
      }
    }, 700);
  };

  menuClose = (e) => {
    if (this.state.scroller === false) this.setState({ scroller: true });
    if (this.state.offset !== 0 && this.state.searchOn === true) {
      this.setState(
        {
          offset: 0,
          reportoArr: [],
          searchOn: false,
          filter: "",
          loading: true,
        },
        () => {
          this.arrBackup = [];
          this.getUsersOffset();
        }
      );
    }
  };

  render() {
    const { form, formErrors, onSubmitDisable, loading } = this.state;

    return (
      <React.Fragment>
        <div className="">
          <Container fluid>
            <Breadcrumbs
              title={`Add user`}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <form>
                      <Row>
                        <Col lg="6">
                          <RenderInputControl
                            controlType="input"
                            inputType="text"
                            inputName="fullName"
                            inputValue={form.fullName}
                            onChangeMethod={this.handleChange}
                            placeholderValue="Enter Full Name Here..."
                            handleBlur={this.trimSpace}
                            labelValue="Full Name"
                            isRequired={true}
                            errors={formErrors}
                          />
                        </Col>
                        <Col lg="6">
                          <RenderInputControl
                            controlType="select"
                            inputName="reportsTo"
                            inputValue={this.state.reportoArr.find(
                              (x) => x.value === form.reportsTo
                            )}
                            onInputChange={this.filterList}
                            onChangeMethod={this.handleChange}
                            optionList={this.state.reportoArr}
                            handleScrollBottom={this.scrollSelectBottom}
                            labelValue="Report To"
                            isRequired={true}
                            handleMenuClose={this.menuClose}
                            errors={formErrors}
                            isLoading={loading}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <RenderInputControl
                            controlType="select"
                            inputName="roleId"
                            inputValue={this.state.roleArr.find(
                              (x) => x.value === form.roleId
                            )}
                            onChangeMethod={this.handleChange}
                            optionList={this.state.roleArr}
                            labelValue="Role"
                            isRequired={true}
                            errors={formErrors}
                          />
                        </Col>
                        <Col lg="6">
                          <RenderInputControl
                            controlType="input"
                            inputType="text"
                            inputName="email"
                            inputValue={form.email}
                            onChangeMethod={this.handleChange}
                            placeholderValue="Enter Email Here..."
                            handleBlur={this.trimSpace}
                            labelValue="Email"
                            isRequired={true}
                            errors={formErrors}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <RenderInputControl
                            controlType="input"
                            inputType="text"
                            inputName="phone"
                            inputValue={form.phone}
                            onChangeMethod={this.handleChange}
                            placeholderValue="Enter Phone Number Here..."
                            handleKeyPress={this.validateNumber}
                            labelValue="Phone"
                            isRequired={true}
                            errors={formErrors}
                          />
                        </Col>
                        <Col lg="6">
                          <RenderInputControl
                            controlType="input"
                            inputType="text"
                            inputName="address"
                            inputValue={form.address}
                            onChangeMethod={this.handleChange}
                            placeholderValue="Enter Address Here..."
                            handleBlur={this.trimSpace}
                            labelValue="Address"
                            isRequired={false}
                            errors={formErrors}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <RenderInputControl
                            controlType="input"
                            inputType="text"
                            inputName="city"
                            inputValue={form.city}
                            onChangeMethod={this.handleChange}
                            placeholderValue="Enter City Name Here..."
                            handleBlur={this.trimSpace}
                            labelValue="City"
                            isRequired={true}
                            errors={formErrors}
                          />
                        </Col>
                        <Col lg="6">
                          <RenderInputControl
                            controlType="select"
                            inputName="state"
                            inputValue={this.statesList.find(
                              (x) => x.value === form.state
                            )}
                            onChangeMethod={this.handleChange}
                            optionList={this.statesList}
                            labelValue="State"
                            isRequired={true}
                            errors={formErrors}
                          />
                        </Col>
                        {/* <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">
                              Dealer Code
                            </Label>
                            <Input
                              className="form-control"
                              type="text"
                              value={form.dealerCode}
                              onBlur={(e) => this.trimSpace(e, "dealerCode")}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "dealerCode",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="ex: 500229															"
                            />
                            {formErrors.dealerCode && (
                              <span className="err">
                                {formErrors.dealerCode}
                              </span>
                            )}
                          </FormGroup>
                        </Col> */}
                      </Row>
                      <Row>
                        <Col lg="6">
                          <RenderInputControl
                            controlType="input"
                            inputType="number"
                            inputName="zipCode"
                            inputValue={form.zipCode}
                            onChangeMethod={this.handleChange}
                            placeholderValue="Enter ZipCode Here..."
                            handleBlur={this.trimSpace}
                            labelValue="Zip Code"
                            isRequired={true}
                            errors={formErrors}
                          />
                        </Col>
                        {this.props.user &&
                          this.props.user.roleId &&
                          this.props.user.roleId.name &&
                          this.props.user.roleId.name === "superadmin" && (
                            <Col lg={6} className="d-flex align-items-end">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={form.isActive}
                                    onChange={(e) =>
                                      this.handleChange({
                                        target: {
                                          name: "isActive",
                                          value: e.target.checked,
                                        },
                                      })
                                    }
                                    name="isActive"
                                    color="primary"
                                  />
                                }
                                label="Mark Active"
                              />
                            </Col>
                          )}
                      </Row>
                      <FormGroup className="mb-0 text-center ">
                        <Button
                          disabled={onSubmitDisable}
                          type="reset"
                          color="secondary"
                          onClick={this.reset}
                          className="mr-2"
                        >
                          Reset
                        </Button>
                        <Button
                          disabled={onSubmitDisable}
                          type="button"
                          color="btn btn-primary"
                          onClick={this.handleSubmit}
                        >
                          Submit
                        </Button>
                      </FormGroup>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.Login.user,
});

export default connect(mapStateToProps, {
  getUser,
  fetchRoles,
  createUser,
  fetchUsersfilters,
})(UserForm);
