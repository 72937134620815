import React, { Component } from "react";
import { Row, Col, Card, CardBody, TabContent, Container } from "reactstrap";
import { Link } from "react-router-dom";
import IndentProgressBar from "./IndentProgressBar";
import RouteDetailTab from "./RouteDetailTab";
import OrderDetailTab from "./OrderDetailTab";
import VehiclePlanningTab from "./VehiclePlanningTab";
import ConfirmDetailTab from "./CofirmDetailTab";
import { connect } from "react-redux";

class IndentLayout extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      activeTabProgress: 1,
      progressValue: 25,
      nextDisabled: true,
    };
  }

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 5) {
        this.setState({
          activeTab: tab,
        });
      }
    }
  };

  nextDisabler = (value) => {
    this.setState({ nextDisabled: value });
  };

  checkValid = (obj) => {
    let flag = 0;
    for (var key in obj) {
      if (
        key === "destination" &&
        this.props.indents.routeDetail.checkDestinationOmit
      )
        continue;
      if (obj[key] === "" || obj[key] === undefined || obj[key].length === 0) {
        flag++;
      }
    }
    if (flag > 0) {
      this.nextDisabler(true);
    } else this.nextDisabler(false);
  };

  tabCheck = (tab) => {
    if (tab >= 1 && tab <= 5) {
      this.setState({
        nextDisabled: true,
        activeTabProgress: tab,
      });
      if (tab === 1) {
        this.setState({ progressValue: 25 });
        this.checkValid(this.props.indents.routeDetail);
      }
      if (tab === 2) {
        this.setState({ progressValue: 50 });
        this.checkValid(this.props.indents.orderDetail);
      }
      if (tab === 3) {
        this.setState({ progressValue: 75 });
        this.checkValid(this.props.indents.vehicleDetail);
      }
      if (tab === 4) {
        this.setState({ progressValue: 100 });
      }
    }
  };

  toggleTabProgress = (tab, dir) => {
    if (
      dir === "next" &&
      this.state.activeTabProgress !== tab &&
      this.state.nextDisabled === false
    ) {
      this.tabCheck(tab);
    } else if (dir === "prev") this.tabCheck(tab);
    //else error message(state)
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container>
            <h6 className="mt-2 mb-4">INDENT TRIP DETAILS</h6>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div id="progrss-wizard" className="twitter-bs-wizard">
                      <IndentProgressBar
                        toggleTabProgress={this.toggleTabProgress}
                        toggleTab={this.toggleTab}
                        State={this.state}
                      />

                      <TabContent
                        activeTab={this.state.activeTabProgress}
                        className="twitter-bs-wizard-tab-content"
                      >
                        <RouteDetailTab checkValid={this.checkValid} />
                        <OrderDetailTab checkValid={this.checkValid} />
                        <VehiclePlanningTab checkValid={this.checkValid} />
                        <ConfirmDetailTab />
                      </TabContent>
                      <ul className="pager wizard twitter-bs-wizard-pager-link">
                        <li
                          className={
                            this.state.activeTabProgress === 1
                              ? "previous disabled"
                              : "previous"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              this.toggleTabProgress(
                                this.state.activeTabProgress - 1,
                                "prev"
                              );
                            }}
                          >
                            Previous
                          </Link>
                        </li>
                        <li
                          className={
                            this.state.activeTabProgress === 5 ||
                            this.state.nextDisabled
                              ? "next disabled"
                              : "next"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              this.toggleTabProgress(
                                this.state.activeTabProgress + 1,
                                "next"
                              );
                            }}
                          >
                            Next
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  indents: state.Indent.indents,
});

export default connect(mapStateToProps, {})(IndentLayout);
