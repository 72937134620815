import React, { Component } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Datetime from "react-datetime";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { fetchHops, createIndent } from "../../store/indent/actions";
import { fetchProductNames } from "../../store/order/actions";
import formatInput from "../InputFormatter";
import { MoonLoader } from "react-spinners";
import IndentLayout from "./CommonResources/Indent/IndentLayout";
import IndentDetails from "./CommonResources/Indent/indentDetails";

class IndentForm extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        {/* <IndentLayout /> */}
        <IndentDetails {...this.props} />
      </React.Fragment>
    );
  }
}

export default connect(null, {
  fetchHops,
  fetchProductNames,
  createIndent,
})(IndentForm);
