import { types } from "./actionTypes";
export const fetchReports = (offset, limit, values) => ({
  type: types.FETCH_REPORTS,
  offset,
  limit,
  values,
});


export const fetchIndentLaneReports = (offset, filters, callback) => ({
  type: types.FETCH_INDENTLANE_REPORTS,
  offset,
  filters,
  callback
 
});

export const fetchIndentStatusReports = (offset, filters, callback) => ({
  type: types.FETCH_INDENTSTATUS_REPORTS,
  offset,
  filters,
  callback
});

export const fetchIndentTransporterReports = (offset, filters, callback) => ({
  type: types.FETCH_INDENTTRANSPORTER_REPORTS,
  offset,
  filters,
  callback
});

export const fetchTransporterPerformance = (offset, limit, values, callback) => ({
  type : types.FETCH_TRANSPORTER_PERFORMANCE,
  offset,
  limit,
  values,
  callback
})