// import { mapKeys } from "lodash";
import { types } from "../actionTypes";

const initialState = {
  drivers: [],
  fetchId: "",
  consentStatus: false,
};

export default (state = { initialState }, action) => {
  switch (action.type) {
    case types.FETCH_DRIVERS_SUCCESS:
      return { ...state, drivers: action.payload,fetchId: "id" + new Date().getTime() };
    case types.FETCH_AVAIL_DRIVERS_SUCCESS:
      return { availDrivers: action.payload };
    case types.FETCH_DRIVER_BYID_SUCCESS:
      return { ...state, editDriver: action.payload };
    case types.EDIT_DRIVER_BYID_SUCCESS:
      return { ...state, editDriverRes: action.payload };
    case types.CREATE_DRIVER_SUCCESS:
      return { ...state, [action.payload.id]: action.payload };
    case types.DELETE_DRIVER_SUCCESS:
      var newState = { ...state };
      delete newState[action.payload.id];
      return newState;
    case types.SUBSCRIBE_CONSENT_SUCCESS:
      console.log(action.payload, "DRIVER PAYLOAD");
      if (action.payload?.data?.data?.status === "ALLOWED")
        return { ...state, consentStatus: true };
      else return { ...state, consentStatus: false };
    case types.UNSUBSCRIBE_CONSENT_SUCCESS:
      console.log(action.payload, "DRIVER PAYLOAD");
      // if (action.payload.data.data.status === "ALLOWED")
      return { ...state, consentStatus: true };
    // else return { ...state, consentStatus: false };
    default:
      state = { ...state };
      break;
  }
  return state;
};
