import React, { Component } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  FormGroup,
  InputGroup,
  Input,
} from "reactstrap";
import { NotificationManager } from "react-notifications";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";
import Breadcrumbs from "../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { MoonLoader } from "react-spinners";
import {
  fetchRoles,
  deleteRole,
  fetchRolesfilter,
} from "../store/accessPolicy/actions";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { getAccessForEntity } from "../pages/policies";
//import { Grid } from "@material-ui/core";
import { Grid } from "@mui/material";

var data = {};
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var isSuperAdmin = false;

class Role extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idToDelete: "",
      open: false,
      loading: true,
      breadcrumbItems: [
        { title: "Role", link: "role" },
        { title: "Role Details", link: "#" },
      ],
      filters: "",
      editAccess: false,
      delAccess: false,
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.policies !== prevProps.policies) {
      this.getAllData();
    }
  }
  componentDidMount = () => {
    this.getAllData();
  };

  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };

  hasAccessPolicy(val, entity) {
    const { policies } = this.props;
    let hasAccess = false;

    if (policies && policies.length > 0) {
      if (policies.includes("All policies")) isSuperAdmin = true;

      hasAccess = getAccessForEntity(policies, entity, val);
    }
    return hasAccess;
  }
  getAllData() {
    this.props.fetchRoles(offset, (res) => {
      console.log("component get all data", res);
      this.refineData(res);
      this.setState({ loading: false });
    });
  }

  refineData = (res) => {
    if (Array.isArray(res.data)) {
      totCnt = res.length;
      // fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      // toVal = res.offset + res.docs.length;
      // totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      let rows = [];
      let editAccess = this.hasAccessPolicy("edit", "role");
      let delAccess = this.hasAccessPolicy("delete", "role");
      this.setState({ editAccess, delAccess });
      res.data.forEach((ele, i) => {
        let actions =
          editAccess || delAccess ? (
            <div disabled={ele.name === "superadmin"}>
              {delAccess ? (
                <span>
                  <i
                    onClick={() => this.handleClickOpen(ele._id)}
                    className="fas fa-trash"
                    style={{ cursor: "pointer" }}
                  ></i>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              ) : null}

              {editAccess ? (
                <span>
                  <Link to={`/edit-role-${ele._id}`}>
                    <i className="fas fa-pencil-alt"></i>
                  </Link>
                </span>
              ) : null}
            </div>
          ) : (
            ""
          );
        rows.push({
          name:
            ele.label.replace(/_/g, " ").charAt(0).toUpperCase() +
            ele.label.replace(/_/g, " ").slice(1),
          description: ele.description,
        });
        Object.keys(rows[i]).map((key) => {
          if (key !== "actions") {
            rows[i][key] = rows[i][key] ? rows[i][key] : "-";
          } else {
            if (!editAccess && !delAccess) delete rows[i][key];
          }
        });
      });
      this.setState({ rows });
    }
  };
  handleClickOpen = (id) => {
    this.setState({ open: true, idToDelete: id });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handledeleteRole = () => {
    this.handleClose();
    this.props.deleteRole(this.state.idToDelete, (res) => {
      if (res["message"] && res["message"] === "roleGroup has been deleted") {
        NotificationManager.success(res["message"], "Success");
        this.getAllData();
      } else {
        NotificationManager.error("Something went wrong!", "Error");
      }
    });
  };
  handleChangePage = (event, val) => {
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    this.getAllData();
  };

  filterList = (e) => {
    // console.error(e,"e")

    if (this.state.filters.length > 2) {
      // console.error(e,"e")
      this.setState({ loading: true });
      this.props.fetchRolesfilter(offset, this.state.filters, (res) => {
        console.log("component", res);
        this.refineData(res);
      });
    } else {
      this.getAllData();
    }
  };
  change(e) {
    let filters = e.target.value;
    this.setState({ filters });
  }
  render() {
    const { fullScreen } = this.props;
    const { loading, rows, editAccess, delAccess } = this.state;
    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#123abc"} />
          </Col>
        </div>
      );
    }
    data = {
      columns: [
        {
          label: "Roles",
          field: "name",
          sort: "asc",
          width: 100,
        },
        {
          label: "Descriptions",
          field: "description",
          sort: "asc",
          width: 150,
        },
      ],
      rows: rows,
    };
    // if (!editAccess && !delAccess) data.columns.pop(-1);

    return (
      <React.Fragment>
        <div /* className="page-content" */>
          <Container fluid>
            <div className="d-flex bd-highlight">
              <div className="p-2 flex-grow-1 bd-highlight">
                <h5
                  className="mt-1"
                  style={{
                    fontWeight: "700",
                    lineHeight: "20px",
                    size: "16px",
                    color: "#808080",
                  }}
                >
                  Role Details
                </h5>
              </div>
              <div className="p-2 bd-highlight">
                {this.hasAccessPolicy("create", "role") ? (
                  <FormGroup className="mb-2 text-right">
                    <div>
                      <Link to="/add-role" className="small">
                        {" "}
                        <Button type="button" color="btn btn-primary">
                          Add Role
                        </Button>
                      </Link>{" "}
                    </div>
                  </FormGroup>
                ) : null}
              </div>
            </div>

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Grid container>
                      {/* <Grid item xs={4} md={6}>
                        <h4 className="card-title"> Role Details </h4>
                      </Grid> */}
                      <Grid item xs={1} md={1}></Grid>
                      <Grid item xs={4} md={4}>
                        <InputGroup>
                          <Input
                            className="form-control"
                            type="text"
                            name="filters"
                            placeholder="Search"
                            aria-label="Search"
                            onChange={(e) => this.change(e)}
                          />
                        </InputGroup>
                      </Grid>
                      <Grid item xs={1} md={1}>
                        <Button
                          type="button"
                          color="btn btn-primary"
                          onClick={this.filterList}
                        >
                          FILTER
                        </Button>
                      </Grid>
                    </Grid>

                    <MDBDataTable
                      className="text-center"
                      responsive
                      striped
                      bordered
                      data={data}
                      searching={false}
                      paging={false}
                    />

                    {totCnt !== 0 ? (
                      <Row xs={12}>
                        <Col xs={6}>
                          <span>
                            Showing {fromVal} to {toVal} of {totCnt} entries
                          </span>
                        </Col>
                        <Col xs={6}>
                          <div className="float-right">
                            <Pagination
                              page={offset / 10 + 1}
                              count={totPages}
                              color="primary"
                              onChange={this.handleChangePage}
                            />
                          </div>
                        </Col>
                      </Row>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>

          <Dialog
            fullScreen={fullScreen}
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">Confirm</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete the role?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary" autoFocus>
                Cancel
              </Button>
              <Button onClick={this.handledeleteRole} color="">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  policies: state.AccessPolicy.accessPolicies.policies,
});

export default connect(mapStateToProps, {
  fetchRoles,
  deleteRole,
  fetchRolesfilter,
})(Role);
