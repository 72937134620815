import React, { Component } from "react";
//import from libraries
import { Row, Col, Card, Button } from "reactstrap";
import Select, { components } from "react-select";
import { DateRangePicker } from "rsuite";
// import images
import calendarIcon from "./assets/calendar.png";
import calendarDarkIcon from "../Trips/assets/calendarDark.png";
import download from "./assets/download.png";
import downloadDark from "./assets/downloadDark.png";
import refreshIcon from "./assets/refresh-cw.png";

import "rsuite/dist/rsuite.min.css";
import "../Dashboard/OperationsDashboard/Styles/dashboard.css";

let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
var darkMode
const MyIcon = React.forwardRef((props, ref) => {
  return (
    <img  className="reportsListStyle" src={darkMode?calendarDarkIcon:calendarIcon} />
  );
});


const DropdownIndicator = props => {
  const iconSvg = props.selectProps.selectProps
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {iconSvg}
      </components.DropdownIndicator>
    )
  );
};

class QuickFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hops: [],
      vehicleTypes: [],
      // searchBar: [],
      searchFilters: {},
      dateValue: [null, null],
      showCalender: false,
      userLangs: undefined,
    };
  }
  handleClickAway = () => {
    if (this.state.showCalender == true) {
      this.setState({ showCalender: false });
    }
  };
  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (this.state.userLangs !== userLang || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }
  render() {
     darkMode = this.props?.mode
    const customStyles1 = {
      option: (styles, state) => ({
        ...styles,
        cursor: "pointer",
      }),
      input: base => ({
        ...base,
        color: darkMode?"#9d9d9d":"#505d69"
      }),
      menu: ({ width, ...css }) => ({
        ...css,
        cursor: "pointer",
        width: "100%",
        backgroundColor: darkMode ? "#171722" : "#ffffff",
        border: darkMode ? "0.5px solid #616161" : "0.5px solid rgb(204, 204, 204)",
        boxShadow: darkMode ? "0px 3px 12px rgba(0, 0, 0, 0.12)" : "0px 3px 12px rgba(0, 0, 0, 0.12)",
        // minWidth: "100%",
      }),
      control: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "26px",
          width:
            this.props.selectedTranspoterValues?.length >= 2 ? "100%" : "100%",
          minWidth: "100%",
          minHeight: "26px",
          flexWrap: "nowrap",
          backgroundColor: darkMode ? "#2E2E46" : ""
        };
      },
      placeholder: (style) => {
        return {
          ...style,
          cursor: "pointer",
          marginTop: "2px",
          height: "26px",
          minHeight: "26px",
        };
      },
      indicatorsContainer: (style) => {
        return {
          ...style,
          cursor: "pointer",
          // backgroundColor: "#8e9af8",
          height: "26px",
          minHeight: "26px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "10px",
          minHeight: "10px",
          backgroundColor: "#cccccc",
          marginTop: "8px",
        };
      },
      dropdownIndicator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "26px",
          minHeight: "26px",
          // backgroundColor: "#0066cc",
        };
      },
    };
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    return (
      <React.Fragment>
        <Card className="darkBgQuickFilter">
          <div className="quickStylesBar reportQuickStyleBar">
            <Row>
              <Col xs={0} md={6} lg={8}></Col>
              <Col xs={12} className="mobileDisplay"
                style={{
                  paddingRight: "0px"
                }}>
                <div className="d-flex justify-content-end">
                  <div>
                    <Button
                      style={{
                        minWidth: "70%",
                      }}
                      className="mobile-reset reset"
                      onClick={() => this.props?.reset()}
                    >
                      <img className="mobile-rest-img" src={refreshIcon}></img>
                    </Button>
                  </div>
                  <div style={{}} className="mr-1">
                    <div className="tooltipIcon QuickFilters">
                    <div className="datepicker-selector-mobile iconButtons mobileCalender actionButtonsDark mr-2 datepicker-custom"
                        style={{ position: "relative", top: "9px" }}>
                        <DateRangePicker
                          className="datePickerIcon"
                          size="md"
                          placeholder=" "
                          block
                          format="dd MMM yy"
                          showOneCalendar
                          placement="bottom"
                          preventOverflow
                          value={this.state.dateValue || [null, null]}
                          onChange={(newValue) => {
                            console.log(newValue)
                            this.props.handleChange(newValue)
                          }}
                          caretAs={MyIcon}
                        />
                      </div>
                      <span className="tooltipIcontext">
                        &nbsp;{this.state.userLangs?.common?.CALENDER}&nbsp;
                      </span>
                    </div>
                  </div>
                  <div style={{}} className="">
                    <div className="tooltipIcon">
                      <Button
                        onClick={() => {
                          this.props.exportToCSV(
                            [...this.props.vehiclesReports],
                            "Excel_Reports_Data"
                          );
                        }}
                        className="iconButtons actionButtonsDark"
                      >
                        <img
                          style={{ margin: "-4px" }}
                          src={download}
                          alt="exp"
                        />
                      </Button>
                      <span
                        className="tooltipIcontext"
                        style={{ width: "55px" }}
                      >
                        &nbsp;{this.state.userLangs?.invoice?.EXPORT}&nbsp;
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={4} lg={2} className="">
                <div className="mt-2">
                  <Select
                    className="transpoter-react_selector"
                    classNamePrefix="react-select"
                    placeholder="Select Transporters..."
                    autoFocus={true}
                    isSearchable
                    styles={customStyles1}
                    components={{ DropdownIndicator }}
                    selectProps={<svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
                      <path d="M12 12.906V11.583C12 10.8812 11.7212 10.2082 11.225 9.71201C10.7288 9.21579 10.0558 8.93701 9.35399 8.93701H4.06299C3.36123 8.93701 2.68821 9.21579 2.19199 9.71201C1.69577 10.2082 1.41699 10.8812 1.41699 11.583V12.906" stroke={darkMode ? "#B7A9FF" : "#2800FC"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M6.5 6C7.88071 6 9 4.88071 9 3.5C9 2.11929 7.88071 1 6.5 1C5.11929 1 4 2.11929 4 3.5C4 4.88071 5.11929 6 6.5 6Z" stroke={darkMode ? "#B7A9FF" : "#2800FC"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>}
                    style={{ width: "82%" }}
                    value={this.props.selectedTranspoterValues}
                    options={this.props.transpoterOptions}
                    onChange={this.props.onChangeTranspoter}
                    onInputChange={(e)=>this.props.filtersTranspoter(e)}
                  />
                </div>
              </Col>
              <Col
                xs={5}
                md={2}
                lg={2}
                className=" desktopDisplay"
              >
                <div className="d-flex justify-content-end reportflex">

                  <div
                    style={{ width: "100%", float: "right" }}
                    className="desktopResetButton"
                  >
                    <Button
                      onClick={() => {
                        this.setState(
                          {
                            dateValue: [null, null],
                          },
                          () => {
                            this.props.reset();
                          }
                        );
                      }}
                      className="reset"
                    >
                      {this.state.userLangs?.common?.RESET}
                    </Button>

                  </div>
                  {/* </Col>68px
              <Col sm={1}> */}
                  <div style={{ width: "40%", float: "right" , marginLeft:"15px"}} className="mr-1">
                    <div className="tooltipIcon QuickFilters">
                      <div className="datepicker-selector-mobile iconButtons mobileCalender actionButtonsDark mr-2 datepicker-custom"
                        style={{ position: "relative", top: "9px" }}>
                        <DateRangePicker
                          className="datePickerIcon"
                          size="md"
                          placeholder=" "
                          block
                          format="dd MMM yy"
                          showOneCalendar
                          placement="bottom"
                          preventOverflow
                          value={this.state.dateValue || [null, null]}
                          onChange={(newValue) => {
                            console.log(newValue)
                            this.props.handleChange(newValue)
                          }}
                          caretAs={MyIcon}
                        />
                      </div>
                      <span className="tooltipIcontext">
                        &nbsp;{this.state.userLangs?.common?.CALENDER}&nbsp;
                      </span>
                    </div>


                  </div>
                  <div style={{ width: "40%", float: "right" }} className="">
                    <div className="tooltipIcon">
                      <Button
                        disabled={!navigator.onLine}
                        onClick={() => {
                          this.props.exportToCSV(
                            [...this.props.vehiclesReports],
                            "Excel_Reports_Data"
                          );
                        }}
                        className={`iconButtons ${darkMode ? 'actionButtonsDark' : ''}`}
                      >
                        <img
                          style={{ margin: "-4px" }}
                          src={darkMode ? downloadDark : download}
                          alt="exp"
                        />
                      </Button>
                      <span
                        className="tooltipIcontext"
                        style={{ width: "55px" }}
                      >
                        &nbsp;{this.state.userLangs?.invoice?.EXPORT}&nbsp;
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </React.Fragment >
    );
  }
}

export default QuickFilters;
