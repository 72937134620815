import React, { Component } from "react";
import { Row, Col, Container } from "reactstrap";
import { MoonLoader } from "react-spinners";
import { connect } from "react-redux";
import {
  fetchUsers,
  fetchUsersfilters,
  UpdateUser,
  fetchUserDetails,
} from "../../store/auth/register/actions";
import Pagination from "@material-ui/lab/Pagination";
import { getAccessForEntity } from "../../pages/policies";
import Switch from "@material-ui/core/Switch";
import { tableColumn } from "../Utility/userUtils";
import { NotificationManager } from "react-notifications";

import QuickFilters from "./QuickFilters";
import Index from "../../components/Common/DataTableNeo/Index";
import {
  HeaderData,
  AdminHeaderData,
  searchBar,
  getLocalStorage,
} from "./RowHeaders/RowHeaders";
import { fetchHops } from "../../store/hop/actions";
import { fetchRoles } from "../../store/accessPolicy/actions";
import actionVerticleDots from "./assets/more-vertical.png";
import { Button } from "reactstrap";
import UserDetails from "./UserDetails";

// import Popup from "../../components/Common/Popup";
// import UserForm_New from "../Forms/UserForm_New";

var data = {};
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var darkTheme

class Users_New extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoading: false,
      idToDelete: "",
      open: false,
      rows: [],
      loading: true,
      policies: [],
      breadcrumbItems: [
        { title: "User", link: "user" },
        { title: "User Details", link: "#" },
      ],
      filters: "",
      key: "fullName",
      searchOn: false,
      nameFilter: "",
      emailFilter: "",
      phoneFilter: "",
      setOpenPopup: false,
      hops: [],
      selectedHopValues: "",
      roleArr: [],
      selectedRoleValue: "",
      user: "",
      hopModal: false,
    };
  }
  componentDidMount = () => {
    this.setState({ dataLoading: true })
    this.getAllData();
    this.getAllLocationsData();
    this.getRolesOffset();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }

  componentDidUpdate(prevProps) {
    if (this.props.policies !== prevProps.policies) {
      this.getAllData();
    }
  }

  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };
  // calling hops api
  fetchHops = (filtersHopsData) => {
    this.props.fetchHops({
      offset: filtersHopsData.offset,
      limit: filtersHopsData.limit,
      label: filtersHopsData.q
    }, (res) => {
      let hops = [];
      if (res.data?.docs?.length > 0) {
        res.data.docs.forEach((ele) => {
          hops.push({
            label: ele?.label ? ele?.label : "",
            value: ele?._id ? ele?._id : "",
          });
        });
      }
      this.setState(
        {
          hops: hops,
        },
        () => { },
        () => { }
      );
    });
  }
  // get location name by filter
  filtersHops = (e) => {
    if (e?.length > 0) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: e
      }
      this.fetchHops(filtersHopsData)

    }
    if (e.length === 0) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: ""
      }
      this.fetchHops(filtersHopsData)
    }
  }
  onChangeHop = (selectedHopValues) => {
    this.setState({ selectedHopValues }, () => {
      console.log("selectedHopValues", this.state.selectedHopValues);
      this.filterList();
    });
  };
  onChangeRole = (selectedRoleValue) => {
    this.setState({ selectedRoleValue }, () => {
      console.log("selectedHopValues", this.state.selectedRoleValue);
      this.filterList();
    });
  };
  getRolesOffset = () => {
    let offset = 0;
    this.props.fetchRoles(offset, (res) => {
      // console.log("res",res.data)
      let roleArr = [];
      if (Array.isArray(res.data)) {
        res.data.forEach((ele) => {
          roleArr.push({
            value: ele._id,
            label:
              ele.label.replace(/_/g, " ").charAt(0).toUpperCase() +
              ele.label.replace(/_/g, " ").slice(1),
          });
        });
        this.setState({ roleArr }, () => {
          console.log("role Arr", roleArr);
        });
      }
    });
  };
  getAllLocationsData = () => {
    let filtersHopsData = {
      offset: 0,
      limit: 10,
      q: ""
    }
    this.fetchHops(filtersHopsData)
  };
  setOpenPopupfalse = (props) => {
    this.setState({ setOpenPopup: props });
  };
  handleSubmit = (props) => {
    this.setState({ setOpenPopup: props });
    let offset = 0;
    this.setState({ dataLoading: true });
    this.getAllData();
  };

  hasAccessPolicy(val, entity) {
    const { policies } = this.props;
    let hasAccess = false;
    if (policies && policies.length > 0) {
      hasAccess = getAccessForEntity(policies, entity, val);
    }
    return hasAccess;
  }

  getAllData = () => {
    this.setState({ dataLoading: true })
    let filters = {
      role: this.state.selectedRoleValue.value,
      hop: this.state.selectedHopValues.value,
      name: this.state.nameFilter,
      email: this.state.emailFilter,
      phone: this.state.phoneFilter,

    }
    console.log("FILTERS", filters?.hop)
    const localStorageData = JSON.parse(localStorage.getItem("usersDataList"));
    this.props.fetchUsers(offset, filters , (res) => {
      // this.refineData(res);
      this.setState({ dataLoading: false });
    });
    if (!navigator.onLine) {
      this.setState({ dataLoading: false }, () => {
        // this.refineData(localStorageData);
      });
    }
  };

  // refineData(res) {
  //   // console.log(res);
  //   if (Array.isArray(res?.data?.docs)) {
  //     totCnt = res?.data?.length;
  //     fromVal = totCnt !== 0 ? offset + 1 : fromVal;
  //     toVal = res.data.offset + res.data?.docs?.length;
  //     totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
  //     let rows = [];
  //     res.data.docs.forEach((ele, index) => {
  //       let hopslist = "";
  //       for (let i = 0; i < ele?.hops?.length; i++) {
  //         hopslist += ele?.hops[i]?.hopCode + ",";
  //       }
  //       let itemObject = {
  //         contact: ele?.phone ? ele.phone : "-",
  //         userName: (
  //           <div
  //             className="text-capitalize textALignUser"
  //           // style={{ textAlign: 'start', marginLeft: '28%' }}
  //           >
  //             {ele?.name ? ele.name.replace(/_/g, " ") : "-"}
  //           </div>
  //         ),
  //         address: ele?.address ? ele.address : "-",
  //         city: ele?.city ? ele.city : "-",
  //         state: ele?.state ? ele.state : "-",
  //         zipCode: ele?.zipCode ? ele.zipCode : "-",
  //         email: ele?.email ? ele.email : "-",
  //         role: ele.role
  //           ? ele?.role?.label.replace(/_/g, " ").charAt(0).toUpperCase() +
  //           ele?.role?.label.replace(/_/g, " ").slice(1)
  //           : "Not Assigned",
  //         hop: (
  //           <div className="tooltip2">
  //             {ele?.hops[0]?.label}
  //             <span className="tooltiptext">
  //               {hopslist.substring(0, hopslist?.length - 1)}
  //             </span>
  //           </div>
  //         ),
  //         //hop: ele?.hops[0]?.label,
  //         status: ele?.isActive
  //           ? this.renderUserStatus("Active")
  //           : this.renderUserStatus("Inactive"),
  //         action:
  //           ele.userType === "external" ? (
  //             <div className="dropdown" disabled={true}>
  //               <Button
  //                 className="iconButtons dropbtn"
  //                 style={{backgroundColor:darkTheme?"#BDBDBD":"#24243C",
  //                 border:darkTheme?"0.5px solid #B7A9FF":""
  //                 }}
  //               >
  //                 <img
  //                   style={{ margin: "-4px" }}
  //                   src={actionVerticleDots}
  //                   alt="actions"
  //                 />
  //               </Button>
  //               <div className="dropdown-content contentDark">
  //                 <div
  //                   className="action-button actionDspan"
  //                   onClick={() => {
  //                     this.closeHopModalBody(ele);
  //                   }}
  //                 >
  //                   Edit
  //                 </div>
  //               </div>
  //             </div>
  //           ) : (
  //             <div className="dropdown" disabled={false}>
  //               <Button className="iconButtons dropbtn actionButtonsDark">
  //                 <img
  //                   style={{ margin: "-4px" }}
  //                   src={actionVerticleDots}
  //                   alt="actions"
  //                 />
  //               </Button>
  //               <div className="dropdown-content contentDark">
  //                 <div
  //                   className="action-button"
  //                   onClick={() => {
  //                     this.closeHopModalBody(ele);
  //                   }}
  //                 >
  //                   Edit
  //                 </div>
  //               </div>
  //             </div>
  //           ),
  //       };
  //       if (
  //         this.props.user &&
  //         this.props.user.roleId &&
  //         this.props.user.roleId.name === "superadmin"
  //       )
  //         itemObject.action = this.renderSwitch(ele._id, index, ele.isActive);
  //       rows.push(itemObject);
  //     });
  //     this.setState({ rows, dataLoading: false });
  //   } else this.setState({ dataLoading: false });
  // }

  handleUserActivation = (userId, index, e) => {
    const { rows } = this.state;
    const rowData = [...rows];
    rowData[index].status = e.target.checked
      ? this.renderUserStatus("Active")
      : this.renderUserStatus("Inactive");
    rowData[index].action = this.renderSwitch(userId, index, e.target.checked);
    this.setState({ dataLoading: true });
    this.props.UpdateUser(userId, { isActive: e.target.checked }, () => {
      this.setState({ rows: rowData, dataLoading: false });
    });
  };

  renderSwitch = (id, index, isActive) => {
    return (
      <Switch
        checked={isActive}
        onChange={(e) => this.handleUserActivation(id, index, e)}
        color="primary"
        name="userActivation"
      />
    );
  };

  renderUserStatus = (type) => {
    return (
      <span
        style={{
          color: "#2800FC",
          fontFamily: "Roboto",
          fontSize: "13px",
          fontStyle: "normal",
          fontWeight: "700",
        }}
      >
        {type}
      </span>
    );
  };

  handleClickOpen = (id) => {
    this.setState({ open: true, idToDelete: id });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChangePage = (event, val) => {
    this.setState({ dataLoading: true });
    if (this.state.searchOn !== true) {
      offset = (val - 1) * process.env.REACT_APP_PERPAGE;
      this.getAllData();
    } else {
      offset = (val - 1) * process.env.REACT_APP_PERPAGE;
      this.getAllData()
      // this.props.fetchUsersfilters(
      //   offset,
        // {
        //   role: this.state.selectedRoleValue.value,
        //   hop: this.state.selectedHopValues.value,
        //   name: this.state.nameFilter,
        //   email: this.state.emailFilter,
        //   phone: this.state.phoneFilter,
        // },
      //   (res) => {
      //     // this.refineData(res);
      //     this.setState({ dataLoading: false });
      //   }
      // );
    }
  };

  enterCheck = (event) => {
    if (event.key === "Enter") this.filterList();
  };

  filterList = () => {
    // console.log("called");
    // if (this.state.filters.length > 2) {
    this.setState({ dataLoading: true }, () => {
      if (this.state.searchOn !== true) this.setState({ searchOn: true });
      offset = 0;
      console.log(this.state , "MY CALL")
      this.getAllData()
      // this.props.fetchUsersfilters(
      //   offset,
      //   // this.state.filters.trim(),
      //   {
      //     role: this.state.selectedRoleValue.value,
      //     hop: this.state.selectedHopValues.value,
      //     name: this.state.nameFilter,
      //     email: this.state.emailFilter,
      //     phone: this.state.phoneFilter,
      //   },
      //   (res) => {
      //     // this.refineData(res);
      //     // console.log(res, "compo");
      //     this.setState({ dataLoading: false });
      //     if (res.message && res.status) {
      //       if (res.status === "success")
      //         NotificationManager.success(res["message"], "Success");
      //       else NotificationManager.warning(res["message"], "Warning");
      //     }
      //   }
      // );
    });
    // }
  };

  change = (e) => {
    let filters = e.target.value;
    this.setState({ filters });
  };

  filterAssigner = (e, filter) => {
    this.setState({ [filter]: e.target.value }, () => {
      console.log(this.state.hopFilter, "test1");
    });
  };

  changed = (e) => {
    let key = e.target.value;
    this.setState({ key });
  };

  reset = (e) => {
    this.setState({ loading: true, searchOn: false });
    offset = 0;
    this.setState({
      nameFilter: "",
      phoneFilter: "",
      emailFilter: "",
      selectedHopValues: "",
      selectedRoleValue: "",
    },()=>{
      this.getAllData()
    })
    
  };
  // onClickAddUser = () => {
  //   this.setState({ setOpenPopup: true });
  // };

  closeHopModalBody = (ele) => {
    this.setState({
      hopModal: !this.state.hopModal,
      user: ele,
    });
  };
  render() {
    let initTogglestorage = localStorage.getItem('Theme')
    if (this.props.switchsTheme === "dark__mode" || initTogglestorage === "dark__mode") {
      darkTheme = true
    } else if (this.props.switchsTheme === "light__mode" || initTogglestorage === "light__mode") {
      darkTheme = false
    }
    console.log(AdminHeaderData, "AdminHeaderData");
    const { fullScreen, user } = this.props;
    const { loading } = this.state;
    let rows = []
    let res = this.props.fetchUser
    // debugger
    console.log("RES", res)
    if (res !== undefined) {
      if (Array.isArray(res?.data?.docs)) {
        console.log("RES", res.data)
        totCnt = res?.data?.length;
        fromVal = totCnt !== 0 ? offset + 1 : fromVal;
        toVal = res.data.offset + res.data?.docs?.length;
        totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);

        res.data.docs.forEach((ele, index) => {
          console.log("RES", ele)
          let hopslist = "";
          for (let i = 0; i < ele?.hops?.length; i++) {
            hopslist += ele?.hops[i]?.hopCode + ",";
          }
          let itemObject = {
            contact: ele?.phone ? ele.phone : "-",
            userName: (
              <div
                className="text-capitalize textALignUser"
              // style={{ textAlign: 'start', marginLeft: '28%' }}
              >
                {ele?.name ? ele.name.replace(/_/g, " ") : "-"}
              </div>
            ),
            address: ele?.address ? ele.address : "-",
            city: ele?.city ? ele.city : "-",
            state: ele?.state ? ele.state : "-",
            zipCode: ele?.zipCode ? ele.zipCode : "-",
            email: ele?.email ? ele.email : "-",
            role: ele.role
              ? ele?.role?.label.replace(/_/g, " ").charAt(0).toUpperCase() +
              ele?.role?.label.replace(/_/g, " ").slice(1)
              : "Not Assigned",
            hop: (
              <div className="tooltip2">
                {ele?.hops[0]?.label}
                <span className="tooltiptext">
                  {hopslist.substring(0, hopslist?.length - 1)}
                </span>
              </div>
            ),
            //hop: ele?.hops[0]?.label,
            status: ele?.isActive
              ? this.renderUserStatus("Active")
              : this.renderUserStatus("Inactive"),
            action:
              ele.userType === "external" ? (
                <div className="dropdown" disabled={true}>
                  <Button
                    className="iconButtons dropbtn"
                    style={{
                      backgroundColor: darkTheme ? "#BDBDBD" : "#24243C",
                      border: darkTheme ? "0.5px solid #B7A9FF" : ""
                    }}
                  >
                    <img
                      style={{ margin: "-4px" }}
                      src={actionVerticleDots}
                      alt="actions"
                    />
                  </Button>
                  <div className="dropdown-content contentDark">
                    <div
                      className="action-button actionDspan"
                      onClick={() => {
                        this.closeHopModalBody(ele);
                      }}
                    >
                      Edit
                    </div>
                  </div>
                </div>
              ) : (
                <div className="dropdown" disabled={false}>
                  <Button className="iconButtons dropbtn actionButtonsDark">
                    <img
                      style={{ margin: "-4px" }}
                      src={actionVerticleDots}
                      alt="actions"
                    />
                  </Button>
                  <div className="dropdown-content contentDark">
                    <div
                      className="action-button"
                      onClick={() => {
                        this.closeHopModalBody(ele);
                      }}
                    >
                      Edit
                    </div>
                  </div>
                </div>
              ),
          };
          if (
            this.props.user &&
            this.props.user.roleId &&
            this.props.user.roleId.name === "superadmin"
          )
            itemObject.action = this.renderSwitch(ele._id, index, ele.isActive);
          rows.push(itemObject);
        });
      }
    }

    // if (loading) {
    //   return (
    //     <div className="page-content">
    //       <Col xs={12} className="loader">
    //         <MoonLoader size={45} color={"#123abc"} />
    //       </Col>
    //     </div>
    //   );
    // }

    data = { columns: tableColumn(), rows: rows };
    if (user && typeof user === "object") {
      if (user.roleId && user.roleId.name && user.roleId.name === "superadmin")
        data.columns.push({
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        });
    }
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;
    return (
      <React.Fragment>
        {getLocalStorage()}
        {this.state.hopModal && (
          <UserDetails
            user={this.state.user}
            getAlldata={this.getAllData}
            addHop={this.openHopModalToggle}
            isOpen={this.state.hopModal}
            closeHopModal={this.closeHopModalBody}
            mode={darkTheme}
          />
        )}
        <div>
          <QuickFilters
            // onClickAddUser={this.onClickAddUser}
            nameFilter={this.state.nameFilter}
            enterCheck={this.enterCheck}
            filterAssigner={this.filterAssigner}
            emailFilter={this.state.emailFilter}
            phoneFilter={this.state.phoneFilter}
            reset={this.reset}
            filterList={this.filterList}
            selectedHopValues={this.state.selectedHopValues}
            hopOptions={this.state.hops}
            onChangeHop={this.onChangeHop}
            selectedRoleValue={this.state.selectedRoleValue}
            roleOptions={this.state.roleArr}
            onChangeRole={this.onChangeRole}
            mode={darkTheme}
            filtersHops={this.filtersHops}
          />
          <Container fluid>
            {userType && userType != "admin" && userType != "Admin" ? (
              <Index
                {...this.props}
                headers={HeaderData}
                data={rows}
                searchBar={searchBar}
                searchable={false}
                getAllData={this.getAllData}
                rowHeight={"56px"}
                dataLoading={this.state.dataLoading}
                fetchId={this.props.fetchId}
              />
            ) : (
              <Index
                {...this.props}
                headers={AdminHeaderData}
                data={rows}
                searchBar={searchBar}
                searchable={false}
                getAllData={this.getAllData}
                rowHeight={"56px"}
                dataLoading={this.state.dataLoading}
                fetchId={this.props.fetchId}
              />
            )}

            {totCnt !== 0 && !this.state.dataLoading ? (
              <Row
                xs={12}
                style={{ color: darkTheme ? "#ffffff" : "#0C0C0C" }}
                className="mb-2 paginationRow"
              >
                <Col xs={7} sm={5} md={4} className="span-col-pagenation">
                  <div className="float-left">
                    <Pagination
                      className="desktop-pagination"
                      size="medium"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                    <Pagination
                      className="mobile-pagination"
                      size="small"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                  </div>
                </Col>
                <Col
                  className="mt-2 spanPagenationentries span-col-pagenation"
                  xs={5}
                  sm={7}
                  md={8}
                >
                  <span>
                    Showing {fromVal} to {toVal} of {totCnt} entries
                  </span>
                </Col>
              </Row>
            ) : null}
          </Container>
          {/* <Popup
            title="Add User"
            openPopup={this.state.setOpenPopup}
            setOpenPopupfalse={this.setOpenPopupfalse}
          >
            <UserForm_New handleSubmit={this.handleSubmit} />
          </Popup> */}
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  console.log(state.Account.fetchUser)
  return {
    ...state,
    user: state.Login.user,
    fetchId: state.Account.fetchId,
    fetchUser: state.Account.fetchUser
  }
}

export default connect(mapStateToProps, {
  fetchUsers,
  fetchUsersfilters,
  UpdateUser,
  fetchHops,
  fetchRoles,
  fetchUserDetails,
})(Users_New);
