// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  TOGGLE_RIGHT_SIDEBAR,
  SHOW_RIGHT_SIDEBAR,
  HIDE_RIGHT_SIDEBAR,
  types,
} from "./actionTypes";

import {
  changeSidebarType as changeSidebarTypeAction,
  changeTopbarTheme as changeTopbarThemeAction,
} from "./actions";

import { getApi, postApi, putApi, deleteApi } from "../../helpers/auth_helper";

/**
 * Changes the body attribute
 */
function changeBodyAttribute(attribute, value) {
  if (document.body) document.body.setAttribute(attribute, value);
  return true;
}

/**
 * Toggle the class on body
 * @param {*} cssClass
 */
function manageBodyClass(cssClass, action = "toggle") {
  switch (action) {
    case "add":
      if (document.body) document.body.classList.add(cssClass);
      break;
    case "remove":
      if (document.body) document.body.classList.remove(cssClass);
      break;
    default:
      if (document.body) document.body.classList.toggle(cssClass);
      break;
  }

  return true;
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* changeLayout({ payload: layout }) {
  try {
    if (layout === "horizontal") {
      yield put(changeTopbarThemeAction("dark"));
      document.body.removeAttribute("data-sidebar");
      document.body.removeAttribute("data-sidebar-size");
    } else {
      yield put(changeTopbarThemeAction("light"));
    }
    yield call(changeBodyAttribute, "data-layout", layout);
  } catch (error) {}
}

/**
 * Changes the layout width
 * @param {*} param0
 */
function* changeLayoutWidth({ payload: { width, layoutType } }) {
  try {
    if (layoutType === "vertical") {
      if (width === "boxed") {
        yield put(changeSidebarTypeAction("icon"));
      } else {
        yield put(changeSidebarTypeAction("default"));
      }
    }
    yield call(changeBodyAttribute, "data-layout-size", width);
  } catch (error) {}
}

/**
 * Changes the left sidebar theme
 * @param {*} param0
 */
function* changeLeftSidebarTheme({ payload: { theme, layoutType } }) {
  try {
    yield call(changeBodyAttribute, "data-sidebar", theme);
    if (layoutType === "vertical") {
      if (theme === "light")
        //Fire action for changing dark theme of topbar
        yield put(changeTopbarThemeAction("dark"));
      if (theme === "dark")
        //Fire action for changing light theme of topbar
        yield put(changeTopbarThemeAction("light"));
    }
  } catch (error) {}
}

/**
 * Changes the topbar theme
 * @param {*} param0
 */
function* changeTopbarTheme({ payload: theme }) {
  try {
    yield call(changeBodyAttribute, "data-topbar", theme);
  } catch (error) {}
}

/**
 * Changes the left sidebar type
 * @param {*} param0
 */
function* changeLeftSidebarType({ payload: { sidebarType, isMobile } }) {
  try {
    switch (sidebarType) {
      case "compact":
        yield call(changeBodyAttribute, "data-sidebar-size", "small");
        yield call(manageBodyClass, "sidebar-enable", "remove");
        yield call(manageBodyClass, "vertical-collpsed", "remove");
        break;
      case "icon":
        yield call(changeBodyAttribute, "data-keep-enlarged", "true");
        yield call(manageBodyClass, "vertical-collpsed", "add");
        break;
      case "condensed":
        yield call(manageBodyClass, "sidebar-enable", "add");
        if (!isMobile) yield call(manageBodyClass, "vertical-collpsed", "add");
        break;
      default:
        yield call(changeBodyAttribute, "data-sidebar-size", "");
        yield call(manageBodyClass, "sidebar-enable", "remove");
        if (!isMobile)
          yield call(manageBodyClass, "vertical-collpsed", "remove");
        break;
    }
  } catch (error) {}
}

/**
 * Toggles the rightsidebar
 */
function* toggleRightSidebar() {
  try {
    yield call(manageBodyClass, "right-bar-enabled");
  } catch (error) {}
}

/**
 * Show the rightsidebar
 */
function* showRightSidebar() {
  try {
    yield call(manageBodyClass, "right-bar-enabled", "add");
  } catch (error) {}
}

/**
 * Hides the rightsidebar
 */
function* hideRightSidebar() {
  try {
    yield call(manageBodyClass, "right-bar-enabled", "remove");
  } catch (error) {}
}

function* workfetchTrips({ offset, filters ,callback}) {
  try {
    // Try to call the API
    // console.log(filters, "filters");
    let filterString = "";
    for (const [key, value] of Object.entries(filters)) {
      let filterKey = key;
      let filterValue = value;
      if (filterKey === "startTime") {
        filterString =
          filterString +
          "&" +
          "startTimeStart=" +
          filterValue[0] +
          "&startTimeEnd=" +
          filterValue[1];
      } else filterString = filterString + "&" + filterKey + "=" + filterValue;
    }

    const uri =
      process.env.REACT_APP_SERVERURL +
      `/trip/searchTripList?offset=` +
      Number(offset) +
      `&limit=` +
      Number(process.env.REACT_APP_PERPAGE) +
      filterString;
    const response = yield call(getApi, uri);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_TRIPS_SUCCESS,
      payload: response,
    });
    callback(response)
    localStorage.setItem("TripData", JSON.stringify(response));
    if (filterString === "&subStatus=in_transit") {
      localStorage.setItem("tripIntransitData", JSON.stringify(response));
    
    }
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not fetch posts.", error);
  }
}

function* workfetchTripsExcel({ offset, filters, callback }) {
  try {
    // Try to call the API
    //consolelog(filters, "filters");
    let filterString = "";
    for (const [key, value] of Object.entries(filters)) {
      let filterKey = key;
      let filterValue = value;
      if (filterKey === "startTime") {
        console.log(value, "-", key);
        filterString =
          filterString +
          "&" +
          "startTimeStart=" +
          filterValue[0] +
          "&startTimeEnd=" +
          filterValue[1];
      } else filterString = filterString + "&" + filterKey + "=" + filterValue;
      console.log(filterString, "FILETR-SAGA");
    }
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/trip/searchTripList?offset=` +
      Number(offset) +
      `&limit=noLimit` +
      // `&limit=` +
      // Number(process.env.REACT_APP_PERPAGE) +
      filterString;
    const response = yield call(getApi, uri);
    callback(response);
    // callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_TRIPS_EXCEL_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not fetch posts.", error);
  }
}
function* workfetchTransporterTrips({
  offset,
  filters,
  searchKey,
  searchValue,
}) {
  try {
    // Try to call the API
    console.log(offset, filters, searchKey, searchValue, "filters");
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/trip/transporter/search?offset=` +
      Number(offset) +
      `&limit=` +
      Number(process.env.REACT_APP_PERPAGE) +
      `&filters=` +
      filters +
      `&searchKey=` +
      searchKey +
      `&searchValue=` +
      searchValue;
    const response = yield call(getApi, uri);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_TRIPS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not fetch posts.", error);
  }
}

function* workstartLoading({ tripID, callback }) {
  try {
    console.log("tripID ", tripID);
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL + `/trip/status/loadingInProgress`;
    const response = yield call(postApi, uri, { tripID });
    callback(response);
    console.log("response test", response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.START_LOADING_SUCCESS,
      payload: { tripID },
    });
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not fetch posts.", error);
  }
}
function* workfinishLoading({ tripID, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/trip/status/inTransit`;
    const response = yield call(postApi, uri, { tripID });
    //   Dispatch the action to the reducers
    callback(response);
    yield put({
      type: types.FINISH_LOADING_SUCCESS,
      payload: { tripID },
    });
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not fetch posts.", error);
  }
}
function* workfetchTripsfilter({ offset, filters, callback }) {
  try {
    // Try to call the API
    //consolelog(filters, "filters");
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/trip/trips?offset=` +
      Number(offset) +
      `&limit=` +
      Number(process.env.REACT_APP_PERPAGE) +
      `&filters=` +
      filters;

    const response = yield call(getApi, uri);
    callback(response);

    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_FILTER_TRIPS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not fetch posts.", error);
  }
}

function* workfetchHops({ callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/hop/filterhop`;
    const response = yield call(getApi, uri);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_HOPS_LIST_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not fetch hops.", error);
  }
}

function* workCreateTrip({ values, callback }) {
  try {
    // //consolelog('values', values);
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/trip/create`;
    const response = yield call(postApi, uri, values);
    // //consolelog('response',response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.CREATE_TRIP_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not create trip.", error);
  }
}
function* workEditTrip({ values, callback }) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL + `/trip/update?tripId=${values.id}`;
    const response = yield call(putApi, uri, values);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.EDIT_TRIP_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not fetch posts.", error);
  }
}

function* workChangeTripStatus({ values, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/trip/status`;
    const response = yield call(putApi, uri, values);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.CHANGE_TRIP_STATUS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not change trip status.", error);
  }
}

function* workFetchTripId({ id, callback }) {
  try {
    //consolelog("Id----", id);
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/trip/${id}`;
    const response = yield call(getApi, uri);
    callback(response);
    //   Dispatch the action to the reducers

    yield put({
      type: types.FETCH_TRIP_BYID_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not fetch trip.", error);
  }
}

function* workFetchTripBillsById({ id }) {
  try {
    //consolelog("Id----", id);
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL + `/trip/getTripByIncrementalId/${id}`;
    const response = yield call(getApi, uri);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_TRIPS_BILLS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not fetch trip.", error);
  }
}

function* workTrackLocationTripId({ id, callback }) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL + `/tracking/locationByTripId/${id}`;
    const response = yield call(getApi, uri);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.TRACK_LOCATION_BYTRIPID_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetchlocationbytripid trip.", error);
  }
}

function* workTrackTripId({ id, callback }) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL + `/tracker/trackers?tripID=${id}`;
    const response = yield call(getApi, uri);
    callback(response);
    // callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.TRACK_TRIP_BYID_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not track trip.", error);
  }
}

function* workDeleteTrip({ id, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/trip/delete/` + id;
    const response = yield call(deleteApi, uri);
    // //consolelog("response", response);
    //   if(response)
    callback(response);

    //   Dispatch the action to the reducers
    yield put({
      type: types.DELETE_TRIP_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not fetch posts.", error);
  }
}

function* workChangeShipmentverify({ verify, callback }) {
  try {
    console.log("FORM SAGA", verify);
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/shipment/verify`;
    const response = yield call(postApi, uri, verify);
    //   Dispatch the action to the reducers
    callback(response);
    yield put({
      type: types.SHIPMENT_VERIFY_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not change trip status.", error);
  }
}
function* workCreateReportIncident({ values, callback }) {
  try {
    // //consolelog('values', values);
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/trip/incidentReport`;
    const response = yield call(postApi, uri, values);
    // //consolelog('response',response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.CREATE_REPORTINCIDENT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not create trip.", error);
  }
}

function* workUpdateDriver({ values, callback }) {
  try {
    // //consolelog('values', values);
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/trip/updateDriver`;
    const response = yield call(postApi, uri, values);
    // //consolelog('response',response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.UPDATE_DRIVER_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not create trip.", error);
  }
}

function* workFetchLocationById({ id, callback }) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL + `/tracking/locationByTripId/${id}`;
    const response = yield call(getApi, uri);
    callback(response);
    // callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_LOCATION_BY_ID_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not track trip.", error);
  }
}

function* workCancelTrip({ values, callback }) {
  console.log("CANCEl---", values);
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/trip/cancel`;
    const response = yield call(postApi, uri, values);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.CANCEL_TRIP_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not cancel trip.", error);
  }
}

function* workUpdateTrip({ values, callback }) {
  console.log("UPDATE---", values);
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/trip/update/driver-vehicle`;
    const response = yield call(postApi, uri, values);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.UPDATE_TRIP_DETAILS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not update trip.", error);
  }
}
function* workTripArrivedAtSourceStatus({ tripID, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/trip/status/arrivedAtHop`;
    const response = yield call(postApi, uri, { tripID });
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.CHANGE_ARRIVEDATSOURCE_STATUS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not change trip status.", error);
  }
}
function* workfetchTripsTower({ offset, filters ,callback}) {
  try {
    // Try to call the API
    // console.log(filters, "filters");
    let filterString = "";
    for (const [key, value] of Object.entries(filters)) {
      let filterKey = key;
      let filterValue = value;
      if (filterKey === "startTime") {
        filterString =
          filterString +
          "&" +
          "startTimeStart=" +
          filterValue[0] +
          "&startTimeEnd=" +
          filterValue[1];
      } else filterString = filterString + "&" + filterKey + "=" + filterValue;
    }

    const uri =
      process.env.REACT_APP_SERVERURL +
      `/trip/searchTripList?offset=` +
      Number(offset) +
      `&limit=` +
     100000+
      filterString;
    const response = yield call(getApi, uri);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_TRIP_TOWER_SUCCESS,
      payload: response,
    });
    callback(response)
    localStorage.setItem("TripData", JSON.stringify(response));
    if (filterString === "&subStatus=in_transit") {
      localStorage.setItem("tripIntransitData", JSON.stringify(response));
    
    }
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not fetch posts.", error);
  }
}
/**
 * Watchers
 */
export function* watchChangeLayoutType() {
  yield takeEvery(CHANGE_LAYOUT, changeLayout);
}

export function* watchChangeLayoutWidth() {
  yield takeEvery(CHANGE_LAYOUT_WIDTH, changeLayoutWidth);
}

export function* watchChangeLeftSidebarTheme() {
  yield takeEvery(CHANGE_SIDEBAR_THEME, changeLeftSidebarTheme);
}

export function* watchChangeLeftSidebarType() {
  yield takeEvery(CHANGE_SIDEBAR_TYPE, changeLeftSidebarType);
}

export function* watchChangeTopbarTheme() {
  yield takeEvery(CHANGE_TOPBAR_THEME, changeTopbarTheme);
}

export function* watchToggleRightSidebar() {
  yield takeEvery(TOGGLE_RIGHT_SIDEBAR, toggleRightSidebar);
}

export function* watchShowRightSidebar() {
  yield takeEvery(SHOW_RIGHT_SIDEBAR, showRightSidebar);
}

export function* watchHideRightSidebar() {
  yield takeEvery(HIDE_RIGHT_SIDEBAR, hideRightSidebar);
}
export function* watchFetchTrips() {
  yield takeEvery(types.FETCH_TRIPS, workfetchTrips);
}
export function* watchFetchTripsExcel() {
  yield takeEvery(types.FETCH_TRIPS_EXCEL, workfetchTripsExcel);
}
export function* watchFetchTransporterTrips() {
  yield takeEvery(types.FETCH_TRANSPORTER_TRIPS, workfetchTransporterTrips);
}
export function* watchFetchTripsfilter() {
  yield takeEvery(types.FETCH_FILTER_TRIPS, workfetchTripsfilter);
}
export function* watchFetchHops() {
  yield takeEvery(types.FETCH_HOPS_LIST, workfetchHops);
}
export function* watchCreateTrip() {
  yield takeEvery(types.CREATE_TRIP, workCreateTrip);
}
export function* watchEditTrip() {
  yield takeEvery(types.EDIT_TRIP, workEditTrip);
}
export function* watchChangeTripStatus() {
  yield takeEvery(types.CHANGE_TRIP_STATUS, workChangeTripStatus);
}
export function* watchFetchTripId() {
  yield takeEvery(types.FETCH_TRIP_BYID, workFetchTripId);
}
export function* watchTrackLocationTripId() {
  yield takeEvery(types.TRACK_LOCATION_BYTRIPID, workTrackLocationTripId);
}
export function* watchTrackTripId() {
  yield takeEvery(types.TRACK_TRIP_BYID, workTrackTripId);
}
export function* watchDeleteTrip() {
  yield takeEvery(types.DELETE_TRIP, workDeleteTrip);
}

export function* watchStartLoading() {
  yield takeEvery(types.START_LOADING, workstartLoading);
}

export function* watchFinishLoading() {
  yield takeEvery(types.FINISH_LOADING, workfinishLoading);
}

export function* watchShipmentVerify() {
  yield takeEvery(types.SHIPMENT_VERIFY, workChangeShipmentverify);
}
export function* watchCreateReportIncident() {
  yield takeEvery(types.CREATE_REPORTINCIDENT, workCreateReportIncident);
}
export function* watchUpdateDriver() {
  yield takeEvery(types.UPDATE_DRIVER, workUpdateDriver);
}
export function* watchFetchLocationById() {
  yield takeEvery(types.FETCH_LOCATION_BY_ID, workFetchLocationById);
}
export function* watchFetchTripBillsById() {
  yield takeEvery(types.FETCH_TRIPS_BILLS, workFetchTripBillsById);
}
export function* watchCancelTrip() {
  yield takeEvery(types.CANCEL_TRIP, workCancelTrip);
}
export function* watchUpdateTrip() {
  yield takeEvery(types.UPDATE_TRIP_DETAILS, workUpdateTrip);
}
export function* watchTripArrivedAtSourceStatus() {
  yield takeEvery(
    types.CHANGE_ARRIVEDATSOURCE_STATUS,
    workTripArrivedAtSourceStatus
  );
}
export function* watchFetchTripsTower() {
  yield takeEvery(
    types.FETCH_TRIP_TOWER,
    workfetchTripsTower
  );
}

function* TripSaga() {
  yield all([
    fork(watchChangeLayoutType),
    fork(watchChangeLayoutWidth),
    fork(watchChangeLeftSidebarTheme),
    fork(watchChangeLeftSidebarType),
    fork(watchToggleRightSidebar),
    fork(watchShowRightSidebar),
    fork(watchHideRightSidebar),
    fork(watchChangeTopbarTheme),
    fork(watchFetchTrips),
    fork(watchFetchTripsExcel),
    fork(watchFetchTransporterTrips),
    fork(watchFetchTripsfilter),
    fork(watchFetchHops),
    fork(watchCreateTrip),
    // fork(watchEditTrip),
    fork(watchFetchTripId),
    fork(watchTrackLocationTripId),
    fork(watchTrackTripId),
    fork(watchDeleteTrip),
    fork(watchChangeTripStatus),
    fork(watchStartLoading),
    fork(watchFinishLoading),
    fork(watchShipmentVerify),
    fork(watchCreateReportIncident),
    fork(watchUpdateDriver),
    fork(watchFetchLocationById),
    fork(watchFetchTripBillsById),
    fork(watchCancelTrip),
    fork(watchUpdateTrip),
    fork(watchTripArrivedAtSourceStatus),
    fork(watchFetchTripsTower)
  ]);
}

export default TripSaga;
