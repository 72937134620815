import React, { Component } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  FormGroup,
  Card,
  CardBody,
  Label,
  Input,
} from "reactstrap";
import { MoonLoader } from "react-spinners";
import moment from "moment";
import Datetime from "react-datetime";
import Select from "react-select";
import { MDBDataTable, MDBIcon, MDBCol, MDBBtn, MDBCollapse } from "mdbreact";
import Pagination from "@material-ui/lab/Pagination";
import { fetchAllTransporterSearch } from "../../store/transporter/actions";
import { fetchReports } from "../../store/actions";
import { connect } from "react-redux";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  Box,
  Grid,
  Item,
  Close,
  ListItem,
  Input as MaterialInput,
  TextField,
  OutlinedInput,
  FormControl,
} from "@mui/material";

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
export class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      FromDate: "",
      ToDate: "",
      transporterList: [],
      transOffset: 0,
      transporter: "",
      searchvalue: "",
      disableSearch: true,
    };
    this.fromRef = React.createRef();
    this.toRef = React.createRef();
  }
  componentDidMount = () => {
    // this.fetchTransporterFunc()
    this.getReportsFunc();
  };
  getReportsFunc = () => {
    let limit = 10;
    let values = { transporter: this.state.transporter };
    this.props.fetchReports(offset, limit, values);
  };
  // fetchTransporterFunc=(search='')=>{
  //     let limit=10
  //     this.setState({loading:true})
  //     this.props.fetchAllTransporterSearch(
  //         this.state.transOffset,limit,
  //         (res) => {
  //             let transporter = [];
  //             console.log(res, "res");
  //             if (res.data?.docs?.length > 0) {
  //               res.data.docs.forEach((ele) => {
  //                 transporter.push({value:{id:ele._id,name:ele.name},label:ele.name})
  //               })}
  //             this.setState({transporterList:transporter,loading:false})
  //             })
  // }
  validFD = (current) => {
    if (this.state.ToDate !== "") {
      let day = new Date(this.state.ToDate);
      day.setDate(day.getDate() + 1);
      return current.isBefore(day);
    } else {
      return true;
    }
  };
  validTD = (current) => {
    if (this.state.FromDate !== "") {
      let day = new Date(this.state.FromDate);
      day.setDate(day.getDate() - 1);
      return current.isAfter(day);
    } else {
      return true;
    }
  };
  handleChange = (e) => {
    let date = new Date(e.target.value);
    date.setHours(0, 0, 0, 0);
    this.setState({
      [e.target.name]: moment(e.target.value).format("YYYY/MM/DD"),
    });
  };
  handleChangePage = (event, val) => {
    let key;
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    this.getReportsFunc();
  };
  reset = () => {
    this.fromRef.current.state.value = "";
    this.fromRef.current.state.inputValue = "";
    this.toRef.current.state.value = "";
    this.toRef.current.state.inputValue = "";
    this.setState(
      { FromDate: "", ToDate: "", transporter: "", transOffset: 0 },
      () => {
        this.getReportsFunc();
      }
    );
  };
  handleTransporter = (e) => {
    console.log(e.target.value, "eee");
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (this.state.transporter.length > 2) {
        this.setState({ disableSearch: false });
      } else {
        this.setState({ disableSearch: true });
      }
    });
  };
  handleSearch = (e) => {
    // console.log(e.target.name, "eee");
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (this.state.searchValue.length > 2) {
        this.fetchTransporterFunc();
      }
    });
  };
  searchFilter = () => {
    this.getReportsFunc();
  };
  exportToCSV = (csvData, fileName) => {
    csvData = csvData?.map(function (item) {
      for (var key in item) {
        var upper = key.toUpperCase();
        // check if it already wasn't uppercase
      }
      return item;
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  render() {
    const { FromDate, ToDate, loading, disableSearch, transporter } =
      this.state;
    let reports = [];
    if (Array.isArray(this.props.reports?.data?.docs)) {
      offset = this.props.reports?.data?.offset;
      totCnt = this.props.reports?.data?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal =
        this.props.reports?.data?.offset + this.props.reports?.data?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      this.props.reports.data.docs.forEach((ele) => {
        reports.push({
          transporter: ele?.name ? ele.name : "N/A",
          vehiclePlacement: ele.stats ? ele.stats.placement : "N/A",
          qualityRejection: ele.stats ? ele.stats.qualityRejection : "N/A",
          onTimeDelivery: ele.stats ? ele.stats.onTimeDelivery : "N/A",
          damage: ele?.stats ? ele.stats.damagePercentage : "N/A",
          shortages: ele?.stats ? ele.stats.shoragePercentage : "N/A",
          totalTrips: ele.stats ? ele.stats.totalTrips : "N/A",
        });
      });
    }

    const customStyles = {
      indicatorsContainer: (style) => {
        return {
          ...style,
          backgroundColor: "#5664D2",
          width: "54px",
          textAlign: "center",
          justifyContent: "center",
          borderRadius: "0px 4px 4px 0px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          backgroundColor: "#5664D2",
        };
      },
      control: (style) => {
        return {
          ...style,
          width: "241px",
          height: "37px",
          backgroundColor: "#F1F5F7",
          borderTop: "0px",
          borderBottom: "0px",
          borderRight: "0px",
        };
      },
    };
    const inputProps = { placeholder: "Select" };
    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#123abc"} />
          </Col>
        </div>
      );
    }

    let data = {
      columns: [
        {
          label: <b>Transporter</b>,
          field: "transporter",
          sort: "asc",
          width: 300,
        },
        {
          label: <b>Vehicle placement</b>,
          field: "vehiclePlacement",
          sort: "asc",
          width: 300,
        },
        {
          label: <b>Quality Rejection</b>,
          field: "qualityRejection",
          sort: "asc",
          width: 300,
        },
        {
          label: <b>On Time Delivery</b>,
          field: "onTimeDelivery",
          sort: "asc",
          width: 50,
        },

        {
          label: <b>Damage</b>,
          field: "damage",
          sort: "asc",
          width: 100,
        },

        {
          label: <b>Shortages</b>,
          field: "shortages",
          sort: "asc",
          width: 300,
        },

        {
          label: <b>Total Trips</b>,
          field: "totalTrips",
          sort: "asc",
          width: 300,
        },
      ],
      rows: reports,
    };
    return (
      <React.Fragment>
        <div /* className="page-content" */>
          <Container fluid>
            <div className="d-flex bd-highlight">
              <div className="p-2 flex-grow-1 bd-highlight">
                <h5
                  className="mt-1"
                  style={{
                    fontWeight: "700",
                    lineHeight: "20px",
                    size: "16px",
                    color: "#808080",
                  }}
                >
                  Transporter Performance{" "}
                </h5>
              </div>
              <div className="p-2 bd-highlight">
                <Button
                  type="button"
                  className=" btn btn-primary rounded-black"
                  onClick={() => {
                    this.exportToCSV([...reports], "Excel_Reports_Data");
                  }}
                  style={{
                    float: "right",
                    margin: "2px 2px 6px 2px",
                  }}
                >
                  <span style={{ fontWeight: "900" }}>Download Excel</span>
                </Button>
              </div>
            </div>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody className="px-3 py-4">
                    <div className="justify-content-between d-flex mb-3 ml-4">
                      <Row className="ml-5">
                        <div className=" d-flex flex-wrap align-items-start float-right">
                          <div className="pb-1 mr-4">
                            <Label className="required-field">
                              Transporter
                            </Label>
                            <input
                              style={{ height: "37px" }}
                              className="form-control searchBox form-control-sm sharp-corners-right rounded-left"
                              type="text"
                              name="transporter"
                              value={transporter}
                              onChange={(e) => this.handleTransporter(e)}
                              placeholder="Search"
                              aria-label="Search"
                              // onKeyPress={this.handleKeyDown}
                              icon="fas fa-times-circle"
                            />
                          </div>

                          <div className="pb-1 ml-5">
                            <form className="">
                              <Row>
                                <Col xs={12} className="pr-0">
                                  <Label className="required-field">
                                    From Date
                                  </Label>
                                </Col>
                                <Col
                                  xs={12}
                                  className="pl-0 cal d-flex flex-row"
                                >
                                  <Datetime
                                    ref={this.fromRef}
                                    style={{
                                      height: "38px",
                                      backgroundColor: "#505d69",
                                      marginRight: "8px",
                                      flex: 1,
                                    }}
                                    value={FromDate}
                                    onChange={(e) =>
                                      this.handleChange({
                                        target: {
                                          name: "FromDate",
                                          value: e._d,
                                        },
                                      })
                                    }
                                    inputProps={inputProps}
                                    closeOnSelect
                                    dateFormat="DD-MM-YYYY"
                                    isValidDate={this.validFD}
                                    defaultValue={new Date()}
                                    input={true}
                                  />
                                  <div className="pl-2">
                                    <Button
                                      style={{
                                        height: "38px",
                                        width: "38px",
                                      }}
                                      className="btn calendar-icon-color"
                                      onClick={() => {
                                        let { open } =
                                          this.fromRef.current.state;
                                        //console.log(open, this.fromRef.current);
                                        if (open === false) {
                                          console.log(
                                            open,
                                            "fromRef",
                                            this.fromRef.current
                                          );
                                          this.fromRef.current._openCalendar();
                                        }
                                      }}
                                    >
                                      <i className="fas fa-calendar"></i>
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </form>
                          </div>
                          <div className="pb-1 mr-4">
                            <form className="">
                              <Row>
                                <Col xs={12} className="pr-0">
                                  <Label className="required-field">
                                    To Date
                                  </Label>
                                </Col>
                                <Col
                                  xs={12}
                                  className="pl-0 cal d-flex flex-row"
                                >
                                  <Datetime
                                    style={{
                                      height: "38px",
                                      backgroundColor: "#505d69",
                                      flex: 1,
                                    }}
                                    value={ToDate}
                                    onChange={(e) =>
                                      this.handleChange({
                                        target: {
                                          name: "ToDate",
                                          value: e._d,
                                        },
                                      })
                                    }
                                    closeOnSelect
                                    dateFormat="DD-MM-YYYY"
                                    isValidDate={this.validTD}
                                    defaultValue={new Date()}
                                    input={true}
                                    inputProps={inputProps}
                                    ref={this.toRef}
                                  />
                                  <div className="pl-2">
                                    <Button
                                      style={{
                                        height: "38px",
                                        width: "38px",
                                      }}
                                      className="btn calendar-icon-color"
                                      onClick={() => {
                                        let { open } = this.toRef.current.state;
                                        //console.log(open, this.toRef.current);
                                        if (open === false) {
                                          console.log(
                                            open,
                                            "toRef",
                                            this.toRef.current
                                          );
                                          this.toRef.current._openCalendar();
                                        }
                                      }}
                                    >
                                      <i className="fas fa-calendar"></i>
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </form>
                          </div>
                          <div
                            className="pb-1 mr-2 mt-4"
                            style={{ paddingTop: "5px" }}
                          >
                            <Button
                              style={{
                                color: "#5664d2 !important",
                              }}
                              className="button-wide mr-2"
                              onClick={this.searchFilter}
                              disabled={disableSearch}
                              color="primary"
                            >
                              Search
                            </Button>
                            <Button
                              style={{
                                backgroundColor: "#74788d !important",
                              }}
                              className=" button-wide ml-4 rounded-black"
                              onClick={this.reset}
                              // disabled={!searchOn}
                            >
                              Reset
                            </Button>
                          </div>
                        </div>
                      </Row>
                    </div>{" "}
                    <div className="dataTableStyles">
                      <MDBDataTable
                        className="text-center text-capitalize"
                        responsive
                        sorting={"true"}
                        data={data}
                        paging={false}
                        searching={false}
                      />

                      {totCnt !== 0 ? (
                        <Row xs={12}>
                          <Col xs={6}>
                            <span>
                              Showing {fromVal} to {toVal} of {totCnt} entries
                            </span>
                          </Col>
                          <Col xs={6}>
                            <div className="float-right">
                              <Pagination
                                shape="rounded"
                                page={offset / 10 + 1}
                                count={totPages}
                                color="primary"
                                onChange={this.handleChangePage}
                              />
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  reports: state.Reports.reports.reports,
});

export default connect(mapStateToProps, {
  fetchAllTransporterSearch,
  fetchReports,
})(Reports);
