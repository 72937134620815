import React, { Component } from "react";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Card from "@mui/material/Card";
import { Progress, UncontrolledTooltip } from "reactstrap";
import { Row, Col } from "reactstrap";
import Grid from "@mui/material/Grid";
import shortageIcon from "../assets/Shortage Shipment.png";
import damagedIcon from "../assets/Damaged Shipment.png";
import shortageDark from "../assets/ShortageDark.png";
import damagedDark from "../assets/DamageDark.png";

export default class ShipmentCard extends Component {
  render() {
    // alert(this.props.darkThemeShip)
    const trips = this.props.trips;
    console.log("shipment",trips)
    const darkTheme = this.props.darkThemeShip;
    return (
      <>
        <Card
          className="shipment-card-parent darkCardStyle"
          // style={{
          //   height: "478px",
          // }}
        >
          <div className="mt-2 pl-2">
            <Row className="pt-2">
              <Col xs="9" className="pdt5">
                <span
                  className={`roboto-font-700 span-shipment-name ${
                    darkTheme ? "light__span" : ""
                  }`}
                >
                  {this.props?.userLang?.dashboard?.SHIPMENT}
                </span>
              </Col>
              <Col xs="3">
                <span
                  className={`pr-3 ${darkTheme ? "light__span" : ""}`}
                  style={{
                    float: "right",
                    marginLeft: "535px",
                  }}
                >
                  <MoreHorizIcon
                  className="MobileShipCardPad1"
                  
                  ></MoreHorizIcon>
                </span>
              </Col>
            </Row>
          </div>
          <hr className="mt-3 shipment-hr-line" />
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            style={{
              marginTop: "18px",
            }}
            className="MobileShipmentPad"
          >
            <Grid item xs={6} md={10}>
              {/* <div className=""> */}
              <Card className="shipment-card darkInternalcard">
                <Row className="pt-3 shipment-card-row MobileShipment ">
                <Col xs="1" >
                <img className="imageIconS" src={darkTheme?damagedDark:damagedIcon}></img>
                  </Col>
                  <Col className="pl-3" xs="5">
                    <div className="w-50 light__span pl-3">
                      {this.props?.userLang?.dashboard?.DAMAGED_SHIPMENT}
                    </div>
                  </Col>
                  <Col xs="5">
                  <span className="fontRoboto light__span">
                  {trips?.damageTripPercentage + " " + "%"}
                  </span>
                  </Col>
                
                </Row>
                <div className="pt-3 pl-3 MobileShipment1">
                  <UncontrolledTooltip
                  style={{
                    paddingLeft:"20px"
                  }}
                    placement="bottom"
                    autohide={true}
                    target="DamagedShipment"
                  >
                    {trips?.damageTripPercentage + " " + "%"}
                  </UncontrolledTooltip>
                  {/* <span className="fontRoboto127">
                  {trips?.damageTripPercentage + " " + "%"}
                  </span> */}
                  <Progress
                   style={{
                    backgroundColor:"#0AB38C"
                  }}
                    id="DamagedShipment"
                    className="shipment-progress"
                    barStyle={{
                      borderRadius: "20px",
                      backgroundColor:"#AC1000"
                    }}
                    // color="success"
                    value={
                      trips?.damageTripPercentage
                    }
                  />
                </div>
              </Card>
              {/* </div> */}
            </Grid>
            <Grid item xs={6} md={10}>
              {/* <div className="pt-2"> */}
              <Card className="shortage-shipment-card darkInternalcard">
                <Row className="pt-3 shipment-card-row MobileShipment">
                 
                  <Col xs="1" >
                    <img className="imageIconS" src={darkTheme?shortageDark:shortageIcon}></img>
                  </Col>
                  <Col className="pl-3" xs="5">
                    <div className="w-50 light__span pl-3">
                      {this.props?.userLang?.dashboard?.SHORTAGE_SHIPMENT}
                    </div>
                  </Col>
                  <Col xs="5">
                  <span className="fontRoboto light__span">
                  {trips?.shortageTripPercentage + " "+"%"}
                  </span>
                  </Col>
                </Row>
                <div className="pt-3 pl-3 shipcard-percentage MobileShipment1 progressBg">
                  <UncontrolledTooltip
                    placement="bottom"
                    autohide={true}
                    target="ShortageShipment"
                  >
                    {trips?.shortageTripPercentage + " " + "%"} 
                  </UncontrolledTooltip>
                  {/* <span className="fontRoboto127">
                  {trips?.shortageTripPercentage + " "+"%"}
                  </span> */}
                  <Progress
                  id="ShortageShipment"
                  style={{
                    backgroundColor:"#0AB38C"
                  }}
                    className="shipment-progress"
                    barStyle={{
                      borderRadius: "20px",
                      backgroundColor:"#AC1000"
                    }}
                    // color="success"
                    value={
                      trips?.shortageTripPercentage
                    }
                   />
                </div>
              </Card>
              {/* </div> */}
            </Grid>
          </Grid>
        </Card>
      </>
    );
  }
}
