import React, { Component } from "react";
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody } from "mdbreact";
import { Row, Col, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import { Input } from "reactstrap";
import { Button } from "@material-ui/core";
import Datetime from "react-datetime";
import moment from "moment";
import { connect } from "react-redux";
import {
  uploadAgreement,
  fetchSignedURL,
} from "../../../store/billing/actions";

var fileObj = [];
var fileArray = [];
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
var darkMode;
class RaiseInvoceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      signedURL: "",
      selectedFile: [],
      uploadData: "",
      fileFlag: false,
      lastUploadLength: 0,
      urlArrayValues: [],
      userLangs: undefined,
    };
    this.fromRef = React.createRef();
    this.fileInput = React.createRef();
  }
  validCheck = (current) => {
    var limitDate = moment(new Date());
    return current.isBefore(limitDate);
  };

  fileUploadTrigger = () => {
    this.fileInput.current.click();
  };


  S3Upload = async () => {
    this.setState({ lastUploadLength: this.state.selectedFile.length });

    for (let i = 0; i < this.state.selectedFile.length; i++) {
      let softCopy = "";

      this.props.fetchSignedURL((res) => {
        softCopy = res.presignedS3Url.split("?")[0];
        console.log("uploadURL", softCopy);
        this.setState(
          {
            urlArrayValues: this.state.urlArrayValues.concat(softCopy),
          },
          () => {
            console.log("signedURL", res.presignedS3Url);
            console.log("URL value log", this.state.urlArrayValues);
            // console.log(
            //   this.state.selectedFile[this.state.lastUploadLength]?.name
            // );
            if (
              this.state.urlArrayValues.length ===
              this.state.selectedFile.length
            )
              this.props.documentAssigner(this.state.urlArrayValues);
            const formData = new FormData();
            formData.append(
              this.state.selectedFile[i],
              this.state.selectedFile[i].name
            );
            console.log("--------", formData);
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", this.state.selectedFile[i].type);
            this.props.uploadAgreement(
              res.presignedS3Url,
              this.state.selectedFile[i],
              myHeaders
            );
          }
        );
      });

      // this.props.submit();
    }

    console.log("Test file",this.state.selectedFile);
    console.log("----length----", this.state.selectedFile.length);
  };

  fileUpload = (event) => {
    console.log("EVENT console", event.target.files);
    if (event.target.files.length) {
      fileObj.push(event.target.files);
      for (let i = 0; i < fileObj[0].length; i++) {
        fileArray.push(event.target.files["0"]);
      }
      this.setState({ selectedFile: fileArray, fileFlag: true }, () => {
        console.log("selectedFile", this.state.selectedFile)
        this.S3Upload();
      });
    }
    
  };
  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (userLang !== this.state.userLangs || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  } 
  handleResetInvoiceInput = () =>{
    console.log("selectedFile called")
  this.fileInput.current.value = "";
  this.props.handleResetInvoiceInput();
  // this.fileInput?.current?.value = null;
  fileArray=[]
  fileObj=[]
  this.setState({
    selectedFile:[],
    fileFlag: false,
    urlArrayValues:[]
    
  
  },
  ()=>{
    console.log("selectedFile reset", this.state.selectedFile)
  })
  }

  handleResetImageInput = () =>{
  this.fileInput.current.value = "";
  this.props.imageReset();
  // this.fileInput?.current?.value = null;
  fileArray=[]
  fileObj=[]
  this.setState({
    selectedFile:[],
    fileFlag: false,
    urlArrayValues:[]
    
  
  },
  ()=>{
    console.log("selectedFile reset", this.state.selectedFile)
  })
  }
  render() {
  
    darkMode = this.props?.mode
    return (
      <React.Fragment>
        <MDBContainer>
          <MDBModal
            isOpen={this.props.isOpen}
            toggle={() => this.props.toggle()}
            centered
            className="RaiseInvoiceModal"
          >
            <MDBModalBody className="invoice-modal-body">
              <Row>
                <i
                  className="fas fa-times closemodal p-3 close"
                  onClick={() => this.props.toggle()}
                ></i>
              </Row>
              <Row className="justify-content-between d-flex mb-3 ml-1">
                <div lg={2} md={2} xs={12} className="float-left">
                  <div
                    className="fontRoboto light__span"
                    style={{
                      color: "#0C0C0C",
                    }}
                  >
                    {this.state.userLangs?.invoice?.INPUT_INVOICE}
                  </div>
                </div>
              </Row>
              <Row>
                <Col xs="12">
                  <FormGroup className="invoiceinput fontRoboto15">
                    <Label className="required-field light__span" lg="12">
                      {
                        this.state.userLangs?.invoice
                          ?.TRANSPORTER_GST_INVOICE_NO
                      }
                    </Label>
                    <Input
                      value={this.props.gstNo}
                      type="text"
                      onChange={(e) => {
                        //console.log(e);
                        this.props.onChange({
                          target: {
                            name: "gstNo",
                            value: e.target.value,
                          },
                        });
                      }}
                      placeholder="Type Here..."
                    />
                     <span style={{fontWeight:400}}>{this.props?.errorMessage?.gstNo && <div className="error"> {this.props.errorMessage?.gstNo} </div>}</span>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className={`pr-0 ${this.props.mode ? "darkModeInvoiceModal" : ""}`}>
                  {this.props.resetDate ?
                  <FormGroup style={{
                    paddingRight:"12px"
                  }}>
                    <Label className="required-field light__span">
                      {" "}
                      {this.state.userLangs?.invoice?.INVOICE_DATE}
                    </Label>
                    <Datetime className="DateInput"
                      value={
                        this.props.FromDate
                          ? moment(this.props.FromDate).format()
                          : ""
                      }
                      style={{ height: "50px", backgroundColor: "#505d69" }}
                      onChange={(e) =>{
                        this.props.onChange({
                          target: {
                            name: "FromDate",
                            value: e._d,
                          },
                        })
                      }
                      
                      }
                      inputProps={{ placeholder: "select date..." }}
                      closeOnSelect
                      dateFormat="DD-MM-YYYY"
                      isValidDate={this.validCheck}
                      defaultValue={new Date()}
                      input={true}
                      ref={this.fromRef}
                      timeFormat={false}
                    />
                    <svg
                    onClick={() => {
                      let { open } = this.fromRef.current?.state;
                      // console.log(open, this.fromRef.current);
                      if (open === false) {
                        //console.log(open, "fromRef", this.fromRef.current);
                        this.fromRef.current._openCalendar();
                      }
                      if (open === true) {
                        //console.log(open,"else fromRef")

                        this.fromRef.current._closeCalendar();
                      }
                    }}
                    style={{
                      position:"relative",
                      left:"89%",
                      top:"-38px"
                    }}
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22 0H4C1.79086 0 0 1.79086 0 4V22C0 24.2091 1.79086 26 4 26H22C24.2091 26 26 24.2091 26 22V4C26 1.79086 24.2091 0 22 0Z"
                        fill={darkMode?"#1b1c31":"#F1F5F7"}
                      />
                      <path
                        d="M22 0.25H4C1.92893 0.25 0.25 1.92893 0.25 4V22C0.25 24.0711 1.92893 25.75 4 25.75H22C24.0711 25.75 25.75 24.0711 25.75 22V4C25.75 1.92893 24.0711 0.25 22 0.25Z"
                         stroke={darkMode?"#B7A9FF":"#2800FC"}
                        strokeWidth="0.5"
                      />
                      <path
                        d="M18 7H8C6.89543 7 6 7.89543 6 9V18C6 19.1046 6.89543 20 8 20H18C19.1046 20 20 19.1046 20 18V9C20 7.89543 19.1046 7 18 7Z"
                         stroke={darkMode?"#B7A9FF":"#2800FC"}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16 6V8"
                         stroke={darkMode?"#B7A9FF":"#2800FC"}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10 6V8"
                         stroke={darkMode?"#B7A9FF":"#2800FC"}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6 11H20"
                         stroke={darkMode?"#B7A9FF":"#2800FC"}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>{this.props?.errorMessage?.fromDate && <div className="error"> {this.props.errorMessage?.fromDate} </div>}</span>
                    {/* <Button
                    style={{ height: "50px" }}
                    className="btn calendar-icon-color darkCalenderCharges"
                    onClick={() => {
                      let { open } = this.fromRef.current?.state;
                      //console.log(open, this.fromRef.current);
                      if (open === false) {
                        //console.log(open, "fromRef", this.fromRef.current);
                        this.fromRef.current._openCalendar();
                      }
                      if (open === true) {
                        //console.log(open,"else fromRef")

                        this.fromRef.current._closeCalendar();
                      }
                    }}
                  >
                    <i className="fas fa-calendar"></i>
                  </Button> */}
                  </FormGroup> :null }
                </Col>
                {/* <Col xs={2} className="pl-0 cal raiseLine">
                  <Label></Label>
                  
                </Col> */}
              </Row>
              <div className="d-flex">
                <FormGroup className="select2-container required mt-1">
                  <input
                    type="file"
                    name="file"
                    style={{ display: "none" }}
                    ref={this.fileInput}
                    onChange={this.fileUpload}
                  />
                  <span
                    style={{
                      cursor: "pointer",
                      color: this.props.mode ? "#B7A9FF" : "#5664D2",
                    }}
                    onClick={this.fileUploadTrigger}
                  >
                    <svg
                      className="pr-2"
                      width="31"
                      height="31"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22 0H4C1.79086 0 0 1.79086 0 4V22C0 24.2091 1.79086 26 4 26H22C24.2091 26 26 24.2091 26 22V4C26 1.79086 24.2091 0 22 0Z"
                        fill={darkMode?"#1b1c31":"#F1F5F7"}
                      />
                      <path
                        d="M22 0.25H4C1.92893 0.25 0.25 1.92893 0.25 4V22C0.25 24.0711 1.92893 25.75 4 25.75H22C24.0711 25.75 25.75 24.0711 25.75 22V4C25.75 1.92893 24.0711 0.25 22 0.25Z"
                         stroke={darkMode?"#B7A9FF":"#2800FC"}
                        strokeWidth="0.5"
                      />
                      <path
                        d="M19.4383 12.6798L13.085 19.0331C12.3067 19.8115 11.251 20.2487 10.1503 20.2487C9.04957 20.2487 7.99393 19.8115 7.21559 19.0331C6.43726 18.2548 6 17.1992 6 16.0984C6 14.9977 6.43726 13.9421 7.21559 13.1637L13.5689 6.8104C14.0878 6.29151 14.7916 6 15.5254 6C16.2592 6 16.963 6.29151 17.4819 6.8104C18.0008 7.32928 18.2923 8.03305 18.2923 8.76686C18.2923 9.50068 18.0008 10.2044 17.4819 10.7233L11.1216 17.0767C10.8622 17.3361 10.5103 17.4819 10.1434 17.4819C9.77647 17.4819 9.42459 17.3361 9.16515 17.0767C8.90571 16.8172 8.75995 16.4653 8.75995 16.0984C8.75995 15.7315 8.90571 15.3796 9.16515 15.1202L15.0346 9.25771"
                         stroke={darkMode?"#B7A9FF":"#2800FC"}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    {this.state.userLangs?.invoice?.ATTACH_TOPSHEET}
                    {this.props?.errorMessage?.documents && <div className="error"> {this.props.errorMessage?.documents} </div>}
                  </span>
                  {this.state.fileFlag
                    ? this.state.selectedFile.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="container"
                            style={{ display: "flex" }}
                          >
                            <div style={{ width: "30%" }}>
                              <i className="fas fa-file-alt fa-2x m-1"></i>
                              {item?.name}
                            </div>
                            <div style={{ flexGrow: "1" }}>
                              <i
                                className="fas fa-times"
                                style={{ cursor: "pointer", color: "red" }}
                                onClick={() => {
                                  let selectedFilesCopy =
                                    this.state.selectedFile;
                                  selectedFilesCopy.splice(index, 1);
                                  if (this.state.selectedFile.length == 0)
                                    this.setState({ fileFlag: false },()=>{
                                      if(!this.state.fileFlag){
                                        this.handleResetImageInput()
                                      }
                                    });
                                  this.setState({
                                    signedURL: "",
                                    selectedFile: selectedFilesCopy?selectedFilesCopy:"",
                                    uploadData: "",
                                    
                                  });
                                }}
                              ></i>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </FormGroup>
              </div>
              <Row>
                <Col xs="4">
                  <MDBBtn
                    className="fontRoboto1541"
                    style={{
                      width: "100%",
                      height: "50px",
                      backgroundColor: "#0AB38C",
                      color: "#FFFFFF",
                    }}
                    color="secondary"
                    onClick={()=> this.handleResetInvoiceInput()}
                    >
                    Reset
                    {/* {userLang?.common?.RESET} */}
                  </MDBBtn>
                </Col>
                <Col xs="4">
                  <MDBBtn
                    className="fontRoboto1541"
                    style={{
                      width: "100%",
                      height: "50px",
                      backgroundColor: this.props.mode ? "#5E40FF" : "#05284E",
                      color: "#FFFFFF",
                    }}
                    color="primary"
                    onClick={() => {
                      // this.S3Upload();
                      this.props.submit(this.state.urlArrayValues)
                    }}
                    disabled={this.props.disabled}
                  >
                    {this.state.userLangs?.common?.SUBMIT}
                  </MDBBtn>
                </Col>
                <Col xs="4"></Col>
              </Row>
            </MDBModalBody>
          </MDBModal>
        </MDBContainer>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  uploadAgreement,
  fetchSignedURL,
})(RaiseInvoceModal);
