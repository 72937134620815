import React, { Component, useRef, useEffect } from "react";


const AutoComplete = (props) => {
    
    const autoCompleteRef = useRef();
    const inputRef = useRef();
    const options = {};
    useEffect(() => {
        console.log(autoCompleteRef)
        console.log(inputRef)
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
            inputRef.current,
            options
        );
        autoCompleteRef.current.addListener("place_changed", async function () {
            const place = await autoCompleteRef.current.getPlace();
            props.callback(place)
        });
    }, []);
    return (
        <>
            <input style={{
                height: "40px",
                width: "68%",
                border: "0.5px solid #AEAEAE",
                borderRadius: "5px",
                backgroundColor: "#ffffff",
            }} ref={inputRef} />
        </>
    );
};
export default AutoComplete;
