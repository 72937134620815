import React, { Component } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  FormGroup,
  UncontrolledTooltip,
  Badge,
} from "reactstrap";
import { MoonLoader } from "react-spinners";
import Breadcrumbs from "../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import {
  fetchIndents,
  fetchIndentsExcel,
  fetchCreatedIndentInfo,
  assignIndent,
  rejectIndent,
  approveIndent,
} from "../store/indent/actions";
import {
  fetchAvailVehicles,
  fetchVehicles,
  createVehicle,
} from "../store/vehicle/actions";
import { fetchVehicleTypesList } from "../store/vehicleType/actions";
import {
  fetchAvailDrivers,
  fetchDrivers,
  createDriver,
} from "../store/driver/actions";
import { getTransporters } from "../store/transporter/actions";
import { createTrip } from "../store/trip/actions";
import { uploadIndent } from "../store/indent/actions";
import { createBulkIndent } from "../store/indent/actions";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import { MDBDataTable, MDBIcon, MDBCol } from "mdbreact";
import Pagination from "@material-ui/lab/Pagination";
import formatDate from "./DateFormatter";
import { getAccessForEntity } from "../pages/policies";
import ModalTransporter from "./ModalTransporter";
import ApproveModal from "./ApproveModal";
import CancelIndentModal from "./CancelIndentModal";
import { MDBBtn } from "mdbreact";
import Uploadform from "../components/Common/UploadForm";
import Sample from "../../src/IndentUpload.csv";
import { restrict } from "./restrict";
import IndentData from "./indentData";
import { date } from "yup/lib/locale";
import Select from "react-select";
import { PERMS } from "../enum/perms.enum";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";

var data = {};
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var totCntExcel = 0;
const options = [
  { value: "source", label: "Source" },
  { value: "destination", label: "Destination" },
  { value: "status", label: "Status" },
];
const filterDate = [
  { value: "2", label: "Yesterday" },
  { value: "1", label: "Today" },
  { value: "7", label: "This Week" },
  { value: "30", label: "This Month" },
];
var indentExcel = [];
class CreatorIndent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Indent", link: "#" },
        { title: "Indent Planning", link: "#" },
      ],
      loading: true,
      showUpload: false,
      selectedFiles: [],
      errorMsg: "",
      showModalUploadForm: false,
      page: "creator-indent",
      heads: [],
      resolved: null,
      data: [],
      filters: "",
      modalIndentDetails: false,
      indentData: {},
      searchKey: "",
      searchValue: "",
      disabled: true,
      searchOn: false,
      keySelected: false,
      radioFilter: "",
      indentId: "",
      cancelModal: false,
      modalApproval: false,
      driver: "",
      vehicle: "",
      onSubmitDisableVehicle: false,
      onSubmitDisableDriver: false,
      export: false,
      toBeSelectedVehicleType: null,
      transporter: "",
    };
    this.indents = [];
    (async () => {
      let redirect = await restrict(PERMS.INDENT_PLANNER_READ);

      if (redirect == false) {
        this.props.history.push("/operationsDashboard");
      }
    })();
  }

  componentDidMount = () => {
    this.getAllData();
    this.props.fetchVehicleTypesList(data || {}, () => {});
  };

  componentDidUpdate(prevProps) {
    //   this.setState({policies:this.props.policies})
    //  this.getAllData();
  }

  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };
  getAllData = () => {
    let offset = 0;
    let key;
    if (this.state.searchKey === "") {
      key = "";
    } else key = this.state.searchKey.value;
    this.props.fetchIndents(
      offset,
      this.state.filters,
      key,
      this.state.searchValue,
      this.state.radioFilter ? this.state.radioFilter.value : "",
      (res) => {}
    );
    this.setState({ loading: true });
    this.props.fetchVehicles(0, "");
    this.props.fetchDrivers(0, "", "", { q: "" });
    this.refineData();
  };

  refineData = () => {
    this.setState({ loading: false });
  };

  handleChangePage = (event, val) => {
    let key;
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    toVal = offset + process.env.REACT_APP_PERPAGE;
    if (this.state.searchKey === "") {
      key = "";
    } else key = this.state.searchKey.value;
    this.props.fetchIndents(
      offset,
      this.state.filters,
      key,
      this.state.searchValue,
      this.state.radioFilter ? this.state.radioFilter.value : "",
      (res) => {}
    );
    this.setState({ loading: true });
    this.refineData();
  };

  toggleExcel = async () => {
    let offset = 0;
    console.log(totCnt, "tcnt");
    let limit = totCnt;
    let key;
    let rt = "";
    if (this.state.searchKey === "") {
      key = "";
    } else key = this.state.searchKey.value;
    this.props.fetchIndentsExcel(
      offset,
      limit,
      this.state.filters,
      key,
      this.state.searchValue,
      this.state.radioFilter ? this.state.radioFilter.value : ""
    );
    this.setState({ loading: true, export: true });
    this.props.fetchVehicles(offset, "");
    this.props.fetchDrivers(offset, "", "", { q: "" });
    this.refineData();
    setTimeout(async () => {
      let indents = [];
      if (Array.isArray(this.props.indentsExcel?.data?.docs)) {
        totCntExcel = this.props.indentsExcel?.data?.length;
        fromVal = totCnt !== 0 ? offset + 1 : fromVal;
        toVal =
          this.props.indentsExcel?.data?.offset +
          this.props.indentsExcel?.data?.docs?.length;
        totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
        this.props.indentsExcel.data.docs.forEach((ele, i) => {
          // console.log(ele.route.via.length?ele.route.via.map((e, j)=>{
          //   let str=''
          //   console.log(str,"str",e);
          //   return str+=e.label
          // }):'',"route",i)
          indents.push({
            IndentId: ele.indentId ? ele.indentId : "---",
            Source: ele.source?.label || "---",
            Destination: ele.destination?.label || "---",
            Vehicle: ele.vehicle?.type?.label || "---",
            Transporter: ele.transporter?.name || "---",
            Route: ele.route.via?.length
              ? ele.route.via.map((e, i) => {
                  console.log(rt, "str", e);
                  return (rt += e.label);
                })
              : "DIRECT",
            // description: ele.description,
            StartTime: ele.startTime
              ? moment(ele.startTime).format("DD-MM-YYYY h:mm A", true)
              : "---",
            ExpiryTime: ele.expiryTime
              ? moment(ele.expiryTime).format("DD-MM-YYYY h:mm A", true)
              : "---",
            Status: ele?.status,
          });
        });
      }
      await this.exportToCSV([...indents], "INDENT_DATA_EXCEL");
    }, 10000);
  };

  exportToCSV = (csvData, fileName) => {
    // console.log(csvData,"csv")
    // csvData = csvData?.map(function (item) {
    //   for (var key in item) {
    //     var upper = key.toUpperCase();
    //     // check if it already wasn't uppercase
    //   }
    //   return item;
    // });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    this.setState({ export: false });
  };
  handleUploadSubmit = async (file) => {
    let rows = [];
    let selectedFiles = file;
    if (selectedFiles.length === 0) {
      this.setState({ errorMsg: "Please upload file" });
    } else {
      let formData = new FormData();
      // formData.file = selectedFiles[0];
      // let file = { ...selectedFiles[0] };
      formData.append("file", file[0]);
      this.props.uploadIndent(formData, (res) => {
        let data = [];
        let resolved = [];
        if (res?.data && res?.data.length > 0) {
          res.data.map((ele) => {
            let dt = {};
            dt.source = ele?.source;
            dt.destination = ele?.destination;
            dt.route = ele?.route;
            dt.vehicleType = ele?.vehicleType;
            dt.description = ele?.description;
            dt.status = ele?.isValid ? (
              <i className="fas fa-check text-success"></i>
            ) : (
              <i className="fas fa-times text-danger"></i>
            );
            data.push(dt);
            if (ele?.isValid) resolved.push(ele.resolved);
          });
        }
        this.setState({
          data: data,
          heads: data.length ? Object.keys(data[0]) : [],
          resolved: resolved,
        });
      });

      // this.setState({ rows });
      //Call validation API
      //Use the response from above and feed into the modal to populate inmodal
      //logic function from props
      this.showToggle();
    }
  };

  showToggle = () => {
    //only for modal
    this.setState({ showModalUploadForm: !this.state.showModalUploadForm });
  };

  createIndent = () => {
    // this.toggleExcel();
    this.showToggle();
    let values = {};
    values.indents = this.state.resolved;
    this.props.createBulkIndent(values, (res) => {
      if (res && res["message"] && res["message"] === "Successfully Added") {
        this.getAllData();
        this.props.history.push("/indent-planning");
        NotificationManager.success(res["message"], "Success");
      } else {
        this.getAllData();
        NotificationManager.error(res["message"], "Error");
        this.setState({ onSubmitDisable: false });
        this.props.history.push("/indent-planning");
      }
    });
  };

  actions = (ele) => {
    switch (ele.status) {
      case "Drafted":
        return (
          <span>
            <MDBBtn
              className="mt-3 color_edit_button"
              onClick={() => this.draftTofinal(ele)}
            >
              <i className="fas fa-pen"></i>
            </MDBBtn>
          </span>
        );
      case "Rejected":
        return (
          <span>
            <MDBBtn
              className="mt-3 color_edit_button"
              onClick={() => this.draftTofinal(ele)}
            >
              <i className="fas fa-pen"></i>
            </MDBBtn>
          </span>
        );
      case "AutoRejected":
        return (
          <span>
            <MDBBtn
              className="mt-3 color_edit_button"
              onClick={() => this.draftTofinal(ele)}
            >
              <i className="fas fa-pen"></i>
            </MDBBtn>
          </span>
        );
      case "Assigned":
        return (
          <span>
            <MDBBtn
              className="mt-3 color_edit_button"
              style={{ backgroundColor: "red" }}
              color="danger"
              onClick={() => {
                this.setState({ indentId: ele._id, cancelModal: true });
              }}
            >
              Cancel
            </MDBBtn>
          </span>
        );
      case "Reassigned":
        return (
          <span>
            <MDBBtn
              className="mt-3 color_edit_button"
              color="danger"
              style={{ backgroundColor: "red" }}
              onClick={() => {
                this.setState({ indentId: ele._id, cancelModal: true });
              }}
            >
              Cancel
            </MDBBtn>
          </span>
        );
      case "Accepted":
        return (
          <span>
            <MDBBtn
              className="mt-3 dataTableButtons"
              onClick={() => {
                this.setState({
                  toBeSelectedVehicleType: {
                    value: ele.vehicle.type._id,
                    label: ele.vehicle?.type?.label,
                  },
                });
                this.props.fetchDrivers(0, "", "", {
                  q: "",
                  transporterId: ele.transporter._id,
                  noLimit: true,
                });
                this.props.fetchVehicles(0, {
                  transporterId: ele.transporter._id,
                  vehicleTypeId: ele.vehicle.type._id,
                  noLimit: true,
                });
                this.setState(
                  { indentId: ele._id, transporter: ele.transporter._id },
                  () => {
                    this.toggleModalApproval();
                  }
                );
              }}
            >
              Provide Details
            </MDBBtn>
          </span>
        );
      default:
        return (
          <span>
            N/A
            {/* <MDBBtn
              className="mt-3 color_edit_button"  componentDidUpdate = () => {
    if (this.state.documentDownloadError)
      this.setState({
        documentDownloadError: false,
        documentDownloadErrorId: "",
      });
  };
              onClick={() => this.draftTofinal(ele)}
              disabled={true}
            >
              <i className="fas fa-pen"></i>
            </MDBBtn> */}
          </span>
        );
    }
  };

  draftTofinal = (ele) => {
    window.history.replaceState(null, null, "/");
    let data = ele;
    data.source = {
      label: ele.source?.label ? ele.source.label : "",
      value: { id: ele.source._id },
    };
    data.destination = {
      label: ele.destination.label ? ele.destination.label : "",
      value: { id: ele.destination._id },
    };
    data.route = ele.route
      ? {
          label: this.route(ele.route.via),
          value: { id: ele.route._id },
        }
      : null;
    data.transporter = {
      name: ele.transporter?.name ? ele.transporter.name : "",
      value: { id: ele.transporter?._id },
    };
    data.vehicle.type = {
      label: ele.vehicle.type.label ? ele.vehicle.type.label : "",
      value: { id: ele.vehicle.type._id ? ele.vehicle.type._id : "" },
    };
    data.startTime = ele.startTime || null;
    data.expiryTime = ele.expiryTime || null;
    date.rejectionHistory = ele.rejectionHistory;
    data.comment = ele.description;
    this.props.history.push({
      pathname: "/add-indent",
      indent: data,
      mode: "edit",
      indentID: ele._id,
    });
  };

  route = (via) => {
    // return via?.map((e, i) => (
    //   <div key={i}>
    //     {i ? (
    //       <div className="text-left pr-4" style={{ fontSize: "0.6em" }}>
    //         <i
    //           style={{ color: "#5664D2" }}
    //           className="fas fa-ellipsis-v ml-1"
    //         ></i>
    //       </div>
    //     ) : null}

    //     <div className="text-left d-flex align-items-center flex-nowrap">
    //       <i
    //         style={{ color: "#5664D2" }}
    //         className="fas fa-circle iconSize"
    //       ></i>
    //       <span className="ml-2">{e.label}</span>
    //     </div>
    //   </div>
    // ));
    return (
      <ul className="bar">
        {via?.map((e, i) => (
          <li key={i}>
            <span
              style={{
                textAlign: "right",
                position: "relative",
                whiteSpace: "nowrap",
              }}
            >
              {e.label}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  clearUpload = () => {
    this.setState({ data: [], resolved: null });
  };

  status = (status, lastRejection, id) => {
    switch (status) {
      case "Accepted":
        return (
          <strong className="tile-text h6 accepted-color">{status}</strong>
        );
      case "Rejected":
        return (
          <>
            <div id={"status-" + id}>
              <strong className="tile-text h6 rejected-color">{status}</strong>
            </div>
            <UncontrolledTooltip
              placement="top"
              autohide={true}
              target={"status-" + id}
            >
              <div>
                <b>{lastRejection?.reason ? lastRejection?.reason : "N/A"}</b>
              </div>
              {lastRejection?.remark ? lastRejection?.remark : "N/A"}
            </UncontrolledTooltip>
          </>
        );
      case "AutoRejected":
        return (
          <>
            <div id={"status-" + id}>
              <strong className="tile-text h6 rejected-color">{status}</strong>
            </div>
            <UncontrolledTooltip
              placement="top"
              autohide={true}
              target={"status-" + id}
            >
              <div>
                <b>{lastRejection?.reason ? lastRejection?.reason : "N/A"}</b>
              </div>
              {lastRejection?.remark ? lastRejection?.remark : "N/A"}
            </UncontrolledTooltip>
          </>
        );
      case "Assigned":
        return (
          <strong className="tile-text h6 assigned-color">{status}</strong>
        );
      case "Reassigned":
        return (
          <strong className="tile-text h6 assigned-color">{status}</strong>
        );
      case "Finalized":
        return (
          <strong className="tile-text h6 finalized-color">{status}</strong>
        );
      case "Drafted":
        return <strong className="tile-text h6 drafted-color">{status}</strong>;
      case "Expired":
        return <strong className="tile-text h6 expired-color">{status}</strong>;
      case "Cancelled":
        return (
          <strong className="tile-text h6 cancelled-color">{status}</strong>
        );
      default:
        break;
    }
  };
  handleClick = (e, ele) => {
    console.log("reach 123", e.type, e.detail);
    if (e.detail == 1) {
      e.preventDefault();
      return;
    }
    if (e.target.type != "button") {
      this.props.fetchCreatedIndentInfo(ele._id, (result) => {
        this.setState({ IndentData: result.data }, () => {
          this.toggleIndentModal();
        });
      });
    }
  };
  toggleIndentModal = () => {
    if (!this.state.cancelModal) {
      this.setState({ modalIndentDetails: !this.state.modalIndentDetails });
    }
  };

  searchKeySetter = (e) => {
    this.setState({ searchKey: e, keySelected: true }, () => {
      if (this.state.searchKey !== "" && this.state.searchValue !== "")
        this.setState({ disabled: false });
      if (this.state.searchValue === "") {
        this.setState({ disabled: true });
      }
    });
  };

  searchValueSetter = (e) => {
    this.setState({ searchValue: e.target.value, searchOn: true }, () => {
      if (this.state.searchKey !== "" && this.state.searchValue !== "")
        this.setState({ disabled: false });
      if (this.state.searchValue === "") {
        this.setState({ disabled: true });
      }
    });
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (this.state.disabled === false) {
        this.getAllData();
      }
    }
  };
  toggleRejectModal = () => {
    this.setState({ cancelModal: false });
  };
  reset = (e) => {
    this.setState(
      {
        searchKey: "",
        searchValue: "",
        disabled: true,
        keySelected: false,
        searchOn: false,
        radioFilter: "",
      },
      () => {
        this.getAllData();
      }
    );
  };

  handleDuration = (e) => {
    let value = e.value;
    this.setState({ radioFilter: e }, () => {
      if (this.state.searchOn !== true) this.setState({ searchOn: true });
      this.getAllData();
    });
  };
  toggleModalApproval = () => {
    this.setState({
      modalApproval: !this.state.modalApproval,
      vehicleForm: {
        VehicleOwner: "",
        VehicleRegistrationNumber: "",
        type: null,
      },
      driverForm: {
        fullName: "",
        phone: "",
        driverLicenseNo: "",
      },
    });
  };

  handleChangeModal = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  filterVehicleList = (e) => {
    if (e.length > 2) {
      this.props.fetchVehicles(null, {
        q: e,
        transporterId: this.state.transporter,
      });
    }
    if (e.length === 0) {
      this.props.fetchVehicles(0, {
        transporterId: this.state.transporter,
      });
    }
  };

  filterDriverList = (e) => {
    if (e.length > 2) {
      this.props.fetchDrivers(0, "", "", {
        name: e,
        transporterId: this.state.transporter,
      });
    }
    if (e.length === 0) {
      this.props.fetchDrivers(0, "", "", {
        transporterId: this.state.transporter,
      });
    }
  };

  handleSubmitSaveVehicle = () => {
    let {
      VehicleOwner,

      VehicleRegistrationNumber,
      type,
    } = this.state.vehicleForm;
    let formObj = {
      ownerName: VehicleOwner,
      transporter: this.state.transporter,
      regNo: VehicleRegistrationNumber,
      type: type.value,
    };
    this.setState({ onSubmitDisableVehicle: true });
    this.props.createVehicle(formObj, (res) => {
      if (res.success) {
        this.props.fetchVehicles();
        NotificationManager.success(res["message"], "Success");
        this.setState({ onSubmitDisableVehicle: false });
      } else {
        NotificationManager.error(res["message"], "Error");
        this.setState({
          onSubmitDisableVehicle: false,
          vehicle: "",
          vehicleForm: {
            VehicleOwner: "",
            VehicleRegistrationNumber: "",
            type: null,
          },
        });
      }
    });

    setTimeout(() => {
      let vh;
      if (Array.isArray(this.props.vehicles?.vehicles?.data?.docs)) {
        vh = this.props.vehicles?.vehicles?.data?.docs.filter((v) => {
          return v.ownerName == this.state.vehicleForm.VehicleOwner;
        });
      }
      this.setState({
        vehicle: {
          value: vh[0]._id,
          label: vh[0].registration.number + " (" + vh[0].type.label + ")",
        },
        vehicleForm: {
          VehicleOwner: "",
          VehicleRegistrationNumber: "",
          type: null,
        },
      });
    }, 4000);
  };

  onVehicleChange = (e) => {
    let { name, value } = e.target;
    let vf = { ...this.state.vehicleForm };
    if (name == "vehicleType") {
      vf.type = value;
    }
    if (name == "VehicleOwner") {
      vf["VehicleOwner"] = value;
    }
    if (name == "VehicleRegistrationNumber") {
      vf["VehicleRegistrationNumber"] = value;
    }
    this.setState({ vehicleForm: vf });
  };

  handleSubmitSaveDriver = () => {
    let { fullName, phone, driverLicenseNo } = this.state.driverForm;
    let formObj = {
      name: fullName,
      transporter: this.state.transporter,
      phone,
      licence: { number: driverLicenseNo },
    };
    this.setState({ onSubmitDisableDriver: true });
    this.props.createDriver(formObj, (res) => {
      if (res.message == "Driver has been created successfully") {
        this.props.fetchDrivers(0, "", "", { q: "" });

        NotificationManager.success(res["message"], "Success");
        this.setState({ onSubmitDisableDriver: false });
      } else {
        NotificationManager.error(res["message"], "Error");
        this.setState({
          onSubmitDisableDriver: false,
          driver: "",
          driverForm: {
            fullName: "",
            phone: "",
            driverLicenseNo: "",
          },
        });
      }
    });
    setTimeout(() => {
      let dt;
      if (Array.isArray(this.props.drivers?.drivers?.data?.docs)) {
        dt = this.props.drivers?.drivers?.data?.docs.filter((v) => {
          return v.name == this.state.driverForm.fullName;
        });
      }
      this.setState({
        driver: {
          value: dt[0]?._id,
          label: dt[0]?.phone + " (" + dt[0]?.name + ")" || "" + ")",
        },
        driverForm: {
          fullName: "",
          phone: "",
          driverLicenseNo: "",
        },
      });
    }, 4000);
  };
  onDriverChange = (e) => {
    let { name, value } = e.target;
    let df = { ...this.state.driverForm };
    if (name == "phone") {
      value = e.target?.value;
      df.phone = value;
    }
    if (name == "driverLicenseNo") {
      df["driverLicenseNo"] = value;
    }
    if (name == "fullName") {
      df["fullName"] = value;
    }
    this.setState({ driverForm: df });
  };

  resetSelection = () => {
    this.setState({ vehicle: "", driver: "" });
  };

  render() {
    const {
      loading,
      searchKey,
      searchValue,
      keySelected,
      searchOn,
      radioFilter,
    } = this.state;
    this.policy = this.props.policies;
    const { vehicles, drivers } = this.props;
    let vehicleType = this.props.vehicleType.vehicleType;
    var show = false;
    let rt = "";
    let indents = [];
    let vehicleOptions = [];
    let driverOptions = [];
    let vehicleTypeOptions = [];

    if (Array.isArray(this.props.indents?.data?.docs)) {
      offset = this.props.indents?.data?.offset;
      totCnt = this.props.indents?.data?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal =
        this.props.indents?.data?.offset +
        this.props.indents?.data?.docs?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      this.props.indents.data.docs.forEach((ele) => {
        indents.push({
          id: ele._id,
          indentId: ele.indentId ? ele.indentId : "---",
          source: ele.source?.label || "---",
          destination: ele.destination?.label || "---",
          vehicle: ele.vehicle?.type?.label || "---",
          transporter: ele.transporter?.name || "---",
          route: ele.route.via?.length ? (
            <>{this.route(ele.route.via)}</>
          ) : (
            "DIRECT"
          ),
          description: ele.description,
          startTime: ele.startTime ? formatDate(ele.startTime) : "---",
          expiryTime: ele.expiryTime ? formatDate(ele.expiryTime) : "---",
          status: this.status(ele.status, ele.lastRejection, ele._id) || "---",
          actions: this.actions(ele),
          clickEvent: (e) => this.handleClick(e, ele),
        });
      });
    }

    if (Array.isArray(vehicles?.vehicles?.data?.docs)) {
      vehicles.vehicles.data.docs.forEach((ele) => {
        vehicleOptions.push({
          value: ele._id,
          label: ele.registration.number + " (" + ele.type.label + ")",
        });
      });
    }

    if (Array.isArray(vehicleType?.data?.docs)) {
      vehicleType.data.docs.forEach((ele) => {
        vehicleTypeOptions.push({
          value: ele._id,
          label: ele.vehicleTypeCode,
        });
      });
    }
    if (Array.isArray(drivers?.drivers?.data?.docs)) {
      drivers.drivers.data.docs.forEach((ele) => {
        driverOptions.push({
          value: ele._id,
          label: ele.phone + " (" + ele.name + ")",
        });
      });
    }

    if (this.policy == "All policy") {
      show = true;
    }
    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#123abc"} />
          </Col>
        </div>
      );
    }
    data = {
      columns: [
        {
          label: <b>Indent Id</b>,
          field: "indentId",
          sort: "asc",
          width: 300,
        },
        {
          label: <b>Source</b>,
          field: "source",
          sort: "asc",
          width: 300,
        },
        {
          label: <b>Destination</b>,
          field: "destination",
          sort: "asc",
          width: 300,
        },
        {
          label: <b>Route</b>,
          field: "route",
          sort: "asc",
          width: 50,
        },
        {
          label: <b>Transporter</b>,
          field: "transporter",
          sort: "asc",
          width: 300,
        },
        {
          label: <b>Vehicle</b>,
          field: "vehicle",
          sort: "asc",
          width: 100,
        },

        {
          label: <b>Start Time</b>,
          field: "startTime",
          sort: "asc",
          width: 300,
        },
        {
          label: <b>Expiry Time</b>,
          field: "expiryTime",
          sort: "asc",
          width: 300,
        },
        {
          label: <b>Status</b>,
          field: "status",
          sort: "asc",
          width: 300,
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
          width: 100,
        },
      ],
      rows: indents,
    };

    const customStyles = {
      indicatorsContainer: (style) => {
        return {
          ...style,
          backgroundColor: "#5664D2",
          width: "54px",
          height: "37px",
          textAlign: "center",
          justifyContent: "center",
          borderRadius: "0px 4px 4px 0px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          backgroundColor: "#5664D2",
        };
      },
      control: (style) => {
        return {
          ...style,
          width: "241px",
          height: "37px",
          backgroundColor: "#F1F5F7",
        };
      },
    };

    return (
      <React.Fragment>
        <ModalTransporter
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          // transporter={this.state.transporter}
          onChange={this.handleChange}
          options={this.state.transportersList}
          submit={this.handleSubmitTransporter}
          disabled={this.state.onSubmitDisable}
        />
        <ApproveModal
          isOpen={this.state.modalApproval}
          toggle={this.toggleModalApproval}
          onChange={this.handleChangeModal}
          options={vehicleOptions}
          optionsdriver={driverOptions}
          driver={this.state.driver}
          vehicle={this.state.vehicle}
          submit={this.handleSubmitApprove}
          disabledVehicle={this.state.onSubmitDisableVehicle}
          disabledDriver={this.state.onSubmitDisableDriver}
          indentIndex={this.state.indentId}
          filterVehicleList={this.filterVehicleList}
          filterDriverList={this.filterDriverList}
          type={vehicleTypeOptions}
          refreshData={this.getAllData}
          submitVehicleForm={this.handleSubmitSaveVehicle}
          onVehicleChange={this.onVehicleChange}
          vehicleForm={this.state.vehicleForm}
          submitDriverForm={this.handleSubmitSaveDriver}
          onDriverChange={this.onDriverChange}
          driverForm={this.state.driverForm}
          resetSelection={this.resetSelection}
          toBeSelectedVehicleType={this.state.toBeSelectedVehicleType}
          transporter={this.state.transporter}
        />
        <CancelIndentModal
          isOpen={this.state.cancelModal}
          toggle={this.toggleRejectModal}
          indentIndex={this.state.indentId}
          refreshData={this.getAllData}
        />
        <div /* className="page-content" */>
          {!this.state.showUpload ? (
            <Container fluid className="mt-2">
              <Row>
                <Col xs={12}>
                  <Card>
                    <CardBody className="px-3 py-4">
                      <div className="justify-content-between d-flex mb-3">
                        <div className="float-left">
                          <div className=" d-flex flex-wrap align-items-start float-right">
                            <div className="pb-1 mr-4">
                              <Select
                                name="radioFilter"
                                onChange={this.handleDuration}
                                placeholder="Select duration"
                                options={filterDate}
                                value={radioFilter}
                                styles={customStyles}
                              />
                            </div>
                            <div className="pb-1 mr-4">
                              <Select
                                name="SearchKey"
                                placeholder="Search Type"
                                onChange={this.searchKeySetter}
                                options={options}
                                value={searchKey}
                                styles={customStyles}
                              />
                            </div>
                            <div className="pb-1 mr-4">
                              <form className="">
                                <Row>
                                  <Col
                                    xs={10}
                                    className="pr-0"
                                    style={{ display: "contents" }}
                                  >
                                    <input
                                      style={{ height: "37px" }}
                                      className="form-control searchBox form-control-sm w-100 sharp-corners-right rounded-left"
                                      type="text"
                                      name="SearchValue"
                                      value={searchValue}
                                      onChange={this.searchValueSetter}
                                      placeholder="Search"
                                      aria-label="Search"
                                      onKeyPress={this.handleKeyDown}
                                      disabled={!keySelected}
                                      icon="fas fa-times-circle"
                                    />
                                  </Col>
                                  <Col xs={2} className="pl-0">
                                    <Button
                                      style={{
                                        height: "37px",
                                        width: "54px",
                                      }}
                                      color="primary"
                                      className="btn sharp-corners-left rounded-right"
                                      onClick={this.getAllData}
                                      disabled={this.state.disabled}
                                    >
                                      <MDBIcon icon="search" />
                                    </Button>
                                  </Col>
                                </Row>
                              </form>
                            </div>
                            <div className="pb-1 mr-2">
                              <Button
                                color="primary"
                                className="btn rounded-black button-wide"
                                onClick={this.reset}
                                disabled={!searchOn}
                              >
                                Reset
                              </Button>
                            </div>
                          </div>
                        </div>
                        <Row>
                          <Col xs={12}>
                            <Button
                              type="button"
                              color="link"
                              className="small align-middle rounded-black mb-1"
                              disabled={this.state.export ? true : false}
                              onClick={() => {
                                this.toggleExcel();
                              }}
                              style={{
                                float: "right",
                                margin: "2px 2px 6px 2px",
                              }}
                            >
                              <span style={{ fontWeight: "900" }}>Export</span>
                            </Button>{" "}
                            <Link
                              to="/add-indent"
                              className="small align-middle"
                            >
                              <Button
                                type="button"
                                color="btn btn-primary"
                                className="mb-1 mr-2 rounded"
                                style={{
                                  float: "right",
                                  margin: "2px 2px 6px 2px",
                                }}
                              >
                                <span style={{ fontWeight: "900" }}>
                                  Add Indent
                                </span>
                              </Button>
                            </Link>
                          </Col>
                        </Row>
                      </div>
                      <div className="dataTableStyles">
                        <MDBDataTable
                          className="text-center text-capitalize"
                          responsive
                          data={data}
                          paging={false}
                          searching={false}
                        />
                        {totCnt !== 0 ? (
                          <Row xs={12}>
                            <Col xs={6}>
                              <span>
                                Showing {fromVal} to {toVal} of {totCnt} entries
                              </span>
                            </Col>
                            <Col xs={6}>
                              <div className="float-right">
                                <Pagination
                                  page={offset / 10 + 1}
                                  shape="rounded"
                                  count={totPages}
                                  color="primary"
                                  onChange={this.handleChangePage}
                                />
                              </div>
                            </Col>
                          </Row>
                        ) : null}
                      </div>
                      <FormGroup
                        style={{ marginBottom: "1px", marginTop: "16px" }}
                      ></FormGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          ) : (
            <Container fluid>
              {/* <Uploadform
                csvUrl={Sample}
                handleSubmit={this.handleUploadSubmit}
                showModal={this.state.showModalUploadForm}
                showToggle={this.showToggle}
                rows={this.state.data}
                resolved={this.state.resolved}
                page={this.state.page}
                heads={this.state.heads}
                insertData={this.createIndent}
                clearUpload={this.clearUpload}
                cancel={this.toggleExcel}
              /> */}
            </Container>
          )}
        </div>
        <Modal
          isOpen={this.state.modalIndentDetails}
          toggle={this.toggleIndentModal}
          size="lg"
        >
          <ModalBody className="indentModal">
            <IndentData
              data={this.state.IndentData}
              formatDate={formatDate}
              routeConvert={this.route}
              header={"Indent Details"}
              closeModal={this.toggleIndentModal}
            />
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    indents: state.Indent.indents.creatorIndents,
    policies: state.AccessPolicy.accessPolicies.policies,
    vehicleType: state.VehicleType.vehicleType,
    vehicles: state.Vehicle.vehicles,
    drivers: state.Driver.drivers,
    indentsExcel: state.Indent.indents.excelIndents,
  };
};

export default connect(mapStateToProps, {
  fetchIndents,
  fetchIndentsExcel,
  getTransporters,
  assignIndent,
  fetchAvailVehicles,
  fetchVehicleTypesList,
  fetchAvailDrivers,
  rejectIndent,
  approveIndent,
  createTrip,
  uploadIndent,
  createBulkIndent,
  fetchCreatedIndentInfo,
  fetchVehicles,
  createVehicle,
  fetchDrivers,
  createDriver,
})(CreatorIndent);
