import React, { Component } from "react";
import { Row, Col, Container } from "reactstrap";
import { NotificationManager } from "react-notifications";
import { MoonLoader } from "react-spinners";
import Index from "../../components/Common/DataTableNeo/Index";
import {
  fetchTrips,
  fetchTripsExcel,
  startLoading,
  finishLoading,
  fetchTransporterTrips,
  shipmentVerify,
  change_arrivedAtSource_status,
} from "../../store/trip/actions";
import { fetchVehicles, createVehicle } from "../../store/vehicle/actions";
import { fetchDrivers, createDriver } from "../../store/driver/actions";
import { fetchVehicleTypesList } from "../../store/vehicleType/actions";
import { fetchAllTransporterSearch } from "../../store/transporter/actions";
import { fetchHopsSourceDestination, fetchHops } from "../../store/hop/actions";
import { fetchAllVehicles } from "../../store/vehicle/actions";
import formatDate from "../DateFormatter";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
// import Pagination from "@mui/material/Pagination";
import { actions } from "./actionButtons";
import QuickFilters from "./quckFilters";
import TripCancelModal from "../tripCancelModal";
import VerifyShipmentModal from "../VerifyShipmentModal";
import { subscribeConsent } from "../../store/driver/actions";
import ApproveModal from "../ApproveModal";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";


//dummy imports
import { HeaderData, searchBar } from "./RowHeaders/RowHeaders";
import {
  TransporterHeaderData,
  TransportersearchBar,
  getLocalStorage,
} from "./RowHeaders/RowHeaders";
import QualityCheckModel from "../TripDetail/QualityCheckModel";

const tripStatusMaster = [
  { label: "Planned", value: "planned" },
  { label: "Arrived At Source", value: "arrived_at_source" },
  { label: "Quality Check Completed", value: "quality_check_completed" },
  { label: "Loading In Progress", value: "loading_in_progress" },
  { label: "Shipment Verified", value: "shipment_verified" },
  { label: "In Transit", value: "in_transit" },
  { label: "Arrived At Via Point", value: "arrived_at_via" },
  { label: "EPOD In Progress", value: "epod_in_progress" },
  { label: "Completed", value: "completed" },
  { label: "Rejected", value: "rejected" },
  { label: "Cancelled", value: "cancelled" },
];

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var totCntExcel = 0;
var searchValues = {};
var darkMode;
class Trip_new extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transpoterId : "",
      dataLoading: false,
      loading: false,
      hops: [],
      vehicleTypes: [],
      vehicleForm: {},
      driverForm: {},
      // searchBar: [],
      searchFilters: {},
      cancelTripDetails: {},
      showCancelTrip: false,
      VerifyShipmentModal: false,
      shipdetails: "",
      operator: "",
      verify: [],
      verifymsg: "",
      modalApproval: false,
      driver: "",
      vehicle: "",
      vehicleTypeListOptions: "",
      onSubmitDisableVehicle: false,
      onSubmitDisableDriver: false,
      tripId: "",
      tripIdEditPop: "",
      tripIdCancelPop: "",
      indentId: "",
      //mess
      // vehicleDetails: {},
      // driverDetails: {},
      transporter: "",
      export: false,

      offset: 0,
      limit: 200,
      filterTranspoter: "",
      allTransporter: "",

      activeTripDelayed: false,
      activeTripUntrack: false,
      activeTripPending: false,
      showTripDetailModel: false,
      transporterVehicleOptions : [],
      transporterDriverOptions : [],
    };
  }

  componentDidMount = () => {
    this.setState({ dataLoading: true })
    let searchFilters = {};
    if (this.props.location?.state?.filter !== undefined) {
      let filter = this.props.location.state.filter;
      if (
        filter === "delayed" ||
        filter === "untracked" ||
        filter === "pending"
      )
        this.quickFilters(this.props.location.state.filter);
      else if (
        filter === "completed" ||
        filter === "planned" ||
        filter === "in_transit"
      ) {
        searchFilters.subStatus = filter;
        this.setState({ searchFilters: searchFilters }, () => {
          this.getAllData();
        });
      }
    } else {
      this.getAllData();
    }
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };
  tripDetailToggleModel = async (id) => {
    this.setState({ showTripDetailModel: !this.state.showTripDetailModel });
    this.setState({ tripDetailId: id });
  };

  resize() {
    // console.log(window.innerWidth, "-- SCREEN SIZE");
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }

  quickFilters = (filterData, dateValue) => {
    this.setState({ dataLoading: true })
    let searchFilters = this.state.searchFilters;
    if (filterData === "delayed" || filterData === "Delayed") {
      searchFilters.delayedStatus = true;
      this.setState({ activeTripDelayed: true });
    }
    if (filterData === "untracked" || filterData === "Untracked") {
      searchFilters.trackingStatus = false;
      this.setState({ activeTripUntrack: true });
    }
    if (filterData === "pending" || filterData === "Pending") {
      searchFilters.pendingStatus = true;
      this.setState({ activeTripPending: true });
    }

    if (filterData === "date") {
      let date = [];
      date[0] = moment(dateValue[0]).format("MM-DD-YYYY");
      date[1] = moment(dateValue[1]).format("MM-DD-YYYY");
      searchFilters.startTime = date;
    }
    this.setState({ searchFilters: searchFilters }, () => {
      this.getAllData();
    });
  };
  getTAllranspoterData = () => {
    this.props.fetchAllTransporterSearch(
      this.state.offset,
      10,
      this.state.filterTranspoter,
      (res) => {
        let transporter = [];
        if (res.data?.docs?.length > 0) {
          res.data.docs.forEach((ele) => {
            transporter.push({
              value: ele._id ? ele._id : "",
              label: ele.name ? ele.name : "",
            });
          });
        }

        this.setState(
          { allTransporter: transporter },
          () => {
            if (searchBar)
              searchBar.searchBarData.forEach((obj) => {
                if (obj.field === "transporter") {
                  obj.options = this.state.allTransporter;
                }
              });
            else
              TransportersearchBar.searchBarData.forEach((obj) => {
                if (obj.field === "transporter") {
                  obj.options = this.state.allTransporter;
                }
              });
          },
          () => { }
        );
      }
    );
  };
  fetchAllTransporterSearch = (filtersData) => {
    this.props.fetchAllTransporterSearch(
      filtersData.offset,
      filtersData.limit,
      filtersData.q,
      (res) => {
        let transporter = [];
        if (res.data?.docs?.length > 0) {
          res.data.docs.forEach((ele) => {
            transporter.push({
              value: ele._id ? ele._id : "",
              label: ele.name ? ele.name : "",
            });
          });
        }

        this.setState(
          { allTransporter: transporter },
          () => {
            if (searchBar)
              searchBar.searchBarData.forEach((obj) => {
                if (obj.field === "transporter") {
                  obj.options = this.state.allTransporter;
                }
              });
            else
              TransportersearchBar.searchBarData.forEach((obj) => {
                if (obj.field === "transporter") {
                  obj.options = this.state.allTransporter;
                }
              });
          },
          () => { }
        );
      }
    )
  }
  filterDataTranspotersAndTrips = (e) => {
    this.filtersHops(e);
    this.filtersTranspoter(e)
  }
  filtersTranspoter = (e) => {

    if (e?.length > 0) {
      let filterData = {
        offset: 0,
        limit: 10,
        q: e
      }
      this.fetchAllTransporterSearch(filterData)
    }
    if (e?.length === 0) {
      let filterData = {
        offset: 0,
        limit: 10,
        q: ""
      }
      this.fetchAllTransporterSearch(filterData)
    }
  }
  // calling hops api
  fetchHops = (filtersHopsData) => {
    this.props.fetchHops({
      offset: filtersHopsData.offset,
      limit: filtersHopsData.limit,
      label: filtersHopsData.q
    }, (res) => {
      this.hops = [];
      if (res?.data?.docs?.length > 0) {
        // this.hops = [this.initialVal("Location")];
        res.data.docs.forEach((ele) => {
          this.hops.push({
            label: ele.hopCode + " (" + ele.label + ")",
            value: {
              name:
                ele.address.addressLine1 + ele.address.city + ele.address.state,
              hopCode: ele.hopCode,
              id: ele._id,
            },
          });
        });
        this.setState({ hops: this.hops }, () => {
          if (searchBar)
            searchBar.searchBarData.forEach((obj) => {
              if (obj.field === "source" || obj.field === "destination") {
                obj.options = this.state.hops;
              }
            });
          else
            TransportersearchBar.searchBarData.forEach((obj) => {
              if (obj.field === "source" || obj.field === "destination") {
                obj.options = this.state.hops;
              }
            });
        });
        //pagination fix for triplist as it has no callback as of now. Would need to be fixed later.
        if (
          this.props.trips?.data?.docs?.length < localStorage.getItem("offset")
        ) {
          offset = (localStorage.getItem("offset") / 10 - 1) * 10;
          console.log("OFFSET", offset);
        }
      }
    });
  }
  // get location name by filter
  filtersHops = (e) => {
    if (e?.length > 0) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: e
      }
      this.fetchHops(filtersHopsData)

    }
    if (e.length === 0) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: ""
      }
      this.fetchHops(filtersHopsData)
    }
  }
  getAllData = () => {
    let offset = 0;
    let typeVt = [];
    this.props.fetchVehicleTypesList(0, (res) => {
      if (Array.isArray(res.data.docs)) {
        if (res.data.docs.length) {
          res.data.docs.forEach((ele) => {
            typeVt.push({
              label: ele.label,
              value: {
                id: ele._id,
              },
            });
          });
        }
      }
    });
    this.setState({ vehicleTypeListOptions: typeVt });

    this.getTAllranspoterData();
    let type = [];
    this.props.fetchAllVehicles(0, (res) => {
      if (Array.isArray(res.data.docs)) {
        if (res.data.docs.length) {
          res.data.docs.forEach((ele) => {
            type.push({
              label: ele.type.label + " - " + ele.registration.number,
              value: {
                // id: ele._id,
                id: ele.registration.number,
              },
            });
          });
        }
      }
    });

    this.setState({ vehicleTypes: type }, () => {
      if (searchBar)
        searchBar.searchBarData.forEach((obj) => {
          if (obj.field === "vehicle") {
            obj.options = this.state.vehicleTypes;
          }
        });
      else
        TransportersearchBar.searchBarData.forEach((obj) => {
          if (obj.field === "vehicle") {
            obj.options = this.state.vehicleTypes;
          }
        });
    });
    let filterData = localStorage.getItem("filters");
    let offsetCount = localStorage.getItem("offset");
    let dataLength = localStorage.getItem("totCnt");
    let offsetCount1 = dataLength < offsetCount ? offset : offsetCount;

    localStorage.setItem("offset", offset);
    this.props.fetchTrips(
      offsetCount1,
      JSON.parse(filterData) || this.state.searchFilters,
      (response) => {
        this.setState({ dataLoading: false })
      }
    )



    // this.props.fetchTrips(offset, this.state.searchFilters);
    // this.props.fetchHopsSourceDestination(0, "", "", (res) => {
    this.props.fetchHops({ offset, limit: 10 }, (res) => {
      this.hops = [];
      if (res?.data?.docs?.length > 0) {
        // this.hops = [this.initialVal("Location")];
        res.data.docs.forEach((ele) => {
          this.hops.push({
            label: ele.hopCode + " (" + ele.label + ")",
            value: {
              name:
                ele.address.addressLine1 + ele.address.city + ele.address.state,
              hopCode: ele.hopCode,
              id: ele._id,
            },
          });
        });
        this.setState({ hops: this.hops }, () => {
          if (searchBar)
            searchBar.searchBarData.forEach((obj) => {
              if (obj.field === "source" || obj.field === "destination") {
                obj.options = this.state.hops;
              }
            });
          else
            TransportersearchBar.searchBarData.forEach((obj) => {
              if (obj.field === "source" || obj.field === "destination") {
                obj.options = this.state.hops;
              }
            });
        });
        //pagination fix for triplist as it has no callback as of now. Would need to be fixed later.
        if (
          this.props.trips?.data?.docs?.length < localStorage.getItem("offset")
        ) {
          offset = (localStorage.getItem("offset") / 10 - 1) * 10;
          console.log("OFFSET", offset);
        }
      }
    });
    if (searchBar)
      searchBar.searchBarData.forEach((obj) => {
        if (obj.field === "subStatus") {
          obj.options = tripStatusMaster;
        }
      });
    else
      TransportersearchBar.searchBarData.forEach((obj) => {
        if (obj.field === "subStatus") {
          obj.options = tripStatusMaster;
        }
      });
  };

  toggleExcel = async () => {
    let offset = 0;
    let limit = totCnt;
    let key;
    let rt = "";
    this.props.fetchTripsExcel(offset, this.state.searchFilters, (res) => {
      // console.log(this.props.tripsExcel?.data?.docs);
      // console.log(res);
      setTimeout(async () => {
        let trips = [];
        if (Array.isArray(res?.data?.docs)) {
          totCntExcel = res?.data?.length;
          fromVal = totCnt !== 0 ? offset + 1 : fromVal;
          toVal = res?.data?.offset + res.data?.docs?.length;
          totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
          res.data.docs.forEach((ele, i) => {
            trips.push({
              TripId: ele.tripId ? ele.tripId : "N/A",
              IndentId: ele.indentId ? ele.indentId?.indentId : null,
              Driver: ele?.driver ? ele?.driver?.name : "N/A",
              Phone: ele?.driver ? ele?.driver?.phone : "N/A",
              Source: ele.source ? `${ele.source.label}` : "N/A",
              Destination: ele.destination ? `${ele.destination.label}` : "N/A",
              Vehicle: ele?.vehicle ? ele?.vehicle?.regNo : "",
              VehicleType: ele?.vehicle?.type ? ele?.vehicle?.type?.label : "",
              Transporter: ele.transporter?.name || "N/A",
              Route: ele.via?.length
                ? ele.via.map((e, i) => {
                  // console.log(rt,"str",e);
                  return (rt += e.label);
                })
                : "DIRECT",
              // description: ele.description,
              StartTime: ele.startTime
                ? moment(ele.startTime).format("DD-MM-YYYY h:mm A", true)
                : "---",
              Status: ele.subStatus ? ele.subStatus : "N/A",
            });
          });
        }
        await this.exportToCSV([...trips], "TRIP_DATA_EXCEL");
      }, 10000);
    });
    this.setState({ loading: true, export: true });

    // console.log(this.props.tripsExcel?.data?.docs);
    // // this.refineData();
  };

  exportToCSV = (csvData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    this.setState({ export: false, loading: false });
  };

  driverPopulate = (value) => {
    let name;
    let phone;
    if (value.name !== "") {
      name = value.name;
    } else name = "----";
    if (value.phone !== "") phone = value.phone;
    else phone = "----";
    if (name === "----" && phone === "----") return "-----";
    // else return name + " (" + phone + ")";
    else
      return (
        <>
          <div>{name}</div>
          <div>{" (" + phone + ")"}</div>
        </>
      );
  };

  route = (via) => {
    return via?.map((e, i) => (
      <div key={i}>
        {i ? (
          <div
            className="text-left pr-4 mobileRoute"
            style={{ fontSize: "0.6em" }}
          >
            <i
              style={{ color: "#5664D2" }}
              className="fas fa-ellipsis-v ml-1"
            ></i>
          </div>
        ) : null}

        <div className="text-left d-flex align-items-center flex-nowrap mobileRoute">
          <i
            style={{ color: "#5664D2" }}
            className="fas fa-circle iconSize"
          ></i>
          <span className="ml-2">{e.label}</span>
        </div>
      </div>
    ));
  };

  transformStatus = (status, path) => {
    let newStatus = status.split("_").join(" ");
    switch (newStatus) {
      case "in transit":
        return (
          <div
            className="tile-text text-capitalize h6"
            style={{ color: "#A020F0" }}
          >
            {" " + newStatus}
          </div>
        );
      case "loading in progress":
        return (
          <div
            className="tile-text text-capitalize h6"
            style={{ color: "#AC1000" }}
          >
            {" " + newStatus}
          </div>
        );
      case "quality check completed":
        return (
          <div
            className="tile-text text-capitalize h6"
            style={{ color: "#28A34C" }}
          >
            {"quality check completed"}
          </div>
        );
      case "cancelled":
        return (
          <div
            className="tile-text text-capitalize h6"
            style={{ color: "#f75c5c" }}
          >
            {" " + newStatus}
          </div>
        );
      case "planned":
        return (
          <div
            className="tile-text text-capitalize h6"
            style={{ color: "#E19600" }}
          >
            {" " + newStatus}
          </div>
        );
      case "Planned":
        return (
          <div
            className="tile-text text-capitalize h6"
            style={{ color: "#E19600" }}
          >
            {" " + newStatus}
          </div>
        );
      case "completed":
        return (
          <div
            className="tile-text text-capitalize h6"
            style={{ color: "#006400" }}
          >
            {" " + "Completed"}
          </div>
        );
      case "arrived at source":
        return (
          <div
            className="tile-text text-capitalize h6"
            style={{ color: "#FF6F00" }}
          >
            {" " + newStatus}
          </div>
        );
      case "arrived at via":
        let viaHop;
        path.path.forEach((ele) => {
          if (ele._id === path.current) viaHop = ele.label;
        });
        return (
          <div>
            <div className="text-capitalize h6 tile-text p-1">
              <div className="" style={{ color: "#FF6F00" }}>
                {" " + newStatus}
              </div>
              <div style={{ color: "#05284E", fontSize: "small" }} className="light__span">
                {"(" + viaHop + ")"}</div>
            </div>
          </div>
        );
      case "shipment verified":
        return (
          <div
            className="tile-text text-capitalize h6"
            style={{ color: "#6900BB" }}
          >
            {" " + newStatus}
          </div>
        );
      case "epod in progress":
        return (
          <div
            className="tile-text text-capitalize h6"
            style={{ color: "#6900BB" }}
          >
            {" " + newStatus}
          </div>
        );
      case "rejected":
        return (
          <div
            className="tile-text text-capitalize h6"
            style={{ color: "#890229" }}
          >
            {" " + newStatus}
          </div>
        );
      default:
        return <div className="tile-text">{newStatus}</div>;
    }
  };
  handleChangePage = (event, val) => {
    this.setState({ dataLoading: true })
    // let key;
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    toVal = offset + process.env.REACT_APP_PERPAGE;
    // if (this.state.searchKey === "") {
    //   key = "";
    // } else key = this.state.searchKey.value;
    let filterData = localStorage.getItem("filters");
    localStorage.setItem("offset", offset);
    this.props.fetchTrips(
      offset,
      JSON.parse(filterData) || this.state.searchFilters,
      (response) => {
        this.setState({ dataLoading: false })
        localStorage.setItem("offset", 0);
      }
    );
  };

  searchValueAssignerFunction = (name, value, type) => {
    this.setState({ dataLoading: true })
    // console.log("val", value);
    if (typeof value === "object") {
      if (type === "date") searchValues[name] = value;
      else searchValues[name] = value.id;
    } else {
      searchValues[name] = value;
    }
    this.setState({ searchFilters: searchValues }, () => {
      localStorage.setItem("filters", JSON.stringify(this.state.searchFilters));
      console.log(this.state.searchFilters, "---Filters-");
      if (type === "select" || type === "date") this.getAllData();
    });
  };

  reset = () => {
    this.setState(
      { searchFilters: {}, activeTripDelayed: false, activeTripUntrack: false, dataLoading: true },
      () => {
        searchValues = {};
        this.props.fetchTrips(0, this.state.searchFilters, (response) => {
          this.setState({ dataLoading: false })
        });
      }
    );
    localStorage.removeItem("filters");
    localStorage.removeItem("offset");
  };
  routingTOTripDetails = (tripDetails) => {
    this.props.history.push({
      pathname: "/tripDetails",
      state: { tripDetails: tripDetails },
    });
  };
  markerAsArrivedChangeStatus = (ele) => {
    this.props.change_arrivedAtSource_status(ele._id, (res) => {
      if (res.success) {
        NotificationManager.success(res["message"], "Success");
        this.getAllData();
      } else {
        NotificationManager.error(res["message"], "Error");
      }
    });
  };
  cancelTripModal = (ele) => {
    this.setState({
      showCancelTrip: !this.state.showCancelTrip,
      tripIdCancelPop: ele?.tripId ? ele?.tripId : "",
    });
  };

  cancelTripDetailSaver = (ele) => {
    // console.log(ele);
    this.setState({ cancelTripDetails: ele });
    this.cancelTripModal(ele);
  };
  routingTOTripTracking = (ele) => {
    this.props.history.push({
      pathname: "/Tracking",
      state: { tripId: ele._id, transporterId: ele?.transporter?._id },
    });
  };
  startLoading = (ele) => {
    this.props.startLoading(ele, (res) => {
      if (res.success) {
        NotificationManager.success(res["message"], "Success");
        this.getAllData();
      } else {
        NotificationManager.error(res["message"], "Error");
      }
    });
  };
  finishLoading = (ele) => {
    this.props.finishLoading(ele, (res) => {
      if (res.success) {
        NotificationManager.success(res["message"], "Success");
        this.getAllData();
      } else {
        NotificationManager.error(res["message"], "Error");
      }
    });
  };
  toggleVerifyShipmentModal = (ele, phoneOperator) => {
    console.log("verify_shipment log", ele, phoneOperator);
    this.setState({
      VerifyShipmentModal: !this.state.VerifyShipmentModal,
      shipdetails: ele,
      operator: phoneOperator?.data?.data?.operator || "----",
    });
  };
  handleChangeModal = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleSubmitverify = ({ verify, callback }) => {
    console.log("FROM TRIP", verify);
    this.props.shipmentVerify(verify, (res) => {
      console.log("RESPONSE", res);
      this.setState({ verifymsg: res.message });
      if (res.success === false) {
        NotificationManager.error(res["message"], "Error");
      } else {
        console.log("inside true");
        let result = res.data.data;
        NotificationManager.success(res["message"], "Success");
        this.setState({ VerifyShipmentModal: false });
        this.getAllData();
      }
    });
  };
  verifyButton = (ele) => {
    this.setState({ dataLoading: true, driver: ele.driver });
    let phone = ele.driver?.phone;
    this.props.subscribeConsent(phone, (res) => {
      this.toggleVerifyShipmentModal(ele, res);
      if (res.statusCode === 200)
        NotificationManager.success(res["message"], "Success");
      this.setState({ dataLoading: false });
    });
  };

  toggleModalApproval = (eleProps) => {
    this.setState({
      modalApproval: !this.state.modalApproval,
      vehicleForm: {
        VehicleOwner: "",
        VehicleRegistrationNumber: "",
        type: null,
      },
      driverForm: {
        fullName: "",
        phone: "",
        driverLicenseNo: "",
      },
    });
  };
  filterVehicleList = (e) => {
    if (e.length > 2) {
      this.props.fetchVehicles(null, {
        regNo: e,
        q: e,
        transporterId: this.state.transpoterId,
      },(res) => {
        this.setState({ transporterVehicleOptions : res.data.docs})
      });
    }
    if (e.length === 0) {
      this.props.fetchVehicles(offset, {
        q: "",
        transporterId: this.state.transpoterId,
      },(res) => {
        this.setState({ transporterVehicleOptions : res.data.docs})
      });
    }
  };
  filterDriverList = (e) => {
    var regexNumber = /^[0-9]+$/;
    var regexString = /^[a-zA-Z]+$/;
    if (e.length > 2) {
      if (e.match(regexNumber)) {
        this.props.fetchDrivers(offset, "", "", { phone: e }, (res) => { 
          this.setState({ transporterDriverOptions : res.data.docs})
        });
      }
      if (e.match(regexString))
        this.props.fetchDrivers(offset, "", "", { name: e }, (res) => { 
          this.setState({ transporterDriverOptions : res.data.docs})
        });
    }
    if (e.length === 0) {
      this.props.fetchDrivers(offset, "", "", { q: "",
        transporterId: this.state.transpoterId
      } , (res) => {
        this.setState({ transporterDriverOptions : res.data.docs})
       });
    }
  };

  handleSubmitSaveVehicle = () => {
    let {
      VehicleOwner,

      VehicleRegistrationNumber,
      type,
    } = this.state.vehicleForm;
    let formObj = {
      ownerName: VehicleOwner,
      transporter: this.state.transporter,
      regNo: VehicleRegistrationNumber,
      type: type?.value ? type?.value : "",
    };
    this.setState({ onSubmitDisableVehicle: true });
    this.props.createVehicle(formObj, (res) => {
      if (res.success) {
        this.props.fetchVehicles(0, {
          q: "",
          transporterId: this.state.transpoterId,
        }, (res) => {
          this.setState({ transporterVehicleOptions : res.data.docs})
        });
        NotificationManager.success(res["message"], "Success");
        this.setState({ onSubmitDisableVehicle: false });
      } else {
        NotificationManager.error(res["message"], "Error");
        this.setState({
          onSubmitDisableVehicle: false,
          vehicle: "",
          vehicleForm: {
            VehicleOwner: "",
            VehicleRegistrationNumber: "",
            type: null,
          },
        });
      }
    });

    setTimeout(() => {
      let vh;
      if(this.state.vehicleForm.VehicleRegistrationNumber !== ""){
        if (Array.isArray(this.state.transporterVehicleOptions)) {
          vh = this.state.transporterVehicleOptions.filter((v) => {
            console.log("vh details", v);
            return v.registration.number == this.state.vehicleForm.VehicleRegistrationNumber;
          });
        }
         this.setState({
        vehicle: {
          value: vh[0]?._id,
          label: vh[0]?.registration?.number + " (" + vh[0]?.type?.label + ")",
        },
        vehicleForm: {
          VehicleOwner: "",
          VehicleRegistrationNumber: "",
          type: null,
        },
      });
      }
      

     
    }, 4000);
  };

  onVehicleChange = (e) => {
    let { name, value } = e.target;
    let vf = { ...this.state.vehicleForm };
    if (name == "vehicleType") {
      vf.type = value;
    }
    if (name == "VehicleOwner") {
      vf["VehicleOwner"] = value;
    }
    if (name == "VehicleRegistrationNumber") {
      vf["VehicleRegistrationNumber"] = value.toString().toUpperCase();
    }
    this.setState({ vehicleForm: vf });
  };

  handleSubmitSaveDriver = () => {
    let { fullName, phone, driverLicenseNo } = this.state.driverForm;
    let formObj = {
      name: fullName,
      transporter: this.state.transporter,
      phone,
      licence: { number: driverLicenseNo },
    };
    this.setState({ onSubmitDisableDriver: true });
    if (Object.keys(formObj.licence && formObj.phone).length > 0) {
      this.props.createDriver(formObj, (res) => {
        if (res.message == "Driver has been created successfully") {
          this.props.fetchDrivers(0, "", "", { q: "" , transporterId : this.state.transpoterId}, (res) => { 
            this.setState({
              transporterDriverOptions : res.data.docs
            })
          });

          NotificationManager.success(res["message"], "Success");
          this.setState({ onSubmitDisableDriver: false });
        } else {
          NotificationManager.error(res["message"], "Error");
          this.setState({
            onSubmitDisableDriver: false,
            driver: "",
            driverForm: {
              fullName: "",
              phone: "",
              driverLicenseNo: "",
              transporter: "",
            },
          });
        }
      });
    } else {
      NotificationManager.error(" All Field required");
    }

    setTimeout(() => {
      let dt;
      if(this.state.driverForm.phone !== ""){
      if (Array.isArray(this.state.transporterDriverOptions)) {
        dt = this.state.transporterDriverOptions.filter((v) => {
          return v.phone == this.state.driverForm.phone;
        });
        console.log(dt, "dt");
      }
      this.setState({
        driver: {
          value: dt[0]?._id,
          label: dt
            ? dt[0]?.phone + " (" + (dt[0]?.name ? dt[0]?.name : "N/A") + ")"
            : this.state.driverShow.phone,
        },
        driverForm: {
          fullName: "",
          phone: "",
          driverLicenseNo: "",
        },
      });
    }
    }, 1000);
  };
  onDriverChange = (e) => {
    let { name, value } = e.target;
    let df = { ...this.state.driverForm };
    if (name == "phone") {
      value = e.target?.value;
      df.phone = value;
    }
    if (name == "driverLicenseNo") {
      df["driverLicenseNo"] = value;
    }
    if (name == "fullName") {
      df["fullName"] = value;
    }
    this.setState({ driverForm: df });
  };

  resetSelection = () => {
    this.setState({ vehicle: "", driver: "", onSubmitDisable: false });
  };
  editDetailButton = (ele) => {
    console.log("ele", ele);
    this.setState({ tripIdEditPop: ele?.tripId ? ele?.tripId : "", transpoterId : ele?.transporter?._id });
    this.props.fetchVehicles(offset, {
      transporterId: ele?.transporter?._id,
    }, (res) => {
      this.setState({ transporterVehicleOptions : res.data.docs})

    });
    this.props.fetchDrivers(offset, "", "", {
      q: "",
      transporterId: ele?.transporter?._id
    }, (res) => { 
      this.setState({ transporterDriverOptions : res.data.docs})
    });
    this.setState(
      {
        indentId: ele.indentId?._id,
        transporter: ele.transporter?._id,
        tripId: ele._id,
        vehicle: {
          value: ele?.vehicle?.id,
          label: ele?.vehicle?.regNo
            ? ele?.vehicle?.regNo + " (" + ele?.vehicle?.type?.label + ")"
            : "Select",
        },
        driver: {
          value: ele?.driver?.id,
          label: ele?.driver?.phone
            ? ele?.driver?.phone + " (" + ele?.driver?.name + ")"
            : "Select",
        },
      },
      () => {
        this.toggleModalApproval(ele);
      }
    );
  };
  handleResetEdit = () => {
    console.log("Console");
    this.setState({
      vehicle : "",
      driver :"",
      vehicleForm: {
        VehicleOwner: "",
        VehicleRegistrationNumber: "",
        type: null,
      },
      driverForm: {
        fullName: "",
        phone: "",
        driverLicenseNo: "",
      },
    });
  };

  render() {
    console.log(this.props, "PROPS")
    const { loading } = this.state;
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    var darkMode = mode === "dark__mode" ? true : false;

    // if (loading) {
    //   return (
    //     <div className="page-content">
    //       <Col xs={12} className="loader">
    //         <MoonLoader size={45} color={"#123abc"} />
    //       </Col>
    //     </div>
    //   );
    // }
    let data = [];
    let vehicleOptions = [];
    let driverOptions = [];
    let vehicleTypeOptions = [];
    let LocalData = JSON.parse(localStorage.getItem("TripData"));
    var PWATest = navigator.onLine ? this.props.trips?.data : LocalData?.data;
    const { vehicles, drivers } = this.props;
    let vehicleType = this.props.vehicleType.vehicleType;
    if (Array.isArray(PWATest?.docs)) {
      data = [];
      offset = PWATest?.offset;
      totCnt = PWATest?.length;
      localStorage.setItem("totCnt", totCnt);
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = PWATest?.offset + PWATest?.docs?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      PWATest.docs.forEach((ele) => {
        data.push({
          id: ele._id,
          tripId: ele.tripId ? ele.tripId : "N/A",
          indentId: ele.indentId ? ele.indentId?.indentId : null,
          driver: ele.driver ? this.driverPopulate(ele.driver) : "N/A",
          source: ele.source ? `${ele.source.label}` : "N/A",
          destination: ele.destination ? `${ele.destination.label}` : "N/A",
          vehicle:
            (
              <>
                <p style={{ padding: "0px", margin: "0px" }}>
                  {ele.vehicle?.regNo}
                </p>
                <p style={{ padding: "0px", margin: "0px" }}>
                  {ele.vehicle?.type?.label}
                </p>
              </>
            ) || "N/A",
          transporter: ele.transporter?.name || "N/A",
          route: ele.via.length ? <>{this.route(ele.via)}</> : "DIRECT",
          description: ele.description,
          startTime: ele.startTime ? formatDate(ele.startTime) : "N/A",
          expiryTime: ele.expiryTime ? formatDate(ele.expiryTime) : "N/A",
          subStatus: this.transformStatus(ele.subStatus, ele.tracking),
          actions: actions(ele, {
            routingTOTripDetails: this.routingTOTripDetails,
            cancelTripDetailSaver: this.cancelTripDetailSaver,
            routingTOTripTracking: this.routingTOTripTracking,
            startLoading: this.startLoading,
            finishLoading: this.finishLoading,
            verifyButton: this.verifyButton,
            editDetailButton: this.editDetailButton,
            markerAsArrivedChangeStatus: this.markerAsArrivedChangeStatus,
            tripDetailToggleModel: this.tripDetailToggleModel,
          }),
        });
      });
      if (Array.isArray(vehicleType?.data?.docs)) {
        vehicleType.data.docs.forEach((ele) => {
          vehicleTypeOptions.push({
            value: ele._id,
            label: ele.vehicleTypeCode,
          });
        });
      }
      if (Array.isArray(this.state.transporterVehicleOptions)) {
        this.state.transporterVehicleOptions.forEach((ele) => {
          vehicleOptions.push({
            value: ele._id,
            label: ele.registration.number + " (" + ele.type.label + ")",
          });
        });
      }
      if (Array.isArray(this.state.transporterDriverOptions)) {
        this.state.transporterDriverOptions.forEach((ele) => {
          driverOptions.push({
            value: ele._id,
            label: ele.phone + " (" + ele.name + ")",
          });
        });
      }
    }
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;
    return (
      <>
        {getLocalStorage()}
        <QualityCheckModel
          isOpen={this.state.showTripDetailModel}
          toggleModel={this.tripDetailToggleModel}
          tripIds={this.state.tripDetailId}
        />
        <ApproveModal
          handleResetEdit={this.handleResetEdit}
          isOpen={this.state.modalApproval}
          toggle={this.toggleModalApproval}
          onChange={this.handleChangeModal}
          options={vehicleOptions}
          optionsdriver={driverOptions}
          driver={this.state.driver}
          vehicle={this.state.vehicle}
          submit={this.handleSubmitApprove}
          disabledVehicle={this.state.onSubmitDisableVehicle}
          disabledDriver={this.state.onSubmitDisableDriver}
          indentIndex={this.state.indentId}
          filterVehicleList={this.filterVehicleList}
          filterDriverList={this.filterDriverList}
          type={vehicleTypeOptions}
          refreshData={this.getAllData}
          submitVehicleForm={this.handleSubmitSaveVehicle}
          onVehicleChange={this.onVehicleChange}
          vehicleForm={this.state.vehicleForm}
          submitDriverForm={this.handleSubmitSaveDriver}
          onDriverChange={this.onDriverChange}
          driverForm={this.state.driverForm}
          resetSelection={this.resetSelection}
          isTrip={true}
          tripIndex={this.state.tripId}
          // vehicleDetails={this.state.vehicleDetails}
          // driverDetails={this.state.driverDetails}
          toBeSelectedVehicleType={this.state.toBeSelectedVehicleType}
          transporter={this.state.transporter}
          tripIdEditPop={
            this.state.tripIdEditPop ? this.state.tripIdEditPop : ""
          }
          mode={darkMode}
        />
        <VerifyShipmentModal
          isOpen={this.state.VerifyShipmentModal}
          toggle={this.toggleVerifyShipmentModal}
          onChange={this.handleChangeModal}
          shipdetails={this.state.shipdetails ? this.state.shipdetails : {}}
          operator={this.state.operator ? this.state.operator : {}}
          // options={this.vehicleRegNoArr}
          // optionsdriver={this.driversList}
          driver={this.state.driver}
          // vehicle={this.state.vehicle}
          submit={this.handleSubmitverify}
          verify={this.state.verify}
          disabled={this.state.onSubmitDisable}
          verifymsg={this.state.verifymsg}
          mode={darkMode}
        />
        <div>
          <QuickFilters
            quickFilters={this.quickFilters}
            reset={this.reset}
            toggleExcel={this.toggleExcel}
            export={this.state.export}
            activeTripDelayed={this.state.activeTripDelayed}
            activeTripUntrack={this.state.activeTripUntrack}
            mode={darkMode}
          />
          <Container
            aria-hidden="true"
            fluid
            className={`${darkMode ? "darkBackgroundList mobilepPadding" : "mobilepPadding"
              }`}
          >


            {userType && userType == "transporter" ? (
              <Index
                {...this.props}
                headers={TransporterHeaderData}
                data={data}
                searchable={true}
                searchBar={searchBar}
                reset={this.reset}
                searchValueAssignerFunction={this.searchValueAssignerFunction}
                getAllData={this.getAllData}
                dataLength={this.props.trips?.data?.length}
                fetchId={this.props.fetchId}
                dataLoading={this.state.dataLoading}
                filterDataTranspotersAndTrips={this.filterDataTranspotersAndTrips}
              />
            ) : (
              <Index
                {...this.props}
                headers={HeaderData}
                data={data}
                searchable={true}
                searchBar={searchBar}
                reset={this.reset}
                searchValueAssignerFunction={this.searchValueAssignerFunction}
                getAllData={this.getAllData}
                dataLength={this.props.trips?.data?.length}
                fetchId={this.props.fetchId}
                dataLoading={this.state.dataLoading}
                filterDataTranspotersAndTrips={this.filterDataTranspotersAndTrips}

              />
            )}

            {totCnt !== 0 && !this.state.dataLoading ? (
              <Row
                xs={12}
                style={{ color: darkMode ? "#9D9D9D" : "#0C0C0C" }}
                className="mb-2 paginationRow"
              >
                <Col xs={7} sm={5} md={4} className="span-col-pagenation">
                  <div className="float-left">
                    <Pagination
                      className="desktop-pagination"
                      size="medium"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                    <Pagination
                      className="mobile-pagination"
                      size="small"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                  </div>
                </Col>
                <Col
                  className="mt-2 spanPagenationentries span-col-pagenation"
                  xs={5}
                  sm={7}
                  md={8}
                >
                  <span>
                    Showing {fromVal} to {toVal} of {totCnt} entries
                  </span>
                </Col>
              </Row>
            ) : null}



          </Container>
        </div>
        <TripCancelModal
          showCancelTrip={this.state.showCancelTrip}
          cancelTripDetails={this.state.cancelTripDetails}
          cancelTripModal={this.cancelTripModal}
          getAllData={this.getAllData}
          tripIdCancelPop={
            this.state.tripIdCancelPop ? this.state.tripIdCancelPop : ""
          }
          mode={darkMode}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    trips: state.Trip.trips.trips,
    fetchId: state.Trip.trips.fetchId,
    vehicleType: state.VehicleType.vehicleType,
    vehicles: state.Vehicle.vehicles,
    drivers: state.Driver.drivers,
  };
};

export default connect(mapStateToProps, {
  fetchTrips,
  fetchHops,
  fetchTripsExcel,
  fetchTransporterTrips,
  fetchAllTransporterSearch,
  startLoading,
  finishLoading,
  fetchHopsSourceDestination,
  fetchAllVehicles,
  subscribeConsent,
  shipmentVerify,
  fetchVehicles,
  fetchDrivers,
  createVehicle,
  createDriver,
  fetchVehicleTypesList,
  change_arrivedAtSource_status,
})(Trip_new);
