import { types } from "./actionTypes";
export const fetchVehicles = (
  offset,
  filter,
  callback
) => ({
  type: types.FETCH_VEHICLES,
  offset,
  filter,
  callback,
});

export const fetchAllVehicles = (offset, callback) => ({
  type: types.FETCH_ALL_VEHICLES,
  offset,
  callback,
});

export const fetchAvailVehicles = (callback) => ({
  type: types.FETCH_AVAIL_VEHICLES,
  callback,
});
// export const fetchPost = id => ({ type: types.FETCH_POST, id });
export const createVehicle = (values, callback) => ({
  type: types.CREATE_VEHICLE,
  values,
  callback,
});
export const fetchVehicleById = (id, callback) => ({
  type: types.FETCH_VEHICLE_BYID,
  id,
  callback,
});
export const editVehicleById = (values, callback) => ({
  type: types.EDIT_VEHICLE_BYID,
  values,
  callback,
});
export const deleteVehicle = (id, callback) => ({
  type: types.DELETE_VEHICLE,
  id,
  callback,
});
export const fetchVehicleType = (data) => ({
  type: types.FETCH_VEHICLES_TYPE,
  ...data,
});
export const fetchAllVehicleTypes = (offset, limit, filter, callback) => ({
  type: types.FETCH_ALL_VEHICLE_TYPES,
  offset,
  limit,
  filter,
  callback,
});
