import React, { Component } from "react";
import { Card } from "reactstrap";
import { Row, Col, Container } from "reactstrap";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { Button, Grid } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import moment from "moment";
import CarbonApexChar from "./CarbonApexChar";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Tooltip from "@material-ui/core/Tooltip";
import exportIcon from "../assets/external-link.png";
import exportDarkIcon from "../../Trips/assets/external-linkDark.png";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Select from "react-select";

var chartTypeStore 
export default class CarbonFootPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      export: false,
      loading: false,
      chartType: chartTypeStore?.chart,
      filterType: "Month",
      dropDownFilterType: false,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };
  toggleFilter = () => {
    this.setState((prevState) => ({
      dropDownFilterType: !prevState.dropDownFilterType,
    }));
  };
  componentDidMount(){
    this.setState({chartType:chartTypeStore})
  }

  toggleExcel = async () => {
    setTimeout(async () => {
      let carbonFootPrintMonth = [];
      if (this.props.carbonEmision) {
        if (this.props.carbonEmision !== undefined) {
          let carbonEmisionData = this.props?.carbonEmision;
          carbonEmisionData.forEach((ele) => {
            if (ele) {
              if(this.props.selectedFilter.value == "month"){
              carbonFootPrintMonth.push({
                CarbonEmissionValue:Number(ele?.carbonEmission.toFixed(2)),
                Month: ele?.tenure,
              });
            }
            else if(this.props.selectedFilter.value == "year"){
              carbonFootPrintMonth.push({
                CarbonEmissionValue: Number(ele?.carbonEmission.toFixed(2)),
                Year: ele?.tenure,
              });
            }
           else if(this.props.selectedFilter.value == "weekDay"){
              carbonFootPrintMonth.push({
                CarbonEmissionValue: Number(ele?.carbonEmission.toFixed(2)),
                "Week Day": ele?.tenure,
              });
            }
            else if(this.props.selectedFilter.value == "week"){
              carbonFootPrintMonth.push({
                CarbonEmissionValue: Number(ele?.carbonEmission.toFixed(2)),
                Week: ele?.tenure,
              });
            }
            else if(this.props.selectedFilter.value == "quarter"){
              carbonFootPrintMonth.push({
                CarbonEmissionValue: Number(ele?.carbonEmission.toFixed(2)),
                  Quarter: ele?.tenure,
              });
            }
            else if(this.props.selectedFilter.value == "date"){
              carbonFootPrintMonth.push({
                CarbonEmissionValue:  Number(ele?.carbonEmission.toFixed(2)),
                  Quarter: ele?.tenure,
              });
            }
            }
          });
        }
      }
      await this.exportToCSV(
        [...carbonFootPrintMonth],
        "CARBONPRINT_DATA_EXCEL"
      );
    }, 1000);

    this.setState({ loading: true, export: true });
  };
  exportToCSV = (csvData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    this.setState({ export: false });
  };
  render() {    
    console.log("this.props.selectedFilter}",this.props.selectedFilter)
    let userPreferredLang = window.localStorage.getItem("userPreferredLang");
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    var darkMode = mode === "dark__mode" ? true : false;
    chartTypeStore = localStorage.getItem("chart")
    ? localStorage.getItem("chart")
    : "line";
    const customStyles = {
      option: (styles, state) => ({
        ...styles,
        cursor: "pointer",
      }),
      menu: ({ width, ...css }) => ({
        ...css,
        cursor: "pointer",
        width: "100%",
        backgroundColor:darkMode?"#171722":"#ffffff",
        color:darkMode?"#B7A9FF":"#505d69",
        border: darkMode?"0.5px solid #616161":"0.5px solid rgb(204, 204, 204)",
        boxShadow: darkMode?"0px 3px 12px rgba(0, 0, 0, 0.12)" : "0px 3px 12px rgba(0, 0, 0, 0.12)"
        // minWidth: "100%",
      }),
      control: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "26px",
          width:
            this.props.selectedTranspoterValues?.length >= 2 ? "100%" : "100%",
          minWidth: "100%",
          minHeight: "26px",
          flexWrap: "nowrap",
          backgroundColor: this.props?.opCardStyle ? "#2E2E46" : "",
        };
      },
      placeholder: (style) => {
        return {
          ...style,
          cursor: "pointer",
          marginTop: "2px",
          height: "26px",
          minHeight: "26px",
          fontSize: "13px",
          fontFamily: "Roboto",
          fontWeight: 400,
          fontStyle: "normal",
          paddingLeft: "10px",
          paddingTop: "5px",
          color: this.props?.opCardStyle ? "#9D9D9D" : "#0C0C0C",
        };
      },
      indicatorsContainer: (style) => {
        return {
          ...style,
          cursor: "pointer",
          // backgroundColor: "#8e9af8",
          height: "24px",
          minHeight: "24px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "10px",
          minHeight: "10px",
          backgroundColor: "#cccccc",
          marginTop: "8px",
        };
      },
      dropdownIndicator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "26px",
          minHeight: "26px",
          color:darkMode?"#b7a9ff":"#0066cc",
        };
      },
    };
   
    return (
      <React.Fragment>
        <div>
          <Grid
            style={{
              backgroundColor: darkMode ? "#1B1C31" : "#F1F5F7",
            }}
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            className="mt-1 pt-1 mb-1 CarbonFootPrintDiv"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{
                // padding: "0px 18px",
                paddingLeft: "18px",
              }}
              className="pdt25"
            >
              <Row className="">
                <Card
                  className="mt-0 shadow invoice-overflow barBgDarkCard"
                  style={{
                    width: "100%",
                    height: "474px",
                  }}
                >
                  <div className="mt-2 pl-3">
                    <Row className="pt-2">
                      <Col xs="7"  sm={8} md={9} lg={10}>
                        <span
                          className="freight-cost-name-apx roboto-font-700 light__span"
                          style={{
                            position: "relative",
                            // top: "10px",
                            left: "30px",
                          }}
                        >
                          {this.props?.userLang?.dashboard?.CARBON_FOOT_PRINT ??
                            "Carbon FootPrint"}
                        </span>
                      </Col>
                      <Col xs="5" sm={4} md={3} lg={2} className="">
                        <div
                          className="d-flex justify-content-end carbonFootAlign "
                        >
                          <div className="pr-2">
                            <div className="">
                              <Select
                              style={{
                                width:"100px",
                                height:"26px"
                              }}
                                className=""
                                classNamePrefix="react-select"
                                placeholder={this.props?.userLang?.common?.MONTHLY ?? "Monthly"}
                                autoFocus={true}
                                isSearchable
                                styles={customStyles}
                                value = { this.props.selectedFilter}
                                options={this.props.corbonFootOptions}
                                onChange={this.props.onChangeCarbonFoot}
                              />
                            </div>
                          </div>
                          <div className="pr-2">
                            <div className="">
                              <Dropdown
                                isOpen={this.state.dropdownOpen}
                                toggle={this.toggle}
                                className=" d-sm-inline-block"
                              >
                                <DropdownToggle
                                  style={{
                                    width: '100%',
                                    display: 'flex',
                                    height: '26px',
                                    border: darkMode?
                                    "0.5px solid #616161":
                                    "1px solid blue",
                                    justifyContent: 'center',
                                  }}
                                  tag="button"
                                  className="btn mt-0 pt-0"
                                  // id="page-header-user-dropdown"
                                >
                                  <span
                                    style={{
                                      display: "flex",
                                      marginTop: "5px",
                                    }}
                                    className='d-xl-inline-block ml-1 text-transform profile-name light__span'
                                  >
                                    {this.state.chartType?this.state.chartType:"line"}
                                  </span>
                                  <i
                                    style={{
                                      fontSize: "17px",
                                    }}
                                    className="mdi mdi-chevron-down ml-1 pl-1 d-xl-inline-block mt-0 profile-chervon"
                                  ></i>
                                </DropdownToggle>
                                <DropdownMenu right className="barDropDark">
                                  <DropdownItem
                                    onClick={() => {
                                      this.setState({ chartType: "line" });
                                      localStorage.setItem('chart','line')
                                    }}
                                  >
                                    <span className="actionDspan">Line</span>
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      this.setState({ chartType: "bar" });
                                      localStorage.setItem('chart','bar')
                                    }}
                                  >
                                    <span className="actionDspan">Bar</span>

                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </div>
                          </div>
                          <div style={{ width: "33.33%" }}>
                            <div className={navigator.onLine ? "tooltipIcon" : "tooltipIcon disable-export"}>
                              <svg
                                className="cursor-pointer exportDark"
                                onClick={() => this.toggleExcel()}
                                width="27"
                                height="27"
                                viewBox="0 0 27 27"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M22.6299 0.0490417H4.62988C2.42074 0.0490417 0.629883 1.8399 0.629883 4.04904V22.049C0.629883 24.2582 2.42074 26.049 4.62988 26.049H22.6299C24.839 26.049 26.6299 24.2582 26.6299 22.049V4.04904C26.6299 1.8399 24.839 0.0490417 22.6299 0.0490417Z"
                                  fill="#F1F5F7"
                                />
                                <path
                                  d="M22.6299 0.299042H4.62988C2.55882 0.299042 0.879883 1.97797 0.879883 4.04904V22.049C0.879883 24.1201 2.55882 25.799 4.62988 25.799H22.6299C24.701 25.799 26.3799 24.1201 26.3799 22.049V4.04904C26.3799 1.97797 24.701 0.299042 22.6299 0.299042Z"
                                  stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                                  strokeWidth="0.5"
                                />
                                <path
                                  d="M18.7559 14.133V18.983C18.7559 19.4119 18.5855 19.8232 18.2823 20.1264C17.979 20.4297 17.5677 20.6 17.1389 20.6H8.24688C7.81803 20.6 7.40674 20.4297 7.10349 20.1264C6.80024 19.8232 6.62988 19.4119 6.62988 18.983V10.091C6.62988 9.66219 6.80024 9.2509 7.10349 8.94765C7.40674 8.64441 7.81803 8.47404 8.24688 8.47404H13.0969"
                                  stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M16.3311 6.04904H21.1811V10.899"
                                  stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M11.9058 15.325L20.9058 6.32504"
                                  stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              <span
                                className="tooltipIcontext1"
                                style={{ width: "55px" }}
                              >
                                {this.props?.userLang?.invoice?.EXPORT ??
                                  "Export"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <hr className="mt-3 ml-3 mr-3 carbonFootPrint-hr-line" />
                  <div
                    className="desktop-freight pd18"
                    style={{
                      backgroundColor: darkMode ? "#171722" : "#F1F5F7",
                      width: "96%",
                      position: "relative",
                      left: "2%",
                    }}
                  >
                    <CarbonApexChar
                      carbonEmision={this.props.carbonEmision}
                      chartType={this.state.chartType}
                      selectedFilter={this.props.selectedFilter}
                    />
                  </div>
                </Card>
              </Row>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}
