import React, { Component } from "react";
import ReactApexCharts from "react-apexcharts";
export default class FreightCost extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartType: this.props.chartType,
      series: [
        {
          // type: this.props.chartType?this.props.chartType:"area",
          name: this.props?.userLang?.dashboard?.FREIGHT_COST,
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          color: "rgba(10, 179, 140, .7)",
        },
      ],
      options: {
        tooltip: {
          // followCursor: false,
          // enabled:true,
          shared:false,
          intersect:true,
         
        },
        markers: {
          size: 5,
          colors: "rgba(40, 0, 252, 1)",
          shape: "circle",
          radius: 3,
          strokeColor: "rgba(241, 245, 247, 1)",
        },
        chart: {
          width: "100%",
          //   height: 300,
          // type: this.props.chartType?this.props.chartType:"area",
          //   foreColor: "#ccc",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        legend: {
          show: false,
          position: "bottom",
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: false,
        },
        stroke: {
          width: 1,
          curve: "straight",
        },

        xaxis: {
          startAtZero: true,
        },

        xaxis: {
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: -10,
            offsetY: 0,
          },
          // labels:{
          //  rotate:0,
          // },

          tickAmount: 11,
          tickPlacement: "between",
          categories: [
            this.props?.userLang?.dashboard?.JANUARY,
            this.props?.userLang?.dashboard?.FEBRUARY,
            this.props?.userLang?.dashboard?.MARCH,
            this.props?.userLang?.dashboard?.APRIL,
            this.props?.userLang?.dashboard?.MAY,
            this.props?.userLang?.dashboard?.JUNE,
            this.props?.userLang?.dashboard?.JULY,
            this.props?.userLang?.dashboard?.AUGUST,
            this.props?.userLang?.dashboard?.SEPTEMBER,
            this.props?.userLang?.dashboard?.OCTOBER,
            this.props?.userLang?.dashboard?.NOVEMBER,
            this.props?.userLang?.dashboard?.DECEMBER,
          ],
        },

        yaxis: {
          forceNiceScale: true,
          show: true,
          showAlways: true,
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: 0,
            offsetY: +10,
          },

          //   tickAmount: 3,
          tickPlacement: "between",
          //   max:60
        },
      },
    };
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps.invoice != this.props.invoice || prevProps.chartType != this.props.chartType) {
      let invoice = this.props?.invoice;
      console.log("test",invoice)
      let freightCost = [];
      let totalMonth = 12;
      let WeekDay = 7;
      let quater = 4;
      let date=[];
      if (this.props.selectedFilteredFreightCost.value == "month") {
        for (let i = 0; i < totalMonth; i++) {
          let checkData = invoice.filter((ele) => {
            // console.log("ele",ele)
            return ele?.tenure == i + 1;
          });
          // console.log("check data", checkData)
          if (checkData?.length > 0) {
            freightCost.push(
              Number(checkData[0].freightCost.toFixed(2))
            );
           
          } else {
            freightCost.push(0);
            
          }
        }

        this.setState(
          {
            chartType: this.props.chartType,
            series: [
              {
                data: freightCost,
              },
            ],
            options: {
              xaxis: {
                categories: [
                  "JAN",
                  "FEB",
                  "MAR",
                  "APR",
                  "MAY",
                  "JUNE",
                  "JULY",
                  "AUG",
                  "SEPT",
                  "OCT",
                  "NOV",
                  "DEC",
                ],
              },
              chart: { type: this.props.chartType },
            },
          },
          () => {
           
            console.log("tthis.state",this.state)
          }
        );
        
      } 
      if (this.props.selectedFilteredFreightCost.value == "weekDay") {
        for (let i = 0; i < WeekDay; i++) {
          let checkData = invoice.filter((ele) => {
            // console.log("ele",ele)
            return ele?.tenure == i;
          });
          // console.log("check data", checkData)
          if (checkData?.length > 0) {
            freightCost.push(
              Number(checkData[0].freightCost.toFixed(2))
            );
           
          } else {
            freightCost.push(0);
            
          }
        }

        this.setState(
          {
            chartType: this.props.chartType,
            series: [
              {
                data: freightCost,
              },
            ],
            options: {
              xaxis: {
                categories: [
                  "Sunday",
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                ],
              },
              chart: { type: this.props.chartType },
            },
          },
          () => {
           
            console.log("tthis.state",this.state)
        
          }
        );
        
      } 
      if (this.props.selectedFilteredFreightCost.value == "quarter") {
        for (let i = 0; i < quater; i++) {
          let checkData = invoice.filter((ele) => {
            // console.log("ele",ele)
            return ele?.tenure == i;
          });
          // console.log("check data", checkData)
          if (checkData?.length > 0) {
            freightCost.push(
              Number(checkData[0].freightCost.toFixed(2))
            );
           
          } else {
            freightCost.push(0);
            
          }
        }

        this.setState(
          {
            chartType: this.props.chartType,
            series: [
              {
                data: freightCost,
              },
            ],
            options: {
              xaxis: {
                categories: [
                  "Quater1 ",
                  "Quater2 ",
                  "Quater3 ",
                  "Quater4 ",
                ],
              },
              chart: { type: this.props.chartType },
            },
          },
          () => {
           
            console.log("tthis.state",this.state)
          }
        );
        
      } 
      if (this.props.selectedFilteredFreightCost.value == "date"|| this.props.selectedFilteredFreightCost.value == "week") {
        invoice.forEach((ele)=>{
          date.push(ele?.tenure)
          freightCost.push(ele?.freightCost)
        })

        this.setState(
          {
            chartType: this.props.chartType,
            series: [
              {
                data: freightCost,
              },
            ],
            options: {
              xaxis: {
                categories: [
                 ...date
                ],
              },
              chart: { type: this.props.chartType },
            },
          },
          () => {
           
            console.log("tthis.state",this.state)
          }
        );
        
      } 
      if (this.props.selectedFilteredFreightCost.value == "year") {
        invoice.forEach((ele)=>{
          date.push(ele?.tenure)
          freightCost.push(ele?.freightCost)
        })

        this.setState(
          {
            chartType: this.props.chartType,
            series: [
              {
                data: [0,...freightCost],
              },
            ],
            options: {
              xaxis: {
                categories: [ date[0]-1,
                 ...date
                ],
              },
              chart: { type: this.props.chartType },
            },
          },
          () => {
           
            console.log("tthis.state",this.state)
          }
        );
        
      } 
    }
  };
  render() {
    // console.log("this",this.state.series[0].data)
    // console.log("freight charges", this.props.invoice);
    let darkMode = this.props.darkInvoiceTheme 
    return (
      <div className={`cursor-pointer freightCostApexchart ${darkMode ? 'apexBgDarkCard' : ''}`}>
        <ReactApexCharts
          options={this.state.options}
          series={this.state.series}
          type={this.state.chartType ? this.state.chartType : "area"}
          // width="1330px"
          height={340}
        />
      </div>
    );
  }
}
