import React, { Component } from "react";
import { Row, Col, Button, TabPane, Label } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class ConfirmDetailTab extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <TabPane tabId={4}>
          <Row className="mb-4">
            <Col md="6">
              <h6 className="p-2 border-bottom">Route Detail</h6>
              <div>
                <Label className="p-2 lable_width">Loading Point</Label>:
                <span className="p-2">
                  {this.props.indents.routeDetail.loadingPoint}
                </span>
              </div>
              <div>
                <Label className="p-2 lable_width">Destination</Label>:
                <span className="p-2">
                  {" "}
                  {this.props.indents.routeDetail.destination}
                </span>
              </div>
              <div>
                <Label className="p-2 lable_width">Start Date/Time</Label>:
                <span className="p-2">
                  {JSON.stringify(this.props.indents.routeDetail.reportTime)}
                </span>
              </div>
            </Col>
            <Col md="6">
              <h6 className="p-2 border-bottom">Order Detail</h6>
              <div>
                <Label className="p-2 lable_width">Order ID</Label>:
                <span className="p-2">
                  {this.props.indents.orderDetail.orderID}
                </span>
                <Label className="p-2 lable_width">Invoice ID</Label>:
                <span className="p-2">
                  {this.props.indents.orderDetail.invoiceID}
                </span>
                <Label className="p-2 lable_width">Material Type</Label>:
                <span className="p-2">
                  {this.props.indents.orderDetail.materialType}
                </span>
                <Label className="p-2 lable_width">Material Quantity</Label>:
                <span className="p-2">
                  {this.props.indents.orderDetail.materialQuantity}
                </span>
                <Label className="p-2 lable_width">Unit</Label>:
                <span className="p-2">
                  {this.props.indents.orderDetail.unit}
                </span>
                <div>
                  <Label className="p-2 lable_width">
                    {" "}
                    Attach Order/Invoice
                  </Label>
                  :<span className="p-2">Uploaed File</span>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="">
            <Col md="6">
              <h6 className="p-2 border-bottom">Customer Detail</h6>
              <div>
                <Label className="p-2 lable_width">Customer Name</Label>:
                <span className="p-2">
                  {this.props.indents.orderDetail.customerName}
                </span>
              </div>
              <div>
                <Label className="p-2 lable_width">Customer Contact</Label>:
                <span className="p-2">
                  {this.props.indents.orderDetail.customerContact}
                </span>
              </div>
            </Col>
            <Col md="6">
              <h6 className="p-2 border-bottom">Vehicle Planning</h6>
              <div>
                <Label className="p-2 lable_width">Vehicle Type</Label>:
                <span className="p-2">
                  {this.props.indents.vehicleDetail.vehicleType}
                </span>
                <Label className="p-2 lable_width">Vehicle Quantity</Label>:
                <span className="p-2">
                  {this.props.indents.vehicleDetail.vehicleQuantity}
                </span>
                <Label className="p-2 lable_width">Transporter Selection</Label>
                :
                <span className="p-2">
                  {this.props.indents.vehicleDetail.transporterSelection}
                </span>
                <Label className="p-2 lable_width">Cost</Label>:
                <span className="p-2">
                  {this.props.indents.vehicleDetail.cost}
                </span>
              </div>
            </Col>
          </Row>

          <div className="row justify-content-center">
            <Col lg="6">
              <div className="text-center">
                <div className="mb-1">
                  <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                </div>
                <div>
                  <h5>Confirm Detail</h5>
                </div>
              </div>
              <Col md="12">
                <div className="mb-2 mt-2 m5 text-center btn_indene_style">
                  <Link>
                    <Button
                      className="m-2 p-2"
                      style={{ background: "#5664d2" }}
                    >
                      Submit
                    </Button>
                  </Link>
                  <Link></Link>
                </div>
              </Col>
            </Col>
          </div>
        </TabPane>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  indents: state.Indent.indents,
});

export default connect(mapStateToProps, {})(ConfirmDetailTab);
