import React, { Component } from "react";

import { Row, Col, Container } from "reactstrap";
import { NotificationManager } from "react-notifications";

import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { MoonLoader } from "react-spinners";
import {
  fetchRoles,
  deleteRole,
  fetchRolesfilter,
} from "../../store/accessPolicy/actions";
import { connect } from "react-redux";
import { getAccessForEntity } from "../../pages/policies";

import Index from "../../components/Common/DataTableNeo/Index";

import {
  AdminHeaderData,
  searchBar,
  getLocalStorage,
} from "./RowHeaders/RowHeaders.js";
import QuickSearchFilters from "./QickSeachFilter";
var data = {};
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var isSuperAdmin = false;

class Roles_New extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idToDelete: "",
      open: false,
      dataLoading: false,
      breadcrumbItems: [
        { title: "Role", link: "role" },
        { title: "Role Details", link: "#" },
      ],
      filters: "",
      editAccess: false,
      delAccess: false,
      rows:[]
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.policies !== prevProps.policies) {
      this.getAllData();
    }
  }
  componentDidMount = () => {
    this.getAllData();
  };

  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };

  hasAccessPolicy(val, entity) {
    const { policies } = this.props;
    let hasAccess = false;

    if (policies && policies.length > 0) {
      if (policies.includes("All policies")) isSuperAdmin = true;

      hasAccess = getAccessForEntity(policies, entity, val);
    }
    return hasAccess;
  }
  reset = () => {
    offset = 0;
    this.setState({ filters: "" }, () => {
      this.props.fetchRolesfilter(offset, this.state.filters, (res) => {
        // console.log("component", res);
        this.refineData(res);
      });
    });
  };
  getAllData() {
    const localStorageData = JSON.parse(localStorage.getItem("rolesListData"));
    this.setState({ dataLoading: true });

    this.props.fetchRoles(offset, (res) => {
      this.refineData(res);
      this.setState({ dataLoading: false });
    });
    if (!navigator.onLine) {
      this.setState(
        {
          loading: false,
        },
        () => {
          localStorageData
            ? this.refineData(localStorageData)
            : this.setState({ rows: [] });
        }
      );
    }
  }

  refineData = (res) => {
    if (Array.isArray(res?.data)) {
      totCnt = res?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      let rows = [];
      let editAccess = this.hasAccessPolicy("edit", "role");
      let delAccess = this.hasAccessPolicy("delete", "role");
      this.setState({ editAccess, delAccess });
      res.data.forEach((ele, i) => {
        let actions =
          editAccess || delAccess ? (
            <div disabled={ele?.name === "superadmin"}>
              {delAccess ? (
                <span>
                  <i
                    onClick={() => this.handleClickOpen(ele?._id)}
                    className="fas fa-trash"
                    style={{ cursor: "pointer" }}
                  ></i>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              ) : null}

              {editAccess ? (
                <span>
                  <Link to={`/edit-role-${ele?._id}`}>
                    <i className="fas fa-pencil-alt"></i>
                  </Link>
                </span>
              ) : null}
            </div>
          ) : (
            ""
          );
        rows.push({
          name:
            ele?.label?.replace(/_/g, " ").charAt(0).toUpperCase() +
            ele?.label?.replace(/_/g, " ").slice(1),
          description: ele?.description.toUpperCase(),
        });
        Object.keys(rows[i]).map((key) => {
          if (key !== "actions") {
            rows[i][key] = rows[i][key] ? rows[i][key] : "-";
          } else {
            if (!editAccess && !delAccess) delete rows[i][key];
          }
        });
      });
      this.setState({ rows:rows });
    }
  };
  handleClickOpen = (id) => {
    this.setState({ open: true, idToDelete: id });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handledeleteRole = () => {
    this.handleClose();
    this.props.deleteRole(this.state.idToDelete, (res) => {
      if (res["message"] && res["message"] === "roleGroup has been deleted") {
        NotificationManager.success(res["message"], "Success");
        this.getAllData();
      } else {
        NotificationManager.error("Something went wrong!", "Error");
      }
    });
  };
  handleChangePage = (event, val) => {
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    this.getAllData();
  };

  filterList = (e) => {
    // console.error(e,"e")

    if (this.state.filters.length > 2) {
      // console.error(e,"e")
      // console.log("called")
      this.props.fetchRolesfilter(offset, this.state.filters, (res) => {
        // console.log("component", res);
        this.refineData(res);
      });
    } else {
      this.getAllData();
    }
  };
  change = (e) => {
    let filters = e.target.value;
    this.setState({ filters }, () => {
      console.log("filter", this.state.filters);
    });
  };
  enterCheck = (event) => {
    if (event.key === "Enter") this.filterList();
  };
  render() {
    const { fullScreen } = this.props;
    const { loading, rows, editAccess, delAccess } = this.state;
    
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;

    // if (!editAccess && !delAccess) data.columns.pop(-1);
    return (
      <>
        <div>
          {getLocalStorage()}
          <QuickSearchFilters
            filterValue={this.state.filters}
            change={this.change}
            filterList={this.filterList}
            reset={this.reset}
            enterCheck={this.enterCheck}
          />
          <Container fluid>
            {userType && (userType != "admin" || userType != "Admin") ? (
              <Index
                {...this.props}
                headers={AdminHeaderData}
                data={rows}
                searchBar={searchBar}
                searchable={false}
                getAllData={this.getAllData}
                rowHeight={"56px"}
                dataLoading={this.state.dataLoading}
              />
            ) : (
              <Index
                {...this.props}
                headers={AdminHeaderData}
                data={rows}
                searchBar={searchBar}
                searchable={false}
                getAllData={this.getAllData}
                rowHeight={"56px"}
                dataLoading={this.state.dataLoading}
              />
            )}
          </Container>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  policies: state.AccessPolicy.accessPolicies.policies,
});

export default connect(mapStateToProps, {
  fetchRoles,
  deleteRole,
  fetchRolesfilter,
})(Roles_New);
