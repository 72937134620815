import { types } from "./actionTypes";
export const fetchDrivers = (offset,name,phone, filters ,callback) => ({
  type: types.FETCH_DRIVERS,
  offset,
  name,
  phone,
  filters,
  callback
});
export const fetchDriversfilter = (offset, filters, callback) => ({
  type: types.FETCH_FILTER_DRIVERS,
  offset,
  filters,
  callback,
});

export const fetchAvailDrivers = (callback) => ({
  type: types.FETCH_AVAIL_DRIVERS,
  callback,
});
// export const fetchPost = id => ({ type: types.FETCH_POST, id });
export const createDriver = (values, callback) => ({
  type: types.CREATE_DRIVER,
  values,
  callback,
});
export const deleteDriver = (id, callback) => ({
  type: types.DELETE_DRIVER,
  id,
  callback,
});
export const editDriverById = (values, callback) => ({
  type: types.EDIT_DRIVER_BYID,
  values,
  callback,
});
export const fetchDriverById = (id, callback) => ({
  type: types.FETCH_DRIVER_BYID,
  id,
  callback,
});
export const uploadDriver = (values, callback) => ({
  type: types.UPLOAD_DRIVER,
  values,
  callback,
});

// export const requestForConcent = (driverId, callback) => ({
//   type: types.REQUEST_CONSENT,
//   driverId,
//   callback,
// });
// export const requestForAddConcent = (driverId, callback) => ({
//   type: types.ADD_CONSENT,
//   driverId,
//   callback,
// });

export const subscribeConsent = (phone, callback) => ({
  type: types.SUBSCRIBE_CONSENT,
  phone,
  callback,
});
export const unsubscribeConsent = (phone, callback) => ({
  type: types.UNSUBSCRIBE_CONSENT,
  phone,
  callback,
});
