import React from "react";
import { Redirect } from "react-router-dom";
import DriverNew from "../pages/MastersDriver/Driver";
import VehicleNew from "../pages/MasterVehicle/Vehicle";
import VehicleType from "../pages/MasterVehicleType/VehicleType";
// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Dashboard
import OperationsDashboard from "../pages/Dashboard/OperationsDashboard/index";
import TransporterDashboard from "../pages/Dashboard/TransporterDashboard/transporterDashboard";
// import InvoiceDashboard from "../pages/Dashboard/InvoiceDashboard";

import InvoiceDashboardCard from "../pages/Dashboard/InvoiceDashboard/InvoiceDashboardCard";
// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsVector from "../pages/Maps/MapsVector";

//Utility
import Maintenance from "../pages/Utility/Maintenance";
import CommingSoon from "../pages/Utility/CommingSoon";
import Error404 from "../pages/Utility/Error404";
import Error401 from "../pages/Utility/Error401";
import Error500 from "../pages/Utility/Error500";
import ConfirmDelivery from "../pages/Utility/ConfirmDelivery";
import OrderDetails from "../pages/Utility/OrderDetails";
import OrderDetail from "../pages/Utility/OrderDetail";
// import OrderDetail from "../pages/Utility/orderNew";

// Forms
import FormAdvanced from "../pages/Forms/FormAdvanced";
import TripForm from "../pages/Forms/TripForm";
import EditTripForm from "../pages/Forms/EditTripForm";
import TrackTripForm from "../pages/Forms/TrackTripForm";
import EmpForm from "../pages/Forms/EmpForm";

//Tables
import EditableTables from "../pages/Tables/EditableTables";
import Dealer from "../pages/dealer";
import Employee from "../pages/employee";
import Hop from "../pages/hop";
import Role from "../pages/role";
import Roles_New from "../pages/Roles/Roles_New";

import Vehicle from "../pages/vehicle";
import Driver from "../pages/driver";
import Order from "../pages/order";
import Trip from "../pages/trip";

import TripBills from "../pages/Billing/tripBills";
import CreatorIndent from "../pages/creatorIndent";
import AssignedTransporterIndent from "../pages/AssignedIndent/assignedTransporterIndent";
// import AssignedTransporterIndent from "../pages/assignedTransporterIndent";

import Transporter from "../pages/Transporter";
import User from "../pages/user";
import Users_New from "../pages/Users/Users_New";

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";
import DealerForm from "../pages/Forms/DealerForm";
import EditDealerForm from "../pages/Forms/EditDealerForm";
import DriverForm from "../pages/Forms/DriverForm";
import EditDriverForm from "../pages/Forms/EditDriverForm";
import VehicleForm from "../pages/Forms/VehicleForm";
import EditVehicleForm from "../pages/Forms/EditVehicleForm";
import Upload from "../pages/Forms/upload";
import HopForm from "../pages/Forms/HopForm";
import EditHopForm from "../pages/Forms/EditHopForm";
import RoleForm from "../pages/Forms/RoleForm";
import EditRoleForm from "../pages/Forms/EditRoleForm";
import IndentForm from "../pages/Forms/IndentForm";
import TransporterForm from "../pages/Forms/TransporterForm";
import UserForm from "../pages/Forms/userForm";
import UserForm_New from "../pages/Forms/UserForm_New";
import Tracking from "../pages/Tracking";

//Billing
import agreementIndex from "../pages/Billing/agreementIndex";
import AgreementIndex_new from "../pages/Billing/AgreementIndex_new";
import addAgreementIndex from "../pages/Billing/AddAgreement/addAgreementIndex";
import InvoiceGeneration from "../pages/Billing/InvoiceNew/InvoiceGeneration/InvoiceGeneration";
import GeneratedInvoice from "../pages/Billing/InvoiceNew/GeneratedInvoices";
import InvoiceList from "../pages/Billing/InvoiceNew/InvoiceList/InvoiceList";
import InvoiceEdit from "../pages/Billing/InvoiceNew/InvoiceEdit";
//new
import UploadForm from "../components/Common/UploadForm";
import Reports from "../pages/Reports/Reports";
import Reports_New from "../pages/Reports/Reports_New";
import VehiclePlacement from "../pages/VehiclePlacementReport/vehiclePlacement";
import onTimeDeliveryReport from "../pages/OntimeDeliveryReport/onTimeDeliveryReport";
import LaneIndent from "../pages/IndentReports/LaneIndentReport/LaneIndent";
import StatusIndent from "../pages/IndentReports/StatusIndentReport/StatusIndent";
import TransportIndent from "../pages/IndentReports/TransporterIndentReport/TransporterIndent";

//UI Revamp
import Trip_new from "../pages/Trips/Trip_new";
import Indent_new from "../pages/Indents/Indent_new";
import RouteOptimization from "../pages/RouteOptimization/Index";
import ControlTower from "../pages/ControlTower/ControlTower";
import TripDetails_new from "../pages/TripDetail/TripDetails_new";
import LoadOptimization from "../pages/LoadOptimization/LoadOptimization";

//profile
import ProfileSettings from "../pages/ProfileSettings/ProfileSettings";

const authProtectedRoutes = [
  // Tables

  // { path: "/indent-planning", component: CreatorIndent },
  { path: "/indent-planning", component: Indent_new },
  {path:"/load-optimization", component:LoadOptimization},
  //New indent entry backup
  { path: "/Indent_new", component: Indent_new },
  { path: "/Route-Optimization", component: RouteOptimization },
  { path: "/assigned-indent", component: AssignedTransporterIndent },
  { path: "/tables-editable", component: EditableTables },
  { path: "/trips", component: Trip_new },
  // { path: "/trips", component: Trip },
  //new trip entry backup
  { path: "/Trip_new", component: Trip_new },
  // { path: "/tripDetails", component: TripDetails },
  { path: "/tripDetails", component: TripDetails_new },
  { path: "/tripBills", component: TripBills },
  { path: "/drivers", component: DriverNew },
  { path: "/add-driver", component: DriverForm },
  { path: "/edit-driver-:id", component: EditDriverForm },

  { path: "/vehicles", component: VehicleNew },
  { path: "/add-vehicle", component: VehicleForm },
  { path: "/edit-vehicle-:id", component: EditVehicleForm },

  { path: "/vehicle-type", component: VehicleType },

  { path: "/hops", component: Hop },
  { path: "/add-hop", component: HopForm },
  { path: "/edit-role-:id", component: EditRoleForm },

  // { path: "/roles", component: Role },
  { path: "/roles", component: Roles_New },
  { path: "/add-role", component: RoleForm },
  { path: "/edit-hop-:id", component: EditHopForm },

  { path: "/transporter", component: Transporter },
  { path: "/add-transporter", component: TransporterForm },

  // { path: "/user", component: User },
  { path: "/user", component: Users_New },
  // { path: "/add-user", component: UserForm },
  // { path: "/add-user", component: UserForm_New},

  { path: "/employees", component: Employee },
  { path: "/add-employee", component: EmpForm },

  { path: "/dealers", component: Dealer },
  { path: "/add-dealer", component: DealerForm },
  { path: "/edit-dealer-:id", component: EditDealerForm },

  // { path: "/indent-planning", component: CreatorIndent },
  // { path: "/assigned-indent", component: AssignedTransporterIndent },

  { path: "/orders", component: Order },
  { path: "/add-order", component: Upload },
  { path: "/upload", component: Upload },
  { path: "/upload-hop", component: Upload },
  { path: "/upload-order", component: Upload },
  { path: "/upload-dealer", component: Upload },
  { path: "/upload-driver", component: Upload },
  { path: "/upload-location", component: Upload },
  { path: "/upload-vehicle", component: Upload },
  { path: "/upload-employee", component: Upload },
  // { path: "/upload", component: Upload },
  // { path: "/upload", component: Upload },
  // { path: "/add-order", component: Upload },
  // { path: "/add-order", component: Upload },
  { path: "/add-indent", component: IndentForm },

  // Forms

  { path: "/form-advanced", component: FormAdvanced },
  //Reports
  // { path: "/Reports", component: Reports },
  { path: "/Reports", component: Reports_New },
  { path: "/vehicle-placement", component: VehiclePlacement },
  { path: "/ontime-delivery-report", component: onTimeDeliveryReport },
  { path: "/indentReport-analysis", component: LaneIndent },
  { path: "/statusIndent", component: StatusIndent },
  { path: "/transporterIndent", component: TransportIndent },

  // Maps
  { path: "/maps-google", component: MapsGoogle },
  { path: "/maps-vector", component: MapsVector },
  { path: "/Tracking", component: Tracking },
  //kurlon project:
  { path: "/operationsDashboard", component: OperationsDashboard },
  { path: "/transporterDashboard", component: TransporterDashboard },
  // { path: "/InvoiceDashboard", component: InvoiceDashboard },
  { path: "/invoice-dashboard", component: InvoiceDashboardCard },

  //forms
  { path: "/add-trip", component: TripForm },
  // { path: "/add-indent", component: IndentForm },
  { path: "/edit-trip-:id", component: EditTripForm },
  { path: "/track-trip-:id", component: TrackTripForm },
  { path: "/UploadForm", component: UploadForm },

  // { path: "/BillingIndex", component: agreementIndex },
  { path: "/BillingIndex", component: AgreementIndex_new },
  { path: "/invoiceGeneration", component: InvoiceGeneration },
  { path: "/generateInvoice", component: GeneratedInvoice },
  { path: "/Billing/AddAgreement", component: addAgreementIndex },
  { path: "/Billing/InvoiceList", component: InvoiceList },
  { path: "/InvoiceEdit", component: InvoiceEdit },
  { path: "/ControlTower", component: ControlTower },

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/operationsDashboard" />,
  },
];
const publicRoutes = [
  { path: "/indent-planning2", component: CreatorIndent },
  { path: "/assigned-indent2", component: AssignedTransporterIndent },
  { path: "/logout", component: Logout },
  { path: "/profileSettings", component: ProfileSettings },
  { path: "/login", component: Login },
  { path: "/confirm-delivery/:tripId/:dealerId", component: ConfirmDelivery },
  { path: "/orders-list/:extordId", component: OrderDetails },
  { path: "/order-detail/:extordId", component: OrderDetail },

  //test

  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/auth-lock-screen", component: AuthLockScreen },

  // Authentication Inner
  { path: "/auth-login", component: Login1 },
  { path: "/auth-register", component: Register1 },
  { path: "/auth-recoverpw", component: ForgetPwd1 },

  { path: "/pages-maintenance", component: Maintenance },
  { path: "/pages-comingsoon", component: CommingSoon },
  { path: "/pages-404", component: Error404 },
  { path: "/pages-500", component: Error500 },
  { path: "/pages-401", component: Error401 },

  //Billing Modules
  // { path: "/Billing/AddAgreement", component: addAgreementIndex },
];

export { authProtectedRoutes, publicRoutes };
