import React from "react";
import { Label, FormGroup, Input } from "reactstrap";
import Select from "react-select";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";

const MyIcon = React.forwardRef((props, ref) => {
  return (
    <span style={{ position: "absolute", top: "2px", right: "10px" }}></span>
  );
});

export const InputField = (props) => {
  const { controlType, inputName, labelValue, isRequired, errors } = props;
  return (
    <FormGroup className="select2-container">
      {
        <Label className={isRequired ? "required-field" : ""}>
          {labelValue}
        </Label>
      }
      {controlType === "text" && <RenderInput {...props} />}
      {controlType === "select" && <RenderSelect {...props} />}
      {controlType === "date" && <RenderDate {...props} />}
      {/* {errors[inputName] && <RenderFormError error={errors[inputName]} />} */}
    </FormGroup>
  );
};

export const RenderFormError = ({ error }) => {
  return <span className="err">{error}</span>;
};

const RenderInput = (inputProps) => {
  const {
    // inputType,
    inputName,
    inputValue,
    onChangeMethod,
    placeholderValue,
    handleKeyPress,
    handleBlur,
    innerRef,
  } = inputProps;
  return (
    <div className="RenderInput">
      <Input
        style={{ height: "22px", margin: "12px", fontSize: "12px" }}
        className="form-control"
        type={
          inputName === "indentId" || inputName === "tripId" ? "number" : "text"
        }
        value={inputValue}
        onChange={(event) =>
          onChangeMethod({
            target: {
              name: inputName,
              value: event.target.value,
            },
          })
        }
        placeholder={placeholderValue}
        onKeyPress={handleKeyPress && handleKeyPress}
        onBlur={(e) => handleBlur && handleBlur(e, inputName)}
        innerRef={innerRef}
      />
    </div>
  );
};

const RenderSelect = (selectProps) => {
  let mode = localStorage.getItem("Theme")
    ? localStorage.getItem("Theme")
    : null;
  let darkMode = mode === "dark__mode" ? true : false;
  const customStyles = {
    indicatorsContainer: (style) => {
      return {
        ...style,
        width: "20px",
        height: "22px",
        textAlign: "center",
        justifyContent: "center",
        borderRadius: "0px 4px 4px 0px",
      };
    },
    control: (style) => {
      return {
        ...style,
        textAlign: "center",
        alignSelf: "flex-end",
        height: "22px",
        minHeight: "22px",
        margin: "10px",
        backgroundColor: "#F1F5F7",
      };
    },
    input: (style, state) => {
      return {
        ...style,
        width: "20%",
        textAlign: "center",
        fontSize: "10",
        color: darkMode ? "#9d9d9d" : "#505d69",
      };
    },

    dropdownIndicator: (style) => {
      return {
        ...style,
        svg: {
          fill: "#c7c7c7",
        },
      };
    },
    placeholder: (style) => {
      return {
        ...style,
        fontSize: "12px",
        position: "sticky",
      };
    },
    valueContainer: (style) => {
      return {
        ...style,
        height: "22px",
        minHeight: "22px",
        alignContent: "center",
      };
    },
    container: (style) => {
      return {
        ...style,
        marginTop: "12px",
      };
    },
    menu: (style) => {
      return {
        ...style,
        backgroundColor: darkMode ? "#171722" : "#ffffff",
        color: darkMode ? "#B7A9FF" : "rgb(51, 51, 51)",
        border: darkMode
          ? "0.5px solid #616161"
          : "0.5px solid rgb(204, 204, 204)",
        boxShadow: darkMode
          ? "0px 3px 12px rgba(0, 0, 0, 0.12)"
          : "0px 3px 12px rgba(0, 0, 0, 0.12)",
        // zIndex: "22px",
        // position: "",
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        backgroundColor: darkMode
          ? darkMode && isFocused
            ? "#1b1c31"
            : null
          : isFocused
          ? "#cce9ff"
          : "",
        color: darkMode ? "#B7A9FF" : "rgb(51, 51, 51)",
      };
    },
  };
  const {
    inputValue,
    inputName,
    onChangeMethod,
    onInputChange,
    optionList,
    handleScrollBottom,
    handleMenuClose,
    isLoading,
    handleKeyPress,
    innerRef,
  } = selectProps;
  return (
    <Select
      value={inputValue}
      onChange={(e) =>
        onChangeMethod({
          target: {
            name: inputName,
            value: e.value,
          },
        })
      }
      options={optionList}
      classNamePrefix="select2-selection"
      placeholder="Select"
      styles={customStyles}
      onMenuScrollToBottom={(event) =>
        handleScrollBottom && handleScrollBottom(event)
      }
      onInputChange={onInputChange}
      onMenuClose={(event) => handleMenuClose && handleMenuClose(event)}
      isLoading={isLoading}
      onKeyPress={handleKeyPress}
      innerRef={innerRef}
    />
  );
};

const RenderDate = (inputProps) => {
  console.log(inputProps, "renderDate");
  const {
    // inputType,
    inputName,
    inputValue,
    onChangeMethod,
    placeholderValue,
    handleKeyPress,
    handleBlur,
    dateBg,
  } = inputProps;
  return (
    <Input
      style={{
        height: "22px",
        margin: "12px",
        fontSize: "12px",
        backgroundColor: `${dateBg}`,
      }}
      className="form-control"
      type="date"
      value={inputValue}
      onChange={(event) =>
        onChangeMethod({
          target: {
            name: inputName,
            value: event.target.value,
          },
        })
      }
      placeholder={placeholderValue}
      onKeyPress={handleKeyPress && handleKeyPress}
      onBlur={(e) => handleBlur && handleBlur(e, inputName)}
    />
  );
};

export const RenderNewDate = (inputProps) => {
  const {
    // inputType,
    inputName,
    inputValue,
    onChangeMethod,
    // onCloseMethod,
    placeholderValue,
    handleKeyPress,
    handleBlur,
    dateBg,
    dateClr,
  } = inputProps;
  return (
    // <LocalizationProvider dateAdapter={AdapterDateFns} utils={MomentUtils}>
    //   <DateRangePicker
    //     calendars={1}
    //     startText=""
    //     endText=""
    //     format={"MM-DD-YYYY"}
    //     // onClose={(event) => onCloseMethod(event)}
    //     // value={this.props.datevalues || [null, null]}
    //     value={inputValue || [null, null]}
    //     onChange={(event) => onChangeMethod(event)}
    //     renderInput={(startProps, endProps) => (
    //       <React.Fragment>
    //         <input
    //           style={{ width: "30%", margin: "auto" ,backgroundColor:`${dateBg}`,color:`${dateClr}`}}
    //           ref={startProps.inputRef}
    //           {...startProps.inputProps}
    //         />
    //         <Box> - </Box>
    //         <input
    //           style={{ width: "30%", margin: "auto" ,backgroundColor:`${dateBg}`,color:`${dateClr}`}}
    //           ref={endProps.inputRef}
    //           {...endProps.inputProps}
    //         />
    //       </React.Fragment>
    //     )}
    //   />
    // </LocalizationProvider>
    <div
      className="FieldsClass"
      style={{
        backgroundColor: `${dateBg}`,
        color: `${dateClr}`,
        height: "22px",
        margin: "12px",
        fontSize: "12px",
      }}
    >
      <DateRangePicker
        // className="RsuiteDateRange"
        size="md"
        placeholder="Select"
        block
        format="dd MMM yy"
        showOneCalendar
        placement="bottomStart"
        preventOverflow
        value={inputValue || [null, null]}
        onChange={(datevalues) => {
          onChangeMethod(datevalues);
          console.log(datevalues);
        }}
        caretAs={MyIcon}
      />
    </div>
  );
};

export const RenderNewDate2 = (inputProps) => {
  const {
    // inputType,
    inputName,
    inputValue,
    onChangeMethod,
    placeholderValue,
    handleKeyPress,
    handleBlur,
    dateBg,
  } = inputProps;
  return (
    <div></div>
    // <LocalizationProvider dateAdapter={AdapterDateFns} utils={MomentUtils}>
    //   <DateRangePicker
    //     label="Advanced keyboard"
    //     value={inputValue}
    //     onChange={(event) => onChangeMethod(event)}
    //     renderInput={(startProps, endProps) => (
    //       <React.Fragment>
    //         <input
    //           style={{ width: "30%", margin: "auto" ,backgroundColor:`${dateBg}` }}
    //           ref={startProps.inputRef}
    //           {...startProps.inputProps}
    //         />
    //         <Box> - </Box>
    //         <input
    //           style={{ width: "30%", margin: "auto" }}
    //           ref={endProps.inputRef}
    //           {...endProps.inputProps}
    //         />
    //       </React.Fragment>
    //     )}
    //   />
    // </LocalizationProvider>
  );
};
