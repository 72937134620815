import React, { Component } from "react";
import Switch from "react-switch";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";

class AppTheme extends Component {
  constructor() {
    super();
    this.state = {
      switch4: false,
      themeStaus: "light__mode",
    };
  }

  componentDidMount() {
    this.themeChange();
  }

  themeChange = () => {
    if (localStorage.getItem("Theme") === "light__mode") {
      document.body.classList.add("light__mode");
      document.body.classList.remove("dark__mode");
      this.setState({ switch4: false, themeStaus: "light__mode" });
    } else if (localStorage.getItem("Theme") === "dark__mode") {
      document.body.classList.remove("light__mode");
      document.body.classList.add("dark__mode");

      this.setState({ switch4: true, themeStaus: "dark__mode" });
    } else if (localStorage.getItem("Theme") === null) {
      document.body.classList.add("light__mode");
      document.body.classList.remove("dark__mode");
      this.setState({ switch4: false });
    }
  };

  onClickDark = () => {
    // if(this.state.switch4 === true){
    this.setState({ themeStaus: "dark__mode" });
    console.log(this.props.switchTheme, "ui");
    this.props.changeSwitchTheme("dark__mode");
    document.body.classList.add("dark__mode");
    document.body.classList.remove("light__mode");
    localStorage.setItem("Theme", "dark__mode");
    // }
  };
  onClickLight = () => {
    // if(this.state.switch4 === false){
    this.setState({ themeStaus: "light__mode" });
    this.props.changeSwitchTheme("light__mode");
    document.body.classList.add("light__mode");
    document.body.classList.remove("dark__mode");
    localStorage.setItem("Theme", "light__mode");
    // }
  };
  switchTheme = () => {
    this.setState({ switch4: !this.state.switch4 }, () => {
      if (this.state.switch4 === true) {
        this.setState({ themeStaus: "dark__mode" });
        console.log(this.props.switchTheme, "ui");
        this.props.changeSwitchTheme("dark__mode");
        document.body.classList.add("dark__mode");
        document.body.classList.remove("light__mode");
        localStorage.setItem("Theme", "dark__mode");
      }

      if (this.state.switch4 === false) {
        this.setState({ themeStaus: "light__mode" });
        this.props.changeSwitchTheme("light__mode");
        document.body.classList.add("light__mode");
        document.body.classList.remove("dark__mode");
        localStorage.setItem("Theme", "light__mode");
      }
    });
  };

  render() {
    let mode = localStorage.getItem("Theme");
    let bgcolor, clr;
    if (mode === "light__mode") {
      clr = "#FFFFFF";
      bgcolor =  "#0AB38C";
    }
    if (mode === "dark__mode") {
      clr = "#FFFFFF";
      bgcolor = "#0AB38C";
    }
    // function Offsymbol(text){
    //     return (
    //       <div
    //         style={{
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center",
    //           height: "100%",
    //           fontSize: 12,
    //           color: "#fff",
    //           paddingRight: 2
    //         }}
    //       >
    //         {" "}
    //         {text}
    //       </div>
    //     );
    //   };

    // function OnSymbol(text) {
    //     return (
    //       <div
    //         style={{
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center",
    //           height: "100%",
    //           fontSize: 12,
    //           color: "#fff",
    //           paddingRight: 2
    //         }}
    //       >
    //         {" "}
    //         {text}
    //       </div>
    //     );
    //   };

    return (
      <>
        <Row>
          <Col>
            <div
              className="pl-2  m-2"
              style={{
                padding: "10px",
              }}
            >
              <svg
                width="25"
                height="25"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.5 9C7.88071 9 9 7.88071 9 6.5C9 5.11929 7.88071 4 6.5 4C5.11929 4 4 5.11929 4 6.5C4 7.88071 5.11929 9 6.5 9Z"
                  fill= { mode === "dark__mode" ? "#FFF": "#05284E"}
                  stroke={ mode === "dark__mode" ? "#FFF": "#05284E"}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.5 1V2"
                  stroke={ mode === "dark__mode" ? "#FFF": "#05284E"}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.5 11V12"
                  stroke={ mode === "dark__mode" ? "#FFF": "#05284E"}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.61035 2.60986L3.32035 3.31986"
                  stroke={ mode === "dark__mode" ? "#FFF": "#05284E"}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.67969 9.67993L10.3897 10.3899"
                  stroke={ mode === "dark__mode" ? "#FFF": "#05284E"}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 6.5H2"
                  stroke={ mode === "dark__mode" ? "#FFF": "#05284E"}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11 6.5H12"
                  stroke={ mode === "dark__mode" ? "#FFF": "#05284E"}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.61035 10.3899L3.32035 9.67993"
                  stroke={ mode === "dark__mode" ? "#FFF": "#05284E"}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.67969 3.31986L10.3897 2.60986"
                  stroke={ mode === "dark__mode" ? "#FFF": "#05284E"}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="p-2 ml-2 mr-2">
                <div
                  className="btn-group"
                  style={{
                    height: "30px",
                    width: "116px",
                    borderRadius: "10px",
                  }}
                >
                  <button
                    type="button"
                    className="btn fontRoboto127"
                    style={{
                      backgroundColor:  mode === "dark__mode" ? "#24243C" :`${bgcolor}`,
                      color: `${clr}`,
                      padding: "5px",
                    }}
                    onClick={() => {
                      this.onClickLight();
                    }}
                  >
                    Light
                  </button>
                  <button
                    type="button"
                    className="btn fontRoboto127"
                    style={{
                      backgroundColor:  mode === "light__mode" ? "" :`${bgcolor}`,
                      color: mode === "light__mode" ? "#05284E":`${clr}`,
                      padding: "5px",
                    }}
                    onClick={() => {
                      this.onClickDark();
                    }}
                  >
                    Dark
                  </button>
                </div>
              </span>

              <svg
                width="25"
                height="25"
                viewBox="0 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 5.9955C10.9037 7.03794 10.5124 8.0314 9.87209 8.85961C9.23175 9.68782 8.36877 10.3165 7.38415 10.6722C6.39952 11.0278 5.33397 11.0957 4.31217 10.8679C3.29038 10.6401 2.3546 10.1259 1.61434 9.38566C0.874076 8.6454 0.359947 7.70962 0.132111 6.68783C-0.0957253 5.66603 -0.0278454 4.60048 0.327808 3.61585C0.683462 2.63123 1.31218 1.76825 2.14039 1.12791C2.9686 0.487564 3.96206 0.0963355 5.0045 0C4.39418 0.825693 4.10049 1.84302 4.17685 2.86695C4.25321 3.89087 4.69454 4.85339 5.42058 5.57942C6.14661 6.30546 7.10913 6.74679 8.13305 6.82315C9.15698 6.89951 10.1743 6.60582 11 5.9955Z"
                  fill={ mode === "dark__mode" ? "#FFF": "#05284E"}
                />
              </svg>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  console.log(state, "state");
  return {
    ...state.Layout,
    switchTheme: state.Layout.switchTheme,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeSwitchTheme: (name) => {
      dispatch({ type: "CHANGE_SWITCH_THEME", payload: name });
    },
  };
};

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(withRouter(AppTheme));
