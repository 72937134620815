import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  TabPane,
  CustomInput,
  Label,
  Input,
  Form,
  FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { connect } from "react-redux";
import { setOrderDetails } from "../../../../store/indent/actions";
import {
  fetchOrders,
  fetchOrdersfilter,
  fetchOrdersbyId,
} from "../../../../store/order/actions";
import {
  renderStateList,
  validateField,
  RenderInputControl,
} from "../../../Utility/userUtils";

const city = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

var offset = 0;

class OrderDetailTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderFormValues: {
        orderID: "",
        invoiceID: "",
        materialType: "",
        materialQuantity: "",
        unit: "",
        customerName: "",
        customerContact: "",
      },
      orderList: [],
    };
  }

  orderFormValues = {
    orderID: "",
    invoiceID: "",
    materialType: "",
    materialQuantity: "",
    unit: "",
    customerName: "",
    customerContact: "",
  };

  componentDidMount = () => {
    this.getAllData();
  };

  getAllData = () => {
    var orderArr = [];
    this.props.fetchOrders(offset, 100, (res) => {
      if (res.docs.length > 0) {
        res.docs.forEach((obj) => {
          orderArr.push({
            label: obj.ExternalOrderId,
            value: obj.ExternalOrderId,
          });
        });
      }
      this.setState({ orderList: orderArr });
    });
  };

  valueAssigner = (e, value) => {
    console.log(e);
    // if (value === "materialQuantity") {
    //   this.orderFormValues[value] = e.target.valueAsNumber;
    //   let n = e.target.valueAsNumber;
    //   this.setState((prevState) => ({
    //     orderFormValues: {
    //       ...prevState.orderFormValues,
    //       [value]: n,
    //     },
    //   }));
    // } else if (value === "orderID") {
    // } else {
    //   this.orderFormValues[value] = e.value;
    // this.setState((prevState) => ({
    //   orderFormValues: {
    //     ...prevState.orderFormValues,
    //     [value]: e.value,
    //   },
    // }));
    // }
    this.setState(
      (prevState) => ({
        orderFormValues: {
          ...prevState.orderFormValues,
          orderID: e.value,
        },
      }),
      () => {
        this.props.fetchOrdersbyId(
          "ExternalOrderId",
          this.state.orderFormValues.orderID,
          (res) => {
            this.refineData(res.docs);
            this.props.setOrderDetails(this.orderFormValues);
            this.props.checkValid(this.orderFormValues);
          }
        );
      }
    );
  };

  reset = () => {
    let orderFormValues = {
      orderID: "",
      invoiceID: "",
      materialType: "",
      materialQuantity: "",
      unit: "",
      customerName: "",
      customerContact: "",
    };
    this.orderFormValues = orderFormValues;
    this.setState({ orderFormValues: orderFormValues });
    this.props.setOrderDetails(orderFormValues);
    this.props.checkValid(orderFormValues);
  };

  refineData = (data) => {
    console.log("insiderd", data);
    // console.log(data[0].MattressType);
    if (data[0]) {
      this.orderFormValues.orderID = data[0].ExternalOrderId;
      this.orderFormValues.invoiceID = data[0].ExternalOrderId;
      this.orderFormValues.materialType = data[0].MattressType;
      this.orderFormValues.materialQuantity = data[0].Quantity;
      this.orderFormValues.unit = data[0].StockType;
      this.orderFormValues.customerContact = "1234567891";
      this.orderFormValues.customerName = "John Doe";
    }
    this.setState({ orderFormValues: this.orderFormValues });
  };

  fetcher = (e) => {
    if (e.length > 2) {
      this.props.fetchOrdersbyId("ExternalOrderId", e, (res) => {
        // this.refineData(res.docs);
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <TabPane tabId={2}>
          <Form>
            <Row>
              <Col lg="12">
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label className="col-form-label">Order ID</Label>
                      <Select
                        name="orderID"
                        value={
                          this.state.orderList.find((x) => {
                            return (
                              x.value === this.state.orderFormValues.orderID
                            );
                          }) || { value: "", label: "" }
                        }
                        label="Single select"
                        options={this.state.orderList}
                        onChange={(e) => this.valueAssigner(e, "orderID")}
                        onInputChange={(e) => this.fetcher(e)}
                        labelValue="Order ID"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      {/* <Label className="col-form-label">Invoice ID</Label> */}
                      <RenderInputControl
                        controlType="input"
                        inputType="text"
                        inputName="fullName"
                        inputValue={this.state.orderFormValues.invoiceID}
                        onChangeMethod={this.handleChange}
                        placeholderValue="Enter Full Name Here..."
                        handleBlur={this.trimSpace}
                        errors={false}
                        labelValue="Invoice ID"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      {/* <Label className="col-form-label">Material Type</Label> */}
                      <RenderInputControl
                        controlType="input"
                        inputType="text"
                        inputName="fullName"
                        inputValue={this.state.orderFormValues.materialType}
                        onChangeMethod={this.handleChange}
                        placeholderValue="Enter Full Name Here..."
                        handleBlur={this.trimSpace}
                        errors={false}
                        labelValue="Material Type"
                      />
                    </FormGroup>
                  </Col>

                  <Col md="4">
                    <FormGroup>
                      {/* <Label
                        htmlFor="example-number-input"
                        className="col-form-label"
                      >
                        Material Quantity
                      </Label> */}
                      <RenderInputControl
                        controlType="input"
                        inputType="number"
                        inputName="fullName"
                        inputValue={this.state.orderFormValues.materialQuantity}
                        onChangeMethod={this.handleChange}
                        placeholderValue="Enter Full Name Here..."
                        handleBlur={this.trimSpace}
                        errors={false}
                        labelValue="Material Type"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      {/* <Label className=" col-form-label">Unit</Label> */}
                      <RenderInputControl
                        controlType="input"
                        inputType="text"
                        inputName="fullName"
                        inputValue={this.state.orderFormValues.unit}
                        onChangeMethod={this.handleChange}
                        placeholderValue="Enter Full Name Here..."
                        handleBlur={this.trimSpace}
                        errors={false}
                        labelValue="Unit"
                      />
                    </FormGroup>
                  </Col>

                  <Col md="4">
                    <FormGroup>
                      {/* <Label className="col-form-label">Customer Name</Label> */}
                      <RenderInputControl
                        controlType="input"
                        inputType="text"
                        inputName="fullName"
                        inputValue={this.state.orderFormValues.customerName}
                        onChangeMethod={this.handleChange}
                        placeholderValue="Enter Full Name Here..."
                        handleBlur={this.trimSpace}
                        errors={false}
                        labelValue="Customer Name"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      {/* <Label className=" col-form-label">
                        Customer Contact
                      </Label> */}
                      <RenderInputControl
                        controlType="input"
                        inputType="text"
                        inputName="fullName"
                        inputValue={this.state.orderFormValues.customerContact}
                        onChangeMethod={this.handleChange}
                        placeholderValue="Enter Full Name Here..."
                        handleBlur={this.trimSpace}
                        errors={false}
                        labelValue="Customer Contact"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label className="col-form-label">
                        Attach Order/Invoice
                      </Label>
                      <div className="custom-file">
                        <CustomInput
                          type="file"
                          className=" form-control custom-file-input"
                          id="customFile"
                        />
                        <Label
                          className="custom-file-label"
                          htmlFor="customFile"
                        >
                          Choose file
                        </Label>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <div className="mb-2 mt-2 m5 text-center btn_indene_style">
                      <Link>
                        <Button
                          className="m-2 p-2 secondary"
                          onClick={this.reset}
                        >
                          Reset
                        </Button>
                      </Link>
                      <Link></Link>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* end */}
          </Form>
        </TabPane>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  indents: state.Indent.indents,
});

export default connect(mapStateToProps, {
  setOrderDetails,
  fetchOrders,
  fetchOrdersfilter,
  fetchOrdersbyId,
})(OrderDetailTab);
