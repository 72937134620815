import React, { Component } from "react";
import ReactApexCharts from "react-apexcharts";
import moment from "moment";

export default class OperationalTat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartType: this.props.chartType,
      series: [
        {
          name: this.props?.userLang?.dashboard?.AVERAGE_LOADING_TIME,
          data: [0, 0, 0, 0, 0, 0, 0],
          color: "rgba(172, 16, 0, 1)",
        },
        {
          name: this.props?.userLang?.dashboard?.AVERAGE_UNLOADING_TIME,
          data: [0, 0, 0, 0, 0, 0, 0],
          color: "rgba(255, 111, 0, 1)",
        },
        {
          name: this.props?.userLang?.dashboard?.QUALITY_CHECK_TIME_AVERAGE,
          data: [0, 0, 0, 0, 0, 0, 0],
          color: "rgba(40, 163, 76, 1)",
        },
      ],
      options: {
        tooltip: {
          // shared: false,
          // intersect: true,
          followCursor: true,
        },
        chart: {
          background: "transparent",
          foreColor: "#373d3f",
          width: "100%",
          height: 300,
          type: "bar",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        legend: {
          show: false,
          position: "bottom",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 0.9,
          //   curve: "straight",
        },
        //   theme: {
        //     mode: 'dark',
        //     palette: '#171722',
        //     monochrome: {
        //         enabled: false,
        //         color: 'white',
        //         shadeTo: 'light',
        //         shadeIntensity: 0.65
        //     },
        // },
        grid: {
          show: true,
          borderColor: "#90A4AE",
          strokeDashArray: 0,
          position: "back",
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
            labels: {
              rotate: 0,
             
            },
          },
          row: {
            colors: undefined,
            opacity: 0.5,
          },
          column: {
            colors: undefined,
            opacity: 0.5,
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },

        xaxis: {
          startAtZero: true,
        },

        xaxis: {
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: -0,
            offsetY: 0,
          },

          // tickAmount: "",
          tickPlacement: "between",
          categories: [
            "Jan-21",
            "Feb-21",
            "Mar-21",
            "Apr-21",
            "Aug-21",
            "Sep-21",
            "Oct-21",
          ],
        },

        yaxis: {
          forceNiceScale: true,
          show: true,
          showAlways: true,
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: 0,
            offsetY: +0,
          },

          //   tickAmount: 3,
          tickPlacement: "between",
          //   max:60
        },
      },
    };
  }
  componentDidUpdate = (prevProps) => {
    if (
      prevProps.operationalTat != this.props.operationalTat ||
      prevProps.chartType != this.props.chartType
    ) {
      let operationalTat = this.props?.operationalTat;
      // console.log("operationalTat operational TATt", operationalTat);
      let OperationalTatAVgMonth = [];
      let OperationalTatULMonth = [];
      let OperationalTatQuaMonth = [];
      let totalMonth = 12;
      let WeekDay = 7;
      let quater = 4
      let date =[];
      if(operationalTat?.length>0){
      if (this.props.selectedFilterOperational.value == "month") {
        for (let i = 0; i < totalMonth; i++) {
          let checkData = operationalTat?.filter((ele) => {
            // console.log("ele",ele)
            return ele?.tenure == i + 1;
          });
          // console.log("check data", checkData)
          if (checkData?.length > 0) {
            OperationalTatAVgMonth.push(
              Number(checkData[0].averageLoadingTime.toFixed(2))
            );
            OperationalTatULMonth.push(
              Number(checkData[0].averageUnloadingTime.toFixed(2))
            );
            OperationalTatQuaMonth.push(
              Number(checkData[0].averageQualityCheckTime.toFixed(2))
            );
          } else {
            OperationalTatAVgMonth.push(0);
            OperationalTatULMonth.push(0);
            OperationalTatQuaMonth.push(0);
          }
        }

        this.setState(
          {
            chartType: this.props.chartType,
            series: [
              {
                data: OperationalTatAVgMonth,
              },
              {
                data: OperationalTatULMonth,
              },
              {
                data: OperationalTatQuaMonth,
              },
            ],
            options: {
              xaxis: {
                categories: [
                  "JAN",
                  "FEB",
                  "MAR",
                  "APR",
                  "MAY",
                  "JUNE",
                  "JULY",
                  "AUG",
                  "SEPT",
                  "OCT",
                  "NOV",
                  "DEC",
                ],
              },
              chart: { type: this.props.chartType },
            },
          },
          () => {
            // console.log("tthis.state",this.state)
          }
        );
      } 
      else if (this.props.selectedFilterOperational.value == "weekDay") {
        for (let i = 0; i < WeekDay; i++) {
          let checkData = operationalTat.filter((ele) => {
            // console.log("ele",ele)
            return ele?.tenure == i;
          });
          // console.log("check data", checkData)
          if (checkData?.length > 0) {
            OperationalTatAVgMonth.push(
              Number(checkData[0].averageLoadingTime.toFixed(2))
            );
            OperationalTatULMonth.push(
              Number(checkData[0].averageUnloadingTime.toFixed(2))
            );
            OperationalTatQuaMonth.push(
              Number(checkData[0].averageQualityCheckTime.toFixed(2))
            );
          } else {
            OperationalTatAVgMonth.push(0);
            OperationalTatULMonth.push(0);
            OperationalTatQuaMonth.push(0);
          }
        }

        this.setState(
          {
            chartType: this.props.chartType,
            series: [
              {
                data: OperationalTatAVgMonth,
              },
              {
                data: OperationalTatULMonth,
              },
              {
                data: OperationalTatQuaMonth,
              },
            ],
            options: {
              xaxis: {
                categories: [
                  "Sunday",
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                ],
              },
              chart: { type: this.props.chartType },
            },
          },
          () => {
            // console.log("tthis.state",this.state)
          }
        );
      }
      else if (this.props.selectedFilterOperational.value == "quarter") {
        for (let i = 0; i < quater; i++) {
          let checkData = operationalTat.filter((ele) => {
            // console.log("ele",ele)
            return ele?.tenure == i+1;
          });
          // console.log("check data", checkData)
          if (checkData?.length > 0) {
            OperationalTatAVgMonth.push(
              Number(checkData[0].averageLoadingTime.toFixed(2))
            );
            OperationalTatULMonth.push(
              Number(checkData[0].averageUnloadingTime.toFixed(2))
            );
            OperationalTatQuaMonth.push(
              Number(checkData[0].averageQualityCheckTime.toFixed(2))
            );
          } else {
            OperationalTatAVgMonth.push(0);
            OperationalTatULMonth.push(0);
            OperationalTatQuaMonth.push(0);
          }
        }

        this.setState(
          {
            chartType: this.props.chartType,
            series: [
              {
                data: OperationalTatAVgMonth,
              },
              {
                data: OperationalTatULMonth,
              },
              {
                data: OperationalTatQuaMonth,
              },
            ],
            options: {
              xaxis: {
                categories: [
                  "Quater1 ",
                  "Quater2 ",
                  "Quater3 ",
                  "Quater4 ",
                ],
              },
              chart: { type: this.props.chartType },
            },
          },
          () => {
            // console.log("tthis.state",this.state)
          }
        );
      }
      else if (this.props.selectedFilterOperational.value == "date" || this.props.selectedFilterOperational.value == "week") {
        operationalTat.forEach((ele)=>{
          date.push(ele?.tenure)
          OperationalTatAVgMonth.push(
            Number(ele?.averageLoadingTime.toFixed(2))
          );
          OperationalTatULMonth.push(
            Number(ele?.averageUnloadingTime.toFixed(2))
          );
          OperationalTatQuaMonth.push(
            Number(ele?.averageQualityCheckTime.toFixed(2))
          );
       })
         
         

        this.setState(
          {
            chartType: this.props.chartType,
            series: [
              {
                data: OperationalTatAVgMonth,
              },
              {
                data: OperationalTatULMonth,
              },
              {
                data: OperationalTatQuaMonth,
              },
            ],
            options: {
              xaxis: {
                categories: [
                  ...date
                ],
              },
              chart: { type: this.props.chartType },
            },
          },
          () => {
            // console.log("tthis.state",this.state)
          }
        );
      }
      else if (this.props.selectedFilterOperational.value == "year" ) {
        operationalTat.forEach((ele)=>{
          date.push(ele?.tenure)
          OperationalTatAVgMonth.push(
            Number(ele?.averageLoadingTime.toFixed(2))
          );
          OperationalTatULMonth.push(
            Number(ele?.averageUnloadingTime.toFixed(2))
          );
          OperationalTatQuaMonth.push(
            Number(ele?.averageQualityCheckTime.toFixed(2))
          );
       })
         
         

        this.setState(
          {
            chartType: this.props.chartType,
            series: [
              {
                data: [0,...OperationalTatAVgMonth],
              },
              {
                data: [0,...OperationalTatULMonth],
              },
              {
                data: [0,...OperationalTatQuaMonth],
              },
            ],
            options: {
              xaxis: {
                categories: [date[0]-1,
                  ...date
                ],
              },
              chart: { type: this.props.chartType },
            },
          },
          () => {
            // console.log("tthis.state",this.state)
          }
        );
      }
    }
    }
  };

  render() {
    return (
      <div className="pt-1 cursor-pointer operationalTatApexChart themeDarkChartText">
        <ReactApexCharts
          options={this.state.options}
          series={this.state.series}
          type={this.state.chartType ? this.state.chartType : "line"}
          height={190}
        />
      </div>
    );
  }
}
