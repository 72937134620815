import React, { Component } from "react";
import { NavItem, NavLink, Progress } from "reactstrap";
import classnames from "classnames";

class IndentProgressBar extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
          <NavItem>
            <NavLink
              className={classnames({
                active: this.props.State.activeTabProgress === 1,
              })}
              onClick={() => {
                this.props.toggleTabProgress(1);
              }}
            >
              <span className="step-number">01</span>
              <span className="step-title">Route Details</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.props.State.activeTabProgress === 2,
              })}
              onClick={() => {
                this.props.toggleTabProgress(2);
              }}
            >
              <span className="step-number">02</span>
              <span className="step-title">Order Details</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.props.State.activeTabProgress === 3,
              })}
              onClick={() => {
                this.props.toggleTabProgress(3);
              }}
            >
              <span className="step-number">03</span>
              <span className="step-title">Vehicle Planning</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.props.State.activeTabProgress === 4,
              })}
              onClick={() => {
                this.props.toggleTabProgress(4);
              }}
            >
              <span className="step-number">04</span>
              <span className="step-title">Confirm Detail</span>
            </NavLink>
          </NavItem>
        </ul>
        <div id="bar" className="mt-4">
          <Progress
            className=""
            color="success"
            animated-progess
            value={this.props.State.progressValue}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default IndentProgressBar;
