import * as React from "react";
import { useRef, useEffect } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { Map } from "./Map";
import { Polyline } from "./Polyline";
import "./MapCore.css";
// import
import completed from "../../../src/assets/images/completed trip.svg";
import delay from "../../../src/assets/images/delayed trips.svg";
import intransit from "../../../src/assets/images/intransit trip.svg";
const render = (status) => {
  return <h1>{status}</h1>;
};
var darkMode;
const MapCore = (props) => {
  // [START maps_react_map_component_app_state]
  const [clicks, setClicks] = React.useState([]);
  const [zoom, setZoom] = React.useState(5); // initial zoom
  const [center, setCenter] = React.useState({ lat: 21.1458, lng: 79.0882 });
  const [vehicleIcon, setVehicleIcon] = React.useState(completed);

  const onClick = (e) => {
    // avoid directly mutating state
    setClicks([...clicks, e.latLng]);
  };
  useEffect(() => {
    if (props.subStatus === "in_transit" && props.delayedStatus === false) {
      setVehicleIcon(intransit);
    } else if (
      props.subStatus === "in_transit" &&
      props.delayedStatus === true
    ) {
      setVehicleIcon(delay);
    } else if (props.subStatus === "completed") {
      setVehicleIcon(completed);
    }
  }, [props.subStatus]);
  const onIdle = (m) => {
    setZoom(m.getZoom());
    setCenter(m.getCenter().toJSON());
  };

  const randomColor = () => {
    {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }
  };
  let mode = localStorage.getItem("Theme")
  ? localStorage.getItem("Theme")
  : null;
  darkMode = mode === "dark__mode" ? true : false;
  return (
    <div
      style={{ display: "flex", height: "800px", margin: "0", padding: "0" }}
    >
      <Wrapper apiKey={process.env.REACT_APP_GOOGLE_API_KEY} render={render}>
        <Map
          center={center}
          onClick={onClick}
          onIdle={onIdle}
          zoom={zoom}
          style={{ flexGrow: "1", height: "800px" }}
          options={darkMode?{
            styles: [
              { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
              { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
              { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
              {
                featureType: "administrative.locality",
                elementType: "labels.text.fill",
                stylers: [{ color: "#d59563" }],
              },
              {
                featureType: "poi",
                elementType: "labels.text.fill",
                stylers: [{ color: "#d59563" }],
              },
              {
                featureType: "poi.park",
                elementType: "geometry",
                stylers: [{ color: "#263c3f" }],
              },
              {
                featureType: "poi.park",
                elementType: "labels.text.fill",
                stylers: [{ color: "#6b9a76" }],
              },
              {
                featureType: "road",
                elementType: "geometry",
                stylers: [{ color: "#38414e" }],
              },
              {
                featureType: "road",
                elementType: "geometry.stroke",
                stylers: [{ color: "#212a37" }],
              },
              {
                featureType: "road",
                elementType: "labels.text.fill",
                stylers: [{ color: "#9ca5b3" }],
              },
              {
                featureType: "road.highway",
                elementType: "geometry",
                stylers: [{ color: "#746855" }],
              },
              {
                featureType: "road.highway",
                elementType: "geometry.stroke",
                stylers: [{ color: "#1f2835" }],
              },
              {
                featureType: "road.highway",
                elementType: "labels.text.fill",
                stylers: [{ color: "#f3d19c" }],
              },
              {
                featureType: "transit",
                elementType: "geometry",
                stylers: [{ color: "#2f3948" }],
              },
              {
                featureType: "transit.station",
                elementType: "labels.text.fill",
                stylers: [{ color: "#d59563" }],
              },
              {
                featureType: "water",
                elementType: "geometry",
                stylers: [{ color: "#17263c" }],
              },
              {
                featureType: "water",
                elementType: "labels.text.fill",
                stylers: [{ color: "#515c6d" }],
              },
              {
                featureType: "water",
                elementType: "labels.text.stroke",
                stylers: [{ color: "#17263c" }],
              },
            ],
          }:{styles:[]}}
        >
          {/* {clicks.map((latLng, i) => {
            return <Marker key={i} position={latLng} />;
          })} */}
          {props.destinationCoordinates &&
          Object.keys(props.destinationCoordinates).length === 0 &&
          Object.getPrototypeOf(props.destinationCoordinates) ===
            Object.prototype ? null : (
            <Marker
              key="destination_marker"
              position={props.destinationCoordinates}
            />
          )}
          {props.destinationList &&
            props.destinationList.map((item, index) => {
              return <Marker key="destination_marker" position={item} />;
            })}

          {props.sourceCoordinates &&
          Object.keys(props.sourceCoordinates).length === 0 &&
          Object.getPrototypeOf(props.sourceCoordinates) ===
            Object.prototype ? null : (
            <Marker
              key="source_marker"
              icon={{
                url: "https://maps.google.com/mapfiles/ms/icons/green-dot.png",
                scaledSize: new window.google.maps.Size(40, 40),
              }}
              position={props.sourceCoordinates}
            />
          )}

          {props.currentLocation &&
          Object.keys(props.currentLocation).length === 0 &&
          Object.getPrototypeOf(props.currentLocation) ===
            Object.prototype ? null : (
            <Marker
              key="marker_3"
              icon={{
                url: vehicleIcon,
                scaledSize: new window.google.maps.Size(50, 50),
              }}
              position={props.currentLocation}
            />
          )}
          {props.vehicleCoordinate &&
            props.vehicleCoordinate.map((item, index) => {
              if (item.status === "in_transit") {
                return (
                  <Marker
                    key="marker_4"
                    icon={{
                      url: item?.delayedStatus === false ? intransit : delay,
                      scaledSize: new window.google.maps.Size(50, 50),
                    }}
                    position={item?.coordinate}
                  />
                );
              } else if (item.status === "completed") {
                return (
                  <Marker
                    key="marker_5"
                    icon={{
                      url: completed,
                      scaledSize: new window.google.maps.Size(50, 50),
                    }}
                    position={item?.coordinate}
                  />
                );
              }
            })}
          {props.coordinateArray &&
            props.coordinateArray.map((item, index) => {
              const r = randomColor();
              return <Polyline path={item} strokeColor={r} />;
            })}

          {props.lineCoordinates &&
            props.lineCoordinates.map((item, index) => {
              return (
                <Polyline
                  path={item.lines}
                  geodesic={false}
                  options={{
                    strokeColor: item.color,
                    strokeOpacity: 1,
                    strokeWeight: 3,
                  }}
                />
              );
            })}
        </Map>
      </Wrapper>
      {/* {form} */}
    </div>
  );
  // [END maps_react_map_component_app_return]
};

// [START maps_react_map_marker_component]
const Marker = (options) => {
  const [marker, setMarker] = React.useState();

  React.useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);
  return null;
};
const AutoComplete = () => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {};
  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      console.log({ place });
    });
  }, []);
  return (
    <div>
      <label>enter address :</label>
      <input ref={inputRef} />
    </div>
  );
};
export default AutoComplete;
export { MapCore };
