import React, { Component } from "react";
import { Row, Col, Card, Button } from "reactstrap";

import calendarIcon from "../Trips/assets/calendar.png";
import exportIcon from "../Trips/assets/external-link.png";
import calendarDarkIcon from "../Trips/assets/calendarDark.png";
import exportDarkIcon from "../Trips/assets/external-linkDark.png";
import TextField from "@mui/material/TextField";
// import StaticDateRangePicker from "@mui/lab/StaticDateRangePicker";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Box from "@mui/material/Box";
import { ClickAwayListener } from "@material-ui/core";
import refreshIcon from "./assets/refresh-cw.png";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import "../Dashboard/OperationsDashboard/Styles/dashboard.css";

const MyIcon = React.forwardRef((props, ref) => {
  return (
    <img src={darkMode?calendarDarkIcon:calendarIcon} />
  );
});

let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
var darkMode
class QuickFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hops: [],
      vehicleTypes: [],
      // searchBar: [],
      searchFilters: {},
      dateValue: [null, null],
      showCalender: false,
      userLangs: undefined,
    };
  }
  handleClickAway = () => {
    if (this.state.showCalender == true) {
      this.setState({ showCalender: false });
    }
  };
  handleOpen = () => {
    this.setState({ showCalender: true });
  };
  handleClose = () => {
    this.setState({ showCalender: false });
  };
  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (this.state.userLangs !== userLang || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }
  render() {
     darkMode = this.props?.mode
    return (
      <React.Fragment>
        <Card className={`${darkMode ? "darkBgQuickFilter" : ""}`}>
          <div className={`quickStylesBar trip-quick-filters ${darkMode ? "darkBgQuickFilter" : ""}`}
          >
            <Row>
              <Col xs={9} md={8}>
                <Button
                  variant="primary"
                  onClick={() => this.props.quickFilters("delayed")}
                  // className="delayed mr-2"
                  className={darkMode ?
                    `${this.props.activeTripDelayed === true
                      ? "activeDarkStyle mr-2"
                      : "delayDarkStyle mr-2"
                    }`
                    : `${this.props.activeTripDelayed === true
                      ? "activeTripDelayed mr-2"
                      : "delayed  mr-2"
                    }`}
                >
                  {this.state.userLangs?.common?.DELAYED}
                </Button>
                <Button
                  variant="primary"
                  onClick={() => this.props.quickFilters("untracked")}
                  className={darkMode ?
                    `${this.props.activeTripUntrack === true
                      ? "activeDarkStyle mr-2"
                      : "delayDarkStyle mr-2"
                    }`
                    : `${this.props.activeTripUntrack === true
                      ? "activeTripUntrack mr-2"
                      : "untracked  mr-2"
                    }`}
                >
                  {this.state.userLangs?.common?.UNTRACKED}
                </Button>
                <Button className="desktop-reset reset"
                  onClick={() => {
                    this.setState({ dateValue: [null, null] }, () => {
                      this.props.reset();
                    });
                  }}
                >
                  {this.state.userLangs?.common?.RESET}
                </Button>
                <Button
                  className="mobile-reset reset"
                  onClick={() =>
                    this.setState({ dateValue: [null, null] }, () => {
                      this.props.reset();
                    })
                  }
                >
                  <img className="mobile-rest-img"
                    src={refreshIcon}
                  ></img>
                </Button>
              </Col>
              <Col xs={3} md={4} style={{ textAlignLast: "end" }}
                className="tripMobileHeader">
                <div style={{ width: "95%", float: "left" }} className="">
                  <div className="tooltipIcon QuickFilters">
                    {/* <Button
                       onClick={() => {
                        this.setState({
                          showCalender: !this.state.showCalender,
                        });
                      }}
                      className={`iconButtons mr-2 ${darkMode?'actionButtonsDark':''}`}
                    >
                      <img
                        style={{ margin: "-4px", height:"15px" }}
                        src={darkMode?calendarDarkIcon:calendarIcon}
                        alt="cal"
                      />
                    </Button> */}
                    <div className="datepicker-selector-mobile iconButtons mobileCalender actionButtonsDark  mr-2 datepicker-custom">
                      <DateRangePicker
                        size="md"
                        placeholder=" "
                        block
                        format="dd MMM yy"
                        showOneCalendar
                        placement="bottom"
                        preventOverflow
                        value={this.state.dateValue || [null, null]}
                        onChange={(newValue) => {
                          console.log(newValue)
                          this.props.quickFilters("date",newValue)
                        }}
                        caretAs={MyIcon}
                      // onChange={changeHandler}
                      />
                    </div>
                    <span className="tooltipIcontext">
                      &nbsp;{this.state.userLangs?.common?.CALENDER}&nbsp;
                    </span>
                  </div>
                  {/* {this.state.showCalender ? (
                    <ClickAwayListener onClickAway={this.handleClickAway}>
                      <div style={{ float: "right", direction: "rtl" }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <StaticDateRangePicker
                            displayStaticWrapperAs="desktop"
                            calendars={1}
                            format={"MM-DD-YYYY"}
                            value={this.state.dateValue}
                            onClose={this.handleClose}
                            onOpen={this.handleOpen}
                            onChange={(newValue) => {
                              this.setState({ dateValue: newValue }, () => {
                                if (this.state.dateValue[1] !== null) {
                                  this.setState({
                                    showCalender: !this.state.showCalender,
                                  });
                                  this.props.quickFilters(
                                    "date",
                                    this.state.dateValue
                                  );
                                }
                              });
                            }}
                            renderInput={(startProps, endProps) => (
                              <React.Fragment>
                                <TextField {...startProps} />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <TextField {...endProps} />
                              </React.Fragment>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </ClickAwayListener>
                  ) : null} */}
                </div>

                <div style={{ width: "5%", float: "right" }}>
                  <div className="tooltipIcon">
                    <Button
                      disabled={!navigator.onLine}
                      onClick={() => this.props.toggleExcel()}
                      className={`iconButtons ${darkMode ? 'iconDarkButtons' : ''}`}
                    >
                      <img
                        style={{ margin: "-4px" }}
                        src={darkMode ? exportDarkIcon : exportIcon}
                        alt="exp"
                      />
                    </Button>
                    <span className="tooltipIcontext" style={{ width: "55px" }}>
                      {" "}
                      &nbsp; {this.state.userLangs?.invoice?.EXPORT}&nbsp;
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default QuickFilters;
