import React, { Component } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { restrictSync, roleCheck } from "../../pages/restrict";

const billingImage = require("../../assets/images/navigation/BILLING.png");
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

let MAIN_INVOICE = "MAIN_INVOICE";

export class InvoiceMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userLangs: undefined,
    };
  }
  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (userLang !== this.state.userLangs || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }
  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

    return (
      <>
        {(() => {
          //transporter
          if (roleCheck("transporter")) {
            return (
              <div className="linkSection">
                <div
                  className={`mainLink ${
                    this.props.activeState === MAIN_INVOICE
                      ? "activeLinkSection"
                      : null
                  }`}
                  onClick={() => this.props.handleActiveLink(MAIN_INVOICE)}
                >
                  <div>
                    <img src={billingImage} />
                    <span className="mainLinkName">
                      {this.state.userLangs?.dashboard?.INVOICE}
                    </span>
                    {this.props.activeState === MAIN_INVOICE &&
                    this.props.show === true ? (
                      <KeyboardArrowUpIcon
                        sx={this.props.keyboardArrowIconStyle}
                      />
                    ) : (
                      <KeyboardArrowDownIcon
                        sx={this.props.keyboardArrowIconStyle}
                      />
                    )}
                  </div>
                </div>
                <div
                  className={`subMenu ${
                    this.props.activeState === MAIN_INVOICE &&
                    this.props.show === true
                      ? "displaySubMenu"
                      : "hideSubMenu"
                  }`}
                >
                  <Link to="/Billing/InvoiceList">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">
                        {this.state.userLangs?.invoice?.INVOICE_LIST}

                      </span>
                    </div>
                  </Link>
                  <Link to="/invoiceGeneration">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">
                        {this.state.userLangs?.invoice?.INVOICE_GENERATION}
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            );
            //Admin||BillingManager
          } else if (roleCheck("Admin") || roleCheck("billing_manager")) {
            return (
              <div className="linkSection">
                <div
                  className={`mainLink ${
                    this.props.activeState === MAIN_INVOICE
                      ? "activeLinkSection"
                      : null
                  }`}
                  onClick={() => this.props.handleActiveLink(MAIN_INVOICE)}
                >
                  <div>
                    <img src={billingImage} />
                    <span className="mainLinkName">
                      {this.state.userLangs?.dashboard?.INVOICE}
                    </span>
                    {this.props.activeState === MAIN_INVOICE &&
                    this.props.show === true ? (
                      <KeyboardArrowUpIcon
                        sx={this.props.keyboardArrowIconStyle}
                      />
                    ) : (
                      <KeyboardArrowDownIcon
                        sx={this.props.keyboardArrowIconStyle}
                      />
                    )}
                  </div>
                </div>
                <div
                  className={`subMenu ${
                    this.props.activeState === MAIN_INVOICE &&
                    this.props.show === true
                      ? "displaySubMenu"
                      : "hideSubMenu"
                  }`}
                >
                  <Link to="/BillingIndex">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">
                        {this.state.userLangs?.invoice?.AGREEMENT}
                      </span>
                    </div>
                  </Link>
                  <Link to="/Billing/InvoiceList">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">
                        {this.state.userLangs?.invoice?.INVOICE_LIST}
                      </span>
                    </div>
                  </Link>

                  <Link to="/tripBills">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">
                        {this.state.userLangs?.invoice?.ADD_BILL}
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            );
            // billing_team_member
          } else if (roleCheck("billing_team_member")) {
            return (
              <div className="linkSection">
                <div
                  className={`mainLink ${
                    this.props.activeState === MAIN_INVOICE
                      ? "activeLinkSection"
                      : null
                  }`}
                  onClick={() => this.props.handleActiveLink(MAIN_INVOICE)}
                >
                  <div>
                    <img src={billingImage} />
                    <span className="mainLinkName">
                      {this.state.userLangs?.dashboard?.INVOICE}
                    </span>
                    {this.props.activeState === MAIN_INVOICE &&
                    this.props.show === true ? (
                      <KeyboardArrowUpIcon
                        sx={this.props.keyboardArrowIconStyle}
                      />
                    ) : (
                      <KeyboardArrowDownIcon
                        sx={this.props.keyboardArrowIconStyle}
                      />
                    )}
                  </div>
                </div>
                <div
                  className={`subMenu ${
                    this.props.activeState === MAIN_INVOICE &&
                    this.props.show === true
                      ? "displaySubMenu"
                      : "hideSubMenu"
                  }`}
                >
                  <Link to="/Billing/InvoiceList">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">
                        {this.state.userLangs?.invoice?.INVOICE_LIST}
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            );
          }
          // loading_unloading
          else if (roleCheck("loading_unloading")) {
            return (
              <div className="linkSection">
                <div
                  className={`mainLink ${
                    this.props.activeState === MAIN_INVOICE
                      ? "activeLinkSection"
                      : null
                  }`}
                  onClick={() => this.props.handleActiveLink(MAIN_INVOICE)}
                >
                  <div>
                    <img src={billingImage} />
                    <span className="mainLinkName">
                      {this.state.userLangs?.dashboard?.INVOICE}
                    </span>
                    {this.props.activeState === MAIN_INVOICE &&
                    this.props.show === true ? (
                      <KeyboardArrowUpIcon
                        sx={this.props.keyboardArrowIconStyle}
                      />
                    ) : (
                      <KeyboardArrowDownIcon
                        sx={this.props.keyboardArrowIconStyle}
                      />
                    )}
                  </div>
                </div>
                <div
                  className={`subMenu ${
                    this.props.activeState === MAIN_INVOICE &&
                    this.props.show === true
                      ? "displaySubMenu"
                      : "hideSubMenu"
                  }`}
                >
                  <Link to="/BillingIndex">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">
                        {this.state.userLangs?.invoice?.AGREEMENT}
                      </span>
                    </div>
                  </Link>
                  <Link to="/tripBills">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">
                        {this.state.userLangs?.invoice?.ADD_BILL}
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            );
          } else {
            return <p></p>;
          }
        })()}
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  // console.log(state, "state");
  return {
    ...state.Layout,
    activeState: state.Layout.activeTheme,
  };
};

export default connect(mapStatetoProps)(withRouter(InvoiceMenu));
