const restrict = async (perm) => {
  let data = JSON.parse(window.localStorage.getItem("authUser"));
  let permissions = data.data.permissions;
  // console.log(perm, "reeere");

  return permissions.includes(perm);
};

const restrictSync = (perm) => {
  let data = JSON.parse(window.localStorage.getItem("authUser"));
  let permissions = data.data.permissions;
  // console.log(perm, "reeere");

  return permissions.includes(perm);
};

const roleCheck = (roleString) => {
  let data = JSON.parse(window.localStorage.getItem("authUser"));
  let role = data.data.role.label;
  if (roleString === role) return true;
  else return false;
};

export { restrict, restrictSync, roleCheck };
