import { takeEvery, fork, all, call } from "redux-saga/effects";

// Login Redux States
import { FORGET_USER, CONFIRM_PASSWORD, SEND_OTP } from "./actionTypes";
// import { forgetUserSuccessful, userForgetPasswordError } from "./actions";

// AUTH related methods
import {
  // postApi,
  postForgetPwd,
  patchForgetPwd,
} from "../../../helpers/auth_helper";
// import { getFirebaseBackend } from "../../../helpers/firebase_helper";

//Initilize firebase
// const fireBaseBackend = getFirebaseBackend();

//If user is login then dispatch redux action's are directly from here.
function* sendOtpToUser({ values, callback }) {
  try {
    const uri = process.env.REACT_APP_SERVERURL + `/phoneauth/sms`;
    // console.log(uri, values);
    const response = yield call(postForgetPwd, uri, values);
    callback(response);
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not send otp.", error);
  }
}

function* forgetUser({ values, callback }) {
  try {
    const uri = process.env.REACT_APP_SERVERURL + `/phoneauth/verifyOTP`;
    const response = yield call(postForgetPwd, uri, values);
    callback(response);
  } catch (error) {
    console.log("Request failed! Could not verify otp.", error);
  }
}

function* sendNewPassword({ values, callback }) {
  try {
    const uri = process.env.REACT_APP_SERVERURL + `/user/update`;
    const response = yield call(patchForgetPwd, uri, values);
    callback(response);
  } catch (error) {
    console.log("Request failed! Could not update password.", error);
  }
}

export function* watchSendOtp() {
  yield takeEvery(SEND_OTP, sendOtpToUser);
}
export function* watchUserForget() {
  yield takeEvery(FORGET_USER, forgetUser);
}
export function* watchUserNewPassword() {
  yield takeEvery(CONFIRM_PASSWORD, sendNewPassword);
}
function* forgetSaga() {
  yield all([
    fork(watchSendOtp),
    fork(watchUserForget),
    fork(watchUserNewPassword),
  ]);
}

export default forgetSaga;
