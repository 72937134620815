import React, { Component } from "react";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Card from "@mui/material/Card";
import { Progress } from "reactstrap";
import { Row, Col } from "reactstrap";
import Tooltip from "@material-ui/core/Tooltip";
import IndentDetailDonutChart from "./IndentDetailDountChart";
// import { Card } from "reactstrap";
import Grid from "@mui/material/Grid";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import exportIcon from "../assets/external-link.png";
import exportDarkIcon from "../../Trips/assets/external-linkDark.png";
import { Button } from "@mui/material";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import OnTimeDeliverCards from "./OnTimeDeliverCards";

export default class OnTimeDelivery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: false,
    };
  }
  toggle = () => {
    this.setState((prevState) => ({
      menu: false,
    }));
  };
  toggleExcel = () => {
    setTimeout(async () => {
      let arrTat = [];
      let onTimeDelivery = this.props?.performance;
      console.log("OnTimedelivery", onTimeDelivery);
      var performanceObj = {
        onTimeDeliveries:0,
        onTimeDeliverisPercentage:0,
       };
      if(onTimeDelivery  != undefined){
        onTimeDelivery.map((ele)=>{
          // console.log("ele tes", ele)
          if(ele?.performanceType == "onTimeDelivery"){
            arrTat.push({
              "Total Deliveries": ele?.totalTrips,
              "OnTime Deliveries(%)": Number((ele?.performancePercentage*100).toFixed(2)),
              "Rejected Deliveries(%)": Number(100-Number((ele?.performancePercentage*100).toFixed(2))),
            });
           
          }
        })
      }
      await this.exportToCSV([...arrTat], "On_Time_Delivery_DATA_EXCEL");
    }, 1000);

    this.setState({ loading: true, export: true });
  };
  exportToCSV = (csvData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    this.setState({ export: false });
  };
  render() {
    let userPreferredLang = window.localStorage.getItem("userPreferredLang");
    const indents = this.props.indents;
    var darkMode = this.props.darkTheme
    return (
      <Card className="onTimeDelivery darkCardStyle">
        <div className="mt-2 pl-3">
          <Row className="pt-2">
            <Col xs="9" className="pdt5">
              <span className="roboto-font-700 span-indent-name light__span">
                {this.props?.userLang?.dashboard?.ON_TIME_DELIVERY}
                {/* On time delivery */}
              </span>
            </Col>
            <Col xs="3">
              <span
                className="pr-3"
                style={{
                  float: "right",
                  marginLeft: "535px",
                }}
              >
                <div
                  className="d-flex justify-content-end"
                  style={{
                    position: "relative",
                    right: "6px",
                  }}
                >
                  <div className="pr-2">
                    {/* <div className=''>
                        <Dropdown
                          isOpen={this.state.menu}
                          toggle={this.toggle}
                          className='d-none d-sm-inline-block'
                        >
                          <DropdownToggle
                            style={{
                              width: '100%',
                              display: 'flex',
                              height: '26px',
                              border: '1px solid blue',

                              justifyContent: 'center',
                            }}
                            tag='button'
                            className='btn mt-0 pt-0'
                          // id="page-header-user-dropdown"
                          >
                            <span
                              style={{
                                display: 'flex',
                                marginTop: '5px',
                              }}
                              className='d-xl-inline-block ml-1 text-transform profile-name'
                            >
                              Monthly
                            </span>
                            <i
                              style={{
                                fontSize: '17px',
                              }}
                              className='mdi mdi-chevron-down ml-1 pl-1 d-xl-inline-block mt-0 profile-chervon'
                            ></i>
                          </DropdownToggle>
                          <DropdownMenu right>
                            <DropdownItem></DropdownItem>

                            <DropdownItem
                              href=''
                              className='notify-item'
                            ></DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div> */}
                  </div>

                  <div>
                    <div className={navigator.onLine ? "tooltipIcon" : "tooltipIcon disable-export"}>
                        <svg
                        className="cursor-pointer exportDark"
                        onClick={() => this.toggleExcel()}
                          width="27"
                          height="27"
                          viewBox="0 0 27 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M22.6299 0.0490417H4.62988C2.42074 0.0490417 0.629883 1.8399 0.629883 4.04904V22.049C0.629883 24.2582 2.42074 26.049 4.62988 26.049H22.6299C24.839 26.049 26.6299 24.2582 26.6299 22.049V4.04904C26.6299 1.8399 24.839 0.0490417 22.6299 0.0490417Z"
                            fill="#F1F5F7"
                          />
                          <path
                            d="M22.6299 0.299042H4.62988C2.55882 0.299042 0.879883 1.97797 0.879883 4.04904V22.049C0.879883 24.1201 2.55882 25.799 4.62988 25.799H22.6299C24.701 25.799 26.3799 24.1201 26.3799 22.049V4.04904C26.3799 1.97797 24.701 0.299042 22.6299 0.299042Z"
                            stroke={darkMode?"#B7A9FF":"#2800FC"}
                            strokeWidth="0.5"
                          />
                          <path
                            d="M18.7559 14.133V18.983C18.7559 19.4119 18.5855 19.8232 18.2823 20.1264C17.979 20.4297 17.5677 20.6 17.1389 20.6H8.24688C7.81803 20.6 7.40674 20.4297 7.10349 20.1264C6.80024 19.8232 6.62988 19.4119 6.62988 18.983V10.091C6.62988 9.66219 6.80024 9.2509 7.10349 8.94765C7.40674 8.64441 7.81803 8.47404 8.24688 8.47404H13.0969"
                            stroke={darkMode?"#B7A9FF":"#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M16.3311 6.04904H21.1811V10.899"
                            stroke={darkMode?"#B7A9FF":"#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.9058 15.325L20.9058 6.32504"
                            stroke={darkMode?"#B7A9FF":"#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      <span
                        className="tooltipIcontext1"
                        style={{ width: "55px" }}
                      >
                        {this.props?.userLang?.invoice?.EXPORT ?? "Export"}
                      </span>
                    </div>
                  </div>
                </div>
              </span>
            </Col>
          </Row>
        </div>
        <hr className="mt-3 indent-hr-line" />
        <OnTimeDeliverCards 
         performance={this.props?.performance}
        indents={indents} 
        userLang={this.props.userLang} />
      </Card>
    );
  }
}
