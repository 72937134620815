import React, { Component } from "react";
import ReactApexCharts from "react-apexcharts";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import exportIcon from "../../Trips/assets/external-link.png";
import exportDarkIcon from "../../Trips/assets/external-linkDark.png";
import { Button } from "@mui/material";
import { Container, Card, CardBody, Row, Col } from "reactstrap";

class BarChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name:"",
          data: [],
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          width: "100%",
          toolbar: { show: false },
        },
        legend: {
          show: false,
        },
        grid: {
          show: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        fill: { type: "gradient" },
        colors: ["#2800FC"],

        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
          tickAmount: 9,
          tickPlacement: "between",
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: 0,
            offsetY: 1,
          },
        },
      },
    };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.laneWiseTripCount != this.props.laneWiseTripCount) {
      let datalabels = this.props.laneWiseTripCount;
      // console.log(datalabels, "propDatalane")
      let series = [];
      let label = [];
      if (Array.isArray(this.props?.laneWiseTripCount)) {
        this.props.laneWiseTripCount
          .sort((a, b) => (a?.key > b?.key ? 1 : -1))
          .forEach((ele) => {
            label.push(ele.key);
            series.push(ele.value);
          });
      }

      let revarray = series;
      // console.log(revarray, "propDatalane")
      let revlabelarray = label;
      this.setState({
        options: {
          ...this.state.options,
          xaxis: { categories: revlabelarray },
        },
      });
      this.setState({ series: [{ data: revarray }] });
    }
  };

  render() {
    let darkMode = this.props.darkBarChart;
    return (
      <React.Fragment>
        <>
          <Card className={`barChart-card ${darkMode ? "barBgDarkCard" : ""}`}>
            <CardBody>
              <div className="mt-1 pl-3 pt-1">
                <Row>
                  <Col xs="11">
                    <span
                      className={`barChartHeader ${
                        darkMode ? "light__span" : ""
                      }`}
                    >
                      {this.props?.userLang?.dashboard?.BUSINESS_BREAKUP}
                    </span>
                  </Col>
                  <Col xs="1">
                    <span className="pr-0">
                      <MoreHorizIcon style={{ color: "blue" }}></MoreHorizIcon>
                    </span>
                  </Col>
                </Row>
              </div>
              <hr
                className="mt-1 actionLine"
                style={{
                  top: "15px",
                  marginTop: "15px",
                  borderTop: darkMode
                    ? "0.5px solid #B9B9B9"
                    : "1px solid #e5e5e5",
                  width: "99%",
                }}
              />
              <Container fluid>
                <div className={`barCardStyle ${darkMode ? "barBgDark" : ""}`}>
                  <CardBody style={{ height: "100%" }}>
                    <div id="chart">
                      <ReactApexCharts
                        options={this.state.options}
                        series={this.state.series}
                        type="bar"
                        height={390}
                        max-width={250}
                      />
                    </div>
                  </CardBody>
                </div>
              </Container>
            </CardBody>
          </Card>
        </>
      </React.Fragment>
    );
  }
}
export default BarChart;
