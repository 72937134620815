import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { FormGroup, Row, Col, Button, h5 } from "reactstrap";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { CSVLink } from "react-csv";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { getLocalStorage } from "../RowHeaders/RowHeaders";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";

import DynamicTable from "../../../components/Common/DynamicTable";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

var date = moment(new Date()).format("DD-MMM-YYYY");
class InvoicePdf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      transporterName: "",
      invoiceId: "",
      date: "",
      gst: "",
      raiseDate: "",
      state: "",
      billCount: "",
    };
  }

  pdfDownload = () => {
    // let pdf =new jsPDF('p', 'pt', 'letter');
    // pdf.setFontSize(8);
    // setTimeout(function(quality = 1){
    //   pdf.html(document.getElementById("printagreement"),{pagesplit:true,margins:[10,10,10,10],callback:function  (pdf) {
    //       ////console.log(document.getElementById("printagreement"))
    //     // pdf.save()
    //     window.open(pdf.output('bloburl'))

    //   }});
    setTimeout(function () {
      let quotes = document.getElementById("printinvoice");
      html2canvas(quotes).then((canvas) => {
        //! MAKE YOUR PDF
        let pdf = new jsPDF("l", "pt", "letter", true);
        //     let pdf= new jsPDF({
        //       orientation: 'l', // landscape
        //       unit: 'pt', // points, pixels won't work properly
        // })

        for (let i = 0; i <= quotes.clientHeight / 1210; i++) {
          //! This is all just html2canvas stuff
          let srcImg = canvas;
          let sX = 0;
          let sY = 1210 * i; // start 980 pixels down for every new page
          let sWidth = 1800;
          let sHeight = 1210;
          let dX = 0;
          let dY = 0;
          let dWidth = 1800;
          let dHeight = 1210;
          window.onePageCanvas = document.createElement("canvas");
          window.onePageCanvas.setAttribute("width", 1800);
          window.onePageCanvas.setAttribute("height", 1210);
          let ctx = window.onePageCanvas.getContext("2d");
          // details on this usage of this function:
          // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images#Slicing
          ctx.drawImage(
            srcImg,
            sX,
            sY,
            sWidth,
            sHeight,
            dX,
            dY,
            dWidth,
            dHeight
          );

          // document.body.appendChild(canvas);
          let canvasDataURL = window.onePageCanvas.toDataURL("image/png", 1.0);

          let width = window.onePageCanvas.width;
          let height = window.onePageCanvas.clientHeight;

          //! If we're on anything other than the first page,
          // add another page
          if (i > 0) {
            pdf.addPage(); //8.5" x 11" in pts (in*72)
          }
          //! now we declare that we're working on that page
          pdf.setPage(i + 1);
          //! now we add content to that page!
          pdf.addImage(
            canvasDataURL,
            "PNG",
            10,
            10,
            width * 0.42,
            height * 0.5
          );
        }

        //  pdf.save('agreement.pdf')
        //  window.open(pdf.output('bloburl'))

        pdf.output("save", "invoice.pdf"); //Try to save PDF as a file (not works on ie before 10, and some mobile devices)
        pdf.output("datauristring");
        pdf.output("dataurlnewwindow");
      });
    }, 2000);
  };

  componentDidUpdate = () => {
    console.log("lifecycle", this.props?.invoice);
    if (this.state.id === "" || this.state.id !== this.props.invoice?._id)
      this.setState({
        id: this.props.invoice?._id,
        transporterName: this.props.invoice?.transporter?.name,
        invoiceId: this.props.invoice?.invoiceId,
        date: moment(this.props.invoice?.createdAt).format("DD-MM-YYYY"),
        gst: this.props.invoice?.gstNo,
        raiseDate: moment(this.props.invoice?.fromDate).format("DD-MM-YYYY"),
        state: this.props.invoice?.state?.stateCode,
        billCount: this.props.invoice?.bills?.length,
      });
  };

  headingPopulator = () => {
    let head = [
      {
        "Transporter Name": this.state.transporterName,
        "Supplier Tax Invoice No":
          this.state.gst + " ,dated." + this.state.raiseDate,
        "TMS Invoice No": this.state.invoiceId + " ,dated." + this.state.date,
        State: this.state.state,
        "No Of Bills": this.state.billCount,
      },
    ];
    return head;
  };

  exportToCSV = (csvData, fileName) => {
    csvData = csvData?.map(function (item) {
      for (var key in item) {
        var upper = key.toUpperCase();
        // check if it already wasn't uppercase
        if (key == "type") {
          upper = "Bill Type";
        }
        if (key == "source") {
          upper = "Source";
        }
        if (key == "destination") {
          upper = "Destination";
        }
        if (key == "lrNumber") {
          upper = "LR Number";
        }
        if (key == "vehicleNo") {
          upper = "Vehicle No";
        }
        if (key == "pfNo") {
          upper = "Pf No./Indent Id";
        }
        if (key == "createdAt") {
          upper = "Created At";
        }
        if (key == "freightAmount") {
          upper = "Freight Amount";
        }
        if (key == "diff") {
          upper = "Diff";
        }
        if (key == "asPerOracle") {
          upper = "As Per Oracle";
        }
        if (key == "loadingCharge") {
          upper = "Loading Charge";
        }
        if (key == "UnLoadingCharges") {
          upper = "UnLoading Charge";
        }
        if (key == "damageCharges") {
          upper = "Damage Charges";
        }
        if (key == "revisedDamageCharges") {
          upper = "Revised Damage Charges";
        }
        if (key == "shortageCharges") {
          upper = "Shortage Charges";
        }
        if (key == "sourceDetentionCharges") {
          upper = "LR Number";
        }
        if (key == "destinationDetentionCharges") {
          upper = "Destination Detention Charges";
        }
        if (key == "lateDeliveryCharges") {
          upper = "Late Delivery Charges";
        }
        if (key == "multipointPickupCharges") {
          upper = "Multipoint Pick Charges";
        }
        if (key == "multipointDropCharges") {
          upper = "Multipoint Drop Charges";
        }
        if (key == "revisedOtherDeductionsCharges") {
          upper = "Other Add/Ded";
        }
        if (key == "cancellationUponArrivalByCompanyCharges") {
          upper = "Cancellation Upon Arrival";
        }
        if (key == "indentRejectionCharges") {
          upper = "Indent Rejection Charges";
        }
        if (key == "netCharges") {
          upper = "Net Charges";
        }
        if (key == "netDeduction") {
          upper = "Net Deductions";
        }
        if (key == "total") {
          upper = "Total";
        }
        if (upper !== key) {
          item[upper] = item[key];
          delete item[key];
        }
      }
      return item;
    });
    console.log(csvData, "data log test");
    let headingData = this.headingPopulator();
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const ws1 = XLSX.utils.json_to_sheet(headingData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, "Invoice Details");
    XLSX.utils.book_append_sheet(wb, ws, "Invoice Calculations");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  typeConvert = (type) => {
    // console.log(type);
    switch (type) {
      case "unknown":
        return "Unknown";
      case "indent_rejection":
        return "Indent Rejection";

      case "vehicle_non_placement":
        return "Vehicle Non-Placement";
      case "canacelation_on_arrival":
        return "Cancellation Upon Arrival";
      case "freight":
        return "Freight Cost";
      case "new_by_old":
        return "New By Old";

      default:
        break;
    }
  };
  lrNo = (shipMent) => {
    let lr = shipMent?.map((s) => {
      return s.lrNo;
    });
    return lr?.join(",");
  };
  shipId = (shipMent) => {
    let shipId = shipMent?.map((s) => {
      return s.shipmentId;
    });
    return shipId?.join(",");
  };
  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    var invoiceHeaders = [
      {
        label: <b>{userLang?.invoice?.BILL_TYPE}</b>,
        field: "type",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.common?.SOURCE}</b>,
        field: "source",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.common?.DESTINATION} </b>,
        field: "destination",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.LR_NUMBER}</b>,
        field: "lrNumber",
        sort: "disabled",
        width: 220,
      },

      {
        label: <b>{userLang?.controlTower?.VEHICLE_NO}</b>,
        field: "vehicleNo",
        sort: "disabled",
        width: 220,
      },
      {
        label: (
          <b>
            {userLang?.invoice?.PF_NO}/{userLang?.planning?.INDENT_ID}
          </b>
        ),
        field: "pfNo",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.CREATED_AT}</b>,
        field: "createdAt",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.FREIGHT_AMOUNT}</b>,
        field: "freightAmount",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.LOADING_CHARGES}</b>,
        field: "loadingCharge",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.UNLOADING_CHARGES}</b>,
        field: "UnLoadingCharges",
        sort: "disabled",
        width: 320,
      },
      {
        label: <b>{userLang?.invoice?.DAMAGE_CHARGES}</b>,
        field: "damageCharges",
        sort: "disabled",
        width: 320,
      },
      {
        label: <b>{userLang?.invoice?.REVISED_DAMAGE_CHARGES}</b>,
        field: "revisedDamageCharges",
        sort: "disabled",
        width: 320,
      },
      {
        label: <b>{userLang?.invoice?.SHORTAGE_CHARGES}</b>,
        field: "shortageCharges",
        sort: "disabled",
        width: 320,
      },
      {
        label: <b>{userLang?.invoice?.SOURCE_DETENTION_CHARGES}</b>,
        field: "sourceDetentionCharges",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.DESTINATION_DETENTION_CHARGES}</b>,
        field: "destinationDetentionCharges",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.LATE_DELIVERY_CHARGES}</b>,
        field: "lateDeliveryCharges",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.MULTI_POINT_PICK_CHARGES}</b>,
        field: "multipointPickupCharges",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.MULT_POINT_DROP_CHARGES}</b>,
        field: "multipointDropCharges",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.OTHER_ADD_DED}</b>,
        field: "revisedOtherDeductionsCharges",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.CANCELLATION_UPON_ARRIVAL} </b>,
        field: "cancellationUponArrivalByCompanyCharges",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.INDENT_REJECTION_CAHRGES} </b>,
        field: "indentRejectionCharges",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.NET_CHARGES} </b>,
        field: "netCharges",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.NET_DEDUCTIONS} </b>,
        field: "netDeduction",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.dashboard?.TOTAL}</b>,
        field: "total",
        sort: "disabled",
        width: 220,
      },
    ];
    //console.log("////////////////////////", this.props.invoice?.bills);
    // console.log("-helo--", this.props.invoice.bills);
    let cal = this.props.invoice?.calculations;
    let transporter = this.props.invoice?.transporter?.name;
    const role =
      JSON.parse(localStorage.getItem("authUser"))?.data?.role?.label || "";
    if (role != "transporter") {
      if (invoiceHeaders.length == 23 && invoiceHeaders.length < 26) {
        invoiceHeaders.splice(
          7,
          0,

          {
            label: <b>{userLang?.invoice?.AS_PER_ORACLE}</b>,
            field: "asPerOracle",
            sort: "disabled",
            width: 220,
          },
          {
            label: <b>{userLang?.invoice?.DIFF}</b>,
            field: "diff",
            sort: "disabled",
            width: 220,
          }
        );
      }
    }
    let final = {
      type: "Total",
      source: "",
      destination: "",
      lrNumber: "",
      vehicleNo: "",
      pfNo: "",
      createdAt: "",
      freightAmount:
        this.props.invoice?.calculations?.revisedFreightCostCharges || 0,
      // billNumber: this.props.invoice?.billId,
      // type:e.type?e.type.split('_').join(" "):'',
      loadingCharge: this.props.invoice?.calculations?.loadingCharges || 0,
      UnLoadingCharges: this.props.invoice?.calculations?.unloadingCharges || 0,
      damageCharges: this.props.invoice?.calculations?.damageCharges
        ? -this.props.invoice?.calculations.damageCharges
        : 0,
      revisedDamageCharges: this.props.invoice?.calculations
        ?.revisedDamageCharges
        ? -this.props.invoice?.calculations.revisedDamageCharges
        : 0,
      shortageCharges: this.props.invoice?.calculations?.shortageCharges
        ? -this.props.invoice?.calculations.shortageCharges
        : 0,
      sourceDetentionCharges: this.props.invoice?.calculations
        ?.sourceDetentionCharges
        ? this.props.invoice?.calculations.sourceDetentionCharges
        : 0,
      destinationDetentionCharges: this.props.invoice?.calculations
        ?.destinationDetentionCharges
        ? this.props.invoice?.calculations.destinationDetentionCharges
        : 0,
      lateDeliveryCharges: this.props.invoice?.calculations?.lateDeliveryCharges
        ? this.props.invoice?.calculations.lateDeliveryCharges
        : 0,
      multipointPickupCharges: this.props.invoice?.calculations
        ?.multipointPickupCharges
        ? this.props.invoice?.calculations.multipointPickupCharges
        : 0,
      multipointDropCharges: this.props.invoice?.calculations
        ?.multipointDropCharges
        ? this.props.invoice?.calculations.multipointDropCharges
        : 0,
      revisedOtherDeductionsCharges: this.props.invoice?.calculations
        ?.revisedOtherDeductionsCharges
        ? this.props.invoice?.calculations.revisedOtherDeductionsCharges
        : 0,
      cancellationUponArrivalByCompanyCharges: this.props.invoice?.calculations
        ?.cancellationUponArrivalByCompanyCharges
        ? this.props.invoice?.calculations
            .cancellationUponArrivalByCompanyCharges
        : 0,
      indentRejectionCharges: this.props.invoice?.calculations
        ?.indentRejectionCharges
        ? this.props.invoice?.calculations.indentRejectionCharges
        : 0,
      netCharges: this.props.invoice?.calculations?.netCharges
        ? this.props.invoice?.calculations.netCharges
        : 0,
      netDeduction: this.props.invoice?.calculations?.netDeduction
        ? -this.props.invoice?.calculations.netDeduction
        : 0,
      // khf: 0,
      total: this.props.invoice?.calculations?.netPayble
        ? this.props.invoice?.calculations.netPayble
        : 0,
    };
    let final2 = {
      type: "Total",
      source: "",
      destination: "",
      lrNumber: "",
      vehicleNo: "",
      pfNo: "",
      createdAt: "",
      freightAmount:
        this.props.invoice?.calculations?.revisedFreightCostCharges || 0,
      asPerOracle: this.props.invoice?.calculations?.freightCharges || 0,
      diff:
        this.props.invoice?.calculations?.revisedFreightCostCharges -
          this.props.invoice?.calculations?.freightCharges || 0,
      // billNumber: this.props.invoice?.billId,
      // type:e.type?e.type.split('_').join(" "):'',
      loadingCharge: this.props.invoice?.calculations?.loadingCharges || 0,
      UnLoadingCharges: this.props.invoice?.calculations?.unloadingCharges || 0,
      damageCharges: this.props.invoice?.calculations?.damageCharges
        ? -this.props.invoice?.calculations.damageCharges
        : 0,
      revisedDamageCharges: this.props.invoice?.calculations
        ?.revisedDamageCharges
        ? -this.props.invoice?.calculations.revisedDamageCharges
        : 0,
      shortageCharges: this.props.invoice?.calculations?.shortageCharges
        ? -this.props.invoice?.calculations.shortageCharges
        : 0,
      sourceDetentionCharges: this.props.invoice?.calculations
        ?.sourceDetentionCharges
        ? this.props.invoice?.calculations.sourceDetentionCharges
        : 0,
      destinationDetentionCharges: this.props.invoice?.calculations
        ?.destinationDetentionCharges
        ? this.props.invoice?.calculations.destinationDetentionCharges
        : 0,
      lateDeliveryCharges: this.props.invoice?.calculations?.lateDeliveryCharges
        ? this.props.invoice?.calculations.lateDeliveryCharges
        : 0,
      multipointPickupCharges: this.props.invoice?.calculations
        ?.multipointPickupCharges
        ? this.props.invoice?.calculations.multipointPickupCharges
        : 0,
      multipointDropCharges: this.props.invoice?.calculations
        ?.multipointDropCharges
        ? this.props.invoice?.calculations.multipointDropCharges
        : 0,
      revisedOtherDeductionsCharges: this.props.invoice?.calculations
        ?.revisedOtherDeductionsCharges
        ? this.props.invoice?.calculations.revisedOtherDeductionsCharges
        : 0,
      cancellationUponArrivalByCompanyCharges: this.props.invoice?.calculations
        ?.cancellationUponArrivalByCompanyCharges
        ? this.props.invoice?.calculations
            .cancellationUponArrivalByCompanyCharges
        : 0,
      indentRejectionCharges: this.props.invoice?.calculations
        ?.indentRejectionCharges
        ? this.props.invoice?.calculations.indentRejectionCharges
        : 0,
      netCharges: this.props.invoice?.calculations?.netCharges
        ? this.props.invoice?.calculations.netCharges
        : 0,
      netDeduction: this.props.invoice?.calculations?.netDeduction
        ? -this.props.invoice?.calculations.netDeduction
        : 0,
      // khf: 0,
      total: this.props.invoice?.calculations?.netPayble
        ? this.props.invoice.calculations.netPayble
        : 0,
    };

    let data = {
      columns: invoiceHeaders,
      rows: this.props.invoice?.bills
        ? this.props.invoice?.bills.map((e) => {
            let obj = {};
            if (role == "transporter") {
              obj = {
                type: this.typeConvert(e.type),
                source: e.trip?.source?.label,
                destination: e.trip?.destination?.label,
                lrNumber:
                  e.type == "freight" && e.shipments !== undefined
                    ? this.lrNo(e.shipments)
                    : "",
                vehicleNo: e.type == "freight" ? e.vehicle?.regNo : "",
                pfNo:
                  e.type == "freight"
                    ? this.shipId(e.shipments)
                    : e.type == "indent_rejection"
                    ? e.indentId
                    : "",
                createdAt: e.createdAt
                  ? moment(new Date(e.createdAt)).format("DD-MMM-YYYY")
                  : "",
                freightAmount: e.calculations?.revisedFreightCostCharges,
                billNumber: e.billId,
                // type:e.type?e.type.split('_').join(" "):'',
                loadingCharge: e.calculations?.loadingCharges || 0,
                UnLoadingCharges: e.calculations?.unloadingCharges || 0,
                damageCharges: e.calculations?.damageCharges
                  ? -e.calculations.damageCharges
                  : 0,
                revisedDamageCharges: e.calculations?.revisedDamageCharges
                  ? -e.calculations.revisedDamageCharges
                  : 0,
                shortageCharges: e.calculations?.shortageCharges
                  ? -e.calculations.shortageCharges
                  : 0,
                sourceDetentionCharges: e.calculations?.sourceDetentionCharges
                  ? e.calculations.sourceDetentionCharges
                  : 0,
                destinationDetentionCharges: e.calculations
                  ?.destinationDetentionCharges
                  ? e.calculations.destinationDetentionCharges
                  : 0,
                lateDeliveryCharges: e.calculations?.lateDeliveryCharges
                  ? e.calculations.lateDeliveryCharges
                  : 0,
                multipointPickupCharges: e.calculations?.multipointPickupCharges
                  ? e.calculations.multipointPickupCharges
                  : 0,
                multipointDropCharges: e.calculations?.multipointDropCharge
                  ? e.calculations.multipointDropCharge
                  : 0,
                revisedOtherDeductionsCharges: e.calculations
                  ?.revisedOtherDeductionsCharges
                  ? e.calculations.revisedOtherDeductionsCharges
                  : 0,
                cancellationUponArrivalByCompanyCharges: e.calculations
                  ?.cancellationUponArrivalByCompanyCharges
                  ? e.calculations.cancellationUponArrivalByCompanyCharges
                  : 0,
                indentRejectionCharges: e.calculations?.indentRejectionCharges
                  ? e.calculations.indentRejectionCharges
                  : 0,
                netCharges: e.calculations?.netCharges
                  ? e.calculations.netCharges
                  : 0,
                netDeduction: e.calculations?.netDeduction
                  ? -e.calculations.netDeduction
                  : 0,
                // khf: 0,
                total: e.calculations?.netPayble ? (
                  e.calculations.netPayble < 0 ? (
                    <p style={{ color: "red" }}>{e.calculations.netPayble}</p>
                  ) : (
                    <p>{e.calculations.netPayble}</p>
                  )
                ) : (
                  0
                ),
              };
            } else {
              obj = {
                type: this.typeConvert(e.type),
                source: e.trip?.source?.label,
                destination: e.trip?.destination?.label,
                lrNumber:
                  e.type == "freight" && e.shipments
                    ? this.lrNo(e.shipments)
                    : "",
                vehicleNo: e.type == "freight" ? e.vehicle?.regNo : "",
                pfNo:
                  e.type == "freight" && e.shipments
                    ? this.shipId(e.shipments)
                    : e.type == "indent_rejection"
                    ? e.indentId
                    : "",
                createdAt: e.createdAt
                  ? moment(new Date(e.createdAt)).format("DD-MMM-YYYY")
                  : "",
                freightAmount: e.calculations?.revisedFreightCostCharges,
                // billNumber: e.billId,
                asPerOracle: e.calculations?.freightCharges,
                diff:
                  e.calculations.revisedFreightCostCharges -
                  e.calculations?.freightCharges,
                // type:e.type?e.type.split('_').join(" "):'',
                loadingCharge: e.calculations?.loadingCharges || 0,
                UnLoadingCharges: e.calculations?.unloadingCharges || 0,
                damageCharges: e.calculations?.damageCharges
                  ? -e.calculations.damageCharges
                  : 0,
                revisedDamageCharges: e.calculations?.revisedDamageCharges
                  ? -e.calculations.revisedDamageCharges
                  : 0,
                shortageCharges: e.calculations?.shortageCharges
                  ? -e.calculations.shortageCharges
                  : 0,
                sourceDetentionCharges: e.calculations?.sourceDetentionCharges
                  ? e.calculations.sourceDetentionCharges
                  : 0,
                destinationDetentionCharges: e.calculations
                  ?.destinationDetentionCharges
                  ? e.calculations.destinationDetentionCharges
                  : 0,
                lateDeliveryCharges: e.calculations?.lateDeliveryCharges
                  ? e.calculations.lateDeliveryCharges
                  : 0,
                multipointPickupCharges: e.calculations?.multipointPickupCharges
                  ? e.calculations.multipointPickupCharges
                  : 0,
                multipointDropCharges: e.calculations?.multipointDropCharge
                  ? e.calculations.multipointDropCharge
                  : 0,
                revisedOtherDeductionsCharges: e.calculations
                  ?.revisedOtherDeductionsCharges
                  ? e.calculations.revisedOtherDeductionsCharges
                  : 0,
                cancellationUponArrivalByCompanyCharges: e.calculations
                  ?.cancellationUponArrivalByCompanyCharges
                  ? e.calculations.cancellationUponArrivalByCompanyCharges
                  : 0,
                indentRejectionCharges: e.calculations?.indentRejectionCharges
                  ? e.calculations.indentRejectionCharges
                  : 0,
                netCharges: e.calculations?.netCharges
                  ? e.calculations.netCharges
                  : 0,
                netDeduction: e.calculations?.netDeduction
                  ? -e.calculations.netDeduction
                  : 0,
                // khf: 0,
                total: e.calculations?.netPayble ? (
                  e.calculations.netPayble < 0 ? (
                    <p style={{ color: "red" }}>{e.calculations.netPayble}</p>
                  ) : (
                    <p>{e.calculations.netPayble}</p>
                  )
                ) : (
                  0
                ),
              };
            }
            return obj;
          })
        : [],
      finalRow: role == "transporter" ? final : final2,
      // finalRow:{
      //   locationFrom: '',
      //   locationTo: '',
      //   lrNumber: '',
      //   billNumber: '',
      //   qty: '',
      //   pfNumber: "",
      //   freightAmount: cal?.freight?cal.freight:40920,
      //   asPerOracle: 41920,
      //   diff: '('+1000+')',
      //   loadingCharge:  cal?.loadingCharge?cal.loadingCharge:'---',
      //   UnLoadingCharges: cal?.UnLoadingCharges?cal.UnLoadingCharges:'---',
      //   lateDeliveryRecovery: cal?.lateDeliveryRecovery?cal.lateDeliveryRecovery:'---',
      //   others: cal?.otherCharges?cal.otherCharges:'---',
      //   deduction: cal?.otherDeductionCharges?cal.otherDeductionCharges:'---',
      //   khf: 0,
      //   total:  cal?.netPayble?cal.netPayble:'---',
      // },
      titleHeader: transporter,
      page: "invoice",
      invoice: this.props.invoice,
    };

    console.log("DATA//", data);

    const customStylesTable = {
      title: (style) => {
        return {
          ...style,
          fontFamily: "Poppins",
          color: "#505d69",
          fontWeight: "bold",
        };
      },
      table: (style) => {
        return {
          ...style,
          textAlign: "center",
          fontFamily: "Poppins",
          borderCollapse: "collapse",
          border: "3px solid #ddd",
          width: "100%",
          marginBottom: "1rem",
          display: "block",
          overFlow: "scroll",
        };
      },
      td: (style) => {
        return {
          ...style,
          padding: "1px",
          color: "#505d69",
          fontSize: "0.61rem",
          textTransform: "capitalize",
        };
      },
      tr: (style) => {
        return { ...style, border: "1px solid #ddd" };
      },
      th: (style) => {
        return {
          ...style,
          border: "1px solid #ddd",
          padding: "2px",
          paddingTop: "6px",
          paddingBottom: "6px",
          textAlign: "center",
          backgroundColor: "#505d69",
          color: "white",
          fontSize: "0.61rem",
        };
      },
      tHeader: (style) => {
        return {
          ...style,
          border: "1px solid #ddd",
          padding: "2px",
          paddingTop: "6px",
          paddingBottom: "6px",
          textAlign: "left",
          color: "black",
          fontSize: "12px",
          width: "1100%",
          marginBottom: "0px",
        };
      },
    };
    return (
      <React.Fragment>
        <div>
          {getLocalStorage()}
          <MDBContainer>
            <MDBModal
              isOpen={this.props.isOpen}
              toggle={this.props.closePdf}
              size={"lg"}
              id="invoice-pdf-modal"
            >
              <MDBModalHeader toggle={this.props.closePdf}></MDBModalHeader>
              <MDBModalBody>
                <div>
                  <Col lg="12" style={{ textAlign: "right" }}>
                    <MDBBtn
                      onClick={() => {
                        this.pdfDownload();
                      }}
                      color="primary"
                      className="mr-1"
                    >
                      {userLang?.common?.DOWNLOAD}
                    </MDBBtn>
                    {/* 
                    <ReactHTMLTableToExcel 
                    id="test-table-xls-button"
                    className="download-table-xls-button btn-primary excl"
                    table="table-to-xls"
                    filename="invoice"
                    sheet="invoice"
                    buttonText="Export"
                    /> */}
                    <MDBBtn
                      color="primary"
                      onClick={(e) => {
                        let rows = [...data.rows];
                        let res = this.props.invoice?.bills.map((e, i) => {
                          rows[i].total = e.calculations?.netPayble;
                          return;
                        });
                        this.exportToCSV(
                          [...rows, data.finalRow],
                          "TopSheet_Invoice_Format"
                        );
                      }}
                    >
                      {userLang?.invoice?.EXPORT}
                    </MDBBtn>
                  </Col>
                  <div id="printinvoice">
                    <table className="pdfTable" style={{ marginLeft: "1.5%" }}>
                      <thead>
                        <tr>
                          <th></th>
                        </tr>
                      </thead>
                      <tfoot>
                        <tr>
                          <td></td>
                        </tr>
                      </tfoot>
                      <tbody>
                        <tr>
                          <td>
                            <DynamicTable
                              styles={customStylesTable}
                              title={date}
                              data={data}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </MDBModalBody>
            </MDBModal>
          </MDBContainer>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    invoice: state.Billing?.billing?.invoice,
  };
};

export default connect(mapStateToProps, {})(InvoicePdf);
