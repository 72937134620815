import React from "react";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  NonVehiclePlacementChargesHeaders = {
    columns: [
      {
        label: userLang?.common?.VEHICLE_TYPE,
        field: "vehicleType",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.invoice?.CHARGES,
        field: "charges",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.common?.START_DATE,
        field: "startDate",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.common?.END_DATE,
        field: "endDate",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.common?.ACTIONS,
        field: "actions",
        sort: "asc",
        width: 1,
      },
    ],
  };
  MultiPointPickChargesHeaders = {
    columns: [
      {
        label: userLang?.common?.SOURCE,
        field: "source",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.common?.DESTINATION,
        field: "destination",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.invoice?.CHARGES,
        field: "charges",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.common?.START_DATE,
        field: "startDate",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.common?.END_DATE,
        field: "endDate",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.common?.ACTIONS,
        field: "actions",
        sort: "asc",
        width: 1,
      },
    ],
  };
  DetentionChargesAtSourceHeaders = {
    columns: [
      {
        label: userLang?.common?.VEHICLE_TYPE,
        field: "vehicleType",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.invoice?.DAY,
        field: "day",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.invoice?.CHARGES,
        field: "charges",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.common?.START_DATE,
        field: "startDate",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.common?.END_DATE,
        field: "endDate",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.invoice?.TIME_PERIOD,
        field: "timePeriod",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.common?.ACTIONS,
        field: "actions",
        sort: "asc",
        width: 1,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var NonVehiclePlacementChargesHeaders = {
  columns: [
    {
      label: userLang?.common?.VEHICLE_TYPE,
      field: "vehicleType",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.invoice?.CHARGES,
      field: "charges",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.common?.START_DATE,
      field: "startDate",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.common?.END_DATE,
      field: "endDate",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.common?.ACTIONS,
      field: "actions",
      sort: "asc",
      width: 1,
    },
  ],
};

export var MultiPointPickChargesHeaders = {
  columns: [
    {
      label: userLang?.common?.SOURCE,
      field: "source",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.common?.DESTINATION,
      field: "destination",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.invoice?.CHARGES,
      field: "charges",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.common?.START_DATE,
      field: "startDate",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.common?.END_DATE,
      field: "endDate",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.common?.ACTIONS,
      field: "actions",
      sort: "asc",
      width: 1,
    },
  ],
};

export var  DetentionChargesAtSourceHeaders = {
  columns: [
    {
      label: userLang?.common?.VEHICLE_TYPE,
      field: "vehicleType",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.invoice?.DAY,
      field: "day",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.invoice?.CHARGES,
      field: "charges",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.common?.START_DATE,
      field: "startDate",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.common?.END_DATE,
      field: "endDate",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.invoice?.TIME_PERIOD,
      field: "timePeriod",
      sort: "asc",
      width: 3,
    },
    {
      label: userLang?.common?.ACTIONS,
      field: "actions",
      sort: "asc",
      width: 1,
    },
  ],
};
export const searchBar = {
  searchBarData: [
    {
      label: userLang?.common?.VEHICLE_TYPE,
      field: "vehicleType",
      type: "text",
    },
    {
      label: userLang?.invoice?.CHARGES,
      field: "charges",
      type: "text",
    },
    {
      label: userLang?.common?.START_DATE,
      field: "startDate",
      type: "text",
    },
    {
      label: userLang?.common?.END_DATE,
      field: "endDate",
      type: "text",
    },
    {
      label: userLang?.common?.ACTIONS,
      field: "actions",
      type: "text",
    },
  ],
};
