import React, { Component, useRef, useEffect } from "react";

import { connect } from "react-redux";
import { compose } from "redux";

import { Row, Col, Card } from "reactstrap";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

const modalData = {
  summary: {
    nbrOfLoadedPackages: 4,
    nbrOfUnLoadedPackages: 0,
    maxUsedVolume: 1568.0,
    maxVolume: 26400.0,
    maxUsedWeight: 20.0,
  },
  containerTypeName: "default_container_type",
  container: {
    uniquePositionKeys: [
      "8/0/0",
      "0/0/28",
      "0/7/14",
      "0/7/0",
      "8/0/14",
      "8/0/21",
      "0/7/7",
      "8/0/7",
      "0/7/21",
    ],
    spacePositions: {
      "(0,7,0)": [
        {
          l: 43,
          w: 12,
          h: 44,
        },
      ],
      "(8,0,0)": [
        {
          l: 50,
          w: 4,
          h: 44,
        },
      ],
      "(0,7,7)": [
        {
          l: 43,
          w: 12,
          h: 37,
        },
      ],
      "(8,0,7)": [
        {
          l: 50,
          w: 4,
          h: 37,
        },
      ],
      "(0,7,14)": [
        {
          l: 43,
          w: 12,
          h: 30,
        },
      ],
      "(8,0,14)": [
        {
          l: 50,
          w: 4,
          h: 30,
        },
      ],
      "(0,7,21)": [
        {
          l: 43,
          w: 12,
          h: 23,
        },
      ],
      "(8,0,21)": [
        {
          l: 50,
          w: 4,
          h: 23,
        },
      ],
      "(0,0,28)": [
        {
          l: 50,
          w: 12,
          h: 16,
        },
      ],
    },
    spaceService: {},
    index: -1,
    width: 12,
    height: 44,
    length: 50,
    maxWeight: 150.0,
    containerType: 0,
    weight: 80.0,
    itemList: [
      {
        size: 56,
        volume: 392,
        h: 7,
        origH: 7,
        x: 0,
        y: 0,
        z: 0,
        xw: 8,
        yl: 7,
        zh: 7,
        w: 8,
        l: 7,
        spinable: true,
        stackable: true,
        loadingLoc: -1,
        unLoadingLoc: -1,
        stackingGroup: 1,
        allowedContainerSet: [0],
        allowedStackingGroups: 1,
        nbrOfAllowedStackedItems: 2147483647,
        immersiveDepth: 0,
        weight: 20.0,
        stackingWeightLimit: 3.4028235e38,
        externalIndex: 0,
        loadingType: "LOAD",
        orderIndex: 0,
        index: 0,
        containerIndex: -1,
        isLoading: false,
        isRotated: false,
      },
      {
        size: 56,
        volume: 392,
        h: 7,
        origH: 7,
        x: 0,
        y: 0,
        z: 7,
        xw: 8,
        yl: 7,
        zh: 14,
        w: 8,
        l: 7,
        spinable: true,
        stackable: true,
        loadingLoc: -1,
        unLoadingLoc: -1,
        stackingGroup: 1,
        allowedContainerSet: [0],
        allowedStackingGroups: 1,
        nbrOfAllowedStackedItems: 2147483647,
        immersiveDepth: 0,
        weight: 20.0,
        stackingWeightLimit: 3.4028235e38,
        externalIndex: 0,
        loadingType: "LOAD",
        orderIndex: 0,
        index: 1,
        containerIndex: -1,
        isLoading: false,
        isRotated: false,
      },
      {
        size: 56,
        volume: 392,
        h: 7,
        origH: 7,
        x: 0,
        y: 0,
        z: 14,
        xw: 8,
        yl: 7,
        zh: 21,
        w: 8,
        l: 7,
        spinable: true,
        stackable: true,
        loadingLoc: -1,
        unLoadingLoc: -1,
        stackingGroup: 1,
        allowedContainerSet: [0],
        allowedStackingGroups: 1,
        nbrOfAllowedStackedItems: 2147483647,
        immersiveDepth: 0,
        weight: 20.0,
        stackingWeightLimit: 3.4028235e38,
        externalIndex: 0,
        loadingType: "LOAD",
        orderIndex: 0,
        index: 2,
        containerIndex: -1,
        isLoading: false,
        isRotated: false,
      },
      {
        size: 56,
        volume: 392,
        h: 7,
        origH: 7,
        x: 0,
        y: 0,
        z: 21,
        xw: 8,
        yl: 7,
        zh: 28,
        w: 8,
        l: 7,
        spinable: true,
        stackable: true,
        loadingLoc: -1,
        unLoadingLoc: -1,
        stackingGroup: 1,
        allowedContainerSet: [0],
        allowedStackingGroups: 1,
        nbrOfAllowedStackedItems: 2147483647,
        immersiveDepth: 0,
        weight: 20.0,
        stackingWeightLimit: 3.4028235e38,
        externalIndex: 0,
        loadingType: "LOAD",
        orderIndex: 0,
        index: 3,
        containerIndex: -1,
        isLoading: false,
        isRotated: false,
      },
    ],
    activePosList: [
      {
        idx: 1,
        x: 0,
        y: 7,
        z: 0,
        type: "BASIC",
      },
      {
        idx: 2,
        x: 8,
        y: 0,
        z: 0,
        type: "BASIC",
      },
      {
        idx: 4,
        x: 0,
        y: 7,
        z: 7,
        type: "BASIC",
      },
      {
        idx: 5,
        x: 8,
        y: 0,
        z: 7,
        type: "BASIC",
      },
      {
        idx: 7,
        x: 0,
        y: 7,
        z: 14,
        type: "BASIC",
      },
      {
        idx: 8,
        x: 8,
        y: 0,
        z: 14,
        type: "BASIC",
      },
      {
        idx: 10,
        x: 0,
        y: 7,
        z: 21,
        type: "BASIC",
      },
      {
        idx: 11,
        x: 8,
        y: 0,
        z: 21,
        type: "BASIC",
      },
      {
        idx: 12,
        x: 0,
        y: 0,
        z: 28,
        type: "BASIC",
      },
    ],
    xMap: {
      map: {
        0: [0, 1, 2, 3],
        8: [0, 1, 2, 3],
      },
    },
    yMap: {
      map: {
        0: [0, 1, 2, 3],
        7: [0, 1, 2, 3],
      },
    },
    zMap: {
      map: {
        0: [0],
        21: [2, 3],
        7: [0, 1],
        28: [3],
        14: [1, 2],
      },
    },
    zGraph: {
      lowerList: [
        {
          item: {
            size: 56,
            volume: 392,
            h: 7,
            origH: 7,
            x: 0,
            y: 0,
            z: 0,
            xw: 8,
            yl: 7,
            zh: 7,
            w: 8,
            l: 7,
            spinable: true,
            stackable: true,
            loadingLoc: -1,
            unLoadingLoc: -1,
            stackingGroup: 1,
            allowedContainerSet: [0],
            allowedStackingGroups: 1,
            nbrOfAllowedStackedItems: 2147483647,
            immersiveDepth: 0,
            weight: 20.0,
            stackingWeightLimit: 3.4028235e38,
            externalIndex: 0,
            loadingType: "LOAD",
            orderIndex: 0,
            index: 0,
            containerIndex: -1,
            isLoading: false,
            isRotated: false,
          },
          lowerItemList: [],
          cutRatioList: [],
          itemRatioArr: [[], []],
        },
        {
          item: {
            size: 56,
            volume: 392,
            h: 7,
            origH: 7,
            x: 0,
            y: 0,
            z: 7,
            xw: 8,
            yl: 7,
            zh: 14,
            w: 8,
            l: 7,
            spinable: true,
            stackable: true,
            loadingLoc: -1,
            unLoadingLoc: -1,
            stackingGroup: 1,
            allowedContainerSet: [0],
            allowedStackingGroups: 1,
            nbrOfAllowedStackedItems: 2147483647,
            immersiveDepth: 0,
            weight: 20.0,
            stackingWeightLimit: 3.4028235e38,
            externalIndex: 0,
            loadingType: "LOAD",
            orderIndex: 0,
            index: 1,
            containerIndex: -1,
            isLoading: false,
            isRotated: false,
          },
          lowerItemList: [
            {
              size: 56,
              volume: 392,
              h: 7,
              origH: 7,
              x: 0,
              y: 0,
              z: 0,
              xw: 8,
              yl: 7,
              zh: 7,
              w: 8,
              l: 7,
              spinable: true,
              stackable: true,
              loadingLoc: -1,
              unLoadingLoc: -1,
              stackingGroup: 1,
              allowedContainerSet: [0],
              allowedStackingGroups: 1,
              nbrOfAllowedStackedItems: 2147483647,
              immersiveDepth: 0,
              weight: 20.0,
              stackingWeightLimit: 3.4028235e38,
              externalIndex: 0,
              loadingType: "LOAD",
              orderIndex: 0,
              index: 0,
              containerIndex: -1,
              isLoading: false,
              isRotated: false,
            },
          ],
          cutRatioList: [1.0],
          itemRatioArr: [
            [
              {
                size: 56,
                volume: 392,
                h: 7,
                origH: 7,
                x: 0,
                y: 0,
                z: 0,
                xw: 8,
                yl: 7,
                zh: 7,
                w: 8,
                l: 7,
                spinable: true,
                stackable: true,
                loadingLoc: -1,
                unLoadingLoc: -1,
                stackingGroup: 1,
                allowedContainerSet: [0],
                allowedStackingGroups: 1,
                nbrOfAllowedStackedItems: 2147483647,
                immersiveDepth: 0,
                weight: 20.0,
                stackingWeightLimit: 3.4028235e38,
                externalIndex: 0,
                loadingType: "LOAD",
                orderIndex: 0,
                index: 0,
                containerIndex: -1,
                isLoading: false,
                isRotated: false,
              },
            ],
            [1.0],
          ],
        },
        {
          item: {
            size: 56,
            volume: 392,
            h: 7,
            origH: 7,
            x: 0,
            y: 0,
            z: 14,
            xw: 8,
            yl: 7,
            zh: 21,
            w: 8,
            l: 7,
            spinable: true,
            stackable: true,
            loadingLoc: -1,
            unLoadingLoc: -1,
            stackingGroup: 1,
            allowedContainerSet: [0],
            allowedStackingGroups: 1,
            nbrOfAllowedStackedItems: 2147483647,
            immersiveDepth: 0,
            weight: 20.0,
            stackingWeightLimit: 3.4028235e38,
            externalIndex: 0,
            loadingType: "LOAD",
            orderIndex: 0,
            index: 2,
            containerIndex: -1,
            isLoading: false,
            isRotated: false,
          },
          lowerItemList: [
            {
              size: 56,
              volume: 392,
              h: 7,
              origH: 7,
              x: 0,
              y: 0,
              z: 7,
              xw: 8,
              yl: 7,
              zh: 14,
              w: 8,
              l: 7,
              spinable: true,
              stackable: true,
              loadingLoc: -1,
              unLoadingLoc: -1,
              stackingGroup: 1,
              allowedContainerSet: [0],
              allowedStackingGroups: 1,
              nbrOfAllowedStackedItems: 2147483647,
              immersiveDepth: 0,
              weight: 20.0,
              stackingWeightLimit: 3.4028235e38,
              externalIndex: 0,
              loadingType: "LOAD",
              orderIndex: 0,
              index: 1,
              containerIndex: -1,
              isLoading: false,
              isRotated: false,
            },
          ],
          cutRatioList: [1.0],
          itemRatioArr: [
            [
              {
                size: 56,
                volume: 392,
                h: 7,
                origH: 7,
                x: 0,
                y: 0,
                z: 7,
                xw: 8,
                yl: 7,
                zh: 14,
                w: 8,
                l: 7,
                spinable: true,
                stackable: true,
                loadingLoc: -1,
                unLoadingLoc: -1,
                stackingGroup: 1,
                allowedContainerSet: [0],
                allowedStackingGroups: 1,
                nbrOfAllowedStackedItems: 2147483647,
                immersiveDepth: 0,
                weight: 20.0,
                stackingWeightLimit: 3.4028235e38,
                externalIndex: 0,
                loadingType: "LOAD",
                orderIndex: 0,
                index: 1,
                containerIndex: -1,
                isLoading: false,
                isRotated: false,
              },
            ],
            [1.0],
          ],
        },
        {
          item: {
            size: 56,
            volume: 392,
            h: 7,
            origH: 7,
            x: 0,
            y: 0,
            z: 21,
            xw: 8,
            yl: 7,
            zh: 28,
            w: 8,
            l: 7,
            spinable: true,
            stackable: true,
            loadingLoc: -1,
            unLoadingLoc: -1,
            stackingGroup: 1,
            allowedContainerSet: [0],
            allowedStackingGroups: 1,
            nbrOfAllowedStackedItems: 2147483647,
            immersiveDepth: 0,
            weight: 20.0,
            stackingWeightLimit: 3.4028235e38,
            externalIndex: 0,
            loadingType: "LOAD",
            orderIndex: 0,
            index: 3,
            containerIndex: -1,
            isLoading: false,
            isRotated: false,
          },
          lowerItemList: [
            {
              size: 56,
              volume: 392,
              h: 7,
              origH: 7,
              x: 0,
              y: 0,
              z: 14,
              xw: 8,
              yl: 7,
              zh: 21,
              w: 8,
              l: 7,
              spinable: true,
              stackable: true,
              loadingLoc: -1,
              unLoadingLoc: -1,
              stackingGroup: 1,
              allowedContainerSet: [0],
              allowedStackingGroups: 1,
              nbrOfAllowedStackedItems: 2147483647,
              immersiveDepth: 0,
              weight: 20.0,
              stackingWeightLimit: 3.4028235e38,
              externalIndex: 0,
              loadingType: "LOAD",
              orderIndex: 0,
              index: 2,
              containerIndex: -1,
              isLoading: false,
              isRotated: false,
            },
          ],
          cutRatioList: [1.0],
          itemRatioArr: [
            [
              {
                size: 56,
                volume: 392,
                h: 7,
                origH: 7,
                x: 0,
                y: 0,
                z: 14,
                xw: 8,
                yl: 7,
                zh: 21,
                w: 8,
                l: 7,
                spinable: true,
                stackable: true,
                loadingLoc: -1,
                unLoadingLoc: -1,
                stackingGroup: 1,
                allowedContainerSet: [0],
                allowedStackingGroups: 1,
                nbrOfAllowedStackedItems: 2147483647,
                immersiveDepth: 0,
                weight: 20.0,
                stackingWeightLimit: 3.4028235e38,
                externalIndex: 0,
                loadingType: "LOAD",
                orderIndex: 0,
                index: 2,
                containerIndex: -1,
                isLoading: false,
                isRotated: false,
              },
            ],
            [1.0],
          ],
        },
      ],
      upperList: [
        [
          {
            size: 56,
            volume: 392,
            h: 7,
            origH: 7,
            x: 0,
            y: 0,
            z: 7,
            xw: 8,
            yl: 7,
            zh: 14,
            w: 8,
            l: 7,
            spinable: true,
            stackable: true,
            loadingLoc: -1,
            unLoadingLoc: -1,
            stackingGroup: 1,
            allowedContainerSet: [0],
            allowedStackingGroups: 1,
            nbrOfAllowedStackedItems: 2147483647,
            immersiveDepth: 0,
            weight: 20.0,
            stackingWeightLimit: 3.4028235e38,
            externalIndex: 0,
            loadingType: "LOAD",
            orderIndex: 0,
            index: 1,
            containerIndex: -1,
            isLoading: false,
            isRotated: false,
          },
        ],
        [
          {
            size: 56,
            volume: 392,
            h: 7,
            origH: 7,
            x: 0,
            y: 0,
            z: 14,
            xw: 8,
            yl: 7,
            zh: 21,
            w: 8,
            l: 7,
            spinable: true,
            stackable: true,
            loadingLoc: -1,
            unLoadingLoc: -1,
            stackingGroup: 1,
            allowedContainerSet: [0],
            allowedStackingGroups: 1,
            nbrOfAllowedStackedItems: 2147483647,
            immersiveDepth: 0,
            weight: 20.0,
            stackingWeightLimit: 3.4028235e38,
            externalIndex: 0,
            loadingType: "LOAD",
            orderIndex: 0,
            index: 2,
            containerIndex: -1,
            isLoading: false,
            isRotated: false,
          },
        ],
        [
          {
            size: 56,
            volume: 392,
            h: 7,
            origH: 7,
            x: 0,
            y: 0,
            z: 21,
            xw: 8,
            yl: 7,
            zh: 28,
            w: 8,
            l: 7,
            spinable: true,
            stackable: true,
            loadingLoc: -1,
            unLoadingLoc: -1,
            stackingGroup: 1,
            allowedContainerSet: [0],
            allowedStackingGroups: 1,
            nbrOfAllowedStackedItems: 2147483647,
            immersiveDepth: 0,
            weight: 20.0,
            stackingWeightLimit: 3.4028235e38,
            externalIndex: 0,
            loadingType: "LOAD",
            orderIndex: 0,
            index: 3,
            containerIndex: -1,
            isLoading: false,
            isRotated: false,
          },
        ],
        [],
      ],
    },
    itemPositionMap: {
      "Item 0 -1 -1 (8,7,7) [0, 0, 0 ] 1": {
        idx: 0,
        x: 0,
        y: 0,
        z: 0,
        type: "ROOT",
      },
      "Item 0 -1 -1 (8,7,7) [0, 0, 7 ] 1": {
        idx: 3,
        x: 0,
        y: 0,
        z: 7,
        type: "BASIC",
      },
      "Item 0 -1 -1 (8,7,7) [0, 0, 14 ] 1": {
        idx: 6,
        x: 0,
        y: 0,
        z: 14,
        type: "BASIC",
      },
      "Item 0 -1 -1 (8,7,7) [0, 0, 21 ] 1": {
        idx: 9,
        x: 0,
        y: 0,
        z: 21,
        type: "BASIC",
      },
    },
    history: [
      {
        size: 56,
        volume: 392,
        h: 7,
        origH: 7,
        x: 0,
        y: 0,
        z: 0,
        xw: 8,
        yl: 7,
        zh: 7,
        w: 8,
        l: 7,
        spinable: true,
        stackable: true,
        loadingLoc: -1,
        unLoadingLoc: -1,
        stackingGroup: 1,
        allowedContainerSet: [0],
        allowedStackingGroups: 1,
        nbrOfAllowedStackedItems: 2147483647,
        immersiveDepth: 0,
        weight: 20.0,
        stackingWeightLimit: 3.4028235e38,
        externalIndex: 0,
        loadingType: "LOAD",
        orderIndex: 0,
        index: 0,
        containerIndex: -1,
        isLoading: false,
        isRotated: false,
      },
      {
        size: 56,
        volume: 392,
        h: 7,
        origH: 7,
        x: 0,
        y: 0,
        z: 7,
        xw: 8,
        yl: 7,
        zh: 14,
        w: 8,
        l: 7,
        spinable: true,
        stackable: true,
        loadingLoc: -1,
        unLoadingLoc: -1,
        stackingGroup: 1,
        allowedContainerSet: [0],
        allowedStackingGroups: 1,
        nbrOfAllowedStackedItems: 2147483647,
        immersiveDepth: 0,
        weight: 20.0,
        stackingWeightLimit: 3.4028235e38,
        externalIndex: 0,
        loadingType: "LOAD",
        orderIndex: 0,
        index: 1,
        containerIndex: -1,
        isLoading: false,
        isRotated: false,
      },
      {
        size: 56,
        volume: 392,
        h: 7,
        origH: 7,
        x: 0,
        y: 0,
        z: 14,
        xw: 8,
        yl: 7,
        zh: 21,
        w: 8,
        l: 7,
        spinable: true,
        stackable: true,
        loadingLoc: -1,
        unLoadingLoc: -1,
        stackingGroup: 1,
        allowedContainerSet: [0],
        allowedStackingGroups: 1,
        nbrOfAllowedStackedItems: 2147483647,
        immersiveDepth: 0,
        weight: 20.0,
        stackingWeightLimit: 3.4028235e38,
        externalIndex: 0,
        loadingType: "LOAD",
        orderIndex: 0,
        index: 2,
        containerIndex: -1,
        isLoading: false,
        isRotated: false,
      },
      {
        size: 56,
        volume: 392,
        h: 7,
        origH: 7,
        x: 0,
        y: 0,
        z: 21,
        xw: 8,
        yl: 7,
        zh: 28,
        w: 8,
        l: 7,
        spinable: true,
        stackable: true,
        loadingLoc: -1,
        unLoadingLoc: -1,
        stackingGroup: 1,
        allowedContainerSet: [0],
        allowedStackingGroups: 1,
        nbrOfAllowedStackedItems: 2147483647,
        immersiveDepth: 0,
        weight: 20.0,
        stackingWeightLimit: 3.4028235e38,
        externalIndex: 0,
        loadingType: "LOAD",
        orderIndex: 0,
        index: 3,
        containerIndex: -1,
        isLoading: false,
        isRotated: false,
      },
    ],
    bearingCapacities: {
      0: 3.4028235e38,
      1: 3.4028235e38,
      2: 3.4028235e38,
      3: 3.4028235e38,
    },
    loadBearingChecker: {},
    maxPosIdx: 13,
    parameter: {
      lifoImportance: 1.0,
      groundContactRule: "FREE",
    },
  },
  packageEventList: [
    {
      id: "",
      x: 0,
      y: 0,
      z: 0,
      w: 8,
      l: 7,
      h: 7,
      stackingGrp: 1,
      weight: 20.0,
      weightLimit: 3.4028235e38,
      isInvalid: false,
      type: "LOAD",
      usedVolumeInContainer: 392.0,
      usedWeightInContainer: 20.0,
      nbrStacksInContainer: 0,
      color: "blue",
    },
    {
      id: "",
      x: 0,
      y: 0,
      z: 7,
      w: 8,
      l: 7,
      h: 7,
      stackingGrp: 1,
      weight: 20.0,
      weightLimit: 3.4028235e38,
      isInvalid: false,
      type: "LOAD",
      usedVolumeInContainer: 392.0,
      usedWeightInContainer: 20.0,
      nbrStacksInContainer: 0,
      color: "red",
    },
    {
      id: "",
      x: 0,
      y: 0,
      z: 14,
      w: 8,
      l: 7,
      h: 7,
      stackingGrp: 1,
      weight: 20.0,
      weightLimit: 3.4028235e38,
      isInvalid: false,
      type: "LOAD",
      usedVolumeInContainer: 392.0,
      usedWeightInContainer: 20.0,
      nbrStacksInContainer: 0,
      color: "green",
    },
    {
      id: "",
      x: 0,
      y: 0,
      z: 21,
      w: 8,
      l: 7,
      h: 7,
      stackingGrp: 1,
      weight: 20.0,
      weightLimit: 3.4028235e38,
      isInvalid: false,
      type: "LOAD",
      usedVolumeInContainer: 392.0,
      usedWeightInContainer: 20.0,
      nbrStacksInContainer: 0,
      color: "pink",
    },
  ],
};

class ThreeDRender extends React.Component {
  componentDidMount = () => {
    this.sceneSetup();
    this.addCustomSceneObjects();
    this.startAnimationLoop();
    window.addEventListener("resize", this.handleWindowResize);
  };

  handleWindowResize = () => {
    const width = this.el.clientWidth;
    const height = this.el.clientHeight;

    this.renderer.setSize(width, height);
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
  };
  addCustomSceneObjects = () => {
    for (let i = 0; i < modalData.packageEventList.length; i++) {
      const geometry = new THREE.BoxGeometry(
        modalData.packageEventList[i].l,
        modalData.packageEventList[i].w,
        modalData.packageEventList[i].h
      );
      const material = new THREE.MeshPhongMaterial({
        color: modalData.packageEventList[i].color,
        emissive: 0x072534,
        side: THREE.DoubleSide,
        flatShading: true,
      });
      this.cube2 = new THREE.Mesh(geometry, material);
      this.cube2.position.set(
        modalData.packageEventList[i].x,
        modalData.packageEventList[i].y,
        modalData.packageEventList[i].z
      );
      this.scene.add(this.cube2);
    }

    //plane
    const geometry = new THREE.BoxGeometry(20, 0.01, 60);
    const material = new THREE.MeshPhongMaterial({
      color: "grey",
      opacity: 0.1,
      transparent: true,
    });
    const plane = new THREE.Mesh(geometry, material);
    plane.position.set(0, -5,0);
    this.scene.add(plane);
    const geometry1 = new THREE.BoxGeometry(0.01, 20, 60);
    const material1 = new THREE.MeshPhongMaterial({
      color: "grey",
      opacity: 0.1,
      transparent: true,
    });
    const plane1 = new THREE.Mesh(geometry1, material1);
    plane.position.set(-10, 0,0);
    this.scene.add(plane);

 
    const lights = [];
    lights[0] = new THREE.PointLight(0xffffff, 1, 0);
    lights[1] = new THREE.PointLight(0xffffff, 1, 0);
    lights[2] = new THREE.PointLight(0xffffff, 1, 0);

    lights[0].position.set(0, 200, 0);
    lights[1].position.set(100, 200, 100);
    lights[2].position.set(-100, -200, -100);

    this.scene.add(lights[0]);
    this.scene.add(lights[1]);
    this.scene.add(lights[2]);
  };
  startAnimationLoop = () => {
    // this.cube.rotation.x += 0.01;
    // this.cube.rotation.y += 0.01;

    this.renderer.render(this.scene, this.camera);
    this.requestID = window.requestAnimationFrame(this.startAnimationLoop);
  };
  sceneSetup = () => {
    // get container dimensions and use them for scene sizing
    const width = this.el.clientWidth;
    const height = this.el.clientHeight;

    this.scene = new THREE.Scene();
    // this.scene.background = new THREE.Color("#F1F5F7");
    

    this.camera = new THREE.PerspectiveCamera(
      75, // fov = field of view
      width/height, // aspect ratio
      0.1, // near plane
    1000 // far plane
    );
    this.controls = new OrbitControls(this.camera, this.el);
    // set some distance from a cube that is located at z = 0
    this.camera.position.z = 5;

    this.renderer = new THREE.WebGLRenderer();
    this.renderer.setSize(width, height);
    this.el.appendChild(this.renderer.domElement); // mount using React ref
  };
  render() {
    console.log(this.camera);
    return (
      <div
        ref={(ref) => (this.el = ref)}
        style={{
          minHeight: "500px",
          height: "500px",
        }}
      ></div>
    );
  }
}
export default ThreeDRender;
