import React, { Component } from "react";
import moment from "moment";
import { tripCountByStatus } from "../../../store/dashboard/actions";
import { connect } from "react-redux";
import BarChart from "./BarChart";
import ApexChart from "./ApexChart";
import { restrict, roleCheck } from "../../restrict";
import { MoonLoader } from "react-spinners";
import { MDBContainer, MDBRow, MDBCard, MDBCol } from "mdbreact";
import TransportHeader from "./TransportHeader";
import Card from "@mui/material/Card";
import "./transporter-dashboard.css";
import { fetchHops } from "../../../store/hop/actions";
import { operationsDashboardTripsAndIndent } from "../../../store/dashboard/actions";
import { transporterHistoryTripsByStatus } from "../../../store/dashboard/actions";

import { Row, Col } from "reactstrap";
import DashboardHeader from "../CommonHeader/DashboardHeader";
import Grid from "@mui/material/Grid";
import VehiclePlacementChart from "./VehiclePlacementChart";
import IndentAcceptanceChart from "./IndentAcceptanceChart";
import ShipmentDamageChart from "./ShipmentDamageChart";
import ShipmentShortageChart from "./ShipmentShortageChart";
import { WaveLoader } from "../../../components/Common/Loader/LoaderIndex";

let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
var offset = 0;
var darkTheme;
class TransporterDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // loading: true,
      dataLoading: false,
      transpoter: [],
      selectedTranspoterValues: [],
      selectedHopValues: [],
      transporterValues: [],
      hopValues: [],
      trips: {},
      indents: {},
      transportApiData: "",
      hops: [],
      datevalues: [null, null],
      dates: [],
      offset: 0,
      limit: 10,
      tripKeydata: "",
      FromDate: "",
      ToDate: "",
      transporters: "",
    };
    (async () => {
      let redirect = await roleCheck("transporter");
      if (redirect === true) {
        this.props.history.push("/assigned-indent");
      }
    })();
  }
  componentDidMount = () => {
    this.getAllTripsAndIndentData();
    this.getAllLocationsData();
    this.setState({dataLoading:true})
    this.props.tripCountByStatus(()=>{
      this.setState({dataLoading:false})
    });
  };
// here removing all filters
  onReset = () => {
    this.setState(
      {
        selectedTranspoterValues: [],
        selectedHopValues: [],
        transporterValues: [],
        hopValues: [],
        datevalues: [null, null],
      },
      () => {
        this.getAllTripsAndIndentData();
      }
    );
  };
 
  onChangeHop = (selectedHopValues) => {
    if (selectedHopValues?.length <= 3) {
      this.setState({ selectedHopValues }, () => {
        if (this.state.selectedHopValues?.length <= 3) {
          var hopValues =
            this.state.selectedHopValues === null
              ? []
              : this.state.selectedHopValues?.map((ele) => {
                  return ele?.value;
                });
          this.setState({ hopValues }, () => {
            this.getAllTripsAndIndentData();
          });
        } else if (this.state.selectedHopValues?.length === undefined) {
          this.setState(
            {
              hopValues: [],
              selectedHopValues: [],
            },
            () => {
              this.getAllTripsAndIndentData();
            }
          );
        }
      });
    } else if (selectedHopValues?.length === undefined) {
      this.setState(
        {
          hopValues: [],
          selectedHopValues: [],
        },
        () => {
          this.getAllTripsAndIndentData();
        }
      );
    }
  };
  onChangeDatePicker = (datevalues) => {
    this.setState({ datevalues }, () => {
      // if (this.state.dates.length >= 0) {
      this.getAllTripsAndIndentData();
      // }
    });
  };
// calling transpoter dashboard api
  getAllTripsAndIndentData = () => {
    this.setState({ dataLoading: true });
    this.props.transporterHistoryTripsByStatus(
      this.state.hopValues,
      this.state.datevalues[0] === null
        ? ""
        : moment(this.state.datevalues[0]).format("MM-DD-YYYY"),
      this.state.datevalues[1] === null
        ? ""
        : moment(this.state.datevalues[1]).format("MM-DD-YYYY"),
      (res) => {
        this.setState({ transportApiData: res?.data });
        this.setState({dataLoading:false},()=>{})
      }
    );
  };
// calling locations api
  getAllLocationsData = () => {
    let filtersHopsData ={
      offset:0,
      limit:10,
      q:""
    }
    this.fetchHops(filtersHopsData)
  };
   // calling locations api
   fetchHops = (filtersHopsData)=>{
    this.props.fetchHops({ offset:filtersHopsData.offset, 
      limit:filtersHopsData.limit ,
      label:filtersHopsData.q}, (res) => {
      let hops = [];
      if (res.data?.docs?.length > 0) {
        res.data.docs.forEach((ele) => {
          hops.push({
            label: ele?.label ? ele?.label : "",
            value: ele?._id ? ele?._id : "",
          });
        });
      }
      this.setState(
        {
          hops: hops,
        },
        () => { },
        ()=>{}
      );
    });
  }
  // geting location name by filters
  filtersHops=(e)=>{
    if (e?.length > 0) {
      let filtersHopsData ={
        offset:0,
        limit:10,
        q:e
      }
      this.fetchHops(filtersHopsData)
    
    }
    if(e.length === 0){
      let filtersHopsData ={
        offset:0,
        limit:10,
        q:""
      }
      this.fetchHops(filtersHopsData)
    }
  }
  refineData = () => {
    this.setState({ loading: false });
  };

  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    const localStorageData = JSON.parse(
      localStorage.getItem("transporterDashboardData")
    );
    const transportData = JSON.parse(localStorage.getItem("transportHistory"));
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkTheme = mode === "dark__mode" ? true : false;

    const trips = this.props?.transporterTrips;
    const assignData = navigator.onLine
      ? this.props.countByStatus
      : localStorageData;
    const assignTransporterData = navigator.onLine
      ? this.props?.transportHistory
      : transportData?.data;

    let laneWidget = [];
    let apexChart = [];
    let transporterName = [];

    // const { loading } = this.state;
    // if (loading) {
    //   return (
    //     <div className="page-content">
    //       <Col xs={12} className="loader">
    //         <MoonLoader size={45} color={"#123abc"} />
    //       </Col>
    //     </div>
    //   );
    // }

    if (
      assignTransporterData?.businessBreakup &&
      assignTransporterData?.businessBreakup?.length > 0 &&
      (assignTransporterData !== undefined || assignTransporterData !== null)
    ) {
      for (const key of assignTransporterData?.businessBreakup) {
        if (key.laneLot !== null) {
          let obj = {
            key: key?.laneLot,
            value: key?.total,
          };
          laneWidget.push(obj);
        }
      }
    }

    if (
      assignTransporterData &&
      assignTransporterData?.hopPerformance?.length > 0 &&
      (assignTransporterData !== undefined || assignTransporterData !== null)
    ) {
      for (const key of assignTransporterData?.hopPerformance) {
        let acceptedIndentPercentage = key?.performance?.filter(
          (ele) => ele?.performanceType === "acceptance"
        );
        console.log(acceptedIndentPercentage, "acceptance works");
        let onTimeDeliveredTripsPercentage = key?.performance?.filter(
          (ele) => ele?.performanceType === "onTimeDelivery"
        );
        let shortageTripsPercentage = key?.performance?.filter(
          (ele) => ele?.performanceType === "shortage"
        );
        let damagedTripsPercentage = key?.performance?.filter(
          (ele) => ele?.performanceType === "damage"
        );

        let indentTripStatus = [
          acceptedIndentPercentage && acceptedIndentPercentage?.length > 0
            ? Number(
                (
                  (acceptedIndentPercentage[0]?.performanceCount /
                    acceptedIndentPercentage[0]?.totalIndents) *
                  100
                ).toFixed(2)
              )
            : 0,
          acceptedIndentPercentage && acceptedIndentPercentage?.length > 0
            ? Number(
                100 -
                  (acceptedIndentPercentage[0]?.performanceCount /
                    acceptedIndentPercentage[0]?.totalIndents) *
                    100
              ).toFixed(2)
            : 0,
          onTimeDeliveredTripsPercentage &&
          onTimeDeliveredTripsPercentage?.length > 0
            ? Number(
                100 -
                  (onTimeDeliveredTripsPercentage[0]?.performanceCount /
                    onTimeDeliveredTripsPercentage[0]?.totalTrips) *
                    100
              ).toFixed(2)
            : 0,
          onTimeDeliveredTripsPercentage &&
          onTimeDeliveredTripsPercentage?.length > 0
            ? Number(
                (
                  (onTimeDeliveredTripsPercentage[0]?.performanceCount /
                    onTimeDeliveredTripsPercentage[0]?.totalTrips) *
                  100
                ).toFixed(2)
              )
            : 0,
          shortageTripsPercentage && shortageTripsPercentage?.length > 0
            ? Number(
                (
                  (shortageTripsPercentage[0]?.performanceCount /
                    shortageTripsPercentage[0]?.totalTrips) *
                  100
                ).toFixed(2)
              )
            : 0,
          damagedTripsPercentage && damagedTripsPercentage?.length > 0
            ? Number(
                (
                  (damagedTripsPercentage[0]?.performanceCount /
                    damagedTripsPercentage[0]?.totalTrips) *
                  100
                ).toFixed(2)
              )
            : 0,
        ];
        let obj = {
          name:
            key?.transporter && key?.transporter?.length
              ? key?.transporter[0]?.name
              : "",
          data: indentTripStatus,
        };
        apexChart.push(obj);
      }
    }
    if (Array.isArray(apexChart)) {
      apexChart.forEach((eleLocTrip) => {
        transporterName.push(eleLocTrip.name);
      });
    }

    return (
      <React.Fragment>
       {/* {this.state.dataLoading ? (
            <WaveLoader />
        ) : ( */}
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          style={{
            backgroundColor: darkTheme ? "#1B1C31" : "#FBFBFB",
          }}
          className="padt38"
        >
          <Grid item xs={12}>
            <DashboardHeader
              {...this.props}
              selectedHopValues={this.state.selectedHopValues}
              hopOptions={this.state.hops}
              onChangeHop={this.onChangeHop}
              onKeyUpHop={this.onKeyUpHop}
              datevalues={this.state.datevalues}
              onChangeDatePicker={this.onChangeDatePicker}
              onReset={this.onReset}
              userLang={userLang}
              hopIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                >
                  <path
                    d="M12.455 6.7275C12.455 11.1825 6.727 15.0005 6.727 15.0005C6.727 15.0005 1 11.1825 1 6.7275C1 5.20847 1.60343 3.75166 2.67755 2.67755C3.75166 1.60343 5.20847 1 6.7275 1C8.24653 1 9.70334 1.60343 10.7775 2.67755C11.8516 3.75166 12.455 5.20847 12.455 6.7275V6.7275Z"
                    stroke="#2800FC"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7 9.00049C8.10457 9.00049 9 8.10506 9 7.00049C9 5.89592 8.10457 5.00049 7 5.00049C5.89543 5.00049 5 5.89592 5 7.00049C5 8.10506 5.89543 9.00049 7 9.00049Z"
                    stroke="#2800FC"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              }
              opCardStyle={darkTheme ? darkTheme : ""}
              filtersHops={this.filtersHops}
            />
          </Grid>

          {this.state.dataLoading ? (
            <WaveLoader />
        ) : (
         <>
           <Grid item xs={12} md={7} className="transDashPD padt38">
            <Card className="apexChart-card apexBgDarkCard">
              <ApexChart
                apiData={apexChart}
                transporterName={transporterName}
                userLang={userLang}
                darkApexChart={darkTheme ? darkTheme : ""}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={5} className="transDashPD barchartPt padt38">
            <Card className="barChart-card">
              <BarChart
                laneWiseTripCount={laneWidget}
                userLang={userLang}
                darkBarChart={darkTheme ? darkTheme : ""}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={3} className="transDashPD barchartPt padt38">
            <Card className="barChart-card">
              <VehiclePlacementChart
                vehiclePlacementData={
                  assignTransporterData?.rankings?.placement
                }
                userLang={userLang}
                mode={darkTheme ? darkTheme : ""}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={3} className="transDashPD barchartPt padt38">
            <Card className="barChart-card">
              <ShipmentShortageChart
                shipmentShortageData={assignTransporterData?.rankings?.shortage}
                userLang={userLang}
                mode={darkTheme ? darkTheme : ""}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={3} className="transDashPD barchartPt padt38">
            <Card className="barChart-card">
              <ShipmentDamageChart
                shipmentDamageData={assignTransporterData?.rankings?.damage}
                userLang={userLang}
                mode={darkTheme ? darkTheme : ""}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={3} className="transDashPD barchartPt padt38">
            <Card className="barChart-card">
              <IndentAcceptanceChart
                indentAcceptanceData={
                  assignTransporterData?.rankings?.acceptance
                }
                userLang={userLang}
                mode={darkTheme ? darkTheme : ""}
              />
            </Card>
          </Grid>
         </>
            )}
        </Grid>
      
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    countByStatus: state.TripCountStatus.tripCountStatus.countStatus,
    operationsTrips:
      state.TripCountStatus.tripCountStatus?.operationsTripIndentDetails?.data
        ?.trip,
    transportHistory: state?.TripCountStatus?.tripCountStatus
      ?.transporterTripHistory?.data
      ? state?.TripCountStatus?.tripCountStatus?.transporterTripHistory?.data
      : "",
    operationsIndents:
      state.TripCountStatus.tripCountStatus?.operationsTripIndentDetails?.data
        ?.indent,
  };
};
export default connect(mapStateToProps, {
  tripCountByStatus,
  fetchHops,
  operationsDashboardTripsAndIndent,
  transporterHistoryTripsByStatus,
})(TransporterDashboard);
