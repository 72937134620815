import React, { Component } from "react";
import { Link } from "react-router-dom";

// Other Layout related Component

class Option extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {}

  componentDidMount() {
    // Scrollto 0,0
  }

  render() {
    let { data } = this.props;
    let { title, subOptions = [], path } = data;
    let hasOptions = subOptions.length;
    return (
      <li className="nav-item dropdown">
        <Link
          onClick={(e) => {
            let a = path ? null : e.preventDefault();
          }}
          className="nav-link cursor dropdown-toggle arrow-none"
          to={path || "/#"}
          id="topnav-apps"
          role="button"
        >
          <i className="ri-apps-2-line mr-2"></i>
          <strong>{title}</strong>
          {hasOptions ? <div className="arrow-down"></div> : null}
        </Link>
        {hasOptions ? (
          <div className={"dropdown-menu"} aria-labelledby="topnav-apps">
            {subOptions
              .filter((e) => e.isAllowed)
              .map((e, index) => (
                <Link key={index} to={e.path} className="dropdown-item">
                  <strong>{e.title}</strong>
                </Link>
              ))}
          </div>
        ) : null}
      </li>
    );
  }
}
export default Option;
