import React, { Component } from "react";
import { Card } from "reactstrap";
import Grid from "@mui/material/Grid";
import closeIcon from "../assets/closeIcon.png";
import { Progress } from "reactstrap";
import { Row, Col } from "reactstrap";
import OnTimeDeliveryDonutChart from "./OnTimeDeliveryDonutChart";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";

export default class OnTimeDeliverCards extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    const indents = this.props.indents;
    return (
      <>
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          className="mt-1 pd18"
        >
          <Grid item xs={12} sm={12} className="pl-1 pr-1">
            <div style={{ marginRight:"6px"}}>
              <Grid container spacing={0} className="">
                <Grid item xs={6}
                 style={{height:"130px"}}
                >
                  <Card className="shadow darkInternalcard">
                    <div className="onTimeDeliveryDetails darkInternalcard"
                      // style={{
                      //   width: "100%",
                      //   height: "130px",
                      //   float: "left",
                      // }}
                    >
                      <span className="pt-2 ml-4 span-indentAcceptance-name light__span">
                      {this.props?.userLang?.dashboard?.TOTAL_DELIVERIES ?? "Total Deliveries"}
                      {/* Total deliveries */}
                      </span>
                      <br></br>
                      <span className="pt-2 ml-4 span-Ontimdelivery-value dark__span">
                        {indents?.onTimeDeliveries}
                      </span>
                      <br></br>
                      <span className="pt-3 ml-4 span-indent-rejection-name light__span">
                      {this.props?.userLang?.dashboard?.ON_TIME_DELIVERY ?? "On Time Deliveries"}
                      {/* On time deliveries */}
                      </span>
                      <br></br>
                      <span className="pt-2 ml-4 span-Ontimdelivery-value dark__span">
                        {indents?.onTimeDeliverisPercentage + " "+"%"}
                      </span>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={6} className="">
                  <Card className=" card-ontime-dountchart shadow darkdountchart">
                    <OnTimeDeliveryDonutChart data={indents}  performance={this.props?.performance}/>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}
