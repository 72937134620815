import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  FormGroup,
  InputGroup,
  Label,
  Input,
} from "reactstrap";
import { NotificationManager } from "react-notifications";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";

import { MoonLoader } from "react-spinners";
import Breadcrumbs from "../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import { MDBDataTable, MDBBtn } from "mdbreact";
import { fetchDrivers } from "../store/driver/actions";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { getAccessForEntity } from "../pages/policies";
//import { Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { restrict } from "./restrict";
import { PERMS } from "../enum/perms.enum";
import { subscribeConsent, unsubscribeConsent } from "../store/driver/actions";
import { debounce } from "lodash";

import Controls from "../components/Common/Controls/Button";
import AddIcon from "@material-ui/icons/Add";
import Popup from "../components/Common/Popup";
import DriverForm from "../pages/Forms/DriverForm";

var data = {};
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var columns = [
  {
    label: "Name",
    field: "fullName",
    sort: "asc",
    width: 150,
  },
  {
    label: "Phone",
    field: "phone",
    sort: "asc",
    width: 200,
  },
  {
    label: "LicNo",
    field: "driverLicenseNumber",
    sort: "asc",
    width: 100,
  },
  {
    label: "Consent Status",
    field: "consentProvided",
    sort: "asc",
    width: 100,
  },
  {
    label: "Actions",
    field: "consentUpdate",
    sort: "asc",
    width: 50,
  },
];
class Driver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      breadcrumbItems: [
        { title: "Driver", link: "#" },
        { title: "Driver Details", link: "#" },
      ],
      offset: 0,
      name: "",
      phoneNo: "",
      setOpenPopup: false,
    };
    (async () => {
      let redirect = await restrict(PERMS.DRIVER_TRANSPORTER_READ);

      if (redirect == false) {
        this.props.history.push("/operationsDashboard");
      }
    })();

    this.handleInputDebounceName = debounce(this.getDriverNameFunc, 1000);
    this.handleInputDebouncePhone = debounce(this.getDriverPhoneFunc, 1000);
  }
  resetName = (e) => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
    this.setState(
      {
        name: "",
        loading: true,
      },

      () => {
        let offset = 0;
        this.setState({ loading: true });
        this.props.fetchDrivers(offset, this.state.name, this.state.phoneNo);
        this.refineData();
      }
    );
  };
  reset = (e) => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
    this.setState(
      {
        name: "",
        phoneNo: "",
        loading: true,
      },

      () => {
        let offset = 0;
        this.setState({ loading: true });
        this.props.fetchDrivers(offset, this.state.name, this.state.phoneNo);
        this.refineData();
      }
    );
  };
  resetPhone = (e) => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
    this.setState(
      {
        phoneNo: "",
        loading: true,
      },

      () => {
        let offset = 0;
        this.setState({ loading: true });
        this.props.fetchDrivers(offset, this.state.name, this.state.phoneNo);
        this.refineData();
      }
    );
  };
  refineData = () => {
    this.setState({ loading: false });
  };

  handleDriverName = (e) => {
    this.setState({ name: e.target.value }, () => {
      if (this.state.name.length > 2) {
        this.handleInputDebounceName();
        this.setState({ disable: false });
      } else {
        this.setState({ disable: true });
      }
    });
  };
  handleDriverPhone = (e) => {
    console.log("hhh", e.target.value);
    this.setState({ phoneNo: e.target.value }, () => {
      if (this.state.phoneNo.length > 2) {
        this.handleInputDebouncePhone();
        this.setState({ disable: false });
      } else {
        this.setState({ disable: true });
      }
    });
  };
  getDriverPhoneFunc() {
    let phoneNo = this.state.phoneNo;
    let name = this.state.name;
    let offset = 0;
    this.props.fetchDrivers(offset, name, phoneNo);
  }
  getDriverNameFunc() {
    let name = this.state.name;
    let phoneNo = this.state.phoneNo;
    let offset = 0;
    this.props.fetchDrivers(offset, name, phoneNo);
  }
  componentDidMount = () => {
    let offset = 0;
    this.setState({ loading: true });
    this.props.fetchDrivers(offset, this.state.name, this.state.phoneNo);
    this.refineData();
  };
  componentDidUpdate(prevProps) {}
  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };

  setOpenPopupfalse = (props) => {
    this.setState({ setOpenPopup: props });
  };

  handleSubmit = (props) => {
    this.setState({ setOpenPopup: props });
    let offset = 0;
    this.setState({ loading: true });
    this.props.fetchDrivers(offset, this.state.name, this.state.phoneNo);
    this.refineData();
  };
  handleChangePage = (event, val) => {
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    this.props.fetchDrivers(offset, this.state.name, this.state.phoneNo);
    this.setState({ loading: true });
    this.refineData();
  };
  consentUpdate = (ele) => {
    let offset = 0;
    if (!ele.consentProvided) {
      this.props.subscribeConsent(ele.phone, (res) => {
        if (res.success) NotificationManager.success(res["message"], "Success");
        this.props.fetchDrivers(offset, this.state.name, this.state.phoneNo);
      });
    } else {
      this.props.unsubscribeConsent(ele.phone, (res) => {
        if (res.success) NotificationManager.success(res["message"], "Success");
        this.props.fetchDrivers(offset, this.state.name, this.state.phoneNo);
      });
    }
  };

  render() {
    const { name, phoneNo, loading } = this.state;
    const { fullScreen, drivers } = this.props;
    let rows = [];
    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#123abc"} />
          </Col>
        </div>
      );
    }
    // console.log(this.props.drivers);

    if (Array.isArray(drivers?.drivers?.data?.docs)) {
      // console.log("drivers : ", this.props.drivers.drivers.data.docs);
      totCnt = drivers?.drivers?.data?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal =
        drivers?.drivers?.data?.offset + drivers?.drivers?.data?.docs?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      drivers.drivers.data.docs.forEach((ele) => {
        rows.push({
          fullName: ele.name,
          phone: ele.phone,
          driverLicenseNumber: ele.licence.number,
          transporterVendor: ele.transporter,
          consentProvided: ele.consentProvided ? (
            <span style={{ color: "green" }}>Consent Provided</span>
          ) : (
            <span style={{ color: "red" }}>Consent not Provided</span>
          ),
          consentUpdate: ele.consentProvided ? (
            <span>
              <MDBBtn
                color="danger"
                size="sm"
                onClick={() => {
                  this.consentUpdate(ele);
                }}
              >
                Stop Consent{" "}
              </MDBBtn>
            </span>
          ) : (
            <span>
              <MDBBtn
                color="primary"
                size="sm"
                onClick={() => {
                  this.consentUpdate(ele);
                }}
              >
                Start Consent{" "}
              </MDBBtn>
            </span>
          ),
        });
      });
    }

    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#123abc"} />
          </Col>
        </div>
      );
    }
    data = {
      columns: columns,
      rows: rows,
    };

    return (
      <React.Fragment>
        <div /* className="page-content" */>
          <Container fluid>
            <div className="d-flex bd-highlight">
              <div className="p-2 flex-grow-1 bd-highlight">
                <h5
                  className="mt-1"
                  style={{
                    fontWeight: "700",
                    lineHeight: "20px",
                    size: "16px",
                    color: "#808080",
                  }}
                >
                  Driver Master{" "}
                </h5>
              </div>
              <div className="p-2 bd-highlight">
                <>
                  <Controls
                    size="small"
                    variant="outlined"
                    onClick={() => {
                      this.setState({ setOpenPopup: true });
                    }}
                    icon={<AddIcon style={{ color: "#2800fc" }} />}
                  ></Controls>
                </>

                {/* </Link> */}
              </div>
            </div>
            <Row>
              <Col xs={12}>
                <Card style={{ height: "100%", marginBottom: "61px" }}>
                  <CardBody>
                    <div className="justify-content-between d-flex mb-3">
                      <div className="float-left">
                        <div className=" d-flex flex-wrap align-items-start float-right">
                          <div className="pb-1 mr-4">
                            <Label className="required-field">Name</Label>
                            <input
                              style={{ height: "37px" }}
                              className="form-control searchBox form-control-sm sharp-corners-right rounded-left"
                              type="text"
                              name="name"
                              value={name}
                              onChange={(e) => this.handleDriverName(e)}
                              onKeyUp={(event) => {
                                if (
                                  this.state.name === "" &&
                                  (event.keyCode === 8 || event.keyCode === 46)
                                ) {
                                  this.resetName();
                                }
                              }}
                              placeholder="Search"
                              aria-label="Search"
                              icon="fas fa-times-circle"
                            />
                          </div>
                          <div className="pb-1 mr-4">
                            <Label className="required-field">Phone</Label>
                            <input
                              style={{ height: "37px" }}
                              className="form-control searchBox form-control-sm sharp-corners-right rounded-left"
                              type="text"
                              name="phoneNo"
                              value={phoneNo}
                              onChange={(e) => this.handleDriverPhone(e)}
                              onKeyUp={(event) => {
                                if (
                                  this.state.phoneNo === "" &&
                                  (event.keyCode === 8 || event.keyCode === 46)
                                ) {
                                  this.resetPhone();
                                }
                              }}
                              placeholder="Search"
                              aria-label="Search"
                              icon="fas fa-times-circle"
                            />
                          </div>
                          <div
                            className="pb-1 mr-2 mt-4"
                            style={{ paddingTop: "5px" }}
                          >
                            <Button
                              style={{
                                backgroundColor: "#74788d !important",
                              }}
                              className=" button-wide ml-4 rounded-black"
                              onClick={this.reset}
                              // disabled={!this.state.searchOn}
                            >
                              Reset
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <MDBDataTable
                      className="text-center"
                      responsive
                      striped
                      bordered
                      data={data}
                      paging={false}
                      searching={false}
                    />
                    {totCnt !== 0 ? (
                      <Row xs={12}>
                        <Col xs={6}>
                          <span>
                            Showing {fromVal} to {toVal} of {totCnt} entries
                          </span>
                        </Col>
                        <Col xs={6}>
                          <div className="float-right">
                            <Pagination
                              page={offset / 10 + 1}
                              count={totPages}
                              color="primary"
                              onChange={this.handleChangePage}
                            />
                          </div>
                        </Col>
                      </Row>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>

          <Popup
            title="Add Driver"
            openPopup={this.state.setOpenPopup}
            setOpenPopupfalse={this.setOpenPopupfalse}
          >
            <DriverForm handleSubmit={this.handleSubmit} />
          </Popup>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  drivers: state.Driver.drivers,
  policies: state.AccessPolicy.accessPolicies.policies,
  user: state.Login.user,
});

export default connect(mapStateToProps, {
  fetchDrivers,
  subscribeConsent,
  unsubscribeConsent,
})(Driver);
