import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import "@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { createDealer } from "../../store/dealer/actions";
import formatInput from "../InputFormatter";

class DealerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onSubmitDisable: false,
      breadcrumbItems: [
        { title: "Dealer", link: "dealers" },
        { title: "Add Dealer", link: "#" },
      ],
      form: {
        fullName: "",
        email: "",
        phone: "",
        dealerCode: "",
        zipCode: "",
        city: "",
        state: "",
        address: "",
        AlternateContactno: "",
      },
      formErrors: {
        // uname: "",
        fullName: "",
        email: "",
        phone: "",
        dealerBranchNo: "",
        dealerCode: "",
        zipCode: "",
        city: "",
        state: "",
        address: "",
        AlternateContactno: "",
      },
    };
    this.statesList = [
      this.initialVal("state"),
      {
        label: "Andaman and Nicobar Islands",
        value: "Andaman and Nicobar Islands",
      },
      { label: "Andhra Pradesh", value: "Andhra Pradesh" },
      { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
      { label: "Assam", value: "Assam" },
      { label: "Bihar", value: "Bihar" },
      { label: "Chandigarh", value: "Chandigarh" },
      { label: "Chhattisgarh", value: "Chhattisgarh" },
      {
        label: "Dadra and Nagar Haveli and Daman and Diu",
        value: "Dadra and Nagar Haveli and Daman and Diu",
      },
      { label: "Delhi", value: "Delhi" },
      { label: "Goa", value: "Goa" },
      { label: "Gujarat", value: "Gujarat" },
      { label: "Haryana", value: "Haryana" },
      { label: "Himachal Pradesh", value: "Himachal Pradesh" },
      { label: "Jammu and Kashmir", value: "Jammu and Kashmir" },
      { label: "Jharkhand", value: "Jharkhand" },
      { label: "Karnataka", value: "Karnataka" },
      { label: "Kerala", value: "Kerala" },
      { label: "Ladakh", value: "Ladakh" },
      { label: "Lakshadweep", value: "Lakshadweep" },
      { label: "Madhya Pradesh", value: "Madhya Pradesh" },
      { label: "Maharashtra", value: "Maharashtra" },
      { label: "Manipur", value: "Manipur" },
      { label: "Meghalaya", value: "Meghalaya" },
      { label: "Mizoram", value: "Mizoram" },
      { label: "Nagaland", value: "Nagaland" },
      { label: "Odisha", value: "Odisha" },
      { label: "Puducherry", value: "Puducherry" },
      { label: "Punjab", value: "Punjab" },
      { label: "Rajasthan", value: "Rajasthan" },
      { label: "Sikkim", value: "Sikkim" },
      { label: "Tamil Nadu", value: "Tamil Nadu" },
      { label: "Telangana", value: "Telangana" },
      { label: "Tripura", value: "Tripura" },
      { label: "Uttar Pradesh", value: "Uttar Pradesh" },
      { label: "Uttarakhand", value: "Uttarakhand" },
      { label: "West Bengal", value: "West Bengal" },
    ];
  }
  initialVal = (val) => {
    return { label: "Select " + val, value: "", isDisabled: true };
  };
  validEmail = (val) => {
    return new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(val);
  };
  validateField = (name, value, refValue) => {
    let errorMsg = null;
    switch (name) {
      case "fullName":
        if (!value) errorMsg = "Please Enter Full Name.";
        break;
      case "email":
        // if (!value) errorMsg = "Please Enter Email.";
        // else {
        if (value && value.trim() !== "" && !this.validEmail(value))
          errorMsg = "Please Enter valid email.";
        // }
        break;
      case "phone":
        if (!value) errorMsg = "Please Enter Phone.";
        if (value.length !== 10) errorMsg = "Please Enter Valid Number.";
        break;
      case "AlternateContactno":
        if (value && value.length !== 10)
          errorMsg = "Please Enter Valid Number.";
        break;
      case "dealerCode":
        if (!value) errorMsg = "Please Enter Dealer Code.";
        break;

      case "zipCode":
        if (!value) errorMsg = "Please Enter zipCode.";
        else if (value.length < 6 || value.length > 6)
          errorMsg = "Zip code should be of length 6";
        break;
        break;
      case "city":
        if (!value) errorMsg = "Please Enter City.";
        break;
      case "state":
        if (!value) errorMsg = "Please Enter State.";
        break;
      case "address":
        if (!value) errorMsg = "Please Enter Address.";
        break;

      default:
        break;
    }
    return errorMsg;
  };

  validateForm = (form, formErrors, validateFunc) => {
    const errorObj = {};
    Object.keys(formErrors).map((x) => {
      let refValue = null;

      const msg = validateFunc(x, form[x], refValue);
      if (msg) errorObj[x] = msg;
    });
    return errorObj;
  };

  handleSubmit = () => {
    this.setState({ onSubmitDisable: true });
    const { form, formErrors } = this.state;
    const errorObj = this.validateForm(form, formErrors, this.validateField);
    //console.log('errorObj', errorObj);
    if (Object.keys(errorObj).length !== 0) {
      this.setState({
        onSubmitDisable: false,
        formErrors: { ...formErrors, ...errorObj },
      });
      return false;
    }
    let formVal = { ...form };
    this.props.createDealer(formVal, (res) => {
      if (res.statusCode === 200 || res.statusCode === 201) {
        NotificationManager.success("Dealer Created Successfully", "Success");
        this.props.history.push("/dealers");
      } else {
        this.setState({ onSubmitDisable: false });
        NotificationManager.error(res["message"], "Error");
      }
    });
  };
  reset = () => {
    let formData = {
      fullName: "",
      phone: "",
      email: "",
      dealerID: "",
      dealerCode: "",
      zipCode: "",
      city: "",
      state: "",
      address: "",
      AlternateContactno: "",
    };

    this.setState({
      form: formData,
    });
    //console.log('form', this.state.form);
  };
  validateNumber = (evt) => {
    var theEvent = evt || window.event;

    // Handle paste
    if (theEvent.type === "paste") {
      var key = theEvent.clipboardData.getData("text/plain");
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /^[0-9]*$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    const { form, formErrors } = this.state;
    let formObj = {};

    formObj = {
      ...form,
      [name]: value,
    };
    this.setState({ form: formObj }, () => {
      if (!Object.keys(formErrors).includes(name)) return;
      let formErrorsObj = {};

      const errorMsg = this.validateField(
        name,
        name === "language" ? this.state.form["language"] : value
      );
      formErrorsObj = { ...formErrors, [name]: errorMsg };
      this.setState({ formErrors: formErrorsObj });
    });
  };

  trimSpace = (event, name) => {
    const { form } = this.state;
    let formObj = formatInput(event, name, form);
    this.setState({ form: formObj });
  };

  render() {
    const { form, formErrors, onSubmitDisable } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Add Dealer"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <form>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">Full Name</Label>

                            <Input
                              className="form-control"
                              type="text"
                              value={form.fullName}
                              onBlur={(e) => this.trimSpace(e, "fullName")}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "fullName",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="Full Name"
                            />
                            {formErrors.fullName && (
                              <span className="err">{formErrors.fullName}</span>
                            )}
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">State</Label>
                            <Select
                              value={this.statesList.find(
                                (x) => x.value === form.state
                              )}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "state",
                                    value: e.value,
                                  },
                                })
                              }
                              options={this.statesList}
                              classNamePrefix="select2-selection"
                            />

                            {formErrors.state && (
                              <span className="err">{formErrors.state}</span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label>Email</Label>
                            <Input
                              className="form-control"
                              type="text"
                              value={form.email}
                              onBlur={(e) => this.trimSpace(e, "email")}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "email",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="username@gmail.com"
                            />
                            {formErrors.email && (
                              <span className="err">{formErrors.email}</span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">Phone</Label>
                            <Input
                              className="form-control"
                              type="text"
                              value={form.phone}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "phone",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="98989898989"
                              onKeyPress={this.validateNumber}
                            />
                            {formErrors.phone && (
                              <span className="err">{formErrors.phone}</span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">
                              Dealer Address
                            </Label>
                            <Input
                              className="form-control"
                              type="text"
                              value={form.address}
                              onBlur={(e) => this.trimSpace(e, "address")}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "address",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="Ex: 201,Street Name,city"
                            />
                            {formErrors.address && (
                              <span className="err">{formErrors.address}</span>
                            )}
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">City</Label>
                            <Input
                              className="form-control"
                              type="text"
                              value={form.city}
                              onBlur={(e) => this.trimSpace(e, "city")}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "city",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="ex: Mumbai"
                            />
                            {formErrors.city && (
                              <span className="err">{formErrors.city}</span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">
                              Dealer Zip Code
                            </Label>
                            <Input
                              className="form-control"
                              type="number"
                              value={form.zipCode}
                              onBlur={(e) => this.trimSpace(e, "zipCode")}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "zipCode",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="ex: 560011"
                            />
                            {formErrors.zipCode && (
                              <span className="err">{formErrors.zipCode}</span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">
                              Dealer Code
                            </Label>
                            <Input
                              className="form-control"
                              type="number"
                              value={form.dealerCode}
                              onBlur={(e) => this.trimSpace(e, "dealerCode")}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "dealerCode",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="ex: 500229															"
                            />
                            {formErrors.dealerCode && (
                              <span className="err">
                                {formErrors.dealerCode}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label>Alternate Contact No</Label>
                            <Input
                              className="form-control"
                              type="text"
                              value={form.AlternateContactno}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "AlternateContactno",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="98989898989"
                              onKeyPress={this.validateNumber}
                            />
                            {formErrors.AlternateContactno && (
                              <span className="err">
                                {formErrors.AlternateContactno}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup className="mb-0 text-center ">
                        <div>
                          <Button
                            disabled={onSubmitDisable}
                            type="reset"
                            color="secondary"
                            onClick={this.reset}
                          >
                            Reset
                          </Button>{" "}
                          <Button
                            disabled={onSubmitDisable}
                            type="button"
                            color="btn btn-primary"
                            className="mr-1"
                            onClick={this.handleSubmit}
                          >
                            Submit
                          </Button>
                        </div>
                      </FormGroup>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(null, { createDealer })(DealerForm);
