import React, { Component } from "react";
import {
  Map,
  InfoWindow,
  Polyline,
  Marker,
  GoogleApiWrapper,
} from "google-maps-react";
import { Button, Card, CardBody } from "reactstrap";
import { fetchLocationById, fetchTripById } from "../store/trip/actions";
import { connect } from "react-redux";
import { compose } from "redux";
import { processMapLocations } from "./TrackerProcessor";
import { MapCore } from "./MapsNew/MapCore";

var directionsService;
export class TrackingMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      currentLocation: {},
      coordinateArray: [],
      sourceCoordinates: {},
      destinationCoordinates: {},
      mapsId: "",
      resetProp: false,
      destinationList: [],
      directions: null,
      subStatus: "",
      delayedStatus: "",
    };
  }
  componentDidUpdate() {
    if (this.props.id === "RouteOptimization") {
      if (this.props.resetFlag) {
        this.setState(
          {
            sourceCoordinates: {},
            destinationList: [],
          },
          () => {
            this.props.changeResetFlag();
          }
        );
      }
      if (this.props.srcChange) {
        if (this.props.source.srcLat !== this.state.sourceCoordinates?.lat)
          this.getAllData();
      }
      if (this.props.destChange) {
        if (
          this.props.destinationList?.length !==
          this.state.destinationList.length
        ) {
          this.getAllData();
        }
      }
    } else {
      if (this.state.mapsId !== this.props.id) {
        this.getAllData();
      }
    }
  }
  componentDidMount = () => {
    this.getAllData();
  };
  getAllData = () => {
    if (this.props.id === "RouteOptimization") {
      let data = this.props;
      let source = {},
        destinationList = [];
      if (data.source.srcLat !== "" && data.source.srcLng !== "") {
        source = {
          lat: data.source.srcLat,
          lng: data.source.srcLng,
        };
      }
      if (data.destinationList) {
        destinationList = data.destinationList.map((item) => {
          return {
            lat: item.destLat,
            lng: item.destLng,
          };
        });
      }

      this.setState(
        {
          sourceCoordinates: source,
          destinationList: destinationList,
        },
        () => {}
      );
    } else {
      this.setState(
        {
          mapsId: this.props.id,
        },
        () => {
          if (this.props.id !== undefined) {
            let source;
            let destination;
            this.props.fetchTripById(this.props.id, (res) => {
              let data = res.data[0];

              source = {
                lat: parseInt(data.source.location.latitude),
                lng: parseInt(data.source.location.longitude),
              };
              destination = {
                lat: parseInt(data.destination.location.latitude),
                lng: parseInt(data.destination.location.longitude),
              };
              this.setState(
                {
                  sourceCoordinates: source,
                  destinationCoordinates: destination,
                  subStatus: res?.data[0]?.subStatus,
                  delayedStatus: res?.data[0]?.delayedStatus,
                  currentLocation:
                    res?.data[0]?.latestKnownLocation?.length > 0
                      ? {
                          lat: res?.data[0]?.latestKnownLocation[0]?.coordinates
                            ?.lng,
                          lng: res?.data[0]?.latestKnownLocation[0]?.coordinates
                            ?.lat,
                        }
                      : {},
                },
                () => {}
              );
            });

            this.props.fetchLocationById(this.props.id, (res) => {
              if (res.data.length) {
                let locationObject = processMapLocations(res);
                let coordArray = [];
                locationObject.coordinateArray.map((item, index) => {
                  coordArray.push({
                    lat: parseInt(item.lng),
                    lng: parseInt(item.lat),
                  });
                });
                this.setState(
                  {
                    coordinateArray: [coordArray],
                  },
                  () => {
                    this.props.handleVehicleHistory(
                      locationObject.recentLocations
                    );
                  }
                );
              } else {
              }
            });
          } else {
            this.setState({
              sourceCoordinates: {},
              destinationCoordinates: {},
              coordinateArray: [],
              currentLocation: {},
              subStatus: "",
              delayedStatus: "",
            });
          }
        }
      );
    }
  };
  render() {
    let vehicleCoordinate = [];
    vehicleCoordinate = this.props.vehicleCoordinates;
    if (this.state.resetProp !== this.props.resetProp) {
      this.setState({ resetProp: this.props.resetProp });
    }

    return (
      <div>
        <MapCore
          sourceCoordinates={this.state.sourceCoordinates}
          destinationCoordinates={this.state.destinationCoordinates}
          coordinateArray={this.state.coordinateArray}
          currentLocation={this.state.currentLocation}
          destinationList={this.state.destinationList}
          directions={this.state.directions}
          lineCoordinates={this.props.lineCoordinates}
          subStatus={this.state.subStatus}
          delayedStatus={this.state.delayedStatus}
          vehicleCoordinate={vehicleCoordinate}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    trips: state.Trip.trips.trips,
  };
};

export default connect(mapStateToProps, {
  fetchLocationById,
  fetchTripById,
})(TrackingMap);
