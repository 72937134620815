import React, { Component } from "react";
import { Card } from "reactstrap";
import { connect } from "react-redux";
import { MDBBtn } from "mdbreact";
import { updateInvoice } from "../../../store/actions";
import { NotificationManager } from "react-notifications";

var requestObject;
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class BillItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      requestObject: {},
      invoiceId: "",
      billId: "",
      //Freight
      freightToggle: false,
      revisedFreightCost: "",
      //Damage
      damageToggle: false,
      revisedDamageCost: "",
      //Loading
      loadingChargesToggle: false,
      revisedLoadingCharges: "",
      //Unloading
      unloadingChargesToggle: false,
      revisedUnloadingCharges: "",
      //VehicleNonPlacementCharges
      revisedVehicleNonPlacementChargesToggle: false,
      revisedVehicleNonPlacementCharges: "",
      //IndentRejectionCharges
      revisedIndentRejectionChargesToggle: false,
      revisedIndentRejectionCharges: "",
      //CancellationUponArrivalByCompanyCharges
      revisedCancellationUponArrivalByCompanyChargesToggle: false,
      revisedCancellationUponArrivalByCompanyCharges: "",
      //MultipointPickupCharges
      revisedMultipointPickupChargesToggle: false,
      revisedMultipointPickupCharges: "",
      //MultipointDropCharges
      revisedMultipointDropChargesToggle: false,
      revisedMultipointDropCharges: "",
      //SourceDetentionCharges
      revisedSourceDetentionChargesToggle: false,
      revisedSourceDetentionCharges: "",
      //DestinationDetentionCharges
      revisedDestinationDetentionChargesToggle: false,
      revisedDestinationDetentionCharges: "",
      //LateDeliveryCharges
      revisedLateDeliveryChargesToggle: false,
      revisedLateDeliveryCharges: "",
      //ShortageCharges
      revisedShortageChargesToggle: false,
      revisedShortageCharges: "",
      //common
      saveButtonDisable: false,
    };
  }

  //Freight
  freightCostToggler = () => {
    console.log("Ftoggler");
    this.setState({ freightToggle: !this.state.freightToggle });
  };

  freightAssigner = (e) => {
    console.log(e.target.value);
    this.setState({ revisedFreightCost: e.target.value });
  };

  closeFreightInput = (event) => {
    if (event.key === "Enter") {
      this.setState({ freightToggle: false });
    }
  };

  //Damage
  damageChargesToggler = () => {
    console.log("Dtoggler");
    this.setState({ damageToggle: !this.state.damageToggle });
  };

  damageAssigner = (e) => {
    this.setState({ revisedDamageCost: e.target.value });
  };

  closeDamageInput = (event) => {
    if (event.key === "Enter") {
      this.setState({ damageToggle: false });
    }
  };

  //Loading
  loadingChargesToggler = () => {
    console.log("Dtoggler");
    this.setState({ loadingChargesToggle: !this.state.loadingChargesToggle });
  };

  loadingAssigner = (e) => {
    this.setState({ revisedLoadingCharges: e.target.value });
  };

  closeLoadingChargesInput = (event) => {
    if (event.key === "Enter") {
      this.setState({ loadingChargesToggle: false });
    }
  };

  //Unloading
  unloadingChargesToggler = () => {
    console.log("Dtoggler");
    this.setState({
      unloadingChargesToggle: !this.state.unloadingChargesToggle,
    });
  };

  unloadingAssigner = (e) => {
    this.setState({ revisedUnloadingCharges: e.target.value });
  };

  closeUnloadingChargesInput = (event) => {
    if (event.key === "Enter") {
      this.setState({ unloadingChargesToggle: false });
    }
  };

  //VehicleNonPlacementCharges
  vehicleNonPlacementChargesToggler = () => {
    console.log("Dtoggler");
    this.setState({
      revisedVehicleNonPlacementChargesToggle:
        !this.state.revisedVehicleNonPlacementChargesToggle,
    });
  };

  vehicleNonPlacementAssigner = (e) => {
    this.setState({ revisedVehicleNonPlacementCharges: e.target.value });
  };

  vehicleNonPlacementChargesInput = (event) => {
    if (event.key === "Enter") {
      this.setState({ revisedVehicleNonPlacementChargesToggle: false });
    }
  };

  //IndentRejectionCharges
  indentRejectionChargesToggler = () => {
    console.log("Dtoggler");
    this.setState({
      revisedIndentRejectionChargesToggle:
        !this.state.revisedIndentRejectionChargesToggle,
    });
  };

  indentRejectionAssigner = (e) => {
    this.setState({ revisedIndentRejectionCharges: e.target.value });
  };

  indentRejectionChargesInput = (event) => {
    if (event.key === "Enter") {
      this.setState({ revisedIndentRejectionChargesToggle: false });
    }
  };

  //CancellationUponArrivalByCompanyCharges
  cancellationUponArrivalByCompanyChargesToggler = () => {
    console.log("Dtoggler");
    this.setState({
      revisedCancellationUponArrivalByCompanyChargesToggle:
        !this.state.revisedCancellationUponArrivalByCompanyChargesToggle,
    });
  };

  cancellationUponArrivalByCompanyAssigner = (e) => {
    this.setState({
      revisedCancellationUponArrivalByCompanyCharges: e.target.value,
    });
  };

  cancellationUponArrivalByCompanyChargesInput = (event) => {
    if (event.key === "Enter") {
      this.setState({
        revisedCancellationUponArrivalByCompanyChargesToggle: false,
      });
    }
  };

  //MultipointPickupCharges
  multipointPickupChargesToggler = () => {
    console.log("Dtoggler");
    this.setState({
      revisedMultipointPickupChargesToggle:
        !this.state.revisedMultipointPickupChargesToggle,
    });
  };

  multipointPickupAssigner = (e) => {
    this.setState({
      revisedMultipointPickupCharges: e.target.value,
    });
  };

  multipointPickupChargesInput = (event) => {
    if (event.key === "Enter") {
      this.setState({
        revisedMultipointPickupChargesToggle: false,
      });
    }
  };

  //MultipointDropCharges
  multipointDropChargesToggler = () => {
    console.log("Dtoggler");
    this.setState({
      revisedMultipointDropChargesToggle:
        !this.state.revisedMultipointDropChargesToggle,
    });
  };

  multipointDropAssigner = (e) => {
    this.setState({
      revisedMultipointDropCharges: e.target.value,
    });
  };

  multipointDropChargesInput = (event) => {
    if (event.key === "Enter") {
      this.setState({
        revisedMultipointDropChargesToggle: false,
      });
    }
  };

  //SourceDetentionCharges
  sourceDetentionChargesToggler = () => {
    console.log("Dtoggler");
    this.setState({
      revisedSourceDetentionChargesToggle:
        !this.state.revisedSourceDetentionChargesToggle,
    });
  };

  sourceDetentionAssigner = (e) => {
    this.setState({
      revisedSourceDetentionCharges: e.target.value,
    });
  };

  sourceDetentionChargesInput = (event) => {
    if (event.key === "Enter") {
      this.setState({
        revisedSourceDetentionChargesToggle: false,
      });
    }
  };

  //DestinationDetentionCharges
  destinationDetentionChargesToggler = () => {
    console.log("Dtoggler");
    this.setState({
      revisedDestinationDetentionChargesToggle:
        !this.state.revisedDestinationDetentionChargesToggle,
    });
  };

  destinationDetentionAssigner = (e) => {
    this.setState({
      revisedDestinationDetentionCharges: e.target.value,
    });
  };

  destinationDetentionChargesInput = (event) => {
    if (event.key === "Enter") {
      this.setState({
        revisedDestinationDetentionChargesToggle: false,
      });
    }
  };

  //LateDeliveryCharges
  lateDeliveryChargesToggler = () => {
    console.log("Dtoggler");
    this.setState({
      revisedLateDeliveryChargesToggle:
        !this.state.revisedLateDeliveryChargesToggle,
    });
  };

  lateDeliveryAssigner = (e) => {
    this.setState({
      revisedLateDeliveryCharges: e.target.value,
    });
  };

  lateDeliveryChargesInput = (event) => {
    if (event.key === "Enter") {
      this.setState({
        revisedLateDeliveryChargesToggle: false,
      });
    }
  };

  //ShortageCharges
  shortageChargesToggler = () => {
    console.log("Dtoggler");
    this.setState({
      revisedShortageChargesToggle: !this.state.revisedShortageChargesToggle,
    });
  };

  shortageAssigner = (e) => {
    this.setState({
      revisedShortageCharges: e.target.value,
    });
  };

  shortageChargesInput = (event) => {
    if (event.key === "Enter") {
      this.setState({
        revisedShortageChargesToggle: false,
      });
    }
  };

  componentDidMount = () => {
    console.log("Invoice Id load on mount", this.props.invoiceId);
    console.log("Bill Id load on mount", this.props.item._id);
    this.setState({
      invoiceId: this.props.invoiceId,
      billId: this.props.item._id,
    });
  };
  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (userLang !== this.state.userLangs || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }
  requestFormatter = () => {
    requestObject = {
      invoiceId: this.state.invoiceId,
      billId: this.state.billId,
    };
    if (this.state.revisedFreightCost) {
      requestObject.revisedFreightCost = parseInt(
        this.state.revisedFreightCost
      );
    }
    if (this.state.revisedDamageCost) {
      requestObject.revisedDamage = parseInt(this.state.revisedDamageCost);
    }
    if (this.state.revisedLoadingCharges) {
      requestObject.revisedLoadingCharges = parseInt(
        this.state.revisedLoadingCharges
      );
    }
    if (this.state.revisedUnloadingCharges) {
      requestObject.revisedUnloadingCharges = parseInt(
        this.state.revisedUnloadingCharges
      );
    }
    if (this.state.revisedVehicleNonPlacementCharges) {
      requestObject.revisedVehicleNonPlacementcharges = parseInt(
        this.state.revisedVehicleNonPlacementCharges
      );
    }
    if (this.state.revisedIndentRejectionCharges) {
      requestObject.revisedIndentRejectioncharges = parseInt(
        this.state.revisedIndentRejectionCharges
      );
    }
    if (this.state.revisedCancellationUponArrivalByCompanyCharges) {
      requestObject.revisedcancellationUponArrivalByCompanycharges = parseInt(
        this.state.revisedCancellationUponArrivalByCompanyCharges
      );
    }
    if (this.state.revisedMultipointPickupCharges) {
      requestObject.revisedMultipointPickupcharges = parseInt(
        this.state.revisedMultipointPickupCharges
      );
    }
    if (this.state.revisedMultipointDropCharges) {
      requestObject.revisedMultipointDropcharges = parseInt(
        this.state.revisedMultipointDropCharges
      );
    }
    if (this.state.revisedSourceDetentionCharges) {
      requestObject.revisedSourceDetentioncharges = parseInt(
        this.state.revisedSourceDetentionCharges
      );
    }
    if (this.state.revisedDestinationDetentionCharges) {
      requestObject.revisedDestinationDetentioncharges = parseInt(
        this.state.revisedDestinationDetentionCharges
      );
    }
    if (this.state.revisedLateDeliveryCharges) {
      requestObject.revisedLateDeliveryChargecharges = parseInt(
        this.state.revisedLateDeliveryCharges
      );
    }
    if (this.state.revisedShortageCharges) {
      requestObject.revisedShortagecharges = parseInt(
        this.state.revisedShortageCharges
      );
    }
    this.setState({ requestObject: requestObject });
    console.log("-", requestObject);
  };

  fire = async () => {
    await this.requestFormatter();
    console.log(this.state.requestObject);
    this.props.updateInvoice(this.state.requestObject, (res) => {
      console.log(res);
      NotificationManager.success(res["message"], "Success");
    });
  };

  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

    let {
      freightToggle,
      damageToggle,
      loadingChargesToggle,
      unloadingChargesToggle,
      revisedVehicleNonPlacementChargesToggle,
      revisedIndentRejectionChargesToggle,
      revisedCancellationUponArrivalByCompanyChargesToggle,
      revisedMultipointPickupChargesToggle,
      revisedMultipointDropChargesToggle,
      revisedSourceDetentionChargesToggle,
      revisedDestinationDetentionChargesToggle,
      revisedLateDeliveryChargesToggle,
      revisedShortageChargesToggle,
    } = this.state;
    if (
      freightToggle ||
      damageToggle ||
      loadingChargesToggle ||
      unloadingChargesToggle ||
      revisedVehicleNonPlacementChargesToggle ||
      revisedIndentRejectionChargesToggle ||
      revisedCancellationUponArrivalByCompanyChargesToggle ||
      revisedMultipointPickupChargesToggle ||
      revisedMultipointDropChargesToggle ||
      revisedSourceDetentionChargesToggle ||
      revisedDestinationDetentionChargesToggle ||
      revisedLateDeliveryChargesToggle ||
      revisedShortageChargesToggle
    ) {
      if (!this.state.saveButtonDisable)
        this.setState({ saveButtonDisable: true });
    } else {
      if (this.state.saveButtonDisable)
        this.setState({ saveButtonDisable: false });
    }
    // console.log(this.props.item);
    return (
      <React.Fragment>
        <Card
          className="mt-2 mb-2"
          index={this.props.index}
          style={{ backgroundColor: this.props.mode ? "#0AB38C" : "#ededed" }}
        >
          <div className="mt-3">
            {this.props.item.calculations.freightCharges ? (
              <div className="d-flex justify-content-lg-between">
                <div
                  className="float-left ml-5 ml-3 font-weight-bold dark__span"
                  style={{ width: "30%" }}
                >
                  {this.state.userLangs?.dashboard?.FREIGHT_COST}:
                </div>
                <div className="flex-grow-1 font-weight-bold dark__span">
                  {this.props.item.calculations.freightCharges}
                </div>
                <div className="text-right dark__span">
                  {this.state.freightToggle ? (
                    <input
                      className="mr-2 darkTransporterModalAgreement"
                      type="number"
                      style={{ width: "50%" }}
                      placeholder="revised"
                      onChange={this.freightAssigner}
                      onKeyDown={this.closeFreightInput}
                    />
                  ) : this.state.revisedFreightCost ? (
                    this.state.revisedFreightCost
                  ) : (
                    this.props.item.calculations.revisedFreightCostCharges
                  )}

                  <i
                    className="far fa-edit mr-5 ml-2 darkInvoiceModalIcons "
                    onClick={this.freightCostToggler}
                  ></i>
                </div>
                <hr />
              </div>
            ) : null}
            {this.props.item.calculations.damageCharges ? (
              <div className="d-flex justify-content-lg-between">
                <div
                  className="float-left ml-5 ml-3 font-weight-bold dark__span"
                  style={{ width: "30%" }}
                >
                  {this.state.userLangs?.invoice?.DAMAGE_CHARGES}:
                </div>
                <div className="flex-grow-1 font-weight-bold dark__span">
                  {this.props.item.calculations.damageCharges}
                </div>
                <div className="text-right dark__span">
                  {this.state.damageToggle ? (
                    <input
                      className="mr-2 darkTransporterModalAgreement"
                      type="number"
                      style={{ width: "50%" }}
                      placeholder="revised"
                      onChange={this.damageAssigner}
                      onKeyDown={this.closeDamageInput}
                    />
                  ) : this.state.revisedDamageCost ? (
                    this.state.revisedDamageCost
                  ) : (
                    this.props.item.calculations.revisedDamageCharges
                  )}
                  <i
                    className="far fa-edit mr-5 ml-2 darkInvoiceModalIcons"
                    onClick={this.damageChargesToggler}
                  ></i>
                </div>
                <hr />
              </div>
            ) : null}
            {this.props.item.calculations.loadingCharges ? (
              <div className="d-flex justify-content-lg-between">
                <div
                  className="float-left ml-5 ml-3 font-weight-bold dark__span"
                  style={{ width: "30%" }}
                >
                  {this.state.userLangs?.invoice?.DAMAGE_CHARGES}:
                </div>
                <div className="flex-grow-1 font-weight-bold dark__span">
                  {this.props.item.calculations.loadingCharges}
                </div>
                <div className="text-right dark__span">
                  {this.state.loadingChargesToggle ? (
                    <input
                      className="mr-2 darkTransporterModalAgreement"
                      type="number"
                      style={{ width: "50%" }}
                      placeholder="revised"
                      onChange={this.loadingAssigner}
                      onKeyDown={this.closeLoadingChargesInput}
                    />
                  ) : this.state.revisedLoadingCharges ? (
                    this.state.revisedLoadingCharges
                  ) : (
                    this.props.item.calculations.revisedLoadingCharges
                  )}
                  <i
                    className="far fa-edit mr-5 ml-2 darkInvoiceModalIcons"
                    onClick={this.loadingChargesToggler}
                  ></i>
                </div>
                <hr />
              </div>
            ) : null}
            {this.props.item.calculations.unloadingCharges ? (
              <div className="d-flex justify-content-lg-between">
                <div
                  className="float-left ml-5 ml-3 font-weight-bold dark__span"
                  style={{ width: "30%" }}
                >
                  {this.state.userLangs?.invoice?.UNLOADING_CHARGES}:
                </div>
                <div className="flex-grow-1 font-weight-bold dark__span">
                  {this.props.item.calculations.unloadingCharges}
                </div>
                <div className="text-right dark__span">
                  {this.state.unloadingChargesToggle ? (
                    <input
                      className="mr-2 darkTransporterModalAgreement"
                      type="number"
                      style={{ width: "50%" }}
                      placeholder="revised"
                      onChange={this.unloadingAssigner}
                      onKeyDown={this.closeUnloadingChargesInput}
                    />
                  ) : this.state.revisedUnloadingCharges ? (
                    this.state.revisedUnloadingCharges
                  ) : (
                    this.props.item.calculations.revisedUnloadingCharges
                  )}
                  <i
                    className="far fa-edit mr-5 ml-2 darkInvoiceModalIcons"
                    onClick={this.unloadingChargesToggler}
                  ></i>
                </div>
                <hr />
              </div>
            ) : null}
            {this.props.item.calculations.vehicleNonPlacementCharges ? (
              <div className="d-flex justify-content-lg-between">
                <div
                  className="float-left ml-5 ml-3 font-weight-bold dark__span"
                  style={{ width: "30%" }}
                >
                  {this.state.userLangs?.invoice?.VEHICLE_NON_PLACEMENT_CHARGES}
                  :
                </div>
                <div className="flex-grow-1 font-weight-bold dark__span">
                  {this.props.item.calculations.vehicleNonPlacementCharges}
                </div>
                <div className="text-right dark__span">
                  {this.state.revisedVehicleNonPlacementChargesToggle ? (
                    <input
                      className="mr-2 darkTransporterModalAgreement"
                      type="number"
                      style={{ width: "50%" }}
                      placeholder="revised"
                      onChange={this.vehicleNonPlacementAssigner}
                      onKeyDown={this.vehicleNonPlacementChargesInput}
                    />
                  ) : this.state.revisedVehicleNonPlacementCharges ? (
                    this.state.revisedVehicleNonPlacementCharges
                  ) : (
                    this.props.item.calculations
                      .revisedVehicleNonPlacementCharges
                  )}
                  <i
                    className="far fa-edit mr-5 ml-2 darkInvoiceModalIcons"
                    onClick={this.vehicleNonPlacementChargesToggler}
                  ></i>
                </div>
                <hr />
              </div>
            ) : null}
            {this.props.item.calculations.indentRejectionCharges ? (
              <div className="d-flex justify-content-lg-between">
                <div
                  className="float-left ml-5 ml-3 font-weight-bold dark__span"
                  style={{ width: "30%" }}
                >
                  {this.state.userLangs?.invoice?.INDENT_REJECTION_CAHRGES}:
                </div>
                <div className="flex-grow-1 font-weight-bold dark__span">
                  {this.props.item.calculations.indentRejectionCharges}
                </div>
                <div className="text-right dark__span">
                  {this.state.revisedIndentRejectionChargesToggle ? (
                    <input
                      className="mr-2 darkTransporterModalAgreement"
                      type="number"
                      style={{ width: "50%" }}
                      placeholder="revised"
                      onChange={this.indentRejectionAssigner}
                      onKeyDown={this.indentRejectionChargesInput}
                    />
                  ) : this.state.revisedIndentRejectionCharges ? (
                    this.state.revisedIndentRejectionCharges
                  ) : (
                    this.props.item.calculations.revisedIndentRejectionCharges
                  )}
                  <i
                    className="far fa-edit mr-5 ml-2 mr-3 darkInvoiceModalIcons"
                    onClick={this.indentRejectionChargesToggler}
                  ></i>
                </div>
                <hr />
              </div>
            ) : null}
            {this.props.item.calculations
              .cancellationUponArrivalByCompanyCharges ? (
              <div className="d-flex justify-content-lg-between">
                <div
                  className="float-left ml-5 ml-3 font-weight-bold dark__span"
                  style={{ width: "30%" }}
                >
                  {
                    this.state.userLangs?.invoice
                      ?.CANCELLATION_UPON_ARRIVAL_BY_COMPANY
                  }
                  :
                </div>
                <div className="flex-grow-1 font-weight-bold dark__span">
                  {
                    this.props.item.calculations
                      .cancellationUponArrivalByCompanyCharges
                  }
                </div>
                <div className="text-right dark__span">
                  {this.state
                    .revisedCancellationUponArrivalByCompanyChargesToggle ? (
                    <input
                      className="mr-2 darkTransporterModalAgreement"
                      type="number"
                      style={{ width: "50%" }}
                      placeholder="revised"
                      onChange={this.cancellationUponArrivalByCompanyAssigner}
                      onKeyDown={
                        this.cancellationUponArrivalByCompanyChargesInput
                      }
                    />
                  ) : this.state
                      .revisedCancellationUponArrivalByCompanyCharges ? (
                    this.state.revisedCancellationUponArrivalByCompanyCharges
                  ) : (
                    this.props.item.calculations
                      .revisedCancellationUponArrivalByCompanyCharges
                  )}
                  <i
                    className="far fa-edit mr-5 ml-2 darkInvoiceModalIcons"
                    onClick={
                      this.cancellationUponArrivalByCompanyChargesToggler
                    }
                  ></i>
                </div>
                <hr />
              </div>
            ) : null}
            {this.props.item.calculations.multipointPickupCharges ? (
              <div className="d-flex justify-content-lg-between">
                <div
                  className="float-left ml-5 ml-3 font-weight-bold dark__span"
                  style={{ width: "30%" }}
                >
                  {this.state.userLangs?.invoice?.MULTI_POINT_PICK_CHARGES}:
                </div>
                <div className="flex-grow-1 font-weight-bold dark__span">
                  {this.props.item.calculations.multipointPickupCharges}
                </div>
                <div className="text-right dark__span">
                  {this.state.revisedMultipointPickupChargesToggle ? (
                    <input
                      className="mr-2 darkTransporterModalAgreement"
                      type="number"
                      style={{ width: "50%" }}
                      placeholder="revised"
                      onChange={this.multipointPickupAssigner}
                      onKeyDown={this.multipointPickupChargesInput}
                    />
                  ) : this.state.revisedMultipointPickupCharges ? (
                    this.state.revisedMultipointPickupCharges
                  ) : (
                    this.props.item.calculations.revisedMultipointPickupCharges
                  )}
                  <i
                    className="far fa-edit mr-5 ml-2 darkInvoiceModalIcons"
                    onClick={this.multipointPickupChargesToggler}
                  ></i>
                </div>
                <hr />
              </div>
            ) : null}
            {this.props.item.calculations.multipointDropCharges ? (
              <div className="d-flex justify-content-lg-between">
                <div
                  className="float-left ml-5 ml-3 font-weight-bold dark__span"
                  style={{ width: "30%" }}
                >
                  {this.state.userLangs?.invoice?.MULT_POINT_DROP_CHARGES}:
                </div>
                <div className="flex-grow-1 font-weight-bold dark__span">
                  {this.props.item.calculations.multipointDropCharges}
                </div>
                <div className="text-right dark__span">
                  {this.state.revisedMultipointDropChargesToggle ? (
                    <input
                      className="mr-2 darkTransporterModalAgreement"
                      type="number"
                      style={{ width: "50%" }}
                      placeholder="revised"
                      onChange={this.multipointDropAssigner}
                      onKeyDown={this.multipointDropChargesInput}
                    />
                  ) : this.state.revisedMultipointDropCharges ? (
                    this.state.revisedMultipointDropCharges
                  ) : (
                    this.props.item.calculations.revisedMultipointDropCharges
                  )}
                  <i
                    className="far fa-edit mr-5 ml-2 darkInvoiceModalIcons"
                    onClick={this.multipointDropChargesToggler}
                  ></i>
                </div>
                <hr />
              </div>
            ) : null}
            {this.props.item.calculations.sourceDetentionCharges ? (
              <div className="d-flex justify-content-lg-between">
                <div
                  className="float-left ml-5 ml-3 font-weight-bold dark__span"
                  style={{ width: "30%" }}
                >
                  {this.state.userLangs?.invoice?.SOURCE_DETENTION_CHARGES}:
                </div>
                <div className="flex-grow-1 font-weight-bold dark__span">
                  {this.props.item.calculations.sourceDetentionCharges}
                </div>
                <div className="text-right dark__span">
                  {this.state.revisedSourceDetentionChargesToggle ? (
                    <input
                      className="mr-2 darkTransporterModalAgreement"
                      type="number"
                      style={{ width: "50%" }}
                      placeholder="revised"
                      onChange={this.sourceDetentionAssigner}
                      onKeyDown={this.sourceDetentionChargesInput}
                    />
                  ) : this.state.revisedSourceDetentionCharges ? (
                    this.state.revisedSourceDetentionCharges
                  ) : (
                    this.props.item.calculations.revisedSourceDetentionCharges
                  )}
                  <i
                    className="far fa-edit mr-5 ml-2 darkInvoiceModalIcons"
                    onClick={this.sourceDetentionChargesToggler}
                  ></i>
                </div>
                <hr />
              </div>
            ) : null}
            {this.props.item.calculations.destinationDetentionCharges ? (
              <div className="d-flex justify-content-lg-between">
                <div
                  className="float-left ml-5 ml-3 font-weight-bold dark__span"
                  style={{ width: "30%" }}
                >
                  {this.state.userLangs?.invoice?.DESTINATION_DETENTION_CHARGES}
                  :
                </div>
                <div className="flex-grow-1 font-weight-bold dark__span">
                  {this.props.item.calculations.destinationDetentionCharges}
                </div>
                <div className="text-right dark__span">
                  {this.state.revisedDestinationDetentionChargesToggle ? (
                    <input
                      className="mr-2 darkTransporterModalAgreement"
                      type="number"
                      style={{ width: "50%" }}
                      placeholder="revised"
                      onChange={this.destinationDetentionAssigner}
                      onKeyDown={this.destinationDetentionChargesInput}
                    />
                  ) : this.state.revisedDestinationDetentionCharges ? (
                    this.state.revisedDestinationDetentionCharges
                  ) : (
                    this.props.item.calculations
                      .revisedDestinationDetentionCharges
                  )}
                  <i
                    className="far fa-edit mr-5 ml-2 darkInvoiceModalIcons"
                    onClick={this.destinationDetentionChargesToggler}
                  ></i>
                </div>
                <hr />
              </div>
            ) : null}
            {this.props.item.calculations.lateDeliveryCharges ? (
              <div className="d-flex justify-content-lg-between">
                <div
                  className="float-left ml-5 ml-3 font-weight-bold dark__span"
                  style={{ width: "30%" }}
                >
                  {this.state.userLangs?.invoice?.LATE_DELIVERY_CHARGES}:
                </div>
                <div className="flex-grow-1 font-weight-bold dark__span">
                  {this.props.item.calculations.lateDeliveryCharges}
                </div>
                <div className="text-right dark__span">
                  {this.state.revisedLateDeliveryChargesToggle ? (
                    <input
                      className="mr-2 darkTransporterModalAgreement"
                      type="number"
                      style={{ width: "50%" }}
                      placeholder="revised"
                      onChange={this.lateDeliveryAssigner}
                      onKeyDown={this.lateDeliveryChargesInput}
                    />
                  ) : this.state.revisedLateDeliveryCharges ? (
                    this.state.revisedLateDeliveryCharges
                  ) : (
                    this.props.item.calculations.revisedLateDeliveryCharges
                  )}
                  <i
                    className="far fa-edit mr-5 ml-2 darkInvoiceModalIcons"
                    onClick={this.lateDeliveryChargesToggler}
                  ></i>
                </div>
                <hr />
              </div>
            ) : null}
            {this.props.item.calculations.shortageCharges ? (
              <div className="d-flex justify-content-lg-between">
                <div
                  className="float-left ml-5 ml-3 font-weight-bold dark__span"
                  style={{ width: "30%" }}
                >
                  {this.state.userLangs?.invoice?.SHORTAGE_CHARGES}:
                </div>
                <div className="flex-grow-1 font-weight-bold dark__span">
                  {this.props.item.calculations.shortageCharges}
                </div>
                <div className="text-right dark__span">
                  {this.state.revisedShortageChargesToggle ? (
                    <input
                      className="mr-2 darkTransporterModalAgreement"
                      type="number"
                      style={{ width: "50%" }}
                      placeholder="revised"
                      onChange={this.shortageAssigner}
                      onKeyDown={this.shortageChargesInput}
                    />
                  ) : this.state.revisedShortageCharges ? (
                    this.state.revisedShortageCharges
                  ) : (
                    this.props.item.calculations.revisedShortageCharges
                  )}
                  <i
                    className="far fa-edit mr-5 ml-2 darkInvoiceModalIcons"
                    onClick={this.shortageChargesToggler}
                  ></i>
                </div>
                <hr />
              </div>
            ) : null}
          </div>
          <div className="text-right">
            <MDBBtn
              className="m-1"
              color="primary"
              onClick={this.fire}
              disabled={this.state.saveButtonDisable}
            >
              {this.state.userLangs?.common?.SAVE}
            </MDBBtn>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { updateInvoice })(BillItems);
