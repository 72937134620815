// import { mapKeys } from "lodash";
import { types } from "../actionTypes";

export default (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_ROUTE_SUCCESS:
      //   // Create a new state object that uses an AJAX request response and grabs the 'id' property from each object in the response to use as its key
      return action.payload;
    default:
      state = { ...state };
      break;
  }

  return state;
};
