import React, { Component } from "react";
import Card from "@mui/material/Card";
import { Row, Col } from "reactstrap";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TripStatusDountChart from "./TripStatusDountChart";
// import { Card } from "reactstrap";
import TripStatusChildCard from "./TripStatusChildCard";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Button, Grid } from "@mui/material";
import exportDarkIcon from "../../Trips/assets/external-linkDark.png";
import Tooltip from "@material-ui/core/Tooltip";
import exportIcon from "../assets/external-link.png";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export default class TripStatusCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: false,
      export: false,
      loading: false,
    };
  }
  toggle = () => {
    this.setState((prevState) => ({
      menu: false,
    }));
  };
  toggleExcel = async () => {
    setTimeout(async () => {
      let tripStatusArr = [];
      if (this.props?.trips) {
        if (this.props?.trips !== undefined) {
          let planned = this.props.trips?.planned;
          let arrivedAtSource = this.props.trips?.arrivedAtSource;
          let qualityCheck = this.props.trips?.qualityCheckCompleted;
          let LoadingProgress = this.props.trips?.loadingInProgress;
          let shipmentVerified = this.props.trips?.shipmentVerified;
          let inTransit = this.props.trips?.inTransit;
          let arrivedAtVia = this.props.trips?.arrivedAtVia;
          let completed = this.props.trips?.completed;

          tripStatusArr.push({
            Planned: planned,
            ArrivedAtSource: arrivedAtSource,
            QualityCheckCompleted: qualityCheck,
            LoadingInProgress: LoadingProgress,
            ShipmentVerified: shipmentVerified,
            Intransit: inTransit,
            ArrivedAtVia: arrivedAtVia,
            completed: completed,
          });
        }
      }
      await this.exportToCSV([...tripStatusArr], "TRIPSTATUS_DATA_EXCEL");
    }, 2000);
    this.setState({ loading: true, export: true });
  };
  exportToCSV = (csvData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    this.setState({ export: false });
  };

  render() {
    let userPreferredLang = window.localStorage.getItem("userPreferredLang");

    const trips = this.props.trips;
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    var darkMode = mode === "dark__mode" ? true : false;
    return (
      <>
        <Card className="trip-card-div darkCardStyle">
          <div className="mt-2 pl-3">
            <Row className="pt-2">
              <Col xs="9" className="pdt5">
                <span className="roboto-font-700 span-trip-status light__span">
                  {this.props?.userLang?.dashboard?.TRIP_STATUS}
                </span>
              </Col>
              <Col xs="3">
                <span
                  className="light__span"
                  style={{
                    float: "right",
                    marginLeft: "535px",
                  }}
                >
                  <div
                    className="d-flex justify-content-end "
                    style={{
                      position: "relative",
                      right: "24px",
                    }}
                  >
                    <div className="pr-2">
                      {/* <div className=''>
                      <Dropdown
                        isOpen={this.state.menu}
                        toggle={this.toggle}
                        className='d-none d-sm-inline-block'
                      >
                        <DropdownToggle
                          style={{
                            width: '100%',
                            display: 'flex',
                            height: '26px',
                            border: '1px solid blue',

                            justifyContent: 'center',
                          }}
                          tag='button'
                          className='btn mt-0 pt-0'
                        // id="page-header-user-dropdown"
                        >
                          <span
                            style={{
                              display: 'flex',
                              marginTop: '5px',
                            }}
                            className='d-xl-inline-block ml-1 text-transform profile-name'
                          >
                            Monthly
                          </span>
                          <i
                            style={{
                              fontSize: '17px',
                            }}
                            className='mdi mdi-chevron-down ml-1 pl-1 d-xl-inline-block mt-0 profile-chervon'
                          ></i>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem></DropdownItem>

                          <DropdownItem
                            href=''
                            className='notify-item'
                          ></DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div> */}
                    </div>
                    <div>
                      <div  className={navigator.onLine ? "tooltipIcon" : "tooltipIcon disable-export"}>
                        <svg
                          className="cursor-pointer exportDark"
                          onClick={() => this.toggleExcel()}
                          width="27"
                          height="27"
                          viewBox="0 0 27 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M22.6299 0.0490417H4.62988C2.42074 0.0490417 0.629883 1.8399 0.629883 4.04904V22.049C0.629883 24.2582 2.42074 26.049 4.62988 26.049H22.6299C24.839 26.049 26.6299 24.2582 26.6299 22.049V4.04904C26.6299 1.8399 24.839 0.0490417 22.6299 0.0490417Z"
                            fill="#F1F5F7"
                          />
                          <path
                            d="M22.6299 0.299042H4.62988C2.55882 0.299042 0.879883 1.97797 0.879883 4.04904V22.049C0.879883 24.1201 2.55882 25.799 4.62988 25.799H22.6299C24.701 25.799 26.3799 24.1201 26.3799 22.049V4.04904C26.3799 1.97797 24.701 0.299042 22.6299 0.299042Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="0.5"
                          />
                          <path
                            d="M18.7559 14.133V18.983C18.7559 19.4119 18.5855 19.8232 18.2823 20.1264C17.979 20.4297 17.5677 20.6 17.1389 20.6H8.24688C7.81803 20.6 7.40674 20.4297 7.10349 20.1264C6.80024 19.8232 6.62988 19.4119 6.62988 18.983V10.091C6.62988 9.66219 6.80024 9.2509 7.10349 8.94765C7.40674 8.64441 7.81803 8.47404 8.24688 8.47404H13.0969"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M16.3311 6.04904H21.1811V10.899"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.9058 15.325L20.9058 6.32504"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span
                          className="tooltipIcontext1"
                          style={{ width: "55px" }}
                        >
                          {this.props?.userLang?.invoice?.EXPORT ?? "Export"}
                        </span>
                      </div>
                    </div>
                  </div>
                </span>
              </Col>
            </Row>
          </div>
          <hr className="mt-3 trip-status-hr-line" />
          <TripStatusChildCard
            trips={trips}
            userLang={this.props?.userLang}
            mode={darkMode}
            tripData={this.props.tripData}
          />
          <Row className="">
            <Col xs="6">
              <Row className=" ml-3 mt-1">
                <Col xs="2" className="mt-1">
                  <span className="span-tripstatus-circle1 span-trip-planned-out-circle">
                    <span className="span-tripstatus-circle2 span-operTat-planned-small-circle"></span>
                  </span>
                </Col>
                <Col xs="10" className="tripstatus-names">
                  <span className="mt-0 mobile-trip-label-pd light__span">
                    {" "}
                    {this.props?.userLang?.dashboard?.PLANNED}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col xs="6">
              <Row className=" ml-3 mt-2">
                <Col xs="2" className="mt-1">
                  <span className="span-tripstatus-circle1 span-trip-shipment-out-circle">
                    <span className="span-tripstatus-circle2 span-trip-shipment-small-circle"></span>
                  </span>
                </Col>
                <Col xs="10" className="tripstatus-names">
                  <span className="mt-0 mobile-trip-label-pd light__span">
                    {" "}
                    {this.props?.userLang?.dashboard?.SHIPMENT_VERIFIED}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-0">
            <Col xs="6">
              <Row className=" ml-3 mt-2">
                <Col xs="2" className="mt-1">
                  <span className="span-tripstatus-circle1 span-trip-arrived-out-circle">
                    <span className="span-tripstatus-circle2 span-trip-arrived-small-circle"></span>
                  </span>
                </Col>
                <Col xs="10" className="tripstatus-names">
                  <span className="mt-0  mobile-trip-label-pd light__span">
                    {this.props?.userLang?.dashboard?.ARRIVED_AT_SOURCE}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col xs="6">
              <Row className=" ml-3 mt-2">
                <Col xs="2" className="mt-1">
                  <span className="span-tripstatus-circle1 span-trip-intransist-out-circle">
                    <span className="span-tripstatus-circle2 span-trip-intransist-small-circle"></span>
                  </span>
                </Col>
                <Col xs="10" className="tripstatus-names">
                  <span className="mt-0  mobile-trip-label-pd light__span">
                    {" "}
                    {this.props?.userLang?.dashboard?.IN_TRANSIT}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-0">
            <Col xs="6">
              <Row className=" ml-3 mt-2">
                <Col xs="2" className="mt-1">
                  <span className="span-tripstatus-circle1 span-trip-qcc-out-circle">
                    <span className="span-tripstatus-circle2 span-trip-qcc-small-circle"></span>
                  </span>
                </Col>
                <Col xs="10" className="tripstatus-names">
                  <span className="mt-0  mobile-trip-label-pd light__span">
                    {this.props?.userLang?.dashboard?.QUALITY_CHECK_COMPLETED}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col xs="6">
              <Row className=" ml-3 mt-2">
                <Col xs="2" className="mt-1">
                  <span className="span-tripstatus-circle1 span-trip-arrived-out-circle">
                    <span className="span-tripstatus-circle2 span-trip-arrived-small-circle"></span>
                  </span>
                </Col>
                <Col xs="10" className="tripstatus-names">
                  <span className="mt-0  mobile-trip-label-pd light__span">
                    {this.props?.userLang?.dashboard?.ARRIVED_AT_VIA_POINT}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-0">
            <Col xs="6">
              <Row className=" ml-3 mt-2">
                <Col xs="2" className="mt-1">
                  <span className="span-tripstatus-circle1 span-trip-loading-out-circle">
                    <span className="span-tripstatus-circle2 span-trip-loading-small-circle"></span>
                  </span>
                </Col>
                <Col xs="10" className="tripstatus-names">
                  <span className="mt-0  mobile-trip-label-pd light__span">
                    {this.props?.userLang?.dashboard?.LOADING_IN_PROGRESS}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col xs="6">
              <Row className=" ml-3 mt-2">
                <Col xs="2" className="mt-1">
                  <span className="span-tripstatus-circle1 span-trip-complete-out-circle">
                    <span className="span-tripstatus-circle2 span-operTat-complete-small-circle"></span>
                  </span>
                </Col>
                <Col xs="10" className="tripstatus-names">
                  <span className="mt-0  mobile-trip-label-pd light__span">
                    {" "}
                    {this.props?.userLang?.dashboard?.COMPLETED}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </>
    );
  }
}
