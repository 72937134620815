import React, { Component } from "react";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBInput,
} from "mdbreact";
import { Label,Row, Col } from "reactstrap";
import { cancelTrip } from "../store/trip/actions";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class TripCancelModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      ReasonString: "",
      tripID: "",
      disable: true,
    };
  }
  reasonSaver = (e) => {
    this.setState({ ReasonString: e.target.value }, () => {
      if (this.state.ReasonString.length > 0) {
        this.setState({ disable: false });
      } else {
        this.setState({ disable: true });
      }
    });
  };

  componentDidUpdate = () => {
    console.log("updated");
    if (
      this.props.cancelTripDetails &&
      this.state.tripID !== this.props.cancelTripDetails._id
    ) {
      this.setState({ tripID: this.props.cancelTripDetails._id });
    }
  };

  cancelTripConfirm = () => {
    this.setState({ loading: true });
    let data = { tripId: this.state.tripID, comment: this.state.ReasonString };
    console.log("Cancelled");
    this.props.cancelTrip(data, (res) => {
      console.log(res);
      this.props.getAllData();
      if (res?.success) {
        this.props.cancelTripModal();
        NotificationManager.success(res["message"], "Success");
        this.setState({
          ReasonString: "",
          disable: true,
        });
      } else {
        NotificationManager.error(res["message"], "Error");
        this.setState({
          ReasonString: "",
          disable: true,
        });
      }
      this.setState({ loading: false });
    });
  };
  handleReset = ()=>{
    this.setState({
      ReasonString: "",
      disable: true,
     })
  }
  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

    return (
      <MDBContainer className="text-capitalize">
        <MDBModal
          size={"md"}
          isOpen={this.props.showCancelTrip}
          toggle={this.props.cancelTripModal}
          centered
          className="TripCancelModal"
        >
          <MDBModalHeader toggle={this.props.cancelTripModal}>
            <p className="fontRoboto">
              {" "}
              {userLang?.trip?.CANCEL_TRIP}
              
            </p>{" "}
            <div>
                  <div
                    className="fontRoboto1541 pt-0 "
                    style={{
                      color: "#05284E",
                    }}
                  >
                   <span className="dark__span">{userLang?.trip?.TRIP_ID} :{" "}
                {this.props?.tripIdCancelPop}</span>
                  </div>
                </div>
          </MDBModalHeader>
          <MDBModalBody>
            <span className="fontRoboto15">
              {/* Why Do You Want to Cancle Trip? */}
            </span>
            <h5 className="light__span"> {userLang?.trip?.TRIP_CANCEL_REASON} </h5>
            <MDBInput
              hint="Type Reason Here..."
              className="required mt-2 fontRoboto17 darkTexBox"
              type="textarea"
              rows="5"
              value={this.state.ReasonString ? this.state.ReasonString : ""}
              onChange={this.reasonSaver}
            />
            {/* <Label className="required-field">
              {" "}
              {userLang?.trip?.TRIP_CANCEL_REASON}
            </Label> */}
            <Row>
              <Col xs="3">
              <MDBBtn
              style={{
                width:"100%",
                height:"50px",
                backgroundColor:"#0AB38C",
                color:"#FFFFFF",
              }}
              className="float-right mt-2"
              onClick={()=>this.handleReset()}
              disabled={this.state.disable || this.state.loading}
            >
              {" "}
              {userLang?.common?.RESET}
            </MDBBtn>
              </Col>
              <Col xs="3">
              <MDBBtn
                style={{
                  width:"100%",
                  height:"50px",
                  backgroundColor:this.props.mode?"#5E40FF":"#05284E",
                  color:"#FFFFFF"
                }}
              className="float-right mt-2"
              onClick={this.cancelTripConfirm}
              disabled={this.state.disable || this.state.loading}
            >
              {" "}
              {userLang?.common?.SUBMIT}
            </MDBBtn>
              </Col>
              <Col xs="6"></Col>
            </Row>
            
          </MDBModalBody>
          {/* <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.props.cancelTripModal}>
              {userLang?.common?.CLOSE}
            </MDBBtn>
          </MDBModalFooter> */}
        </MDBModal>
      </MDBContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { cancelTrip })(TripCancelModal);
