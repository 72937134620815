import React, { Component } from "react";
import { Row, Col, Card, Button } from "reactstrap";
import { InputGroup, Input } from "reactstrap";
import MapPin from "./assets/map-pin.png";
import MapPinDk from "./assets/map-pinDrk.png";
import calendarIcon from "./assets/calendar.png";
import calendarDarkIcon from "./assets/calendarDark.png";
import download from "./assets/download.png";
import Select,{components} from "react-select";
import "./invoiceListTheme.scss";
import TextField from "@mui/material/TextField";
// import StaticDateRangePicker from "@mui/lab/StaticDateRangePicker";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Box from "@mui/material/Box";
import { ClickAwayListener } from "@material-ui/core";
import refreshIcon from "./assets/refresh-cw.png"
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";

let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
var darkMode;

const MyIcon = React.forwardRef((props, ref) => {
  return (
    <img className="invoiceListStyle" src={darkMode?calendarDarkIcon:calendarIcon} />
  );
});

const DropdownIndicator = props => {
  const iconSvg = props.selectProps.selectProps
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {iconSvg}
      </components.DropdownIndicator>
    )
  );
};



class QuickFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hops: [],
      vehicleTypes: [],
      // searchBar: [],
      searchFilters: {},
      dateValue: [null, null],
      showCalender: false,
      userLangs:undefined
    };
  }
  handleClickAway = () => {
    if (this.state.showCalender == true) {
      this.setState({ showCalender: false });
    }
  };
  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (userLang !== this.state.userLangs || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }
  render() {
    let mode = localStorage.getItem('Theme')?localStorage.getItem('Theme'):null
     darkMode = ((mode === "dark__mode") ? true : false);
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    const customStylesV2 = {
      menu: ({ width, ...css }) => ({
        ...css,
        width: "100%",
        minWidth: "100%",
        backgroundColor:darkMode?"#171722":"#ffffff",
        border: darkMode?"0.5px solid #616161":"0.5px solid rgb(204, 204, 204)",
        boxShadow: darkMode?"0px 3px 12px rgba(0, 0, 0, 0.12)" : "0px 3px 12px rgba(0, 0, 0, 0.12)",
      }),
      input: base => ({
        ...base,
        color: darkMode?"#9d9d9d":"#505d69"
      }),
      control: (style) => {
        return {
          ...style,
          height: "26px",
          width: this.props?.form?.type?.length >= 2 ? "100%" : "100%",
          minWidth: "100%",
          minHeight: "26px",
          backgroundColor:darkMode ? "#2E2E46":"",
          border: darkMode ? "1.5px solid #B7A9FF":""
        };
      },
      placeholder: (style) => {
        return {
          ...style,
          marginTop: "0px",
          height: "24px",
          minHeight: "24px",
           color:darkMode?"#ffffff":""
        };
      },
      indicatorsContainer: (style) => {
        return {
          ...style,
          // backgroundColor: "#8e9af8",
          height: "26px",
          minHeight: "26px",
          marginTop:"3px"
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "10px",
          minHeight: "10px",
          backgroundColor: "#cccccc",
          marginTop: "4px",
        };
      },
      dropdownIndicator: (style) => {
        return {
          ...style,
          height: "24px",
          minHeight: "26px",
          // backgroundColor: "#0066cc",
          marginTop: "-5px",
        };
      },

    };
  
    return (
      <React.Fragment>
        <Card className="darkBgQuickFilter">
          <div className="quickStylesBar invoiceStyle">
            <>
            <Row>
              <Col xs={0} md={6} lg={9}>
              </Col>
              <Col xs={12}  className="mobileDisplay">
                  <div className="d-flex justify-content-end">
                    <div>
                  <Button
                  style={{
                    minWidth:"70%"
                  }}
                        className="mobile-reset reset"
                        onClick={() => this.props?.reset()}
                      >
                        <img className="mobile-rest-img"
                          src={refreshIcon}
                        ></img>
                      </Button>
                      </div>
                      <div className="filter">
               
               <div className="tooltipIcon QuickFilters">
                 {/* <Button
                   onClick={() => {
                     this.setState({
                       showCalender: !this.state.showCalender,
                     });
                   }}
                   className="iconButtons mr-0"
                 >
                   <img
                     style={{ margin: "-4px" }}
                     src={calendarIcon}
                     alt="cal"
                   />
                 </Button> */}
                 <div className="datepicker-selector-mobile iconButtons mobileCalender actionButtonsDark  mr-2 datepicker-custom">
                      <DateRangePicker
                        size="md"
                        placeholder=" "
                        block
                        format="dd MMM yy"
                        showOneCalendar
                        placement="bottom"
                        preventOverflow
                        value={this.state.dateValue || [null, null]}
                        onChange={(newValue) => {
                          console.log(newValue)
                          this.props.handleChange(newValue);
                        }}
                        caretAs={MyIcon}
                      // onChange={changeHandler}
                      />
                    </div>
                 <span
                   className="tooltipIcontext"
                   style={{ width: "130px", left: "20%" }}
                 >
                   &nbsp;{this.state.userLangs?.common?.CALENDER}&nbsp;
                 </span>
               </div>
               {/* {this.state.showCalender ? (
                  <ClickAwayListener onClickAway={this.handleClickAway}>
                 <div style={{ float: "left", direction: "rtl" }}>
                   <LocalizationProvider dateAdapter={AdapterDateFns}>
                     <StaticDateRangePicker
                       displayStaticWrapperAs="desktop"
                       calendars={1}
                       format={"MM-DD-YYYY"}
                       value={this.state.dateValue}
                       onChange={(newValue) => {
                         this.setState({ dateValue: newValue }, () => {
                           if (this.state.dateValue[1] !== null) {
                             this.setState({
                               showCalender: !this.state.showCalender,
                             });
                             this.props.handleChange(this.state.dateValue);
                           }
                         });
                       }}
                       renderInput={(startProps, endProps) => (
                         <React.Fragment>
                           <TextField {...startProps} />
                           <Box sx={{ mx: 2 }}> to </Box>
                           <TextField {...endProps} />
                         </React.Fragment>
                       )}
                     />
                   </LocalizationProvider>
                 </div>
                 </ClickAwayListener>
               ) : null} */}
           
           </div>
                </div>
                    
                  </Col>
              <Col xs={12} md={3} lg={2}>
              <div className="filter">
                  <Select
                    name={this.props.name}
                    className="transpoter-react_selector"
                    classNamePrefix="react-select"
                    styles={customStylesV2}
                    style={{ width: "82%" }}
                    isMulti={false}
                    onChange={this.props.handleStateChange}
                    options={this.props.stateListOption}
                    value={this.props.stateFilter}
                    id="typeVehicleList"
                    placeholder="Select State..."
                    components={{ DropdownIndicator }}
                    selectProps={<svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
                    <path d="M12.455 6.7275C12.455 11.1825 6.727 15.0005 6.727 15.0005C6.727 15.0005 1 11.1825 1 6.7275C1 5.20847 1.60343 3.75166 2.67755 2.67755C3.75166 1.60343 5.20847 1 6.7275 1C8.24653 1 9.70334 1.60343 10.7775 2.67755C11.8516 3.75166 12.455 5.20847 12.455 6.7275V6.7275Z" stroke={darkMode?"#B7A9FF":"#2800FC"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7 9.00049C8.10457 9.00049 9 8.10506 9 7.00049C9 5.89592 8.10457 5.00049 7 5.00049C5.89543 5.00049 5 5.89592 5 7.00049C5 8.10506 5.89543 9.00049 7 9.00049Z" stroke={darkMode?"#B7A9FF":"#2800FC"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>}
                    // classNamePrefix='select2-selection'
                  />
                </div>
              </Col>
              <Col xs={3} md={2} lg={1} className="desktopDisplay">
              <div className="d-flex flex-row">
              

              <div className="filter">               
                  <div className="tooltipIcon QuickFilters">
                    {/* <Button
                      onClick={() => {
                        this.setState({
                          showCalender: !this.state.showCalender,
                        });
                      }}
                      className="iconButtons mr-2 actionButtonsDark"
                    >
                      <img
                        style={{ margin: "-4px" }}
                        src={darkMode ? calendarDarkIcon:calendarIcon}
                        alt="cal"
                      />
                    </Button> */}
                     <div className="datepicker-selector-mobile iconButtons mobileCalender actionButtonsDark  mr-2 datepicker-custom invoiceListDatePicker">
                      <DateRangePicker
                        size="md"
                        placeholder=" "
                        block
                        format="dd MMM yy"
                        showOneCalendar
                        placement="bottom"
                        preventOverflow
                        value={this.state.dateValue || [null, null]}
                        onChange={(newValue) => {
                          console.log(newValue)
                          this.props.handleChange(newValue);
                        }}
                        caretAs={MyIcon}
                      // onChange={changeHandler}
                      />
                    </div>
                    <span
                      className="tooltipIcontext"
                      style={{ width: "130px", left: "20%" }}
                    >
                      &nbsp;{this.state.userLangs?.common?.CALENDER}&nbsp;
                    </span>
                  </div>
                  {/* {this.state.showCalender ? (
                     <ClickAwayListener onClickAway={this.handleClickAway}>
                    <div style={{ float: "left", direction: "rtl" }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <StaticDateRangePicker
                          displayStaticWrapperAs="desktop"
                          calendars={1}
                          format={"MM-DD-YYYY"}
                          value={this.state.dateValue}
                          onChange={(newValue) => {
                            this.setState({ dateValue: newValue }, () => {
                              if (this.state.dateValue[1] !== null) {
                                this.setState({
                                  showCalender: !this.state.showCalender,
                                });
                                this.props.handleChange(this.state.dateValue);
                              }
                            });
                          }}
                          renderInput={(startProps, endProps) => (
                            <React.Fragment>
                              <TextField {...startProps} />
                              <Box sx={{ mx: 2 }}> to </Box>
                              <TextField {...endProps} />
                            </React.Fragment>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                    </ClickAwayListener>
                  ) : null} */}
              
              </div>
              <div className=" filter">
                {" "}
                  <Button
                    onClick={() => {
                      this.setState({ dateValue: [null, null] }, () => {
                        this.props.reset();
                      });
                    }}
                    className="reset"
                  >
                    {this.state.userLangs?.common?.RESET}
                  </Button>
                </div>
            </div>
              </Col>
            </Row>
             
            </>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default QuickFilters;
