import React, { Component } from "react";
import { Card, CardHeader, Container, CardBody } from "reactstrap";
import {
  updateBill,
  fetchBills,
  acceptInvoice,
} from "../../../store/billing/actions";
import { connect } from "react-redux";
import { PERMS } from "../../../enum/perms.enum";
import { restrict } from "../../restrict";
import BillItems from "./BillItems";
import { TextField } from "@material-ui/core";
import { MDBBtn, MDBCollapse } from "mdbreact";
import { NotificationManager } from "react-notifications";
import moment from "moment";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class InvoiceEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      bills: [],
      comment: "",
      //toggles
      revisedFreightCostToggle: false,
      //expand-Collapse
      expandIndex: "",
      userLangs: undefined,
    };
    (async () => {
      let redirect = await restrict(PERMS.TRIP_READ);
      // console.log(redirect, "redirect");

      if (redirect == false) {
        this.props.history.push("/operationsDashboard");
      }
    })();
  }

  componentDidMount = () => {
    console.log("mounted invoice editor");
    this.setState({ bills: this.props.location.state.bills }, () => {
      console.log(this.state.bills);
    });
  };

  commentAssigner = (e) => {
    this.setState({ comment: e.target.value });
  };

  submitForApproval = () => {
    let values = {
      invoiceId: this.props.location.state.id,
      status: "approval_pending",
      comment: this.state.comment,
    };
    this.props.acceptInvoice(values, (res) => {
      NotificationManager.success(res["message"], "Success");
    });

    this.props.history.push("/Billing/InvoiceList");
  };

  toggle = (index) => {
    this.setState((prevState) => ({
      expandIndex: prevState.expandIndex !== index ? index : "",
    }));
  };
  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (userLang !== this.state.userLangs || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }
  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    var darkMode = mode === "dark__mode" ? true : false;
    let bills = this.props.location.state.bills;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container
            fluid
            style={{
              backgroundColor: darkMode ? "#1B1C31" : "white",
              border: darkMode?"2px solid #616161":"none",
            }}
          >
            <Card className="darkBackgroundList">
              <div className="d-flex">
                <div md={2} className="float-left" style={{ width: "50%" }}>
                  <h5
                    className="mt-1 light__span"
                    style={{
                      fontWeight: "700",
                      lineHeight: "24px",
                      size: "16px",
                      color: "#252B3B",
                    }}
                  >
                    {this.state.userLangs?.invoice?.EDIT_INVOICE}{" "}
                  </h5>
                </div>
                <div className="float-right mt-1" style={{ width: "50%" }}>
                  <span className="light__span">
                    {this.state.userLangs?.common?.TRANSPORTER}:{" "}
                    <span className="font-weight-bold dark__span">
                      {this.props.location.state.transporter.name}
                    </span>
                  </span>
                </div>
              </div>

              <div>
                {bills.map((item, index) => {
                  return (
                    <Card key={index}>
                      <CardHeader className="darkMultipleCharges">
                        <div className="d-flex justify-content-between">
                          <div className="ml-3">
                            <span className="font-weight-bold">
                              {this.state.userLangs?.planning?.INDENT_ID}:{" "}
                              {item.indentId}
                            </span>
                          </div>

                          <div className="ml-3">
                            <span className="font-weight-bold">
                              {this.state.userLangs?.trip?.TRIP_ID}:{" "}
                              {item.trip?.tripId ? item.trip?.tripId : "N/A"}
                            </span>
                          </div>

                          <div className="ml-3">
                            <span className="font-weight-bold">
                              {this.state.userLangs?.trip?.TRIP_DATE}:{" "}
                              {item.trip?.startTime
                                ? moment(item.trip?.startTime).format(
                                    "DD/MM/YYYY"
                                  )
                                : "N/A"}
                            </span>
                          </div>

                          <div style={{ width: "20%", float: "left" }}>
                            <span className="font-weight-bold text-capitalize">
                              {this.state.userLangs?.invoice?.BILL_TYPE}:{" "}
                              {item.type.replace(/_/g, " ")}
                            </span>
                          </div>
                          <div style={{ width: "20%", float: "left" }}>
                            <span className="font-weight-bold text-capitalize">
                              {this.state.userLangs?.invoice?.TOTAL_COST}:{" "}
                              {item.calculations.netPayble}
                            </span>
                          </div>
                          <div
                            onClick={() => {
                              this.toggle(index);
                            }}
                          >
                            <MDBBtn>
                              <i className="fas fa-chevron-down"></i>
                            </MDBBtn>
                          </div>
                        </div>
                      </CardHeader>
                      <MDBCollapse
                        id={index}
                        isOpen={this.state.expandIndex === index}
                      >
                        <CardBody className="darkInvoiceEdit">
                          <BillItems
                            invoiceId={this.props.location.state.id}
                            index={index}
                            item={item}
                            mode={darkMode}
                          />
                        </CardBody>
                      </MDBCollapse>
                    </Card>
                  );
                })}
              </div>
            </Card>
            <Card>
              <div className="darkBackgroundList">
                <div >
                  <TextField
                    className="mb-1 darkCommentText"
                    style={{ width: "100%" }}
                    id="outlined-basic"
                    label={this.state.userLangs?.planning?.COMMENTS}
                    variant="outlined"
                    onChange={this.commentAssigner}
                    multiline
                    required
                  />
                </div>
                <div className="text-center mb-2">
                  <MDBBtn
                    style={{ width: "35%" }}
                    color="primary"
                    onClick={this.submitForApproval}
                    disabled={!this.state.comment}
                  >
                    {this.state.userLangs?.invoice?.SUBMIT_APPROVAL}
                  </MDBBtn>
                </div>
              </div>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {
  updateBill,
  fetchBills,
  acceptInvoice,
})(InvoiceEdit);
