import React, { Component } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { NotificationManager } from "react-notifications";
//import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";
import { MoonLoader } from "react-spinners";
import Breadcrumbs from "../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchVehicles } from "../store/vehicle/actions";
import { fetchVehicleTypesList } from "../store/vehicleType/actions";
import { MDBDataTable, MDBIcon } from "mdbreact";
import Pagination from "@material-ui/lab/Pagination";
import { getAccessForEntity } from "../pages/policies";
import { restrict } from "./restrict";
import { PERMS } from "../enum/perms.enum";
import { Grid } from "@mui/material";
import Controls from "../components/Common/Controls/Button";
import AddIcon from "@material-ui/icons/Add";
import Popup from "../components/Common/Popup";
import { debounce } from "lodash";
import VehicleForm from "../pages/Forms/VehicleForm";
var data = {};
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var limit = process.env.REACT_APP_PERPAGE;
var columns = [
  {
    label: "Owner Name",
    field: "vehicleOwnerName",
    sort: "asc",
    width: 100,
  },
  {
    label: "Reg No",
    field: "registrationNo",
    sort: "asc",
    width: 100,
  },
  // {
  //   label: "Transporter",
  //   field: "transporter",
  //   sort: "asc",
  //   width: 150,
  // },
  {
    label: "Vehicle Type",
    field: "vehicleType",
    sort: "asc",
    width: 150,
  },
];

class Vehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      form: {
        VehicleOwner: "",

        VehicleRegistrationNumber: "",
        type: "",
      },
      breadcrumbItems: [
        { title: "Vehicle", link: "#" },
        { title: "Vehicle Details", link: "#" },
      ],
      name: "",
      regNo: "",
      setOpenPopup: false,
      // disabled: true
    };
    (async () => {
      let redirect = await restrict(PERMS.VEHICLE_TRANSPORTER_READ);

      if (redirect == false) {
        this.props.history.push("/operationsDashboard");
      }
    })();
    this.handleInputDebounceName = debounce(this.getOwnerNameFunc, 1000);
    this.handleInputDebounceRegNo = debounce(this.getRegNoFunc, 1500);
  }

  componentDidMount = () => {
    this.getAllData();
    this.fetchVehicleType();
    console.log("This.state.search value", this.state.searchValue);
  };
  fetchVehicleType = (data) => {
    data = data || {};

    // console.log("func v type: ");
    this.props.fetchVehicleTypesList(
      {
        offset,
      },
      () => {}
    );
  };
  componentDidUpdate(prevProps) {}
  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };

  getAllData = () => {
    let offset = 0;
    let limit = 10;
    let q;
    this.props.fetchVehicles(offset, { q }, this.state.name, this.state.regNo);
  };

  setOpenPopupfalse = (props) => {
    this.setState({ setOpenPopup: props });
  };
  handleSubmit = (props) => {
    this.setState({ setOpenPopup: props });
    let offset = 0;
    this.setState({ loading: true });

    this.getAllData();
  };

  handleSearchName = (e, data) => {
    this.setState({ name: e?.target?.value }, () => {
      offset = 0;

      if (this.state.name.length > 2) {
        this.handleInputDebounceName();
        // this.setState({disabled:false})
      } else {
        this.handleInputDebounceName();
        // e= " ";
        // this.props.fetchVehicles(offset, { q:e });
        // this.setState({disabled:true})
      }
    });
  };

  getOwnerNameFunc = () => {
    let ownerName = this.state.name;
    let regNo = this.state.regNo;
    console.log(ownerName, "ownerName");
    let fetchValue = this.state.form?.type?.value?.id;
    this.props.fetchVehicles(offset, {
      q: "",
      vehicleTypeId: fetchValue,
      ownerName: ownerName,
      regNo: regNo,
    });
  };

  getRegNoFunc = () => {
    let ownerName = this.state.name;
    let regNo = this.state.regNo;
    let fetchValue = this.state.form?.type?.value?.id;
    this.props.fetchVehicles(offset, {
      q: "",
      vehicleTypeId: fetchValue,
      ownerName: ownerName,
      regNo: regNo,
    });
    // this.setState({disabled:false})
  };
  handleSearchVehicle = (e) => {
    this.setState({ regNo: e.target.value }, () => {
      offset = 0;

      if (this.state.regNo.length > 2) {
        this.handleInputDebounceRegNo();
      } else {
        this.handleInputDebounceRegNo();
      }
    });
  };

  reset = (e) => {
    this.setState(
      {
        name: "",
        regNo: "",
        form: { type: "" },
        // disabled: true,
      },
      () => {
        this.getAllData();
      }
    );
  };

  hasAccessPolicy(val, entity) {
    const { policies } = this.props;
    let hasAccess = false;

    if (policies && policies.length > 0) {
      hasAccess = getAccessForEntity(policies, entity, val);
    }
    return hasAccess;
  }

  handleChangePage = (event, val) => {
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    toVal = offset + process.env.REACT_APP_PERPAGE;
    let ownerName = this.state.name;
    let regNo = this.state.regNo;
    let fetchValue = this.state.form?.type?.value?.id;
    this.props.fetchVehicles(offset, {
      q: "",
      vehicleTypeId: fetchValue,
      ownerName: ownerName,
      regNo: regNo,
    });
    this.setState({ loading: true });
  };

  handleSubmitChange = (e) => {
    console.log("handleSubmitChange", typeof e.target.value, e);
    const name = e.target.name;
    let value = e.target.value;
    let formData = this.state.form;
    formData[`${name}`] = value;

    this.setState({ form: formData }, () => {});
    let fetchValue = this.state.form?.type?.value?.id;
    console.log(fetchValue, "fetch");
    let ownerName = this.state.name;
    let regNo = this.state.regNo;
    this.props.fetchVehicles(0, {
      q: " ",
      vehicleTypeId: fetchValue,
      ownerName: ownerName,
      regNo: regNo,
    });

    this.setState({ loading: true });
  };

  render() {
    const { fullScreen, vehicles } = this.props;
    const { name, regNo, disabled, form } = this.state;
    let rows = [];
    // if (loading) {
    //   return (
    //     <div className="page-content">
    //       <Col xs={12} className="loader">
    //         <MoonLoader size={45} color={"#123abc"} />
    //       </Col>
    //     </div>
    //   );
    // }

    let type = [];
    let data = this.props.vehicleType?.data?.docs;
    if (Array.isArray(data)) {
      if (data.length) {
        data.forEach((ele) => {
          type.push({
            label: ele.label + " - " + ele.vehicleTypeCode,
            value: {
              id: ele._id,
              // latitude: ele.cordinates ? ele.cordinates[0] : null,
              // longitude: ele.cordinates ? ele.cordinates[1] : null,
            },
          });
        });
      }
    }
    console.log(type, "type");

    if (Array.isArray(vehicles?.vehicles?.data?.docs)) {
      console.log("vehicle: ", vehicles.vehicles.data.docs);
      offset = this.props.vehicles?.vehicles.data?.offset;
      totCnt = this.props.vehicles?.vehicles?.data?.length;
      toVal =
        this.props.vehicles?.vehicles?.data?.offset +
        this.props.vehicles?.vehicles?.data?.docs?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      vehicles.vehicles.data.docs.forEach((ele) => {
        rows.push({
          vehicleOwnerName: ele.ownerName,
          registrationNo: ele.registration.number,
          transporter: ele.transporter,
          vehicleType: ele.type.label,
        });
      });
    }

    data = {
      columns: columns,
      rows: rows,
    };

    return (
      <React.Fragment>
        <div /* className="page-content" */>
          <Container fluid>
            <div className="d-flex bd-highlight">
              <div className="p-2 flex-grow-1 bd-highlight">
                <h5
                  className="mt-1"
                  style={{
                    fontWeight: "700",
                    lineHeight: "20px",
                    size: "16px",
                    color: "#808080",
                  }}
                >
                  Vehicle Master{" "}
                </h5>
              </div>
              <div className="p-2 bd-highlight">
                <Controls
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    this.setState({ setOpenPopup: true });
                  }}
                  icon={<AddIcon style={{ color: "#2800fc" }} />}
                ></Controls>
              </div>
            </div>

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Grid container alignItems="center" className="mb-3">
                      <Grid item xs={12} sm={8} md={2}>
                        <div className="pb-1 mr-4">
                          <Label>Owner Name</Label>
                          <input
                            style={{ height: "37px" }}
                            className="form-control searchBox form-control-sm sharp-corners-right rounded-left"
                            type="text"
                            name="name"
                            value={name}
                            onChange={(e) => this.handleSearchName(e, data)}
                            placeholder="Search"
                            aria-label="Search"
                            icon="fas fa-times-circle"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={8} md={2}>
                        <div className="pb-1 mr-4">
                          <Label>Reg No</Label>
                          <input
                            style={{ height: "37px" }}
                            className="form-control searchBox form-control-sm sharp-corners-right rounded-left"
                            type="text"
                            name="name"
                            value={regNo}
                            onChange={(e) => this.handleSearchVehicle(e)}
                            placeholder="Search"
                            aria-label="Search"
                            icon="fas fa-times-circle"
                          />
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={8} md={3}>
                        <div className="pb-1 mr-4">
                          <>
                            <Label>Vehicle Type </Label>
                            <Select
                              name="type"
                              value={form?.type || null}
                              onChange={(e) => {
                                this.handleSubmitChange({
                                  target: {
                                    name: "type",
                                    value: e,
                                  },
                                });
                              }}
                              options={type}
                            />
                          </>
                        </div>
                      </Grid>

                      <Grid item className="ml-2 mt-2">
                        <Button
                          style={{
                            color: "#333333 !important",
                            marginTop: "14px",
                          }}
                          className="rounded-black button-wide"
                          onClick={this.reset}
                          // disabled={disabled}
                        >
                          Reset
                        </Button>
                      </Grid>
                    </Grid>

                    <MDBDataTable
                      className="text-center"
                      responsive
                      striped
                      bordered
                      data={data}
                      searching={false}
                      paging={false}
                    />
                    {totCnt !== 0 ? (
                      <Row xs={12}>
                        <Col xs={6}>
                          <span>
                            Showing {fromVal} to {toVal} of {totCnt} entries
                          </span>
                        </Col>
                        <Col xs={6}>
                          <div className="float-right">
                            <Pagination
                              shape="rounded"
                              page={offset / 10 + 1}
                              count={totPages}
                              color="primary"
                              onChange={this.handleChangePage}
                            />
                          </div>
                        </Col>
                      </Row>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>

          <Popup
            title="Add Vehicle"
            openPopup={this.state.setOpenPopup}
            setOpenPopupfalse={this.setOpenPopupfalse}
          >
            <VehicleForm handleSubmit={this.handleSubmit} />
          </Popup>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  vehicles: state.Vehicle.vehicles,
  policies: state.AccessPolicy.accessPolicies.policies,
  vehicleType: state.VehicleType.vehicleType.vehicleType,
});

export default connect(mapStateToProps, {
  fetchVehicles,
  fetchVehicleTypesList,
})(Vehicle);
