import React, { Component } from "react";
import { Label, FormGroup, Row, Col, Input } from "reactstrap";
import Datetime from "react-datetime";
import { ErrorMessage } from "formik";
import moment from "moment";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class ChargesStartEndDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      charges: "",
      endDate: "",
    };
    this.startdate = React.createRef();
    this.expirydate = React.createRef();
  }

  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

    return (
      <React.Fragment>
        <Row>
          <Col xs={12} md={4}>
            <FormGroup className="darkAgreementVehicleCharge">
              <Label className="col-form-label required-field light__span">
                {userLang?.invoice?.CHARGES}{" "}
              </Label>
              <Input
                value={this.props.chargeVal}
                type="number"
                onChange={this.props.handleChange}
                id="charge"
                placeholder="Enter Charges"
                name="charges"
                className={
                  this.props.errors.charges && this.props.touched.charges
                    ? "input-error"
                    : null
                }
              />
              <ErrorMessage name="charges" component="span" className="error" />
            </FormGroup>
          </Col>
          <Col xs={12} md={4}>
            <div className="d-flex">
              <FormGroup className="select2-container required darkAgreementVehicleCharge">
                <Label className="col-form-label required-field light__span">
                  {" "}
                  {userLang?.common?.START_DATE}
                </Label>
                <div className="d-flex">
                  <Datetime
                    value={this.props.startDateVal}
                    name="startdate"
                    timeFormat={false}
                    isValidDate={this.props.valid}
                    onChange={(e) => {
                      this.props.setFieldValue(
                        "startdate",
                        moment(e).format("l")
                      );
                      console.log("DATE", e);
                    }}
                    options={[]}
                    defaultValue={new Date()}
                    input={true}
                    id="startdate"
                    ref={this.startdate}
                    className={
                      this.props.errors.startdate &&
                      this.props.touched.startdate
                        ? "input-error"
                        : null
                    }
                  />
                  <div className="d-flex align-items-center justify-content-end icon-cont">
                    <span
                      className="py-2 px-2 darkCalenderCharges"
                      onClick={() => {
                        if (this.startdate.current.state.open == false) {
                          this.startdate.current._openCalendar();
                        } else {
                          this.startdate.current._closeCalendar();
                        }
                      }}
                    >
                      <i className="far fa-calendar fa-lg"></i>
                    </span>
                  </div>
                </div>
                <ErrorMessage
                  name="startdate"
                  component="span"
                  className="error"
                />
              </FormGroup>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="d-flex">
              <FormGroup className="select2-container required darkAgreementVehicleCharge">
                <Label className="col-form-label required-field light__span">
                  {" "}
                  {userLang?.common?.EXPIRY_DATE}
                </Label>
                <div className="d-flex">
                  <Datetime
                    value={this.props.endDateVal}
                    name="expirydate"
                    timeFormat={false}
                    isValidDate={this.props.validExpiry}
                    onChange={(e) => {
                      this.props.setFieldValue(
                        "expirydate",
                        moment(e).format("l")
                      );
                      console.log(moment(e).format("DD-MMM-YYYY"));
                    }}
                    options={[]}
                    defaultValue={new Date()}
                    input={true}
                    id="expirydate"
                    ref={this.expirydate}
                    className={
                      this.props.errors.expirydate &&
                      this.props.touched.expirydate
                        ? "input-error"
                        : null
                    }
                  />
                  <div className="d-flex align-items-center justify-content-end icon-cont">
                    <span
                      className="py-2 px-2 darkCalenderCharges"
                      onClick={() => {
                        if (this.expirydate.current.state.open == false) {
                          this.expirydate.current._openCalendar();
                        } else {
                          this.expirydate.current._closeCalendar();
                        }
                      }}
                    >
                      <i className="far fa-calendar fa-lg"></i>
                    </span>
                  </div>
                </div>
                <ErrorMessage
                  name="expirydate"
                  component="span"
                  className="error"
                />
              </FormGroup>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
export default ChargesStartEndDate;
