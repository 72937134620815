// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { types } from "./actionTypes";

import {
  deleteApi,
  getApi,
  patchApi,
  postApi,
  uploadExcel
} from "../../helpers/auth_helper";

function* workfetchDealers({ offset, callback }) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/dealer/dealers?offset=` +
      offset +`&limit=`+process.env.REACT_APP_PERPAGE;
    const response = yield call(getApi, uri);
    callback(response);
    //console.log('FETCH_DEALERS',response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_DEALERS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch dealer.", error);
  }
}
function* workfetchDealersfilter({ offset,filters,key, callback }) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/dealer/dealers?offset=` +
      offset +`&filters=`+filters+`&key=`+key;
      console.log(uri);
    const response = yield call(getApi, uri);
    callback(response);
    //console.log('FETCH_DEALERS',response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_DEALERS_FILTER_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch dealer.", error);
  }
}
function* workfetchAvailDealers() {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/dealer/dealers`;
    const response = yield call(getApi, uri);
    //console.log('FETCH_DEALERS',response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_DEALERS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch dealer.", error);
  }
}
function* workCreateDealer({ values, callback }) {
  try {
    //console.log('values', values);
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/dealer/create`;
    const response = yield call(postApi, uri, values);
    //console.log('response',response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.CREATE_DEALER_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch dealer.", error);
  }
}

function* workEditDealerById({ values, callback }) {
  try {
    // console.log("values", values);
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL + `/dealer/update/` + values.dealerID;
    const response = yield call(patchApi, uri, values);
    //console.log('response',response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.EDIT_DEALER_BYID_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch dealer.", error);
  }
}

function* workFetchDealerById({ id, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/dealer/dealer/` + id;
    const response = yield call(getApi, uri);
    //console.log('response',response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_DEALER_BYID_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch dealer.", error);
  }
}
function* workUploadDealer({ values, callback }) {
  try {
    //console.log('values', values);
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL + "/upload/csv-dealer-upload/single";
    const response = yield call(uploadExcel, uri, values);
    // console.log(response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.UPLOAD_DEALERS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch driver.", error);
  }
}
function* workDeleteDealer({ id, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/dealer/delete/` + id;
    const response = yield call(deleteApi, uri);
    //console.log('response',response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.DELETE_DEALER_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch dealer.", error);
  }
}
/**
 * Watchers
 */

export function* watchFetchDealers() {
  yield takeEvery(types.FETCH_DEALERS, workfetchDealers);
}
export function* watchFetchDealersfilter() {
  yield takeEvery(types.FETCH_FILTER_DEALERS, workfetchDealersfilter);
}
export function* watchFetchAvailDealers() {
  yield takeEvery(types.FETCH_AVAIL_DEALERS, workfetchAvailDealers);
}
export function* watchCreateDealer() {
  yield takeEvery(types.CREATE_DEALER, workCreateDealer);
}
export function* watchEditDealerById() {
  yield takeEvery(types.EDIT_DEALER_BYID, workEditDealerById);
}
export function* watchFetchDealerById() {
  yield takeEvery(types.FETCH_DEALER_BYID, workFetchDealerById);
}
export function* watchUploadDealer() {
  yield takeEvery(types.UPLOAD_DEALERS, workUploadDealer);
}
export function* watchDeleteDealer() {
  yield takeEvery(types.DELETE_DEALER, workDeleteDealer);
}
function* DealerSaga() {
  yield all([
    fork(watchFetchDealers),
    fork(watchFetchDealersfilter),
    fork(watchFetchAvailDealers),
    fork(watchCreateDealer),
    fork(watchEditDealerById),
    fork(watchDeleteDealer),
    fork(watchUploadDealer),
    fork(watchFetchDealerById),
  ]);
}

export default DealerSaga;
