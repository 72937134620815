import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Row, Col, Button } from "reactstrap";
import "./ProfileSettings.scss";
import {
  fetchAllNotificationData,
  updateAllNotificationData,
  updateUserPassword,
} from "../../store/actions";
import { NotificationManager } from "react-notifications";
import { emptyObject } from "./NotificationTypeMaster";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
class ProfileSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetail: {},
      isShownPass: false,
      isShownConfirmPass: false,
      password: "",
      confirmPassword: "",
      disablePasswordSave: "",
      disablePasswordReset: "",
      IndentNotificationDataArray: [],
      TripNotificationDataArray: [],
      InvoiceNotificationDataArray: [],
      payloadData: {},

      indentGlobalSwitchValue_inApp: false,
      indentGlobalSwitchValue_sms: false,
      indentGlobalSwitchValue_whatsapp: false,
      indentGlobalSwitchValue_email: false,
      //
      tripGlobalSwitchValue_inApp: false,
      tripGlobalSwitchValue_sms: false,
      tripGlobalSwitchValue_whatsapp: false,
      tripGlobalSwitchValue_email: false,
      //
      invoiceGlobalSwitchValue_inApp: false,
      invoiceGlobalSwitchValue_sms: false,
      invoiceGlobalSwitchValue_whatsapp: false,
      invoiceGlobalSwitchValue_email: false,
    };
  }

  hopRender = (hopData) => {
    return (
      <Row style={{ display: "flex", padding: "0 54px" }}>
        {hopData?.map((e, i) => (
          <Col key={i}>
            <Row>
              {i ? (
                <Col style={{ padding: "0 2px", marginTop: "36px" }}>
                  <hr style={{ borderTop: "dashed 3px" }} />
                </Col>
              ) : null}

              {i % 2 ? (
                <Col
                  xs={2}
                  className="text-left d-flex align-items-center flex-nowrap mobileRoute"
                  style={{ marginTop: "-6px" }}
                >
                  <div style={{ paddingTop: "0", flexBasis: "min-content" }}>
                    <div className="hopLabel">
                      <span className="ml-2">{e.label}</span>
                    </div>
                    <div className="hopDot">
                      {" "}
                      <i
                        style={{ color: "#5664D2" }}
                        className="fas fa-circle iconSize"
                      ></i>
                    </div>
                  </div>
                </Col>
              ) : (
                <Col
                  xs={2}
                  className="text-left d-flex align-items-center flex-nowrap mobileRoute"
                  style={{ marginTop: "36px" }}
                >
                  <div style={{ paddingTop: "8px", flexBasis: "min-content" }}>
                    <div className="hopDot">
                      {" "}
                      <i
                        style={{ color: "#5664D2" }}
                        className="fas fa-circle iconSize"
                      ></i>
                    </div>
                    <div className="hopLabel">
                      <span className="ml-2">{e.label}</span>
                    </div>
                  </div>
                </Col>
              )}
              {i !== hopData.length - 1 ? (
                <Col style={{ padding: "0 0", marginTop: "36px" }}>
                  <hr style={{ borderTop: "dashed 3px" }} />
                </Col>
              ) : null}
            </Row>
          </Col>
        ))}
      </Row>
    );
  };

  changeValueToggle = (item, index, value, category, notificationItem) => {
    let notificationItemValue;
    if (notificationItem === "sms") {
      notificationItemValue = 0;
    } else if (notificationItem === "inApp") {
      notificationItemValue = 1;
    } else if (notificationItem === "email") {
      notificationItemValue = 2;
    } else if (notificationItem === "whatsapp") {
      notificationItemValue = 3;
    }
    let {
      IndentNotificationDataArray,
      TripNotificationDataArray,
      InvoiceNotificationDataArray,
    } = this.state;
    if (category === "Indent") {
      let key = notificationItem;
      if (notificationItem === "sms") {
        IndentNotificationDataArray[index][Object.keys(item)[0]].sms =
          !IndentNotificationDataArray[index][Object.keys(item)[0]].sms;
      } else if (notificationItem === "inApp") {
        IndentNotificationDataArray[index][Object.keys(item)[0]].inApp =
          !IndentNotificationDataArray[index][Object.keys(item)[0]].inApp;
      } else if (notificationItem === "email") {
        IndentNotificationDataArray[index][Object.keys(item)[0]].email =
          !IndentNotificationDataArray[index][Object.keys(item)[0]].email;
      } else if (notificationItem === "whatsapp") {
        IndentNotificationDataArray[index][Object.keys(item)[0]].whatsapp =
          !IndentNotificationDataArray[index][Object.keys(item)[0]].whatsapp;
      }
    } else if (category === "Trips") {
      let key = notificationItem;
      if (notificationItem === "sms") {
        TripNotificationDataArray[index][Object.keys(item)[0]].sms =
          !TripNotificationDataArray[index][Object.keys(item)[0]].sms;
      } else if (notificationItem === "inApp") {
        TripNotificationDataArray[index][Object.keys(item)[0]].inApp =
          !TripNotificationDataArray[index][Object.keys(item)[0]].inApp;
      } else if (notificationItem === "email") {
        TripNotificationDataArray[index][Object.keys(item)[0]].email =
          !TripNotificationDataArray[index][Object.keys(item)[0]].email;
      } else if (notificationItem === "whatsapp") {
        TripNotificationDataArray[index][Object.keys(item)[0]].whatsapp =
          !TripNotificationDataArray[index][Object.keys(item)[0]].whatsapp;
      }
    } else if (category === "Invoice") {
      if (notificationItem === "sms") {
        InvoiceNotificationDataArray[index][Object.keys(item)[0]].sms =
          !InvoiceNotificationDataArray[index][Object.keys(item)[0]].sms;
      } else if (notificationItem === "inApp") {
        InvoiceNotificationDataArray[index][Object.keys(item)[0]].inApp =
          !InvoiceNotificationDataArray[index][Object.keys(item)[0]].inApp;
      } else if (notificationItem === "email") {
        InvoiceNotificationDataArray[index][Object.keys(item)[0]].email =
          !InvoiceNotificationDataArray[index][Object.keys(item)[0]].email;
      } else if (notificationItem === "whatsapp") {
        InvoiceNotificationDataArray[index][Object.keys(item)[0]].whatsapp =
          !InvoiceNotificationDataArray[index][Object.keys(item)[0]].whatsapp;
      }
    }

    let indent = {};
    let trip = {};
    let invoice = {};
    let data = {};
    this.setState(
      {
        IndentNotificationDataArray: IndentNotificationDataArray,
        TripNotificationDataArray: TripNotificationDataArray,
        InvoiceNotificationDataArray: InvoiceNotificationDataArray,
      },
      () => {
        this.state.IndentNotificationDataArray.forEach((item) => {
          let key = Object.keys(item)[0];
          indent = { ...indent, [key]: item[Object.keys(item)[0]] };
        });

        this.state.TripNotificationDataArray.forEach((item) => {
          let key = Object.keys(item)[0];
          trip = { ...trip, [key]: item[Object.keys(item)[0]] };
        });
        this.state.InvoiceNotificationDataArray.forEach((item) => {
          let key = Object.keys(item)[0];

          invoice = { ...invoice, [key]: item[Object.keys(item)[0]] };
        });
        data = {
          userId: this.state.userDetail?.data?._id,
          indent,
          trip,
          invoice,
        };

        this.setState({ payloadData: data }, () => {
          this.props.updateAllNotificationData(
            this.state.payloadData,
            (res) => {}
          );
        });
      }
    );
  };

  renderSwitch = (item, index, value, category, notificationItem) => {
    return (
      <div className="toggle-switch ">
        <input
          type="checkbox"
          className="toggle-switch-checkbox"
          name={notificationItem + "_" + index + "_" + category}
          id={notificationItem + "_" + index + "_" + category}
          onClick={() =>
            this.changeValueToggle(
              item,
              index,
              value,
              category,
              notificationItem
            )
          }
          checked={value}
        />
        <label
          className="toggle-switch-label"
          htmlFor={notificationItem + "_" + index + "_" + category}
        >
          <span className="toggle-switch-inner" />
          <span className="toggle-switch-switch" />
        </label>
      </div>
    );
  };
  changeValueToggleHead = (category, item) => {
    let IndentNotificationDataArray = this.state.IndentNotificationDataArray;
    if (category === "indents") {
      IndentNotificationDataArray.forEach((i) => {
        i[Object.keys(i)[0]][item] = !i[Object.keys(i)[0]][item];
      });
      this.setState(
        {
          IndentNotificationDataArray: IndentNotificationDataArray,
        },
        () => {
          if (item === "inApp") {
            this.setState({
              indentGlobalSwitchValue_inApp:
                !this.state.indentGlobalSwitchValue_inApp,
            });
          }
        }
      );
    }
  };

  renderSwitchHead = (category, item) => {
    let value;
    if (category === "indents")
      if (item === "inApp") value = this.state.indentGlobalSwitchValue_inApp;
      else if (item === "sms") value = this.state.indentGlobalSwitchValue_sms;
      else if (item === "whatsapp")
        value = this.state.indentGlobalSwitchValue_whatsapp;
      else if (item === "email")
        value = this.state.indentGlobalSwitchValue_email;
    if (category === "trips")
      if (item === "inApp") value = this.state.tripGlobalSwitchValue_inApp;
      else if (item === "sms") value = this.state.tripGlobalSwitchValue_sms;
      else if (item === "whatsapp")
        value = this.state.tripGlobalSwitchValue_whatsapp;
      else if (item === "email") value = this.state.tripGlobalSwitchValue_email;
    if (category === "invoice")
      if (item === "inApp") value = this.state.invoiceGlobalSwitchValue_inApp;
      else if (item === "sms") value = this.state.invoiceGlobalSwitchValue_sms;
      else if (item === "whatsapp")
        value = this.state.invoiceGlobalSwitchValue_whatsapp;
      else if (item === "email")
        value = this.state.invoiceGlobalSwitchValue_email;
    return (
      <div className="toggle-switch">
        <input
          type="checkbox"
          className="toggle-switch-checkbox"
          name={item + "_" + category + "_Head"}
          id={item + "_" + category + "_Head"}
          onClick={() => this.changeValueToggleHead(category, item)}
          checked={value}
        />
        <label
          className="toggle-switch-label"
          htmlFor={item + "_" + category + "_Head"}
        >
          <span className="toggle-switch-inner" />
          <span className="toggle-switch-switch" />
        </label>
      </div>
      // </div>
    );
  };

  loadData = (res) => {
    let IndentNotificationDataArray = [];
    let TripNotificationDataArray = [];
    let InvoiceNotificationDataArray = [];
    let indent = res.data.indent;
    let trip = res.data.trip;
    let invoice = res.data.invoice;
    for (var key of Object.keys(indent)) {
      IndentNotificationDataArray.push({ [key]: indent[key] });
    }
    for (var key of Object.keys(trip)) {
      TripNotificationDataArray.push({ [key]: trip[key] });
    }
    for (var key of Object.keys(invoice)) {
      InvoiceNotificationDataArray.push({ [key]: invoice[key] });
    }

    this.setState({
      IndentNotificationDataArray: IndentNotificationDataArray,
      TripNotificationDataArray: TripNotificationDataArray,
      InvoiceNotificationDataArray: InvoiceNotificationDataArray,
    });
  };

  componentDidMount = () => {
    let data;

    let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let userDetail = JSON.parse(window.localStorage.getItem("authUser"));

    this.setState(
      {
        userDetail: userDetail,
        permissions: userDetail?.data?.permissions,
      },
      () => {
        data = this.state.userDetail?.data?._id;

        this.props.fetchAllNotificationData(data, (fetchRes) => {
          if (fetchRes.data === null) {
            console.log("ALLdata", fetchRes);
            emptyObject.userId = this.state.userDetail?.data?._id;
            this.props.updateAllNotificationData(emptyObject, (res) => {
              console.log("Upadte", res);
              this.props.fetchAllNotificationData(data, (updateRes) => {
                this.loadData(updateRes);
              });
            });
          } else {
            this.loadData(fetchRes);
          }
        });
      }
    );
  };

  componentDidUpdate = () => {
    var indentGlobalSwitchValue_inAppTrueFlag = false;
    this.state.IndentNotificationDataArray.map((item, index) => {
      for (var key of Object.keys(item)) {
        let subItem = item[key];
        for (var key of Object.keys(subItem)) {
          if (key === "inApp")
            if (
              this.state.indentGlobalSwitchValue_inApp === false &&
              subItem[key]
            ) {
              this.setState({ indentGlobalSwitchValue_inApp: true });
              // indentGlobalSwitchValue_inAppTrueFlag = true;
            }
          if (key === "sms")
            if (
              this.state.indentGlobalSwitchValue_sms === false &&
              subItem[key]
            ) {
              this.setState({ indentGlobalSwitchValue_sms: true });
            }
          if (key === "whatsapp")
            if (
              this.state.indentGlobalSwitchValue_whatsapp === false &&
              subItem[key]
            ) {
              this.setState({ indentGlobalSwitchValue_whatsapp: true });
            }
          if (key === "email")
            if (
              this.state.indentGlobalSwitchValue_email === false &&
              subItem[key]
            ) {
              this.setState({ indentGlobalSwitchValue_email: true });
            }
        }
      }
    });
    // if (
    //   indentGlobalSwitchValue_inAppTrueFlag !==
    //   this.state.indentGlobalSwitchValue_inApp
    // ) {
    //   this.setState({
    //     indentGlobalSwitchValue_inApp: indentGlobalSwitchValue_inAppTrueFlag,
    //   });
    // }
    this.state.TripNotificationDataArray.map((item, index) => {
      for (var key of Object.keys(item)) {
        let subItem = item[key];
        for (var key of Object.keys(subItem)) {
          if (key === "inApp")
            if (
              this.state.tripGlobalSwitchValue_inApp === false &&
              subItem[key]
            ) {
              this.setState({ tripGlobalSwitchValue_inApp: true });
            }
          if (key === "sms")
            if (
              this.state.tripGlobalSwitchValue_sms === false &&
              subItem[key]
            ) {
              this.setState({ tripGlobalSwitchValue_sms: true });
            }
          if (key === "whatsapp")
            if (
              this.state.tripGlobalSwitchValue_whatsapp === false &&
              subItem[key]
            ) {
              this.setState({ tripGlobalSwitchValue_whatsapp: true });
            }
          if (key === "email")
            if (
              this.state.tripGlobalSwitchValue_email === false &&
              subItem[key]
            ) {
              this.setState({ tripGlobalSwitchValue_email: true });
            }
        }
      }
    });
    this.state.InvoiceNotificationDataArray.map((item, index) => {
      for (var key of Object.keys(item)) {
        let subItem = item[key];
        for (var key of Object.keys(subItem)) {
          if (key === "inApp")
            if (
              this.state.invoiceGlobalSwitchValue_inApp === false &&
              subItem[key]
            ) {
              this.setState({ invoiceGlobalSwitchValue_inApp: true });
            }
          if (key === "sms")
            if (
              this.state.invoiceGlobalSwitchValue_sms === false &&
              subItem[key]
            ) {
              this.setState({ invoiceGlobalSwitchValue_sms: true });
            }
          if (key === "whatsapp")
            if (
              this.state.invoiceGlobalSwitchValue_whatsapp === false &&
              subItem[key]
            ) {
              this.setState({ invoiceGlobalSwitchValue_whatsapp: true });
            }
          if (key === "email")
            if (
              this.state.invoiceGlobalSwitchValue_email === false &&
              subItem[key]
            ) {
              this.setState({ invoiceGlobalSwitchValue_email: true });
            }
        }
      }
    });
  };

  passwordChange = (e, fieldString) => {
    if (fieldString === "password") {
      console.log(e.target.value, "====", fieldString);
      this.setState({ password: e.target.value });
    }
    if (fieldString === "confirmPassword") {
      console.log(e.target.value, "==!==", fieldString);
      this.setState({ confirmPassword: e.target.value });
    }
  };

  passwordUpdateTrigger = () => {
    let data = {
      userId: this.state.userDetail.data._id,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
    };
    this.props.updateUserPassword(data, (res) => {
      console.log(res, "uuu");
      if (res.success === false) {
        NotificationManager.error(res["message"], "Error");
      } else {
        NotificationManager.success(res["message"], "Success");
        this.setState({ password: "", confirmPassword: "" });
      }
    });
  };

  passwordFieldReset = () => {
    this.setState({ password: "", confirmPassword: "" });
  };

  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let {
      isShownPass,
      isShownConfirmPass,
      IndentNotificationDataArray,
      TripNotificationDataArray,
      InvoiceNotificationDataArray,
    } = this.state;
    const submitButtonSpanStyle = {
      margin: "auto",
      fontWeight: "normal",
      fontSize: "15px",
      fontStyle: "normal",
      fontFamily: "Verdana, sans-serif",
      color: "#FFFFFF",
    };

    const resetButtonSpanStyle = {
      margin: "auto",
      fontWeight: "normal",
      fontSize: "15px",
      fontStyle: "normal",
      fontFamily: "Verdana, sans-serif",
      color: "#FFFFFF",
    };

    const resetButtonStyle = {
      backgroundColor: "#0AB38C",
      borderRadius: "3px",
      height: "50px",
      width: "120px",
      margin: "auto",
      marginRight: "9px",
    };
    const submitButtonStyle = {
      backgroundColor: "#05284E",
      borderRadius: "3px",
      height: "50px",
      width: "120px",
      margin: "auto",
      marginRight: "9px",
    };
    return (
      <React.Fragment>
        <Card className="ProfileMainCards darkCardStyle">
          <div className="CardHeading light__span">{userLang?.common?.USER_DETAILS??'User Details'}</div>
          <hr className="lineStraight lineHr" />
          <Row>
            <Col xs={12} md={6}>
              <Row>
                <Col>
                  <div className="CardSubHeadings light__span">{userLang?.common?.USER_NAME??'Name'}</div>
                  <div>
                    <input
                      className="CardInputFields darkTexBox light__span"
                      value={this.state.userDetail?.data?.name}
                      readonly
                    />
                  </div>
                </Col>
                <Col>
                  <div className="CardSubHeadings light__span">{userLang?.common?.PHONE??'Phone'}</div>
                  <div>
                    <input
                      className="CardInputFields darkTexBox light__span"
                      value={
                        this.state.userDetail?.data?.phone
                          ? this.state.userDetail?.data?.phone
                          : userLang?.common?.NO_DATA??'No Data'
                      }
                      readonly
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="CardSubHeadings light__span">{userLang?.common?.EMAIL??'Email'}</div>
                  <input
                    className="CardInputFields darkTexBox light__span"
                    value={this.state.userDetail?.data?.email}
                    readonly
                  />
                </Col>
                <Col>
                  <div className="CardSubHeadings light__span">{userLang?.common?.ROLE??'Role'}</div>
                  <div>
                    <input
                      className="CardInputFields darkTexBox light__span"
                      value={this.state.userDetail?.data?.role?.label}
                      readonly
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6}>
              <div className="CardSubHeadings light__span">{userLang?.common?.HOP??'Hop'}</div>
              <Card className="HopCard darkTexBox light__span">
                <div>
                  {this.hopRender(this.state.userDetail?.data?.hopDetails)}
                </div>
              </Card>
            </Col>
          </Row>
        </Card>
        <Card className="ProfileMainCards darkCardStyle">
          <div className="CardHeading light__span">{userLang?.common?.UPDATE_PASSWORD??'Update Password'}</div>
          <hr className="lineStraight lineHr" />
          <Row>
            <Col xs={12} md={4}>
              <div className="CardSubHeadings light__span">{userLang?.common?.NEW_PASSWORD??'New Password'}</div>
              <div>
                <input
                  className="CardPasswordFields"
                  value={this.state.password}
                  placeholder={userLang?.common?.NEW_PASSWORD??'New Password'}
                  onChange={(e) => {
                    this.passwordChange(e, "password");
                  }}
                  type={isShownPass ? "text" : "password"}
                />
                <Button
                  style={{ width: "10%" }}
                  className="PasswordShowButtons"
                  onClick={() => {
                    this.setState({ isShownPass: !this.state.isShownPass });
                  }}
                >
                  <i
                    style={{ color: "#B9B9B9" }}
                    className="ri-eye-off-line"
                  ></i>
                </Button>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="CardSubHeadings light__span">{userLang?.common?.CONFIRM_PASSWORD??'Confirm Password'}</div>
              <div>
                <input
                  className="CardPasswordFields"
                  value={this.state.confirmPassword}
                  placeholder={userLang?.common?.CONFIRM_PASSWORD??'Confirm Password'}
                  onChange={(e) => {
                    this.passwordChange(e, "confirmPassword");
                  }}
                  type={isShownConfirmPass ? "text" : "password"}
                />
                <Button
                  style={{ width: "10%" }}
                  className="PasswordShowButtons"
                  onClick={() => {
                    this.setState({
                      isShownConfirmPass: !this.state.isShownConfirmPass,
                    });
                  }}
                >
                  <i
                    style={{ color: "#B9B9B9" }}
                    className="ri-eye-off-line"
                  ></i>
                </Button>
              </div>
            </Col>
            <Col xs={4} style={{ alignSelf: "flex-end" ,position:"relative",top:"-37px"}}>
              <Button
                onClick={this.passwordFieldReset}
                style={resetButtonStyle}
              >
                <span style={resetButtonSpanStyle}>{userLang?.common?.RESET??'Reset'}</span>
              </Button>
              <Button
                onClick={this.passwordUpdateTrigger}
                style={submitButtonStyle}
              >
                <span style={submitButtonSpanStyle}>{userLang?.common?.SAVE??'Save'}</span>
              </Button>
            </Col>
          </Row>
        </Card>
        <Card className="ProfileMainCards darkCardStyle">
          <div className="CardHeading light__span"> {userLang?.common?.NOTIFICATION_SETTING??'Notification Setting'}</div>
          <hr className="lineStraight lineHr" />
          <Card className="NotificationSettingsHeaderStyles darkCardStyle">
            <Row>
              <Col xs={5} className="light__span">{userLang?.common?.FIELDS??'Fields'}</Col>
              <Col className="light__span">{userLang?.common?.MOBILE_APP??'Mobile Ap'}</Col>
              <Col className="light__span">{userLang?.common?.SMS??'SMS'}</Col>
              <Col className="light__span">{userLang?.common?.WHATSAPP??'whatsapp'}</Col>
              <Col className="light__span">{userLang?.common?.EMAIL??'Email'}</Col>
            </Row>
          </Card>
          <Card className="NotificationSettingsHeaderStyles darkCardStyle">
            <Row>
              <Col xs={5} className="light__span">{userLang?.common?.INDENT??'Indents'}</Col>
              {/* <Col>{this.renderSwitchHead("indents", "inApp")}</Col>
              <Col>{this.renderSwitchHead("indents", "sms")}</Col>
              <Col>{this.renderSwitchHead("indents", "whatsapp")}</Col>
              <Col>{this.renderSwitchHead("indents", "email")}</Col> */}
            </Row>
          </Card>
          {this.state.IndentNotificationDataArray.map((item, index) => {
            return (
              <Card className="NotificationItemCard darkCardStyle" key={index}>
                <Row>
                  <Col xs={5} className="light__span">{Object.keys(item)[0]}</Col>
                  <Col>
                    {this.renderSwitch(
                      item,
                      index,
                      item[Object.keys(item)[0]].inApp,
                      "Indent",
                      "inApp"
                    )}
                  </Col>
                  <Col>
                    {this.renderSwitch(
                      item,
                      index,
                      item[Object.keys(item)[0]].sms,
                      "Indent",
                      "sms"
                    )}
                  </Col>
                  <Col>
                    {this.renderSwitch(
                      item,
                      index,
                      item[Object.keys(item)[0]].whatsapp,
                      "Indent",
                      "whatsapp"
                    )}
                  </Col>
                  <Col>
                    {this.renderSwitch(
                      item,
                      index,
                      item[Object.keys(item)[0]].email,
                      "Indent",
                      "email"
                    )}
                  </Col>
                </Row>
              </Card>
            );
          })}
          <Card className="NotificationSettingsHeaderStyles darkCardStyle">
            <Row>
              <Col xs={5} className="light__span">{userLang?.common?.TRIPS??'Trips'}</Col>
              {/* <Col>{this.renderSwitchHead("trips", "inApp")}</Col>
              <Col>{this.renderSwitchHead("trips", "sms")}</Col>
              <Col>{this.renderSwitchHead("trips", "whatsapp")}</Col>
              <Col>{this.renderSwitchHead("trips", "email")}</Col> */}
            </Row>
          </Card>
          {this.state.TripNotificationDataArray.map((item, index) => {
            return (
              <Card className="NotificationItemCard darkCardStyle" key={index}>
                <Row>
                  <Col xs={5} className="light__span">{Object.keys(item)[0]}</Col>
                  <Col>
                    {this.renderSwitch(
                      item,
                      index,
                      item[Object.keys(item)[0]].inApp,
                      "Trips",
                      "inApp"
                    )}
                  </Col>
                  <Col>
                    {this.renderSwitch(
                      item,
                      index,
                      item[Object.keys(item)[0]].sms,
                      "Trips",
                      "sms"
                    )}
                  </Col>
                  <Col>
                    {this.renderSwitch(
                      item,
                      index,
                      item[Object.keys(item)[0]].whatsapp,
                      "Trips",
                      "whatsapp"
                    )}
                  </Col>
                  <Col>
                    {this.renderSwitch(
                      item,
                      index,
                      item[Object.keys(item)[0]].email,
                      "Trips",
                      "email"
                    )}
                  </Col>
                </Row>
              </Card>
            );
          })}
          <Card className="NotificationSettingsHeaderStyles darkCardStyle">
            <Row>
              <Col xs={5} className="light__span">{userLang?.common?.INVOICE??'Invoice'}</Col>
              {/* <Col>{this.renderSwitchHead("invoice", "inApp")}</Col>
              <Col>{this.renderSwitchHead("invoice", "sms")}</Col>
              <Col>{this.renderSwitchHead("invoice", "whatsapp")}</Col>
              <Col>{this.renderSwitchHead("invoice", "email")}</Col> */}
            </Row>
          </Card>
          {this.state.InvoiceNotificationDataArray.map((item, index) => {
            return (
              <Card className="NotificationItemCard darkCardStyle" key={index}>
                <Row>
                  <Col xs={5} className="light__span">{Object.keys(item)[0]}</Col>
                  <Col>
                    {this.renderSwitch(
                      item,
                      index,
                      item[Object.keys(item)[0]].inApp,
                      "Invoice",
                      "inApp"
                    )}
                  </Col>
                  <Col>
                    {this.renderSwitch(
                      item,
                      index,
                      item[Object.keys(item)[0]].sms,
                      "Invoice",
                      "sms"
                    )}
                  </Col>
                  <Col>
                    {this.renderSwitch(
                      item,
                      index,
                      item[Object.keys(item)[0]].whatsapp,
                      "Invoice",
                      "whatsapp"
                    )}
                  </Col>
                  <Col>
                    {this.renderSwitch(
                      item,
                      index,
                      item[Object.keys(item)[0]].email,
                      "Invoice",
                      "email"
                    )}
                  </Col>
                </Row>
              </Card>
            );
          })}
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    indents: state.Indent.indents.assignedIndents,
    policies: state.AccessPolicy.accessPolicies.policies,
    drivers: state.Driver.drivers,
  };
};

export default connect(mapStateToProps, {
  fetchAllNotificationData,
  updateAllNotificationData,
  updateUserPassword,
})(ProfileSettings);
