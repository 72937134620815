import {
  CHECK_LOGIN,
  LOGIN_USER_SUCCESSFUL,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  API_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOAD_USER_SUCCESSFUL,
  CHECK_LOAD,
  GET_USER,
  AD_LOGIN,
  AD_LOGIN_SUCCESS,
  FETCH_ALL_NOTIFICATION_DATA,
  UPDATE_ALL_NOTIFICATION_DATA,
  UPDATE_USER_PASSWORD,
} from "./actionTypes";

export const checkLogin = (user, history, callback) => {
  return {
    type: CHECK_LOGIN,
    payload: { user, history },
    callback,
  };
};

export const checkADLogin = (token, history, callback) => {
  return {
    type: AD_LOGIN,
    payload: { token, history },
    callback,
  };
};

export const loginUserSuccessful = (user) => {
  return {
    type: LOGIN_USER_SUCCESSFUL,
    payload: user,
  };
};
export const loadUser = (user) => {
  // console.log(user,"actions")
  return {
    type: CHECK_LOAD,
    payload: user,
  };
};
export const loadUserSuccess = (user) => {
  // console.log(user,"actions")
  return {
    type: LOAD_USER_SUCCESSFUL,
    payload: user,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const logoutUser = (history) => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

export const getUser = (offset, callback) => ({
  type: GET_USER,
  offset,
  callback,
});

export const fetchAllNotificationData = (data, callback) => ({
  type: FETCH_ALL_NOTIFICATION_DATA,
  data,
  callback,
});

export const updateAllNotificationData = (data, callback) => ({
  type: UPDATE_ALL_NOTIFICATION_DATA,
  data,
  callback,
});

export const updateUserPassword = (data, callback) => ({
  type: UPDATE_USER_PASSWORD,
  data,
  callback,
});
