import React, { Component } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  FormGroup,
  Card,
  CardBody,
  Label,
  Input,
} from "reactstrap";
import { MDBDataTable, MDBIcon, MDBCol, MDBBtn, MDBCollapse } from "mdbreact";
import { connect } from "react-redux";
import Select from "react-select";
import { Link } from "react-router-dom";
import { restrict } from "../../restrict";
import { PERMS } from "../../../enum/perms.enum";
import { BorderLeft } from "@material-ui/icons";
import Datetime from "react-datetime";
import Checkbox from "@material-ui/core/Checkbox";
import $ from "jquery";
import { fetchInvoiceById } from "../../../store/billing/actions";
import InvoicePdf from "./InvoicePdf";
import { MoonLoader } from "react-spinners";

const filterDate = [
  { value: "2", label: "Yesterday" },
  { value: "1", label: "Today" },
  { value: "7", label: "This Week" },
  { value: "30", label: "This Month" },
];

class GeneratedInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      breadcrumbItems: [
        { title: "Invoice", link: "#" },
        { title: "Invoice Generation", link: "#" },
      ],
      radioFilter: "",
      searchValue: "",
      searchOn: false,
      FromDate: "",
      ToDate: "",
      raiseinvoiceModal: false,
      showPdf: false,
    };
    this.fromRef = React.createRef();
    this.toRef = React.createRef();

    (async () => {
      let redirect = await restrict(PERMS.INDENT_TRANSPORTER_READ);
      // //console.log(redirect, "redirect11");

      if (redirect == false) {
        this.props.history.push("/operationsDashboard");
      }
    })();
  }

  componentDidMount = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      let invId = this.props.history?.location?.state?.invoiceId;
      if (invId) {
        //console.log(invId,"invoiceId")
        this.props.fetchInvoiceById(invId, (res) => {
          console.log(res);
        });
      }
      this.setState({ loading: false });
    }, 4000);
  };

  handleDuration = (e) => {
    let value = e.value;
    this.setState({ radioFilter: e }, () => {
      if (this.state.searchOn !== true) this.setState({ searchOn: true });
      this.getAllData();
    });
  };

  searchValueSetter = (e) => {
    this.setState({ searchValue: e.target.value, searchOn: true }, () => {
      if (this.state.searchKey !== "" && this.state.searchValue !== "")
        this.setState({ disabled: false });
      if (this.state.searchValue === "") {
        this.setState({ disabled: true });
      }
    });
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (this.state.disabled === false) {
        this.getAllData();
      }
    }
  };
  handleRadioChange = (e) => {
    this.setState({ radioFilter: e, searchOn: !this.state.searchOn }, () => {
      // this.getAllData();
    });
  };
  validTD = (current) => {
    if (this.state.FromDate !== "") {
      let day = new Date(this.state.FromDate);
      day.setDate(day.getDate() - 1);
      return current.isAfter(day);
    } else {
      return true;
    }
  };
  validFD = (current) => {
    if (this.state.ToDate !== "") {
      let day = new Date(this.state.ToDate);
      day.setDate(day.getDate() + 1);
      return current.isBefore(day);
    } else {
      return true;
    }
  };

  handleChange = (e) => {
    let date = new Date(e.target.value);
    date.setHours(0, 0, 0, 0);
    // //console.log(date,"from,to")
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeRow = (state) => {
    //console.log('state', state);
    // this.setState({ selectedRows: state.selectedRows });
  };
  openRaiseInvoiceModal = () => {
    this.setState({ raiseinvoiceModal: !this.state.raiseinvoiceModal });
  };

  handlePdf = () => {
    ////console.log(AggreementPdf)
    this.setState({ showpdf: !this.state.showpdf });
  };
  render() {
    const { radioFilter, FromDate, ToDate, loading } = this.state;
    const { invoice } = this.props;

    // console.log("---------------INV", invoice);

    const customStyles = {
      indicatorsContainer: (style) => {
        return {
          ...style,
          backgroundColor: "#5664D2",
          width: "54px",
          textAlign: "center",
          justifyContent: "center",
          borderRadius: "0px 4px 4px 0px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          backgroundColor: "#5664D2",
        };
      },
      control: (style) => {
        return {
          ...style,
          width: "241px",
          height: "37px",
          backgroundColor: "#F1F5F7",
          borderTop: "0px",
          borderBottom: "0px",
          borderRight: "0px",
        };
      },
    };
    const inputProps = { placeholder: "Select" };
    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#123abc"} />
          </Col>
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <InvoicePdf isOpen={this.state.showpdf} closePdf={this.handlePdf} />

          <Container fluid>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody className="px-3 py-4">
                    <div>
                      <Row className="justify-content-between d-flex mb-3 ml-1">
                        <div lg={2} md={2} xs={12} className="float-left">
                          <h5
                            className="mt-1"
                            style={{
                              fontWeight: "700",
                              lineHeight: "24px",
                              size: "16px",
                              color: "#252B3B",
                              font: "Poppins",
                            }}
                          >
                            Generated Invoice{" "}
                          </h5>
                        </div>
                        {/* <div lg={8} md={8} xs={12}className=" d-flex flex-wrap align-items-start float-right" lg="8" md="8">
                                    <div md={3} xs={12} className="pr-5 pb-1">
                        <form className="">
                          <Row>
                            <Col xs={9} className="pr-0">
                            <Label className="required-field">
                                From Date
                              </Label>
                              <Datetime
                              ref={this.fromRef}
                              style={{ height: "38px",backgroundColor:'#505d69' }}
                                value={FromDate}
                                onChange={(e) =>
                                  this.handleChange({
                                    target: {
                                      name: "FromDate",
                                      value: e._d,
                                    },
                                  })
                                }
                                inputProps={inputProps}
                                closeOnSelect
                                dateFormat="DD-MM-YYYY"
                                isValidDate={this.validFD}
                                defaultValue={new Date()}
                                input={true}
                              />
                            </Col>
                            <Col xs={2} className="pl-0 cal">
                                <Label></Label>
                              <Button
                                style={{ height: "38px" }}
                                className="btn calendar-icon-color"
                                onClick={()=>{
                                    let {open}=this.fromRef.current.state
                                    //console.log(open,this.fromRef.current)
                                    if(open===false){
                                        //console.log(open,"fromRef",this.fromRef.current)
                                        this.fromRef.current._openCalendar()
                                    }
                                    // if(open===true){
                                    //     //console.log(open,"else fromRef")

                                    //     this.fromRef.current._closeCalendar()
                                    // }
                                }}
                              >
                                  <i className="fas fa-calendar"></i>
                              </Button>
                            </Col>
                          </Row>
                        </form>
                        </div>
                        <div md={3} xs={12} className="pr-5 pb-1">
                        <form className="">
                          <Row>
                            <Col xs={9} className="pr-0">
                            <Label className="required-field">
                                To Date
                              </Label>
                              <Datetime
                              style={{ height: "38px" }}
                                value={FromDate}
                                onChange={(e) =>
                                  this.handleChange({
                                    target: {
                                      name: "ToDate",
                                      value: e._d,
                                    },
                                  })
                                }
                                closeOnSelect
                                dateFormat="DD-MM-YYYY"
                                isValidDate={this.validTD}
                                defaultValue={new Date()}
                                input={true}
                                inputProps={inputProps}
                              />
                            </Col>
                            <Col xs={2} className="pl-0 cal">
                                <Label></Label>
                              <Button
                                style={{ height: "38px" }}
                                className="btn calendar-icon-color"
                                onClick={()=>{
                                    let {open}=this.toRef.current.state
                                    //console.log(open,this.toRef.current)
                                    if(open===false){
                                        //console.log(open,"toRef",this.toRef.current)
                                        this.toRef.current._openCalendar()
                                    }
                                    // if(open===true){
                                    //     //console.log(open,"else toRef")

                                    //     this.toRef.current._closeCalendar()
                                    // }
                                }}
                              
                              >
                                  <i className="fas fa-calendar"></i>
                              </Button>
                            </Col>
                          </Row>null
                        </form>
                        </div>
                        <div lg={2} md={2}xs={12} className="pb-1 mr-4">
                          <Label className="required-field">State</Label>
                        <Select
                          name="radioFilter"
                          onChange={this.handleRadioChange}
                          options={filterDate}
                          value={radioFilter}
                          styles={customStyles}
                          placeholder="Select duration"
                        />
                                            </div>
                       
                        </div>*/}
                      </Row>
                    </div>{" "}
                    <div className="container-fluid">
                      <div className="view-invoice">
                        <Row className="view-row">
                          <Col className="view-col" md="3">
                            <div className="label">Invoice Ref No:</div>
                            <div className="field">{invoice?.invoiceId}</div>
                          </Col>
                          <Col className="view-col" md="3">
                            <div className="label">Invoice Date:</div>
                            <div className="field">
                              {invoice?.createdAt
                                ? invoice.createdAt.split("T")[0]
                                : 0}
                            </div>
                          </Col>
                          <Col className="view-col" md="3">
                            <div className="label">State:</div>
                            <div className="field">
                              {invoice?.state?.stateCode}
                            </div>
                          </Col>
                          <Col className="view-col" md="3">
                            <div className="label">
                              Transporter GST Invoice No:
                            </div>
                            <div className="field">{invoice?.gstNo}</div>
                          </Col>
                        </Row>
                        <Row className="view-row">
                          {/* <Col className="view-col" md="3">
                            <div className="label">
                              Transporter Invoice Date:
                            </div>
                            <div className="field">
                              {invoice?.createdAt
                                ? invoice.createdAt.split("T")[0]
                                : 0}
                            </div>
                          </Col> */}
                          <Col className="view-col" md="3">
                            <div className="label">No Of Items:</div>
                            <div className="field">
                              {invoice?.bills?.length}{" "}
                            </div>
                          </Col>
                          <Col className="view-col" md="3">
                            <div className="label">Total Cost:</div>
                            <div className="field">
                              {invoice?.calculations?.netPayble || 0} ₹
                            </div>
                          </Col>
                          <Col className="view-col" md="3">
                            <div className="label">Invoice Status:</div>
                            <div
                              className="field"
                              style={{ textTransform: "capitalize" }}
                            >
                              {invoice?.status}
                            </div>
                          </Col>
                        </Row>

                        <Row className="view-row">
                          <Col className="view-col" md="3">
                            <div className="label">
                              Transporter Invoice Attachment File
                            </div>
                            <div className="field">
                              <Button onClick={this.handlePdf}>View PDF</Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="invoice-button">
                        <Button
                          onClick={() =>
                            this.props.history.push("/Billing/InvoiceList")
                          }
                          className="btn btn-primary"
                        >
                          View Details
                        </Button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    invoice: state.Billing?.billing?.invoice,
  };
};
export default connect(mapStateToProps, { fetchInvoiceById })(
  GeneratedInvoices
);
