import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { getOrderByExternalId } from "../../store/order/actions";
import BootstrapTable from "react-bootstrap-table-next";
import { connect } from "react-redux";
import NotificationManager from "react-notifications/lib/NotificationManager";
var rows = [];
var data = {};

class OrderCompleted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      orderData: [],
    };
  }

  componentDidMount = () => {
    // if (!localStorage.getItem('authUser')) this.props.history.push('/');
    const path = this.props.history.location.pathname;
    const id = path.split("/")[2];
    this.props.getOrderByExternalId(id, (res) => {
      if (res["message"] === "Order not found,Invalid OrderID") {
        NotificationManager.error(res["message"], "Error");
      } else {
        this.setState({ orderData: res.docs });
      }
    });
  };

  render() {
    rows = [];
    if (this.state.orderData.length !== 0) {
      this.state.orderData.forEach((ele, i) => {
        rows.push({
          ProductCode: ele.ProductCode,
          ProductDimension: ele.ProductDimension,
          ProductName: ele.ProductName,
          Quantity: ele.Quantity,
          ProductPrice: ele.ProductPrice,
          SubTotal: ele.SubTotal,
          ExternalOrderId: (
            <span>
              <span>{ele.ExternalOrderId}</span>
              <a href="">{" (Click here)"}</a>
            </span>
          ),
          OrderStatus: ele.OrderStatus,
          DealerCode: ele.DealerCode?.dealerCode,
          TripDetails: ele.tripDetails,
          _id: ele._id,
          id: i + 1,
        });
      });
    }
    data = {
      columns: [
        {
          dataField: "id",
          text: "No..",
        },
        {
          dataField: "ProductCode",
          text: "Product Code.",
        },
        {
          dataField: "ProductDimension",
          text: "Product Dimension",
        },
        {
          dataField: "ProductName",
          text: "Product Name",
        },
        {
          dataField: "Quantity",
          text: "Quantity",
        },
        {
          dataField: "ProductPrice",
          text: "Product Price",
        },
        {
          dataField: "SubTotal",
          text: "SubTotal",
        },
        {
          dataField: "ExternalOrderId",
          text: "Ext. OrderId",
        },
        {
          dataField: "OrderStatus",
          text: "Order Status",
        },
        {
          dataField: "DealerCode",
          text: "Dealer Code",
        },
      ],
      rows: rows,
    };

    const tableRowEvents = {
      onClick: (e, row) => {
        this.props.history.push(`/order-detail/` + row._id);
      },
    };

    return (
      <div style={{ backgroundColor: "white", height: "100vh" }}>
        <React.Fragment>
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">Orders</h4>

              {this.state.orderData.length !== 0 ? (
                <div className="table-responsive" style={{ cursor: "pointer" }}>
                  <BootstrapTable
                    keyField="_id"
                    data={data.rows}
                    columns={data.columns}
                    rowEvents={tableRowEvents}
                    // pagination={paginationFactory(options)}
                  />
                </div>
              ) : (
                "Coming soon"
              )}
            </CardBody>
          </Card>
        </React.Fragment>
      </div>
    );
  }
}

export default connect(null, { getOrderByExternalId })(OrderCompleted);
