import React, { Component } from "react";
import { Container, Row, Col, Card } from "reactstrap";
import { connect } from "react-redux";
import TrackingMap from "../TrackingMap";
import Grid from "@mui/material/Grid";
import Select from "react-select";
import { fetchTripsTower, fetchTripById } from "../../store/trip/actions";
import { fetchHops } from "../../store/hop/actions";

// import
import { operationsDashboardTripsAndIndent } from "../../store/actions";
import moment from "moment";
import refreshIcon from "../Dashboard/assets/reset icon.jpg";
import useStylesTest from "../Dashboard/OperationsDashboard/Styles/DashboardCardStyles";
import DashboardCard from "../Dashboard/OperationsDashboard/DashboardCard";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

const leftArrowImage = require("../../assets/images/navigation/left arrow icon.svg");
const rightArrowImage = require("../../assets/images/navigation/right arrow icon.svg");
const truckImage = require("../../assets/images/navigation/vehicle.png");

let offset = 0;
var searchValues = {};
var darkMode;

class ControlTower extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tripsId: "",
      openTowerModal: false,
      selectedFilterTrips: undefined,
      searchFilters: { subStatus: "in_transit" },
      driver: "",
      contact: "",
      vehicleType: "",
      vehicleNo: "",
      vehicleAddress: "",
      startLocation: "",
      destination: "",
      route: "",
      startTime: "",
      estimateTime: "",
      transporter: "",
      rightSideNavigationModal: false,
      inputValue: "",
      limit: 200,
      dropDownSourceDestination: [],
      selectedsourceDestination: undefined,
      trips: "",
      vehicleError: "",
      status: "",
      zoomVal: "",
      activeIndex: undefined,
      userLangs: undefined,
      completed: 0,
      inTransit: 0,
      color: "#A020F0",
      vehicleCoordinates: [],
    };
  }

  componentDidMount() {
    this.getAllData();
    this.getAllTripsAndIndentData();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }
  getAllTripsAndIndentData = () => {
    this.props.operationsDashboardTripsAndIndent(
      "",
      "",
      "",
      "",
      "",
      "",
      (res) => {
        let completedCount = res?.data?.tripInformation?.trips.filter((ele) => {
          if (ele.subStatus === "completed") {
            this.setState({
              completed: ele?.total,
            });
          } else if (ele.subStatus === "in_transit") {
            this.setState({
              inTransit: ele?.total,
            });
          }
        });
      }
    );
  };
  getAllData = () => {
    this.props.fetchTripsTower(offset, this.state.searchFilters, (res) => {
      let vehicleLocation = [];

      if (Array.isArray(res?.data?.docs)) {
        res.data.docs.forEach((ele) => {
          if (
            ele?.latestKnownLocation?.length > 0 &&
            (ele?.subStatus === "in_transit" || ele?.subStatus === "completed")
          ) {
            vehicleLocation.push({
              coordinate: {
                lat: parseInt(ele?.latestKnownLocation[0]?.coordinates?.lng),
                lng: parseInt(ele?.latestKnownLocation[0]?.coordinates?.lat),
              },
              status: ele?.subStatus,
              delayedStatus: ele.delayedStatus,
            });
          }
        });
      }
      this.setState({
        vehicleCoordinates: vehicleLocation,
      });
    });
  };

  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (this.state.userLangs !== userLang || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }
  keyPress = (e) => {
    if (e.key === "Enter")
      this.setState(
        {
          tripsId: "",
          activeIndex: undefined,
        },
        () => {
          this.getAllData();
        }
      );
  };
  handleChange = (val) => {
    var regexNumber = /^[0-9]+$/;
    var alphaNumExp = /^[a-zA-Z0-9]+$/;
    let selectedFilters = this.state.searchFilters;
    if (this.state.selectedFilterTrips.value === "vehicle") {
      if (alphaNumExp.test(val)) {
        selectedFilters["vehicle"] = val.toUpperCase();
        this.setState({
          inputValue: val,
          searchFilters: selectedFilters,
        });
      } else {
        if (val.length === 0) {
          delete selectedFilters["vehicle"];
          this.setState({
            inputValue: val,
            searchFilters: selectedFilters,
          });
        }
      }
    } else if (this.state.selectedFilterTrips.value === "driver") {
      if (regexNumber.test(val)) {
        selectedFilters["driver"] = val;
        this.setState({
          inputValue: val,
          searchFilters: selectedFilters,
        });
      } else {
        if (val.length === 0) {
          delete selectedFilters["driver"];
          this.setState({
            inputValue: val,
            searchFilters: selectedFilters,
          });
        }
      }
    } else if (this.state.selectedFilterTrips.value === "tripId") {
      if (regexNumber.test(val)) {
        selectedFilters["tripId"] = val;
        this.setState({
          inputValue: val,
          searchFilters: selectedFilters,
        });
      } else {
        if (val.length === 0) {
          delete selectedFilters["tripId"];
          this.setState({
            inputValue: val,
            searchFilters: selectedFilters,
          });
        }
      }
    } else if (this.state.selectedFilterTrips.value === "indentId") {
      if (regexNumber.test(val)) {
        selectedFilters["indentId"] = val;
        this.setState({
          inputValue: val,
          searchFilters: selectedFilters,
        });
      } else {
        if (val.length === 0) {
          delete selectedFilters["indentId"];
          this.setState({
            inputValue: val,
            searchFilters: selectedFilters,
          });
        }
      }
    } else if (this.state.selectedFilterTrips.value === "shipmentId") {
      if (regexNumber.test(val)) {
        selectedFilters["shipment"] = val;
        this.setState({
          inputValue: val,
          searchFilters: selectedFilters,
        });
      } else {
        if (val.length === 0) {
          delete selectedFilters["shipment"];
          this.setState({
            inputValue: val,
            searchFilters: selectedFilters,
          });
        }
      }
    }
  };
  singleFetchTrips = (id) => {
    this.props.fetchTripById(id ? id : "", (res) => {
      if (res) {
        this.setState({
          tripsId: res?.data[0]?._id ? res?.data[0]?._id : "",
          driver: res?.data[0].driver ? res?.data[0]?.driver?.name : "",
          contact: res?.data[0]?.driver ? res?.data[0]?.driver?.phone : "",
          vehicleType: res?.data[0]?.vehicle?.type
            ? res?.data[0]?.vehicle?.type?.label
            : "",
          vehicleNo: res?.data[0]?.vehicle?.regNo
            ? res?.data[0]?.vehicle?.regNo
            : "",
          vehicleAddress: res?.data[0],
          startLocation: res?.data[0]?.source
            ? res?.data[0]?.source?.label
            : "",
          destination: res?.data[0]?.destination
            ? res?.data[0]?.destination?.label
            : "",
          route: res?.data[0]?.route ? res?.data[0]?.route?.name : "",
          startTime: res?.data[0]?.startTime
            ? moment(res?.data[0]?.startTime).format("L") +
              " ," +
              moment(res?.data[0]?.startTime).format("LT")
            : "",
          estimateTime: res?.data[0]?.expectedArrivalTime
            ? moment(res?.data[0]?.expectedArrivalTime).format("L") +
              " ," +
              moment(res?.data[0]?.expectedArrivalTime).format("LT")
            : "",
          transporter: res?.data[0]?.transporter
            ? res?.data[0]?.transporter?.name
            : "",
          status: res?.data[0]?.subStatus
            ? res?.data[0]?.subStatus.replaceAll("_", " ")
            : "",
          vehicleCoordinates: [],
        });
      }
    });
  };
  fetchHops = (filtersHopsData) => {
    let hops = [];
    this.props.fetchHops(
      {
        offset: filtersHopsData.offset,
        limit: filtersHopsData.limit,
        label: filtersHopsData.q,
      },
      (res) => {
        if (res?.data?.docs?.length > 0) {
          res.data.docs.forEach((ele) => {
            hops.push({
              label: ele.hopCode + " (" + ele.label + ")",
              value: ele._id,
            });
          });
        }
        this.setState({
          dropDownSourceDestination: hops,
        });
      }
    );
  };
  filterData = (e) => {
    if (e?.length > 0) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: e,
      };
      this.fetchHops(filtersHopsData);
    }
    if (e.length === 0) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: "",
      };
      this.fetchHops(filtersHopsData);
    }
  };
  dropDownSource = (e) => {
    let hops = [];

    if (e.value === "source" || e.value === "destination") {
      this.props.fetchHops({ offset, limit: 10 }, (res) => {
        if (res?.data?.docs?.length > 0) {
          res.data.docs.forEach((ele) => {
            hops.push({
              label: ele.hopCode + " (" + ele.label + ")",
              value: ele._id,
            });
          });
        }
      });
    }
    this.setState({
      dropDownSourceDestination: hops,
      selectedFilterTrips: e,
      selectedsourceDestination: undefined,
      inputValue: "",
    });
  };
  filterSourceDestination = (e) => {
    let checkSourceDestination = { ...this.state.searchFilters };
    if (this.state.selectedFilterTrips.value === "source") {
      checkSourceDestination["source"] = e.value;
    } else {
      checkSourceDestination["destination"] = e.value;
    }
    this.setState(
      {
        selectedsourceDestination: e,
        searchFilters: checkSourceDestination,
      },
      () => {
        this.getAllData();
      }
    );
  };
  cardClick = (name) => {
    let filter;
    let colorCode;
    if (name === "In Transit") {
      filter = "in_transit";
      colorCode = "#A020F0";
    } else if (name === "Completed") {
      filter = "completed";
      colorCode = "#006400";
    } else if (name === "Trips Delayed") {
      filter = "delayed";
      colorCode = "#E53D2C";
    }

    if (name != "Trips Delayed") {
      this.reset({ subStatus: filter }, colorCode);
    } else {
      this.reset({ delayedStatus: true }, colorCode);
    }
  };
  reset = (searchFilters = { subStatus: "in_transit" }, color = "#A020F0") => {
    this.setState(
      {
        searchFilters: searchFilters,
        driver: "",
        contact: "",
        vehicleType: "",
        vehicleNo: "",
        vehicleAddress: "",
        startLocation: "",
        destination: "",
        route: "",
        startTime: "",
        estimateTime: "",
        inputValue: "",
        transporter: "",
        selectedsourceDestination: undefined,
        selectedFilterTrips: {
          label: this.state.userLangs?.trip?.TRIP_ID,
          value: "tripId",
        },
        status: "",
        tripsId: "",
        activeIndex: undefined,
        rightSideNavigationModal: false,
        color: color,
        vehicleCoordinates: [],
      },
      () => {
        searchValues = {};
        // this.props.fetchTripsTower(0, this.state.searchFilters, (res) => {});
        this.getAllData();
      }
    );
  };
  render() {
    // delayed
    // unTracked
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkMode = mode === "dark__mode" ? true : false;
    let dropDownSearch = [
      { label: this.state.userLangs?.trip?.TRIP_ID, value: "tripId" },
      { label: this.state.userLangs?.planning?.INDENT_ID, value: "indentId" },
      { label: this.state.userLangs?.trip?.SHIPMENT_ID, value: "shipmentId" },
      { label: this.state.userLangs?.common?.SOURCE, value: "source" },
      {
        label: this.state.userLangs?.common?.DESTINATION,
        value: "destination",
      },
      { label: this.state.userLangs?.trip?.DRIVER, value: "driver" },
      { label: this.state.userLangs?.common?.VEHICLE, value: "vehicle" },
    ];
    if (this.state.selectedFilterTrips === undefined) {
      this.setState({
        selectedFilterTrips: {
          label: this.state.userLangs?.trip?.TRIP_ID || userLang?.trip?.TRIP_ID,
          value: "tripId",
        },
      });
    }
    let data = [];

    if (Array.isArray(this.props.trips?.data?.docs)) {
      data = [];
      this.props.trips.data.docs.forEach((ele) => {
        if (ele?.subStatus === "in_transit" || ele?.subStatus === "completed") {
          data.push({
            id: ele._id,
            tripId: ele.tripId ? ele.tripId : "N/A",
            indentId: ele.indentId ? ele.indentId?.indentId : null,
            vehicle: ele.vehicle ? ele.vehicle.regNo : "N/A",
            locationDescription:
              ele?.latestKnownLocation.length > 0
                ? ele?.latestKnownLocation[0]?.locationDescription
                : "N/A",
          });
        }
      });
    }
    const customStyles = {
      menu: (style) => {
        return {
          ...style,
          cursor: "pointer",
          width: "100%",
          backgroundColor: darkMode ? "#171722" : "#ffffff",
          opacity: darkMode ? "1" : "",
          color: darkMode ? "#9d9d9d" : "",
          border: darkMode ? "1px solid #9d9d9d" : "",
        };
      },
      input: (style) => {
        return {
          ...style,
          color: darkMode ? "#9d9d9d" : "black",
        };
      },

      option: (style, state) => {
        return {
          ...style,
          backgroundColor: state.isFocused ? "#1B1C31" : "",
          color: state.isSelected ? "#ffffff" : "",
        };
      },
    };
    const classes = useStylesTest(darkMode, "none");
    return (
      <div>
        <Row>
          <Col xs={12}>
            <div className="mapFullHeightDiv">
              <div className="trackingBackground">
                <TrackingMap
                  id={this.state.tripsId ? this.state.tripsId : undefined}
                  detailToggler={() => {}}
                  handleVehicleHistory={() => {}}
                  mode={darkMode}
                  vehicleCoordinates={this.state.vehicleCoordinates}
                />

                <img
                  src={rightArrowImage}
                  onClick={() => {
                    this.setState({ openTowerModal: true });
                  }}
                  className="rightArrowMaps"
                />
                {this.state.openTowerModal && (
                  <>
                    <div className="d-flex justify-content-between">
                      <div className="trackingAbosulte transDarkCard">
                        <div className="controlTowerCard mb-3">
                          <Grid
                            container
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            className="dashboard-card-spacing dashContainerDiv"
                            style={{
                              width: "100%",
                              // padding:"0px 5px"
                              margin: "0px",
                              paddingRight: "15px",
                            }}
                          >
                            <Grid item xs={12}>
                              <div className="towerInTransit">
                                <DashboardCard
                                  cardStyle={classes.inTransistCardStyle}
                                  nameStyle={classes.inTransistNameStyle}
                                  iconStyle={classes.inTransistIconStyle}
                                  icon={
                                    <svg
                                      width="34"
                                      height="34"
                                      viewBox="0 0 34 34"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g filter="url(#filter0_d_2969_2588)">
                                        <rect
                                          x="4.00488"
                                          y="4.5"
                                          width="25"
                                          height="25"
                                          rx="4"
                                          fill={darkMode?"#171722":"#EAC8FF"}
                                        />
                                      </g>
                                      <path
                                        d="M17.871 11.4474H8.6167V19.4678H17.871V11.4474Z"
                                        fill="#A020F0"
                                        stroke="#A020F0"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M20.0747 14.5322H22.5425L24.3934 16.383V19.4678H20.0747V14.5322Z"
                                        fill="#A020F0"
                                        stroke="#A020F0"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M11.393 22.5526C12.2448 22.5526 12.9354 21.862 12.9354 21.0102C12.9354 20.1583 12.2448 19.4678 11.393 19.4678C10.5411 19.4678 9.85059 20.1583 9.85059 21.0102C9.85059 21.862 10.5411 22.5526 11.393 22.5526Z"
                                        fill="#A020F0"
                                        stroke="#A020F0"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M20.6918 22.5526C21.5436 22.5526 22.2342 21.862 22.2342 21.0102C22.2342 20.1583 21.5436 19.4678 20.6918 19.4678C19.84 19.4678 19.1494 20.1583 19.1494 21.0102C19.1494 21.862 19.84 22.5526 20.6918 22.5526Z"
                                        fill="#A020F0"
                                        stroke="#A020F0"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <defs>
                                        <filter
                                          id="filter0_d_2969_2588"
                                          x="0.00488281"
                                          y="0.5"
                                          width="33"
                                          height="33"
                                          filterUnits="userSpaceOnUse"
                                          colorInterpolationFilters="sRGB"
                                        >
                                          <feFlood
                                            floodOpacity="0"
                                            result="BackgroundImageFix"
                                          />
                                          <feColorMatrix
                                            in="SourceAlpha"
                                            type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                            result="hardAlpha"
                                          />
                                          <feOffset />
                                          <feGaussianBlur stdDeviation="2" />
                                          <feComposite
                                            in2="hardAlpha"
                                            operator="out"
                                          />
                                          <feColorMatrix
                                            type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                                          />
                                          <feBlend
                                            mode="normal"
                                            in2="BackgroundImageFix"
                                            result="effect1_dropShadow_2969_2588"
                                          />
                                          <feBlend
                                            mode="normal"
                                            in="SourceGraphic"
                                            in2="effect1_dropShadow_2969_2588"
                                            result="shape"
                                          />
                                        </filter>
                                      </defs>
                                    </svg>
                                  }
                                  numberStyle={classes.inTransistNumberStyle}
                                  name={"In Transit"}
                                  color={true ? "#ffffff" : "#A020F0"}
                                  iconClr={classes.intranEye}
                                  value={
                                    this.state.inTransit
                                      ? this.state.inTransit
                                      : 0
                                  }
                                  cardClick={this.cardClick}
                                />
                              </div>
                            </Grid>

                            <Grid item xs={6}>
                              <DashboardCard
                                cardStyle={classes.completedCardStyle}
                                nameStyle={classes.completedNameStyle}
                                iconStyle={classes.completedIconStyle}
                                icon={
                                  <svg
                                    width="34"
                                    height="34"
                                    viewBox="0 0 34 34"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g filter="url(#filter0_d_2969_2587)">
                                      <rect
                                        x="4.00488"
                                        y="4.5"
                                        width="25"
                                        height="25"
                                        rx="4"
                                        fill={darkMode?"#171722":"#A5F2BB"}
                                      />
                                    </g>
                                    <path
                                      d="M20.9951 11.227H12.0148C11.3063 11.227 10.7319 11.8013 10.7319 12.5099V21.4901C10.7319 22.1986 11.3063 22.773 12.0148 22.773H20.9951C21.7036 22.773 22.278 22.1986 22.278 21.4901V12.5099C22.278 11.8013 21.7036 11.227 20.9951 11.227Z"
                                      fill="#006400"
                                      stroke="#006400"
                                      strokeWidth="2.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M19.588 14.7578L15.1035 19.2423L13.4219 17.5606"
                                      stroke="#A5F2BB"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                    />
                                    <defs>
                                      <filter
                                        id="filter0_d_2969_2587"
                                        x="0.00488281"
                                        y="0.5"
                                        width="33"
                                        height="33"
                                        filterUnits="userSpaceOnUse"
                                        colorInterpolationFilters="sRGB"
                                      >
                                        <feFlood
                                          floodOpacity="0"
                                          result="BackgroundImageFix"
                                        />
                                        <feColorMatrix
                                          in="SourceAlpha"
                                          type="matrix"
                                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                          result="hardAlpha"
                                        />
                                        <feOffset />
                                        <feGaussianBlur stdDeviation="2" />
                                        <feComposite
                                          in2="hardAlpha"
                                          operator="out"
                                        />
                                        <feColorMatrix
                                          type="matrix"
                                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                                        />
                                        <feBlend
                                          mode="normal"
                                          in2="BackgroundImageFix"
                                          result="effect1_dropShadow_2969_2587"
                                        />
                                        <feBlend
                                          mode="normal"
                                          in="SourceGraphic"
                                          in2="effect1_dropShadow_2969_2587"
                                          result="shape"
                                        />
                                      </filter>
                                    </defs>
                                  </svg>
                                }
                                numberStyle={classes.completedNummberStyle}
                                name={"Completed"}
                                iconClr={classes.completeEye}
                                color={true ? "#ffffff" : "#006400"}
                                value={
                                  this.state.completed
                                    ? this.state.completed
                                    : 0
                                }
                                cardClick={this.cardClick}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <DashboardCard
                                cardStyle={classes.tripDelayedCardStyle}
                                nameStyle={classes.tripDelayedNameStyle}
                                iconStyle={classes.tripDelayedIconStyle}
                                icon={
                                  <svg
                                    width="33"
                                    height="34"
                                    viewBox="0 0 33 34"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g filter="url(#filter0_d_4338_5712)">
                                      <rect
                                        x="4"
                                        y="4.5"
                                        width="25"
                                        height="25"
                                        rx="4"
                                        fill={darkMode?"#171722":"#FFE3E1"}
                                      />
                                    </g>
                                    <path
                                      d="M15.3599 11.6055L9.71022 21.0361C9.59365 21.2378 9.53195 21.4665 9.53126 21.6995C9.53056 21.9324 9.59091 22.1615 9.70628 22.3639C9.82165 22.5663 9.98802 22.7349 10.1888 22.853C10.3896 22.9711 10.6179 23.0345 10.8508 23.037H22.1494C22.3823 23.0345 22.6105 22.9711 22.8114 22.853C23.0122 22.7349 23.1785 22.5663 23.2939 22.3639C23.4093 22.1615 23.4696 21.9324 23.4689 21.6995C23.4682 21.4665 23.4065 21.2378 23.29 21.0361L17.6403 11.6055C17.5214 11.4094 17.354 11.2472 17.1542 11.1347C16.9544 11.0221 16.729 10.963 16.4997 10.963C16.2703 10.963 16.0449 11.0221 15.8451 11.1347C15.6453 11.2472 15.4779 11.4094 15.3591 11.6055H15.3599Z"
                                      fill="#E53D2C"
                                      stroke="#E53D2C"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M16.5005 15.0333V17.7015"
                                      stroke="white"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M16.5005 20.3688H16.5075"
                                      stroke="white"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <defs>
                                      <filter
                                        id="filter0_d_4338_5712"
                                        x="0"
                                        y="0.5"
                                        width="33"
                                        height="33"
                                        filterUnits="userSpaceOnUse"
                                        colorInterpolationFilters="sRGB"
                                      >
                                        <feFlood
                                          flood-opacity="0"
                                          result="BackgroundImageFix"
                                        />
                                        <feColorMatrix
                                          in="SourceAlpha"
                                          type="matrix"
                                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                          result="hardAlpha"
                                        />
                                        <feOffset />
                                        <feGaussianBlur stdDeviation="2" />
                                        <feComposite
                                          in2="hardAlpha"
                                          operator="out"
                                        />
                                        <feColorMatrix
                                          type="matrix"
                                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                                        />
                                        <feBlend
                                          mode="normal"
                                          in2="BackgroundImageFix"
                                          result="effect1_dropShadow_4338_5712"
                                        />
                                        <feBlend
                                          mode="normal"
                                          in="SourceGraphic"
                                          in2="effect1_dropShadow_4338_5712"
                                          result="shape"
                                        />
                                      </filter>
                                    </defs>
                                  </svg>
                                }
                                numberStyle={classes.tripDelayedNumberStyle}
                                name={"Trips Delayed"}
                                //  value={trips?.deleyed}
                                value={
                                  this.props?.TripsAndIndentData
                                    ?.tripInformation?.delayed
                                }
                                iconClr={classes.tripDelayEye}
                                color={"#FFFFFF"}
                                cardClick={this.cardClick}
                              />
                            </Grid>
                          </Grid>
                        </div>

                        <div className="d-flex justify-content-between controlAlignItem">
                          <Select
                            value={this.state.selectedFilterTrips}
                            isMulti={false}
                            styles={darkMode ? customStyles : ""}
                            onInputChange={""}
                            onChange={(e) => {
                              this.dropDownSource(e);
                            }}
                            options={dropDownSearch}
                            classNamePrefix="select2-selection"
                            placeholder="select..."
                          />
                          &nbsp;
                          {!(
                            this.state.selectedFilterTrips.value === "source" ||
                            this.state.selectedFilterTrips.value ===
                              "destination"
                          ) ? (
                            <input
                              type="text"
                              placeholder="Search"
                              value={this.state.inputValue}
                              onChange={(e) => {
                                this.handleChange(e.target.value.trimStart());
                              }}
                              onKeyPress={this.keyPress}
                            />
                          ) : (
                            <Select
                              value={this.state.selectedsourceDestination}
                              isMulti={false}
                              styles={darkMode ? customStyles : ""}
                              autoFocus={true}
                              isSearchable={true}
                              onInputChange={(e) => this.filterData(e)}
                              onChange={(e) => {
                                this.filterSourceDestination(e);
                              }}
                              options={this.state.dropDownSourceDestination}
                              classNamePrefix="select2-selection"
                              placeholder="select..."
                            />
                          )}{" "}
                          &nbsp;
                          <div>
                            <img
                              style={{
                                width: "44px",
                                height: "48px",
                                cursor: "pointer",
                              }}
                              src={refreshIcon}
                              onClick={() => {
                                this.reset();
                              }}
                            />
                          </div>
                          {/* <img
                                  src={leftArrowImage}
                                  onClick={() => {
                                    this.setState({ openTowerModal: false });
                                  }}
                                /> */}
                        </div>
                        <br />
                        <Card
                          style={{
                            height: "364px",
                            overflowX: "hidden",
                            overflowY: "scroll",
                            margin: "0px 15px",
                            backgroundColor:darkMode?"#2E2E46":""
                          }}
                        >
                          {data.length > 0 ? (
                            data.map((ele, i) => (
                              <div
                                key={i}
                                // className="shadow mt-2"
                                style={{
                                  backgroundColor: "#F1F5F7",
                                }}
                                className={`shadow mt-2 tripHighlights ${
                                  this.state.activeIndex === i
                                    ? "highlightsTrips"
                                    : ""
                                }`}
                                onClick={() => {
                                  this.setState({
                                    activeIndex: i,
                                  });
                                  this.singleFetchTrips(ele.id);
                                }}
                              >
                                <Row className="tripDarkSpace"
                                  style={{
                                    padding: "10px",
                                    marginBottom: "-24px",
                                  }}
                                >
                                  <Col xs={12}>
                                    <Row className="tripsWhiteSpace">
                                      <Col xs="6" className="light__span">Trip Id :</Col>
                                      <Col xs="6" className="dark__span">{ele.tripId}</Col>
                                      <Col xs="6" className="light__span">Vehicle No :</Col>
                                      <Col xs="4" className="dark__span">{ele.vehicle}</Col>
                                      <Col xs="2">
                                        <div
                                          style={{
                                            backgroundColor: this.state.color,
                                            width: "5px",
                                            height: "5px",
                                            borderRadius: "50%",
                                            boxShadow: `0 0 10px 5px ${this.state.color}`,
                                            marginTop: "5px",
                                          }}
                                        ></div>
                                      </Col>
                                      <Col xs="6" className="light__span">Latest Known Location:</Col>
                                      <Col
                                        xs="6"
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {/* {ele.locationDescription} */}
                                        <div className="tooltipIcon">
                                          <p className="controlTooltipIcontext dark__span">
                                            {ele.locationDescription}
                                          </p>
                                          {ele.locationDescription != "N/A" && (
                                            <span className="tooltipIcontextControl dark__span">
                                              {" "}
                                              {ele.locationDescription}
                                            </span>
                                          )}
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </div>
                            ))
                          ) : (
                            <div className="d-flex align-items-center justify-content-center noDataRecord text-center">
                              <div>
                                {/* <b>Oops!</b> <br /> */}
                                {this.state.userLangs?.controlTower?.NO_TRIPS}
                              </div>
                            </div>
                          )}
                        </Card>
                      </div>
                      <img
                        src={leftArrowImage}
                        onClick={() => {
                          this.setState({ openTowerModal: false }, () => {
                            // this.reset();
                          });
                        }}
                        className="mapsLeftArrowImg"
                      />
                    </div>
                  </>
                )}
                {!this.state.rightSideNavigationModal && this.state.tripsId && (
                  <div className="detailTracking">
                    <ul>
                      <li>
                        <div className="tooltip2">
                          <img
                            src={leftArrowImage}
                            alt="leftArrowImage"
                            onClick={() => {
                              this.setState({
                                rightSideNavigationModal:
                                  !this.state.rightSideNavigationModal,
                              });
                            }}
                          />
                          <span className="tooltiptext">
                            {this.state.userLangs?.controlTower?.TRIPS_DETAILS}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
                {this.state.rightSideNavigationModal && (
                  <div className="detailsTripMaps">
                    {this.state.rightSideNavigationModal && (
                      <div className="rightNavigationdiv">
                        <img
                          src={rightArrowImage}
                          alt="rightArrowImage"
                          onClick={() => {
                            this.setState({
                              rightSideNavigationModal: false,
                            });
                          }}
                        />
                      </div>
                    )}
                    <div className="userDetails transDarkCard">
                      <h4 className="light__span controlTowerPadding">
                        {this.state.userLangs?.controlTower?.DRIVER_DETAILS}
                      </h4>
                      <hr />
                      <Row
                        style={{
                          padding: "10px",
                        }}
                      >
                        <Col xs={12}>
                          <Row className="tripsWhiteSpace">
                            <Col xs="4" className="sourceTransist light__span">
                              {" "}
                              {this.state.userLangs?.trip?.DRIVER} :
                            </Col>
                            <Col xs="8" style={{ textTransform: "capitalize" }}>
                              {" "}
                              <span className="dark__span">{this.state.driver}</span>
                            </Col>
                            <Col xs="4" className="sourceTransist light__span">
                              {" "}
                              {this.state.userLangs?.masters?.CONTACT} :
                            </Col>
                            <Col xs="8"><span className="dark__span">{this.state.contact}</span></Col>
                            <Col xs="4" className="sourceTransist light__span">
                              {" "}
                              {this.state.userLangs?.controlTower?.VEHICLE_NO} :
                            </Col>
                            <Col xs="8"><span className="dark__span">{this.state.vehicleNo}</span></Col>
                            <Col xs="4" className="sourceTransist light__span">
                              {" "}
                              {this.state.userLangs?.common?.VEHICLE_TYPE} :
                            </Col>
                            <Col xs="8"><span className="dark__span">{this.state.vehicleType}</span></Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>

                    <div className="tripDetails transDarkCard">
                      <h4 className="light__span controlTowerPadding">
                        {this.state.userLangs?.controlTower?.TRIPS_DETAILS}
                      </h4>
                      <hr />
                      <Row
                        style={{
                          padding: "10px",
                        }}
                      >
                        <Col xs={12}>
                          <Row className="tripsWhiteSpace">
                            <Col xs="6" className="sourceTransist light__span">
                              {
                                this.state.userLangs?.controlTower
                                  ?.START_LOCATION
                              }{" "}
                              :
                            </Col>
                            <Col xs="6" className="dark__span">{this.state.startLocation}</Col>
                            <Col xs="6" className="sourceTransist light__span">
                              {this.state.userLangs?.common?.DESTINATION} :
                            </Col>
                            <Col xs="6" className="dark__span"> {this.state.destination}</Col>
                            <Col xs="6" className="sourceTransist light__span">
                              {" "}
                              {this.state.userLangs?.common?.ROUTE} :
                            </Col>
                            <Col xs="6" className="dark__span"> {this.state.route}</Col>
                            <Col xs="6" className="sourceTransist light__span">
                              {" "}
                              {
                                this.state.userLangs?.controlTower
                                  ?.TRIP_START_TIME
                              }{" "}
                              :
                            </Col>
                            <Col xs="6" className="dark__span"> {this.state.startTime}</Col>
                            <Col xs="6" className="sourceTransist light__span">
                              {" "}
                              {this.state.userLangs?.controlTower?.ARRIVAL_TIME}
                              :
                            </Col>
                            <Col xs="6" className="dark__span"> {this.state.estimateTime}</Col>
                            <Col xs="6" className="sourceTransist light__span">
                              {" "}
                              {this.state.userLangs?.common?.STATUS}:
                            </Col>
                            <Col xs="6" className="dark__span" style={{ textTransform: "capitalize" }}>
                              {this.state.status}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    trips: state.Trip.trips.tripsTower,
    fetchId: state.Trip.trips.fetchId,
    TripsAndIndentData:
      state.TripCountStatus.tripCountStatus?.operationsTripIndentDetails?.data,
  };
};

export default connect(mapStateToProps, {
  fetchTripsTower,
  fetchTripById,
  fetchHops,
  operationsDashboardTripsAndIndent,
})(ControlTower);
