import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import "@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { connect } from "react-redux";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { fetchRoleById, fetchDropPolicies, editRole } from "../../store/accessPolicy/actions";
import formatInput from '../InputFormatter';
import { MDBDataTable, MDBBtn } from "mdbreact";
import { NotificationManager } from "react-notifications";


var data;
class EditRoleForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onSubmitDisable: false,
      breadcrumbItems: [
        { title: "Role", link: "roles" },
        { title: "Edit Role", link: "#" },
      ],
      create: false,
      read: false,
      update: false,
      delete: false,
      form: {
        role: "",
        policy: [],
        accessPolicies: [],
        id: ''

      },
      formErrors: {
        role: "",
        policy: [],
        accessPolicies: [],
        id: ''
      },
      policyList: [],
      initialPolicies: []
    };

  }
  initialVal = (val) => {
    return { label: "Select " + val, value: "", isDisabled: true };
  };
  componentDidMount() {
    this.setState({ loading: true });
    const id = window.location.pathname.split("/edit-role-")[1];
    this.props.fetchRoleById(id, (res) => {
      let form={}
      console.log(res._id)
      if(res.policies){
      res.policies.forEach((ele) => {
        ele.label = ele.id.name;
        ele.value = ele.id.name;
        ele._id= ele.id._id;
      })
       form = {
        id: id,
        role: res.roleGroupId.id.name,
        policy: res.policies,
        accessPolicies: []
      }
      this.setState({ form, loading: false });
    }
    else{

      form.id=res._id;
      form.role=res.name;
      this.setState({form})
    }
    });
    this.fetchDropList();

  }
  fetchDropList = () => {
    this.props.fetchDropPolicies((res) => {
      let initialPolicies = [];
      res.forEach((ele1) => { 
        if(ele1.name==="indent"){
          if((/true/i).test(process.env.REACT_APP_INDENT)===false){
            // console.log("if1")
  
            return;
          }
          else{
            initialPolicies.push({"value": ele1._id,"item": ele1.name,"label":ele1.name,_id: ele1._id });
  
          }
         }
         if(ele1.name==="transporter"){
  
          if((/true/i).test(process.env.REACT_APP_TRANSPORTER)===false){
            // console.log("if2")
  
            return;
          }
          else{
            initialPolicies.push({"value": ele1._id,"item": ele1.name,"label":ele1.name,_id: ele1._id });
  
          }
        }
         if(ele1.name==="vehicle"){
  
          if((/true/i).test(process.env.REACT_APP_VEHICLE)===false){
            // console.log("if2")
  
            return;
          }
          else{
            initialPolicies.push({"value": ele1._id,"item": ele1.name,"label":ele1.name,_id: ele1._id });
  
          }
        }
        else{
         initialPolicies.push({"value": ele1._id,"item": ele1.name,"label":ele1.name,_id: ele1._id })
        }
       })
      this.setState({ initialPolicies });
      this.setDropDownVal();
    })
  }

  //set Dropdown val if any selected options is present
  setDropDownVal = () => {
    const { form, initialPolicies } = this.state;
    let policyList = [];
    initialPolicies.forEach((ele1) => {
      let isPres = form.policy?.some((ele) => { return ele.label == ele1.label });
      if (!isPres) policyList.push({ value: ele1.label, label: ele1.label,_id:ele1._id })
    });
    this.setState({ policyList });
  }


  validateField = (name, value, refValue) => {
    let errorMsg = null;

    switch (name) {
      case "role":
        if (!value) errorMsg = "Please Enter role.";
        break;
      case "policy":
        if (value && value.length == 0) errorMsg = "Please Select policy.";
        else if (!value) errorMsg = "Please Select policy.";
        if (errorMsg) this.fetchAllPolicies();
        else this.setDropDownVal();
        break;

      default:
        break;
    }
    return errorMsg;
  };

  validateForm = (form, formErrors, validateFunc) => {
    const errorObj = {};
    Object.keys(formErrors).map((x) => {
      let refValue = null;

      const msg = validateFunc(x, form[x], refValue);
      if (msg) errorObj[x] = msg;
    });
    return errorObj;
  };


  handleSubmit = () => {
    this.setState({ onSubmitDisable: true });
    const { form, formErrors } = this.state;

    const errorObj = this.validateForm(form, formErrors, this.validateField);
    if (Object.keys(errorObj).length !== 0) {
      this.setState({
        onSubmitDisable: false,
        formErrors: { ...formErrors, ...errorObj },
      });
      return false;
    }

    let formVal = {
      policies: form.accessPolicies,
    };

    this.props.editRole(form.id,formVal, (res) => {
      if (
        res["message"] &&
        res["message"] === "RoleGroupPolicies has been successfully updated"
      ) {
        NotificationManager.success(res["message"], "Success");
        this.props.history.push("/roles");
      } else {
        NotificationManager.error(res["message"], "Error");
        this.setState({ onSubmitDisable: false });
      }
    });
  };

  fetchAllPolicies = () => {
    const { initialPolicies } = this.state;
    let policyList = [...initialPolicies];
    this.setState({ policyList });
  }
  reset = () => {
  
    const { form } = this.state;

    this.fetchAllPolicies();
    let formData = {
      role: form.role,
      policy: "",
      id: form.id
    };

    this.setState({
      form: formData,
    });
    this.props.history.push("/roles");

  };

  handleChange = (e) => {
    const { name, value } = e.target;
    const { form, formErrors } = this.state;
    let formObj = {};

    formObj = {
      ...form,
      [name]: value,
    };
    this.setState({ form: formObj }, () => {
      if (!Object.keys(formErrors).includes(name)) return;
      let formErrorsObj = {};

      const errorMsg = this.validateField(
        name,
        name === "language" ? this.state.form["language"] : value
      );
      formErrorsObj = { ...formErrors, [name]: errorMsg };
      this.setState({ formErrors: formErrorsObj });
    });
  };

  trimSpace = (event, name) => {
    const { form } = this.state;
    let formObj = formatInput(event, name, form);
    this.setState({ form: formObj });
  }
  render() {
    const { form, formErrors, onSubmitDisable, policyList } = this.state;
    let rows = [];
    form.accessPolicies = [];
    if (form.policy && form.policy.length > 0) {
      form.policy.forEach((ele, i) => {
        console.log(ele.read,ele.edit);
        form.accessPolicies.push({
          id: ele.id?._id? ele.id._id:ele._id,
          value: ele.label,
          create: ele.create ? ele.create : false,
          read: ele.read ? ele.read : false,
          edit: ele.edit ? ele.edit : false,
          delete: ele.delete ? ele.delete : false,
        })
      });
      form.accessPolicies.forEach((ele, i) => {
        let create = "create" + i + ele.create;
        let read = "read" + i + ele.read;
        let update = "update" + i + ele.edit;
        let del = "delete" + i + ele.delete;
        rows.push(
          {
            name: ele.value,
            create: (
              <div className="custom-control custom-switch mb-2" dir="ltr">
                <Input type="checkbox" className="custom-control-input" id={create} onChange={(e) => {
                  ele.create = e.target.checked

                }}
                  defaultChecked={ele.create}
                />
                <Label className="custom-control-label" htmlFor={create} ></Label>
              </div>),
            read: (
              <div className="custom-control custom-switch mb-2" dir="ltr">
                <Input type="checkbox" className="custom-control-input" id={read} onChange={(e) => {
                  ele.read = !ele.read

                }}
                  defaultChecked={ele.read}
                />
                <Label className="custom-control-label" htmlFor={read} ></Label>
              </div>),
            Update: (
              <div className="custom-control custom-switch mb-2" dir="ltr">
                <Input type="checkbox" className="custom-control-input" id={update} onChange={(e) => {
                  ele.edit = e.target.checked

                }} defaultChecked={ele.edit} />
                <Label className="custom-control-label" htmlFor={update} ></Label>
              </div>),
            Delete: (
              <div className="custom-control custom-switch mb-2" dir="ltr">
                <Input type="checkbox" className="custom-control-input" id={del} onChange={(e) => {
                  ele.delete = e.target.checked
                }} defaultChecked={ele.delete} />
                <Label className="custom-control-label" htmlFor={del} ></Label>
              </div>),

          })
          delete ele.name;
          delete ele.value;
      })
    }

    data = {
      columns: [
        {
          label: "Policies",
          field: "name",
          sort: "asc",
          width: 300,
        },
        {
          label: "Create",
          field: "create",
          sort: "asc",
          width: 300,
        },
        {
          label: "Read",
          field: "read",
          sort: "asc",
          width: 300,
        },
        {
          label: "Update",
          field: "Update",
          sort: "asc",
          width: 100,
        },
        {
          label: "Delete",
          field: "Delete",
          sort: "asc",
          width: 150,
        },
      ],
      rows: rows
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Edit Role"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <form>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">Role Name</Label>

                            <Input
                              className="form-control"
                              type="text"
                              value={form.role}
                              onBlur={(e) => this.trimSpace(e, "role")}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "role",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="Role Name"
                              // disabled
                            />
                            {formErrors.role && (
                              <span className="err">{formErrors.role}</span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">Policies</Label>
                            <Select
                              isMulti={true}
                              value={form.policy}
                              onChange={(e) => {
                                this.handleChange({
                                  target: {
                                    name: "policy",
                                    value: e,
                                  },
                                })

                              }

                              }
                              options={policyList}
                              classNamePrefix="select2-selection"
                            />

                            {formErrors.policy && (
                              <span className="err">{formErrors.policy}</span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <Card>
                            <CardBody>
                              <h4 className="card-title"> Trip Details </h4>

                              <MDBDataTable
                                responsive
                                striped
                                bordered
                                data={data}
                                paging={true}
                              />
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <FormGroup className="mb-0 text-center ">
                        <div>
                          <Button
                            disabled={onSubmitDisable}
                            type="reset"
                            color="secondary"
                            onClick={this.reset}
                          >
                            Reset
                          </Button>{" "}
                          <Button
                            disabled={onSubmitDisable}
                            type="button"
                            color="btn btn-primary"
                            className="mr-1"
                            onClick={this.handleSubmit}
                          >
                            Submit
                          </Button>
                        </div>
                      </FormGroup>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment >
    );
  }
}


export default connect(null, { fetchRoleById, fetchDropPolicies, editRole })(EditRoleForm);
