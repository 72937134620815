import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import {
  createDriver,
  fetchDriverById,
  editDriverById,
  deleteDriver,
} from "../../store/driver/actions";
import { MoonLoader } from "react-spinners";
import { NotificationManager } from "react-notifications";
import "@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import formatInput from "../InputFormatter";

class EditDriverForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onSubmitDisable: false,
      loading: false,
      breadcrumbItems: [
        { title: "Driver", link: "drivers" },
        { title: "Edit Driver", link: "#" },
      ],

      form: {
        // uname: "",
        id: "",
        fullName: "",
        // lname: "",
        phone: "",
        // email: "",
        // role: "",
        driverStatus: "",

        // vehicleSerialNo: "",
        driverLicenseNumber: "",
        bloodGroup: "",
        emergencyContactPerson: "",
        emergencyContactNo: "",
        zipCode: "",
        state: "",
        city: "",
        transporterVendor: "",
      },
      formErrors: {
        // uname: "",
        fullName: "",
        // lname: "",
        phone: "",
        // email: "",
        // role: "",
        driverStatus: "",
        // vehicleSerialNo: "",
        driverLicenseNumber: "",
        bloodGroup: "",
        emergencyContactPerson: "",
        emergencyContactNo: "",
        zipCode: "",
        state: "",
        city: "",
        transporterVendor: "",
      },
    };

    this.bloodGrpList = [
      { label: "Select blood group", value: "", isDisabled: true },
      { value: "O+", label: "O+" },
      { value: "AB", label: "AB" },
      { value: "A", label: "A" },
      { value: "B", label: "B" },
    ];
    this.driverStatus = [
      { label: "Select status", value: "", isDisabled: true },
      { label: "AVAILABLE", value: "AVAILABLE" },
      { label: "ASSIGNED", value: "ASSIGNED" },
    ];
  }
  componentDidMount = () => {
    this.setState({ loading: true });
    const id = window.location.pathname.split("/edit-driver-")[1];
    this.props.fetchDriverById(id, (res) => {
      const form = {
        id: res._id,
        fullName: res.userId.fullName,
        phone: res.userId.phone,
        driverStatus: res.driverStatus.toUpperCase(),

        // vehicleSerialNo: "",
        driverLicenseNumber: res.driverLicenseNumber,
        bloodGroup: res.bloodGroup,
        emergencyContactPerson: res.emergencyContactPerson,
        emergencyContactNo: res.emergencyContactNo
          ? res.emergencyContactNo.toString()
          : "",
        zipCode: res.userId.zipCode,
        state: res.userId.state,
        city: res.userId.city,
        transporterVendor: res.transporterVendor || "",
      };
      this.setState({ form, loading: false });
    });
  };
  validateField = (name, value, refValue) => {
    let errorMsg = null;
    switch (name) {
      case "fullName":
        if (!value) errorMsg = "Please Enter Full Name.";
        break;

      case "phone":
        if (!value) errorMsg = "Please Enter Phone.";
        if (value.length !== 10) errorMsg = "Please Enter Valid Number.";
        break;

      // case "driverLicenseNumber":
      //   if (!value) errorMsg = "Please Enter Driver License Number.";
      //   break;
      // case "emergencyContactPerson":
      //   if (!value) errorMsg = "Please Enter Emergency Contact Person.";
      //   break;
      case "emergencyContactNo":
        // if (!value) errorMsg = "Please Enter Emergency Contact No.";
        if (value && value.trim() !== "" && value.length !== 10)
          errorMsg = "Please Enter Valid Number.";
        break;
        break;
      // case "bloodGroup":
      //   if (!value) errorMsg = "Please Select Blood Group.";
      //   break;
      // case "transporterVendor":
      //   if (!value) errorMsg = "Please Enter Transport Vendor.";
      //   break;
      case "driverStatus":
        if (!value) errorMsg = "Please Select Driver Status.";
        break;
      default:
        break;
    }
    return errorMsg;
  };

  validateForm = (form, formErrors, validateFunc) => {
    const errorObj = {};
    Object.keys(formErrors).map((x) => {
      let refValue = null;

      const msg = validateFunc(x, form[x], refValue);
      if (msg) errorObj[x] = msg;
    });
    return errorObj;
  };

  handleSubmit = () => {
    this.setState({ onSubmitDisable: true });
    const { form, formErrors } = this.state;
    const errorObj = this.validateForm(form, formErrors, this.validateField);
    if (Object.keys(errorObj).length !== 0) {
      this.setState({
        onSubmitDisable: false,
        formErrors: { ...formErrors, ...errorObj },
      });
      return false;
    }

    this.props.editDriverById(form, (res) => {
      if (
        res["message"] &&
        res["message"] === "Driver has been successfully updated"
      ) {
        NotificationManager.success(res["message"], "Success");
        this.props.history.push("/drivers");
      } else {
        NotificationManager.error(res["message"], "Error");
        this.setState({ onSubmitDisable: false });
      }
    });
  };
  reset = () => {
    let formData = {
      // uname: "",
      fullName: "",
      // lname: "",
      phone: "",
      email: "",
      // role: "",
      driverStatus: "",
      // vehicleSerialNo: "",
      driverLicenseNumber: "",
      bloodGroup: "",
      emergencyContactPerson: "",
      emergencyContactNo: "",
      zipCode: "",
      state: "",
      city: "",
      transporterVendor: "",
    };

    this.setState({
      form: formData,
    });
    this.props.history.push("/drivers");
  };
  validateNumber = (evt) => {
    var theEvent = evt || window.event;

    // Handle paste
    if (theEvent.type === "paste") {
      var key = theEvent.clipboardData.getData("text/plain");
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /^[0-9]*$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    const { form, formErrors } = this.state;
    let formObj = {};

    formObj = {
      ...form,
      [name]: value,
    };
    this.setState({ form: formObj }, () => {
      if (!Object.keys(formErrors).includes(name)) return;
      let formErrorsObj = {};

      const errorMsg = this.validateField(
        name,
        name === "language" ? this.state.form["language"] : value
      );
      formErrorsObj = { ...formErrors, [name]: errorMsg };
      this.setState({ formErrors: formErrorsObj });
    });
  };

  trimSpace = (event, name) => {
    const { form } = this.state;
    let formObj = formatInput(event, name, form);
    this.setState({ form: formObj });
  };
  render() {
    const { form, formErrors, loading, onSubmitDisable } = this.state;
    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#123abc"} />
          </Col>
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Edit Driver"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <form>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">Full Name</Label>

                            <Input
                              className="form-control"
                              type="text"
                              value={form.fullName}
                              onBlur={(e) => this.trimSpace(e, "fullName")}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "fullName",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="Full Name"
                            />
                            {formErrors.fullName && (
                              <span className="err">{formErrors.fullName}</span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">Phone</Label>
                            <Input
                              className="form-control"
                              type="text"
                              value={form.phone}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "phone",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="98989898989"
                              onKeyPress={this.validateNumber}
                            />
                            {formErrors.phone && (
                              <span className="err">{formErrors.phone}</span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label>Driver License No</Label>
                            <Input
                              className="form-control"
                              type="text"
                              value={form.driverLicenseNumber}
                              onBlur={(e) =>
                                this.trimSpace(e, "driverLicenseNumber")
                              }
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "driverLicenseNumber",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="HR2620110040897"
                            />
                            {formErrors.driverLicenseNumber && (
                              <span className="err">
                                {formErrors.driverLicenseNumber}
                              </span>
                            )}
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label>Emergency Contact Person</Label>
                            <Input
                              className="form-control"
                              type="text"
                              value={form.emergencyContactPerson}
                              onBlur={(e) =>
                                this.trimSpace(e, "emergencyContactPerson")
                              }
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "emergencyContactPerson",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="Ex: Smith"
                            />
                            {formErrors.emergencyContactPerson && (
                              <span className="err">
                                {formErrors.emergencyContactPerson}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label>Blood Group</Label>
                            <Select
                              value={this.bloodGrpList.find(
                                (x) =>
                                  x.value.toUpperCase() ===
                                  form.bloodGroup.toUpperCase()
                              )}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "bloodGroup",
                                    value: e.value,
                                  },
                                })
                              }
                              options={this.bloodGrpList}
                            />

                            {formErrors.bloodGroup && (
                              <span className="err">
                                {formErrors.bloodGroup}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label>Emergency Contact No</Label>
                            <Input
                              className="form-control"
                              type="text"
                              value={form.emergencyContactNo}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "emergencyContactNo",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="98989898989"
                              onKeyPress={this.validateNumber}
                            />
                            {formErrors.emergencyContactNo && (
                              <span className="err">
                                {formErrors.emergencyContactNo}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label>Transport Vendor</Label>
                            <Input
                              className="form-control"
                              type="text"
                              value={form.transporterVendor}
                              onBlur={(e) =>
                                this.trimSpace(e, "transporterVendor")
                              }
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "transporterVendor",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="Transport Vendor"
                            />
                            {formErrors.transporterVendor && (
                              <span className="err">
                                {formErrors.transporterVendor}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">
                              Driver Status
                            </Label>
                            <Select
                              value={this.driverStatus.find(
                                (x) => x.value === form.driverStatus
                              )}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "driverStatus",
                                    value: e.value,
                                  },
                                })
                              }
                              options={this.driverStatus}
                              // classNamePrefix="select2-selection"
                            />

                            {formErrors.driverStatus && (
                              <span className="err">
                                {formErrors.driverStatus}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row></Row>
                      <FormGroup className="mb-0 text-center">
                        <div>
                          <Button
                            type="reset"
                            color="secondary"
                            onClick={this.reset}
                          >
                            Cancel
                          </Button>{" "}
                          <Button
                            disabled={onSubmitDisable}
                            type="button"
                            color="btn btn-primary"
                            className="mr-1"
                            onClick={this.handleSubmit}
                          >
                            Submit
                          </Button>
                        </div>
                      </FormGroup>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(null, {
  createDriver,
  fetchDriverById,
  editDriverById,
  deleteDriver,
})(EditDriverForm);
