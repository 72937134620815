import React, { Component } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Container,
  Button,
  FormGroup,
  InputGroup,
  Input,
} from "reactstrap";
import { NotificationManager } from "react-notifications";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";
import Breadcrumbs from "../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { MoonLoader } from "react-spinners";
import {
  fetchDealers,
  deleteDealer,
  fetchDealersfilter,
} from "../store/dealer/actions";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { getAccessForEntity } from "../pages/policies";
import { Grid } from "@material-ui/core";

var data = {};
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var isSuperAdmin = false;
class Dealer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idToDelete: "",
      open: false,
      loading: true,
      breadcrumbItems: [
        { title: "Dealer", link: "dealers" },
        { title: "Dealer Details", link: "#" },
      ],
      editAccess: false,
      delAccess: false,
      filters: "",
      key: "dealerCode",
      searchOn: false,
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.policies !== prevProps.policies) {
      this.getAllData();
    }
  }
  componentDidMount = () => {
    this.getAllData();
  };

  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };

  hasAccessPolicy(val, entity) {
    const { policies } = this.props;
    let hasAccess = false;

    if (policies && policies.length > 0) {
      hasAccess = getAccessForEntity(policies, entity, val);
    }
    return hasAccess;
  }
  getAllData() {
    this.props.fetchDealers(offset, (res) => {
      this.refineData(res);
      this.setState({ loading: false });
    });
  }

  refineData(res) {
    if (Array.isArray(res.docs)) {
      totCnt = res.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = res.offset + res.docs.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      let rows = [];
      let editAccess = this.hasAccessPolicy("edit", "dealer");
      let delAccess = this.hasAccessPolicy("delete", "dealer");
      this.setState({ editAccess, delAccess });
      res.docs.forEach((ele, i) => {
        let cordinates = ele.hopId?.cordinates;

        let location = cordinates
          ? cordinates[0]
          : "" + (cordinates ? "," : "-") + (cordinates ? cordinates[1] : "");
        let actions =
          editAccess || delAccess ? (
            <div>
              {delAccess ? (
                <span>
                  <i
                    onClick={() => this.handleClickOpen(ele._id)}
                    className="fas fa-trash"
                    style={{ cursor: "pointer" }}
                  ></i>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              ) : null}

              {editAccess && ele.name !== "superadmin" ? (
                <span>
                  <Link to={`/edit-dealer-${ele._id}`}>
                    <i className="fas fa-pencil-alt"></i>
                  </Link>
                </span>
              ) : null}
            </div>
          ) : (
            ""
          );

        rows.push({
          fullName: ele.userId?.fullName,
          roleId: ele.userId?.roleId,
          email: ele.userId?.email,
          dealerCode: ele.dealerCode,
          phone: ele.userId?.phone,
          dealerGeolocation: location,
          address: ele.userId?.address,
          zipCode: ele.userId?.zipCode,
          city: ele.userId?.city,
          state: ele.userId?.state,
          actions: actions,
          AlternateContactno: ele.AlternateContactno || "",
        });

        Object.keys(rows[i]).map((key) => {
          if (key !== "actions") {
            rows[i][key] = rows[i][key] ? rows[i][key] : "-";
          } else {
            if (!editAccess && !delAccess) delete rows[i][key];
          }
        });
      });
      this.setState({ rows });
    }
  }

  handleClickOpen = (id) => {
    this.setState({ open: true, idToDelete: id });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleDeleteDealer = () => {
    this.handleClose();
    this.props.deleteDealer(this.state.idToDelete, (res) => {
      if (res["message"] && res["message"] === "Dealer has been deleted") {
        NotificationManager.success(res["message"], "Success");
        this.getAllData();
      } else {
        NotificationManager.error("Something went wrong!", "Error");
      }
    });
  };
  handleChangePage = (event, val) => {
    this.setState({ loading: true });
    if (this.state.searchOn !== true) {
      offset = (val - 1) * process.env.REACT_APP_PERPAGE;
      this.getAllData();
    } else {
      offset = (val - 1) * process.env.REACT_APP_PERPAGE;
      this.props.fetchDealersfilter(
        offset,
        this.state.filters,
        this.state.key,
        (res) => {
          this.refineData(res);
          this.setState({ loading: false });
        }
      );
    }
  };

  filterList = (e) => {
    if (this.state.filters.length > 2) {
      this.setState({ loading: true });
      offset = 0;
      if (this.state.searchOn !== true) this.setState({ searchOn: true });
      this.props.fetchDealersfilter(
        offset,
        this.state.filters,
        this.state.key,
        (res) => {
          this.refineData(res);
          this.setState({ loading: false });
          if (res.message && res.status) {
            if (res.status == "success")
              NotificationManager.success(res["message"], "Success");
            else NotificationManager.warning(res["message"], "Warning");
          }
        }
      );
    }
  };
  change(e) {
    let filters = e.target.value;
    this.setState({ filters });
  }
  changed = (e) => {
    let key = e.target.value;
    this.setState({ key }, () => {
      console.log(this.state.key);
    });
  };
  reset = (e) => {
    this.setState({ loading: true, searchOn: false });
    offset = 0;
    this.props.fetchDealers(offset, (res) => {
      this.refineData(res);
      this.setState({ loading: false, filters: "" });
    });
  };
  render() {
    const { fullScreen } = this.props;
    const { loading, rows, editAccess, delAccess } = this.state;
    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#123abc"} />
          </Col>
        </div>
      );
    }

    const optionList = [
      { key: "dealerCode", value: "Dealer Code" },
      { key: "fullName", value: "Name" },
      { key: "email", value: "Email" },
      { key: "phone", value: "Phone" },
      { key: "zipCode", value: "Zip Code" },
      { key: "city", value: "City" },
      { key: "state", value: "State" },
    ];

    data = {
      columns: [
        {
          label: "Name",
          field: "fullName",
          sort: "asc",
          width: 270,
          align: "center",
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
          width: 200,
          align: "center",
        },
        {
          label: "Phone",
          field: "phone",
          sort: "asc",
          width: 100,
          align: "center",
        },
        {
          label: "Address",
          field: "address",
          sort: "asc",
          width: 100,
          align: "center",
        },
        {
          label: "DealerGeolocation",
          field: "dealerGeolocation",
          align: "center",
          sort: "asc",
          width: 150,
          align: "center",
        },
        {
          label: "DealCode",
          field: "dealerCode",
          sort: "asc",
          width: 150,
          align: "center",
        },
        {
          label: "AlternateContactno",
          field: "AlternateContactno",
          sort: "asc",
          width: 100,
          align: "center",
        },
        {
          label: "ZipCode",
          field: "zipCode",
          sort: "asc",
          width: 270,
          align: "center",
        },
        {
          label: "City",
          field: "city",
          sort: "asc",
          width: 200,
          align: "center",
        },
        {
          label: "State",
          field: "state",
          sort: "asc",
          width: 100,
          align: "center",
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
          width: 100,
        },
      ],
      rows: rows,
    };
    if (!editAccess && !delAccess) data.columns.pop(-1);

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Dealer"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            {this.hasAccessPolicy("create", "dealer") ? (
              <FormGroup className="mb-2 text-right">
                <div>
                  <Link to="/add-dealer" className="small">
                    {" "}
                    <Button type="button" color="btn btn-primary">
                      Add Dealer
                    </Button>
                  </Link>{" "}
                  <Link to="/upload-dealer" className="small">
                    {" "}
                    <Button type="button" color="btn btn-primary">
                      Upload Excel
                    </Button>
                  </Link>
                </div>
              </FormGroup>
            ) : null}

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Grid container alignItems="center" className="mb-2">
                      <Grid item xs={9} md={3}>
                        <h4 className="card-title"> Dealer Details </h4>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <InputGroup>
                          <Input
                            className="form-control mr-2 mt-2"
                            type="select"
                            name="key"
                            placeholder="key"
                            aria-label="Search"
                            onChange={(e) => this.changed(e)}
                            value={this.state.key}
                          >
                            {optionList.map((item, index) => (
                              <option key={index} value={item.key}>
                                {item.value}
                              </option>
                            ))}
                          </Input>
                        </InputGroup>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <InputGroup>
                          <Input
                            className="form-control mr-2 mt-2"
                            type="text"
                            name="filters"
                            placeholder="Search"
                            aria-label="Search"
                            value={this.state.filters}
                            onChange={(e) => this.change(e)}
                          />
                        </InputGroup>
                      </Grid>
                      <Grid item className="mr-2 mt-2">
                        <Button
                          type="button"
                          color="btn btn-primary"
                          onClick={this.filterList}
                          disabled={this.state.filters.trim() === ""}
                        >
                          Search
                        </Button>
                      </Grid>
                      <Grid item className="ml-2 mt-2">
                        <Button
                          type="button"
                          color="btn btn-primary"
                          onClick={this.reset}
                          disabled={this.state.searchOn === false}
                        >
                          Reset
                        </Button>
                      </Grid>
                    </Grid>
                    <MDBDataTable
                      className="text-center"
                      responsive
                      striped
                      bordered
                      data={data}
                      paging={false}
                      searching={false}
                      noBottomColumns
                    />

                    {totCnt !== 0 ? (
                      <Row xs={12}>
                        <Col xs={6}>
                          <span>
                            Showing {fromVal} to {toVal} of {totCnt} entries
                          </span>
                        </Col>
                        <Col xs={6}>
                          <div className="float-right">
                            <Pagination
                              page={offset / 10 + 1}
                              count={totPages}
                              color="primary"
                              onChange={this.handleChangePage}
                            />
                          </div>
                        </Col>
                      </Row>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          <Dialog
            fullScreen={fullScreen}
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">Confirm</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete the dealer?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary" autoFocus>
                Cancel
              </Button>
              <Button onClick={this.handleDeleteDealer} color="">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  dealers: state.Dealer.dealers,
  policies: state.AccessPolicy.accessPolicies.policies,
});

export default connect(mapStateToProps, {
  fetchDealers,
  deleteDealer,
  fetchDealersfilter,
})(Dealer);
