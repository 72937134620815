export const types = {
  FETCH_VEHICLETYPELIST: "FETCH_VEHICLETYPE",
  FETCH_VEHICLETYPELIST_SUCCESS: "FETCH_VEHICLETYPE_SUCCESS",

  SPEC_FILTER_VEHICLETYPE:"SPEC_FILTER_VEHICLETYPE",
  SPEC_FILTER_VEHICLETYPE_SUCCESS :"SPEC_FILTER_VEHICLETYPE_SUCCESS",
  

  SPEC_ADD_VEHICLETYPE:"SPEC_ADD_VEHICLETYPE",
  SPEC_ADD_VEHICLETYPE_SUCCESS:"SPEC_ADD_VEHICLETYPE_SUCCESS",

  SPEC_UPDATE_VEHICLETYPE:"SPEC_UPDATE_VEHICLETYPE",
  SPEC_UPDATE_VEHICLETYPE_SUCCESS:"SPEC_UPDATE_VEHICLETYPE_SUCCESS",

  SPEC_DELETE_VEHICLETYPE:"SPEC_DELETE_VEHICLETYPE",
  SPEC_DELETE_VEHICLETYPE_SUCCESS:"SPEC_DELETE_VEHICLETYPE_SUCCESS",

  SPEC_FETCHSIGNEDURL_VEHICLETYPE:"SPEC_FETCHSIGNEDURL_VEHICLETYPE",
  SPEC_FETCHSIGNEDURL_VEHICLETYPE_SUCCESS:"SPEC_FETCHSIGNEDURL_VEHICLETYPE_SUCCESS",
  
  UPLOAD_VEHICLETYPE:"UPLOAD_VEHICLETYPE_SUCCESS",
  UPLOAD_VEHICLETYPE_SUCCESS:"UPLOAD_VEHICLETYPE_SUCCESS"
};
