import React from "react";
import { Button } from "reactstrap";
import actionVerticleDots from "../Trips/assets/more-vertical.png";

export const actions = (actionString, routingTOTrip) => {
  switch (actionString?.subStatus) {
    case "Planned":
      return (
        <div className="dropdown">
          <Button className="iconButtons dropbtn">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content">
            <div
              className="action-button"
              onClick={() => routingTOTrip.editDetailButton(actionString)}
            >
              Edit Details
            </div>
            <div
              className="action-button"
              onClick={() =>
                routingTOTrip.markerAsArrivedChangeStatus(actionString)
              }
            >
              Marked as Arrived
            </div>
            <div
              className="action-button-cancel"
              onClick={() => routingTOTrip.cancelTripDetailSaver(actionString)}
            >
              Cancel
            </div>
            <div
              className="action-button"
              onClick={() => routingTOTrip.routingTOTripDetails(actionString)}
            >
              Trip History
            </div>
          </div>
        </div>
      );
    case "planned":
      return (
        <div className="dropdown">
          <Button className="iconButtons dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.editDetailButton(actionString)}
            >
              Edit Details
            </div>
            <div
              className="action-button actionDspan"
              onClick={() =>
                routingTOTrip.markerAsArrivedChangeStatus(actionString)
              }
            >
              Marked as Arrived
            </div>
            <div
              className="action-button-cancel actionDrspan"
              onClick={() => routingTOTrip.cancelTripDetailSaver(actionString)}
            >
              Cancel
            </div>
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.routingTOTripDetails(actionString)}
            >
              Trip History
            </div>
          </div>
        </div>
      );
    case "arrived_at_source":
      return (
        <div className="dropdown">
          <Button className="iconButtons dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.editDetailButton(actionString)}
            >
              Edit Details
            </div>
            <div
              className="action-button-cancel actionDrspan"
              onClick={() => routingTOTrip.cancelTripDetailSaver(actionString)}
            >
              Cancel
            </div>
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.routingTOTripDetails(actionString)}
            >
              Trip History
            </div>
          </div>
        </div>
      );
    case "quality_check_completed":
      return (
        <div className="dropdown">
          <Button className="iconButtons dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.startLoading(actionString._id)}
            >
              Start Loading
            </div>
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.editDetailButton(actionString)}
            >
              Edit Details
            </div>
            <div
              className="action-button-cancel actionDrspan"
              onClick={() => routingTOTrip.cancelTripDetailSaver(actionString)}
            >
              Cancel
            </div>
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.routingTOTripDetails(actionString)}
            >
              Trip History
            </div>
          </div>
        </div>
      );
    case "loading_in_progress":
      return (
        <div className="dropdown">
          <Button className="iconButtons dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.verifyButton(actionString)}
            >
              Verify
            </div>
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.editDetailButton(actionString)}
            >
              Edit Details
            </div>
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.routingTOTripDetails(actionString)}
            >
              Trip History
            </div>
          </div>
        </div>
      );
    case "shipment_verified":
      return (
        <div className="dropdown">
          <Button className="iconButtons dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div
              className="action-button actionDspan"
              onClick={() => {
                routingTOTrip.finishLoading(actionString._id);
              }}
            >
              Mark Loading Done
            </div>
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.routingTOTripDetails(actionString)}
            >
              Trip History
            </div>
          </div>
        </div>
      );
    case "in_transit":
      return (
        <div className="dropdown">
          <Button className="iconButtons dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.routingTOTripTracking(actionString)}
            >
              Track
            </div>
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.routingTOTripDetails(actionString)}
            >
              Trip History
            </div>
          </div>
        </div>
      );
    case "arrived_at_via":
      return (
        <div className="dropdown">
          <Button className="iconButtons dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.editDetailButton(actionString)}
            >
              Edit Details
            </div>
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.verifyButton(actionString)}
            >
              Verify
            </div>
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.routingTOTripDetails(actionString)}
            >
              Trip History
            </div>
          </div>
        </div>
      );
    case "completed":
      return (
        <div className="dropdown">
          <Button className="iconButtons dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.routingTOTripDetails(actionString)}
            >
              Trip History
            </div>
          </div>
        </div>
      );
    case "epod_in_progress":
      return (
        <div className="dropdown">
          <Button className="iconButtons dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.routingTOTripDetails(actionString)}
            >
              Trip History
            </div>
          </div>
        </div>
      );
    case "rejected":
      return (
        <div className="dropdown">
          <Button className="iconButtons dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.routingTOTripDetails(actionString)}
            >
              Trip History
            </div>
          </div>
        </div>
      );

    default:
      return (
        <div className="dropdown">
          <Button className="iconButtons dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div className="disabled"><span className="actionDspan">No Options Available</span></div>
          </div>
        </div>
      );
  }
};
