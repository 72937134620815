import React, { Component } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  FormGroup,
  UncontrolledTooltip,
  Badge,
} from "reactstrap";
import { MoonLoader } from "react-spinners";
import Breadcrumbs from "../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import {
  fetchTrips,
  fetchTripsExcel,
  startLoading,
  finishLoading,
  fetchTransporterTrips,
} from "../store/trip/actions";
import { getTransporters } from "../store/transporter/actions";
import {
  fetchAvailVehicles,
  fetchVehicles,
  createVehicle,
} from "../store/vehicle/actions";
import { fetchVehicleTypesList } from "../store/vehicleType/actions";
import {
  fetchAvailDrivers,
  fetchDrivers,
  createDriver,
} from "../store/driver/actions";

import QualityCheckModel from "./TripDetail/QualityCheckModel";
import TripDetailShipmentModel from "./TripDetail/ShipmentModel";
import TripHistoryModel from "./TripDetail/TripHistoryModel";

import { subscribeConsent } from "../store/driver/actions";
import { createTrip, shipmentVerify } from "../store/trip/actions";
import { uploadIndent } from "../store/indent/actions";
import { createBulkIndent } from "../store/indent/actions";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import { MDBDataTable, MDBIcon, MDBCol } from "mdbreact";
import Pagination from "@material-ui/lab/Pagination";
import formatDate from "./DateFormatter";
import { getAccessForEntity } from "../pages/policies";
import ModalTransporter from "./ModalTransporter";
import ApproveModal from "./ApproveModal";
import VerifyShipmentModal from "./VerifyShipmentModal";
import RejectModal from "./RejectModal";
import { MDBBtn } from "mdbreact";
import Uploadform from "../components/Common/UploadForm";
import Sample from "../../src/IndentUpload.csv";
import { restrict } from "./restrict";
import IndentData from "./indentData";
import { date } from "yup/lib/locale";
import Select from "react-select";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { PERMS } from "../enum/perms.enum";
import { Switch } from "@material-ui/core";
import { truncateSync } from "fs";
import { CenterFocusStrong, RingVolumeOutlined } from "@material-ui/icons";
import TripCancelModal from "./tripCancelModal";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import moment from "moment";

var data = {};
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var totCntExcel = 0;
const options = [
  { value: "source", label: "Source" },
  { value: "destination", label: "Destination" },
  { value: "status", label: "Status" },
];
const filterDate = [
  { value: "2", label: "Yesterday" },
  { value: "1", label: "Today" },
  { value: "7", label: "This Week" },
  { value: "30", label: "This Month" },
];

class Trip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Trip", link: "#" },
        { title: "Trip Details", link: "#" },
      ],
      loading: false,
      showUpload: false,
      selectedFiles: [],
      errorMsg: "",
      showModalUploadForm: false,
      page: "Trips",
      heads: [],
      resolved: null,
      data: [],
      filters: "",
      indentData: {},
      setOpenPopup: "",
      searchKey: "",
      searchValue: "",
      disabled: true,
      searchOn: false,
      keySelected: false,
      radioFilter: "",
      VerifyShipmentModal: false,
      ////////////changrtest///////////////////
      showTripDetailModel: false,
      showShipDetailModel: false,
      showTripHistoryModel: false,
      tripDetailId: " ",
      driverName: " ",
      transporterName: " ",
      /////////////////////////////
      shipdetails: " ",
      operator: " ",
      verify: [],
      showCancelTrip: false,
      cancelTripDetails: {},
      modalApproval: false,
      driver: " ",
      vehicle: " ",
      onSubmitDisableVehicle: false,
      onSubmitDisableDriver: false,
      tripId: " ",
      indentId: "",
      //mess
      // vehicleDetails: {},
      // driverDetails: {},
      transporter: "",
      export: false,
    };
    this.indents = [];
    (async () => {
      let redirect = await restrict(PERMS.TRIP_READ);
      // console.log(redirect, "redirect");

      if (redirect == false) {
        this.props.history.push("/operationsDashboard");
      }
    })();
    console.log(this.state.tripDetailId, "tripId");
  }

  componentDidMount = () => {
    this.getAllData();
    console.log("history is ", this.history);
    this.props.fetchVehicleTypesList(data || {}, () => {});
    this.setState({ shipmentVerify: [] });
  };

  componentDidUpdate(prevProps) {
    //   this.setState({policies:this.props.policies})
    //  this.getAllData();
  }

  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };
  getAllData = () => {
    this.setState({ loading: true });
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    console.log("user", authUser);
    let role = authUser.data.role.label;
    let offset = 0;
    let key;

    if (this.state.searchKey === "") {
      key = "";
    } else key = this.state.searchKey.value;

    console.log(
      "Under test",
      "key is",
      localStorage.getItem("searchKey"),
      "value is ",
      localStorage.getItem("searchValue")
    );

    if (
      !(
        localStorage.getItem("searchKey") == null ||
        localStorage.getItem("searchKey") == undefined
      ) &&
      !(
        localStorage.getItem("searchValue") == null ||
        localStorage.getItem("searchValue") == undefined
      )
    ) {
      var isSearched = true;
    }

    if (role === "transporter") {
      console.log("trans");
      this.props.fetchTransporterTrips(
        offset,
        this.state.filters,
        key,
        this.state.searchValue
      );
    } else {
      if (!isSearched) {
        this.props.fetchTrips(
          offset,
          this.state.filters,
          key,
          this.state.searchValue
        );
      } else {
        this.props.fetchTrips(
          offset,
          this.state.filters,
          localStorage.getItem("searchKey"),
          localStorage.getItem("searchValue")
        );
        isSearched = false;
        // this.state.searchValue = localStorage.getItem("searchValue");
        // this.state.searchKey.value = localStorage.getItem("searchKey");
        this.setState({ searchValue: localStorage.getItem("searchValue") });
        this.setState({
          searchKey: { value: localStorage.getItem("searchKey") },
        });
        localStorage.removeItem("searchKey");
        localStorage.removeItem("searchValue");
      }
    }
    // this.props.fetchVehicles(offset, "");
    // this.props.fetchDrivers(offset, "", "", { q: "" });
    this.refineData();
  };

  refineData = () => {
    this.setState({ loading: false });
  };

  handleChangePage = (event, val) => {
    let key;
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    toVal = offset + process.env.REACT_APP_PERPAGE;
    if (this.state.searchKey === "") {
      key = "";
    } else key = this.state.searchKey.value;
    this.props.fetchTrips(
      offset,
      this.state.filters,
      key,
      this.state.searchValue,
      this.state.radioFilter ? this.state.radioFilter.value : ""
    );
    this.setState({ loading: true });
    this.refineData();
  };

  ////////////////////////changetest///////////////////////////////////////////////////////////////

  tripDetailToggleModel = async (id) => {
    this.setState({ showTripDetailModel: !this.state.showTripDetailModel });
    this.setState({ tripDetailId: id });
  };

  tripShipmentToggleModel = async (id) => {
    this.setState({ showShipDetailModel: !this.state.showShipDetailModel });
    this.setState({ tripDetailId: id });
  };

  tripHistoryToggleModel = async (id, name, driver) => {
    this.setState({ showTripHistoryModel: !this.state.showTripHistoryModel });
    this.setState({
      tripDetailId: id,
      transporterName: name,
      driverName: driver,
    });
  };

  //////////////////////////////////////////////////////////////////////////////////////////

  toggleExcel = async () => {
    let offset = 0;
    console.log(totCnt, "tcnt");
    let limit = totCnt;
    let key;
    let rt = "";
    if (this.state.searchKey === "") {
      key = "";
    } else key = this.state.searchKey.value;
    this.props.fetchTripsExcel(
      offset,
      limit,
      this.state.filters,
      key,
      this.state.searchValue,
      this.state.radioFilter ? this.state.radioFilter.value : ""
    );
    this.setState({ loading: true, export: true });

    this.refineData();
    setTimeout(async () => {
      let trips = [];
      if (Array.isArray(this.props.tripsExcel?.data?.docs)) {
        totCntExcel = this.props.tripsExcel?.data?.length;
        fromVal = totCnt !== 0 ? offset + 1 : fromVal;
        toVal =
          this.props.tripsExcel?.data?.offset +
          this.props.tripsExcel?.data?.docs?.length;
        totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
        this.props.tripsExcel.data.docs.forEach((ele, i) => {
          // console.log(ele.via.length?ele.via.map((e, j)=>{
          //   let str=''
          //   console.log(str,"str",e);
          //   return str+=e.label
          // }):'',"route",i)
          trips.push({
            TripId: ele.tripId ? ele.tripId : "N/A",
            IndentId: ele.indentId ? ele.indentId?.indentId : null,
            Driver: ele.driver ? this.driverPopulate(ele.driver) : "N/A",
            Source: ele.source ? `${ele.source.label}` : "N/A",
            Destination: ele.destination ? `${ele.destination.label}` : "N/A",
            Vehicle:
              `${ele.vehicle ? ele.vehicle.regNo : ""}` +
                `${ele.vehicle?.type ? ele.vehicle?.type.label : ""}` || "N/A",
            transporter: ele.transporter?.name || "N/A",
            Route: ele.via?.length
              ? ele.via.map((e, i) => {
                  // console.log(rt,"str",e);
                  return (rt += e.label);
                })
              : "DIRECT",
            // description: ele.description,
            StartTime: ele.startTime
              ? moment(ele.startTime).format("DD-MM-YYYY h:mm A", true)
              : "---",
            Status: ele.subStatus ? ele.subStatus : "N/A",
          });
        });
      }
      await this.exportToCSV([...trips], "TRIP_DATA_EXCEL");
    }, 10000);
  };

  exportToCSV = (csvData, fileName) => {
    // console.log(csvData,"csv")
    // csvData = csvData?.map(function (item) {
    //   for (var key in item) {
    //     var upper = key.toUpperCase();
    //     // check if it already wasn't uppercase
    //   }
    //   return item;
    // });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    this.setState({ export: false });
  };
  startLoading = (ele) => {
    this.props.startLoading(ele._id);
  };
  finishLoading = (ele) => {
    this.props.finishLoading(ele._id);
  };

  actions = (ele) => {
    const role = JSON.parse(localStorage.getItem("authUser"))?.data?.role
      ?.label;
    if (role == "transporter") {
      switch (ele.subStatus) {
        case "quality_check_completed":
          return (
            <span>
              {/* <MDBBtn
                className="mt-3 dataTableButtons"
                onClick={() => this.startLoading(ele)}
              >
                START LOADING
              </MDBBtn> */}
            </span>
          );
        case "loading_in_progress":
          return (
            <span>
              {/* <MDBBtn
                className="mt-3 dataTableButtons"
                onClick={() => this.toggleVerifyShipmentModal(ele)}
              >
                VERIFY
              </MDBBtn> */}
            </span>
          );
        case "planned":
          return (
            <span>
              <MDBBtn
                className="mt-3 dataTableButtons"
                onClick={() => {
                  this.props.fetchVehicles(offset, {
                    transporterId: ele.transporter._id,
                  });
                  this.props.fetchDrivers(offset, "", "", { q: "" });
                  this.setState(
                    {
                      indentId: ele.indentId._id,
                      transporter: ele.transporter._id,
                      tripId: ele._id,
                      vehicle: {
                        value: ele?.vehicle?.id,
                        label:
                          ele?.vehicle?.regNo +
                          " (" +
                          ele?.vehicle?.type?.label +
                          ")",
                      },
                      driver: {
                        value: ele?.driver?.id,
                        label:
                          ele?.driver?.phone + " (" + ele?.driver?.name + ")",
                      },
                    },
                    () => {
                      this.toggleModalApproval();
                    }
                  );
                }}
              >
                Edit Details
              </MDBBtn>
            </span>
          );
        case "shipment_verified":
          return (
            <span>
              {/* <MDBBtn
                className="mt-3 dataTableButtons"
                onClick={() => this.finishLoading(ele)}
              >
                Mark Loading Done
              </MDBBtn> */}
            </span>
          );
        case "in_transit":
          return (
            <span>
              <MDBBtn
                className="mt-3 dataTableButtons"
                onClick={() =>
                  this.props.history.push({
                    pathname: "/Tracking",
                    state: { tripId: ele._id },
                  })
                }
              >
                TRACK
              </MDBBtn>
            </span>
          );
        case "Trip completed":
          return (
            <span>
              <MDBBtn
                className="mt-3 dataTableButtons"
                onClick={() => this.props.history.push("/Tracking")}
              >
                TRACK
              </MDBBtn>
            </span>
          );
        default:
          break;
      }
    } else {
      switch (ele.subStatus) {
        case "quality_check_completed":
          return (
            <span>
              <MDBBtn
                className="mt-3 dataTableButtons"
                onClick={() => this.startLoading(ele)}
              >
                START LOADING
              </MDBBtn>
              <MDBBtn
                className="mt-3 dataTableButtons"
                onClick={() => {
                  this.setState({ transporterId: ele.transporter._id });
                  this.setState({
                    toBeSelectedVehicleType: {
                      value: ele.vehicle?.type?._id,
                      label: ele.vehicle?.type?.label,
                    },
                  });
                  this.props.fetchVehicles(offset, {
                    transporterId: ele.transporter._id,
                    vehicleTypeId: ele.vehicle.type._id,
                    noLimit: true,
                  });
                  this.props.fetchDrivers(offset, "", "", {
                    q: "",
                    transporterId: ele.transporter._id,
                    noLimit: true,
                  });
                  this.setState(
                    {
                      indentId: ele.indentId._id,
                      transporter: ele.transporter._id,
                      tripId: ele._id,
                      vehicle: {
                        value: ele.vehicle.id,
                        label:
                          ele.vehicle.regNo +
                          " (" +
                          ele.vehicle.type.label +
                          ")",
                      },
                      driver: {
                        value: ele.driver.id,
                        label: ele.driver.phone + " (" + ele.driver.name + ")",
                      },
                    },
                    () => {
                      this.toggleModalApproval();
                    }
                  );
                }}
              >
                Edit Details
              </MDBBtn>
              <MDBBtn
                className="mt-3 dataTableButtons"
                style={{ backgroundColor: "#da6666" }}
                onClick={() => this.cancelTripDetailSaver(ele)}
              >
                CANCEL
              </MDBBtn>
            </span>
          );
        case "arrived_at_via":
          let flag = false;
          ele.tracking.path.forEach((e) => {
            if (e.operations.unloading === false) flag = true;
          });
          if (flag === true)
            return (
              <span>
                <MDBBtn
                  className="mt-3 dataTableButtons"
                  onClick={() => this.startLoading(ele)}
                >
                  START LOADING
                </MDBBtn>
                <MDBBtn
                  className="mt-3 dataTableButtons"
                  style={{ backgroundColor: "#da6666" }}
                  onClick={() => this.cancelTripDetailSaver(ele)}
                >
                  CANCEL
                </MDBBtn>
              </span>
            );
          break;
        case "loading_in_progress":
          return (
            <span>
              <MDBBtn
                className="mt-3 dataTableButtons"
                disabled={this.state.loading}
                onClick={() => {
                  this.setState({ loading: true, driver: ele.driver });
                  let phone = ele.driver?.phone;
                  this.props.subscribeConsent(phone, (res) => {
                    console.log(res, "subscripecondetttt");
                    this.toggleVerifyShipmentModal(ele, res);
                    if (res.statusCode === 200)
                      NotificationManager.success(res["message"], "Success");
                    this.setState({ loading: false });
                  });
                }}
              >
                VERIFY
              </MDBBtn>
              <MDBBtn
                className="mt-3 dataTableButtons"
                onClick={() => {
                  this.setState({ transporterId: ele.transporter._id });
                  this.setState({
                    toBeSelectedVehicleType: {
                      value: ele.vehicle?.type?._id,
                      label: ele.vehicle?.type?.label,
                    },
                  });
                  this.props.fetchVehicles(offset, {
                    transporterId: ele.transporter._id,
                    vehicleTypeId: ele.vehicle.type._id,
                    noLimit: true,
                  });
                  this.props.fetchDrivers(offset, "", "", {
                    q: "",
                    transporterId: ele.transporter._id,
                    noLimit: true,
                  });
                  this.setState(
                    {
                      indentId: ele.indentId._id,
                      transporter: ele.transporter._id,
                      tripId: ele._id,
                      vehicle: {
                        value: ele.vehicle.id,
                        label:
                          ele.vehicle.regNo +
                          " (" +
                          ele.vehicle.type.label +
                          ")",
                      },
                      driver: {
                        value: ele.driver.id,
                        label: ele.driver.phone + " (" + ele.driver.name + ")",
                      },
                    },
                    () => {
                      this.toggleModalApproval();
                    }
                  );
                }}
              >
                Edit Details
              </MDBBtn>
            </span>
          );
        case "planned":
          return (
            <span>
              <MDBBtn
                className="mt-3 dataTableButtons"
                onClick={() => {
                  this.setState({ transporterId: ele.transporter._id });
                  this.setState({
                    toBeSelectedVehicleType: {
                      value: ele.vehicle?.type?._id,
                      label: ele.vehicle?.type?.label,
                    },
                  });
                  this.props.fetchVehicles(offset, {
                    transporterId: ele.transporter._id,
                    vehicleTypeId: ele.vehicle.type._id,
                    noLimit: true,
                  });
                  this.props.fetchDrivers(offset, "", "", {
                    q: "",
                    transporterId: ele.transporter._id,
                    noLimit: true,
                  });
                  this.setState(
                    {
                      indentId: ele.indentId._id,
                      transporter: ele.transporter._id,
                      tripId: ele._id,
                      vehicle: {
                        value: ele.vehicle.id,
                        label: ele.vehicle.regNo
                          ? ele.vehicle.regNo +
                            " (" +
                            ele.vehicle?.type?.label +
                            ")"
                          : "Select",
                      },
                      driver: {
                        value: ele.driver?.id,
                        label: ele.driver
                          ? ele.driver?.phone + " (" + ele.driver?.name + ")"
                          : "Select",
                      },
                    },
                    () => {
                      console.log(
                        "log from trip -- driver--",
                        this.state.driver,
                        "vehicle--",
                        this.state.vehicle
                      );
                      this.toggleModalApproval();
                    }
                  );
                }}
              >
                Edit Details
              </MDBBtn>
              <MDBBtn
                className="mt-3 dataTableButtons warning"
                style={{ backgroundColor: "#da6666" }}
                onClick={() => this.cancelTripDetailSaver(ele)}
              >
                CANCEL
              </MDBBtn>
            </span>
          );
        case "arrived_at_source":
          return (
            <span>
              <MDBBtn
                className="mt-3 dataTableButtons"
                onClick={() => {
                  this.setState({ transporterId: ele.transporter._id });
                  this.setState({
                    toBeSelectedVehicleType: {
                      value: ele.vehicle?.type?._id,
                      label: ele.vehicle?.type?.label,
                    },
                  });
                  this.props.fetchVehicles(offset, {
                    transporterId: ele.transporter._id,
                    vehicleTypeId: ele.vehicle.type._id,
                    noLimit: true,
                  });
                  this.props.fetchDrivers(offset, "", "", {
                    q: "",
                    transporterId: ele.transporter._id,
                    noLimit: true,
                  });
                  this.setState(
                    {
                      indentId: ele.indentId._id,
                      transporter: ele.transporter._id,
                      tripId: ele._id,
                      vehicle: {
                        value: ele.vehicle.id,
                        label:
                          ele.vehicle?.regNo +
                          " (" +
                          ele.vehicle?.type?.label +
                          ")",
                      },
                      driver: {
                        value: ele.driver.id,
                        label: ele.driver.phone + " (" + ele.driver.name + ")",
                      },
                    },
                    () => {
                      this.toggleModalApproval();
                    }
                  );
                }}
              >
                Edit Details
              </MDBBtn>
              <MDBBtn
                className="mt-3 dataTableButtons"
                onClick={() => this.cancelTripDetailSaver(ele)}
                style={{ backgroundColor: "#da6666" }}
              >
                CANCEL
              </MDBBtn>
            </span>
          );
        case "shipment_verified":
          return (
            <span>
              <MDBBtn
                className="mt-3 dataTableButtons"
                onClick={() => this.finishLoading(ele)}
              >
                Mark Loading Done
              </MDBBtn>
            </span>
          );
        case "in_transit":
          return (
            <span>
              <MDBBtn
                className="mt-3 dataTableButtons"
                onClick={() => {
                  localStorage.setItem("searchKey", this.state.searchKey.value);
                  localStorage.setItem("searchValue", this.state.searchValue);
                  this.props.history.push({
                    pathname: "/Tracking",
                    state: { tripId: ele._id },
                  });
                }}
              >
                TRACK
              </MDBBtn>
            </span>
          );
        case "completed":
          return (
            <span>
              {/* <MDBBtn
                className="mt-3 dataTableButtons"
                onClick={() =>
                  this.props.history.push({
                    pathname: "/tripDetails",
                    state: { tripId: ele._id },
                  })
                }
              >
                View Details
              </MDBBtn> */}
              N/A
            </span>
          );
        default:
          // return (
          //   <span>
          //     <MDBBtn className="mt-3 dataTableButtons">Default only test</MDBBtn>
          //   </span>
          // );
          break;
      }
    }
  };
  toggleVerifyShipmentModal = (ele, phoneOperator) => {
    console.log("verify_shipment log", ele, phoneOperator);
    this.setState({
      VerifyShipmentModal: !this.state.VerifyShipmentModal,
      shipdetails: ele,
      operator: phoneOperator?.data?.data?.operator || "----",
    });
  };

  cancelTripModal = () => {
    this.setState({ showCancelTrip: !this.state.showCancelTrip });
  };

  cancelTripDetailSaver = (ele) => {
    console.log(ele);
    this.setState({ cancelTripDetails: ele });
    this.cancelTripModal();
  };

  route = (via) => {
    return via?.map((e, i) => (
      <div key={i}>
        {i ? (
          <div className="text-left pr-4" style={{ fontSize: "0.6em" }}>
            <i
              style={{ color: "#5664D2" }}
              className="fas fa-ellipsis-v ml-1"
            ></i>
          </div>
        ) : null}

        <div className="text-left d-flex align-items-center flex-nowrap">
          <i
            style={{ color: "#5664D2" }}
            className="fas fa-circle iconSize"
          ></i>
          <span className="ml-2">{e.label}</span>
        </div>
      </div>
    ));
  };

  clearUpload = () => {
    this.setState({ data: [], resolved: null });
  };

  transformStatus = (status) => {
    let newStatus = status.split("_").join(" ");
    switch (newStatus) {
      case "in transit":
        return (
          <div style={{ color: "#5664D2" }}>
            <i style={{ color: "#5664D2" }} className="fas fa-circle"></i>
            {" " + newStatus}
          </div>
        );
      case "loading in progress":
        return (
          <div style={{ color: "#C58F23" }}>
            <i style={{ color: "#C58F23" }} className="fas fa-circle"></i>
            {" " + newStatus}
          </div>
        );
      case "quality check completed":
        return (
          <div style={{ color: "#369756" }}>
            <i style={{ color: "#56D27F" }} className="fas fa-circle"></i>
            {" " + newStatus}
          </div>
        );
      case "cancelled":
        return (
          <div style={{ color: "#f75c5c" }}>
            <i style={{ color: "#f20000" }} className="fas fa-circle"></i>
            {" " + newStatus}
          </div>
        );
      default:
        return (
          <div>
            <i className="fas fa-circle"></i>
            {newStatus}
          </div>
        );
    }
  };
  status = (status, lastRejection, id) => {
    switch (status) {
      case "Accepted":
        return (
          <strong className="tile-text h6 accepted-color">{status}</strong>
        );
      case "Rejected":
        return (
          <>
            <div id={"status-" + id}>
              <strong className="tile-text h6 rejected-color">{status}</strong>
            </div>
            <UncontrolledTooltip
              placement="top"
              autohide={true}
              target={"status-" + id}
            >
              <div>
                <b>{lastRejection.reason}</b>
              </div>
              {lastRejection.remark}
            </UncontrolledTooltip>
          </>
        );
      case "Assigned":
        return (
          <strong className="tile-text h6 assigned-color">{status}</strong>
        );
      case "Reassigned":
        return (
          <strong className="tile-text h6 assigned-color">{status}</strong>
        );
      case "Finalized":
        return (
          <strong className="tile-text h6 finalized-color">{status}</strong>
        );
      case "Drafted":
        return <strong className="tile-text h6 drafted-color">{status}</strong>;
      default:
        break;
    }
  };
  handleClick = (e, ele) => {
    if (e.target.type != "button") {
      // this.props.fetchCreatedIndentInfo(ele._id, (result) => {
      //   this.setState({ IndentData: result.data }, () => {
      //     this.toggleIndentModal();
      //   });
      // });
    }
  };

  searchKeySetter = (e) => {
    console.log(e);
    this.setState({ searchKey: e, keySelected: true }, () => {
      if (this.state.searchKey !== "" && this.state.searchValue !== "")
        this.setState({ disabled: false });
      if (this.state.searchValue === "") {
        this.setState({ disabled: true });
      }
    });
  };

  searchValueSetter = (e) => {
    this.setState({ searchValue: e.target.value, searchOn: true }, () => {
      if (this.state.searchKey !== "" && this.state.searchValue !== "")
        this.setState({ disabled: false });
      if (this.state.searchValue === "") {
        this.setState({ disabled: true });
      }
    });
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (this.state.disabled === false) {
        this.getAllData();
      }
    }
  };

  reset = (e) => {
    this.setState(
      {
        searchKey: "",
        searchValue: "",
        disabled: true,
        keySelected: false,
        searchOn: false,
        radioFilter: "",
      },
      () => {
        this.getAllData();
      }
    );
  };

  driverPopulate = (value) => {
    let name;
    let phone;
    if (value.name !== "") {
      name = value.name;
    } else name = "----";
    if (value.phone !== "") phone = value.phone;
    else phone = "----";
    if (name === "----" && phone === "----") return "-----";
    else return name + " (" + phone + ")";
  };

  handleDuration = (e) => {
    let value = e.value;
    this.setState({ radioFilter: e }, () => {});
  };
  handleSubmitverify = (verify, callback) => {
    this.props.shipmentVerify(verify, (res) => {
      let result = res.data.data;
      let shipverify = result.filter(
        (rs) => rs.message == "not verified successfully"
      );
      // console.log(shipverify,"respooooo")
      if (shipverify.length != result.length) {
        this.setState(
          { VerifyShipmentModal: !this.state.VerifyShipmentModal },
          () => {
            this.getAllData();
          }
        );
      } else {
        this.setState({ verify: result });
      }
      console.log("Response log in trip", res);
      callback(res);
    });
  };

  toggleModalApproval = () => {
    this.setState({
      modalApproval: !this.state.modalApproval,
      vehicleForm: {
        VehicleOwner: "",
        VehicleRegistrationNumber: "",
        type: null,
      },
      driverForm: {
        fullName: "",
        phone: "",
        driverLicenseNo: "",
      },
    });
  };

  handleChangeModal = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    console.log("handleChangeModal", e.target);
  };

  filterVehicleList = (e) => {
    if (e.length > 2) {
      this.props.fetchVehicles(null, {
        q: e,
        transporterId: this.state.transporterId,
      });
    }
    if (e.length === 0) {
      this.props.fetchVehicles(offset, "");
    }
  };

  filterDriverList = (e) => {
    if (e.length > 2) {
      this.props.fetchDrivers(offset, "", "", { name: e });
    }
    if (e.length === 0) {
      this.props.fetchDrivers(offset, "", "", { q: "" });
    }
  };

  handleSubmitSaveVehicle = () => {
    let {
      VehicleOwner,

      VehicleRegistrationNumber,
      type,
    } = this.state.vehicleForm;
    let formObj = {
      ownerName: VehicleOwner,
      transporter: this.state.transporter,
      regNo: VehicleRegistrationNumber,
      type: type.value,
    };
    this.setState({ onSubmitDisableVehicle: true });
    this.props.createVehicle(formObj, (res) => {
      console.log("reach 1", res);
      if (res.success) {
        this.props.fetchVehicles();
        NotificationManager.success(res["message"], "Success");
        this.setState({ onSubmitDisableVehicle: false });
      } else {
        NotificationManager.error(res["message"], "Error");
        this.setState({
          onSubmitDisableVehicle: false,
          vehicle: "",
          vehicleForm: {
            VehicleOwner: "",
            VehicleRegistrationNumber: "",
            type: null,
          },
        });
      }
    });

    setTimeout(() => {
      let vh;
      if (Array.isArray(this.props.vehicles?.vehicles?.data?.docs)) {
        vh = this.props.vehicles?.vehicles?.data?.docs.filter((v) => {
          console.log("vh details", v);
          return v.ownerName == this.state.vehicleForm.VehicleOwner;
        });
      }

      this.setState({
        vehicle: {
          value: vh[0]?._id,
          label: vh[0]?.registration?.number + " (" + vh[0]?.type?.label + ")",
        },
        vehicleForm: {
          VehicleOwner: "",
          VehicleRegistrationNumber: "",
          type: null,
        },
      });
    }, 4000);
  };

  onVehicleChange = (e) => {
    let { name, value } = e.target;
    let vf = { ...this.state.vehicleForm };
    if (name == "vehicleType") {
      vf.type = value;
    }
    if (name == "VehicleOwner") {
      vf["VehicleOwner"] = value;
    }
    if (name == "VehicleRegistrationNumber") {
      vf["VehicleRegistrationNumber"] = value;
    }
    this.setState({ vehicleForm: vf });
  };

  handleSubmitSaveDriver = () => {
    let { fullName, phone, driverLicenseNo } = this.state.driverForm;
    let formObj = {
      name: fullName,
      transporter: this.state.transporter,
      phone,
      licence: { number: driverLicenseNo },
    };
    this.setState({ onSubmitDisableDriver: true });
    this.props.createDriver(formObj, (res) => {
      if (res.message == "Driver has been created successfully") {
        this.props.fetchDrivers(0, "", "", { q: "" });

        NotificationManager.success(res["message"], "Success");
        this.setState({ onSubmitDisableDriver: false });
      } else {
        NotificationManager.error(res["message"], "Error");
        this.setState({
          onSubmitDisableDriver: false,
          driver: "",
          driverForm: {
            fullName: "",
            phone: "",
            driverLicenseNo: "",
            transporter: "",
          },
        });
      }
    });
    setTimeout(() => {
      let dt;
      if (Array.isArray(this.props.drivers?.drivers?.data?.docs)) {
        dt = this.props.drivers?.drivers?.data?.docs.filter((v) => {
          return v.name == this.state.driverForm.fullName;
        });
      }
      this.setState({
        driver: {
          value: dt[0]?._id,
          label: dt[0]?.phone + " (" + dt[0]?.name + ")",
        },
        driverForm: {
          fullName: "",
          phone: "",
          driverLicenseNo: "",
        },
      });
    }, 4000);
  };
  onDriverChange = (e) => {
    let { name, value } = e.target;
    let df = { ...this.state.driverForm };
    if (name == "phone") {
      value = e.target?.value;
      df.phone = value;
    }
    if (name == "driverLicenseNo") {
      df["driverLicenseNo"] = value;
    }
    if (name == "fullName") {
      df["fullName"] = value;
    }
    this.setState({ driverForm: df });
  };

  resetSelection = () => {
    this.setState({ vehicle: "", driver: "" });
  };

  render() {
    console.log(this.props.trips.data);
    const { fullScreen, policies } = this.props;
    const role = JSON.parse(localStorage.getItem("authUser"))?.data?.role
      ?.label;

    const {
      loading,
      searchKey,
      searchValue,
      keySelected,
      searchOn,
      radioFilter,
    } = this.state;
    this.policy = this.props.policies;
    var show = false;
    let { showCancelTrip } = this.state;

    let trips = [];
    let vehicleOptions = [];
    let driverOptions = [];
    let vehicleTypeOptions = [];

    const { vehicles, drivers } = this.props;
    let vehicleType = this.props.vehicleType.vehicleType;

    if (Array.isArray(this.props.trips?.data?.docs)) {
      offset = this.props.trips?.data?.offset;
      totCnt = this.props.trips?.data?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal =
        this.props.trips?.data?.offset + this.props.trips?.data?.docs?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      this.props.trips.data.docs.forEach((ele) => {
        trips.push({
          id: ele._id,
          tripId: ele.tripId ? ele.tripId : "N/A",
          indentId: ele.indentId ? ele.indentId?.indentId : null,
          driver: ele.driver ? this.driverPopulate(ele.driver) : "N/A",
          source: ele.source ? `${ele.source.label}` : "N/A",
          destination: ele.destination ? `${ele.destination.label}` : "N/A",
          vehicle:
            (
              <>
                <p>{ele.vehicle?.regNo}</p>
                <p>{ele.vehicle?.type?.label}</p>
              </>
            ) || "N/A",
          transporter: ele.transporter?.name || "N/A",
          route: ele.via.length ? <>{this.route(ele.via)}</> : "DIRECT",
          description: ele.description,
          startTime: ele.startTime ? formatDate(ele.startTime) : "N/A",
          expiryTime: ele.expiryTime ? formatDate(ele.expiryTime) : "N/A",
          //status: this.status(ele.status, ele.lastRejection, ele._id) || "---",
          subStatus: this.transformStatus(ele.subStatus),
          actions: this.actions(ele),
          details: (
            <>
              <MDBBtn
                className="mt-3 dataTableButtons"
                onClick={() => {
                  localStorage.setItem("searchKey", this.state.searchKey.value);
                  localStorage.setItem("searchValue", this.state.searchValue);

                  this.tripDetailToggleModel(ele._id);
                }}
              >
                QualityDetails
              </MDBBtn>
              <MDBBtn
                className="mt-3 dataTableButtons"
                onClick={() => {
                  localStorage.setItem("searchKey", this.state.searchKey.value);
                  localStorage.setItem("searchValue", this.state.searchValue);

                  this.tripShipmentToggleModel(ele._id);
                }}
              >
                ShipDetails
              </MDBBtn>
              <MDBBtn
                className="mt-3 dataTableButtons"
                onClick={() => {
                  localStorage.setItem("searchKey", this.state.searchKey.value);
                  localStorage.setItem("searchValue", this.state.searchValue);

                  this.tripHistoryToggleModel(
                    ele._id,
                    ele.transporter?.name,
                    ele?.driver?.name
                  );
                }}
              >
                tripHistoryDetails
              </MDBBtn>
            </>
          ),
          clickEvent: (e) => this.handleClick(e, ele),
        });
      });
    }

    if (Array.isArray(vehicles?.vehicles?.data?.docs)) {
      vehicles.vehicles.data.docs.forEach((ele) => {
        vehicleOptions.push({
          value: ele._id,
          label: ele.registration.number + " (" + ele.type.label + ")",
        });
      });
    }

    if (Array.isArray(vehicleType?.data?.docs)) {
      vehicleType.data.docs.forEach((ele) => {
        vehicleTypeOptions.push({
          value: ele._id,
          label: ele.vehicleTypeCode,
        });
      });
    }
    if (Array.isArray(drivers?.drivers?.data?.docs)) {
      drivers.drivers.data.docs.forEach((ele) => {
        driverOptions.push({
          value: ele._id,
          label: ele.phone + " (" + ele.name + ")",
        });
      });
    }

    if (this.policy == "All policy") {
      show = true;
    }
    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#123abc"} />
          </Col>
        </div>
      );
    }
    data = {
      columns: [
        {
          label: <span>Trip Id</span>,
          field: "tripId",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Indent Id</span>,
          field: "indentId",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Source</span>,
          field: "source",
          sort: "asc",
          width: 300,
        },
        {
          label: <b>Destination</b>,
          field: "destination",
          sort: "asc",
          width: 300,
        },
        {
          label: <b>Route</b>,
          field: "route",
          sort: "asc",
          width: 50,
        },
        {
          label: <b>Vehicle</b>,
          field: "vehicle",
          sort: "asc",
          width: 100,
        },
        {
          label: <b>Driver</b>,
          field: "driver",
          sort: "asc",
          width: 300,
        },
        {
          label: <b>Start Time</b>,
          field: "startTime",
          sort: "asc",
          width: 300,
        },
        {
          label: <b>Status</b>,
          field: "subStatus",
          sort: "asc",
          width: 300,
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
          width: 100,
        },
        {
          label: "Details",
          field: "details",
          sort: "asc",
          width: 100,
        },
      ],
      rows: trips,
    };
    if (role != "transporter") {
      data.columns.splice(4, 0, {
        label: <b>Transporter</b>,
        field: "transporter",
        sort: "asc",
        width: 300,
      });
    }
    const customStyles = {
      indicatorsContainer: (style) => {
        return {
          ...style,
          backgroundColor: "#5664D2",
          width: "54px",
          textAlign: "center",
          justifyContent: "center",
          borderRadius: "0px 4px 4px 0px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          backgroundColor: "#5664D2",
        };
      },
      control: (style) => {
        return {
          ...style,
          width: "241px",
          height: "37px",
          backgroundColor: "#F1F5F7",
        };
      },
    };

    return (
      <React.Fragment>
        {/* ///////////////changetest//////////////// */}
        <QualityCheckModel
          isOpen={this.state.showTripDetailModel}
          toggleModel={this.tripDetailToggleModel}
          tripIds={this.state.tripDetailId}
        />

        <TripDetailShipmentModel
          isOpen={this.state.showShipDetailModel}
          toggleModel={this.tripShipmentToggleModel}
          tripIds={this.state.tripDetailId}
        />

        <TripHistoryModel
          isOpen={this.state.showTripHistoryModel}
          toggleModel={this.tripHistoryToggleModel}
          tripIds={this.state.tripDetailId}
          transporter={this.state.transporterName}
          driver={this.state.driverName}
        />
        {/* ///////////////////////////////////////////////////////////////// */}

        <ApproveModal
          isOpen={this.state.modalApproval}
          toggle={this.toggleModalApproval}
          onChange={this.handleChangeModal}
          options={vehicleOptions}
          optionsdriver={driverOptions}
          driver={this.state.driver}
          vehicle={this.state.vehicle}
          submit={this.handleSubmitApprove}
          disabledVehicle={this.state.onSubmitDisableVehicle}
          disabledDriver={this.state.onSubmitDisableDriver}
          indentIndex={this.state.indentId}
          filterVehicleList={this.filterVehicleList}
          filterDriverList={this.filterDriverList}
          type={vehicleTypeOptions}
          refreshData={this.getAllData}
          submitVehicleForm={this.handleSubmitSaveVehicle}
          onVehicleChange={this.onVehicleChange}
          vehicleForm={this.state.vehicleForm}
          submitDriverForm={this.handleSubmitSaveDriver}
          onDriverChange={this.onDriverChange}
          driverForm={this.state.driverForm}
          resetSelection={this.resetSelection}
          isTrip={true}
          tripIndex={this.state.tripId}
          // vehicleDetails={this.state.vehicleDetails}
          // driverDetails={this.state.driverDetails}
          toBeSelectedVehicleType={this.state.toBeSelectedVehicleType}
          transporter={this.state.transporter}
        />
        <VerifyShipmentModal
          isOpen={this.state.VerifyShipmentModal}
          toggle={this.toggleVerifyShipmentModal}
          onChange={this.handleChangeModal}
          shipdetails={this.state.shipdetails ? this.state.shipdetails : {}}
          operator={this.state.operator ? this.state.operator : {}}
          // options={this.vehicleRegNoArr}
          // optionsdriver={this.driversList}
          driver={this.state.driver}
          // vehicle={this.state.vehicle}
          submit={this.handleSubmitverify}
          verify={this.state.verify}
          disabled={this.state.onSubmitDisable}
        />
        <div /* className="page-content" */>
          {!this.state.showUpload ? (
            <Container
              className="mt-2"
              style={{
                minWidth: "100%",
              }}
            >
              <Row>
                <Col xs={12}>
                  <Card style={{ height: "100%", marginBottom: "61px" }}>
                    <CardBody className="px-3 py-4">
                      <div className="justify-content-between d-flex mb-3">
                        <div md={2} className="float-left">
                          <h5
                            className="mt-1"
                            style={{
                              fontWeight: "700",
                              lineHeight: "24px",
                              size: "16px",
                              color: "#252B3B",
                            }}
                          >
                            Trip Details{" "}
                          </h5>
                        </div>
                        <div className=" d-flex flex-wrap align-items-start float-right">
                          <div md={2} className="pb-1 mr-4">
                            <Select
                              name="SearchKey"
                              placeholder="Search Type"
                              onChange={this.searchKeySetter}
                              options={options}
                              value={searchKey}
                              styles={customStyles}
                            />
                          </div>
                          <div md={3} xs={12} className="pb-1 mr-4">
                            <form className="">
                              <Row>
                                <Col xs={10} className="pr-0 pl-2">
                                  <input
                                    style={{ height: "37px" }}
                                    className="form-control searchBox form-control-sm sharp-corners-right rounded-left"
                                    type="text"
                                    name="SearchValue"
                                    value={searchValue}
                                    onChange={this.searchValueSetter}
                                    placeholder="Search"
                                    aria-label="Search"
                                    onKeyPress={this.handleKeyDown}
                                    disabled={!keySelected}
                                    icon="fas fa-times-circle"
                                  />
                                </Col>
                                <Col xs={2} className="pl-0">
                                  <Button
                                    style={{
                                      height: "37px",
                                      width: "54px",
                                    }}
                                    color="primary"
                                    className="btn sharp-corners-left rounded-right"
                                    onClick={this.getAllData}
                                    disabled={this.state.disabled}
                                  >
                                    <MDBIcon icon="search" />
                                  </Button>
                                </Col>
                              </Row>
                            </form>
                          </div>
                          <div className="text-right reset-col ml-1" md={1}>
                            <Button
                              style={{
                                color: "#333333 !important",
                              }}
                              className="rounded-black button-wide"
                              onClick={this.reset}
                              disabled={!searchOn}
                            >
                              Reset
                            </Button>
                          </div>
                          <div className="text-right reset-col ml-1" md={1}>
                            <Button
                              style={{
                                color: "#333333 !important",
                              }}
                              className="rounded-black button-wide"
                              disabled={this.state.export ? true : false}
                              onClick={() => this.toggleExcel()}
                            >
                              Export
                            </Button>
                          </div>
                        </div>
                      </div>{" "}
                      <div className="dataTableStyles">
                        <MDBDataTable
                          className="text-center text-capitalize"
                          responsive
                          data={data}
                          paging={false}
                          searching={false}
                        />
                        {totCnt !== 0 ? (
                          <Row xs={12}>
                            <Col xs={6}>
                              <span>
                                Showing {fromVal} to {toVal} of {totCnt} entries
                              </span>
                            </Col>
                            <Col xs={6}>
                              <div className="float-right">
                                <Pagination
                                  shape="rounded"
                                  page={offset / 10 + 1}
                                  count={totPages}
                                  color="primary"
                                  onChange={this.handleChangePage}
                                />
                              </div>
                            </Col>
                          </Row>
                        ) : null}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          ) : (
            <Container fluid>
              <Uploadform
                csvUrl={Sample}
                handleSubmit={this.handleUploadSubmit}
                showModal={this.state.showModalUploadForm}
                showToggle={this.showToggle}
                rows={this.state.data}
                resolved={this.state.resolved}
                page={this.state.page}
                heads={this.state.heads}
                insertData={this.createIndent}
                clearUpload={this.clearUpload}
              />
            </Container>
          )}
        </div>
        <TripCancelModal
          showCancelTrip={this.state.showCancelTrip}
          cancelTripDetails={this.state.cancelTripDetails}
          cancelTripModal={this.cancelTripModal}
          getAllData={this.getAllData}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    trips: state.Trip.trips.trips,
    policies: state.AccessPolicy.accessPolicies.policies,
    vehicleType: state.VehicleType.vehicleType,
    vehicles: state.Vehicle.vehicles,
    drivers: state.Driver.drivers,
    tripsExcel: state.Trip.trips.tripsExcel,
  };
};

export default connect(mapStateToProps, {
  fetchTrips,
  fetchTripsExcel,
  fetchTransporterTrips,
  getTransporters,
  fetchAvailVehicles,
  fetchAvailDrivers,
  createTrip,
  uploadIndent,
  createBulkIndent,
  startLoading,
  finishLoading,
  shipmentVerify,
  subscribeConsent,
  fetchVehicles,
  createVehicle,
  fetchDrivers,
  createDriver,
  fetchVehicleTypesList,
})(Trip);
